import { collection, addDoc, getDocs, query, where, connectFirestoreEmulator } from 'firebase/firestore';
import { doc, getDoc, updateDoc, deleteDoc, increment } from "firebase/firestore";
import { db } from "../firebase/index";
import store from "../store/index";

import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';


const addNewUserExternal = (lastname, firstname, email, teamid, userid, avatarurl) => {



  const teamRef = doc(db, "teams", teamid);
  getDoc(teamRef)
    .then((teamSnap) => {
      if (teamSnap.exists()) {
        const companylogo = teamSnap.data().logourl
        const teamname = teamSnap.data().teamname


        let usersRef = collection(db, "users");

        addDoc(usersRef, {
          lastname: lastname,
          firstname: firstname,
          email: email,
          teamid: teamid,
          userid: userid,
          avatarurl: avatarurl,
          emailsignature: "<p>&nbsp;</p> <p>&nbsp;</p> <p>-</p> <div>" + firstname + " " + lastname + "</div> <div>" + teamname + "</div> <div>&nbsp;</div> <div>" + email + "</div> <div>&nbsp;</div> <p><img src='" + companylogo + "' width='150' /></p>",
          generalalertstatus: "read",
          userrole: "Administrator",
          proptions: {
            fields: {
              notes: false,
              company: false,
              contactdetails: false,
              experience: false,
              linkedinurl: false,
              location: false,
              status: false,
              title: false
            },
            namedisplay: "fullname",
            fileformat: "excel"
          },
        })
          .catch((e) => {
            console.log(e.error);
          })
          .then(() => {
            //Confirmation toast
            iziToast.show({
              title: 'Profile created !',
              backgroundColor: '#47be7d',
              titleColor: 'white',
              theme: 'dark',
              position: 'bottomRight',
              timeout: 3000,
              icon: 'fa fa-check'
            });
          });








      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })


}

const addClientExternal = (name, logo, location, status, teamaccess) => {


  let currenttimestamp = Date.now()

  let clientsRef = collection(db, "clients");

  addDoc(clientsRef, {
    clientname: name,
    location: location,
    numberofprojects: 0,
    clientlogourl: logo,
    clientstatus: "Client",
    owninguser: store.state.userData.userid,
    otherusers: "",
    createdat: currenttimestamp,
    teamaccess: teamaccess,
    team: store.state.userData.teamid,
    notes: ""
  })
    .then(() => {
                  //Confirmation toast
                  iziToast.show({
                    title: 'Client created !',
                    backgroundColor: '#47be7d',
                    titleColor: 'white',
                    theme: 'dark',
                    position: 'bottomRight',
                    timeout: 3000,
                    icon: 'fa fa-check'
                  });



                  //show askifproject modal
                  let askifprojectmodal = new bootstrap.Modal(document.getElementById('askifproject'), {
                    keyboard: false
                  })
                  askifprojectmodal.show();

    },
    (error) => {
      console.log(error);
    })



}

const addProjectExternal = (clientobject, positiontitle, location, status, teamaccess) => {

  let currenttimestamp = Date.now()

  let projectsRef = collection(db, "projects");
  addDoc(projectsRef, {
    clientobject: clientobject,
    positiontitle: positiontitle,
    location: location,
    startedat: currenttimestamp,
    endedat: false,
    status: status,
    teamaccess: teamaccess,
    owninguser: store.state.userData.userid,
    team: store.state.userData.teamid,
    targetcompanies: [],
    numberofpotentialcandidates: 0,
    numberofdiscarded: 0,
    numberofinprogress: 0,
    numberofnostatus: 0,
    notes: "",
    otherusers: []
  })
    .then(() => {
                  //Confirmation toast
                  iziToast.show({
                    title: 'Project created !',
                    backgroundColor: '#47be7d',
                    titleColor: 'white',
                    theme: 'dark',
                    position: 'bottomRight',
                    timeout: 3000,
                    icon: 'fa fa-check'
                  });


      const clientRef = doc(db, "clients", clientobject.id);
      updateDoc(clientRef, {
        numberofprojects: increment(1),
      });
    })
    .catch((e) => {
      console.log(e.error);
    });
}

const addProfileFromManualFormExternal = (
  lastname,
  firstname,
  currenttitle,
  currentcompany,
  email,
  phone,
  profileLocation,
  profilepicurl
) => {


  if(!lastname || !firstname) {
    alert("Please add a first and last name to the profile.")
    return;
  }

  let docRef = collection(db, "profiles/");
  addDoc(docRef, {
    alsoviewed: [],
    candidateorclient: "Candidate",
    clientvisible: false,
    currentcompany: currentcompany,
    currentcompanyliurl: "",
    currentcompanylilogo: "",
    currentjobtitle: currenttitle,
    cvurl: "",
    educationlist: [],
    experiencelist: [],
    linkedinsummary: "",
    email: email,
    favourite: false,
    firstname: firstname,
    firsttimeshown: true,
    gdprconsentstatus: "Not requested",
    lastname: lastname,
    latestupdatetimestamp: Date.now(),
    latestupdatebyuser: store.state.currentUserId,
    linkedinurl: "",
    location: profileLocation,
    mobilenumber: phone,
    new: true,
    owninguser: store.state.currentUserId,
    profilepic: profilepicurl,
    tags: [],
    teamaccess: true,
    teamid: store.state.userData.teamid,
    opentaskscounter: 0,
    newemailscounter: 0,
    companysize: 0,
  })
    .then((docRef) => {
      iziToast.show({
        title: 'Profile added !',
        backgroundColor: '#47be7d',
        titleColor: 'white',
        theme: 'dark',
        position: 'bottomRight',
        timeout: 3000,
        icon: 'fa fa-check'
      });
    })

}

function inputCvToCreateProfileExternal() {
  const file = document.getElementById("inputCvToCreateProfile").files[0]
  console.log(file)


  // Send the binary file to the Cloud Function
  const options = {
    method: 'POST',
    body: file,
  }

  fetch('https://us-central1-kiwano-3f6de.cloudfunctions.net/parseNewProfileCv', options)
    .then(res => res.text())
    .then(data => {
      console.log(data);
      let profile = JSON.parse(data)

      //reformat experience and education list:

      let reformatedExperiences = [];
      let reformatedEducations = [];

      //reformat experiences
      for (let i = 0; i < profile.data.profile.experiences.length; i++) {

        let experience = profile.data.profile.experiences[i]


        //in case there is no start/end year

        let startyear = experience.date_start
        let endyear = experience.date_end


        if (!startyear) {
          startyear = ""
        }
        else {
          startyear = experience.date_start.substring(0, 4)
        }

        if (!endyear) {
          endyear = ""
        }
        else {
          endyear = experience.date_end.substring(0, 4)
        }

        reformatedExperiences.push(
          {
            "company": experience.company,
            "title": experience.title,
            "id": Math.floor(Math.random() * 100000000),
            "startyear": startyear,
            "endyear": endyear
          }
        )
      }


      //reformat education
      for (let i = 0; i < profile.data.profile.educations.length; i++) {

        let education = profile.data.profile.educations[i]


        //in case there is no start/end year

        let startyear = education.date_start
        let endyear = education.date_end


        if (!startyear) {
          startyear = ""
        }
        else {
          startyear = education.date_start.substring(0, 4)
        }

        if (!endyear) {
          endyear = ""
        }
        else {
          endyear = education.date_end.substring(0, 4)
        }




        reformatedEducations.push(
          {
            "school": education.school,
            "degree_name": education.title,
            "id": Math.floor(Math.random() * 100000000),
            "startyear": startyear,
            "endyear": endyear
          }
        )
      }


      let tempFirstname;
      let tempLastName;

      //Check if first and last names exist. If not, create a " " string. 
      //This prevents the whole profile list from crashing.
      if(!profile.data.profile.info.first_name) {
        tempFirstname = " "
      } else {
        tempFirstname = profile.data.profile.info.first_name
      }

      if(!profile.data.profile.info.last_name) {
        tempLastName = " "
      } else {
        tempLastName = profile.data.profile.info.last_name
      }




      //add profile to Firestore.
      ///////////// IMPORTANT: the property keys in this object are identical to 
      ///////////// the ones in ChromeExtension.vue. If anything is changed here
      ///////////// it should also be changed in the chrome extension component for consistency
      let docRef = collection(db, "profiles/");
      addDoc(docRef, {
        alsoviewed: [],
        candidateorclient: "Candidate",
        clientvisible: false,
        currentcompany: profile.data.profile.experiences[0].company,
        currentcompanyliurl: "",
        currentcompanylilogo: "",
        currentjobtitle: profile.data.profile.experiences[0].title,
        cvurl: "",
        educationlist: reformatedEducations,
        experiencelist: reformatedExperiences,
        linkedinsummary: "",
        email: profile.data.profile.info.email,
        favourite: false,
        firstname: tempFirstname,
        firsttimeshown: true,
        gdprconsentstatus: "Not requested",
        lastname: tempLastName,
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.currentUserId,
        linkedinurl: "",
        location: profile.data.profile.info.location.fields.country,
        mobilenumber: profile.data.profile.info.phone,
        new: true,
        owninguser: store.state.currentUserId,
        profilepic: profile.data.profile.info.picture,
        tags: [],
        teamaccess: true,
        teamid: store.state.userData.teamid,
        opentaskscounter: 0,
        newemailscounter: 0,
        companysize: 0,
      })
        .then((docRef) => {
          store.state.importState = "done"

          setTimeout(() => {
            store.state.importState = "off"
            
          }, 1000);




          const storage = getStorage();
          const storageRef = ref(storage, "userid" + store.state.userData.userid + "cvforprofile" + docRef.id + "filename" + file.name);

          // 'file' comes from the Blob or File API
          uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');

            getDownloadURL(storageRef)
              .then((url) => {
                console.log("this is the doc ref: " + docRef.id)

                /*
                    // This can be downloaded directly:
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                      const blob = xhr.response;
                    };
                    xhr.open('GET', url);
                    xhr.send();
                    */
                setTimeout(() => {


                  const filetype = file.name.split(".")[1]

                  if (filetype != "doc" &&
                    filetype != "docx" &&
                    filetype != "pdf" &&
                    filetype != "xls" &&
                    filetype != "xlsx" &&
                    filetype != "ppt" &&
                    filetype != "pptx"
                  ) {
                    alert("This file type is not supported. Please upload one of the following file types: Word, Excel, CSV, PowerPoint or PDF.")
                    returnd
                  }



                  const profileRef = doc(db, "profiles", docRef.id);

                  updateDoc(profileRef, {
                    cvfile: {
                      "name": file.name,
                      "url": url,
                      "type": filetype,
                      "id": Math.floor(Math.random() * 100000000),
                    }
                  });

                  setTimeout(() => {
                    store.state.importState = "off"
                  }, 2000);


                })

                //as there is not realtime listener attached to currentProfileDetails, we need to update the Vuex data manually 
                //with the uploaded CV
                //Important to note that the ID here is different than the ID assigned in the Firestore doc, as here another ID is 
                //generated again. Does not seem to be a problem as ID is used only to distinguish files in the Projects and Clients v-for
                //directive.

                store.state.currentProfileDetails.cvfile = {
                  "name": file.name,
                  "url": url,
                  "type": filetype,
                  "id": Math.floor(Math.random() * 100000000),
                }

                console.log("DONE... URL: " + url)
                setTimeout(() => {


                  $('#uploadingfilealert').modal('hide');


                }, 700);




              })
              .catch((error) => {
                // Handle any errors
              });

          });

        })


    })
    .catch(err => {
      console.log(err);
      alert("File type not supported. Please upload a Word of PDF document.")
    });

}

export {
  addNewUserExternal, addClientExternal, addProjectExternal, addProfileFromManualFormExternal, inputCvToCreateProfileExternal

}