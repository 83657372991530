<template>

  <div
    class="wrapper d-flex flex-column flex-row-fluid container-xxl"
    id="kt_wrapper"
  >
 
    <!--begin::Toolbar-->
    <Toolbar title="Back"></Toolbar>
    <!--end::Toolbar-->

    <!--begin::Main-->

    <!--begin::Main new-->
    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div
        class="content flex-row-fluid"
        id="kt_content"
      >

        <!--begin::Layout-->
        <div class="d-flex flex-column flex-lg-row">

          <!--begin::Id Card-->
          <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
            <!--begin::Card-->
            <div class="card mb-5 mb-xl-8 boxFadeIn3">
              <!--begin::Card body-->
              <div class="card-body">
                <!--begin::Summary-->
                <!--begin::User Info-->
                <div class="d-flex flex-start py-5">
                  <!--begin::Profile pic-->
                  <div>xxxxxxxxxxx</div>
                  <div class="symbol symbol-75px symbol-rounded mb-7 me-6">
                    <img
                      v-if="$store.state.currentProfileDetails.profilepic"
                      :src="$store.state.currentProfileDetails.profilepic"
                      alt="image"
                      onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                    />
                    <img
                      alt="Pic"
                      v-if="!$store.state.currentProfileDetails.profilepic"
                      src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                    />

                  </div>
                  <!--end::Profile pic-->
                  <!--begin::Profile name, title & company -->
                  <div class="d-flex flex-start flex-column">
                    <a
                      href="#"
                      class="fs-3 text-gray-800 fw-bolder"
                    >{{ $store.state.currentProfileDetails.firstname + ' ' + $store.state.currentProfileDetails.lastname.toUpperCase() }}</a>
                    <a
                      href="#"
                      class="fs-6 text-gray-400 fw-bolder"
                    >{{$store.state.currentProfileDetails.currentjobtitle.length < 30 ? $store.state.currentProfileDetails.currentjobtitle : $store.state.currentProfileDetails.currentjobtitle.substring(0,30)+'...'}}</a>
                    <a
                      href="#"
                      class="fs-6 text-gray-800 fw-bolder mb-3"
                    >{{ $store.state.currentProfileDetails.currentcompany }}</a>

                    <div>
                      LK | CV
                    </div>
                  </div>
                  <!--end::Profile name, title & company-->
                </div>

                <!--end::User Info-->

                <!--end::Summary-->

                <!--begin:"show details" toggle-->
               <div class="d-flex flex-stack fs-7 text-muted py-3">
                  <div
                    class="rotate collapsible collapsed"
                    data-bs-toggle="collapse"
                    href="#showuserdatils"
                    role="button"
                    aria-expanded="false"
                    aria-controls="showuserdatils"
                  >Details
                    <span class="ms-2 rotate-180">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg--><span class="svg-icon svg-icon-3"><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                            fill="black"
                          ></path>
                        </svg></span>
                      <!--end::Svg Icon-->
                    </span>
                  </div>

                </div>
                <!--end:"show details" toggle-->

                <!--begin::Details-->
     <div
      id="showuserdatils"
      class="collapse"
      style=""
    >
                    <div class="separator"></div>
                <!--begin::Details content-->
                <div class="pb-5 fs-6" >
                  <!--begin::Details item-->
                  <div class="fw-bolder mt-5">Phone</div>
                  <div class="text-gray-600">+33 6 05 45 34 67</div>
                  <div class="fw-bolder mt-5">Email</div>
                  <div class="text-gray-600">john.doe@gmail.com</div>
                  <!--begin::Details item-->
                  <!--begin::Details item-->
                  <div class="fw-bolder mt-5">Location</div>
                  <div class="text-gray-600">
                    <div
                      href="#"
                      class="text-gray-600 text-hover-primary"
                    >{{ $store.state.currentProfileDetails.location }}</div>
                  </div>
                  <div class="fw-bolder mt-5">Description</div>
                  <div class="text-gray-600">
                    <div
                      href="#"
                      class="text-gray-600 text-hover-primary"
                    > fdsoif jqosdifj osdqifj sdf qsdfjsdoif jsdoifjsdof joif jso j oidf jsodqi fjsdoijsof jqsdoif jsdq</div>
                  </div>

                </div>
                <!--end::Details content-->
     </div>

                <!--end::Details -->

              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
            <SmallCard
              whichCard="profileexperience"
              title="Experience"
              subTitle="none"
            ></SmallCard>
          </div>
          <!--end::Id Card-->

          <!--begin::Other cards-->
          <div class="flex-lg-row-fluid ms-lg-8 row">

            <!--begin::Left Col-->
            <div class="col-xl-6 pe-7">
              <!--begin::Project profile activity Card-->
              <div class="row">
                <SmallCard
                  whichCard="projectprofileactivity"
                  title="Projects"
                  subTitle="none"
                ></SmallCard>
              </div>
              <!--end::Project profile activity Card-->
              <!--begin::Tasks Card-->
              <div class="row">
                <SmallCard
                  whichCard="profiletasks"
                  title="Tasks"
                  subTitle="none"
                ></SmallCard>
              </div>
              <!--end::Tasks Card-->
              <!--begin::Emails Card-->
              <div class="row">
                <SmallCard
                  whichCard="emails"
                  title="Emails"
                  subTitle="none"
                ></SmallCard>
              </div>
              <!--end::Email Card-->
            </div>
            <!--end::Left Col-->

            <!--begin::Right Col-->
            <div class="col-xl-6 ps-7 pe-7">
              <!--begin::Tags Card-->
              <div class="row">
                <SmallCard
                  whichCard="profiletags"
                  title="Tags"
                  subTitle="none"
                  :key="$store.state.tagComponentKey"
                ></SmallCard>
              </div>
              <!--end::Tags Card-->
              <!--begin::Timeline Card-->
              <div class="row">
                <SmallCard
                  whichCard="profiletimeline"
                  title="Timeline"
                  subTitle=""
                ></SmallCard>
              </div>
              <!--end::Timeline Card-->
              <!--begin::Settings Card-->
              <div class="row">
                <SmallCard
                  whichCard="profileSettings"
                  title="Settings"
                  subTitle="none"
                ></SmallCard>
              </div>
              <!--end::Settings Card-->

            </div>
            <!--end::Right Col-->

          </div>
          <!--end::Other cards-->

        </div>
        <!--end::Layout-->

      </div>
      <!--end::Content-->
    </div>
    <!--end::Main new-->

    <!--begin::Main-->
    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div
        class="content flex-row-fluid"
        id="kt_content"
      >
        <!--begin::Row-->
        <div class="row g-5 g-xl-8">
          <!--begin::Col-->
          <div class="col-xl-4">
            <SmallCard></SmallCard>
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-xl-4">
            <SmallCard></SmallCard>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row gy-5 g-xl-8">
          <!--begin::Col-->
          <div class="col-xl-4">

          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-xl-8">

            <MediumCard></MediumCard>

          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->

      </div>
      <!--end::Content-->
    </div>
    <!--end::Main-->

    <!--begin::Main old-->
    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div
        class="content flex-row-fluid"
        id="kt_content"
      >

        <!--begin::Layout-->
        <div class="d-flex flex-column flex-lg-row">

          <!--begin::Sidebar-->
          <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
            <!--begin::Card-->
            <div class="card mb-5 mb-xl-8 boxFadeIn3">
              <!--begin::Card body-->
              <div class="card-body">
                <!--begin::Summary-->
                <!--begin::User Info-->
                <div class="d-flex flex-start py-5">
                  <!--begin::Logo-->
                  <div class="symbol symbol-75px symbol-rounded mb-7 me-6">
                    <img
                      v-if="$store.state.currentProfileDetails.profilepic"
                      :src="$store.state.currentProfileDetails.profilepic"
                      alt="image"
                      onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                    />
                    <img
                      alt="Pic"
                      v-if="!$store.state.currentProfileDetails.profilepic"
                      src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                    />

                  </div>
                  <!--end::Logo-->
                  <!--begin::Project title-->
                  <div class="d-flex flex-start flex-column">
                    <a
                      href="#"
                      class="fs-3 text-gray-800 fw-bolder"
                    >{{ $store.state.currentProfileDetails.firstname + ' ' + $store.state.currentProfileDetails.lastname.toUpperCase() }}</a>
                    <a
                      href="#"
                      class="fs-6 text-gray-400 fw-bolder"
                    >{{$store.state.currentProfileDetails.currentjobtitle.length < 30 ? $store.state.currentProfileDetails.currentjobtitle : $store.state.currentProfileDetails.currentjobtitle.substring(0,30)+'...'}}</a>
                    <a
                      href="#"
                      class="fs-6 text-gray-800 fw-bolder mb-3"
                    >{{ $store.state.currentProfileDetails.currentcompany }}</a>

                    <div>
                      LK | CV
                    </div>
                  </div>
                  <!--end::Project title-->

                  <!--begin::Position-->
                  <div class="mb-9">
                    <!--begin::Badge-->
                    <!--begin::Badge-->
                  </div>
                  <!--end::Position-->

                </div>
                <!--end::User Info-->
                <!--end::Summary-->
                <div class="mb-6">

                  <!--begin::Details -->
                  <div class="d-flex flex-stack fs-4 py-3 mt-4">
                    <div class="fw-bolder">Tags
                    </div>
                    <div class="ms-auto">
                      <a
                        href="#"
                        class="btn btn-light-primary btn-sm"
                        style="margin-bottom: -6px;"
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                  <div class="separator mb-6"></div>

                  <input
                    id="onlyMarvel"
                    class="border border-secondary ps-2 border-2 border-dashed rounded "
                    v-model="$store.state.currentProfileDetails.taglist"
                    type="text"
                    name="mcu"
                  >
                </div>
                <!--begin::Details -->
                <div class="d-flex flex-stack fs-4 py-3">
                  <div class="fw-bolder">Details
                  </div>
                  <div class="ms-auto">
                    <a
                      href="#"
                      class="btn btn-light-primary btn-sm"
                      style="margin-bottom: -6px;"
                    >
                      Edit
                    </a>
                  </div>
                </div>

                <!--end::Details -->
                <div class="separator"></div>
                <!--begin::Details content-->
                <div class="pb-5 fs-6">
                  <!--begin::Details item-->
                  <div class="fw-bolder mt-5">Account ID</div>
                  <div class="text-gray-600">ID-45453423</div>
                  <!--begin::Details item-->
                  <!--begin::Details item-->
                  <div class="fw-bolder mt-5">Email</div>
                  <div class="text-gray-600">
                    <a
                      href="#"
                      class="text-gray-600 text-hover-primary"
                    >info@keenthemes.com</a>
                  </div>
                  <!--begin::Details item-->
                  <!--begin::Details item-->
                  <div class="fw-bolder mt-5">Address</div>
                  <div class="text-gray-600">101 Collin Street,
                    <br />Melbourne 3000 VIC
                    <br />Australia
                  </div>
                  <!--begin::Details item-->
                  <!--begin::Details item-->
                  <div class="fw-bolder mt-5">Language</div>
                  <div class="text-gray-600">English</div>
                  <!--begin::Details item-->
                  <!--begin::Details item-->
                  <div class="fw-bolder mt-5">Last Login</div>
                  <div class="text-gray-600">10 Mar 2022, 6:05 pm</div>
                  <!--begin::Details item-->
                </div>
                <!--end::Details content-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Sidebar-->

          <!--begin::Content-->
          <div class="flex-lg-row-fluid ms-lg-15">

            <div class="mb-5">
              Profile activity:
              <ul>
                <li
                  v-for="log in sortedProfileActivityLogs"
                  :key="log.id"
                >
                  <span>{{log.typeofupdate}}</span>
                  <br>
                  <span>by user: {{log.byuser}}</span>
                  <br>
                  <span>on {{new Date(log.timestamp).toLocaleString()}}</span>
                </li>
              </ul>
            </div>

            <div class="mb-5">
              Related projects:
              <ul>
                <li
                  v-for="project in sortedProfileProjectBindings"
                  :key="project.id"
                >
                  <span>{{project.clientname}}</span>
                  <br>
                  <span>{{project.positiontitle}}</span>
                  <br>
                  <span>{{new Date(project.timestamp).toLocaleString()}}</span>

                </li>
              </ul>
            </div>

            <!--begin:::Tabs-->
            <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
              <!--begin:::Tab item-->
              <li class="nav-item">
                <a
                  class="nav-link text-active-primary pb-4 active d-flex"
                  data-bs-toggle="tab"
                  href="#projects_tab"
                >Projects
                  <div
                    class="ms-2"
                    style="margin-top: -3px;"
                  ><span class="badge badge-square badge-warning">2</span></div>
                </a>
              </li>
              <!--end:::Tab item-->
              <!--begin:::Tab item-->
              <li class="nav-item">
                <a
                  class="nav-link text-active-primary pb-4 d-flex"
                  data-kt-countup-tabs="true"
                  data-bs-toggle="tab"
                  href="#todo_tab"
                >To do
                  <div
                    class="ms-2"
                    style="margin-top: -3px;"
                  ><span class="badge badge-square badge-warning">5</span></div>
                </a>

              </li>
              <!--end:::Tab item-->
              <!--begin:::Tab item-->
              <li class="nav-item">
                <a
                  class="nav-link text-active-primary pb-4"
                  data-bs-toggle="tab"
                  href="#kt_user_view_overview_events_and_logs_tab"
                >Emails</a>
              </li>
              <!--end:::Tab item-->
              <!--begin:::Tab item-->
              <li class="nav-item ms-auto">
                <!--begin::Action menu-->
                <!--begin::Stat-->
                <div class="border border-gray-300 border-dashed rounded py-2 px-4 mb-3 d-flex bg-white">
                  <div class="">
                    <!--begin::Label-->
                    <div class="fw-bold fs-8 text-gray-400">Latest update</div>
                    <!--end::Label-->
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <div class="fs-8 fw-bolder">29 Jan, 2022</div>
                    </div>
                    <!--end::Number-->

                  </div>
                  <!--begin::Symbol-->
                  <div class="symbol symbol-35px ms-4">
                    <img
                      src="assets/media/avatars/300-6.jpg"
                      alt=""
                    />
                  </div>
                  <!--end::Symbol-->
                </div>
                <!--end::Stat-->

                <!--end::Menu-->
              </li>
              <!--end:::Tab item-->
            </ul>
            <!--end:::Tabs-->

            <!--begin:::Tab content-->
            <div
              class="tab-content"
              id="myTabContent"
            >

              <!--begin:::Tab pane-->
              <div
                class="tab-pane fade show active"
                id="projects_tab"
                role="tabpanel"
              >

                <!--begin::Card-->
                <div class="card card-flush mb-6 mb-xl-9">
                  <!--begin::Card header-->
                  <div class="card-header mt-9">
                    <!--begin::Card title-->
                    <div class="card-title flex-column">
                      <div
                        @click="goToProject()"
                        class="d-flex pointer latestprofilesitem"
                      >
                        <!--begin::Symbol-->
                        <div class="symbol symbol-40px me-4">
                          <img
                            src="https://ichef.bbci.co.uk/news/999/cpsprodpb/15951/production/_117310488_16.jpg"
                            class=""
                            alt=""
                          />
                          <!--<img :src="$store.state.currentProjectDetails.clientlogourl" class="" alt="" />-->
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Title-->
                        <a
                          class="d-flex flex-column"
                          href="javascript:void(0)"
                        >
                          <!--<span class="fs-8 fw-boldest text-success text-uppercase">Profile info regarding :</span>-->
                          <!--
                                                                <span class="fs-6 text-gray-800 fw-bold">{{$store.state.currentProjectDetails.position}}</span>
                                                                <span class="fs-7 text-muted fw-bold">{{$store.state.currentProjectDetails.clientname}}</span>
                                                                -->

                        </a>
                        <!--end::Title-->

                      </div>
                    </div>
                    <!--end::Card title-->

                    <!--begin::Card toolbar-->
                    <div class="ms-auto d-flex flex-column">

                      <div class="ms-auto">
                        <!--begin::Action menu-->
                        <a
                          href="#"
                          class="btn btn-light-success btn-sm me-2"
                          data-kt-menu-trigger="click"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                        >
                          In progress
                          <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                          <span class="svg-icon svg-icon-2 me-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          <!--end::Svg Icon-->
                        </a>
                        <!--end::Action menu-->

                      </div>

                    </div>
                    <!--end::Card toolbar-->
                  </div>
                  <!--end::Card header-->

                  <!--begin::Card body-->
                  <div class="card-body p-9 pt-7">

                    <!--begin:row-->
                    <div class="row mt-6">

                      <!--begin:left column-->
                      <div class="col-8 ps-8 pe-15">

                        <!--begin:notes-->
                        <div class="row">
                          <div class="d-flex flex-column fs-5 text-gray-800 fw-bolder">
                            <!--begin:header-->
                            <div class="d-flex mb-2">
                              <span>Notes</span>
                              <div class="ms-auto">
                                <a
                                  href="#"
                                  class="btn btn-light-primary btn-sm"
                                  style="margin-top: -10px;"
                                >
                                  Edit
                                </a>
                              </div>
                            </div>
                            <div class="separator"></div>
                            <!--end:header-->

                            <div class="mt-3">
                              <div class="fs-6 text-gray-600">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 150... <span class="text-primary">Read more</span> </div>
                            </div>
                          </div>
                        </div>
                        <!--end:notes-->

                        <!--begin:rating-->
                        <div class="row mb-10">

                          <!--begin:header-->
                          <div class="d-flex mb-2 mt-11 fs-5 text-gray-800 fw-bolder">
                            <span>Match</span>
                            <div class="ms-auto">
                              <span class="h1">{{rating}}%</span>
                            </div>
                          </div>
                          <!--end:header-->
                          <div class=" me-2 mb-5">
                            <input
                              v-model="rating"
                              type="range"
                              class="slider"
                            />
                          </div>

                        </div>
                        <!--end:rating-->

                      </div>
                      <!--end:left column-->

                      <!--begin:right column (comments)-->

                      <div class="col-4 fs-5 text-gray-800 fw-bolder d-flex flex-column pe-6">

                        <!--begin:header-->
                        <div class="d-flex mb-2">
                          <span>Comments</span>
                          <div class="ms-auto">
                            <a
                              href="#"
                              class="btn btn-light-primary btn-sm"
                              style="margin-top: -10px;"
                            >
                              Add
                            </a>
                          </div>
                        </div>
                        <div class="separator"></div>
                        <!--end:header-->

                        <div class="m-0">
                          <!--begin::Timeline-->
                          <div class="timeline ms-n1 mt-5">

                            <!--begin::Timeline item-->
                            <div class="timeline-item align-items-center mb-4">
                              <!--begin::Timeline line-->
                              <div class="timeline-line w-20px mt-9 mb-n14"></div>
                              <!--end::Timeline line-->
                              <!--begin::Timeline icon-->
                              <div
                                class="timeline-icon pt-1"
                                style="margin-left: 0.7px"
                              >
                                <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                                <span class="svg-icon svg-icon-2 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                <!--end::Svg Icon-->
                              </div>
                              <!--end::Timeline icon-->
                              <!--begin::Timeline content-->
                              <div class="timeline-content m-0">
                                <!--begin::Label-->
                                <span class="fs-8 fw-boldest text-gray-400 text-uppercase">Today</span>
                                <!--begin::Label-->
                                <!--begin::Title-->
                                <a
                                  href="#"
                                  class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary"
                                >Will meet HR tomorrow</a>
                                <!--end::Title-->
                                <!--begin::Description-->
                                <div class="d-flex align-items-center mt-1 fs-6">
                                  <!--begin::Info-->
                                  <div class="text-muted me-2 fs-7">Added by Thomas</div>
                                  <!--end::Info-->

                                </div>
                                <!--end::Description-->
                              </div>
                              <!--end::Timeline content-->
                            </div>
                            <!--end::Timeline item-->

                            <!--begin::Timeline item-->
                            <div class="timeline-item align-items-center mb-4">
                              <!--begin::Timeline line-->
                              <div class="timeline-line w-20px mt-9 mb-n14"></div>
                              <!--end::Timeline line-->
                              <!--begin::Timeline icon-->
                              <div
                                class="timeline-icon pt-1"
                                style="margin-left: 0.7px"
                              >
                                <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                                <span class="svg-icon svg-icon-2 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                <!--end::Svg Icon-->
                              </div>
                              <!--end::Timeline icon-->
                              <!--begin::Timeline content-->
                              <div class="timeline-content m-0">
                                <!--begin::Label-->
                                <span class="fs-8 fw-boldest text-gray-400 text-uppercase">2 days ago</span>
                                <!--begin::Label-->
                                <!--begin::Title-->
                                <a
                                  href="#"
                                  class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary"
                                >Will meet HR tomorrow</a>
                                <!--end::Title-->
                                <!--begin::Description-->
                                <div class="d-flex align-items-center mt-1 fs-6">
                                  <!--begin::Info-->
                                  <div class="text-muted me-2 fs-7">Added by Daniel</div>
                                  <!--end::Info-->

                                </div>
                                <!--end::Description-->
                              </div>
                              <!--end::Timeline content-->
                            </div>
                            <!--end::Timeline item-->

                          </div>
                          <!--end::Timeline-->
                        </div>

                        <!--begin:status-->
                        <div class="row mb-10">

                        </div>
                        <!--end:status-->

                      </div>

                      <!--end:right column (comments)-->

                    </div>
                    <!--end:row-->

                  </div>
                  <!--end::Card body-->

                </div>
                <!--end::Card-->

                <!--begin::Card-->
                <div class="card card-flush mb-6 mb-xl-9">
                  <!--begin::Card header-->
                  <div class="card-header mt-9">
                    <!--begin::Card title-->
                    <div class="card-title flex-column">
                      <div
                        @click="goToProject()"
                        class="d-flex pointer latestprofilesitem"
                      >
                        <!--begin::Symbol-->
                        <div class="symbol symbol-40px me-4">
                          <!--
                                                                <img :src="$store.state.currentProjectDetails.clientlogourl" class="" alt="" />
                                                                -->
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Title-->
                        <a
                          class="d-flex flex-column"
                          href="javascript:void(0)"
                        >
                          <!--<span class="fs-8 fw-boldest text-success text-uppercase">Profile info regarding :</span>-->
                          <!--
                                                                <span class="fs-6 text-gray-800 fw-bold">{{$store.state.currentProjectDetails.position}}</span>
                                                                <span class="fs-7 text-muted fw-bold">{{$store.state.currentProjectDetails.clientname}}</span>
                                                                -->

                        </a>
                        <!--end::Title-->

                      </div>
                    </div>
                    <!--end::Card title-->

                    <!--begin::Card toolbar-->
                    <div class="ms-auto d-flex flex-column">

                      <!--begin::Stat-->
                      <div class="border border-gray-300 border-dashed rounded py-3 px-4 mb-3">
                        <!--begin::Label-->
                        <div class="fw-bold fs-8 text-gray-400">Latest update</div>
                        <!--end::Label-->
                        <!--begin::Number-->
                        <div class="d-flex align-items-center">
                          <div class="fs-8 fw-bold">29 Jan, 2022</div>
                        </div>
                        <!--end::Number-->

                      </div>
                      <!--end::Stat-->

                    </div>
                    <!--end::Card toolbar-->
                  </div>
                  <!--end::Card header-->

                  <!--begin::Card body-->
                  <div class="card-body p-9 pt-7">

                    <!--begin:row-->
                    <div class="row mt-6">

                      <!--begin:left column-->
                      <div class="col-8 ps-8 pe-15">

                        <!--begin:notes-->
                        <div class="row">
                          <div class="d-flex flex-column fs-5 text-gray-800 fw-bolder">
                            <!--begin:header-->
                            <div class="d-flex mb-2">
                              <span>Notes</span>
                              <div class="ms-auto">
                                <a
                                  href="#"
                                  class="btn btn-light-primary btn-sm"
                                  style="margin-top: -10px;"
                                >
                                  Edit
                                </a>
                              </div>
                            </div>
                            <div class="separator"></div>
                            <!--end:header-->

                            <div class="mt-3">
                              <div class="fs-6 text-gray-600">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 150... <span class="text-primary">Read more</span> </div>
                            </div>
                          </div>
                        </div>
                        <!--end:notes-->

                        <!--begin:status-->
                        <div class="row mb-10">

                          <!--begin:header-->
                          <div class="d-flex mb-2 mt-11 fs-5 text-gray-800 fw-bolder">
                            <span>Match</span>
                            <div class="ms-auto">
                              <span class="h1">{{rating}}%</span>
                            </div>
                          </div>
                          <!--end:header-->
                          <div class=" me-2 mb-5">
                            <input
                              v-model="rating"
                              type="range"
                              class="slider"
                            />
                          </div>

                        </div>
                        <!--end:status-->

                      </div>
                      <!--end:left column-->

                      <!--begin:right column (comments)-->

                      <div class="col-4 fs-5 text-gray-800 fw-bolder d-flex flex-column pe-15">

                        <!--begin:header-->
                        <div class="d-flex mb-2">
                          <span>Comments</span>
                          <div class="ms-auto">
                            <a
                              href="#"
                              class="btn btn-light-primary btn-sm"
                              style="margin-top: -10px;"
                            >
                              Add
                            </a>
                          </div>
                        </div>
                        <div class="separator"></div>
                        <!--end:header-->

                        <div class="m-0">
                          <!--begin::Timeline-->
                          <div class="timeline ms-n1 mt-5">

                            <!--begin::Timeline item-->
                            <div class="timeline-item align-items-center mb-4">
                              <!--begin::Timeline line-->
                              <div class="timeline-line w-20px mt-9 mb-n14"></div>
                              <!--end::Timeline line-->
                              <!--begin::Timeline icon-->
                              <div
                                class="timeline-icon pt-1"
                                style="margin-left: 0.7px"
                              >
                                <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                                <span class="svg-icon svg-icon-2 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                <!--end::Svg Icon-->
                              </div>
                              <!--end::Timeline icon-->
                              <!--begin::Timeline content-->
                              <div class="timeline-content m-0">
                                <!--begin::Label-->
                                <span class="fs-8 fw-boldest text-gray-400 text-uppercase">Today</span>
                                <!--begin::Label-->
                                <!--begin::Title-->
                                <a
                                  href="#"
                                  class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary"
                                >Will meet HR tomorrow</a>
                                <!--end::Title-->
                                <!--begin::Description-->
                                <div class="d-flex align-items-center mt-1 fs-6">
                                  <!--begin::Info-->
                                  <div class="text-muted me-2 fs-7">Added by Thomas</div>
                                  <!--end::Info-->

                                </div>
                                <!--end::Description-->
                              </div>
                              <!--end::Timeline content-->
                            </div>
                            <!--end::Timeline item-->

                            <!--begin::Timeline item-->
                            <div class="timeline-item align-items-center mb-4">
                              <!--begin::Timeline line-->
                              <div class="timeline-line w-20px mt-9 mb-n14"></div>
                              <!--end::Timeline line-->
                              <!--begin::Timeline icon-->
                              <div
                                class="timeline-icon pt-1"
                                style="margin-left: 0.7px"
                              >
                                <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                                <span class="svg-icon svg-icon-2 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                <!--end::Svg Icon-->
                              </div>
                              <!--end::Timeline icon-->
                              <!--begin::Timeline content-->
                              <div class="timeline-content m-0">
                                <!--begin::Label-->
                                <span class="fs-8 fw-boldest text-gray-400 text-uppercase">2 days ago</span>
                                <!--begin::Label-->
                                <!--begin::Title-->
                                <a
                                  href="#"
                                  class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary"
                                >Will meet HR tomorrow</a>
                                <!--end::Title-->
                                <!--begin::Description-->
                                <div class="d-flex align-items-center mt-1 fs-6">
                                  <!--begin::Info-->
                                  <div class="text-muted me-2 fs-7">Added by Daniel</div>
                                  <!--end::Info-->

                                </div>
                                <!--end::Description-->
                              </div>
                              <!--end::Timeline content-->
                            </div>
                            <!--end::Timeline item-->

                          </div>
                          <!--end::Timeline-->
                        </div>

                        <!--begin:status-->
                        <div class="row mb-10">

                        </div>
                        <!--end:status-->

                      </div>

                      <!--end:right column (comments)-->

                    </div>
                    <!--end:row-->

                  </div>
                  <!--end::Card body-->

                </div>
                <!--end::Card-->

              </div>
              <!--end:::Tab pane-->

              <!--begin:::Tab pane-->
              <div
                class="tab-pane fade"
                id="todo_tab"
                role="tabpanel"
              >

                <!--begin::Tasks-->
                <div class="card card-flush mb-6 mb-xl-9">
                  <!--begin::Card header-->
                  <div class="card-header mt-6">
                    <!--begin::Card title-->
                    <div class="card-title flex-column">
                      <h2 class="mb-1">User's Tasks</h2>
                      <div class="fs-6 fw-bold text-muted">Total 25 tasks in backlog</div>
                    </div>
                    <!--end::Card title-->
                    <!--begin::Card toolbar-->
                    <div class="card-toolbar">
                      <button
                        type="button"
                        class="btn btn-light-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_add_task"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/files/fil005.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z"
                              fill="black"
                            />
                            <rect
                              x="11"
                              y="19"
                              width="10"
                              height="2"
                              rx="1"
                              transform="rotate(-90 11 19)"
                              fill="black"
                            />
                            <rect
                              x="7"
                              y="13"
                              width="10"
                              height="2"
                              rx="1"
                              fill="black"
                            />
                            <path
                              d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->Add Task
                      </button>
                    </div>
                    <!--end::Card toolbar-->
                  </div>
                  <!--end::Card header-->
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-column">
                    <!--begin::Item-->
                    <div class="d-flex align-items-center position-relative mb-7">
                      <!--begin::Label-->
                      <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
                      <!--end::Label-->
                      <!--begin::Details-->
                      <div class="fw-bold ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-dark text-hover-primary"
                        >Create FureStibe branding logo</a>
                        <!--begin::Info-->
                        <div class="fs-7 text-muted">Due in 1 day
                          <a href="#">Karina Clark</a>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Details-->
                      <!--begin::Menu-->
                      <button
                        type="button"
                        class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </button>
                      <!--begin::Task menu-->
                      <div
                        class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        data-kt-menu-id="kt-users-tasks"
                      >
                        <!--begin::Header-->
                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bolder">Update Status</div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Menu separator-->
                        <div class="separator border-gray-200"></div>
                        <!--end::Menu separator-->
                        <!--begin::Form-->
                        <form
                          class="form px-7 py-5"
                          data-kt-menu-id="kt-users-tasks-form"
                        >
                          <!--begin::Input group-->
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bold">Status:</label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <select
                              class="form-select form-select-solid"
                              name="task_status"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              data-hide-search="true"
                            >
                              <option></option>
                              <option value="1">Approved</option>
                              <option value="2">Pending</option>
                              <option value="3">In Process</option>
                              <option value="4">Rejected</option>
                            </select>
                            <!--end::Input-->
                          </div>
                          <!--end::Input group-->
                          <!--begin::Actions-->
                          <div class="d-flex justify-content-end">
                            <button
                              type="button"
                              class="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-users-update-task-status="reset"
                            >Reset</button>
                            <button
                              type="submit"
                              class="btn btn-sm btn-primary"
                              data-kt-users-update-task-status="submit"
                            >
                              <span class="indicator-label">Apply</span>
                              <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                          </div>
                          <!--end::Actions-->
                        </form>
                        <!--end::Form-->
                      </div>
                      <!--end::Task menu-->
                      <!--end::Menu-->
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center position-relative mb-7">
                      <!--begin::Label-->
                      <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
                      <!--end::Label-->
                      <!--begin::Details-->
                      <div class="fw-bold ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-dark text-hover-primary"
                        >Schedule a meeting with FireBear CTO John</a>
                        <!--begin::Info-->
                        <div class="fs-7 text-muted">Due in 3 days
                          <a href="#">Rober Doe</a>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Details-->
                      <!--begin::Menu-->
                      <button
                        type="button"
                        class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </button>
                      <!--begin::Task menu-->
                      <div
                        class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        data-kt-menu-id="kt-users-tasks"
                      >
                        <!--begin::Header-->
                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bolder">Update Status</div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Menu separator-->
                        <div class="separator border-gray-200"></div>
                        <!--end::Menu separator-->
                        <!--begin::Form-->
                        <form
                          class="form px-7 py-5"
                          data-kt-menu-id="kt-users-tasks-form"
                        >
                          <!--begin::Input group-->
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bold">Status:</label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <select
                              class="form-select form-select-solid"
                              name="task_status"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              data-hide-search="true"
                            >
                              <option></option>
                              <option value="1">Approved</option>
                              <option value="2">Pending</option>
                              <option value="3">In Process</option>
                              <option value="4">Rejected</option>
                            </select>
                            <!--end::Input-->
                          </div>
                          <!--end::Input group-->
                          <!--begin::Actions-->
                          <div class="d-flex justify-content-end">
                            <button
                              type="button"
                              class="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-users-update-task-status="reset"
                            >Reset</button>
                            <button
                              type="submit"
                              class="btn btn-sm btn-primary"
                              data-kt-users-update-task-status="submit"
                            >
                              <span class="indicator-label">Apply</span>
                              <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                          </div>
                          <!--end::Actions-->
                        </form>
                        <!--end::Form-->
                      </div>
                      <!--end::Task menu-->
                      <!--end::Menu-->
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center position-relative mb-7">
                      <!--begin::Label-->
                      <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
                      <!--end::Label-->
                      <!--begin::Details-->
                      <div class="fw-bold ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-dark text-hover-primary"
                        >9 Degree Project Estimation</a>
                        <!--begin::Info-->
                        <div class="fs-7 text-muted">Due in 1 week
                          <a href="#">Neil Owen</a>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Details-->
                      <!--begin::Menu-->
                      <button
                        type="button"
                        class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </button>
                      <!--begin::Task menu-->
                      <div
                        class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        data-kt-menu-id="kt-users-tasks"
                      >
                        <!--begin::Header-->
                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bolder">Update Status</div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Menu separator-->
                        <div class="separator border-gray-200"></div>
                        <!--end::Menu separator-->
                        <!--begin::Form-->
                        <form
                          class="form px-7 py-5"
                          data-kt-menu-id="kt-users-tasks-form"
                        >
                          <!--begin::Input group-->
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bold">Status:</label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <select
                              class="form-select form-select-solid"
                              name="task_status"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              data-hide-search="true"
                            >
                              <option></option>
                              <option value="1">Approved</option>
                              <option value="2">Pending</option>
                              <option value="3">In Process</option>
                              <option value="4">Rejected</option>
                            </select>
                            <!--end::Input-->
                          </div>
                          <!--end::Input group-->
                          <!--begin::Actions-->
                          <div class="d-flex justify-content-end">
                            <button
                              type="button"
                              class="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-users-update-task-status="reset"
                            >Reset</button>
                            <button
                              type="submit"
                              class="btn btn-sm btn-primary"
                              data-kt-users-update-task-status="submit"
                            >
                              <span class="indicator-label">Apply</span>
                              <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                          </div>
                          <!--end::Actions-->
                        </form>
                        <!--end::Form-->
                      </div>
                      <!--end::Task menu-->
                      <!--end::Menu-->
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center position-relative mb-7">
                      <!--begin::Label-->
                      <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
                      <!--end::Label-->
                      <!--begin::Details-->
                      <div class="fw-bold ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-dark text-hover-primary"
                        >Dashboard UI &amp; UX for Leafr CRM</a>
                        <!--begin::Info-->
                        <div class="fs-7 text-muted">Due in 1 week
                          <a href="#">Olivia Wild</a>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Details-->
                      <!--begin::Menu-->
                      <button
                        type="button"
                        class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </button>
                      <!--begin::Task menu-->
                      <div
                        class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        data-kt-menu-id="kt-users-tasks"
                      >
                        <!--begin::Header-->
                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bolder">Update Status</div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Menu separator-->
                        <div class="separator border-gray-200"></div>
                        <!--end::Menu separator-->
                        <!--begin::Form-->
                        <form
                          class="form px-7 py-5"
                          data-kt-menu-id="kt-users-tasks-form"
                        >
                          <!--begin::Input group-->
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bold">Status:</label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <select
                              class="form-select form-select-solid"
                              name="task_status"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              data-hide-search="true"
                            >
                              <option></option>
                              <option value="1">Approved</option>
                              <option value="2">Pending</option>
                              <option value="3">In Process</option>
                              <option value="4">Rejected</option>
                            </select>
                            <!--end::Input-->
                          </div>
                          <!--end::Input group-->
                          <!--begin::Actions-->
                          <div class="d-flex justify-content-end">
                            <button
                              type="button"
                              class="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-users-update-task-status="reset"
                            >Reset</button>
                            <button
                              type="submit"
                              class="btn btn-sm btn-primary"
                              data-kt-users-update-task-status="submit"
                            >
                              <span class="indicator-label">Apply</span>
                              <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                          </div>
                          <!--end::Actions-->
                        </form>
                        <!--end::Form-->
                      </div>
                      <!--end::Task menu-->
                      <!--end::Menu-->
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center position-relative">
                      <!--begin::Label-->
                      <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
                      <!--end::Label-->
                      <!--begin::Details-->
                      <div class="fw-bold ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-dark text-hover-primary"
                        >Mivy App R&amp;D, Meeting with clients</a>
                        <!--begin::Info-->
                        <div class="fs-7 text-muted">Due in 2 weeks
                          <a href="#">Sean Bean</a>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Details-->
                      <!--begin::Menu-->
                      <button
                        type="button"
                        class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </button>
                      <!--begin::Task menu-->
                      <div
                        class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        data-kt-menu-id="kt-users-tasks"
                      >
                        <!--begin::Header-->
                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bolder">Update Status</div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Menu separator-->
                        <div class="separator border-gray-200"></div>
                        <!--end::Menu separator-->
                        <!--begin::Form-->
                        <form
                          class="form px-7 py-5"
                          data-kt-menu-id="kt-users-tasks-form"
                        >
                          <!--begin::Input group-->
                          <div class="fv-row mb-10">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bold">Status:</label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <select
                              class="form-select form-select-solid"
                              name="task_status"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              data-hide-search="true"
                            >
                              <option></option>
                              <option value="1">Approved</option>
                              <option value="2">Pending</option>
                              <option value="3">In Process</option>
                              <option value="4">Rejected</option>
                            </select>
                            <!--end::Input-->
                          </div>
                          <!--end::Input group-->
                          <!--begin::Actions-->
                          <div class="d-flex justify-content-end">
                            <button
                              type="button"
                              class="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-users-update-task-status="reset"
                            >Reset</button>
                            <button
                              type="submit"
                              class="btn btn-sm btn-primary"
                              data-kt-users-update-task-status="submit"
                            >
                              <span class="indicator-label">Apply</span>
                              <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                          </div>
                          <!--end::Actions-->
                        </form>
                        <!--end::Form-->
                      </div>
                      <!--end::Task menu-->
                      <!--end::Menu-->
                    </div>
                    <!--end::Item-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Tasks-->
              </div>
              <!--end:::Tab pane-->

            </div>
            <!--end:::Tab content-->

          </div>
          <!--end::Content-->

        </div>
        <!--end::Layout-->

        <!--begin::Modals-->
        <!--begin::Modal - New Product-->
        <div
          class="modal fade"
          id="kt_modal_add_event"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                action="#"
                id="kt_modal_add_event_form"
              >
                <!--begin::Modal header-->
                <div class="modal-header">
                  <!--begin::Modal title-->
                  <h2
                    class="fw-bolder"
                    data-kt-calendar="title"
                  >Add Event</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                    id="kt_modal_add_event_close"
                  >
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                    <span class="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="6"
                          y="17.3137"
                          width="16"
                          height="2"
                          rx="1"
                          transform="rotate(-45 6 17.3137)"
                          fill="black"
                        />
                        <rect
                          x="7.41422"
                          y="6"
                          width="16"
                          height="2"
                          rx="1"
                          transform="rotate(45 7.41422 6)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Input group-->
                  <div class="fv-row mb-9">
                    <!--begin::Label-->
                    <label class="fs-6 fw-bold required mb-2">Event Name</label>
                    <!--end::Label-->
                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="calendar_event_name"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-9">
                    <!--begin::Label-->
                    <label class="fs-6 fw-bold mb-2">Event Description</label>
                    <!--end::Label-->
                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="calendar_event_description"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-9">
                    <!--begin::Label-->
                    <label class="fs-6 fw-bold mb-2">Event Location</label>
                    <!--end::Label-->
                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="calendar_event_location"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-9">
                    <!--begin::Checkbox-->
                    <label class="form-check form-check-custom form-check-solid">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="kt_calendar_datepicker_allday"
                      />
                      <span
                        class="form-check-label fw-bold"
                        for="kt_calendar_datepicker_allday"
                      >All Day</span>
                    </label>
                    <!--end::Checkbox-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row row-cols-lg-2 g-10">
                    <div class="col">
                      <div class="fv-row mb-9">
                        <!--begin::Label-->
                        <label class="fs-6 fw-bold mb-2 required">Event Start Date</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input
                          class="form-control form-control-solid"
                          name="calendar_event_start_date"
                          placeholder="Pick a start date"
                          id="kt_calendar_datepicker_start_date"
                        />
                        <!--end::Input-->
                      </div>
                    </div>
                    <div
                      class="col"
                      data-kt-calendar="datepicker"
                    >
                      <div class="fv-row mb-9">
                        <!--begin::Label-->
                        <label class="fs-6 fw-bold mb-2">Event Start Time</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input
                          class="form-control form-control-solid"
                          name="calendar_event_start_time"
                          placeholder="Pick a start time"
                          id="kt_calendar_datepicker_start_time"
                        />
                        <!--end::Input-->
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="row row-cols-lg-2 g-10">
                    <div class="col">
                      <div class="fv-row mb-9">
                        <!--begin::Label-->
                        <label class="fs-6 fw-bold mb-2 required">Event End Date</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input
                          class="form-control form-control-solid"
                          name="calendar_event_end_date"
                          placeholder="Pick a end date"
                          id="kt_calendar_datepicker_end_date"
                        />
                        <!--end::Input-->
                      </div>
                    </div>
                    <div
                      class="col"
                      data-kt-calendar="datepicker"
                    >
                      <div class="fv-row mb-9">
                        <!--begin::Label-->
                        <label class="fs-6 fw-bold mb-2">Event End Time</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input
                          class="form-control form-control-solid"
                          name="calendar_event_end_time"
                          placeholder="Pick a end time"
                          id="kt_calendar_datepicker_end_time"
                        />
                        <!--end::Input-->
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    type="reset"
                    id="kt_modal_add_event_cancel"
                    class="btn btn-light me-3"
                  >Cancel</button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="button"
                    id="kt_modal_add_event_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">Submit</span>
                    <span class="indicator-progress">Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>
        <!--end::Modal - New Product-->
        <!--begin::Modal - New Product-->
        <div
          class="modal fade"
          id="kt_modal_view_event"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header border-0 justify-content-end">
                <!--begin::Edit-->
                <div
                  class="btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-primary me-2"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Edit Event"
                  id="kt_modal_view_event_edit"
                >
                  <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                        fill="black"
                      />
                      <path
                        d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Edit-->
                <!--begin::Edit-->
                <div
                  class="btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger me-2"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Delete Event"
                  id="kt_modal_view_event_delete"
                >
                  <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                        fill="black"
                      />
                      <path
                        opacity="0.5"
                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Edit-->
                <!--begin::Close-->
                <div
                  class="btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary"
                  data-bs-toggle="tooltip"
                  title="Hide Event"
                  data-bs-dismiss="modal"
                >
                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="black"
                      />
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body pt-0 pb-20 px-lg-17">
                <!--begin::Row-->
                <div class="d-flex">
                  <!--begin::Icon-->
                  <!--begin::Svg Icon | path: icons/duotune/general/gen014.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-muted me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                        fill="black"
                      />
                      <path
                        d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                        fill="black"
                      />
                      <path
                        d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--end::Icon-->
                  <div class="mb-9">
                    <!--begin::Event name-->
                    <div class="d-flex align-items-center mb-2">
                      <span
                        class="fs-3 fw-bolder me-3"
                        data-kt-calendar="event_name"
                      ></span>
                      <span
                        class="badge badge-light-success"
                        data-kt-calendar="all_day"
                      ></span>
                    </div>
                    <!--end::Event name-->
                    <!--begin::Event description-->
                    <div
                      class="fs-6"
                      data-kt-calendar="event_description"
                    ></div>
                    <!--end::Event description-->
                  </div>
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="d-flex align-items-center mb-2">
                  <!--begin::Icon-->
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs050.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <circle
                        fill="#000000"
                        cx="12"
                        cy="12"
                        r="8"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--end::Icon-->
                  <!--begin::Event start date/time-->
                  <div class="fs-6">
                    <span class="fw-bolder">Starts</span>
                    <span data-kt-calendar="event_start_date"></span>
                  </div>
                  <!--end::Event start date/time-->
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="d-flex align-items-center mb-9">
                  <!--begin::Icon-->
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs050.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <circle
                        fill="#000000"
                        cx="12"
                        cy="12"
                        r="8"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--end::Icon-->
                  <!--begin::Event end date/time-->
                  <div class="fs-6">
                    <span class="fw-bolder">Ends</span>
                    <span data-kt-calendar="event_end_date"></span>
                  </div>
                  <!--end::Event end date/time-->
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="d-flex align-items-center">
                  <!--begin::Icon-->
                  <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-muted me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                        fill="black"
                      />
                      <path
                        d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--end::Icon-->
                  <!--begin::Event location-->
                  <div
                    class="fs-6"
                    data-kt-calendar="event_location"
                  ></div>
                  <!--end::Event location-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Modal body-->
            </div>
          </div>
        </div>
        <!--end::Modal - New Product-->
        <!--end::Modals-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Main old-->
    <!--begin::Footer-->
    <div
      class="footer py-4 d-flex flex-column flex-md-row align-items-center justify-content-between"
      id="kt_footer"
    >
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="text-white opacity-75 fw-bold me-1">2023©</span>
        <a
          href="https://kiwanotalent.com"
          target="_blank"
          class="text-white text-hover-primary opacity-75"
          >Kiwano</a
        >
      </div>
      <!--end::Copyright-->
						<!--begin::Menu-->
						<ul class="menu menu-white menu-hover-primary fw-bold order-1 opacity-75">
							<li class="menu-item">
								<a href="https://kiwanotalent.com" target="_blank" class="menu-link px-2">About</a>
							</li>

						</ul>
						<!--end::Menu-->
    </div>
    <!--end::Footer-->
  </div>

</template>

<script>
import Toolbar from "../components/Toolbar-Component.vue";
import SmallCard from "./Cards/SmallCard.vue";
import MediumCard from "./Cards/MediumCard.vue";
import IdCard from "./Cards/IdCard.vue";
import Tagify from "@yaireo/tagify";
import VueApexCharts from "vue3-apexcharts";
import { attachProfileToProjectExternal } from "../js/profiledetails";
import { unsubtoprofileprojectbindings } from "../js/getdata";

export default {
  methods: {
    attachProfileToProject(profileid, project) {
      //located in profiledetails.js
      attachProfileToProjectExternal(profileid, project);
    },
  },

  computed: {
    myActiveProjects() {
      const myProjects = this.$store.state.currentMyProjectList;

      const results = myProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    teamActiveProjects() {
      const teamProjects = this.$store.state.currentTeamProjectList;

      const results = teamProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    sortedProfileProjectBindings() {
      const allBindings = this.$store.state.currentProfileProjectBindings;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      return allBindings.sort(compare);
    },
    sortedProfileActivityLogs() {
      const logs = this.$store.state.currentProfileActivityLogs;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      return logs.sort(compare);
    },
  },

  data() {
    return {
      rating: 50,
      series: [75],
      chartOptions: {
        chart: {
          height: 100,
          type: "radialBar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: false,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: false,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: 13,
                show: true,
                color: "#888",
                fontSize: "10px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                offsetY: -15,
                color: "#111",
                fontSize: "18px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["%"],
      },

      //tagify test data
      mcuHeros: [
        { value: "ironman", code: "im" },
        { value: "antman", code: "am" },
        { value: "captain america", code: "ca" },
        { value: "thor", code: "th" },
        { value: "spiderman", code: "sm" },
      ],

      tags: [],
    };
  },
  components: {
    Toolbar,
    apexchart: VueApexCharts,
    SmallCard,
    MediumCard,
    IdCard,
  },
  mounted() {
    //this.tags.push('VIP')
    /*
for(let i = 0 ; i < this.$store.state.currentProfileDetails.taglist.length ; i++) {
	this.tags.push(this.$store.state.currentProfileDetails.taglist[i])
}
*/
    /*
    var input = document.getElementById("onlyMarvel");
    var tagify = new Tagify(input, {
      whitelist: this.$store.state.profileTagNameList,
      enforceWhitelist: true,
      dropdown: {
        maxItems: 20, // <- mixumum allowed rendered suggestions
        classname: "tagify__inline__suggestions", // <- custom classname for this dropdown, so it could be targeted
        enabled: 0, // <- show suggestions on focus
        closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
      },
    });
    tagify.on("add", function (e) {
      console.log(e.detail.data);
    });
    */
  },
};
</script>

<style>
</style>