<template>
    <div
    class="bg-light bg-hover-secondary border border-0 rounded p-3"
    v-if="$store.state.currentRoute == '/projects' && $store.state.currentProjectDetails.notes"
    data-bs-toggle="modal"
    data-bs-target="#addprojectorclientnotes"
  >
    <div v-html="$store.state.currentProjectDetails.notes.length < 100
                  ? $store.state.currentProjectDetails.notes
                  : $store.state.currentProjectDetails.notes.substring(
                      0,
                      100
                    ) + '...'"></div>

                    
  </div>

<CardAlert
    v-if="$store.state.currentRoute == '/projects' && !$store.state.currentProjectDetails.notes"
      alerttype="info"
      title="No notes yet"
      text="Click the button above to add a note."
      style="margin-top: -22px;"
    ></CardAlert>



  <div
    class="bg-light bg-hover-secondary border border-0 rounded p-3"
    v-if="$store.state.currentRoute == '/clients' && $store.state.currentClientDetails.notes"
    data-bs-toggle="modal"
    data-bs-target="#addprojectorclientnotes"
  >
    <div v-html="$store.state.currentClientDetails.notes.length < 100
                  ? $store.state.currentClientDetails.notes
                  : $store.state.currentClientDetails.notes.substring(
                      0,
                      100
                    ) + '...'"></div>

  </div>
<CardAlert
    v-if="$store.state.currentRoute == '/clients' && !$store.state.currentClientDetails.notes"
      alerttype="info"
      title="No notes yet"
      text="Click the button above to add a note."
      style="margin-top: -22px;"
    ></CardAlert>
</template>

<script>

import CardAlert from "../../CardAlerts/CardAlert.vue";



export default {

components: {
    CardAlert
}

};
</script>

<style>
</style>