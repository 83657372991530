<template>
  <div class="d-flex" style="margin-top: -20px;">
    
    <div class="ms-auto">
        <a class="btn btn-light-primary btn-sm mb-6"
        :href="$store.state.fileToShow.url"
        target="_blank"  
        >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/arrows/arr091.svg-->
<span class="svg-icon svg-icon-muted svg-icon-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" width="12" height="2" rx="1" transform="matrix(0 -1 -1 0 12.75 19.75)" fill="currentColor"/>
<path d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z" fill="currentColor"/>
<path opacity="0.3" d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
            Download file

        </a>
    </div>
    
    </div>
  <iframe v-if="$store.state.fileToShow && $store.state.fileToShow.type == 'pdf'" width="700" height="800" :src="$store.state.fileToShow.url+'#toolbar=0'" frameborder="0"></iframe>
  <iframe v-if="false && $store.state.fileToShow.type != 'pdf' && loadoffice"  :src="'https://view.officeapps.live.com/op/embed.aspx?src='+$store.state.fileToShow.url" width="700" height="800" frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
  <div v-if="$store.state.fileToShow.type != 'pdf'">
    
      <CardAlert
    alerttype="info"
    title="Preview unavailable"
    text="Unfortunately, you can only preview PDF files directly in the app. To view the file, click the Download button above."
  >
  </CardAlert>
  </div>
</template>


<script>

import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
    components: {
      CardAlert
    },
    data() {
      return {
        loadoffice: false,
      }
    },
    mounted() {

      //weirdly this fixes the "loading forever" bug where the page was loading for at least a minute on page load.
      setTimeout(() => {
      this.loadoffice = true;
        
      }, 5000);
    }
}
</script>

<style>

</style>