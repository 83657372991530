<template>
<div class="row">
    <div class="col-6">
        <h2 class="py-5">
            Details
        </h2>
  <div class="border border-secondary p-3 rounded">
    <div class="pb-5 fs-6">
      <!--begin::Details item-->
      <div class="fw-bold mt-5">Name</div>
      <div class="text-gray-600">
        {{ $store.state.backofficeUserDetails.firstname }}
        {{ $store.state.backofficeUserDetails.lastname }}
      </div>
      <!--begin::Details item-->
      <!--begin::Details item-->
      <div class="fw-bold mt-5">Email</div>
      <div class="text-gray-600">
        <a href="#" class="text-gray-600 text-hover-primary">{{
          $store.state.backofficeUserDetails.email
        }}</a>
      </div>
      <!--begin::Details item-->
      <!--begin::Details item-->
      <div class="fw-bold mt-5">Team</div>
      <div class="text-gray-600">
        {{ $store.state.backofficeUserDetails.teamname }}
      </div>
      <!--begin::Details item-->
      <!--begin::Details item-->
      <div class="fw-bold mt-5">Team id</div>
      <div class="text-gray-600">
        {{ $store.state.backofficeUserDetails.teamid }}
      </div>
      <!--begin::Details item-->
      <!--begin::Details item-->
      <div class="fw-bold mt-5">User ID</div>
      <div class="text-gray-600">
        {{ $store.state.backofficeUserDetails.userid }}
      </div>
      <!--begin::Details item-->
      <!--begin::Details item-->
      <div class="fw-bold mt-5">User "Firestore doc" id</div>
      <div class="text-gray-600">
        {{ $store.state.backofficeUserDetails.id }}
      </div>
      <!--begin::Details item-->
            <!--begin::Details item-->
      <div class="fw-bold mt-5" :class="{'text-danger': !$store.state.backofficeUserDetails.algoliasearchkey}">User Algolia Search Key</div>
      <div class="text-gray-600">
        {{ $store.state.backofficeUserDetails.algoliasearchkey }}
      </div>
      <!--begin::Details item-->
      <!--begin::Details item-->
      <div class="fw-bold mt-5 mb-4">Email integration</div>
        <div
          class="text-gray-600"
          v-if="$store.state.backofficeUserDetails.nylasaccountid"
        >Email connected ! 
        </div>

        <div class="text-gray-600" v-if="!$store.state.backofficeUserDetails.nylasaccountid">
            No email connected yet

        </div>

      

      <!--begin::Details item-->
    </div>
  </div>
    </div>
    <div class="col-6">
        <h2 class="py-5">
            Recent activity
        </h2>
  <div class="border border-secondary p-3 rounded">
    <div class="py-3" v-for="log in $store.state.backofficeUserDetails.logs" :key="log.id">
        {{ new Date(log.timestamp).toLocaleString() }}
        <br>
        Type of update: {{ log.typeofupdate }}
        <br>
        Type of data: {{ log.typeofdata }}
        <br>
        <span v-if="log.parentobject">
        Parent object id: {{ log.parentobject.id }}
        </span>
        <br>
        <span v-if="log.typeofdata == 'profile' && log.parentobject">
       Name: {{ log.parentobject.firstname }} {{ log.parentobject.lastname }}
        </span>
    </div>
  </div>
    </div>
</div>

</template>

<script>


export default {

};
</script>

<style>
</style>