<template>
  <div
    class="toolbar d-flex py-5 gap-2"
    id="kt_toolbar"
    :class="
      $store.state.currentRoute === '/clients' ||
      $store.state.currentRoute === '/dashboard'
        ? 'py-8'
        : 'py-5'
    "
  >
    <!--begin::Page title section-->
    <div
      v-if="
        ($store.state.showingProfilesAs == 'profileDetails' &&
          ($store.state.currentRoute == '/profiles' ||
            $store.state.currentRoute == '/')) ||
        ($store.state.showingProjectsAs == 'projectDetails' &&
          $store.state.currentRoute == '/projects') ||
        $store.state.currentRoute == '/clients' ||
        $store.state.currentRoute == '/dashboard'
      "
      class="page-title d-flex flex-column align-items-start me-3 gap-1"
    >
      <!--begin::Title-->
      <h1
        class="d-flex align-items-center fw-bolder m-0 fs-3 animate__animated"
      >
        <!--begin: back button Projects-->
        <button
          class="btn btn-sm btn-light-primary me-4"
          @click="backToProjectList"
          v-if="
            $store.state.currentRoute == '/projects' &&
            $store.state.showingProjectsAs == 'projectDetails'
          "
        >
          <!--begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr002.svg-->
          <span
            style="cursor: pointer"
            @click="backToProjectList"
            class="svg-icon svg-icon-primary svg-icon-4 me-2 pointer"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z"
                fill="black"
              /></svg
          ></span>
          <!--end::Svg Icon-->
          Project list
        </button>
        <!--end: back button Projects-->

        <!--begin: back button Profiles-->
        <button
          class="btn btn-sm btn-light-primary me-4"
          @click="backToProfileList"
          v-if="
            $store.state.showingProfilesAs == 'profileDetails' &&
            ($store.state.currentRoute == '/profiles' ||
              $store.state.currentRoute == '/')
          "
        >
          <!--begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr002.svg-->
          <span
            @click="backToProfileList"
            class="svg-icon svg-icon-primary svg-icon-4 me-2 custompointer"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z"
                fill="black"
              /></svg
          ></span>
          <!--end::Svg Icon-->
          Profile list
        </button>
        <!--end: back button Profiles-->

        <!--begin: back button Client-->
        <button
          class="btn btn-sm btn-light-primary me-4"
          @click="backToClientList"
          v-if="
            $store.state.currentRoute == '/clients' &&
            $store.state.showingClientsAs == 'clientDetails'
          "
        >
          <!--begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr002.svg-->
          <span
            style="cursor: pointer"
            @click="backToClientList"
            class="svg-icon svg-icon-primary svg-icon-4 me-2 pointer"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z"
                fill="black"
              /></svg
          ></span>
          Client list
          <!--end::Svg Icon-->
        </button>
        <!--end: back button Client-->

        <!--see ProfileList.vue to change how this title is assigned depending on showingProfilesAs property in Vuex-->
        {{ title }}
        <!--begin::Separator
							<span v-if="$store.state.currentRoute === '/' && $store.state.showProjectProfiles" class="h-20px border-gray-400 border-start mx-3"></span>
							end::Separator-->

        <!--begin::Description in Profiledetails-->

        <!--end::Description-->
      </h1>
      <!--end::Title (General title: (recent) Profiles, (recent) Projects...-->
    </div>
    <!--end::Page title section-->

    <!--begin: Change profile view-->
    <div
      v-if="
        ($store.state.showingProfilesAs == 'recentProfiles' &&
          ($store.state.currentRoute == '/profiles' ||
            $store.state.currentRoute == '/')) ||
        ($store.state.showingProfilesAs == 'projectProfiles' &&
          ($store.state.currentRoute == '/profiles' ||
            $store.state.currentRoute == '/')) ||
        ($store.state.showingProfilesAs == 'searchResults' &&
          ($store.state.currentRoute == '/profiles' ||
            $store.state.currentRoute == '/'))
      "
    >
      <button
        class="ms-4 btn btn-sm btn-light border border-3 border-dashed border-primary d-flex"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        id="changeProfileViewButton"
      >
        <i
          class="bi bi-eye fs-2"
          v-if="$store.state.showingProfilesAs != 'projectProfiles'"
        ></i>
        {{ title }}
        <span
          class="badge badge-circle badge-light-primary ms-2"
          v-if="
            ($store.state.showingProfilesAs == 'recentProfiles' ||
              $store.state.showingProfilesAs == 'searchResults') &&
            $store.state.currentProfileList.length > 0 &&
            ($store.state.currentRoute == '/profiles' ||
              $store.state.currentRoute == '/')
          "
          >{{ $store.state.currentProfileList.length }}</span
        >
        <span
          class="badge badge-circle badge-light-primary ms-2"
          v-if="
            ($store.state.showingProfilesAs == 'recentProfiles' ||
              $store.state.showingProfilesAs == 'searchResults') &&
            $store.state.currentProfileList.length == 0 &&
            ($store.state.currentRoute == '/profiles' ||
              $store.state.currentRoute == '/')
          "
        ></span>

        <!--begin::Title (Filtered title: (project) Profiles...-->
        <div
          class="d-flex text-muted fw-bold text-muted d-block fs-7"
          style="margin-bottom: -5px; margin-left: -13px"
          v-if="
            $store.state.showingProfilesAs == 'projectProfiles' &&
            ($store.state.currentRoute == '/profiles' ||
              $store.state.currentRoute == '/')
          "
        >
          <div
            class="d-flex align-items-start mb-1 ps-3 pointer latestprofilesitem pe-5"
          >
            <!--begin::Symbol-->
            <div class="symbol symbol-35px me-4 companylogo">
              <img
                v-if="
                  $store.state.currentProjectDetailsForProfileList.clientobject
                    .clientlogourl
                "
                :src="
                  $store.state.currentProjectDetailsForProfileList.clientobject
                    .clientlogourl
                "
                class=""
                alt=""
              />
              <img
                v-else
                src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                class="h-40 align-self-center"
                alt=""
              />
            </div>
            <!--end::Symbol-->
            <!--begin::Title-->
            <div
              class="d-flex flex-column align-items-start"
              href="javascript:void(0)"
            >
              <div class="fs-7 text-gray-800 fw-bold">
                {{
                  $store.state.currentProjectDetailsForProfileList.positiontitle
                }}
              </div>
              <div class="fs-7 text-muted fw-bold" style="margin-top: -3px">
                {{
                  $store.state.currentProjectDetailsForProfileList.clientobject
                    .clientname
                }}
              </div>
            </div>
            <span
              class="badge badge-circle badge-light-primary ms-2"
              v-if="
                $store.state.showingProfilesAs == 'projectProfiles' &&
                $store.state.currentProfileList.length > 0 &&
                ($store.state.currentRoute === '/profiles' ||
                  $store.state.currentRoute == '/')
              "
              >{{ $store.state.currentProfileList.length }}</span
            >
            <span
              class="badge badge-circle badge-light-primary ms-2"
              v-if="
                $store.state.showingProfilesAs == 'projectProfiles' &&
                $store.state.currentProfileList.length == 0 &&
                ($store.state.currentRoute === '/profiles' ||
                  $store.state.currentRoute == '/')
              "
              >{{ $store.state.currentProfileList.length }}</span
            >
            <!--end::Title-->
          </div>
        </div>
        <!--end::Title (Filtered title: (project) Profiles...-->
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
        <span class="ms-2 svg-icon svg-icon-4 m-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
              fill="black"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </button>
      <ul
        class="dropdown-menu w-300px w-md-350px py-7 px-7 menu-sub menu-sub-dropdown"
        aria-labelledby="changeProfileViewButton"
        role="menu"
      >
        <!--begin::Items for Profilelist-->
        <div
          v-if="
            $store.state.currentRoute === '/profiles' ||
            $store.state.currentRoute === '/'
          "
          class="scroll-y mh-200px mh-lg-325px"
        >
          <li
            class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
            @click="getRecentProfiles()"
          >
            <i class="bi bi-arrow-counterclockwise me-3 fs-1"></i>
            Recent profiles
          </li>
          <li
            class="d-flex align-items-center mb-1 py-2 ps-3 latestprofilesitem"
            :class="{
              'text-muted': !$store.state.searchOn,
              'bg-hover-light': $store.state.searchOn,
              pointer: $store.state.searchOn,
            }"
            @click="getSearchResults()"
          >
            <span class="svg-icon svg-icon-muted svg-icon-1 me-3"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.3"
                  d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                  fill="currentColor"
                ></path></svg
            ></span>

            Search results
          </li>
          <hr style="border-top: 1px solid #ececec" class="ms-4 me-4" />
          <li
            class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/communication/com013.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-1 me-3"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                  fill="currentColor"
                />
                <rect
                  opacity="0.3"
                  x="8"
                  y="3"
                  width="8"
                  height="8"
                  rx="4"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
            My projects :
          </li>
          <li
            v-for="project in myActiveProjects"
            :key="project.id"
            @click="getProfilesForThisProject(project.id)"
            class="ms-6 d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
          >
            <!--begin::Symbol-->
            <div class="symbol symbol-40px me-4 companylogo">
              <img
                v-if="project.clientobject.clientlogourl"
                :src="project.clientobject.clientlogourl"
                class=""
                alt=""
              />
              <img
                v-else
                src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                style="width: 50px"
                alt=""
              />
            </div>
            <!--end::Symbol-->
            <!--begin::Title-->
            <a class="d-flex flex-column" href="javascript:void(0)">
              <span class="fs-6 text-gray-800 fw-bold">{{
                project.positiontitle
              }}</span>
              <span class="fs-7 text-muted fw-bold">{{
                project.clientobject.clientname
              }}</span>
            </a>
            <!--end::Title-->
          </li>
          <hr style="border-top: 1px solid #ececec" class="ms-4 me-4" />
          <li
            class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/communication/com014.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-1 me-3"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                  fill="currentColor"
                />
                <rect
                  opacity="0.3"
                  x="14"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                  fill="currentColor"
                />
                <path
                  d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                  fill="currentColor"
                />
                <rect
                  opacity="0.3"
                  x="6"
                  y="5"
                  width="6"
                  height="6"
                  rx="3"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
            Other projects from Team :
          </li>
          <li
            v-for="project in teamActiveProjects"
            :key="project.id"
            @click="getProfilesForThisProject(project.id)"
            class="ms-6 d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
          >
            <!--begin::Symbol-->
            <div class="symbol symbol-40px me-4 companylogo">
              <img
                v-if="project.clientobject.clientlogourl"
                :src="project.clientobject.clientlogourl"
                class=""
                alt=""
              />
              <img
                v-else
                src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                style="width: 50px"
                alt=""
              />
            </div>
            <!--end::Symbol-->
            <!--begin::Title-->
            <a class="d-flex flex-column" href="javascript:void(0)">
              <span class="fs-6 text-gray-800 fw-bold">{{
                project.positiontitle
              }}</span>
              <span class="fs-7 text-muted fw-bold">{{
                project.clientobject.clientname
              }}</span>
            </a>
            <!--end::Title-->
          </li>
        </div>
        <!--end:items for Profilelist-->

        <!--begin::Items for Projectlist-->
        <div
          v-if="$store.state.currentRoute === '/projects'"
          class="scroll-y mh-200px mh-lg-325px"
        >
          <li
            class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
            @click="getMyProjects()"
          >
            <span class="svg-icon svg-icon-muted svg-icon-1 me-3"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                  fill="currentColor"
                ></path>
                <rect
                  opacity="0.3"
                  x="8"
                  y="3"
                  width="8"
                  height="8"
                  rx="4"
                  fill="currentColor"
                ></rect></svg
            ></span>
            <div class="d-flex flex-column">
              <span>My projects</span>
              <span class="text-muted">(Created or joined by me)</span>
            </div>
          </li>
          <li
            class="d-flex align-items-center mb-1 py-2 ps-3 latestprofilesitem custompointer bg-hover-light"
            @click="getTeamProjects()"
          >
            <span class="svg-icon svg-icon-muted svg-icon-1 me-3"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                  fill="currentColor"
                ></path>
                <rect
                  opacity="0.3"
                  x="14"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                  fill="currentColor"
                ></rect>
                <path
                  d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                  fill="currentColor"
                ></path>
                <rect
                  opacity="0.3"
                  x="6"
                  y="5"
                  width="6"
                  height="6"
                  rx="3"
                  fill="currentColor"
                ></rect></svg
            ></span>
            <div class="d-flex flex-column">
              <span>Team projects</span>
              <span class="text-muted">(Other projects from Team)</span>
            </div>
          </li>
        </div>
        <!--end:items for Projectlist-->
      </ul>
    </div>
    <!--end: Change profile View-->



    <!--begin:Show projects options-->
    <div class="fs-8 border border-1 border-primary border-dashed rounded p-3 pe-5" v-if="
            $store.state.currentRoute === '/projects'  &&
          ($store.state.showingProjectsAs == 'myProjects' || $store.state.showingProjectsAs == 'teamProjects' || $store.state.showingProjectsAs == 'allProjects')
    ">
    <div class="d-flex" style="margin-top: -10px;">
 

      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
      <input class="form-check-input" type="radio"  v-model="$store.state.showingProjectsAs" id="radioMyProjects" value="myProjects" >
        <label class="form-check-label" for="radioMyProjects">
          My projects </label
        >
      </div>
      <div
      
        class="ms-3 form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
      <input class="form-check-input" type="radio" v-model="$store.state.showingProjectsAs" id="radioTeamProjects" value="teamProjects" >

        <label class="form-check-label" for="radioTeamProjects">
          Team projects
        </label>
      </div>
            <div
      
        class="ms-4 form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
      <input class="form-check-input" type="radio" v-model="$store.state.showingProjectsAs" id="radioAllProjects" value="allProjects" >

        <label class="form-check-label" for="radioAllProjects">
          All projects
        </label>
      </div>
      
  
    </div>
    </div>
    <!--end:Show projects options-->

    <!--begin::Actions-->
    <div class="d-flex ms-auto align-items-center py-1">
      <!--begin::Recommended profiles modal button-->
      <div
        class="me-3 animate__animated animate__fadeIn animate__delay-2s dropdown"
        v-if="showDropdown"
      >
        <button
          class="btn btn-sm btn-light-primary dropdown-toggle d-flex align-items-center"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          id="similarprofilesbtn"
          :data-kt-indicator="$store.state.fetchingAiSearchResultsProgress"
        >
          <div class="indicator-label">
            <span class="badge badge-sm badge-circle badge-primary me-2">
              {{ badgeCount }}
            </span>
            Similar profiles
          </div>
          <div class="indicator-progress">
            <span
              class="spinner-border spinner-border-sm align-middle me-2"
            ></span>
            Fetching profiles
          </div>
        </button>
        <ul
          class="dropdown-menu p-5 w-225px"
          aria-labelledby="similarprofilesbtn"
          role="menu"
        >
          <li>
            <button
              v-if="
                $store.state.currentProjectDetailsForProfileList
                  .aisearchresults &&
                typeof $store.state.currentProjectDetailsForProfileList
                  .aisearchresults != 'string'
              "
              class="btn btn-sm btn-light-primary mt-3"
              @click="fetchAISearchResultsForProject"
              :class="{
                disabled:
                  $store.state.currentProjectDetailsForProfileList
                    .aisearchresults == 'noprofilesfound' ||
                  $store.state.currentProjectDetailsForProfileList
                    .aisearchresults.length == 0,
              }"
            >
              From your database
              <span
                v-if="
                  $store.state.currentProjectDetailsForProfileList
                    .aisearchresults &&
                  typeof $store.state.currentProjectDetailsForProfileList
                    .aisearchresults != 'string'
                "
                class="badge badge-sm badge-circle badge-primary ms-2"
                >{{
                  $store.state.currentProjectDetailsForProfileList
                    .aisearchresults.length
                }}</span
              >
              <span
                v-if="
                  $store.state.currentProjectDetailsForProfileList
                    .aisearchresults == 'noprofilesfound'
                "
                class="badge badge-sm badge-circle badge-primary ms-2"
                >{{ 0 }}</span
              >
            </button>
            <div
              v-if="
                !$store.state.currentProjectDetailsForProfileList
                  .aisearchresults ||
                $store.state.currentProjectDetailsForProfileList.aisearchresults
                  .length == 0 ||
                $store.state.currentProjectDetailsForProfileList
                  .aisearchresults == 'noprofilesfound'
              "
              class="fs-8 text-muted ms-2 mt-2"
            >
              To get similar profiles from your own database, go to the
              "Projects" tab, click on your project, and upload a job
              description in the "Similar profiles" module. Kiwano will search
              for similar profiles in your database.
            </div>
            <div class="separator my-6"></div>
          </li>

          <li>
            <button
              class="btn btn-sm btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#recommendedProfiles"
            >
              From LinkedIn
              <span class="badge badge-sm badge-circle badge-primary ms-2">{{
                $store.state.recommendedProfiles.length
              }}</span>
            </button>
          </li>
        </ul>
      </div>
      <!--end::Recommended profiles modal button-->

      <!--begin::Progress report Btn-->
      <div
        v-if="
          ($store.state.currentRoute === '/profiles' ||
            $store.state.currentRoute === '/') &&
          ($store.state.showingProfilesAs == 'projectProfiles' ||
            $store.state.showingProfilesAs == 'recentProfiles')
        "
        class="dropdown me-2 tooltip"
      >
        <span
          v-if="$store.state.showingProfilesAs != 'projectProfiles'"
          class="tooltiptext"
        >
          You can only export a progress report when viewing profiles from a
          specific project. You are currently viewing Recent profiles. Click on
          the Profiles selector on the left side of this toolbar, and select a
          project.
        </span>
        <button
          class="btn btn-sm btn-flex btn-light-primary"
          :class="{
            disabled: $store.state.showingProfilesAs != 'projectProfiles',
          }"
          type="button"
          id="progressReportBtn"
          data-bs-toggle="modal"
          data-bs-target="#generatePR"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-26-051612/core/html/src/media/icons/duotune/arrows/arr091.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                width="12"
                height="2"
                rx="1"
                transform="matrix(0 -1 -1 0 12.75 19.75)"
                fill="currentColor"
              />
              <path
                d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z"
                fill="currentColor"
              />
              <path
                opacity="0.3"
                d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          Prog. report
        </button>
      </div>
      <!--end::Progress report Btn-->

      <!--begin::sort Profiles (v-ifs are inside the SortProfileList component)-->
      <SortProfileList></SortProfileList>
      <!--end::sort Profiles-->

      <!--begin::filter Profiles-->
      <div
        v-if="
          ($store.state.currentRoute === '/profiles' ||
            $store.state.currentRoute === '/') &&
          $store.state.showingProfilesAs == 'projectProfiles'
        "
        class="dropdown me-2"
      >
        <button
          class="btn btn-sm btn-flex btn-light"
          type="button"
          id="filterProfilesButton"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-5"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          <div
            v-if="$store.state.filterAppliedToProfiles"
            style="margin-top: -24px; margin-left: -5px"
          >
            <span
              class="bullet bullet-dot h-6px w-6px bg-primary me-2 mt-5"
            ></span>
          </div>
          Filter
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
          <span class="ms-2 svg-icon svg-icon-4 m-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                fill="black"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>

        <div
          class="dropdown-menu menu-sub menu-sub-dropdown"
          aria-labelledby="filterProfilesButton"
          id="filterProfilesDrodownMenu"
          role="menu"
          :class="{
            'w-600px': $store.state.showingProfilesAs == 'projectProfiles',
            'w-400px':
              $store.state.showingProfilesAs == 'recentProfiles' ||
              $store.state.showingProfilesAs == 'searchResults',
          }"
        >
          <SmallCard
            whichCard="filterprofilelist"
            title="Filter options"
            subTitle="none"
          ></SmallCard>
        </div>
      </div>
      <!--end::filter Profiles-->
      <div
        v-if="
          $store.state.showingProfilesAs == 'projectProfiles' &&
          ($store.state.currentRoute === '/profiles' ||
            $store.state.currentRoute === '/')
        "
      >
        <button
          class="btn btn-sm btn-primary"
          @click="goToProjectFromProfileList()"
        >
          Go to project
        </button>
      </div>
    </div>

    <!--begin: Actions for ProfileDetails.vue-->

    <div
      v-if="
        ($store.state.currentRoute === '/profiles' ||
          $store.state.currentRoute === '/') &&
        $store.state.showingProfilesAs == 'profileDetails'
      "
      :class="{
        'mt-3': taskCountForButtonIndicator > 0 || numberOfNewEmails > 0,
      }"
    >



          <!--begin: LinkedIn Chat-->


      <!--begin::Button-->

      <button
        class="btn me-4 btn-sm btn-light d-none"
        :class="{
          disabled:
            $store.state.currentProfileDetails.candidateorclient == 'Client',
        }"
        id="kt_linkedinchat_toggle"
        @click="scrollDrawerToBottom(), getLiChat()"
      >
        <i class="fab fa-linkedin-in"></i>
        <span class="fs-9">Messages</span>
        <span
          v-if="numberOfNewEmails > 0"
          class="position-absolute top-0 start-100 translate-middle badge badge-sm badge-circle badge-primary"
          >{{ numberOfNewEmails }}</span
        >
      </button>
      <!--end::Button-->

      <!--end: LinkedIn Chat-->

    
      <!--begin: emails-->

      <!--begin::Button-->

      <button
        class="btn me-4 btn-sm btn-light position-relative"
        :class="{
          disabled:
            $store.state.currentProfileDetails.candidateorclient == 'Client',
        }"
        id="emailbuttondropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-envelope"></i>
        <span class="fs-9">Emails</span>
        <span
          v-if="numberOfNewEmails > 0"
          class="position-absolute top-0 start-100 translate-middle badge badge-sm badge-circle badge-primary"
          >{{ numberOfNewEmails }}</span
        >
      </button>
      <!--end::Button-->

      <!--begin Dropdown menu-->
      <div
        class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px dropdownFadeIn"
        aria-labelledby="emailbuttondropdown"
        role="menu"
      >
        <SmallCard whichCard="emails" title="Emails"></SmallCard>
      </div>
      <!--end Dropdown menu-->
      <!--end: emails-->

      <!--begin: Tasks-->
      <!--begin::Button-->
      <button
        class="btn btn-sm btn-light me-4 position-relative"
        id="profiletasksdropdownbutton"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
      >
        <i class="bi bi-list-task"> </i>
        <span class="fs-9">Tasks</span>
        <span
          v-if="taskCountForButtonIndicator > 0"
          class="position-absolute top-0 start-100 translate-middle badge badge-sm badge-circle badge-primary"
          >{{ taskCountForButtonIndicator }}</span
        >
      </button>
      <!--end::Button-->

      <!--begin: Dropdown menu-->
      <div
        class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px dropdownFadeIn"
        aria-labelledby="profiletasksdropdownbutton"
        role="menu"
      >
        <SmallCard whichCard="profiletasks" title="Tasks"></SmallCard>
      </div>
      <!--end: Dropdown menu-->

      <!--end: Tasks-->

      <button
        class="btn btn-sm btn-icon btn-light fs-9"
        id="moretoolsbuttoninprofiledetails"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        style="border-radius: 10px"
      >
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen052.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-2"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="rotate(90 12 12)">
              <rect
                x="10"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
              <rect
                x="17"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
              <rect
                x="3"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </button>

      <div
        class="dropdown-menu menu-sub menu-sub-dropdown dropdownFadeIn p-5"
        aria-labelledby="moretoolsbuttoninprofiledetails"
        role="menu"
      >
        <div class="d-flex flex-column">
          <!--begin: Timeline-->
          <!--begin::Button-->
          <button
            class="btn btn-sm btn-light me-3 w-150px mb-3"
            id="profiletimelinedropdownbutton"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <i class="bi bi-arrow-counterclockwise me-1"></i>
            <span class="fs-9">Timeline</span>
          </button>
          <!--end::Button-->
          <!--begin: Dropdown menu-->
          <div
            class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px dropdownFadeIn"
            aria-labelledby="profiletimelinedropdownbutton"
            role="menu"
          >
            <SmallCard whichCard="profiletimeline" title="Timeline"></SmallCard>
          </div>
          <!--end: Dropdown menu-->
          <!--end: Timeline-->

          <!--begin: Meetings-->
          <!--begin::Button-->
          <a
            class="btn btn-sm btn-light me-3 w-150px mb-3"
            id="profilemeetingsdropdown"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
            ><i class="bi bi-calendar2-event me-1"></i>
            <span class="fs-9">Meetings</span>
          </a>
          <!--end::Button-->
          <!--begin: Dropdown menu-->
          <div
            class="dropdown-menu menu-sub menu-sub-dropdown w-150px w-md-150px dropdownFadeIn"
            aria-labelledby="profilemeetingsdropdown"
            role="menu"
          >
            <div class="p-8">Coming soon</div>
          </div>
          <!--end: Dropdown menu-->
          <!--end: Meetings-->

          <!--begin: Settings or Options?-->
          <!--begin::Button-->
          <button
            class="btn btn-sm btn-light me-3 w-150px mb-3"
            id="profilesettingsdropdown"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <i class="bi bi-gear me-1"></i>
            <span class="fs-9">Settings</span>
          </button>
          <!--end::Button-->
          <!--begin: Dropdown menu-->
          <div
            class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px dropdownFadeIn"
            aria-labelledby="profilesettingsdropdown"
            role="menu"
          >
            <SmallCard whichCard="profileSettings" title="Options"></SmallCard>
          </div>
          <!--end: Dropdown menu-->
          <!--end: Settings or Options?-->

          <!--begin: ChetGPT-->
          <button
            class="btn btn-light me-2 w-150px mb-3"
            data-bs-toggle="modal"
            data-bs-target="#generatemessage"
            @click="getProjectOfLatestBinding()"
          >
            <img
              style="width: 30px; margin-left: -10px"
              src="https://1000logos.net/wp-content/uploads/2023/02/ChatGPT-Logo.png"
              alt=""
            />
            <span class="fs-9">Outreach AI</span>
          </button>

          <!--end: ChatGPT-->

          <!--begin: Delete button-->
          <button
            class="btn btn-light-danger w-150px fs-9"
            @click="deleteProfile()"
          >
            <i class="bi bi-trash"></i>
            Delete profile
          </button>

          <!--end: Delete button-->
        </div>
      </div>
    </div>

    <!--end: Actions for ProfileDetails.vue-->

    <!--begin: Actions for ClientDetails.vue-->

    <div
      v-if="
        $store.state.currentRoute === '/clients' &&
        $store.state.showingClientsAs == 'clientDetails'
      "
    >
      <!--begin: Delete button-->
      <button class="btn btn-light-danger w-150px fs-9" @click="deleteClient()">
        <i class="bi bi-trash"></i>
        Delete Client
      </button>
    </div>

    <!--end: Delete button-->

    <!--begin: Actions for ClientDetails.vue-->

    <!--begin: Actions for ProjectDetails.vue-->

    <div
      v-if="
        $store.state.currentRoute === '/projects' &&
        $store.state.showingProjectsAs == 'projectDetails'
      "
    >
      <button
        class="btn btn-sm btn-primary me-3"
        @click="
          getProfilesFromProjectTab($store.state.currentProjectDetails.id)
        "
      >
        Go to profiles
      </button>

      <!--begin:More tools (project)-->
      <button
        class="btn btn-sm btn-icon btn-light fs-9"
        id="moretoolsbuttoninprojectdetails"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        style="border-radius: 10px"
      >
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen052.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-2"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="rotate(90 12 12)">
              <rect
                x="10"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
              <rect
                x="17"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
              <rect
                x="3"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </button>
      <div
        class="dropdown-menu dropdown-menu-start menu-sub menu-sub-dropdown dropdownFadeIn p-5"
        aria-labelledby="moretoolsbuttoninprojectdetails"
        role="menu"
      >
        <!--begin: Delete button-->
        <button
          class="btn btn-light-danger w-150px fs-9"
          @click="deleteProject()"
        >
          <i class="bi bi-trash"></i>
          Delete Project
        </button>
        <div v-if="$store.state.userData.issuperadmin == true" class="mt-3">
          <span v-if="$store.state.currentProjectDetails.linkedproject"
            >Linked project:
            {{ $store.state.currentProjectDetails.linkedproject }}</span
          >
          <br />
          <input v-model="linkedprojectid" type="text" />
          <button @click="addLinkedProject()">Save</button>
        </div>
      </div>

      <!--end: Delete button-->
    </div>
    <!--end:More tools (project)-->

    <!--begin: Actions for ProjectDetails.vue-->

    <!--end::Actions-->
  </div>
</template>

<script>
import axios from "axios";

import {
  getProfilesForThisProjectExternal,
  getRecentProfilesExternal,
  getSingleProjectExternal,
  getSearchResultsExternal,
  fetchAndShowAISearchResultsForProjectExternal,
} from "../js/getdata";
import { applyMatchExternal } from "../js/profilelist";
import "animate.css";
import { deleteProfileExternal } from "../js/profiledetails";
import { deleteClientExternal } from "../js/clientdetails";
import {
  deleteProjectExternal,
  addLinkedProjectExternal,
} from "../js/projectdetails";
import { goToDetailedProjectExternal } from "../js/navigation";

import SmallCard from "./Cards/SmallCard.vue";

import SortProfileList from "./Cards/ProfileCardContents/SortProfileList.vue";

export default {
  components: {
    SmallCard,
    SortProfileList,
  },
  props: ["title"],
  data() {
    return {
      isSticky: false,
      linkedprojectid: "",
      projectstoshow: "myprojects"
    };
  },
  /*inject: ["isSticky"], Commented out because it trows an error. Seems to work without it.*/

  computed: {
    myActiveProjects() {
      //filter out duplicate projects (for some reason the projects sometimes appear twice or multiple times)
      const uniqueProjects = this.$store.state.currentMyProjectList.filter(
        (project, index, self) => {
          // Check for the first occurrence of the project with the same id
          const firstIndex = self.findIndex((p) => p.id === project.id);

          // Return true if the current index is the same as the first occurrence index
          return index === firstIndex;
        }
      );

      const projects = uniqueProjects;

      const results = projects
        .filter((obj) => {
          return obj.status === "Active";
        })
        .sort((a, b) => {
          return b.startedat - a.startedat;
        });

      return results;
    },
    teamActiveProjects() {
      //filter out duplicate projects (for some reason the projects sometimes appear twice or multiple times)
      const uniqueProjects = this.$store.state.currentTeamProjectList.filter(
        (project, index, self) => {
          // Check for the first occurrence of the project with the same id
          const firstIndex = self.findIndex((p) => p.id === project.id);

          // Return true if the current index is the same as the first occurrence index
          return index === firstIndex;
        }
      );

      const projects = uniqueProjects;

      const results = projects
        .filter((obj) => {
          return obj.status === "Active";
        })
        .sort((a, b) => {
          return b.startedat - a.startedat;
        });

      return results;
    },
    numberOfNewEmails() {
      const emails = this.$store.state.emailsin;

      const reformatedemails = emails.filter((obj) => {
        return typeof obj.timestamp == "number";
      });

      const currentprofileemails = reformatedemails.filter((obj) => {
        return obj.emailfrom === this.$store.state.currentProfileDetails.email;
      });

      const newemails = currentprofileemails.filter((obj) => {
        return obj.readstatus === "unread";
      });

      const newemailscount = newemails.length;
      return newemailscount;
    },
    taskCountForButtonIndicator() {
      const originalTaskList = this.$store.state.currentProfileTasks;
      const results = originalTaskList.filter((obj) => {
        return (
          obj.assigneduserobj.userid === this.$store.state.userData.userid &&
          !obj.completed
        );
      });
      return results.length;
    },
    showDropdown() {
      return (
        this.$store.state.showingProfilesAs === "projectProfiles" &&
        (this.$store.state.recommendedProfiles.length > 0 ||
          (Array.isArray(
            this.$store.state.currentProjectDetailsForProfileList
              .aisearchresults
          ) &&
            this.$store.state.currentProjectDetailsForProfileList
              .aisearchresults.length > 0)) &&
        this.$store.state.currentRoute === "/profiles"
      );
    },
    badgeCount() {
      return (
        this.$store.state.recommendedProfiles.length +
        (Array.isArray(
          this.$store.state.currentProjectDetailsForProfileList.aisearchresults
        )
          ? this.$store.state.currentProjectDetailsForProfileList
              .aisearchresults.length
          : 0)
      );
    },

    //used to check if there are at least 3 In progresss in order to make Match prediction available
    /*
    numberofpotentialcandidates() {


      //get all statuses that have In progress category
      let potentialCandidateStatuses = [];
      for (let i = 0 ; i < this.$store.state.profileStatusList.length ; i++) {
        if(this.$store.state.profileStatusList[i].category == "In progress") {
          potentialCandidateStatuses.push(this.$store.state.profileStatusList[i].id)
        }
      }


    let potentialCandidates = this.$store.state.currentProfileList.filter(element => {
        return potentialCandidateStatuses.includes(element.statusid);
    });


      if(potentialCandidates.length > 2) {
        
        //analysis of the first 3 In progresss:


        let yearsInCompanyValues = [];
        let careerLengthValues = [];

        for (let i = 0 ; i < potentialCandidates.length ; i++) {              
              careerLengthValues.push(potentialCandidates[i].experiencemetadata.careerYears)
              yearsInCompanyValues.push(potentialCandidates[i].experiencemetadata.avrYearsInComp)
        }

        let averageCareerLength = careerLengthValues.reduce((a, b) => a + b, 0) / careerLengthValues.length
        let averageYearsInCompany = yearsInCompanyValues.reduce((a, b) => a + b, 0) / yearsInCompanyValues.length

        

        console.log(averageYearsInCompany)
        console.log(averageCareerLength)


        let matchingProfiles = this.$store.state.currentProfileList.filter(profile => profile.experiencemetadata.careerYears > averageCareerLength);

      matchingProfiles.forEach(profile => {
        alert(profile.firstname)
        // Execute the applyMatch function with the id of the current profile
        applyMatchExternal(profile);
      });

      }

     
    return potentialCandidates.length
        






      //let numberOfPotentialCandidates = 0;
      //for (let i = 0 ; i < potentialCandidateStatuses.length ; i++) {
      //  numberOfPotentialCandidates = numberOfPotentialCandidates + this.$store.state.currentProfileList.filter((obj) => obj.statusid === potentialCandidateStatuses[i]).length;
      //}


 //return ?

      //this.$store.state.currentProfileList.filter((obj) => obj.id === id).length;

      

     
    }
    */
  },
  methods: {
    goToProjectFromProfileList() {
      goToDetailedProjectExternal(
        this.$store.state.currentProjectDetailsForProfileList.id
      );
      this.$router.push("/projects");
    },
    getProjectOfLatestBinding() {
      getSingleProjectExternal(
        this.$store.state.currentProfileProjectBindings[0].projectid
      );
      //also reset $store.state.generateProgressState to 'ready'
      this.$store.state.generateProgressState = "ready";
    },
    deleteProfile() {
      deleteProfileExternal(this.$store.state.currentProfileDetails.id);
    },
    deleteClient() {
      deleteClientExternal(this.$store.state.currentClientDetails.id);
    },
    deleteProject() {
      deleteProjectExternal(this.$store.state.currentProjectDetails);
    },
    backToProjectList() {
      this.$store.state.showingProjectsAs = "myProjects";
      this.$store.state.currentProjectDetails = false;
    },
    backToProfileList() {
      this.$store.state.showingProfilesAs =
        this.$store.state.profilesViewInCache;
      this.$store.state.hitBack = true;
    },
    backToClientList() {
      this.$store.state.showClientDetails = false;
      this.$store.state.showingClientsAs = "myClients";
    },
    goToProject(projectId) {
      alert(
        "this checks go to Project function in Toolbar component, projectID:" +
          projectId
      );
    },
    getProfilesForThisProject(projectid) {
      //in case the SelectAll checkbox was checked, uncheck it
      if (this.$store.state.currentProfileList.length > 0) {
        if (document.querySelector("#myElement") !== null) {
          document.getElementById("selectAllBtn").checked = false;
        }
      }

      //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
      this.$store.state.selectedProfiles = [];

      getProfilesForThisProjectExternal(projectid);
    },
    getRecentProfiles() {
      //in case the SelectAll checkbox was checked, uncheck it
      if (this.$store.state.currentProfileList.length > 0) {
        document.getElementById("selectAllBtn").checked = false;
      }

      //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
      this.$store.state.selectedProfiles = [];

      getRecentProfilesExternal();
    },
    getSearchResults() {
      //in case the SelectAll checkbox was checked, uncheck it
      if (this.$store.state.currentProfileList.length > 0) {
        document.getElementById("selectAllBtn").checked = false;
      }

      //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
      this.$store.state.selectedProfiles = [];

      getSearchResultsExternal();
    },
    say: function (message) {
      alert(message);
    },

    //the filter logic is in SmallCard.vue, not here, because it has a decicated component
    getMyProjects() {
            console.log(this.projectstoshow)

      this.$store.state.showingProjectsAs = "myProjects";
    },
    getTeamProjects() {
      console.log(this.projectstoshow)
      this.$store.state.showingProjectsAs = "teamProjects";
    },
        getAllProjects() {
      console.log(this.projectstoshow)
      this.$store.state.showingProjectsAs = "allProjects";
    },
    onButtonClick() {
      alert("button is clicked");
    },
    fetchAISearchResultsForProject() {
      fetchAndShowAISearchResultsForProjectExternal(
        this.$store.state.currentProjectDetailsForProfileList.id
      );
    },
    getProfilesFromProjectTab(projectid) {
      this.$router.push("/profiles");
      getProfilesForThisProjectExternal(projectid);
    },
    addLinkedProject() {
      addLinkedProjectExternal(
        this.$store.state.currentProjectDetails.id,
        this.linkedprojectid
      );
    },
    scrollDrawerToBottom() {
        this.$nextTick(() => {
    const messagesElement = document.getElementById('limessageselement');
    if (messagesElement) {
      messagesElement.scrollTop = messagesElement.scrollHeight;
    }
  });
    },
    async getLiChat() {

        let linkedinid;
        let linkedinurl;
        let linkedinrecruiterid;
        let profileid = this.$store.state.currentProfileDetails.id

            try {
        //Check if there is a LinkedIn ID already (ACo....)
        if(this.$store.state.currentProfileDetails.linkedinid) {
                  linkedinid = this.$store.state.currentProfileDetails.linkedinid
                  linkedinrecruiterid = this.$store.state.currentProfileDetails.linkedinrecruiterid

        } else {
                  linkedinid = "noIdYet"
                  linkedinrecruiterid = "noIdYet"
        }

        //Check if there is a LinkedIn profile attached to this profile
        if(this.$store.state.currentProfileDetails.linkedinurl) {
          linkedinurl = this.$store.state.currentProfileDetails.linkedinurl
        } else {
          linkedinurl = "noLinkedInProfile"
        }
        const response = await axios.get(`https://us-central1-kiwano-dev.cloudfunctions.net/getUnipileLiChatContent?param1=${encodeURIComponent(linkedinid)}&param2=${encodeURIComponent(linkedinrecruiterid)}&param3=${encodeURIComponent(linkedinurl)}&param4=${encodeURIComponent(profileid)}`);
        console.log(response.data);  // This will log the accounts to the console
        this.$store.state.lichatcontent = response.data
        console.log("here it is: " + this.$store.state.lichatcontent)
        this.scrollDrawerToBottom()
      } catch (error) {
        console.error('Failed to fetch chat content:', error);
      }
    }
  },

  mounted() {
    window.scrollTo(0, 0);
    if (this.$store.state.currentRoute === "/") {
    }
  },
};
</script>

<style></style>
