<template>
  <div
    class="app-main flex-column flex-row-fluid"
    id="kt_app_main"
    style="margin-top: -30px"
  >
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
      -
      <!--begin::Content-->
      <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-xxl">
          <!--begin::Stepper-->
          <div
            class="
              stepper stepper-pills stepper-column
              d-flex
              flex-column flex-xl-row flex-row-fluid
              gap-10
            "
            id="kt_create_account_stepper"
            data-kt-stepper="true"
          >
            <!--begin::Aside-->
            <div
              class="
                card
                d-flex
                justify-content-center justify-content-xl-start
                flex-row-auto
                w-100 w-xl-300px w-xxl-400px
              "
            >
              <!--begin::Wrapper-->
              <div class="card-body px-6 px-lg-10 px-xxl-15 py-20">
                <!--begin::Nav-->
                <div class="stepper-nav">
                  <!--begin::Step 1-->
                  <div
                    class="stepper-item"
                    :class="{
                      current:
                        activeComponent == 'Signup1' ||
                        activeComponent == 'Signup1ExistingCompany',
                    }"
                    data-kt-stepper-element="nav"
                  >
                    <!--begin::Wrapper-->
                    <div class="stepper-wrapper d-flex">
                      <!--begin::Icon-->
                      <div class="stepper-icon w-40px h-40px">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">1</span>
                      </div>
                      <!--end::Icon-->
                      <!--begin::Label-->
                      <div class="stepper-label">
                        <h3 class="stepper-title">Team</h3>
                        <div class="stepper-desc fw-semibold">
                          Create your team
                        </div>
                      </div>
                      <!--end::Label-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Line-->
                    <div class="stepper-line h-40px"></div>
                    <!--end::Line-->
                  </div>
                  <!--end::Step 1-->
                  <!--begin::Step 2-->
                  <div
                    class="stepper-item"
                    data-kt-stepper-element="nav"
                    :class="{ current: activeComponent == 'Signup2' }"
                  >
                    <!--begin::Wrapper-->
                    <div class="stepper-wrapper d-flex">
                      <!--begin::Icon-->
                      <div class="stepper-icon w-40px h-40px">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">2</span>
                      </div>
                      <!--end::Icon-->
                      <!--begin::Label-->
                      <div class="stepper-label">
                        <h3 class="stepper-title">Profile</h3>
                        <div class="stepper-desc fw-semibold">
                          Setup Your Profile Details
                        </div>
                      </div>
                      <!--end::Label-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Line-->
                    <div class="stepper-line h-40px"></div>
                    <!--end::Line-->
                  </div>
                  <!--end::Step 2-->
                  <!--begin::Step 4-->
                  <div
                    class="stepper-item"
                    data-kt-stepper-element="nav"
                    :class="{ current: activeComponent == 'Signup4' }"
                  >
                    <!--begin::Wrapper-->
                    <div class="stepper-wrapper d-flex">
                      <!--begin::Icon-->
                      <div class="stepper-icon w-40px h-40px">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">3</span>
                      </div>
                      <!--end::Icon-->
                      <!--begin::Label-->
                      <div class="stepper-label">
                        <h3 class="stepper-title">Done</h3>
                        <div class="stepper-desc fw-semibold">
                          Ready to go !
                        </div>
                      </div>
                      <!--end::Label-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Line-->
                    <div class="stepper-line h-40px"></div>
                    <!--end::Line-->
                  </div>
                  <!--end::Step 4-->
                  <!--begin::Step 5-->
                  <div
                    class="d-none stepper-item mark-completed"
                    data-kt-stepper-element="nav"
                  >
                    <!--begin::Wrapper-->
                    <div class="stepper-wrapper d-flex">
                      <!--begin::Icon-->
                      <div class="stepper-icon w-40px h-40px">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">5</span>
                      </div>
                      <!--end::Icon-->
                      <!--begin::Label-->
                      <div class="stepper-label">
                        <h3 class="stepper-title">Done !</h3>
                        <div class="stepper-desc fw-semibold">
                          Woah, here we are
                        </div>
                      </div>
                      <!--end::Label-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Step 5-->
                </div>
                <!--end::Nav-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--begin::Aside-->
            <!--begin::Form steps Content and buttons-->
            <div class="card d-flex flex-row-fluid flex-center">
              <!--begin::Form-->
              <div
                class="
                  card-body
                  py-20
                  w-100
                  mw-xl-700px
                  px-9
                  fv-plugins-bootstrap5 fv-plugins-framework
                "
                novalidate="novalidate"
                id="kt_create_account_form"
              >
                <!--begin::Step 1-->
                <div class="current" data-kt-stepper-element="content">
                  <!--begin::Wrapper-->
                  <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-10 pb-lg-15">
                      <!--begin::Titles-->
                      <h2
                        v-if="
                          activeComponent == 'Signup1' ||
                          activeComponent == 'Signup1ExistingCompany'
                        "
                        class="fw-bold d-flex align-items-center text-dark"
                      >
                        Team
                      </h2>
                      <h2
                        v-if="activeComponent == 'Signup2'"
                        class="fw-bold d-flex align-items-center text-dark"
                      >
                        Profile
                      </h2>
                      <h2
                        v-if="activeComponent == 'Signup3'"
                        class="fw-bold d-flex align-items-center text-dark"
                      >
                        Billing
                      </h2>
                      <h2
                        v-if="activeComponent == 'Signup4'"
                        class="fw-bold d-flex align-items-center text-dark"
                      >
                        Welcome !
                      </h2>
                      <!--end::Titles-->

                      <!--begin::Notice-->
                      <div
                        v-if="activeComponent == 'Signup1'"
                        class="text-muted fw-semibold fs-6"
                      >
                        Create your team
                      </div>
                      <!--end::Notice-->
                      <!--begin::Notice-->
                      <div
                        v-if="activeComponent == 'Signup1ExistingCompany'"
                        class="text-muted fw-semibold fs-6"
                      >
                        You are joining an existing team. Click Next to continue
                      </div>
                      <!--end::Notice-->
                      <!--begin::Notice-->
                      <div
                        v-if="activeComponent == 'Signup2'"
                        class="text-muted fw-semibold fs-6"
                      >
                        Setup your profile details
                      </div>
                      <!--end::Notice-->
                      <!--begin::Notice-->
                      <div
                        v-if="activeComponent == 'Signup3'"
                        class="text-muted fw-semibold fs-6"
                      >
                        Billing details and Payment Method
                      </div>
                      <!--end::Notice-->
                      <!--begin::Notice-->
                      <div
                        v-if="activeComponent == 'Signup4'"
                        class="text-muted fw-semibold fs-6"
                      ></div>
                      <!--end::Notice-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::form content steps-->

                    <div
                      v-if="activeComponent == 'Signup1'"
                      class="animate__animated animate__fadeIn"
                    >
                      <Signup1></Signup1>
                    </div>
                    <div
                      v-if="activeComponent == 'Signup1ExistingCompany'"
                      class="animate__animated animate__fadeIn"
                    >
                      <Signup1ExistingCompany
                        :companyName="joinedCompanyName"
                        :logoURL="joinedCompanyLogoURL"
                        :companyCity="joinedCompanyCity"
                        :companyCountry="joinedCompanyCountry"
                      ></Signup1ExistingCompany>
                    </div>
                    <div
                      class="animate__animated animate__fadeIn"
                      v-if="activeComponent == 'Signup2'"
                    >
                      <Signup2></Signup2>
                    </div>
                    <div
                      class="animate__animated animate__fadeIn"
                      v-if="activeComponent == 'Signup3'"
                    >
                      <Signup3></Signup3>
                    </div>
                    <div
                      class="animate__animated animate__fadeIn"
                      v-if="activeComponent == 'Signup4'"
                    >
                      <Signup4></Signup4>
                    </div>

                    <!--end::form content steps-->

                    <!--begin: footer bottons-->
                    <div class="d-flex mt-15">
                      <!--begin: back button-->
                      <div
                        v-if="
                          activeComponent != 'Signup1' &&
                          activeComponent != 'Signup1ExistingCompany'
                        "
                        class="text-end"
                      >
                        <button
                          class="btn btn-light-primary"
                          @click="goToPreviousStep()"
                        >
                          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr074.svg-->
                          <span
                            class="svg-icon svg-icon-muted svg-icon-2"
                            style="margin-left: -10px"
                            ><svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <!--end::Svg Icon-->
                          Back
                        </button>
                      </div>
                      <!--end: back button-->
                      <!--begin: next button-->
                      <div class="ms-auto" v-if="activeComponent != 'Signup4'">
                        <button class="btn btn-primary" @click="goToNextStep()">
                          Next
                          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr071.svg-->
                          <span
                            class="svg-icon svg-icon-muted svg-icon-2"
                            style="margin-right: -10px"
                            ><svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <!--end::Svg Icon-->
                        </button>
                      </div>
                      <!--end: next button-->
                    </div>
                    <!--end: footer buttons-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Step 1-->
              </div>
              <!--end::Form-->
            </div>
            <!--end::Form steps Content and buttons-->
          </div>
          <!--end::Stepper-->
        </div>
        <!--end::Content container-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
  </div>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../firebase/index";
import router from "../router/index";
import store from "../store/index";
import { signUpFunc } from "../js/auth";
import Signup1 from "./FormContents/Signup1.vue";
import Signup2 from "./FormContents/Signup2.vue";
import Signup3 from "./FormContents/Signup3.vue";
import Signup4 from "./FormContents/Signup4.vue";
import Signup1ExistingCompany from "./FormContents/Signup1ExistingCompany.vue";

export default {
  components: { Signup1, Signup2, Signup3, Signup4, Signup1ExistingCompany },
  data() {
    return {
      activeComponent: "Signup1",

      //if the user is joining an existing company, this data will be filled with the URL parameters included in the link received by user (signup invitation generated in Backoffice.vue)
      joinedCompanyName: "",
      joinedCompanyLogoURL: "",
      joinedCompanyCity: "",
      joinedCompanyCountry: "",
      neworexistingcompany: "",
      trialorfree: "",
      teamId: ""
      
    };
  },
  methods: {
    goToNextStep() {
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (this.activeComponent == "Signup1") {
        //reset FieldIssue for these inputs
        this.$store.state.signup_TeamNameFieldIssue = false;
        this.$store.state.signup_TeamCityFieldIssue = false;
        this.$store.state.signup_TeamCountryFieldIssue = false;

        //check for empty values
        let FieldIssue = false;

        if (this.$store.state.signup_TeamName == "") {
          this.$store.state.signup_TeamNameFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_TeamCity == "") {
          this.$store.state.signup_TeamCityFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_TeamCountry == "") {
          this.$store.state.signup_TeamCountryFieldIssue = true;
          FieldIssue = true;
        }


        if (FieldIssue) {
          alert("Please fill in the required fields");
          return;
        }

        //go to next step
        this.activeComponent = "Signup2";
      } else if (this.activeComponent == "Signup1ExistingCompany") {
        //go to next step
        this.activeComponent = "Signup2";
      } else if (this.activeComponent == "Signup2") {
        //reset FieldIssue for these inputs
        this.$store.state.signup_ProfileFirstNameFieldIssue = false;
        this.$store.state.signup_ProfileLastNameFieldIssue = false;
        this.$store.state.signup_ProfileEmailFieldIssue = false;
        this.$store.state.signup_ProfileConfirmEmailFieldIssue = false;
        this.$store.state.signup_ProfilePasswordFieldIssue = false;
        this.$store.state.signup_ProfileConfirmPasswordFieldIssue = false;
        this.$store.state.signup_ProfileMobileNumberFieldIssue = false;
        this.$store.state.signup_ProfileEmailsDontMatch = false;
        this.$store.state.signup_ProfilePwDontMatch = false;
        this.$store.state.signup_ProfilePwTooShort = true;

        //check for empty values and other issues in input
        let FieldIssue = false;

        if (this.$store.state.signup_ProfileFirstName == "") {
          this.$store.state.signup_ProfileFirstNameFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_ProfileLastName == "") {
          this.$store.state.signup_ProfileLastNameFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_ProfileEmail == "") {
          this.$store.state.signup_ProfileEmailFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_ProfileConfirmEmail == "") {
          this.$store.state.signup_ProfileConfirmEmailFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_ProfilePassword == "") {
          this.$store.state.signup_ProfilePasswordFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_ProfileConfirmPassword == "") {
          this.$store.state.signup_ProfileConfirmPasswordFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_ProfileMobileNumber == "") {
          this.$store.state.signup_ProfileMobileNumberFieldIssue = true;
          FieldIssue = true;
        }
        if (
          this.$store.state.signup_ProfileEmail !=
          this.$store.state.signup_ProfileConfirmEmail
        ) {
          this.$store.state.signup_ProfileEmailsDontMatch = true;
          FieldIssue = true;
        }
        if (
          this.$store.state.signup_ProfilePassword !=
          this.$store.state.signup_ProfileConfirmPassword
        ) {
          this.$store.state.signup_ProfilePwDontMatch = true;
          FieldIssue = true;
        }
        //PW strength is checked in Signup2.vue
        if (this.$store.state.passwordStrength < 3) {
          alert(this.$store.state.passwordStrength)
          this.$store.state.signup_ProfilePwTooShort = true;
          FieldIssue = true;
        }
    

        if (FieldIssue) {
          alert("Please fill out the required fields");
          return;
        }

        //signup user
        this.signUpMethod();
        console.log("will signup user");

        //go to next step
        this.activeComponent = "Signup4";
      }
    },
    goToPreviousStep() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.activeComponent == "Signup2") {
        if (this.joinedCompanyName) {
          this.activeComponent = "Signup1ExistingCompany";
        } else if (!this.joinedCompanyName) {
          this.activeComponent = "Signup1";
        }
      } else if (this.activeComponent == "Signup4") {
        this.activeComponent = "Signup2";
      }
    },
    signUpMethod() {
      //imported from jsfunction/auth.js

        console.log(this.neworexistingcompany)
        console.log(this.trialorfree)
        console.log(this.teamId)
        console.log(this.$store.state.signup_ProfileEmail)
        console.log(this.$store.state.signup_ProfilePassword)


      signUpFunc(
        this.neworexistingcompany,
        this.trialorfree,
        this.teamId,
        this.$store.state.signup_ProfileEmail,
        this.$store.state.signup_ProfilePassword
      );
    },
  },
  mounted() {
    //why is this here??
    store.state.currentRoutePath = this.$route.path;

    //get URL params if user is joining an existing company
    console.log(window.location.href);

    const pageURL = window.location.href;
    const URLArray = pageURL.split("?customquerystr?");
    const queryString = URLArray[1];
    const paramArray = queryString.split("&param");
    console.log("here is the array " + paramArray);
    this.neworexistingcompany = paramArray[0];
    this.trialorfree = paramArray[1];
    this.joinedCompanyName = paramArray[2];
    this.joinedCompanyLogoURL = paramArray[3];
    this.joinedCompanyCity = paramArray[4];
    this.joinedCompanyCountry = paramArray[5];
    this.teamId = paramArray[6]

    console.log(this.teamId)

    //convert special characters for Team Name
    this.joinedCompanyName = this.joinedCompanyName
      .replaceAll("%20", " ")
      .replaceAll("%C3%80", "À")
      .replaceAll("%C3%81", "Á")
      .replaceAll("%C3%82", "Â")
      .replaceAll("%C3%83", "Ã")
      .replaceAll("%C3%84", "Ä")
      .replaceAll("%C3%85", "Å")
      .replaceAll("%C3%86", "Æ")
      .replaceAll("%C3%87", "Ç")
      .replaceAll("%C3%88", "È")
      .replaceAll("%C3%89", "É")
      .replaceAll("%C3%8A", "Ê")
      .replaceAll("%C3%8B", "Ë")
      .replaceAll("%C3%8C", "Ì")
      .replaceAll("%C3%8D", "Í")
      .replaceAll("%C3%8E", "Î")
      .replaceAll("%C3%8F", "Ï")
      .replaceAll("%C3%90", "Ð")
      .replaceAll("%C3%91", "Ñ")
      .replaceAll("%C3%92", "Ò")
      .replaceAll("%C3%93", "Ó")
      .replaceAll("%C3%94", "Ô")
      .replaceAll("%C3%95", "Õ")
      .replaceAll("%C3%96", "Ö")
      .replaceAll("%C3%97", "×")
      .replaceAll("%C3%98", "Ø")
      .replaceAll("%C3%99", "Ù")
      .replaceAll("%C3%9A", "Ú")
      .replaceAll("%C3%9B", "Û")
      .replaceAll("%C3%9C", "Ü")
      .replaceAll("%C3%9D", "Ý")
      .replaceAll("%C3%9E", "Þ")
      .replaceAll("%C3%9F", "ß")
      .replaceAll("%C3%A0", "à")
      .replaceAll("%C3%A1", "á")
      .replaceAll("%C3%A2", "â")
      .replaceAll("%C3%A3", "ã")
      .replaceAll("%C3%A4", "ä")
      .replaceAll("%C3%A5", "å")
      .replaceAll("%C3%A6", "æ")
      .replaceAll("%C3%A7", "ç")
      .replaceAll("%C3%A8", "è")
      .replaceAll("%C3%A9", "é")
      .replaceAll("%C3%AA", "ê")
      .replaceAll("%C3%AB", "ë")
      .replaceAll("%C3%AC", "ì")
      .replaceAll("%C3%AD", "í")
      .replaceAll("%C3%AE", "î")
      .replaceAll("%C3%AF", "ï")
      .replaceAll("%C3%B0", "ð")
      .replaceAll("%C3%B1", "ñ")
      .replaceAll("%C3%B2", "ò")
      .replaceAll("%C3%B3", "ó")
      .replaceAll("%C3%B4", "ô")
      .replaceAll("%C3%B5", "õ")
      .replaceAll("%C3%B6", "ö")
      .replaceAll("%C3%B7", "÷")
      .replaceAll("%C3%B8", "ø")
      .replaceAll("%C3%B9", "ù")
      .replaceAll("%C3%BA", "ú")
      .replaceAll("%C3%BB", "û")
      .replaceAll("%C3%BC", "ü");

    this.joinedCompanyCity = this.joinedCompanyCity
      .replaceAll("%20", " ")
      .replaceAll("%C3%80", "À")
      .replaceAll("%C3%81", "Á")
      .replaceAll("%C3%82", "Â")
      .replaceAll("%C3%83", "Ã")
      .replaceAll("%C3%84", "Ä")
      .replaceAll("%C3%85", "Å")
      .replaceAll("%C3%86", "Æ")
      .replaceAll("%C3%87", "Ç")
      .replaceAll("%C3%88", "È")
      .replaceAll("%C3%89", "É")
      .replaceAll("%C3%8A", "Ê")
      .replaceAll("%C3%8B", "Ë")
      .replaceAll("%C3%8C", "Ì")
      .replaceAll("%C3%8D", "Í")
      .replaceAll("%C3%8E", "Î")
      .replaceAll("%C3%8F", "Ï")
      .replaceAll("%C3%90", "Ð")
      .replaceAll("%C3%91", "Ñ")
      .replaceAll("%C3%92", "Ò")
      .replaceAll("%C3%93", "Ó")
      .replaceAll("%C3%94", "Ô")
      .replaceAll("%C3%95", "Õ")
      .replaceAll("%C3%96", "Ö")
      .replaceAll("%C3%97", "×")
      .replaceAll("%C3%98", "Ø")
      .replaceAll("%C3%99", "Ù")
      .replaceAll("%C3%9A", "Ú")
      .replaceAll("%C3%9B", "Û")
      .replaceAll("%C3%9C", "Ü")
      .replaceAll("%C3%9D", "Ý")
      .replaceAll("%C3%9E", "Þ")
      .replaceAll("%C3%9F", "ß")
      .replaceAll("%C3%A0", "à")
      .replaceAll("%C3%A1", "á")
      .replaceAll("%C3%A2", "â")
      .replaceAll("%C3%A3", "ã")
      .replaceAll("%C3%A4", "ä")
      .replaceAll("%C3%A5", "å")
      .replaceAll("%C3%A6", "æ")
      .replaceAll("%C3%A7", "ç")
      .replaceAll("%C3%A8", "è")
      .replaceAll("%C3%A9", "é")
      .replaceAll("%C3%AA", "ê")
      .replaceAll("%C3%AB", "ë")
      .replaceAll("%C3%AC", "ì")
      .replaceAll("%C3%AD", "í")
      .replaceAll("%C3%AE", "î")
      .replaceAll("%C3%AF", "ï")
      .replaceAll("%C3%B0", "ð")
      .replaceAll("%C3%B1", "ñ")
      .replaceAll("%C3%B2", "ò")
      .replaceAll("%C3%B3", "ó")
      .replaceAll("%C3%B4", "ô")
      .replaceAll("%C3%B5", "õ")
      .replaceAll("%C3%B6", "ö")
      .replaceAll("%C3%B7", "÷")
      .replaceAll("%C3%B8", "ø")
      .replaceAll("%C3%B9", "ù")
      .replaceAll("%C3%BA", "ú")
      .replaceAll("%C3%BB", "û")
      .replaceAll("%C3%BC", "ü");


      this.joinedCompanyCountry = this.joinedCompanyCountry
      .replaceAll("%20", " ")
      .replaceAll("%C3%80", "À")
      .replaceAll("%C3%81", "Á")
      .replaceAll("%C3%82", "Â")
      .replaceAll("%C3%83", "Ã")
      .replaceAll("%C3%84", "Ä")
      .replaceAll("%C3%85", "Å")
      .replaceAll("%C3%86", "Æ")
      .replaceAll("%C3%87", "Ç")
      .replaceAll("%C3%88", "È")
      .replaceAll("%C3%89", "É")
      .replaceAll("%C3%8A", "Ê")
      .replaceAll("%C3%8B", "Ë")
      .replaceAll("%C3%8C", "Ì")
      .replaceAll("%C3%8D", "Í")
      .replaceAll("%C3%8E", "Î")
      .replaceAll("%C3%8F", "Ï")
      .replaceAll("%C3%90", "Ð")
      .replaceAll("%C3%91", "Ñ")
      .replaceAll("%C3%92", "Ò")
      .replaceAll("%C3%93", "Ó")
      .replaceAll("%C3%94", "Ô")
      .replaceAll("%C3%95", "Õ")
      .replaceAll("%C3%96", "Ö")
      .replaceAll("%C3%97", "×")
      .replaceAll("%C3%98", "Ø")
      .replaceAll("%C3%99", "Ù")
      .replaceAll("%C3%9A", "Ú")
      .replaceAll("%C3%9B", "Û")
      .replaceAll("%C3%9C", "Ü")
      .replaceAll("%C3%9D", "Ý")
      .replaceAll("%C3%9E", "Þ")
      .replaceAll("%C3%9F", "ß")
      .replaceAll("%C3%A0", "à")
      .replaceAll("%C3%A1", "á")
      .replaceAll("%C3%A2", "â")
      .replaceAll("%C3%A3", "ã")
      .replaceAll("%C3%A4", "ä")
      .replaceAll("%C3%A5", "å")
      .replaceAll("%C3%A6", "æ")
      .replaceAll("%C3%A7", "ç")
      .replaceAll("%C3%A8", "è")
      .replaceAll("%C3%A9", "é")
      .replaceAll("%C3%AA", "ê")
      .replaceAll("%C3%AB", "ë")
      .replaceAll("%C3%AC", "ì")
      .replaceAll("%C3%AD", "í")
      .replaceAll("%C3%AE", "î")
      .replaceAll("%C3%AF", "ï")
      .replaceAll("%C3%B0", "ð")
      .replaceAll("%C3%B1", "ñ")
      .replaceAll("%C3%B2", "ò")
      .replaceAll("%C3%B3", "ó")
      .replaceAll("%C3%B4", "ô")
      .replaceAll("%C3%B5", "õ")
      .replaceAll("%C3%B6", "ö")
      .replaceAll("%C3%B7", "÷")
      .replaceAll("%C3%B8", "ø")
      .replaceAll("%C3%B9", "ù")
      .replaceAll("%C3%BA", "ú")
      .replaceAll("%C3%BB", "û")
      .replaceAll("%C3%BC", "ü");




    if (this.joinedCompanyName) {
      this.activeComponent = "Signup1ExistingCompany";
    }
  },
};
</script>

<style>
</style>