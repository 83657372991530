import {
  onSnapshot,
  query,
  collection,
  collectionGroup,
  getDocs,
  where,
  doc,
  updateDoc,
  getDoc,
  orderBy,
  addDoc,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  setPersistence,
} from "firebase/auth";

import { db } from "../firebase/index";
import store from "../store/index";
import route from "../router/index";
import router from "../router/index";

import { sortProfileListExternal } from "../js/profilelist";

import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

////////////////////////////////////////////////////
/////////////////GLOBAL VARIABLES///////////////////
////////////////////////////////////////////////////

//This function is assigned a realtime listener when the getRecentProfilesExternal runs the first time.
//When I run this function later, it unsubs to the changes. And empties this variable so it can be reassigned again, and unsubbed again, etc. Built in Firestore callback.
let unsubToUserDataChanges;
let unsubToTeamMembers;
let unsubToTeamData;
let unsubToProfilesRecentlyUpdatedByUser;
let unsubscribeToProjectProfilesExternal;
let unsubtoprofileactivitylogs;
let unsubtoprofileprojectbindings;
let unsubtoprofilesinthisproject;
let unsubtoprofilesinthisproject2;
let unsubtolatestprofileprojectbindings;
let unsubscribeToRecentlyModifiedByUserProfilesExternal;
let unsubtolatestprofilecomments;
let unsubtoprofiletasks;
let unsubtolatestprofileemails;
let unsubToEmailTemplates;
let unsubToSingleProject;
let unsubToSingleClient;
let unsubToEmailNotifications;
let unsubToTaskNotifications;
let unsubToClientCommentsNotifications;
let unsubToClientCommentsNotifications2;
let unsubtoallemailsin;
let unsubtoalltasks;
let unsubtocalendarevents;
let unsubtoownprojectscount;
let unsubtoteamnprojectscount;


////////////////////////////////////////////////////
/////////////////GET DATA FUNCTIONS/////////////////
////////////////////////////////////////////////////

const getGlobalAppSettings = () => {
  //Get loggedin user data
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const unsubFromGlobalAppSettings = onSnapshot(
        doc(db, "globalappsettings", "4fxYN8frxpfDsORtIP1e"),
        (doc) => {
          //Get global app settings
          store.state.globalAppSettings = doc.data();

          //Show general alert
          setTimeout(() => {
            // Get current route
            let currentRoute = router.currentRoute.value;

            // Get path of current route
            let path = currentRoute.path;

            if (
              store.state.globalAppSettings.showgeneralalert &&
              store.state.subscriptiontype != "client" &&
              path != "/portal" &&
              path != "/extension" &&
              path != "/mobile" &&
              path != "/signup" &&
              path != "/signin" &&
              store.state.userData.generalalertstatus == "unread"
            ) {
              $("#generalalertmessage").modal("show");
            } else if (!store.state.globalAppSettings.showgeneralalert) {
              $("#generalalertmessage").modal("hide");
            }
          }, 2000);
        }
      );
    } else {
      console.log("User not logged in.");
    }
  });
};

/////////////////////////////////////////
//Get user data (user profile data, status list, cients, projects...) and update it in realtime
//Fired from App.vue
const getUserDataExternal = () => {
  //Get loggedin user data
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      //This clears the cache if a user switches to another account
      if (unsubToUserDataChanges) {
        unsubToUserDataChanges();
        unsubToUserDataChanges = undefined;
      }

      //This clears the cache if a user switches to another account
      if (unsubToTeamMembers) {
        unsubToTeamMembers();
        unsubToTeamMembers = undefined;
      }

      //This clears the cache if a user switches to another account
      if (unsubToTeamData) {
        unsubToTeamData();
      }

      //This clears the cache if a user switches to another account
      if (unsubtolatestprofileemails) {
        unsubtolatestprofileemails();
        unsubtolatestprofileemails = undefined;
      }

      //Get general user data: TODO//why use a query here since there will ever only be 1 match?
      //Looks like a snapshot listener on the unique doc would be better

      //Get general user data: Use direct document reference since there will ever only be 1 match.
      const userDocRef = doc(db, "users", user.uid);
      // Listen to Firestore document changes
      unsubToUserDataChanges = onSnapshot(
        userDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            let user = docSnapshot.data();
            user.id = docSnapshot.id;
            store.state.userData = user;

            //initial data fetch and listen to Firestore collection changes
            //only if user is not a client

            if (store.state.userData.subscriptiontype == "client") {
              console.log("stopped because client 1");
            } else {
              //Get all clients: initial render and listen to changes
              const qClients = query(
                collection(db, "clients"),
                where("team", "==", user.teamid),
                orderBy("createdat", "desc")
              );

              onSnapshot(
                qClients,
                (clientSnapshot) => {
                  store.state.currentClientList = [];

                  clientSnapshot.docs.forEach((clientDoc) => {
                    let client = clientDoc.data();
                    client.id = clientDoc.id;
                    store.state.currentClientList.push(client);
                  });

                  //Sort clients: first Active ones, then Closed ones
                  function compare(a, b) {
                    if (a.clientstatus < b.clientstatus) {
                      return -1;
                    }
                    if (a.clientstatus > b.clientstatus) {
                      return 1;
                    }
                    return 0;
                  }
                  store.state.currentClientList.sort(compare);
                },
                (error) => {
                  console.log(error);
                }
              );
            }

            //Get own projects: initial render and listen to changes
            //only if user is not a client
            if (store.state.userData.subscriptiontype == "client") {
              console.log("stopped because client 2");
            } else {
              const qProjects1 = query(
                collection(db, "projects"),
                where("owninguser", "==", store.state.userData.userid)
              );

              const qProjects2 = query(
                collection(db, "projects"),
                where(
                  "otherusers",
                  "array-contains",
                  store.state.userData.userid
                )
              );

              const processSnapshot = (snapshot) => {
                snapshot.docs.forEach((doc4) => {
                  let project = doc4.data();
                  project.id = doc4.id;
                  store.state.currentMyProjectList.push(project);
                  const bindingsQuery = query(
                    collectionGroup(db, "profileprojectbindings"),
                    where("projectid", "==", project.id),
                    where("teamid", "==", project.team)
                  );

                  getCountFromServer(bindingsQuery).then((bindingsSnap) => {
                    project.profilescount = bindingsSnap.data().count;
                    store.state.currentMyProjectList.push(project);
                  });
                });
              };

              // Listen to Firestore collection changes
              if (unsubtoownprojectscount) {
                unsubtoownprojectscount();
              }
              unsubtoownprojectscount = onSnapshot(
                qProjects1,
                (snapshot) => {
                  store.state.currentMyProjectList = [];
                  processSnapshot(snapshot);


                  if (unsubtoteamnprojectscount) {
                    unsubtoteamnprojectscount();
                  }
                  
                  unsubtoteamnprojectscount = onSnapshot(qProjects2, (snapshot) => {
                    
                    processSnapshot(snapshot);

                    //Old: Sorting is done directly in the ProjectList.vue component.
                    //Comment above was before. It seems better to sort it below directly so that all lists of projects will be sorted,
                    //including for ex. in the Chrome extension.
                    //But based on the old comment above, there might now be 2 sort functions running.
                    store.state.currentMyProjectList.sort(
                      (a, b) => a.startedat - b.startedat
                    );
                  });
                },
                (error) => {
                  console.log(error); // Here I get: FirebaseError: Missing or insufficient permissions.
                }
              );

              //Get public projects from other team members: initial render and listen to changes
              const qTeamProjects = query(
                collection(db, "projects"),
                where("owninguser", "!=", store.state.userData.userid),
                where("teamaccess", "==", "Public"),
                where("team", "==", store.state.userData.teamid)
              );
              onSnapshot(
                qTeamProjects,
                (snapshot) => {
                  store.state.currentTeamProjectList = [];
                  snapshot.docs.forEach((doc3) => {
                    let project = doc3.data();
                    project.id = doc3.id;

                    //filter out team project that user has already joined, they should appear in "My projects" above
                    if (project.otherusers) {
                      if (
                        project.otherusers.includes(store.state.userData.userid)
                      ) {
                        //do not push the project to the Vuex
                        return;
                      }
                    }

                    store.state.currentTeamProjectList.push(project);
                  });

                  //Sort projects: first Active ones, then Closed ones
                  function compare(a, b) {
                    if (a.status < b.status) {
                      return -1;
                    }
                    if (a.status > b.status) {
                      return 1;
                    }
                    return 0;
                  }
                  store.state.currentTeamProjectList.sort(compare);

                  //Also sort based on timestamp
                  store.state.currentTeamProjectList.sort(
                    (a, b) => a.startedat - b.startedat
                  );

                  /* //Testing code:
            console.log("store.state.userData.id: " + store.state.userData.id)
            console.log("store.state.currentUserId: " + store.state.currentUserId)
            console.log("team projects length: " +  store.state.currentTeamProjectList.length)
            function logProjectProperties(projectList) {
              projectList.forEach(project => {
                console.log("////////////////")
                console.log(project.clientobject.clientname);
                console.log(project.owninguser);
                console.log(project.team);
                console.log(project.teamaccess);
                console.log(project.positiontitle);
              });
            }
            logProjectProperties(store.state.currentTeamProjectList);
            */
                },

                (error) => {
                  console.log(error);
                }
              );

              if (
                store.state.userData.subscriptiontype != "client" &&
                store.state.userData.threelatestprofiles
              ) {
                //Get latest profiles viewed to display in mobile UI
                store.state.latestprofilesvieweddocs = [];
                for (
                  let i = 0;
                  i < store.state.userData.threelatestprofiles.length;
                  i++
                ) {
                  const profileRef = doc(
                    db,
                    "profiles",
                    store.state.userData.threelatestprofiles[i]
                  );
                  getDoc(profileRef).then((profileDoc) => {
                    if (profileDoc.exists()) {
                      let tempProfile = profileDoc.data();
                      tempProfile.id = profileDoc.id;
                      store.state.latestprofilesvieweddocs.push(tempProfile);
                    }
                  });
                }
              }
            }

            //Get team defined Profile STATUS list
            //only if user is not a client
       
              const qProfileStatusList = query(
                collection(
                  db,
                  "teams/" + store.state.userData.teamid + "/profilestatuslist"
                ),
                orderBy("order")
              );

        
              onSnapshot(
                qProfileStatusList,
                (snapshot) => {
              
                  store.state.profileStatusList = [];
                  snapshot.docs.forEach((doc2) => {
                    let status = doc2.data();
                    status.id = doc2.id;

                    //Create an array of Status objects (to access all the details of the status)
                    store.state.profileStatusList.push({
                      id: status.id,
                      status: status.status,
                      category: status.category,
                      order: status.order,
                      tracked: status.tracked,
                    });

                    //Create an array of only the status names (to quickly check if a status exists in the list, for ex.
                    //to determine if a Deleted Status warning should be displayed in ProfileList.vue)
                    store.state.statusNameArrayList.push(status.status);
                  });
                },
                (error) => {
                  console.log("Error fetching team status list: ", error);
                }
              );
            

            //Get Profile TAGS list from the Team document

            const qProfileTagList = query(
              collection(db, "teams/" + user.teamid + "/profiletaglist")
            );
            onSnapshot(
              qProfileTagList,
              (profileDocSnapshot) => {
                store.state.profileTagList = [];
                profileDocSnapshot.docs.forEach((doc1) => {
                  let tag = doc1.data();
                  tag.id = doc1.id;
                  //Create an array of tags objects (to access all the details of the tag)
                  store.state.profileTagList.push(tag);
                  //Create an array of only the tag names (to quickly check if a tag exists in the list, useful?
                  //store.state.profileTagNameList.push(tag.tagname)
                });
              },
              (error) => {
                console.log("Error fetching team tag list: ", error);
              }
            );

            /*
                    //Get teammembers
                    const qTeammembers = query(collection(db, "users"), where("teamid", "==", store.state.userData.teamid));
                    unsubToTeamMembers = onSnapshot(qTeammembers, (teammembersSnap) => {
                      store.state.teammembers = []
                      teammembersSnap.docs.forEach((doc9) => {
                        let teammember = doc9.data()
                        teammember.docid = doc9.id
          
                        //Create an array of team members
                        store.state.teammembers.push(teammember)
          
                      })
                    })
                    */

            //get teammembers

            const qTeammembers = query(
              collection(db, "users"),
              where("teamid", "==", store.state.userData.teamid)
            );
            unsubToTeamMembers = onSnapshot(
              qTeammembers,
              (teammembersSnap) => {
                store.state.teammembers = [];
                const teamMembers = [];
                teammembersSnap.docs.forEach((doc9) => {
                  let teammember = doc9.data();
                  teammember.id = doc9.id;

                  // Create an array of team members
                  teamMembers.push(teammember);
                });
                store.state.teammembers = teamMembers;
              },
              (error) => {
                console.log(error); // Here I get: FirebaseError: Missing or insufficient permissions.
              }
            );

            //Get team data

            const teamDocRef = doc(db, "teams", user.teamid);
            //just changed unsubToTeamMembers to unsubToTeamData, if there is a bug, change it back. looked like the wrong name to me
            unsubToTeamData = onSnapshot(
              teamDocRef,
              (docSnapshot) => {
                store.state.teamData = docSnapshot.data();
                store.state.teamData.id = docSnapshot.id;
              },
              (error) => {
                console.log(error);
              }
            );

            //Get User defined emailtemplates

            //only if user is not a client
            if (store.state.userData.subscriptiontype == "client") {
              console.log("stopped because client 7");
            } else {
              const qEmailTemplates = query(
                collection(db, "users/" + user.id + "/emailtemplates"),
                where("userid", "==", user.userid)
              );
              unsubToEmailTemplates = onSnapshot(
                qEmailTemplates,
                (templatesSnap) => {
                  store.state.emailtemplates = [];
                  templatesSnap.docs.forEach((doc10) => {
                    let template = doc10.data();
                    template.id = doc10.id;

                    //Create an array of the templates for this user
                    store.state.emailtemplates.push(template);
                  });
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          } else {
            console.log("No such user!");
          }
        },
        (error) => {
          console.log("Error fetching user data: ", error);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log("user not loggedin: from getUserData()");
    }
  });
};
//end get user data function

const getNotifications = () => {
  if (unsubToEmailNotifications) {
    unsubToEmailNotifications();
    unsubToEmailNotifications = undefined;
  }

  if (unsubToTaskNotifications) {
    unsubToTaskNotifications();
    unsubToTaskNotifications = undefined;
  }

  if (unsubToClientCommentsNotifications) {
    unsubToClientCommentsNotifications();
    unsubToClientCommentsNotifications = undefined;
  }

  if (unsubToClientCommentsNotifications2) {
    unsubToClientCommentsNotifications2();
    unsubToClientCommentsNotifications2 = undefined;
  }

  //Get loggedin user data
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const qUserData = query(
        collection(db, "users"),
        where("userid", "==", user.uid)
      );
      //get the related user based on the authetification id

      getDocs(qUserData).then((snapshot) => {
        snapshot.docs.forEach((doc213) => {
          let user = doc213.data();
          user.id = doc213.id;
          store.state.userData = user;

          //stop if user is a client in the client portal
          if (store.state.userData.subscriptiontype == "client") {
            console.log("stopped here");
            return;
          }

          ////get Notifications

          //email notifications
          const qEmailnotifications = query(
            collection(db, "users/" + user.id + "/emailsin"),
            where("readstatus", "==", "unread"),
            orderBy("timestamp", "desc"),
            limit(30)
          );
          unsubToEmailNotifications = onSnapshot(
            qEmailnotifications,
            (emailNotificationsSnap) => {
              store.state.emailnotifications = [];
              emailNotificationsSnap.docs.forEach((doc11) => {
                let emailnotification = doc11.data();
                emailnotification.id = doc11.id;
                emailnotification.notificationtype = "email";

                const relatedProfileRef = doc(
                  db,
                  "profiles",
                  emailnotification.profileid
                );
                getDoc(relatedProfileRef).then((relatedProfileSnap) => {
                  if (relatedProfileSnap.exists()) {
                    emailnotification.firstname =
                      relatedProfileSnap.data().firstname;
                    emailnotification.lastname =
                      relatedProfileSnap.data().lastname;
                    emailnotification.currentcompany =
                      relatedProfileSnap.data().currentcompany;
                    emailnotification.currentjobtitle =
                      relatedProfileSnap.data().currentjobtitle;

                    let profilePicFromDoc =
                      relatedProfileSnap.data().profilepic;
                    if (
                      profilePicFromDoc.includes("GoogleAccessId") ||
                      profilePicFromDoc.includes("media.licdn.com")
                    ) {
                      emailnotification.profilepic =
                        "https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png";
                    } else {
                      emailnotification.profilepic =
                        relatedProfileSnap.data().profilepic;
                    }

                    //Create an array of the templates for this user
                    store.state.emailnotifications.push(emailnotification);
                  }
                });
              });
            },
            (error) => {
              console.log(error);
            }
          );

          //tasks notification
          //HERE TODO: ADD ANOTHER QUERY FOR TASKS DUE TODAY

          const qTasks = query(
            collectionGroup(db, "profiletasks"),
            where("assigneduserobj.userid", "==", store.state.userData.userid),
            where("byuser", "!=", store.state.userData.userid),
            where("completed", "==", false),
            where("readstatus", "==", "unread"),
            orderBy("byuser", "desc"),
            orderBy("timestamp", "desc"),
            limit(30)
          );
          unsubToTaskNotifications = onSnapshot(
            qTasks,
            (tasksSnap) => {
              store.state.tasknotifications = [];
              tasksSnap.docs.forEach((doc12) => {
                let tasknotif = doc12.data();
                tasknotif.id = doc12.id;
                tasknotif.notificationtype = "task";

                const relatedProfileRef = doc(
                  db,
                  "profiles",
                  tasknotif.profileid
                );
                getDoc(relatedProfileRef).then((relatedProfileSnap) => {
                  if (relatedProfileSnap.exists()) {
                    tasknotif.firstname = relatedProfileSnap.data().firstname;
                    tasknotif.lastname = relatedProfileSnap.data().lastname;
                    tasknotif.currentcompany =
                      relatedProfileSnap.data().currentcompany;
                    tasknotif.currentjobtitle =
                      relatedProfileSnap.data().currentjobtitle;
                    tasknotif.profilepic = relatedProfileSnap.data().profilepic;

                    //Create an array of the templates for this user
                    store.state.tasknotifications.push(tasknotif);
                  }
                });
              });
            },
            (error) => {
              console.log(error);
            }
          );

          //client comments notifications. chatGPT came up with this simulated logical OR query

          const qClientComments1 = query(
            collectionGroup(db, "profilecomments"),
            where("owninguser", "==", store.state.userData.userid),
            where("readstatus", "==", "unread"),
            where("userisaclient", "==", true),
            orderBy("timestamp", "desc")
          );

          //case1: user is the owninguser of this project
          (unsubToClientCommentsNotifications = onSnapshot(
            qClientComments1,
            (clientCommentSnap) => {
              store.state.clientcommentsnotificationsOwnProjects = [];

              clientCommentSnap.docs.forEach(
                (doc14) => {
                  let clientcomment = doc14.data();
                  clientcomment.id = doc14.id;
                  clientcomment.notificationtype = "clientcomment";

                  const relatedProfileRef = doc(
                    db,
                    "profiles",
                    clientcomment.profileid
                  );
                  getDoc(relatedProfileRef).then((relatedProfileSnap) => {
                    if (relatedProfileSnap.exists()) {
                      clientcomment.firstname =
                        relatedProfileSnap.data().firstname;
                      clientcomment.lastname =
                        relatedProfileSnap.data().lastname;
                      clientcomment.currentcompany =
                        relatedProfileSnap.data().currentcompany;
                      clientcomment.currentjobtitle =
                        relatedProfileSnap.data().currentjobtitle;
                      clientcomment.profilepic =
                        relatedProfileSnap.data().profilepic;

                      // Create an array of comments
                      store.state.clientcommentsnotificationsOwnProjects.push(
                        clientcomment
                      );
                    }
                  });
                },

                (error) => {
                  console.log(error);
                }
              );
            }
          )),
            (error) => {
              console.log(error);
            };

          setTimeout(() => {
            //delay to make sure that currentMyProjectList is populated, the get projects async (snapshot) function above runs in parallel

            const joinedProjectIds = store.state.currentMyProjectList
              .filter(
                (project) => project.owninguser !== store.state.userData.userid
              )
              .map((project) => project.id);

            if (joinedProjectIds.length > 0) {
              const qClientComments2 = query(
                collectionGroup(db, "profilecomments"),
                where("projectid", "in", joinedProjectIds),
                where("readstatus", "==", "unread"),
                where("userisaclient", "==", true),
                orderBy("timestamp", "desc")
              );

              //case2: user is NOT the owninguser of this project
              (unsubToClientCommentsNotifications2 = onSnapshot(
                qClientComments2,
                (clientCommentSnap2) => {
                  store.state.clientcommentsnotificationsJoinedProjects = [];

                  clientCommentSnap2.docs.forEach(
                    (doc15) => {
                      let clientcomment = doc15.data();
                      clientcomment.id = doc15.id;
                      clientcomment.notificationtype = "clientcomment";

                      const relatedProfileRef = doc(
                        db,
                        "profiles",
                        clientcomment.profileid
                      );
                      getDoc(relatedProfileRef).then((relatedProfileSnap) => {
                        if (relatedProfileSnap.exists()) {
                          clientcomment.firstname =
                            relatedProfileSnap.data().firstname;
                          clientcomment.lastname =
                            relatedProfileSnap.data().lastname;
                          clientcomment.currentcompany =
                            relatedProfileSnap.data().currentcompany;
                          clientcomment.currentjobtitle =
                            relatedProfileSnap.data().currentjobtitle;
                          clientcomment.profilepic =
                            relatedProfileSnap.data().profilepic;

                          // Create an array of comments
                          store.state.clientcommentsnotificationsJoinedProjects.push(
                            clientcomment
                          );
                        }
                      });
                    },

                    (error) => {
                      console.log(error);
                    }
                  );
                }
              )),
                (error) => {
                  console.log(error);
                };
            }
          }, 1500);
        });
      });
    }
  });
};

const getAllEmailsIn = () => {
  //Unsubscribe to other snapshot listeners,
  //including the ones to be created below
  if (unsubtoallemailsin) {
    unsubtoallemailsin();
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubtoallemailsin = undefined;
  }

  //get Emails "in"
  setTimeout(() => {
    const qEmailsFromProfile = query(
      collection(db, "users/" + store.state.userData.id + "/emailsin/"),
      where("emailto", "==", store.state.userData.email),
      orderBy("timestamp", "desc"),
      limit(30)
    );
    unsubtoallemailsin = onSnapshot(
      qEmailsFromProfile,
      (emailSnap) => {
        store.state.allemailsin = [];
        emailSnap.docs.forEach((emailDoc) => {
          //define a single email
          let emailmessage = emailDoc.data();
          emailmessage.id = emailDoc.id;
          store.state.allemailsin.push(emailmessage);

          //getSingleProfileExternal(store.state.currentProfileDetails.id)
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, 1000);
};

const getAllOpenTasks = () => {
  //Unsubscribe to other snapshot listeners,
  //including the ones to be created below
  if (unsubtoalltasks) {
    unsubtoalltasks();
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubtoalltasks = undefined;
  }

  const qAllTasks = query(
    collectionGroup(db, "profiletasks"),
    where("assigneduserobj.id", "==", store.state.userData.id),
    where("completed", "==", false),
    orderBy("timestamp", "desc"),
    limit(30)
  );
  unsubtoalltasks = onSnapshot(
    qAllTasks,
    (tasksnap) => {
      store.state.tasksInDashboardLoading = true;

      store.state.alltasks = [];

      tasksnap.docs.forEach((taskDoc) => {
        //define a single task
        let singleTask = taskDoc.data();
        singleTask.id = taskDoc.id;
        //store.state.alltasks.push(singleTask)

        const profileRef = doc(db, "profiles", singleTask.profileid);

        getDoc(profileRef).then((result) => {
          if (result.exists()) {
            let profile = result.data();

            singleTask.profileFirstName = profile.firstname;
            singleTask.profileLastName = profile.lastname;
            singleTask.profileCompany = profile.currentcompany;
            singleTask.profilePic = profile.profilepic;

            store.state.alltasks.push(singleTask);
          }
        });
      });
      setTimeout(() => {
        store.state.tasksInDashboardLoading = false;
      }, 1000);
    },
    (error) => {
      console.log(error);
    }
  );
};

/////////////////////////////////////////
//Get profiles functions

const getRecentProfilesExternal = () => {
  //go back to the initial number of loaded profiles (for faster loading)
  store.state.numberOfProfilesLoaded =
    store.state.numberOfProfilesLoadedInitially;

  //Empty profiles in cache (Vuex array): in case the user switches from an account to another, to avoid permission problems in Firebase
  store.state.currentProfileList = [];

  //Empty profiles filter in case any filters were applied
  store.state.filterAppliedToProfiles = false;
  store.state.addedByMe = false;
  store.state.visibleToClient = false;
  store.state.gdprFilterApplied = false;
  store.state.gdprFilterValue = "";
  store.state.tagFilterArray = [];
  store.state.statusFilterArray = [];

  //Unsubscribe to other Profile snapshot listeners,
  //including the ones to be created below
  //(when the user switches from Recent profiles to Project Profile and back to Recent profile)
  if (unsubscribeToProjectProfilesExternal) {
    unsubscribeToProjectProfilesExternal();
    console.log("unsubbed from: unsubscribeToProjectProfilesExternal");
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubscribeToProjectProfilesExternal = undefined;
  }

  if (unsubToProfilesRecentlyUpdatedByUser) {
    unsubToProfilesRecentlyUpdatedByUser();
    console.log("unsubbed from: unsubToProfilesRecentlyUpdatedByUser");
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubToProfilesRecentlyUpdatedByUser = undefined;
  }

  //For toolbar header
  store.state.showingProfilesAs = "recentProfiles";

  //Get loggedin user data
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      //if user is on the Extension page, the following code should not run. It could create duplicates in the Vuex array (currentProfileList).
      if (window.location.href.includes("extension")) {
        return;
      }

      //build the query to get the latest 30 profiles
      const qChangedByThisUser = query(
        collection(db, "profiles"),
        where("latestupdatebyuser", "==", user.uid),
        orderBy("latestupdatetimestamp", "desc"),
        limit(30)
      );

      /*
      const qChangedByThisUser = query(
        collectionGroup(db, "profiles"),
        where("latestupdatebyuser", "==", user.uid),
        orderBy("latestupdatetimestamp", "desc"),
        limit(30)
      );
      */

      /*
      getDocs(qChangedByThisUser)
  .then((bindingsSnapshot) => {
    //create temporary & local binding array to build the bindings array (not to pollute the existing array, if it exists)
    bindingsSnapshot.forEach((bindingDoc) => {

        console.log("test")

    })
  })
  .catch((e) => {
 
      console.log(e)
    
  })
  */

      //create realtime listener based on query
      unsubToProfilesRecentlyUpdatedByUser = onSnapshot(
        qChangedByThisUser,
        (querySnapshot) => {
          store.state.currentProfileList = [];

          if (querySnapshot.docs.length == 0) {
            //if no recent profiles (mostly first time signin in to a brand new account), display empty profiles message
            store.state.emptyProfileList = true;
          } else {
            store.state.emptyProfileList = false;
          }

          querySnapshot.forEach((recentProfileDoc) => {
            let profile = recentProfileDoc.data();
            profile.id = recentProfileDoc.id;
            profile.statusorder = 0;

            //build the list of recently updated profiles
            store.state.currentProfileList.push(profile);

            if (
              store.state.currentProfileList.length == querySnapshot.docs.length
            ) {
              //store the original profile list before any filter has been applied, so we can restore it when the filters are cleared
              store.state.cachedProfileList = store.state.currentProfileList;
            }
          });
        },
        (error) => {
          console.log("this is the error: " + error);
        }
      );

      /*
          const qChangedByThisUser = query
            (
              collectionGroup(db, 'activitylogs'),
              where('typeofdata', '==', 'profile'),
              where('byuser', '==', user.uid),
              orderBy("timestamp", "desc"),
              limit(30)
            )
    
          unsubToProfilesRecentlyUpdatedByUser = onSnapshot(qChangedByThisUser, (querySnapshot) => {
            store.state.currentProfileList = [];
            querySnapshot.forEach((doc) => {
    
    
    
              //get the parent Profile
              getDoc(doc.ref.parent.parent)
                .then((profileSnap) => {
                  //store resulting profile in object
                  let profile = profileSnap.data()
                  profile.id = profileSnap.id
    
                  //Does multiple recent activity logs refer to the same profile?
                  var profileExists = store.state.currentProfileList.filter(obj => {
                    return obj.id === profile.id
                  })
    
                  if (profileExists.length != 0) {
                    //do nothing, this avoids duplicates
                    return
                  }
                  else {
                    store.state.currentProfileList.push(profile)
                  }
    
    
                })
    
            });
            //console.log("Current cities in CA: ", cities.join(", "));
          });
          */
    } else {
      console.log("user not loggedin, from getRecentProfiles()");
    }
  });
};

const getProfilesForThisProjectExternal = (projectid) => {
  //go back to the initial number of loaded profiles (for faster loading)
  store.state.numberOfProfilesLoaded =
    store.state.numberOfProfilesLoadedInitially;

  //store the original profile list before any filter has been applied, so we can restore it when the filters are cleared
  //store.state.cachedProfileList = store.state.currentProfileList
  //Now we use another method in Smallcard.vue, where getProfilesForThisProjectExternal() from getdata.js is executed instead.

  store.state.currentProfileList = [];

  //Empty profiles filter in case any filters were applied
  store.state.filterAppliedToProfiles = false;
  store.state.addedByMe = false;
  store.state.visibleToClient = false;
  store.state.gdprFilterApplied = false;
  store.state.gdprFilterValue = "";
  store.state.tagFilterArray = [];
  store.state.statusFilterArray = [];

  //unsub
  if (unsubtoprofilesinthisproject) {
    unsubtoprofilesinthisproject();
  }
  if (unsubToProfilesRecentlyUpdatedByUser) {
    unsubToProfilesRecentlyUpdatedByUser();
  }
  if (unsubtoprofileprojectbindings) {
    unsubtoprofileprojectbindings();
  }
  if (unsubtoprofilesinthisproject2) {
    unsubtoprofilesinthisproject2();
  }

  //empty selected profiles before loading new profile list
  store.state.selectAllProfilesChecked = false;
  let selectedProfiles = store.state.selectedProfiles;
  selectedProfiles.splice(0, selectedProfiles.length);

  //Get current project data, to be displayed in Toolbar (project reference)
  const projectRef = doc(db, "projects", projectid);
  getDoc(projectRef).then((projectSnap) => {
    if (projectSnap.exists()) {
      //Vuex property enabling correct header in Toolbar
      //delay in order to prevent jumping on DOM render
      store.state.showingProfilesAs = "projectProfiles";

      const project = projectSnap.data();

      //Check if user has access: for example when the owninguser is someone else and marks it
      //as Private while the project appeared in the Vuex array of current user
      if (
        project.owninguser == store.state.currentUserId ||
        (project.owninguser != store.state.currentUserId &&
          project.teamaccess == "Public")
      ) {
        //store project data
        store.state.currentProjectDetailsForProfileList = {};
        store.state.currentProjectDetailsForProfileList = project;
        store.state.currentProjectDetailsForProfileList.id = projectSnap.id;
      } else {
        alert(
          "You do not have access to this Project. The user who created it has changed the status to Private."
        );
        return;
      }
    }
  });

  //get the profiles (listen to changes in the projectprofilebindings subcollection)
  const qProjectProfiles1 = query(
    collectionGroup(db, "profileprojectbindings"),
    where("projectid", "==", projectid)
  );

  unsubtoprofilesinthisproject = onSnapshot(
    qProjectProfiles1,
    (bindingSnapshot) => {
      //if no profiles in this project
      if (bindingSnapshot.docs.length == 0) {
        store.state.currentProfileList = [];
        store.state.emptyProfileList = true;
        return;
      } else {
        store.state.emptyProfileList = false;
      }

      //if change type is "added", this lets us wait until the forEach loop has ended, then it launches the normal "bindingSnapshot.docs.forEach((bindingDoc)" loop.
      let changeCounter = 0;

      bindingSnapshot.docChanges().forEach((change) => {
        //change type is "added"
        if (change.type === "added") {
          changeCounter = changeCounter + 1;
          if (bindingSnapshot.docChanges().length == changeCounter) {
            store.state.currentProfileList = [];
            let docsCounter = 0;

            bindingSnapshot.docs.forEach((bindingDoc) => {
              let profileId = bindingDoc.ref.parent.parent.id;

              //Ref to parent profile
              const parentProfileRef = doc(db, "profiles", profileId);

              //Get the parent Profile
              getDoc(parentProfileRef).then((document) => {
                const qComments = query(
                  collectionGroup(db, "profilecomments"),
                  where("projectid", "==", projectid),
                  where("profileid", "==", document.id),
                  orderBy("timestamp", "desc"),
                  limit(30)
                );

                let profileComments = [];

                getDocs(qComments).then((commentSnapshot) => {
                  commentSnapshot.forEach((commentDoc) => {
                    let comment = commentDoc.data();
                    comment.id = commentDoc.id;
                    profileComments.push(comment);
                  });

                  let profile = document.data();

                  profile.id = document.id;
                  //below, adding properties for display of project profiles in profilelist.vue
                  profile.statusid = bindingDoc.data().profilestatusid;
                  profile.bindingcrossaccountid =
                    bindingDoc.data().crossaccountid;
                  profile.bindingid = bindingDoc.id;
                  profile.bindingprojectid = bindingDoc.data().projectid;
                  profile.statusorder = 0;
                  profile.comments = profileComments;
                  profile.notes = bindingDoc.data().notes;

                  //handle case where the profile pic returns an error
                  let profilePic = document.data().profilepic;
                  if (
                    profilePic &&
                    (profilePic.includes("GoogleAccessId") ||
                      profilePic.includes("media.licdn.com"))
                  ) {
                    profile.profilepic = "";
                  }

                  /*
                  //replace the profile pictures of the alsoviewed profiles with an empty string
                  profile.alsoviewed.forEach(obj => {
                    if (obj.profile_picture) {
                        obj.profile_picture = '';
                    }
                });
                */

                  //check if user has access
                  if (
                    profile.owninguser == store.state.userData.userid ||
                    profile.teamaccess
                  ) {
                    //push the profile to current profiles array

                    profile.alsoviewed = [];

                    store.state.currentProfileList.push(profile);
                  }

                  docsCounter = docsCounter + 1;

                  //if all profiles have been loaded into currentProfileList...
                  if (bindingSnapshot.docs.length == docsCounter) {
                    //if no authorised profile in this project
                    if (store.state.currentProfileList.length == 0) {
                      store.state.emptyProfileList = true;
                      return;
                    }

                    //Sort the profiles
                    sortProfileListExternal("status");

                    ////////////////GET RECOMMENDED PROFILES
                    // Get the recommendedProfiles array from the store
                    let allRecommendedProfiles =
                      store.state.currentProjectDetailsForProfileList
                        .recommendedprofiles;

                    if (
                      allRecommendedProfiles == undefined ||
                      allRecommendedProfiles.length == 0
                    ) {
                      store.state.recommendedProfiles = [];
                      return;
                    }

                    // Create an object to store the counts of each profile_id
                    let idCounts = {};

                    // Iterate over the recommendedProfiles array and count the number of occurrences of each profile_id
                    allRecommendedProfiles.forEach((profile) => {
                      if (!idCounts[profile.profile_id]) {
                        idCounts[profile.profile_id] = 0;
                      }
                      idCounts[profile.profile_id]++;
                    });

                    // Filter the recommendedProfiles array to return only the objects with a profile_id that appears more than once
                    allRecommendedProfiles = allRecommendedProfiles.filter(
                      (profile) => idCounts[profile.profile_id] > 1
                    );

                    let recommendedProfiles = [];

                    // Filter out objects with duplicate profile_id values from the allRecommendedProfiles array
                    let uniqueRecommendedProfiles =
                      allRecommendedProfiles.filter((profile, index, self) => {
                        return (
                          self.findIndex(
                            (p) => p.profile_id === profile.profile_id
                          ) === index
                        );
                      });

                    // Concatenate the uniqueRecommendedProfiles array into the recommendedProfiles array
                    recommendedProfiles = recommendedProfiles.concat(
                      uniqueRecommendedProfiles
                    );

                    let uniqueRecommendedProfilesNotYetInProject =
                      recommendedProfiles.filter((recommendedProfile) => {
                        return store.state.currentProfileList.every(
                          (currentProfile) => {
                            return (
                              currentProfile.firstname !==
                                recommendedProfile.first_name ||
                              currentProfile.lastname !==
                                recommendedProfile.last_name
                            );
                          }
                        );
                      });

                    if (
                      store.state.currentProjectDetailsForProfileList
                        .discardedrecommendedprofiles
                    ) {
                      const discardedrecommendedprofiles =
                        store.state.currentProjectDetailsForProfileList
                          .discardedrecommendedprofiles;
                      uniqueRecommendedProfilesNotYetInProject =
                        uniqueRecommendedProfilesNotYetInProject.filter(
                          (item) =>
                            !discardedrecommendedprofiles.includes(
                              item.profile_id
                            )
                        );
                    }

                    store.state.recommendedProfiles =
                      uniqueRecommendedProfilesNotYetInProject;
                  }
                });
              });
            });
          }
        }
        //change type is "modified", then we should only update the one modified document. this prevents flickering and reloading all profiles
        else if (change.type === "modified") {
          //Ref to parent profile
          const parentProfileRef = doc(
            db,
            "profiles",
            change.doc.ref.parent.parent.id
          );

          //Get the parent Profile
          getDoc(parentProfileRef).then((document) => {
            let profile = document.data();
            profile.id = document.id;
            //below, adding properties for display of project profiles in profilelist.vue
            profile.statusid = change.doc.data().profilestatusid;
            profile.bindingid = change.doc.id;
            profile.bindingprojectid = change.doc.data().projectid;
            profile.statusorder = 0;

            const index = store.state.currentProfileList.findIndex((object) => {
              return object.id === profile.id;
            });

            //remove the profile
            store.state.currentProfileList.splice(index, 1);

            //add the profile back with the new status
            store.state.currentProfileList.splice(index, 0, profile);
          });
        }
      });

      //let tempProfileList = [];

      /*
    
        bindingSnapshot.docChanges().forEach((change) => {
          
          if (change.type === "added") {
            //store.state.currentProfileList = [];
            //Ref to parent profile
            const parentProfileRef = doc(db, "profiles", change.doc.ref.parent.parent.id);
    
            //Get the parent Profile
            getDoc(parentProfileRef)
              .then((document) => {
    
                const qComments = query
                (
                  collectionGroup(db, 'profilecomments'),
                  where('projectid', '==', projectid),
                  where('profileid', '==', document.id),
                  orderBy("timestamp", "desc"),
                  limit(30)
                )
    
                  let profileComments = []
    
                getDocs(qComments)
                .then((commentSnapshot) => {
                  commentSnapshot.forEach((commentDoc) => {
                    let comment = commentDoc.data()
                    comment.id = commentDoc.id
                    profileComments.push(comment)
                  })
                
    
                let profile = document.data()
                console.log(document.data().firstname, document.id)
    
                profile.id = document.id
                //below, adding properties for display of project profiles in profilelist.vue
                profile.statusid = change.doc.data().profilestatusid
                profile.bindingid = change.doc.id
                profile.bindingprojectid = change.doc.data().projectid
                profile.statusorder = 0
                profile.comments = profileComments
                profile.notes = change.doc.data().notes
    
    
                //before: i don't know why I created this tempProfileList workflow. 
                //I ended up shwoing only one profile in profilelist.vue, "projectprofiles", whenever a new
                //profile was added from linkedin. basically it rendered only the new profile and the other were not visible anymore.
                //But when using currentProfileList like below it works.
                tempProfileList.push(profile)
                //store.state.currentProfileList.push(profile)
    
    
                //if all profiles have been rendered
                if (tempProfileList.length == bindingSnapshot.docChanges().length) {
                  alert("all profiles rendered")
    
                  
                  if(bindingSnapshot.docChanges().length != bindingSnapshot.docs.length) {
                    store.state.currentProfileList.push(profile)
                  } else {
                    store.state.currentProfileList = tempProfileList
                  }
                  
    
                  //making sure that the empty profile list message dissapeares
                  store.state.emptyProfileList = false;
    
                  store.state.currentProfileList = tempProfileList
    
    
    
                  //store the original profile list before any filter has been applied, so we can restore it when the filters are cleared
                  store.state.cachedProfileList = store.state.currentProfileList
    
                }
                else {
                  console.log("waiting for profiles...")
                }
    
              })
    
              })
    
    
          }
          else if (change.type === "modified") {
            alert("modified")
            //Ref to parent profile
            const parentProfileRef = doc(db, "profiles", change.doc.ref.parent.parent.id);
    
    
            //Get the parent Profile
            getDoc(parentProfileRef)
              .then((document) => {
                let profile = document.data()
                profile.id = document.id
                //below, adding properties for display of project profiles in profilelist.vue
                profile.statusid = change.doc.data().profilestatusid
                profile.bindingid = change.doc.id
                profile.bindingprojectid = change.doc.data().projectid
                profile.statusorder = 0
    
    
    
    
                const index = store.state.currentProfileList.findIndex(object => {
                  return object.id === profile.id;
                });
    
                //remove the profile
                store.state.currentProfileList.splice(index, 1)
                console.log("just deleted")
                for (let i = 0; i < store.state.currentProfileList.length; i++) {
                  console.log(store.state.currentProfileList[i].firstname)
                }
    
                //add the profile back with the new status
                store.state.currentProfileList.splice(index, 0, profile)
                console.log("just added")
                for (let i = 0; i < store.state.currentProfileList.length; i++) {
                  console.log(store.state.currentProfileList[i].firstname)
                }
              })
          }
    
    
        })
    
        */
    },
    (error) => {
      console.log(error);
    }
  );

  //now also listen to the profiles themselves, not the bidings, so that the changes in profiledetails.vue will also be shown
};

const getSearchResultsExternal = () => {
  //go back to the initial number of loaded profiles (for faster loading)
  store.state.numberOfProfilesLoaded =
    store.state.numberOfProfilesLoadedInitially;

  //Empty profiles filter in case any filters were applied
  store.state.filterAppliedToProfiles = false;
  store.state.addedByMe = false;
  store.state.visibleToClient = false;
  store.state.gdprFilterApplied = false;
  store.state.gdprFilterValue = "";
  store.state.tagFilterArray = [];
  store.state.statusFilterArray = [];

  if (unsubscribeToProjectProfilesExternal) {
    unsubscribeToProjectProfilesExternal();
    console.log("unsubbed from: unsubscribeToProjectProfilesExternal");
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubscribeToProjectProfilesExternal = undefined;
  }

  if (unsubToProfilesRecentlyUpdatedByUser) {
    unsubToProfilesRecentlyUpdatedByUser();
    console.log("unsubbed from: unsubToProfilesRecentlyUpdatedByUser");
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubToProfilesRecentlyUpdatedByUser = undefined;
  }

  if (store.state.searchOn) {
    store.state.currentProfileList = [];
    setTimeout(() => {
      //making sure that the empty profile list message dissapears
      store.state.emptyProfileList = false;
      //load search results into currentProfileList
      store.state.currentProfileList = store.state.searchResults;

      //store the original profile list before any filter has been applied, so we can restore it when the filters are cleared
      //store.state.cachedProfileList = store.state.currentProfileList
      store.state.showingProfilesAs = "searchResults";

      //go to profiles tab in case user was on another tab
      route.push("/profiles");
    }, 500);
  } else {
    alert("You have not searched for any profiles yet!");
  }
};

async function fetchAndShowAISearchResultsForProjectExternal(projectId) {
  store.state.fetchingAiSearchResultsProgress = "on";

  const getAISearchResultsForProject = httpsCallable(
    functions,
    "getAISearchResultsForProject"
  );
  try {
    const result = await getAISearchResultsForProject({ projectId });
    if (result.data.error) {
      console.error(result.data.error);
      return null;
    }

    store.state.fetchingAiSearchResultsProgress = "off";

    //go back to the initial number of loaded profiles (for faster loading)
    store.state.numberOfProfilesLoaded =
      store.state.numberOfProfilesLoadedInitially;

    //Empty profiles filter in case any filters were applied
    store.state.filterAppliedToProfiles = false;
    store.state.addedByMe = false;
    store.state.visibleToClient = false;
    store.state.gdprFilterApplied = false;
    store.state.gdprFilterValue = "";
    store.state.tagFilterArray = [];
    store.state.statusFilterArray = [];

    store.state.currentProfileList = [];

    //making sure that the empty profile list message dissapears
    store.state.emptyProfileList = false;

    store.state.currentProfileList = result.data.aiSearchResults;

    //store the original profile list before any filter has been applied, so we can restore it when the filters are cleared
    //store.state.cachedProfileList = store.state.currentProfileList
    store.state.showingProfilesAs = "searchResults";

    route.push("/profiles");
  } catch (error) {
    console.error("Error calling the function", error);
    return null;
  }
}

/////////////////////////////////////////
//Get a single document (profile, project or client)

//get single profile
async function getSingleProfileExternal(profileId, origin) {
  //this prevents flickering when going from a profile to another
  store.state.currentProfileDetails = false;
  store.state.currentProfileComments = [];
  store.state.currentProfileProjectBindings = [];

  //ensure that the Add comment modal has the option to attach a project switched off
  //it will be switched on when the user clicks on the Add comment button
  store.state.attachprofilecommenttoproject = false;

  //////////////////////get the profile details

  const profileRef = doc(db, "profiles", profileId);

  getDoc(profileRef)
    .then((result) => {
      if (result.exists()) {
        store.state.currentProfileDetails = result.data();
        store.state.currentProfileDetails.id = result.id;

        //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
        store.state.selectedProfiles = [];

        if (origin != "notifications") {
          //Switch from ProfileList.vue to ProfileDetails.vue (except when email is opened from Notifications. still necessary? we switch to profileDetails anyway in notifications.vue in the openEmail method)

          //first store current Profiles view in cache (see store/index.js for more details)
          //except if user already is on ProfileDetails.vue, then "profileDetails would be stored".
          //For some features, like adding an email in the email dropdown in profiledetails.vue, the getSingleProfile function here is fired again
          //in order to show latest emails. So we need to filter this out here.
          if (store.state.showingProfilesAs != "profileDetails") {
            store.state.profilesViewInCache = store.state.showingProfilesAs;
          }

          //show profile details
          store.state.showingProfilesAs = "profileDetails";
        }
      } else {
        alert(
          "It seems like the user who created this profile has deleted it. Please check with the relevant user."
        );
      }
    })
    .catch((e) => {
      if (e.message == "Missing or insufficient permissions.") {
        alert(
          "Cannot access this profile. Most likely for one of the 2 following reasons: 1/ The user who created it has deleted it. 2/The user who created it has marked it as Private. Please check with the relevant user."
        );
      }
    });

  //////////////////////get activity logs for this profile

  //First: unsubscribe to previous onSnapshot to minimize data consumption in Firestore
  if (unsubtoprofileactivitylogs) {
    unsubtoprofileactivitylogs();
    //reset to initial state: can now be reassigned to a sub onSnapshot function
    unsubtoprofileactivitylogs = undefined;
  }

  //get the activity logs
  const qProfileActivityLogs = query(
    collection(db, "profiles/" + profileId + "/activitylogs")
  );
  unsubtoprofileactivitylogs = onSnapshot(
    qProfileActivityLogs,
    (profileActivitySnap) => {
      store.state.currentProfileActivityLogs = [];
      profileActivitySnap.docs.forEach((profileActivityLog) => {
        let profileactivitylog = profileActivityLog.data();
        profileactivitylog.id = profileActivityLog.id;

        //push results to Vuex array
        store.state.currentProfileActivityLogs.push(profileactivitylog);
      });
    }
  );

  //////////////////// Get the projects this profile is attached to

  //First: unsubscribe to previous onSnapshot to minimize data consumption in Firestore
  if (unsubtoprofileprojectbindings) {
    unsubtoprofileprojectbindings();
    //reset to initial state: can now be reassigned to a sub onSnapshot function
    unsubtoprofileprojectbindings = undefined;
  }

  //get all the projects this profile is attached to
  const qProfileProjectBindings = query(
    collection(db, "profiles/" + profileId + "/profileprojectbindings"),
    where("teamid", "==", store.state.userData.teamid)
  );
  unsubtoprofileprojectbindings = onSnapshot(
    qProfileProjectBindings,
    (bindingsSnap) => {
      store.state.currentProfileProjectBindings = [];
      store.state.totalNumberOfBindings = bindingsSnap.docs.length;
      bindingsSnap.docs.forEach((bindingDoc) => {
        //define a single binding
        let binding = bindingDoc.data();
        binding.id = bindingDoc.id;
        binding.metadata = bindingDoc.metadata;

        //get the related project of this binding
        const relatedProjectRef = doc(db, "projects", binding.projectid);

        getDoc(relatedProjectRef)
          .then((result) => {
            let project = result.data();

            let relatedProject = {
              clientlogo: project.clientobject.clientlogourl,
              clientname: project.clientobject.clientname,
              positiontitle: project.positiontitle,
              projectid: result.id,
              bindingid: binding.id,
              timestamp: binding.timestamp,
              match: binding.match,
              notes: binding.notes,
              profilestatusid: binding.profilestatusid,
              metadata: binding.metadata,
              crossaccountid: binding.crossaccountid,
              profileid: binding.profileid,
            };

            //here we need to catch and filter out any binding in cache. I don't know exactly how this
            //works but when we click on a profile row in ProfileList.vue immediately after page load
            //we get the right number of profile bindings once in ProfileDetails.vue / ProfileProjectActivity card (getSingleProfileExternal function in getdata.js).
            //But when we then go back to ProfileList.vue, and select a project to get the profiles from
            //this project, AND THEN click again on a profile row, we get one of the bindings twice:
            //once from cache and once not from cache. So there is a duplicate in this case.
            //So we need to filter it out here.

            if (
              store.state.currentProfileProjectBindings.find(
                (o) => o.bindingid === bindingDoc.id
              )
            ) {
              return;
            }
            store.state.currentProfileProjectBindings.push(relatedProject);
          })
          .then(() => {})
          .catch((e) => {
            console.log("project probably deleted");
            console.log(e);
          });
      });
    }
  );

  //////////////////// Get the profile comments

  //First: unsubscribe to previous onSnapshots to minimize data consumption in Firestore
  if (unsubtolatestprofilecomments) {
    unsubtolatestprofilecomments();
    //reset to initial state: can now be reassigned to a sub onSnapshot function
    unsubtolatestprofilecomments = undefined;
  }

  const qProfileComments = query(
    collection(db, "profiles/" + profileId + "/profilecomments"),
    orderBy("timestamp", "desc")
  );
  unsubtolatestprofilecomments = onSnapshot(qProfileComments, (commentSnap) => {
    store.state.currentProfileComments = [];

    commentSnap.docs.forEach((commentDoc) => {
      //define a single binding
      let comment = commentDoc.data();
      comment.id = commentDoc.id;

      store.state.currentProfileComments.push(comment);
    });
  });

  ///////////////// Get the tasks for this profile

  //First: unsubscribe to previous onSnapshots to minimize data consumption in Firestore
  if (unsubtoprofiletasks) {
    unsubtoprofiletasks();
    //reset to initial state: can now be reassigned to a sub onSnapshot function
    unsubtoprofiletasks = undefined;
  }

  const qProfileTasks = query(
    collection(db, "profiles/" + profileId + "/profiletasks"),
    orderBy("duedate", "desc")
  );
  unsubtoprofiletasks = onSnapshot(qProfileTasks, (taskSnap) => {
    store.state.currentProfileTasks = [];

    taskSnap.docs.forEach((taskDoc) => {
      //define a single task
      let task = taskDoc.data();
      task.id = taskDoc.id;

      store.state.currentProfileTasks.push(task);
    });
  });

  ///////////// Tags: they are loaded directly along with all the other profile details (root level array on the profile doc)

  ////////////// Get emails for this profile

  //First: unsubscribe to previous onSnapshots to minimize data consumption in Firestore
  if (unsubtolatestprofileemails) {
    unsubtolatestprofileemails();
    //reset to initial state: can now be reassigned to a sub onSnapshot function
    unsubtolatestprofileemails = undefined;
  }

  //get Emails "in"
  setTimeout(() => {
    const qEmailsFromProfile = query(
      collection(db, "users/" + store.state.userData.id + "/emailsin/"),
      where("emailto", "==", store.state.userData.email),
      where("emailfrom", "==", store.state.currentProfileDetails.email)
    );
    unsubtolatestprofileemails = onSnapshot(
      qEmailsFromProfile,
      (emailSnap) => {
        store.state.emailsin = [];
        emailSnap.docs.forEach((emailDoc) => {
          //define a single email
          let emailmessage = emailDoc.data();
          emailmessage.id = emailDoc.id;
          store.state.emailsin.push(emailmessage);

          //getSingleProfileExternal(store.state.currentProfileDetails.id)
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, 1000);

  //get Emails "out"
  //my email to be replaced with: store.state.userData.email once the feature is done
  setTimeout(() => {
    const qEmailsToProfile = query(
      collection(db, "users/" + store.state.userData.id + "/emailsout/"),
      where("emailfrom", "==", store.state.userData.email),
      where("emailto", "==", store.state.currentProfileDetails.email)
    );
    unsubtolatestprofileemails = onSnapshot(
      qEmailsToProfile,
      (emailSnap2) => {
        store.state.emailsout = [];
        emailSnap2.docs.forEach((emailDoc2) => {
          //define a single email
          let emailmessage = emailDoc2.data();
          emailmessage.id = emailDoc2.id;
          store.state.emailsout.push(emailmessage);
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, 1000);




}

//get single project
async function getSingleProjectExternal(projectid) {
  //avoid flickering showing the recent profiles of the previous project
  store.state.profilesInProjectForLatestProfilesFeature = [];

  //Show 'Search button' for LinkedIn profiles in AI Search card (Projectdetails)
  store.state.allRecommendedProfilesCounted = "off";

  //Reset recommended profiles array
  store.state.recommendedProfilesInProjectDetails = [];

  if (unsubToSingleProject) {
    unsubToSingleProject();
  }

  unsubToSingleProject = onSnapshot(
    doc(db, "projects", projectid),
    (singleProjectDoc) => {
      if (singleProjectDoc.exists()) {
        store.state.currentProjectDetails = singleProjectDoc.data();
        store.state.currentProjectDetails.id = singleProjectDoc.id;
        //show project details
        store.state.showingProjectsAs = "projectDetails";
      } else {
        store.state.currentProjectDetails = false;
      }
    }
  );
}

//User can check if there are any recommended LinkedIn profiles when in Project details
const checkForRecommendedProfilesFromLinkedInExternal = (projectid) => {
  ////////////////GET RECOMMENDED PROFILES
  // Get the recommendedProfiles array from the store

  async function fetchProjectProfiles(projectid) {
    //reset profiles count to show preloaded in AI search card in project details.
    store.state.allRecommendedProfilesCounted = "counting";

    // Create the query
    const qProjectProfiles = query(
      collectionGroup(db, "profileprojectbindings"),
      where("projectid", "==", projectid)
    );

    // Fetch documents only once
    const querySnapshot = await getDocs(qProjectProfiles);

    // Directly mutate the projectProfilesInProjectDetails array
    const projectProfilesInProjectDetails = [];

    //to check if the loop below is complete or not
    let totalProfilesCount = querySnapshot.docs.length;
    let profilesCount = 0;

    for (const doc of querySnapshot.docs) {
      // Use a for loop to handle async operations in sequence
      // Get the parent profile's collection reference
      const parentProfileCollection = doc.ref.parent;

      // Get the parent profile's document reference
      const parentProfileDocRef = parentProfileCollection.parent;

      if (parentProfileDocRef) {
        // Fetch the parent profile document
        const parentProfileSnapshot = await getDoc(parentProfileDocRef);

        if (parentProfileSnapshot.exists()) {
          // Add the parent profile data to the array
          projectProfilesInProjectDetails.push(parentProfileSnapshot.data());

          profilesCount++;
          if (profilesCount == totalProfilesCount) {
            store.state.allRecommendedProfilesCounted = "done";
          }
        }
      }
    }

    // Directly mutate the store
    store.state.projectProfilesInProjectDetails =
      projectProfilesInProjectDetails;
  }

  // Call the function with a specific project ID
  fetchProjectProfiles(store.state.currentProjectDetails.id)
    .then(() => {
      let allRecommendedProfiles =
        store.state.currentProjectDetails.recommendedprofiles;

      if (
        allRecommendedProfiles == undefined ||
        allRecommendedProfiles.length == 0
      ) {
        store.state.recommendedProfilesInProjectDetails = [];
        return;
      }

      // Create an object to store the counts of each profile_id
      let idCounts = {};

      // Iterate over the recommendedProfiles array and count the number of occurrences of each profile_id
      allRecommendedProfiles.forEach((profile) => {
        if (!idCounts[profile.profile_id]) {
          idCounts[profile.profile_id] = 0;
        }
        idCounts[profile.profile_id]++;
      });

      // Filter the recommendedProfiles array to return only the objects with a profile_id that appears more than once
      allRecommendedProfiles = allRecommendedProfiles.filter(
        (profile) => idCounts[profile.profile_id] > 1
      );

      let recommendedProfiles = [];

      // Filter out objects with duplicate profile_id values from the allRecommendedProfiles array
      let uniqueRecommendedProfiles = allRecommendedProfiles.filter(
        (profile, index, self) => {
          return (
            self.findIndex((p) => p.profile_id === profile.profile_id) === index
          );
        }
      );

      // Concatenate the uniqueRecommendedProfiles array into the recommendedProfiles array
      recommendedProfiles = recommendedProfiles.concat(
        uniqueRecommendedProfiles
      );

      let uniqueRecommendedProfilesNotYetInProject = recommendedProfiles.filter(
        (recommendedProfile) => {
          return store.state.projectProfilesInProjectDetails.every(
            (currentProfile) => {
              return (
                currentProfile.firstname !== recommendedProfile.first_name ||
                currentProfile.lastname !== recommendedProfile.last_name
              );
            }
          );
        }
      );

      if (store.state.currentProjectDetails.discardedrecommendedprofiles) {
        const discardedrecommendedprofiles =
          store.state.currentProjectDetails.discardedrecommendedprofiles;
        uniqueRecommendedProfilesNotYetInProject =
          uniqueRecommendedProfilesNotYetInProject.filter(
            (item) => !discardedrecommendedprofiles.includes(item.profile_id)
          );
      }

      store.state.recommendedProfilesInProjectDetails =
        uniqueRecommendedProfilesNotYetInProject;
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

//get profiles who declined GDPR consent (GDPR Management)
const displayGDPRDeclinedProfilesExternal = () => {
  //in case the SelectAll checkbox was checked, uncheck it
  if (document.getElementById("selectAllBtn")) {
    //if the button exists, aka we are on profilelist.vue and not profiledetails
    document.getElementById("selectAllBtn").checked = false;
  }

  //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
  store.state.selectedProfiles = [];

  //go back to the initial number of loaded profiles (for faster loading)
  store.state.numberOfProfilesLoaded =
    store.state.numberOfProfilesLoadedInitially;

  //Empty profiles in cache (Vuex array): in case the user switches from an account to another, to avoid permission problems in Firebase
  store.state.currentProfileList = [];

  //Empty profiles filter in case any filters were applied
  store.state.filterAppliedToProfiles = false;
  store.state.addedByMe = false;
  store.state.visibleToClient = false;
  store.state.gdprFilterApplied = false;
  store.state.gdprFilterValue = "";
  store.state.tagFilterArray = [];
  store.state.statusFilterArray = [];

  //Unsubscribe to other Profile snapshot listeners,
  //including the ones to be created below
  //(when the user switches from Recent profiles to Project Profile and back to Recent profile)
  if (unsubscribeToProjectProfilesExternal) {
    unsubscribeToProjectProfilesExternal();
    console.log("unsubbed from: unsubscribeToProjectProfilesExternal");
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubscribeToProjectProfilesExternal = undefined;
  }

  if (unsubToProfilesRecentlyUpdatedByUser) {
    unsubToProfilesRecentlyUpdatedByUser();
    console.log("unsubbed from: unsubToProfilesRecentlyUpdatedByUser");
    //Empty this variable to reset initial state (can now be reassigned on new query)
    unsubToProfilesRecentlyUpdatedByUser = undefined;
  }

  //For toolbar header
  store.state.showingProfilesAs = "searchResults";

  //if user is on the Extension page, the following code should not run. It could create duplicates in the Vuex array (currentProfileList).
  if (window.location.href.includes("extension")) {
    return;
  }

  //build the query to get the first 100
  const qGDPRdeclined = query(
    collection(db, "profiles"),
    where("gdprconsentstatus", "==", "Consent declined"),
    where("owninguser", "==", store.state.userData.userid),
    orderBy("latestupdatetimestamp", "desc"),
    limit(100)
  );

  let profiles = [];

  getDocs(qGDPRdeclined).then((gdprprofilessnap) => {
    gdprprofilessnap.forEach((gdprprofilesdoc) => {
      let profile = gdprprofilesdoc.data();
      profile.id = gdprprofilesdoc.id;

      profiles.push(profile);
    });

    store.state.currentProfileList = profiles;

    //searchOn defines that there has been a search query, so that the user can click on "Search results" in the profiles view selector in Toolbar.
    store.state.searchOn = true;

    //populating search results so that the user can go back to them later
    store.state.searchResults = store.state.currentProfileList;
  });
};

//get single client
async function getSingleClientExternal(clientid) {
  //avoid flickering showing the recent profiles of the previous project
  store.state.clientContactsForThisClient = [];

  //this prevents flickering when going from a client to another
  store.state.currentClientDetails = false;

  if (unsubToSingleClient) {
    unsubToSingleClient();
  }

  unsubToSingleClient = onSnapshot(
    doc(db, "clients", clientid),
    (singleClientDoc) => {
      if (singleClientDoc.exists()) {
        store.state.currentClientDetails = singleClientDoc.data();
        store.state.currentClientDetails.id = singleClientDoc.id;
      }
    }
  );
}

//get latest viewed profiles (for mobile version)
const getLatestViewedProfilesExternal = (profileidsarray) => {};

//count number of profiles who have declined GDPR consent (to display the number in the GDPR management modal)
const countnumberofprofileswhohavedeclinedExternal = () => {
  const coll = collection(db, "profiles");
  const qDeclined = query(
    coll,
    where("owninguser", "==", store.state.userData.userid),
    where("gdprconsentstatus", "==", "Consent declined")
  );
  getCountFromServer(qDeclined).then((numberofprofilessnap) => {
    let numberofprofileswhodeclined = numberofprofilessnap.data().count;
    store.state.numberofdeclined = numberofprofileswhodeclined;
  });
};

/*
const getCalendarEventsExternal = async (userNylasToken) => {



  try {

      const getCalendarEventsFunction = httpsCallable(functions, 'getCalendarEvents');
      const result = await getCalendarEventsFunction({ accessToken: userNylasToken });
      const events = result.data.events;
      // Do something with the events, e.g., display them in the UI
      console.log("here are the calendar events: " + result.data.events)
  } catch (error) {
      console.error('Error fetching calendar events:', error);
  }
};
*/

//Helper function to filter out duplicate Nylas events
function filterDuplicateEvents(events) {
  const unique = {};

  events.forEach((event) => {
    // Create a unique key by combining the 'title' and 'when' properties
    const uniqueKey = `${event.title}-${event.when.start_time}-${event.when.end_time}`;

    // Store the event in the 'unique' object using this unique key
    unique[uniqueKey] = event;
  });

  // Return an array of the unique events
  return Object.values(unique);
}

async function fetchCalendarEventsExternal(accessToken) {
  try {
    const fetchEventsFunction = httpsCallable(functions, "fetchCalendarEvents");
    const result = await fetchEventsFunction({ accessToken: accessToken });
    const eventsJson = result.data.events;
    const events = eventsJson.map((eventJson) => JSON.parse(eventJson));

    // Filter out duplicate events
    const uniqueEvents = filterDuplicateEvents(events);

    store.state.userCalendarEvents = false;
    store.state.userCalendarEvents = uniqueEvents; // Store the unique events in Vuex
    return uniqueEvents; // Return the events to the caller
  } catch (error) {
    console.error("Error fetching calendar events:", error);
  }
}





export {
  getUserDataExternal,
  getProfilesForThisProjectExternal,
  getRecentProfilesExternal,
  getSingleProfileExternal,
  getSingleProjectExternal,
  getSingleClientExternal,
  getSearchResultsExternal,
  getGlobalAppSettings,
  getLatestViewedProfilesExternal,
  displayGDPRDeclinedProfilesExternal,
  countnumberofprofileswhohavedeclinedExternal,
  getNotifications,
  getAllEmailsIn,
  getAllOpenTasks,
  checkForRecommendedProfilesFromLinkedInExternal,
  fetchAndShowAISearchResultsForProjectExternal,
  fetchCalendarEventsExternal,
};
