<template>
  <div
    v-if="loadingsimulation"
    style="margin-left: 45%; margin-right: 20%; margin-top: 20%"
  >
    <div class="ms-5 mt-10 me-20" id="preloader_1">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <!--begin: Page-->
  <div
    class="p-10 animate__animated animate__fadeIn"
    v-if="
      !loadingsimulation &&
      $store.state.currentProjectDetails &&
      $store.state.currentProfileList &&
      $store.state.userData.email &&
      ($store.state.currentProjectDetails.authorisedclientusersforportal.includes(
        $store.state.userData.email
      ) ||
        ($store.state.userData.subscriptiontype != 'client' &&
          $store.state.userData.teamid ==
            $store.state.currentProjectDetails.team)) &&
      $store.state.currentProjectDetails.portalactive
    "
  >
    <!--begin: Page Header-->
    <div class="row">
      <div class="d-flex">
        <div class="">
          <img height="50" :src="$store.state.teamData.teamlogourl" alt="" />
        </div>
        <div class="ms-auto dropdown">
          <button
            class="btn btn-sm btn-transparent border border-1 border-gray-500 text-muted"
            type="button"
            id="clientuserprofilebtn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/communication/com006.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-2"
              ><svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                  fill="currentColor"
                />
                <path
                  d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                  fill="currentColor"
                />
                <rect
                  x="7"
                  y="6"
                  width="4"
                  height="4"
                  rx="2"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
            {{
              computedUser(
                $store.state.userData.email,
                null,
                "fromprofilebutton"
              )
            }}
          </button>
          <ul
            class="dropdown-menu py-2 menu-sub menu-sub-dropdown bg-transparent border border-0"
            aria-labelledby="clientuserprofilebtn"
            role="menu"
          >
            <li class="">
              <button class="ms-9 btn btn-sm btn-light" @click="signOut()">
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr096.svg-->
                <span
                  class="svg-icon svg-icon-muted svg-icon-2"
                  style="margin-left: -6px; margin-right: -0px"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.3"
                      width="12"
                      height="2"
                      rx="1"
                      transform="matrix(-1 0 0 1 20 11)"
                      fill="currentColor"
                    />
                    <path
                      d="M18.1313 11.6927L16.3756 10.2297C15.9054 9.83785 15.8732 9.12683 16.306 8.69401C16.6957 8.3043 17.3216 8.28591 17.7336 8.65206L20.6592 11.2526C21.1067 11.6504 21.1067 12.3496 20.6592 12.7474L17.7336 15.3479C17.3216 15.7141 16.6957 15.6957 16.306 15.306C15.8732 14.8732 15.9054 14.1621 16.3756 13.7703L18.1313 12.3073C18.3232 12.1474 18.3232 11.8526 18.1313 11.6927Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.5"
                      d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
                Log-out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--end: Page Header-->

    <div class="row">
      <div class="col-8 me-10 mt-20" style="margin-left: 220px">
        <div
          class="card mb-6 bg-opacity-0"
          v-if="$store.state.currentProjectDetails"
        >
          <div class="card-body pt-9 pb-0">
            <!--begin::Details-->
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="me-7 mb-4">
                <div
                  class="symbol border border-1 border-dark symbol-100px symbol-lg-55px symbol-fixed position-relative"
                >
                  <img
                    :src="
                      $store.state.currentProjectDetails.clientobject
                        .clientlogourl
                    "
                    alt="image"
                  />
                </div>
              </div>
              <!--end::Pic-->

              <!--begin::Info-->
              <div>
                <!--begin::Title-->
                <div
                  class="d-flex justify-content-between align-items-start flex-wrap mb-2"
                >
                  <!--begin::User-->
                  <div class="d-flex flex-column">
                    <!--begin::Name-->
                    <div class="d-flex align-items left mb-2">
                      <div class="text-white fs-2 fw-bold">
                        {{ $store.state.currentProjectDetails.positiontitle }}
                      </div>
                    </div>
                    <!--end::Name-->
                    <!--begin::Number of profiles-->
                    <div v-if="!searchString" class="text-white fs-6">
                      {{ computedProfiles.length + " Profiles shared" }}
                    </div>
                    <div v-if="searchString" class="text-white fs-6">
                      {{ computedProfiles.length + " Profile(s) found" }}
                    </div>

                    <!--end::Number of profiles-->
                  </div>
                  <!--end::User-->
                </div>
                <!--end::Title-->
              </div>
              <!--end::Info-->

              <!--begin::sort Profiles-->
              <div class="d-flex ms-auto mt-6" style="margin-right: -70px">
                <div class="me-3">
                  <div class="input-group">
                    <input
                      type="text"
                      style="height: 37px"
                      class="form-control bg-opacity-0 text-white fs-7 border border-1 border-light border-dashed"
                      placeholder="Search profiles"
                      v-model="searchString"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <span
                      class="input-group-text border border-1 custompointer border-light border-dashed"
                      :class="{
                        'bg-opacity-0': !searchActive,
                        'bg-white': searchActive,
                      }"
                      @click="searchString = ''"
                      style="height: 37px; width: 35px"
                      id="basic-addon2"
                    >
                      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr088.svg-->
                      <span
                        class="svg-icon svg-icon-1"
                        :class="{
                          'svg-icon-light': !searchActive,
                          'svg-icon-primary': searchActive,
                        }"
                        style="margin-left: -8px"
                        ><svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x="7.05025"
                            y="15.5356"
                            width="12"
                            height="2"
                            rx="1"
                            transform="rotate(-45 7.05025 15.5356)"
                            fill="currentColor"
                          />
                          <rect
                            x="8.46447"
                            y="7.05029"
                            width="12"
                            height="2"
                            rx="1"
                            transform="rotate(45 8.46447 7.05029)"
                            fill="currentColor"
                          /></svg
                      ></span>
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                </div>
                <SortProfileList></SortProfileList>
              </div>
              <!--end::sort Profiles-->
            </div>
            <!--end::Details-->
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2"></div>
      <div class="col-8 bg-light border border-0 rounded p-10">
        <div
          class="fs-6 fw-normal text-muted"
          v-if="computedProfiles.length == 0 && !searchString"
        >
          No profiles shared. If you are supposed to see profiles here, please
          contact your search partner.
        </div>
        <div
          class="fs-6 fw-bolder text-muted"
          v-if="computedProfiles.length == 0 && searchString"
        >
          No profiles found.
        </div>
        <div v-else v-for="profile in computedProfiles" :key="profile.id">
          <div
            class="card mb-5 mb-xxl-8 customcollapse shadow-sm"
            :id="profile.id + 'portalcard'"
          >
            <!--begin::Body-->
            <div class="card-body p-12">
              <!--begin::Header-->
              <div class="d-flex align-items-center">
                <!--begin::User-->
                <div class="d-flex flex-column flex-grow-1">
                  <div class="d-flex">
                    <div class="symbol symbol-circle symbol-65px me-5">
                      <img
                        v-if="profile.profilepic"
                        :src="profile.profilepic"
                        alt=""
                      />
                      <img
                        alt="Pic"
                        v-if="!profile.profilepic"
                        src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                      />
                    </div>
                    <!--begin::Info-->
                    <div class="d-flex flex-column">
                      <div class="d-flex">
                        <h5>
                          {{ profile.firstname + " " + profile.lastname }}
                        </h5>
                        <div style="margin-top: -2px">
                          <span
                            class="badge badge-sm badge-light ms-3"
                            :class="{
                              'badge-light-success':
                                getStatusCategory(profile.statusid) ==
                                'Interested',
                              'badge-light-primary':
                                getStatusCategory(profile.statusid) ==
                                'In progress',
                              'badge-light-danger':
                                getStatusCategory(profile.statusid) ==
                                'Discarded',
                              'badge-light-white':
                                getStatusCategory(profile.statusid) ==
                                'No status',
                            }"
                            >{{ computedStatus(profile.statusid) }}
                          </span>
                        </div>
                      </div>

                      <h5 class="text-gray-400 fw-bold">
                        {{ profile.currentjobtitle }}
                      </h5>
                      <h5 class="">{{ profile.currentcompany }}</h5>
                    </div>
                    <!--end::Info-->
                  </div>

                  <div style="margin-left: 89px"></div>
                </div>
                <!--end::User-->

                <!--begin::right side buttons-->
                <div class="my-0">
                  <div class="" style="margin-top: -40px">
                    <!--begin:Go to LinkedIn profile Btn-->
                    <a
                      v-if="profile.linkedinurl"
                      @click="goToLinkedinProfile(profile.linkedinurl)"
                      class="btn btn-icon btn-white btn-sm border border-primary"
                      ><i
                        class="fab fa-linkedin-in text-primary"
                        aria-hidden="true"
                      ></i
                    ></a>
                    <!--end:Go to LinkedIn profile Btn-->
                    <!--begin:Show CV Btn-->
                    <a
                      v-if="profile.cvfile"
                      href=""
                      class="btn btn-icon btn-white btn-sm border border-primary text-primary fw-boldest fs-7 ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#fileviewer"
                      @click="storeFileForDisplay(profile.cvfile)"
                      >CV</a
                    >
                    <!--end:Show CV Btn-->
                  </div>
                </div>
                <!--end::right side buttons-->
              </div>
              <!--end::Header-->

              <div class="row">
                <div class="d-flex" style="margin-top: -20px">
                  <div class="ms-auto">
                    <div class="d-flex">
                      <div
                        class="mt-2 me-3 animate__animated animate__fadeIn"
                        v-if="profile.notes"
                        :id="'profilenotes' + profile.id"
                      >
                        <div
                          class="badge badge-sm badge-light position-relative"
                        >
                          Notes
                          <span
                            class="bullet bullet-dot bg-primary me-5 position-absolute top-0 start-100 translate-middle h-5px w-5px"
                          ></span>
                        </div>
                      </div>

                      <div
                        class="mt-2"
                        v-if="
                          computedComments(profile.comments) &&
                          computedComments(profile.comments).length > 0
                        "
                      >
                        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/communication/com003.svg-->
                        <span
                          :id="profile.id + 'commentcount1'"
                          class="svg-icon svg-icon-muted svg-icon-1 animate__animated animate__fadeIn"
                          ><svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                              fill="currentColor"
                            />
                            <path
                              d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                      <!--end::Svg Icon-->
                      <div
                        :id="profile.id + 'commentcount2'"
                        v-if="
                          computedComments(profile.comments) &&
                          computedComments(profile.comments).length > 0
                        "
                        style="margin-top: -5px; margin-left: -10px"
                      >
                        <span
                          class="badge badge-sm badge-circle badge-secondary animate__animated animate__fadeIn"
                          >{{ profile.comments.length }}</span
                        >
                      </div>

                      <button
                        class="btn btn-sm btn-white ms-1"
                        :id="profile.id + 'expandbtn'"
                        @click="toggleCollapse(profile)"
                      >
                        Details
                        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr082.svg-->
                        <span
                          class="svg-icon svg-icon-muted svg-icon-2"
                          style="margin-right: -10px"
                          ><svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.5"
                              d="M12.5657 9.63427L16.75 5.44995C17.1642 5.03574 17.8358 5.03574 18.25 5.44995C18.6642 5.86416 18.6642 6.53574 18.25 6.94995L12.7071 12.4928C12.3166 12.8834 11.6834 12.8834 11.2929 12.4928L5.75 6.94995C5.33579 6.53574 5.33579 5.86416 5.75 5.44995C6.16421 5.03574 6.83579 5.03574 7.25 5.44995L11.4343 9.63427C11.7467 9.94669 12.2533 9.94668 12.5657 9.63427Z"
                              fill="currentColor"
                            />
                            <path
                              d="M12.5657 15.6343L16.75 11.45C17.1642 11.0357 17.8358 11.0357 18.25 11.45C18.6642 11.8642 18.6642 12.5357 18.25 12.95L12.7071 18.4928C12.3166 18.8834 11.6834 18.8834 11.2929 18.4928L5.75 12.95C5.33579 12.5357 5.33579 11.8642 5.75 11.45C6.16421 11.0357 6.83579 11.0357 7.25 11.45L11.4343 15.6343C11.7467 15.9467 12.2533 15.9467 12.5657 15.6343Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </button>
                      <button
                        class="d-none btn btn-sm btn-icon btn-light ms-3"
                        :id="profile.id + 'collapsebtn'"
                        @click="toggleCollapse(profile)"
                      >
                        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr089.svg-->
                        <span class="svg-icon svg-icon-muted svg-icon-2"
                          ><svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="6"
                              y="11"
                              width="12"
                              height="2"
                              rx="1"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-5 me-5" style="margin-left: 80px">
                  <div class="d-flex">
                    <div class="mt-3 text-muted">
                      <i class="bi bi-geo-alt-fill mt-2"></i>
                      {{ profile.location }}
                    </div>
                  </div>
                  <div class="mt-10 mb-5">
                    <h3>Experience</h3>
                  </div>

                  <div class="timeline-label">
                    <div
                      v-for="exp in profile.experiencelist"
                      :key="exp.id"
                      class="timeline-item mt-5"
                    >
                      <!--begin::Label-->
                      <div
                        v-if="exp.endyear != 3000"
                        class="timeline-label text-gray-800 fs-7"
                      >
                        {{ exp.endyear }} - {{ exp.startyear }}
                      </div>
                      <div
                        v-if="exp.endyear == 3000"
                        class="timeline-label text-gray-800 fs-7"
                      >
                        Today - {{ exp.startyear }}
                      </div>

                      <!--end::Label-->
                      <!--begin::Badge-->
                      <div class="timeline-badge">
                        <i class="fa fa-genderless text-primary fs-1"></i>
                      </div>
                      <!--end::Badge-->
                      <!--begin::Text-->
                      <div class="d-flex flex-column">
                        <div class="timeline-content d-flex">
                          <span class="fw-bold text-gray-800 ps-3"
                            >{{ exp.company }}
                          </span>
                        </div>
                        <div class="fw-mormal timeline-content text-muted ps-3">
                          {{ exp.title }}
                        </div>
                      </div>

                      <!--end::Text-->
                    </div>
                  </div>

                  <div class="mt-10">
                    <h3>Education</h3>
                  </div>

                  <!--begin::Timeline-->
                  <div class="timeline-label">
                    <!--begin::Item-->

                    <div
                      v-for="edu in profile.educationlist"
                      :key="edu.id"
                      class="timeline-item mt-5"
                    >
                      <!--begin::Label-->
                      <div
                        v-if="edu.endyear != 'nodatahere'"
                        class="timeline-label text-gray-800 fs-7"
                      >
                        {{ edu.endyear }} - {{ edu.startyear }}
                      </div>
                      <div
                        v-else
                        class="timeline-label text-gray-800 fs-7"
                      ></div>

                      <!--end::Label-->
                      <!--begin::Badge-->
                      <div class="timeline-badge">
                        <i class="fa fa-genderless text-warning fs-1"></i>
                      </div>
                      <!--end::Badge-->
                      <!--begin::Text-->
                      <div class="d-flex flex-column">
                        <div class="timeline-content d-flex">
                          <span class="fw-bold text-gray-800 ps-3"
                            >{{ edu.school }}
                          </span>
                        </div>
                        <div class="fw-mormal timeline-content text-muted ps-3">
                          {{ edu.degree_name }}
                        </div>
                      </div>

                      <!--end::Text-->
                    </div>

                    <!--end::Item-->
                  </div>
                  <!--end::Timeline-->
                </div>

                <div class="col-5 ms-10 pe-10" style="margin-top: 90px">
                  <div class="d-flex flex-column">
                    <div
                      class="mb-10 border border-primary border-dashed rounded p-5"
                    >
                      <h3 class="mb-5">Notes</h3>
                      <span v-if="profile.notes">
                        {{ profile.notes }}
                      </span>
                      <span v-if="!profile.notes" class="text-muted">
                        No notes
                      </span>
                    </div>
                    <div
                      class="border border-primary border-dashed rounded p-5"
                    >
                      <h3>Comments</h3>
                      <div class="timeline mt-10">
                        <div class="timeline-item">
                          <!--begin::Timeline line-->
                          <div class="timeline-line w-40px"></div>
                          <!--end::Timeline line-->
                          <!--begin::Timeline icon-->
                          <div
                            class="ms-1 timeline-icon symbol symbol-circle border border-2 symbol-40px me-4"
                          >
                            <div class="symbol symbol-circle symbol-35px">
                              <img
                                src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <!--end::Timeline icon-->
                          <!--begin::Timeline content-->
                          <div class="timeline-content mb-8 mt-n1">
                            <!--begin::Input group-->
                            <div class="form-floating d-flex flex-column">
                              <textarea
                                class="form-control"
                                placeholder="Leave a comment here"
                                v-model="newCommentText"
                                @focus="showingSaveBtn = true"
                                id="floatingTextarea2"
                                style="height: 80px"
                              ></textarea>
                              <label for="floatingTextarea2"
                                >Write something...</label
                              >
                              <button
                                v-if="showingSaveBtn"
                                class="ms-auto btn btn-sm btn-primary mt-2 animate__animated animate__fadeIn"
                                @click="saveComment(profile)"
                              >
                                Save
                              </button>
                            </div>
                            <!--end::Input group-->
                          </div>
                        </div>
                        <TransitionGroup
                          name="list"
                          tag="div"
                          v-if="computedComments(profile.comments)"
                        >
                          <div
                            class="timeline-item"
                            v-for="comment in computedComments(
                              profile.comments
                            )"
                            :key="comment.id"
                          >
                            <!--begin::Timeline line-->
                            <div class="timeline-line w-40px"></div>
                            <!--end::Timeline line-->
                            <!--begin::Timeline icon-->
                            <div
                              class="ms-1 timeline-icon symbol symbol-circle symbol-40px me-4"
                            >
                              <div class="symbol symbol-circle symbol-35px">
                                <img
                                  v-if="
                                    !comment.userisaclient &&
                                    getUserAvatar(comment.userwhocomments)
                                  "
                                  :src="getUserAvatar(comment.userwhocomments)"
                                  alt="img"
                                />
                                <div
                                  v-if="
                                    !comment.userisaclient &&
                                    !getUserAvatar(comment.userwhocomments)
                                  "
                                  class="symbol-label fs-5 fw-semibold text-primary"
                                >
                                  {{
                                    getUserInitialsIfNoAvatar(
                                      comment.userwhocomments
                                    )
                                  }}
                                </div>

                                <img
                                  v-if="comment.userisaclient"
                                  src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                                  alt="img"
                                />
                              </div>
                            </div>
                            <!--end::Timeline icon-->
                            <!--begin::Timeline content-->
                            <div class="timeline-content mb-8 mt-n1">
                              <!--begin::Timeline content-->
                              <div class="d-flex flex-column">
                                <div
                                  v-if="comment.userisaclient"
                                  style="margin-bottom: -10px; z-index: 2"
                                >
                                  <span
                                    class="badge badge-sm badge-light text-primary"
                                    >Client ({{
                                      computedUser(
                                        null,
                                        comment.userwhocomments,
                                        "fromcomment"
                                      )
                                    }})</span
                                  >
                                </div>
                                <div
                                  class="timeline-content mb-2 bg-light p-3 rounded"
                                >
                                  <!--begin::Timeline main-->
                                  <div class="pe-3 mb-5">
                                    <!--begin::Description-->
                                    <div
                                      v-if="comment.commenttext"
                                      class="d-flex align-items-center mt-1 fs-6"
                                    >
                                      <!--begin::Info-->
                                      <div class="d-flex flex-column">
                                        <!--commenttext can be either a comment or a task-->
                                        <div
                                          class="d-flex align-items-start"
                                        ></div>
                                        <div
                                          class="fs-7 fw-bold text-gray-800 ps-3"
                                        >
                                          {{ comment.commenttext }}
                                        </div>
                                      </div>
                                      <!--end::Info-->
                                    </div>

                                    <!--end::Description-->
                                  </div>

                                  <!--end::Timeline main-->
                                  <!--begin::Timeline details-->
                                  <div class="overflow-auto"></div>
                                  <!--end::Timeline details-->
                                </div>
                                <!--end::Timeline content-->

                                <!--end::Timeline content-->
                                <!--begin::Info-->
                                <!--begin: Footer-->
                                <div class="d-flex">
                                  <!--begin: Date-->
                                  <div class="d-flex ms-auto">
                                    <div class="fs-7 text-muted">
                                      {{ timelineItemDate(comment.timestamp) }}
                                    </div>
                                    <div
                                      v-if="
                                        timelineItemDate(comment.timestamp) ==
                                          '2 days' ||
                                        timelineItemDate(comment.timestamp) ==
                                          '3 days'
                                      "
                                      class="fs-7 text-muted mb-5"
                                    >
                                      ago
                                    </div>
                                    <div class="fs-7 text-muted">
                                      {{ timelineItemYear(comment.timestamp) }}
                                    </div>
                                  </div>
                                  <!--end: Date-->
                                  <div
                                    v-if="comment.userisaclient"
                                    class="d-flex flex-row"
                                  >
                                    <!--begin::Svg Icon (delete btn) | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr011.svg-->
                                    <span
                                      class="svg-icon svg-icon-muted ms-1 svg-icon-xs custompointer"
                                      @click="
                                        deleteProfileCommentFromPortal(
                                          profile,
                                          comment
                                        )
                                      "
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.5"
                                          x="6"
                                          y="17.3137"
                                          width="16"
                                          height="2"
                                          rx="1"
                                          transform="rotate(-45 6 17.3137)"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="7.41422"
                                          y="6"
                                          width="16"
                                          height="2"
                                          rx="1"
                                          transform="rotate(45 7.41422 6)"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    <!--end::Svg Icon (delete btn)-->
                                  </div>
                                </div>
                                <!--end: Footer-->

                                <!--end::Info-->
                              </div>
                            </div>
                          </div>
                        </TransitionGroup>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <button
                    class="ms-auto me-10 btn btn-sm btn-icon btn-light"
                    :id="profile.id + 'collapsebtn'"
                    @click="toggleCollapse(profile)"
                  >
                    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr089.svg-->
                    <span class="svg-icon svg-icon-muted svg-icon-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="6"
                          y="11"
                          width="12"
                          height="2"
                          rx="1"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </button>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
  <!--end: Page-->

  <div
    v-if="
      !loadingsimulation &&
      (!$store.state.currentProjectDetails ||
        !$store.state.userData.email ||
        !$store.state.currentProjectDetails.authorisedclientusersforportal.includes(
          $store.state.userData.email ||
            !$store.state.currentProjectDetails.portalactive
        )) &&
      $store.state.userData.subscriptiontype != 'client' &&
      $store.state.userData.teamid != $store.state.currentProjectDetails.team
    "
    style="margin-left: 20%; margin-right: 20%; margin-top: 20%"
    class="d-flex flex-column"
  >
    <div class="alert alert-primary d-flex align-items-center p-5 mb-10">
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen050.svg-->
      <span class="svg-icon svg-icon-primary me-5 svg-icon-2hx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
            fill="currentColor"
          />
          <rect
            x="9"
            y="13.0283"
            width="7.3536"
            height="1.2256"
            rx="0.6128"
            transform="rotate(-45 9 13.0283)"
            fill="currentColor"
          />
          <rect
            x="9.86664"
            y="7.93359"
            width="7.3536"
            height="1.2256"
            rx="0.6128"
            transform="rotate(45 9.86664 7.93359)"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">Alert</h4>
        <span
          >You do not have access to this project. Please contact your search
          partner for support.</span
        >
      </div>
    </div>
    <div class="mx-auto">
      <button class="btn btn-sm btn-primary" @click="signOut()">
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr096.svg-->
        <span
          class="svg-icon svg-icon-muted svg-icon-2"
          style="margin-left: -6px; margin-right: -0px"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.3"
              width="12"
              height="2"
              rx="1"
              transform="matrix(-1 0 0 1 20 11)"
              fill="currentColor"
            />
            <path
              d="M18.1313 11.6927L16.3756 10.2297C15.9054 9.83785 15.8732 9.12683 16.306 8.69401C16.6957 8.3043 17.3216 8.28591 17.7336 8.65206L20.6592 11.2526C21.1067 11.6504 21.1067 12.3496 20.6592 12.7474L17.7336 15.3479C17.3216 15.7141 16.6957 15.6957 16.306 15.306C15.8732 14.8732 15.9054 14.1621 16.3756 13.7703L18.1313 12.3073C18.3232 12.1474 18.3232 11.8526 18.1313 11.6927Z"
              fill="currentColor"
            />
            <path
              opacity="0.5"
              d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
        Log-out
      </button>
    </div>
  </div>
</template>

<script>
import {
  getProfilesForThisProjectExternal,
  getSingleProjectExternal,
} from "../js/getdata";

import {
  addProfileCommentFromClientExternal,
  deleteProfileCommentExternal,
} from "../js/profiledetails";

import { signOutExternal } from "../js/auth";

import SortProfileList from "../components/Cards/ProfileCardContents/SortProfileList.vue";
import { sortProfileListExternal } from "../js/projectlist";

export default {
  components: {
    SortProfileList,
  },
  data() {
    return {
      loadingsimulation: true,
      showingSaveBtn: false,
      newCommentText: "",
      //this checks if computedProfiles has run once, so that the "sort by status" doesn't re-run every time the computed propperty runs
      computedProfilesHasRun: false,
      searchString: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.loadingsimulation = false;
    }, 1000);


    const queryString = window.location.href.split("?")[1];

    const queryStringArray = queryString.split("&");

    //the object holding the query string parameters
    //params: teamid (not used yet), projectid, teamlogourl
    let params = {};

    for (let i = 0; i < queryStringArray.length; i++) {
      const thisParamArray = queryStringArray[i].split("=");
      const key = thisParamArray[0];
      const value = thisParamArray[1];
      params[key] = value;
    }

    //delay here, because otherwise there's a sync problem between signup, auth and user rendering, which throws a security rules error
    setTimeout(() => {
      getSingleProjectExternal(params.projectid);

      getProfilesForThisProjectExternal(params.projectid);
    }, 1500);
  },
  methods: {
    signOut() {
      signOutExternal();
    },
    goToLinkedinProfile(linkedinurl) {
      window.open(linkedinurl, "_blank");
    },
    storeFileForDisplay(file) {
      this.$store.state.fileToShow = false;
      this.$store.state.fileToShow = file;
    },
    timelineItemDate(timelineItemDate) {
      const itemDate = new Date(timelineItemDate).getDate();
      const itemMonth = new Date(timelineItemDate).getMonth();
      const itemYear = new Date(timelineItemDate).getFullYear();

      const currentDate = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      if (itemDate == currentDate) {
        return "Today";
      } else if (
        itemDate == currentDate - 1 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "Yest.";
      } else if (
        itemDate == currentDate - 2 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "2 days";
      } else if (
        itemDate == currentDate - 3 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "3 days";
      } else {
        const monthName = new Date(timelineItemDate).toLocaleString("en-US", {
          month: "short",
        });
        const finalDate = monthName + ". " + itemDate;

        return finalDate;
      }
    },
    timelineItemYear(timelineItemDate) {
      const itemYear = new Date(timelineItemDate).getFullYear();
      const currentYear = new Date().getFullYear();

      if (itemYear == currentYear) {
        return "";
      } else {
        return " ," + itemYear;
      }
    },
    initializeEditCommentModal(comment) {
      this.$store.state.currentProfileComment = "";
      this.$store.state.currentProfileComment = comment;

      document.getElementById("editProfileCommentTextarea").style.height =
        "250px";
    },
    getUserAvatar(userid) {

 
      //for the comments only
      const users = this.$store.state.teammembers;
      //only returns the comments with reference to the project of this profile/project binding
      let byuser = users.filter((obj) => {
        //first check if the comment has a project reference
        return obj.userid === userid;
      });
      if (byuser[0].avatarurl) {
        return byuser[0].avatarurl;
      } else {
        return false;
      }
        




    },
    getUserInitialsIfNoAvatar(userid) {
  
        //for the comments only
        const users = this.$store.state.teammembers;
        //only returns the comments with reference to the project of this profile/project binding
        let byuser = users.filter((obj) => {
          //first check if the comment has a project reference
          return obj.userid === userid;
        });

        console.log(byuser[0].firstname.substring(0, 1))

        return (
          byuser[0].firstname.substring(0, 1) +
          byuser[0].lastname.substring(0, 1)
        );

    },
    computedUser(email, userid, origin) {
      if (origin === "fromprofilebutton") {
        const user =
          this.$store.state.currentProjectDetails.authorisedclientusersforportal.find(
            (member) => member.email === email
          );

        if (user) {
          return user.firstname;
        } else {
          return this.$store.state.userData.firstname;
        }
      } else if (origin === "fromcomment") {
        const user = this.$store.state.teammembers.find(
          (member) => member.userid === userid
        );

        if (!user) {
          return "User not found";
        }

        if (typeof user.subscriptiontype === "undefined") {
          return "Subscription type undefined";
        }

        if (user.subscriptiontype === "client") {
          const user2 =
            this.$store.state.currentProjectDetails.authorisedclientusersforportal.find(
              (member) => member.email === user.email
            );

          if (user2) {
            return user2.firstname;
          } else {
            return "Deleted user";
          }
        } else {
          return "Subscription type not client";
        }
      } else {
        return "Invalid origin";
      }
    },
    toggleCollapse(profile) {
      if (
        document
          .getElementById(profile.id + "portalcard")
          .classList.contains("customcollapseopen")
      ) {
        document
          .getElementById(profile.id + "portalcard")
          .classList.remove("customcollapseopen");

        document
          .getElementById(profile.id + "collapsebtn")
          .classList.add("d-none");

        document
          .getElementById(profile.id + "expandbtn")
          .classList.remove("d-none");
        document
          .getElementById("profilenotes" + profile.id)
          .classList.remove("d-none");
        document
          .getElementById(profile.id + "commentcount1")
          .classList.remove("d-none");
        document
          .getElementById(profile.id + "commentcount2")
          .classList.remove("d-none");
      } else {
        document
          .getElementById(profile.id + "portalcard")
          .classList.add("customcollapseopen");
        document
          .getElementById(profile.id + "expandbtn")
          .classList.add("d-none");
        document
          .getElementById(profile.id + "collapsebtn")
          .classList.remove("d-none");
        document
          .getElementById("profilenotes" + profile.id)
          .classList.add("d-none");
        document
          .getElementById(profile.id + "commentcount1")
          .classList.add("d-none");
        document
          .getElementById(profile.id + "commentcount2")
          .classList.add("d-none");
      }
    },
    computedStatus(statusid) {
      //this takes the profile status id and looks for the corresponding
      //status in the team defined status list.
      //Then it returns the name of the status insead of the id, so that it becomes readable by user

      let allstatuses = this.$store.state.profileStatusList;
      

      //If no status has been assigned yet, the profilestatusid in Firestore and Vuex is 'xxx' by default
      if (statusid == "xxx") {
        
        return "No status";
      }
      //If a status has been assigned...
      else {
        
        //look for the corresponding status
        let statusobject = allstatuses.find((o) => o.id === statusid);

        //if there is no corresponding status in the statuslist because the status has been deleted by user from the statuslist...
        if (statusobject == undefined) {
          return "No status";
        }
        //if there is a corresponding status in the statuslist...
        else {
          //return status name
          return statusobject.status;
        }
      }
    },
    getStatusCategory(statusid) {
      const statusList = this.$store.state.profileStatusList;
      const matchingStatus = statusList.find(
        (status) => status.id === statusid
      );
      if (matchingStatus) {
        const matchingStatusCategory = matchingStatus.category;
        return matchingStatusCategory;
      } else {
        return "No status";
      }
    },
    computedComments(profilecomments) {
      if (!profilecomments) {
        return undefined;
      }

      const filteredComments = profilecomments.filter(
        (comment) => comment.showtoclient === true
      );

      filteredComments.sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });

      return filteredComments;
    },
    saveComment(profile) {
      //catch if user is not a client (normal user who is previewing the portal)
      if (this.$store.state.userData.subscriptiontype != "client") {
        alert(
          "Only client users can leave a comment here. It seems you are a Kiwano user previewing the portal."
        );

        //hide Save button
        this.showingSaveBtn = false;

        //reset comment form
        this.newCommentText = "";

        return;
      }

      //hide Save button
      this.showingSaveBtn = false;

      //create comment id
      let commentidnbr = Math.floor(Math.random() * 1000000) + 1;
      let commentid = commentidnbr.toString();

      //saveComment
      addProfileCommentFromClientExternal(
        profile,
        this.newCommentText,
        commentid
      );

      //update vuex currentProfileList
      const index = this.$store.state.currentProfileList.findIndex(
        (element) => element.id === profile.id
      );

      const commentToAdd = {
        timestamp: Date.now(),
        userwhocomments: this.$store.state.userData.userid,
        userisaclient: true,
        profileteamaccess: profile.teamaccess,
        owninguser: profile.owninguser,
        commenttext: this.newCommentText,
        attachproject: true,
        clientname:
          this.$store.state.currentProjectDetails.clientobject.clientname,
        positiontitle: this.$store.state.currentProjectDetails.positiontitle,
        clientlogo:
          this.$store.state.currentProjectDetails.clientobject.clientlogourl,
        projectid: this.$store.state.currentProjectDetails.id,
        showtoclient: true,
        //we add the profileid so that we can bind the comments to the right profiles when rendering all profiles from a specific project in profilelist.vue
        profileid: profile.id,
        id: commentid,
      };

      this.$store.state.currentProfileList[index].comments.push(commentToAdd);

      //Empty comment textarea
      this.newCommentText = "";
    },
    deleteProfileCommentFromPortal(profile, comment) {
      //before deleting the comment from Firestore, we need to remove it from the rendered array, so that the Vue animation can happen
      const commentList = profile.comments;
      const index = commentList.map((e) => e.id).indexOf(comment.id);
      commentList.splice(index, 1);

      //call Delete function
      deleteProfileCommentExternal(comment.id, profile.id);
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
    computedName() {
      return 1;
    },
    computedProfiles() {
      if (!this.computedProfilesHasRun) {
        this.computedProfilesHasRun = true;
      }

      const filteredProfiles = this.$store.state.currentProfileList.filter(
        (profile) => profile.clientvisible == true
      );

      const filteredProfiles2 = filteredProfiles.filter(
        (profile) =>
          profile.lastname
            .toLowerCase()
            .includes(this.searchString.toLowerCase()) ||
          profile.firstname
            .toLowerCase()
            .includes(this.searchString.toLowerCase()) ||
          profile.currentcompany
            .toLowerCase()
            .includes(this.searchString.toLowerCase()) ||
          profile.currentjobtitle
            .toLowerCase()
            .includes(this.searchString.toLowerCase())
      );

      return filteredProfiles2;
    },
    searchActive() {
      if (this.searchString) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
