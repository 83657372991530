
<template>
  <div class="d-flex flex-column align-items-center text-center">
    <span class="h3">
      Would you like to create a new project ?
    </span>
    <div class="d-flex justify-content-center mt-10">
      <button class="btn btn-primary btn-lg" @click="openaddnewproject">Yes</button>
      <button class="ms-3 btn btn-danger btn-lg ml-2" @click="closemodal">No</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openaddnewproject() {
      this.$store.state.clientincache = true;
      //close current question modal  
      this.closemodal();
      //show add new project modal
      let addnewprojectmodal = new bootstrap.Modal(
        document.getElementById("addnewproject"),
        {
          keyboard: false,
        }
      );
      addnewprojectmodal.show();
    },
    closemodal() {
      $("#askifproject").modal("hide");
    },
  },
};
</script>

<style></style>
