


<template>



		<!--begin::Root-->

		<!--begin::Main app UI-->
		<div class="d-flex flex-column flex-root">

			<!--begin::Page-->
			<div class="page d-flex flex-column flex-column-fluid">



				<!--begin::Main Tab Nav bar-->
				<div style="z-index: 3">
				<MainTabNav
      ></MainTabNav>
				</div>

				<!--end::Main Tab Nav bar-->
				

		

				
				<!--begin::Wrapper : the different tabs of the app are injected here-->
        		<ProfileList v-if="($store.state.showingProfilesAs == 'recentProfiles' || $store.state.showingProfilesAs == 'projectProfiles' || $store.state.showingProfilesAs == 'searchResults')  && $store.state.userData.email"></ProfileList>
				<ProfileDetailsAlt v-if="$store.state.showingProfilesAs == 'profileDetails' && $store.state.userData.email && $store.state.currentProfileDetails"></ProfileDetailsAlt>
				<!--end::Wrapper-->
				

			</div>
			<!--end::Page-->

		</div>




		<!--end::Root-->





</template>

<script>

import ProfileList from '../components/ProfileList.vue'
import ProfileDetailsAlt from '../components/ProfileDetailsAlt.vue'
import MainTabNav from '../components/MainTabNav.vue'
import Modal1 from '../components/Modals/Modal1.vue'
import Modal2 from '../components/Modals/Modal1.vue'
import ViewTitle from '../components/ViewTitle.vue'




export default {
	  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
    components: {
        ProfileList, ProfileDetailsAlt, MainTabNav, Modal1, Modal2, ViewTitle
    },
	methods: {
		closemodals: function() {
			this.$store.state.showusermenu = false;
		}
	},
    created() {
    this.$store.state.currentRoute = this.$route.path
  },
  mounted() {

	  	KTMenu.createInstances();
	  	//KTDrawer.init();
		KTDrawer.createInstances();
		//KTDrawer.handleShow();
    	//KTDrawer.handleDismiss();
		KTScroll.createInstances();
		KTScrolltop.createInstances();
		KTSticky.createInstances();
		KTDialer.createInstances();
		KTImageInput.createInstances();
		KTPasswordMeter.createInstances();
		KTSwapper.createInstances();
		KTToggle.createInstances();
		KTWidgets.init()


  }


}
</script>
