//The only purpose of this component is to redirect the client user to the portal and skipping the auth workflow. 
//Haven't found a better way to do this.

<template>
  
</template>

<script>

import router from "../router/index";

export default {

mounted() {


        const queryString = window.location.href.split("?")[1];

        const queryStringArray = queryString.split("&");

        //the object holding the query string parameters
        //params: teamid (not used yet), projectid, teamlogourl
        let params = {};

        for (let i = 0; i < queryStringArray.length; i++) {
          const thisParamArray = queryStringArray[i].split("=");
          const key = thisParamArray[0];
          const value = thisParamArray[1];
          params[key] = value;
        }

      
          router.push('/portal?projectid=' + params.projectid)

setTimeout(() => {
    location.reload()

          return;
        
}, 1000);
        
        
    
}

}
</script>

<style>

</style>