<template>

<div class="row" style="margin-bottom: -50px;">
<div class="col col-4">
  <ProfileActivityKpi origin="dashboardmodal" :key="$store.state.profileActivityComponentKey"></ProfileActivityKpi>

</div>
<div class="col col-4">

  <NewProjectsKpi :key="$store.state.newProjectsComponentKey"></NewProjectsKpi>
</div>
<div class="col col-4">

  <TimeToHireKpi :key="$store.state.timeToHireComponentKey"></TimeToHireKpi>
</div>
</div>



</template>

<script>

import ProfileActivityKpi from "../../Cards/ProfileActivityKpi.vue"
import NewProjectsKpi from "../../Cards/NewProjectsKpi.vue"
import TimeToHireKpi from "../../Cards/TimeToHireKpi.vue"

export default {

    components: {
        ProfileActivityKpi,
        NewProjectsKpi,
        TimeToHireKpi

    }

}
</script>

<style>

</style>