<template>
  <button
    class="d-flex bg-light bg-hover-secondary p-1 border rounded dropdown"
    id="profileselectclientdropdown"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <span v-if="!$store.state.currentProfileDetails.clientname" class="fw-mormal timeline-content text-dark ps-3">
      Select client
    </span>
          <div
        class="d-flex px-6 py-4 bg-hover-secondary"
        v-else
      >
      <div class="d-flex">
        <div class="symbol symbol-40px">
          <img
            :src="$store.state.currentProfileDetails.clientlogo"
            class="h-40 align-self-center"
            alt=""
          />
        </div>
        <div class="d-flex flex-column ms-3 text-start">
          <div class="text-dark fw-bold fs-6">{{
            $store.state.currentProfileDetails.clientname
          }}</div>

          <div class="text-muted fw-semibold">{{
            $store.state.currentProfileDetails.clientlocation
          }}</div>
        </div>
      </div>
        <div class="ms-6">
          <span class="badge badge-sm"
                :class="{'badge-success': $store.state.currentProfileDetails.clientstatus == 'Client', 
                         'badge-primary': $store.state.currentProfileDetails.clientstatus == 'Lead'
                        }"
          >
          {{ $store.state.currentProfileDetails.clientstatus }}

          </span>
        </div>


      </div>
    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr072.svg-->
    <span class="ms-auto svg-icon svg-icon-muted svg-icon-2"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
          fill="currentColor"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
  </button>
  <!--begin Dropdown menu-->
  <div
    class="
      dropdown-menu
      menu-sub menu-sub-dropdown
      w-300px w-md-350px
      dropdownFadeIn
      
      pt-5
    
    "
    aria-labelledby="profileselectclientdropdown"
    role="menu"
  >
  <div class="scroll h-300px me-2">
    <div v-for="client in $store.state.currentClientList" :key="client.id">
      <div
        class="d-flex px-6 py-4 bg-hover-light"
        @click="assignClientToProfile(client.id)"
      >
        <div class="symbol symbol-40px">
          <img
            :src="client.clientlogourl"
            class="h-40 align-self-center"
            alt=""
          />
        </div>
        <div class="d-flex flex-column ms-3">
          <a class="text-dark fw-bold fs-6">{{
            client.clientname
          }}</a>

          <span class="text-muted fw-semibold d-block">{{
            client.location
          }}</span>
        </div>

        <div class="ms-auto me-4">
          <span class="badge badge-sm"
                :class="{'badge-light-success': client.clientstatus == 'Client', 
                         'badge-light-primary': client.clientstatus == 'Lead'
                        }"
          >
          {{ client.clientstatus }}

          </span>
        </div>


      </div>
    </div>
  </div>

  </div>
  <!--end Dropdown menu-->
</template>

<script>

import { assignClientToProfileExternal } from "../../../js/profiledetails"

export default {



mounted() {
  setTimeout(() => {
  console.log(this.$store.state.currentClientList)    
  }, 1000);

},
methods: {
  assignClientToProfile(clientid) {
    assignClientToProfileExternal(clientid)
  }
}

};
</script>

<style>
</style>