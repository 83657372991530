<template>


    <!-- Offline Banner -->
    <div v-if="!isOnline" class="offline-banner">
      You are currently offline. You actions will not be saved.
    </div>

<button v-show="false" id="guidelauncher">Invisible button clicked in the welcomeUser modal that launches the tutorial on first login</button>


  <!--begin::Alert-->
  <div
    v-if="
      $store.state.userData.subscriptiontype == 'trial' &&
      returnTrialDaysLeft($store.state.userData.signuptimestamp) > 0 &&
      currentRouteName != '/billing' &&
      currentRouteName != '/extension' &&
      currentRouteName != '/paid'
    "
    class="alert alert-primary d-flex align-items-center p-5"
    style="border-radius: 0"
  >
    <!--begin::Icon-->
    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/communication/com009.svg-->
    <span class="svg-icon svg-icon-primary svg-icon-2"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M5.78001 21.115L3.28001 21.949C3.10897 22.0059 2.92548 22.0141 2.75004 21.9727C2.57461 21.9312 2.41416 21.8418 2.28669 21.7144C2.15923 21.5869 2.06975 21.4264 2.0283 21.251C1.98685 21.0755 1.99507 20.892 2.05201 20.7209L2.886 18.2209L7.22801 13.879L10.128 16.774L5.78001 21.115Z"
          fill="currentColor"
        />
        <path
          d="M21.7 8.08899L15.911 2.30005C15.8161 2.2049 15.7033 2.12939 15.5792 2.07788C15.455 2.02637 15.3219 1.99988 15.1875 1.99988C15.0531 1.99988 14.92 2.02637 14.7958 2.07788C14.6717 2.12939 14.5589 2.2049 14.464 2.30005L13.74 3.02295C13.548 3.21498 13.4402 3.4754 13.4402 3.74695C13.4402 4.01849 13.548 4.27892 13.74 4.47095L14.464 5.19397L11.303 8.35498C10.1615 7.80702 8.87825 7.62639 7.62985 7.83789C6.38145 8.04939 5.2293 8.64265 4.332 9.53601C4.14026 9.72817 4.03256 9.98855 4.03256 10.26C4.03256 10.5315 4.14026 10.7918 4.332 10.984L13.016 19.667C13.208 19.859 13.4684 19.9668 13.74 19.9668C14.0115 19.9668 14.272 19.859 14.464 19.667C15.3575 18.77 15.9509 17.618 16.1624 16.3698C16.374 15.1215 16.1932 13.8383 15.645 12.697L18.806 9.53601L19.529 10.26C19.721 10.452 19.9814 10.5598 20.253 10.5598C20.5245 10.5598 20.785 10.452 20.977 10.26L21.7 9.53601C21.7952 9.44108 21.8706 9.32825 21.9221 9.2041C21.9737 9.07995 22.0002 8.94691 22.0002 8.8125C22.0002 8.67809 21.9737 8.54505 21.9221 8.4209C21.8706 8.29675 21.7952 8.18392 21.7 8.08899Z"
          fill="currentColor"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
    <!--end::Icon-->

    <!--begin::Wrapper-->
    <div class="d-flex flex-column">
      <!--begin::Title-->

      <!--end::Title-->
      <!--begin::Content-->
      <span
        ><span class="fw-bolder ms-3"
          >You have
          {{ returnTrialDaysLeft($store.state.userData.signuptimestamp) }}
          day(s) left on your free trial. </span
        ><span
          class="badge badge-sm badge-primary custompointer ms-2 me-2"
          @click="goToBilling()"
          >Upgrade here</span
        >Or reach out to us at
        <a class="fw-bolder" href="mailto:support@kiwanotalent.com"
          >support@kiwanotalent.com</a
        >
        if you have any question.</span
      >

      <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Alert-->

<div class="d-none" id="kt_drawer_chat_toggle">
  <button>Open chat</button>
</div>


  <MainHeader
    v-if="
      (currentRouteName != '/extension' &&
        currentRouteName != '/portal' &&
        currentRouteName != '/mobile' &&
        ($store.state.userData.userid || currentRouteName == '/signin') &&
        currentRouteName != '/gdprexternal') ||
      currentRouteName == '/signup'
    "
    style="z-index: 4"
  ></MainHeader>

  <router-view></router-view>




  <OffCanvasElements
    v-if="
      currentRouteName != '/extension' &&
      currentRouteName != '/signup' &&
      $store.state.userData.subscriptiontype != 'client'
    "
  ></OffCanvasElements>

  <!-- Begin modals -->

  <!--begin::Modal - Welcome user (first time logged in only) -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="welcomeUser"
    :title="'Welcome ' + $store.state.userData.firstname + '!'"
    buttonText="Remove"
  ></Modal1>
  <!--end::Modal - Welcome user (first time logged in only)-->

  <!--begin::Modal - Edit profile status list -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="editProfileStatusList"
    title="Edit status list"
    buttonText="Edit status list"
  ></Modal1>
  <!--end::Modal - Edit profile status list-->

  <!--begin::Modal - Edit profile-project binding -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="editProfileProjectBinding"
    title="Project match"
    buttonText="Remove"
  ></Modal1>
  <!--end::Modal - Edit profile-project binding-->

  <!--begin::Modal - Add profile comment -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="addProfileComment"
    title="New comment"
    buttonText="Remove"
  ></Modal1>
  <!--end::Modal - Add profile comment-->

  <!--begin::Modal - Edit profile comment -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="editProfileComment"
    title="Edit comment"
    buttonText=""
  ></Modal1>
  <!--end::Modal - Edit profile comment-->

  <!--begin::Modal - Add profile Task -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="addProfileTask"
    title="New task"
    buttonText="Remove"
  ></Modal1>
  <!--end::Modal - Add profile Task-->

  <!--begin::Modal - Edit team members -->
  <Modal1 whichModal="editAccount" title="My account"></Modal1>
  <!--end::Modal - Edit team members-->

  <!--begin::Modal - Edit team members -->
  <Modal1
    whichModal="editTeammembers"
    title="My team"
    buttonText=""
    v-if="$store.state.userData.subscriptiontype != 'client'"
  ></Modal1>
  <!--end::Modal - Edit team members-->

  <!--begin::Edit profile tag list -->
  <Modal1
    v-if="
      $store.state.teammembers &&
      $store.state.userData.subscriptiontype != 'client'
    "
    whichModal="editProfileTags"
    title="Edit tag list"
    buttonText=""
  ></Modal1>
  <!--end::Edit profile tag list-->

  <!--begin::Email modal -->
  <Modal1
    whichModal="email"
    title=""
    buttonText=""
    v-if="$store.state.userData.subscriptiontype != 'client'"
  ></Modal1>
  <!--end::Email modal-->

  <!--begin::Edit Email signarture -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="editSignature"
    title="Edit email signature"
    buttonText=""
  ></Modal1>
  <!--end::Edit Email signarture-->

  <!--begin::Edit Email signarture -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="editEmailTemplates"
    title="Email templates"
    buttonText=""
  ></Modal1>
  <!--end::Edit Email signarture-->

  <!--begin::Edit Email signarture -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="recommendedProfiles"
    title="Suggested profiles"
    buttonText=""
  ></Modal1>
  <!--end::Edit Email signarture-->

  <!--begin::Edit Project And Client Info -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="editprojectandclientinfo"
    title="Edit info"
    buttonText=""
  ></Modal1>
  <!--end::Edit Project And Client Info-->

  <!--begin::Add project or client notes -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="addprojectorclientnotes"
    title="Notes"
    buttonText=""
  ></Modal1>
  <!--end::Add project or client notes-->

  <!--begin::Add new profile -->
  <Modal1
    whichModal="addnewprofile"
    title="New profile"
    buttonText=""
    v-if="$store.state.userData.subscriptiontype != 'client'"
  ></Modal1>
  <!--end::Add new profile-->

  <!--begin::Add new Project -->
  <Modal1
    whichModal="addnewproject"
    title="New project"
    buttonText=""
    v-if="$store.state.userData.subscriptiontype != 'client'"
  ></Modal1>
  <!--end::Add new Project-->

  <!--begin::Add new Client -->
  <Modal1
    whichModal="addnewclient"
    title="New client"
    buttonText=""
    v-if="$store.state.userData.subscriptiontype != 'client'"
  ></Modal1>
  <!--end::Add new Client-->

  <!--begin::Ask if project should be created too -->
  <Modal1
    whichModal="askifproject"
    title=""
    buttonText=""
    v-if="$store.state.userData.subscriptiontype != 'client'"
  ></Modal1>
  <!--end::Ask if project should be created too-->

  <!--begin::Edit profile experience -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="editprofileexperience"
    title="Edit experience"
    buttonText=""
  ></Modal1>
  <!--end::Edit profile experience-->

  <!--begin::Generate PR -->
  <Modal1
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="generatePR"
    title="Create progress report"
    buttonText=""
  ></Modal1>
  <!--end::Generate PR-->

  <!--begin::Password reset -->
  <Modal1
    whichModal="resetpassword"
    title="Password reset"
    buttonText=""
  ></Modal1>
  <!--end::Password reset-->

  <!--begin::Generate outreach -->
  <Modal1
    whichModal="generatemessage"
    title="Outreach AI"
    subTitle="Create an outreach message with Chat GPT !"
    buttonText=""
  ></Modal1>
  <!--end::Generate outreach-->

  <!--begin::GDPR Monitoring -->
  <Modal1
    whichModal="gdprmanagement"
    title="GDPR Management"
    subTitle="Manage your GDPR compliance"
    buttonText=""
  ></Modal1>
  <!--end::GDPR Monitoring-->

  <!--begin::GDPR Monitoring -->
  <Modal1
    v-if="$store.state.currentProjectDetails.positiontitle"
    whichModal="projectkpis"
    title="Analytics"
    :subTitle="
      $store.state.currentProjectDetails.positiontitle +
      ' / ' +
      $store.state.currentProjectDetails.clientobject.clientname
    "
    buttonText=""
  ></Modal1>
  <!--end::GDPR Monitoring-->

  <!--begin::Email Integration -->
  <Modal1
    v-if="$store.state.userData.userid"
    whichModal="emailintegration"
    title="Email integration"
    buttonText=""
  ></Modal1>
  <!--end::Email Integration-->

  <!--begin::Configure General Alert -->
  <Modal1
    v-if="$store.state.userData.userid"
    whichModal="configuregeneralalert"
    title="Configure General Alert"
    buttonText=""
  ></Modal1>
  <!--end::Configure General Alert-->

  <!--begin::General Alert Message -->
  <Modal1
    v-if="$store.state.userData.userid"
    whichModal="generalalertmessage"
    title=""
    buttonText=""
  ></Modal1>
  <!--end::General Alert Message-->

    <!--begin::Add Guest Account -->
  <Modal1
    whichModal="addguestaccount"
    title="Add guest account"
    buttonText=""
  ></Modal1>
  <!--end::Add Guest Account-->

    <component
    v-if="showModal"
    :is="currentModalComponent"
    :title="modalTitle"
    :buttonText="modalButtonText"
  ></component>

  <!--begin::Upload file loading animation -->
  <AlertModal
    v-if="$store.state.userData.subscriptiontype != 'client'"
    whichModal="uploadingfilealert"
    title="Uploading file"
    buttonText=""
  ></AlertModal>
  <!--end::Upload file loading animation-->

  <!--begin::Sending email loading animation -->
  <AlertModal
    whichModal="sendingemail"
    title="Sending email, please wait..."
    buttonText=""
  ></AlertModal>
  <!--end::Sending email loading animation-->

  <!--begin::AI Progress animation -->
  <AlertModal
    whichModal="aiprogress"
    title="AI Search"
    buttonText=""
  ></AlertModal>
  <!--end::AI Progress animation-->

  <LargeModal whichModal="fileviewer" title="File preview"></LargeModal>

  <!--begin::Advanced Search -->
  <LargeModal
    whichModal="advancedsearch"
    title="Advanced search"
    subTitle=""
    buttonText=""
  ></LargeModal>
  <!--end::Advanced Search-->

  <!--begin::Dashboard KPIs -->
  <LargeModal
    whichModal="dashboardkpis"
    title="Analytics"
    subTitle="All projects"
    buttonText=""
  ></LargeModal>
  <!--end::Dashboard KPIs-->

    <!--begin::General terms -->
  <LargeModal
    whichModal="generalterms"
    title="General terms"
    subTitle=""
    buttonText=""
  ></LargeModal>
  <!--end::General terms-->

      <!--begin::DPA -->
  <LargeModal
    whichModal="dpa"
    title="Data processing agreement"
    subTitle=""
    buttonText=""
  ></LargeModal>
  <!--end::DPA-->

  <EditClientPortal
    v-if="$store.state.userData.subscriptiontype != 'client'"
  ></EditClientPortal>

  <!-- End modals -->

  <!-- End modals -->

  <StoreEmailToken v-if="$store.state.userData.userid"></StoreEmailToken>
</template>

<script>
import MainHeader from "./components/MainHeader.vue";
import OffCanvasElements from "./components/OffCanvasElements.vue";
import Modal1 from "./components/Modals/Modal1.vue";
import AlertModal from "./components/Modals/AlertModal.vue";
import LargeModal from "./components/Modals/LargeModal.vue";
import EditClientPortal from "./components/Modals/EditClientPortal.vue";
import { getGlobalAppSettings } from "./js/getdata";
import NavigationSideBar from "./components/NavigationSideBar.vue";
import ProfileListLeftPreview from "./components/ProfileListLeftPreview.vue";
import StoreEmailToken from "./components/StoreEmailToken.vue";

import { addTeamIdFunction } from "./js/profiledetails";

import "izitoast/dist/css/iziToast.min.css";
import iziToast from "izitoast";

//import Calendar from "./components/Cards/EverywhereCardContents/Calendar.vue";

import {
  getProjectsExternal,
  getRecentProfilesExternal,
  getClientsExternal,
  getUserDataExternal,
  getClients,
  getSingleProfile,
  getSingleProject,
  getProfilesForThisProjectExternal,
  getNotifications,
  fetchCalendarEventsExternal
} from "./js/getdata";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore/lite";
import store from "../src/store/index";
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getNylasSchedulerURLExternal } from "./js/user"

import { updateEmail } from "firebase/auth";



export default {
  watch: {
    // Watch for route changes
    //to hide Brevo Chat on certain routes
    $route: "checkRoute",
  },
  data() {
    return {
            showModal: false,
      currentModalComponent: null,
      modalTitle: '',
      modalButtonText: '',
      isOnline: navigator.onLine,
    }
  },

  computed: {
    currentRouteName() {
      return this.$route.path;
    },
  },
  components: {
    MainHeader,
    OffCanvasElements,
    Modal1,
    AlertModal,
    LargeModal,
    EditClientPortal,
    NavigationSideBar,
    ProfileListLeftPreview,
    StoreEmailToken,

  },
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mounted() {
    //this.getProjects();

    //this puts focus on the "add profile comment" modal textarea
    $("#addProfileComment").on("shown.bs.modal", function () {
      $("#profileCommentTextarea").focus();
    });

    //this puts focus on the "edit tags" modal textarea
    $("#editProfileTags").on("shown.bs.modal", function () {
      $("#editProfielTagInput").focus();
    });

    //using a delay here as otherwise the route is not resolved and accessible yet.
    //TODO: find a better way for this.

    setTimeout(() => {
      if (this.currentRouteName != "/portal") {
        getRecentProfilesExternal();
      }
    }, 100);

// Page background color change
this.unwatch = this.$watch(
      () => this.$route.path,
      (newPath) => {
        // newPath is the new value of this.$route.path

        let newBackgroundColor;
        switch (newPath) {
          case '/':
          case '/profiles':
            newBackgroundColor = '#316493';
            break;
          case '/projects':
            newBackgroundColor = '#005C54';
            break;
          case '/clients':
            newBackgroundColor = '#513E63';
            break;
          case '/dashboard':
            newBackgroundColor = '#4D4C4A';
            break;
          default:
            newBackgroundColor = '#316493';  // Default background color
            break;
        }
        
        document.body.style.backgroundColor = newBackgroundColor;
      }
    );
    
  },
    beforeUnmount() {
    // Unwatch page background color change
    this.unwatch();
  },
  created() {
    //to hide Brevo Chat on certain routes
    setTimeout(() => {
      this.checkRoute();
    }, 200);

        window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    //Check device and redirect if on mobile
    this.checkDeviceAndRedirect();

    //Check screen width to determine if the ProfileListLeftPreview should be displayed or not (if the screen is wide enough)
    // Define a function to update browser width
    const updateWidth = () => {
      this.$store.state.browserwidth = window.innerWidth;
    };

    updateWidth(); // Initialize the width

    window.addEventListener("resize", updateWidth);

    //Get loggedin user data
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (this.currentRouteName == "/portal") {
          getUserDataExternal();
          getGlobalAppSettings();
        } else {
          getUserDataExternal();
          getGlobalAppSettings();
          getNotifications();
        }
      } else {
        if (
          this.currentRouteName != "/signup" &&
          this.currentRouteName != "/clientauth" &&
          this.currentRouteName != "/gdprexternal" &&
          this.currentRouteName != "/billing"
        ) {
          this.$router.push("/signin");
        }
      }
    });
  },
  beforeUnmount() {
    const updateWidth = () => {
      this.$store.state.browserwidth = window.innerWidth;
    };

    window.removeEventListener("resize", updateWidth);
  },
   beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  methods: {





     updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },


        openModal(modalName, title, buttonText) {
      this.currentModalComponent = () => import(`./components/Modals/Modal1`);
      this.modalTitle = title;
      this.modalButtonText = buttonText;
      this.showModal = true;
    },
      openScheduleEditor() {
    // Prompt the Schedule Editor when a user clicks on the button
    nylas.scheduler.show({
      auth: {
        // Account <ACCESS_TOKEN> with active calendar scope
        accessToken: this.$store.state.userData.nylasemailtoken, // Assuming you have stored the Nylas access token in your component's data
      },
      style: {
        // Style the Schedule Editor
        tintColor: '#32325d',
        backgroundColor: 'white',
      },
      defaults: {
        event: {
          title: '30-min Coffee Meeting',
          duration: 30,
        },
      },
    });
  },

    //to hide Brevo Chat on certain routes
    checkRoute() {
      const routesToHideChat = ["/extension", "/signin", "/signup"]; // replace with your routes
      const brevoWidget = document.getElementById("brevo-conversations");

      if (brevoWidget) {
        if (routesToHideChat.includes(this.$route.path)) {
          // Hide the widget
          brevoWidget.style.display = "none";
        } else {
          // Show the widget
          brevoWidget.style.display = "block";
        }
      }
    },

    returnTrialDaysLeft(timestamp) {
      let currentTimestamp = Date.now();
      let endOfTrialTimestamp = timestamp + 86400000 * 14;
      let millisecondsLeft = endOfTrialTimestamp - currentTimestamp;
      let daysLeft = millisecondsLeft / 86400000;
      let daysLeftRounded = Math.round(daysLeft);

      if (daysLeftRounded > 0) {
        return daysLeftRounded;
      } else {
        this.$router.push("/billing");
      }
    },
    goToBilling() {
      this.$router.push("/billing");
    },
    checkDeviceAndRedirect() {
      const userAgent = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );

      if (isMobile) {
        window.location.href = "https://app.kiwanotalent.com/#/mobile";
      }
    },
  },
};
</script>

<style>
@import "./assets/css/metronicstylebundle.css";

/*Custom CSS*/

.offline-banner {
  background-color: #e69a19;
  color: black;
  padding: 10px;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mce-container {
  border: 0px !important;
}

.custombadgehover:hover {
  opacity: 0.4;
}

.customtablehover:hover {
  background-color: #00466e;
}

.customiconbtn {
  cursor: pointer;
}

.custompointer {
  cursor: pointer;
}

.customiconbtn:hover {
  color: black;
}

.customUpdatedRowAnimation {
  animation-name: customUpdatedRow;
  animation-duration: 1s;
}

@keyframes customUpdatedRow {
  from {
    background-color: #009cf74e;
  }
  to {
    background-color: white;
  }
}

.customProfileStatusMoveUp {
  animation-name: customProfileStatusMoveUp;
  animation-duration: 0.5s;
}

@keyframes customProfileStatusMoveUp {
  0% {
    transform: translate(0px, 0px);
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translate(0px, -65px);
    animation-timing-function: ease-in-out;
  }
}

.customProfileStatusMoveDown {
  animation-name: customProfileStatusMoveDown;
  animation-duration: 0.5s;
}

@keyframes customProfileStatusMoveDown {
  0% {
    transform: translate(0px, 0px);
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translate(0px, 65px);
    animation-timing-function: ease-in-out;
  }
}

.customProfileStatusMargin {
  margin-left: 2.6rem !important;
}

.customfaded {
  opacity: 0.5;
}

/*Begin TOOLTIP*/

/* Tooltip container 
.customtooltip {
}
*/

/* Tooltip text */
.customtooltip .customtooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: white;
  color: rgba(43, 43, 43, 0.763);
  text-align: left;
  padding: 20px;
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);

  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.customtooltip:hover .customtooltiptext {
  visibility: visible;
}

/*End TOOLTIP*/

.customloadmorebtn {
  color: black;
  background-color: grey;
  padding: 0.65rem;
  border-radius: 5%;
}

.custom-range input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 26px;
  height: 8px;
  width: 170px;
  background-color: rgb(180, 180, 180);
}

.custom-range-thumb input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0;
  height: 0;
}

/*Vue List transition animation*/

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

/*Vue DOM node transition animation*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.customdottedunderlined:hover {
  text-decoration: underline dotted;
  text-underline-offset: 2px;
}

.customrobotomonofont {
  font-family: "Roboto Mono";
  font-size: 22px;
}

.customborderbottom {
  border-bottom: solid;
}

.custominput {
  border: none;
}

.custominput:focus {
  outline: none;
}

.customflexcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

/* CSS preloader animation */

#preloader_1 {
  position: relative;
}
#preloader_1 span {
  display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #9b59b6;
  position: absolute;
  animation: preloader_1 1.5s infinite ease-in-out;
}

#preloader_1 span:nth-child(2) {
  left: 11px;
  animation-delay: 0.2s;
}
#preloader_1 span:nth-child(3) {
  left: 22px;
  animation-delay: 0.4s;
}
#preloader_1 span:nth-child(4) {
  left: 33px;
  animation-delay: 0.6s;
}
#preloader_1 span:nth-child(5) {
  left: 44px;
  animation-delay: 0.8s;
}
@keyframes preloader_1 {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #0067a3;
  }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: #009ef7;
  }
  50% {
    height: 5px;
    transform: translateY(0px);
    background: #64c6ff;
  }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #00466e;
  }
}

/* Custom scrollbar: applies to all scrollbars even without class. This interferes with the Metronic .scroll class but does not seem to cause any conflict */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(186, 186, 186);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Begin: Style for file input */
input[type="file"]::before {
  content: "Browse";
}

/* End: Style for file input */

/* custom preloader used for file upload */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #009ef7;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #009ef7 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.lds-ring-small {
  display: inline-block;
  position: relative;
}
.lds-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 4px;
  border: 4px solid #009ef7;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #009ef7 transparent transparent transparent;
}
.lds-ring-small div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-small div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-small div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* custom preloader used for file upload */

.customcollapse {
  max-height: 140px;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.customcollapseopen {
  max-height: 4000px;
  transition: max-height 0.3s ease-in;
}

.custom-sticky-top-offset {
  top: 30px;
  position: sticky;
}

.mb-custom-extension-normal {
  margin-bottom: 120px !important;
}

.mb-custom-extension-big {
  margin-bottom: 300px !important;
}

/* begin:: Slide left / right transition for Mobile UI */

.slideleft-enter-active,
.slideleft-leave-active {
  transition: all 0.2s ease;
}

.slideleft-enter-from {
  transform: translateX(100%);
}

.slideleft-enter-to {
  transform: translateX(0%);
}

.slideleft-leave-from {
  transform: translateX(0%);
}

.slideleft-leave-to {
  transform: translateX(-100%);
}

.slideright-enter-active,
.slideright-leave-active {
  transition: all 0.2s ease;
}

.slideright-enter-from {
  transform: translateX(-100%);
}

.slideright-enter-to {
  transform: translateX(0%);
}

.slideright-leave-from {
  transform: translateX(0%);
}

.slideright-leave-to {
  transform: translateX(100%);
}

/* end:: Slide left / right transition for Mobile UI */

/*begin:: clear notification button animation*/

.notification {
  position: relative;
}

.notification-content {
  flex-grow: 1;
}

.clear-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  opacity: 0;
  height: 35%;
  background-color: #f1416c;
  color: white;
  border: none;
  cursor: pointer;
  transition:
    width 0.3s,
    padding-right 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-right: 0;
}

.notification:hover .clear-btn {
  width: 70px;
  opacity: 1;
  padding-right: 7px;
}

/*end:: clear notification button animation*/

.companylogo img {
  aspect-ratio: 2/2;
  object-fit: contain;
}

.profilespreviewcard {
  position: fixed;
  left: 0;
  top: 185px;
  width: 80px;
  height: 450px;
  z-index: 1000;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  transition:
    width 0.3s,
    background-color 0.3s; /* Add background-color transition */

  background-color: transparent;
}

.profilespreviewcard:hover {
  width: 330px;
  z-index: 2000;
  background-color: #ffffff;
}

.profilespreviewheader {
  position: fixed;
  left: 0;
  top: 135px;
  width: 70px;
  z-index: 4000;
  background-color: transparent;
}

.profilespreviewheader .previewheadercard {
  border-top-right-radius: 10px;
  height: 50px;
  width: 330px;
  background-color: #ffffff;
  padding-top: 12px;
  padding-left: 15px;
}

.scroll-container-content {
  position: relative;
  z-index: 1;
}

.fade-overlay {
  position: absolute;
  width: 100%;
  height: 150px; /* Adjust this value to control the fade-out height */
  z-index: 2;
  pointer-events: none; /* Prevents the overlay from blocking mouse events */
}

.fade-overlay-top {
  top: 0;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgb(49, 100, 147) 100%
  );
}

.fade-overlay-bottom {
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgb(49, 100, 147) 100%
  );
}

.customarrowup {
  top: -25px;
  left: 21px;
  z-index: 3;
}

.customarrowdown {
  bottom: -150px;
  left: 21px;
  z-index: 3;
}

.force-dropdown-down {
  position: relative;
}

.force-dropdown-down::before {
  content: "";
  position: absolute;
  top: -9999px; /* Move the hidden element far up */
  height: 9999px; /* Make the hidden element very tall */
  width: 100%;
  z-index: -1; /* Place the hidden element behind the select element */
}

.custom-accordion-header {
  line-height: 1.2; /* Adjust line-height for better alignment */
}

.custom-accordion-button {
  height: 40px; /* Change the height according to your preference */
  padding: 6px 15px; /* Adjust padding to align text properly within the new height */
  margin-bottom: -1px; /* Fix the issue with the content hiding behind the header */
}

.custom-text-wrap {
  white-space: normal;
  word-wrap: break-word;
  text-align: left;
  line-height: 1.5;
}

/* to make it clear that the Advanced search Filter panel can be scrolled */
.gradient-bottom {
  position: relative;
}

.gradient-bottom::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, white, transparent);
  border-bottom-left-radius: inherit; /* New property */
  border-bottom-right-radius: inherit; /* New property */
}

.ais-RefinementList-count {
  display: none;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
  box-shadow: none !important;
}

.ais-RefinementList-labelText {
  font-family: Poppins, Helvetica, "sans-serif";
}

.custom-shadow {
  box-shadow: 2px 2px 10px 0px rgba(128, 128, 128, 0.2); /* Offset-x | Offset-y | Blur-radius | Spread-radius | Color */
  padding: 10px;
  background-color: #fff; /* Adjust as needed */
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.2;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.2;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;
}

.loading-dots--dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.loading-dots--dot.whitedots {
  background-color: #ffffff;
}

.loading-dots--dot.graydots {
  background-color: #b5b5b5;
}

.loading-dots--dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dots--dot:nth-child(3) {
  animation-delay: 1s;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1 !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #444444;
  color: #ffffff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  line-height: 1.5;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%; /* Place the tooltip text at the bottom */
  left: 50%;
  margin-left: -60px; /* -60 is half the width of the tooltip text to center it*/

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.6s;
}

/* Tooltip text when mouse hovers over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.customrouterlink {
  /* add your desired styles here */
  color: inherit; /* to inherit the color from the parent */
  text-decoration: none; /* to remove the underline */
}

.dropdown-downwards {
  top: 100% !important; /* Position it immediately below the button */
  bottom: auto !important;
  transform: none !important;
}

.smooth-progress {
  transition: width 0.5s ease-out;
}

@keyframes gradientWave {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.progress-bar-gradient {
  width: 100%; /* or whatever width you'd like */
  height: 30px;
  background: linear-gradient(
    90deg,
    #62e1dc 0%,
    /* Darker variant */ #006aa7 50%,
    /* Original color */ #62e1dc 100% /* Lighter variant */
  );
  background-size: 200% 200%;
  animation: gradientWave 0.5s ease infinite alternate; /* 1s for faster, 'alternate' for back-and-forth */
}


/*Smooth color transition when moving through main tabs*/
body {
  transition: background-color 0.3s ease;
}


</style>
