<template>
  <div>
    <!--begin::Task list-->
    <div v-if="filteredTaskList.length > 0">
      <TransitionGroup name="list" tag="div">
        <div
          class="d-flex align-items-start mb-4"
          v-for="task in filteredTaskList"
          :key="task.id"
        >
          <!--begin::Checkbox-->
          <div class="form-check form-check-custom form-check-solid me-5 mt-1">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="task.completed"
              @click="handleProfileTaskCheckBox(task)"
            />
          </div>
          <!--end::Checkbox-->

          <!--begin::Description-->
          <div class="flex-grow-1">
            <span href="#" class="text-gray-800 fw-bold fs-6"
              >{{ task.text }}

              <!--begin::Svg Icon (delete btn) | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr011.svg-->
              <span
                class="svg-icon svg-icon-muted ms-3 svg-icon-xs custompointer mt-1"
                @click="deleteProfileTask(task.id)"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon (delete btn)-->
            </span>

            <span
              class="fw-semibold d-block"
              :class="{
                'text-danger': overdue(task.duedate),
                'text-muted': !overdue(task.duedate),
              }"
            >
              {{ taskDueDate(task.duedate) }} 
            </span>
          </div>
          <!--end::Description-->
          <div class="dropdown" style="margin-top: -8px">
            <button
              class="btn btn-icon px-10 btn-active-color-dark btn-active-light btn-text-white me-3"
              type="button"
              id="dropdownMenuButton32"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div v-if="task.assigneduserobj.avatarurl" class="symbol symbol-circle symbol-25px">
                <img :src="task.assigneduserobj.avatarurl" alt="img" />
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                <span class="ms-1 svg-icon svg-icon-4 m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </div>
                                <div
                    v-else
                    class="d-flex symbol symbol-circle symbol-30px mt-1 ps-3"
                  >
                    <div>
                      <div
                        v-if="task.assigneduserobj.firstname"
                        class="symbol-label fs-8 fw-semibold text-primary"
                      >
                        {{
                          task.assigneduserobj.firstname.substring(0, 1) +
                          task.assigneduserobj.lastname.substring(0, 1)
                        }}
                      </div>
                      
                    </div>
                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                <span class=" svg-icon svg-icon-4 mt-2 me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
                  </div>
            </button>
            <div
              class="dropdown-menu menu-sub menu-sub-dropdown dropdown-menu-end w-300px w-md-150px dropdownFadeIn"
              aria-labelledby="dropdownMenuButton32"
            >
              <div class="text-muted py-2 ms-4 mb-2 fw-bolder">
                Assign to...
              </div>

              <div
                class="menu-item my-0 bg-hover-light"
                v-for="user in $store.state.teammembers"
                :key="user.userid"
              >
                <div
                  class="d-flex"
                  v-if="
                    user.userid != task.assigneduserobj.userid &&
                    user.subscriptiontype != 'client'
                  "
                >
                  <div
                    v-if="user.avatarurl"
                    class="symbol symbol-circle symbol-20px mt-1 ps-4"
                  >
                    <img
                      :src="user.avatarurl"
                      class="h-30 align-self-center"
                      alt=""
                    />
                  </div>
                  <div
                    v-else
                    class="symbol symbol-circle symbol-20px mt-1 ps-4"
                  >
                    <div>
                      <div
                        v-if="user.firstname"
                        class="symbol-label fs-8 fw-semibold text-primary"
                      >
                        {{
                          user.firstname.substring(0, 1) +
                          user.lastname.substring(0, 1)
                        }}
                      </div>
                    </div>
                  </div>
                  <a
                    class="menu-link text-muted py-2"
                    @click="reAssignUserAfterAdd(user, task)"
                    ><span class="">{{ user.firstname }}</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
    <!--end:Task list-->
    <!--begin:No tasks Alert-->
    <div v-else>
      <CardAlert
        alerttype="info"
        title="No tasks"
        text="Click 'Add task' above to add one"
      ></CardAlert>
    </div>
    <!--end:No tasks Alert-->
  </div>
</template>

<script>
import { handleProfileTaskCheckBoxExternal } from "../../../js/profiledetails";
import { deleteProfileTaskExternal } from "../../../js/profiledetails";
import { reAssignUserAfterAddExternal } from "../../../js/profiledetails";
import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
  components: {
    CardAlert,
  },
  methods: {
    taskDueDate(taskDueDateTimeStamp) {
      //TODO: would be much easier to use getDate(), getHour(), etc. here

      //this calculates the number of days left till due date
      const nowTimestamp = new Date().getTime();
      const nowHours = new Date().getHours();
      const hoursLeftTillTomorrow = 23 - nowHours; //don't know why but it only works with 23, not 24...
      const millisecsLeftTillTomorrow = hoursLeftTillTomorrow * 3600000;
      const dueDateTimestamp = taskDueDateTimeStamp * 1;
      const timeTillDueDate = dueDateTimestamp - nowTimestamp;
      const timeTillDueDateMinusToday =
        timeTillDueDate - millisecsLeftTillTomorrow;

      const daysTillDueDate = timeTillDueDateMinusToday / 86400000;

      if (!dueDateTimestamp) {
        return "";
      } else if (daysTillDueDate > -1 && daysTillDueDate < 0) {
        return "Due today";
      } else if (daysTillDueDate < 1 && daysTillDueDate > 0) {
        return "Due tomorrow";
      } else if (daysTillDueDate < 2 && daysTillDueDate > 1) {
        return "Due in 2 days";
      } else if (daysTillDueDate < 3 && daysTillDueDate > 2) {
        return "Due in 3 days";
      } else if (daysTillDueDate < 4 && daysTillDueDate > 3) {
        return "Due in 4 days";
      } else if (daysTillDueDate < 5 && daysTillDueDate > 3) {
        return "Due in 5 days";
      } else if (daysTillDueDate > 5) {
        const dueDate = new Date(dueDateTimestamp);
        let options = { weekday: "long", month: "short", day: "numeric" };
        return "Due " + dueDate.toLocaleString("en-US", options);
      } else if (daysTillDueDate < -1 && daysTillDueDate > -2) {
        return "Due Yesterday";
      } else if (daysTillDueDate < -2 && daysTillDueDate > -3) {
        return "Due 2 days ago";
      } else if (daysTillDueDate < -3 && daysTillDueDate > -4) {
        return "Due 3 days ago";
      } else if (daysTillDueDate < -4 && daysTillDueDate > -5) {
        return "Due 4 days ago";
      } else if (daysTillDueDate < -5 && daysTillDueDate > -6) {
        return "Due 5 days ago";
      } else if (daysTillDueDate < 6) {
        const dueDate = new Date(dueDateTimestamp);
        let options = { weekday: "long", month: "short", day: "numeric" };
        return "Due " + dueDate.toLocaleString("en-US", options);
      }
    },
    overdue(dueDateTimestamp) {
      if (dueDateTimestamp > Date.now()) {
        return false;
      } else if (dueDateTimestamp < Date.now()) {
        return true;
      }
    },
    handleProfileTaskCheckBox(task) {
      handleProfileTaskCheckBoxExternal(task);
    },
    deleteProfileTask(taskid) {
      //before deleting the task from Firestore, we need to remove it from the rendered array, so that the Vue animation can happen
      //we have to use the vuex array here, not the computed propperty. In comments it worked with the computed property, don't know why.
      const taskList = this.$store.state.currentProfileTasks;
      const index = taskList.map((e) => e.taskid).indexOf(taskid);
      taskList.splice(index, 1);

      //fire delete function
      deleteProfileTaskExternal(taskid);
    },
    reAssignUserAfterAdd(user, task) {
      reAssignUserAfterAddExternal(user, task);
    },
  },
  computed: {
    filteredTaskList() {
      if (this.$store.state.showcompletedtasks) {
        //return all tasks, completed & uncompleted
        const originalTaskList = this.$store.state.currentProfileTasks;
        return originalTaskList;
      } else if (
        !this.$store.state.showcompletedtasks &&
        !this.$store.state.showonlymytasks
      ) {
        //return only uncompleted tasks
        const originalTaskList = this.$store.state.currentProfileTasks;
        const results = originalTaskList.filter((obj) => {
          return obj.completed === false;
        });

        //Sort the tasks by timestamp
        function compare(a, b) {
          if (a.timestamp > b.timestamp) {
            return -1;
          }
          if (a.timestamp < b.timestamp) {
            return 1;
          }
          return 0;
        }

        const sortedresults = results.sort(compare);

        return sortedresults;
      } else if (this.$store.state.showonlymytasks) {
        //return only my tasks
        const originalTaskList = this.$store.state.currentProfileTasks;
        const results = originalTaskList.filter((obj) => {
          return (
            obj.assigneduserobj.userid === this.$store.state.userData.userid &&
            obj.completed === false
          );
        });
        return results;
      }
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>