<template>
  <!--begin::Body-->
    <div class="card-body" style="margin-top: -40px;">
 
        
        <div class="">
            <span class="text-dark fs-1 fw-bold me-2">{{ $store.state.currentProjectDetails.numberofpotentialcandidates +
                                                         $store.state.currentProjectDetails.numberofinprogress + 
                                                         $store.state.currentProjectDetails.numberofdiscarded +
                                                         $store.state.currentProjectDetails.numberofnostatus
                 }}</span>
            
            <span class="fw-semibold text-muted fs-7">Profiles</span>
        </div>

        <div class="progress h-7px bg-secondary bg-opacity-50 mt-7">
            <div class="progress-bar bg-success" role="progressbar" :style="'width: ' + projectmetrics.interestedPercentage + '%;'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        
            <div class="progress-bar bg-primary" role="progressbar" :style="'width: ' + projectmetrics.inProgressPercentage + '%;'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        
            <div class="progress-bar bg-danger" role="progressbar" :style="'width: ' + projectmetrics.discardedPercentage + '%;'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        
        
        </div>

        <div class="d-flex">
            <button class="btn btn-light-primary w-100 mt-12"
                        data-bs-toggle="modal"
            data-bs-target="#projectkpis">
                <i class="bi bi-eye fs-2"></i>
                See more</button>
        </div>
    </div>
    <!--end:: Body-->
</template>

<script>
export default {

computed: {
    projectmetrics() {

let totalProfiles = this.$store.state.currentProjectDetails.numberofnostatus + this.$store.state.currentProjectDetails.numberofpotentialcandidates + this.$store.state.currentProjectDetails.numberofinprogress + this.$store.state.currentProjectDetails.numberofdiscarded
let interested = this.$store.state.currentProjectDetails.numberofpotentialcandidates;
let inprogress = this.$store.state.currentProjectDetails.numberofinprogress;
let discarded = this.$store.state.currentProjectDetails.numberofdiscarded;

let interestedPercentage = (interested / totalProfiles) * 100;
let inProgressPercentage = (inprogress / totalProfiles) * 100;
let discardedPercentage = (discarded / totalProfiles) * 100;

return {
    totalprofiles: totalProfiles,
    interestedPercentage: interestedPercentage,
    inProgressPercentage: inProgressPercentage,
    discardedPercentage: discardedPercentage
}

        


    }
}

}
</script>

<style>

</style>