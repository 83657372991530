<template>
  <div
    :class="{
      'd-flex': routepath == '/mobile',
      'flex-column': routepath == '/mobile',
      'row': routepath != '/mobile'
    }"
  >
    <div :class="{'col-7': routepath != '/mobile'}">
      <!--begin::Timeline-->
      <div
        class="timeline-label"
        v-if="$store.state.currentProfileDetails.experiencelist.length > 0"
      >
        <!--begin::Item-->
        <TransitionGroup name="list" tag="div">
          <div
            v-for="exp in $store.state.currentProfileDetails.experiencelist"
            :key="exp.id"
            class="timeline-item"
          >
            <!--begin::Label-->
            <div
              v-if="exp.endyear != 3000"
              class="timeline-label text-gray-800 fs-7"
            >
              {{ exp.endyear }} - {{ exp.startyear }}
            </div>
            <div
              v-if="exp.endyear == 3000"
              class="timeline-label text-gray-800 fs-7"
            >
              Today - {{ exp.startyear }}
            </div>

            <!--end::Label-->
            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="fa fa-genderless text-primary fs-1"></i>
            </div>
            <!--end::Badge-->
            <!--begin::Text-->
            <div class="d-flex flex-column">
              <div class="timeline-content d-flex">
                <span class="fw-bold text-gray-800 ps-3"
                  >{{ exp.company }}
                </span>
                <div
                  style="margin-top: -2px"
                  @click="deleteProExperience(exp.id)"
                >
                  <!--begin::Svg Icon (delete btn) | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr011.svg-->
                  <span
                    class="svg-icon svg-icon-muted ms-1 svg-icon-xs custompointer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon (delete btn)-->
                </div>
              </div>
              <div class="fw-mormal timeline-content text-muted ps-3">
                {{ exp.title }}
              </div>
            </div>

            <!--end::Text-->
          </div>
        </TransitionGroup>
        <!--end::Item-->
      </div>
      <!--end::Timeline-->
      <div v-else>
        <CardAlert
          alerttype="info"
          title="No experiences"
          text="The LinkedIn profile could be empty, or the import is still in progress. Please refresh the page and try again."
        ></CardAlert>
      </div>
    </div>

    <div :class="{ 'mt-15': routepath == '/mobile', 'col-5': routepath != '/mobile' }">
      <!--begin::Timeline-->
      <div class="timeline-label">
        <!--begin::Item-->
        <TransitionGroup name="list" tag="div">
          <div
            v-for="edu in $store.state.currentProfileDetails.educationlist"
            :key="edu.id"
            class="timeline-item"
          >
            <!--begin::Label-->
            <div
              v-if="edu.endyear != 'nodatahere'"
              class="timeline-label text-gray-800 fs-7"
            >
              {{ edu.endyear }} - {{ edu.startyear }}
            </div>
            <div v-else class="timeline-label text-gray-800 fs-7"></div>

            <!--end::Label-->
            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="fa fa-genderless text-warning fs-1"></i>
            </div>
            <!--end::Badge-->
            <!--begin::Text-->
            <div class="d-flex flex-column">
              <div class="timeline-content d-flex">
                <span class="fw-bold text-gray-800 ps-3"
                  >{{ edu.school }}

                  <!--begin::Svg Icon (delete btn) | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr011.svg-->
                  <span
                    @click="deleteEducation(edu.id)"
                    class="svg-icon svg-icon-muted ms-1 svg-icon-xs custompointer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon (delete btn)-->
                </span>
              </div>
              <div class="fw-mormal timeline-content text-muted ps-3">
                {{ edu.degree_name }}
              </div>
            </div>

            <!--end::Text-->
          </div>
        </TransitionGroup>
        <!--end::Item-->
      </div>
      <!--end::Timeline-->
    </div>
  </div>
</template>

<script>
import CardAlert from "../../CardAlerts/CardAlert.vue";
import { deleteProExperienceExternal } from "../../../js/profiledetails";
import { deleteEducationExternal } from "../../../js/profiledetails";

export default {
  components: {
    CardAlert,
  },
  methods: {
    deleteProExperience(expid) {
      const arr = this.$store.state.currentProfileDetails.experiencelist;

      //get index of the element
      const index = arr.findIndex((object) => {
        return object.id === expid;
      });

      //remove the experience from Vuex array (this triggers Transition Group animation)
      arr.splice(index, 1);

      //remove the experience from Firestore
      deleteProExperienceExternal();
    },
    deleteEducation(eduid) {
      const arr = this.$store.state.currentProfileDetails.educationlist;

      //get index of the element
      const index = arr.findIndex((object) => {
        return object.id === eduid;
      });

      //remove the education item from Vuex array (this triggers Transition Group animation)
      arr.splice(index, 1);

      deleteEducationExternal();
    },
  },
  computed: {
    computedExperienceList() {
      const experiences =
        this.$store.state.currentProfileDetails.experiencelist;
      const sortedArray = experiences.sort((a, b) =>
        a.ends_at.year > b.ends_at.year ? 1 : -1
      );
      return sortedArray;
    },
    routepath() {
      return this.$route.path;
    },
  },
};
</script>

<style>
</style>