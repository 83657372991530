<template>



  <!--begin::Notifications scroll wrapper-->
  <div
    
    class="scroll-y mh-200px mh-lg-350px notification-list"
    style="margin-bottom: -35px; height: 350px"
  >
    <!--begin::Notification list-->
    <CardAlert
      v-if="$store.state.numberofnotifications == 0"
      alerttype="info"
      title="All clear!"
      text="There are no notifications at the moment."
    ></CardAlert>
    <div
      class="pe-3 mb-6 timeline-item"
      v-for="notif in computedNotifications"
      :key="notif.id"
      :id="'notification' + notif.id"
    >
      <!--begin::EMAIL notification-->
      <div class="notification" v-if="notif.notificationtype == 'email'">
        <div
          class="timeline-content mb-1 bg-light bg-hover-secondary p-3 rounded notification-content"
      data-bs-toggle="modal"
      data-bs-target="#email"
      @click="openEmail(notif)"
        >
          <!--begin::Timeline main-->
          <div class="pe-3 mb-5">
            <!--begin::Description-->
            <div class="d-flex align-items-top mt-1 fs-6">
              <!--begin::Info-->
              <div class="d-flex flex-column">
                <!--commenttext can be either a comment or a task-->

                <div class="d-flex fs-7 fw-bold text-gray-800 ps-1">
                  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/communication/com002.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-2"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z"
                        fill="currentColor"
                      />
                      <path
                        d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <div>
                    <span class="fw-boldest text-dark me-2">New email:</span>
                    <span>"{{ notif.emailsubject }}", from :</span>
                  </div>
                </div>
              </div>
              <!--end::Info-->
              <!--begin: Date-->
              <div class="d-flex me-3 ms-auto" style="width: 75px">
                <div class="fs-7 text-primary ms-auto">
                  {{ timelineItemDate(notif.timestamp) }}
                </div>
                <div
                  v-if="
                    timelineItemDate(notif.timestamp) == '2 days ' ||
                    timelineItemDate(notif.timestamp) == '3 days '
                  "
                  class="fs-7 text-primary mb-5"
                >
                  ago
                </div>
                <div class="fs-7 text-primary">
                  {{ timelineItemYear(notif.timestamp) }}
                </div>
              </div>
              <!--end: Date-->
            </div>
            <!--end::Description-->
          </div>
          <!--end::Timeline main--><!--begin::Timeline details-->
          <div class="overflow-auto ps-11">
            <!--begin::related project-->
            <div class="ms-4">
              <div class="d-flex flex-row">
                <div class="symbol symbol-30px me-3">
                          <img
                            alt="Pic"
                            v-if="notif.profilepic"
                            :src="notif.profilepic"
                            onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                          />
                          <img
                            alt="Pic"
                            v-if="!notif.profilepic"
                            src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                          />
                </div>
                <div>
                  <div class="text-dark fw-bold fs-8"
                    >{{ notif.firstname }} {{ notif.lastname }}</div
                  >
                  <div class="">
                    <span class="text-muted fw-semibold d-block fs-9">{{
                      notif.currentcompany
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--end::related project-->
          </div>
          <!--end::Timeline details-->
        </div>
        <button class="clear-btn fs-7" @click="clear(notif)">Clear</button>
      </div>
      <!--end::EMAIL notification-->

      <!--begin::TASK notification-->
      <div class="notification" v-if="notif.notificationtype == 'task'">
        <div
          class="timeline-content mb-1 bg-light bg-hover-secondary p-3 rounded notification-content"
          @click="goToDetailedProfile(notif.profileid, 'task')"
        >
          <!--begin::Timeline main-->
          <div class="pe-3 mb-3">
            <!--begin::Description-->
            <div class="d-flex align-items-top mt-1 fs-6">
              <!--begin::Info-->
              <div class="d-flex flex-column">
                <!--commenttext can be either a comment or a task-->

                <div class="d-flex fs-7 fw-bold text-gray-800 ps-1">
                  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/text/txt009.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-2"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M17 10H11C10.4 10 10 9.6 10 9V8C10 7.4 10.4 7 11 7H17C17.6 7 18 7.4 18 8V9C18 9.6 17.6 10 17 10ZM22 4V3C22 2.4 21.6 2 21 2H11C10.4 2 10 2.4 10 3V4C10 4.6 10.4 5 11 5H21C21.6 5 22 4.6 22 4ZM22 15V14C22 13.4 21.6 13 21 13H11C10.4 13 10 13.4 10 14V15C10 15.6 10.4 16 11 16H21C21.6 16 22 15.6 22 15ZM18 20V19C18 18.4 17.6 18 17 18H11C10.4 18 10 18.4 10 19V20C10 20.6 10.4 21 11 21H17C17.6 21 18 20.6 18 20Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8 5C8 6.7 6.7 8 5 8C3.3 8 2 6.7 2 5C2 3.3 3.3 2 5 2C6.7 2 8 3.3 8 5ZM5 4C4.4 4 4 4.4 4 5C4 5.6 4.4 6 5 6C5.6 6 6 5.6 6 5C6 4.4 5.6 4 5 4ZM8 16C8 17.7 6.7 19 5 19C3.3 19 2 17.7 2 16C2 14.3 3.3 13 5 13C6.7 13 8 14.3 8 16ZM5 15C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17C5.6 17 6 16.6 6 16C6 15.4 5.6 15 5 15Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <div>
                    <span class="fw-boldest text-dark me-1">New task</span>
                    assigned to you by
                    {{ getTaskCreator(notif.byuser).firstname }}:
                    <span class="text-gray-600" style="font-style: italic">"{{ notif.text }}" </span><br><div class="mt-3 fs-8">Regarding :</div>
                  </div>
                </div>
              </div>
              <!--end::Info-->
              <!--begin: Date-->
              <div class="d-flex me-3" style="width: 75px">
                <div class="fs-7 text-primary ms-auto">
                  {{ timelineItemDate(notif.timestamp) }}
                </div>
                <div
                  v-if="
                    timelineItemDate(notif.timestamp) == '2 days ' ||
                    timelineItemDate(notif.timestamp) == '3 days '
                  "
                  class="fs-7 text-primary mb-5"
                >
                  ago
                </div>
                <div class="fs-7 text-primary">
                  {{ timelineItemYear(notif.timestamp) }}
                </div>
              </div>
              <!--end: Date-->
            </div>
            <!--end::Description-->
          </div>
          <!--end::Timeline main--><!--begin::Timeline details-->
          <div class="overflow-auto ps-11">
            <!--begin::related project-->
            <div class="ms-4">
              <div class="d-flex flex-row">
                <div class="symbol symbol-30px me-3">
                          <img
                            alt="Pic"
                            v-if="notif.profilepic"
                            :src="notif.profilepic"
                            onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                          />
                          <img
                            alt="Pic"
                            v-if="!notif.profilepic"
                            src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                          />
                </div>
                <div>
                  <div class="text-dark fw-bold fs-8"
                    >{{ notif.firstname }} {{ notif.lastname }}</div
                  >
                  <div class="">
                    <span class="text-muted fw-semibold d-block fs-9">{{
                      notif.currentcompany
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--end::related project-->
          </div>
          <!--end::Timeline details-->
        </div>
        <button class="clear-btn fs-7" @click="clear(notif)">Clear</button>
      </div>
      <!--end::TASK notification-->

      <!--begin::CLIENT COMMENT notification-->
      <div
        class="notification"
        v-if="notif.notificationtype == 'clientcomment'"
      >
        <div
          class="timeline-content mb-1 bg-light bg-hover-secondary p-3 rounded notification-content"
          @click="goToDetailedProfile(notif.profileid, 'clientcomment')"
        >
          <!--begin::Timeline main-->
          <div class="pe-3 mb-5">
            <!--begin::Description-->
            <div class="d-flex align-items-top mt-1 fs-6">
              <!--begin::Info-->
              <div class="d-flex flex-column">
                <!--commenttext can be either a comment or a task-->

                <div class="d-flex fs-7 fw-bold text-gray-800 ps-1">
                  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/communication/com012.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-3"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                        fill="currentColor"
                      />
                      <rect
                        x="6"
                        y="12"
                        width="7"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      />
                      <rect
                        x="6"
                        y="7"
                        width="12"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <div>
                    <span class="fw-boldest text-dark me-1"
                      >New client comment:
                    </span>
                    {{ getCommentClient(notif.userwhocomments) }} said :
                    <span
                      ><span style="font-style: italic"
                        >"{{ notif.commenttext }}"</span
                      >, regarding :</span
                    >
                  </div>
                </div>
              </div>
              <!--end::Info-->
              <!--begin: Date-->
              <div class="d-flex me-3" style="width: 75px;">
                <div class="fs-7 text-primary ms-auto">
                  {{ timelineItemDate(notif.timestamp) }}
                </div>
                <div
                  v-if="
                    timelineItemDate(notif.timestamp) == '2 days ' ||
                    timelineItemDate(notif.timestamp) == '3 days '
                  "
                  class="fs-7 text-primary mb-5"
                >
                  ago
                </div>
                <div class="fs-7 text-primary">
                  {{ timelineItemYear(notif.timestamp) }}
                </div>
              </div>
              <!--end: Date-->
            </div>
            <!--end::Description-->
          </div>
          <!--end::Timeline main-->
          <!--begin::Timeline details-->
          <div class="overflow-auto ps-11">
            <!--begin::related project-->
            <div class="ms-4">
              <div class="d-flex flex-row">
                <div class="symbol symbol-30px me-3">
                          <img
                            alt="Pic"
                            v-if="notif.profilepic"
                            :src="notif.profilepic"
                            onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                          />
                          <img
                            alt="Pic"
                            v-if="!notif.profilepic"
                            src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                          />
                </div>
                <div>
                  <div class="text-dark fw-bold fs-8"
                    >{{ notif.firstname }} {{ notif.lastname }}</div
                  >
                  <div class="">
                    <span class="text-muted fw-semibold d-block fs-9">{{
                      notif.currentcompany
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--end::related project-->
          </div>
          <!--end::Timeline details-->
        </div>
        <button class="clear-btn fs-7" @click="clear(notif)">Clear</button>
      </div>
      <!--end::CLIENT COMMENT notification-->
    </div>
    <!--end::Notification list-->
  </div>
  <!--end::Notifications scroll wrapper-->
</template>

<script>
import { getSingleProfileExternal } from "../../../js/getdata.js";
import {
  markAsReadExternal,
  clearNotificationExternal,
} from "../../../js/profiledetails.js";
import { goToDetailedProfileExternal } from "../../../js/navigation.js";
import router from "../../../router/index.js";
import CardAlert from "../../CardAlerts/CardAlert.vue"

export default {
  components: {
    CardAlert
  },
  data() {
    return {
      showTabs: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showTabs = true;
    }, 2000);
  },
  computed: {
    computedNotifications() {
      //merging the 3 lists of notifications
      //fallback of empty array in case it has not yet been populated

      const clientCommentsOwnProjects =
        this.$store.state.clientcommentsnotificationsOwnProjects || [];
      const clientCommentsJoinedProjects =
        this.$store.state.clientcommentsnotificationsJoinedProjects || [];
      const taskNotifications = this.$store.state.tasknotifications || [];
      const emailNotifications = this.$store.state.emailnotifications || [];

      const mergedArray = [
        ...clientCommentsOwnProjects,
        ...clientCommentsJoinedProjects,
        ...taskNotifications,
        ...emailNotifications,
      ];

      //sort the notifications by timestamp
      const sortednotifications = mergedArray.sort(
        (a, b) => b.timestamp - a.timestamp
      );

      this.$store.state.numberofnotifications = sortednotifications.length;

      return sortednotifications;
    },
  },
  methods: {
    getTaskCreator(userid) {
      const teamMembers = this.$store.state.teammembers;

      const matchingMember = teamMembers.find(
        (teamMember) => teamMember.userid === userid
      );

      if (matchingMember) {
        return matchingMember;
      } else {
        console.log("No team member found with the given userid");
        return "(Unknown user)";
      }
    },
    getCommentClient(userid) {
      const teamMembers = this.$store.state.teammembers;

      const matchingMember = teamMembers.find(
        (teamMember) => teamMember.userid === userid
      );

      const emailToMatch = matchingMember.email;

      const projectList = this.$store.state.currentMyProjectList;

      function findUserFullName(email) {
        const foundUser = projectList
          .flatMap((project) => {
            if (
              project.authorisedclientusersforportal &&
              project.authorisedclientusersforportal.length > 0
            ) {
              return project.authorisedclientusersforportal;
            } else {
              return [];
            }
          })
          .find((user) => user.email === email);

        if (foundUser) {
          const { firstname, lastname } = foundUser;
          return `${firstname} ${lastname}`;
        } else {
          return "User not found";
        }
      }

      const fullName = findUserFullName(emailToMatch);
      return fullName;
    },
    openEmail(email) {
      //the second argument is used to prevent switching to projectdetails when opening an email from notification
      getSingleProfileExternal(email.profileid, "notifications");


    this.$store.state.showingProfilesAs = "profileDetails"


    //go to profiles
    router.push('/profiles')

      document.getElementById("openNotifications2").click()

      //open email
      //Clear previous email in modal, if any
      this.$store.state.currentEmailMessage = {};

      //prepare form
      this.$store.state.emailSubject = "Re: " + email.emailsubject;
      this.$store.state.emailBody =
        "<p></p><p></p>" +
        this.$store.state.userData.emailsignature +
        "<br>On " +
        Date(email.timestamp).toLocaleString("en-US") +
        "<br><" +
        email.emailfrom +
        "> wrote: " +
        email.emailbodyhtml;

      //load clicked email in the modal
      this.$store.state.currentEmailMessage = email;

      //if it's an incoming email...
      if (email.emailfrom != this.$store.state.userData.email) {
        markAsReadExternal(email);
      }
    },
    timelineItemDate(timelineItemDate) {
      const itemDate = new Date(timelineItemDate).getDate();
      const itemMonth = new Date(timelineItemDate).getMonth();
      const itemYear = new Date(timelineItemDate).getFullYear();

      const currentDate = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      if (itemDate == currentDate) {
        return "Today";
      } else if (
        itemDate == currentDate - 1 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "Yest.";
      } else if (
        itemDate == currentDate - 2 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "2 days";
      } else if (
        itemDate == currentDate - 3 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "3 days";
      } else {
        const monthName = new Date(timelineItemDate).toLocaleString("en-US", {
          month: "short",
        });
        const finalDate = monthName + ". " + itemDate;

        return finalDate;
      }
    },
    timelineItemYear(timelineItemDate) {
      const itemYear = new Date(timelineItemDate).getFullYear();
      const currentYear = new Date().getFullYear();

      if (itemYear == currentYear) {
        return "";
      } else {
        return " ," + itemYear;
      }
    },
    goToDetailedProfile(profileId, notificationtype) {
      goToDetailedProfileExternal(profileId, "notifications", notificationtype);
    },
    clear(notif) {
      document
        .getElementById("notification" + notif.id)
        .classList.add("animate__animated");
      document
        .getElementById("notification" + notif.id)
        .classList.add("animate__fadeOutLeft");
      document
        .getElementById("notification" + notif.id)
        .classList.add("animate__faster");

      setTimeout(() => {
        clearNotificationExternal(notif);
      }, 500);
    },
  },
};
</script>

<style>
</style>