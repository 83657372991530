<template>
  <!--begin: header-->
  <div class="p-3 ps-6 pt-6 d-flex mb-10">
    <img
      alt="Logo"
      src="../assets/media/logos/kiwano.png"
      class="d-lg-inline-block h-30px"
    />
    <div class="ms-auto d-flex">
      <div
        v-if="$store.state.userData.avatarurl"
        class="symbol symbol-circle symbol-30px symbol-md-35px ms-auto pe-4"
        id="openUserMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img :src="$store.state.userData.avatarurl" alt="user" />
      </div>
      <div
        v-else
        class="symbol symbol-circle symbol-30px symbol-md-35px ms-auto pe-4"
        id="openUserMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div class="symbol-label fs-5 fw-semibold text-primary">
          {{
            $store.state.userData.firstname.substring(0, 1) +
            $store.state.userData.lastname.substring(0, 1)
          }}
        </div>
      </div>
      <button
        class="d-none btn btn-sm btn-icon btn-light fs-9"
        id="extensionmenu"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        style="border-radius: 10px"
      >
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen052.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-2"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="rotate(90 12 12)">
              <rect
                x="10"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
              <rect
                x="17"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
              <rect
                x="3"
                y="10"
                width="4"
                height="4"
                rx="2"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </button>

      <div
        class="d-none dropdown-menu menu-sub menu-sub-dropdown dropdownFadeIn"
        aria-labelledby="extensionmenu"
        role="menu"
      >
        <div class="d-flex flex-column">
          <!--begin: Connect Button-->
          <!--begin::Button-->
          <a
            class="d-none btn btn-md btn-light w-150px"
            id="connectbtn"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
            ><i class="bi bi-calendar2-event me-1"></i>
            <span class="fs-7">Connect</span>
          </a>
          <!--end::Button-->
          <!--begin: Dropdown menu-->
          <div
            class="dropdown-menu menu-sub menu-sub-dropdown w-150px w-md-150px dropdownFadeIn"
            aria-labelledby="connectbtn"
            role="menu"
          >
            <div class="p-8">Coming soon</div>
          </div>
          <!--end: Dropdown menu-->
          <!--end: Connect Button-->
        </div>
      </div>
    </div>
  </div>
  <!--begin: header-->

  <!--begin::Search Input Field-->
  <div class="d-none w-lg-100 position-relative ms-3 me-7">
    <!--begin::Input-->
    <input
      type="text"
      class="form-control form-control-solid"
      style="background-color: #11ffee00; border: none !important; color: white"
      placeholder="Quick search"
      name="searchInput"
      id="searchInput"
      autocomplete="off"
      v-model="$store.state.searchInputText"
      @keydown="showSearchDropdown()"
    />

    <!--end::Input-->

    <!--begin::search icon-->
    <div class="position-absolute translate-middle-y top-50 end-0 me-3">
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr088.svg-->
      <span
        @click="resetSearchBar()"
        v-if="$store.state.searchInputText.length > 0"
        class="custompointer svg-icon svg-icon-white svg-icon-2hx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.5"
            x="7.05025"
            y="15.5356"
            width="12"
            height="2"
            rx="1"
            transform="rotate(-45 7.05025 15.5356)"
            fill="currentColor"
          />
          <rect
            x="8.46447"
            y="7.05029"
            width="12"
            height="2"
            rx="1"
            transform="rotate(45 8.46447 7.05029)"
            fill="currentColor"
          /></svg
      ></span>
      <!--end::Svg Icon-->
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen004.svg-->
      <span class="svg-icon svg-icon-muted svg-icon-1"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
            fill="currentColor"
          />
          <path
            opacity="0.3"
            d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
    <!--end::search icon-->
  </div>
  <!--end::Search Input Field-->

  <!--begin: Divider-->
  <div style="margin-top: -13px" class="d-none mx-7">
    <hr style="border-top: 2px solid WhiteSmoke !important" />
  </div>
  <!--end: Divider-->

  <div>
    <div class="text-white ms-15 mb-5" v-if="duplicateProfile == 'checking'">
      Checking for duplicate... 
      <span class="spinner-border spinner-border-sm align-middle ms-3"></span>
    </div>
    <Alert
    class="ms-5 me-5 mb-5"
    v-if="duplicateProfile && duplicateProfile != 'checking'"
    alerttype="danger"
    title="Duplicate!"
    text="This profile was found in your database. You can still import it as a duplicate if you want to."
  ></Alert>
  </div>

  <Alert
    class="ms-5 me-5 mb-5"
    v-if="notALinkedInProfile"
    alerttype="danger"
    title="No profile detected!"
    text="Why? Please make sure that: 1/ You are currently viewing a LinkedIn profile, either a Public profile or in Recruiter 2/ You do not have any other Chrome windows open. The Extension can sometimes be confused when there are multiple Chrome windows open. However, you can have as many tabs open as you wish. If you still see this message, please contact support. "
  ></Alert>

  <!--begin: cards (search & import)-->

  <!--begin::Search result Card-->
  <div
    v-if="$store.state.searchState == 'on'"
    key="search"
    class="card card-xl-stretch mb-xl-8 ms-5 me-5"
  >
    <SmallCard
      whichCard="searchfromchromeextension"
      title="Search results"
      subTitle="search"
      v-if="$store.state.userData.algoliasearchkey"
    ></SmallCard>
  </div>
  <!--end::Search result Card-->

  <!--begin::Import Card-->
  <div
    v-if="!notALinkedInProfile && $store.state.searchState == 'off'"
    key="import"
    class="card card-xl-stretch mb-xl-8 ms-5 me-5 bg-opacity-0"
  >
    <!--begin::Import Card Header-->
    <div
      class="card-header border-0 mt-5"
      style="margin-bottom: -15px !important"
    >
      <h1 class="d-flex mb-4">
        <div>
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/communication/com013.svg-->
          <span class="svg-icon svg-icon-light svg-icon-1 me-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                fill="currentColor"
              />
              <rect
                opacity="0.3"
                x="8"
                y="3"
                width="8"
                height="8"
                rx="4"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </div>
        <div class="mt-1 text-light">
          {{ $store.state.tabTitle.replace(/.*\|/, "") }}
        </div>
      </h1>
    </div>
    <!--end::Import Card Header-->

    <!--begin:Import Card body-->
    <div class="card-body d-flex flex-column">
      <!--begin: Attach to project-->
      <div class="d-flex flex-stack mb-4">
        <div class="me-5 d-flex">
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen041.svg-->
          <span class="svg-icon svg-icon-light svg-icon-2hx me-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
              />
              <rect
                x="10.8891"
                y="17.8033"
                width="12"
                height="2"
                rx="1"
                transform="rotate(-90 10.8891 17.8033)"
                fill="currentColor"
              />
              <rect
                x="6.01041"
                y="10.9247"
                width="12"
                height="2"
                rx="1"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          <div class="d-flex flex-column">
            <label class="fs-6 fw-semibold text-light">Add to project</label>
            <div class="fs-7 fw-semibold text-muted text-light">Optional</div>
          </div>
        </div>
        <div class="dropdown">
          <!--begin: Attach to project Button-->
          <button
            class="btn btn-sm btn-outline-dashed btn-outline dropdown d-flex"
            type="button"
            id="selectProjectToAttachProfileButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="text-white" v-if="!selectedProject"
              >Select project</span
            >

            <div
              v-if="selectedProject"
              class="d-flex align-items-center pointer latestprofilesitem"
            >
              <!--begin::Symbol-->
              <div class="symbol symbol-35px me-4 companylogo">
                <img :src="selectedProject.clientobject.clientlogourl" alt="" />
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div
                class="d-flex flex-column align-items-start"
                href="javascript:void(0)"
              >
                <span class="fs-7 text-light fw-bold">{{
                  selectedProject.positiontitle
                }}</span>
                <span class="fs-8 text-muted fw-bold">{{
                  selectedProject.clientobject.clientname
                }}</span>
              </div>
              <!--end::Title-->
            </div>

            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr072.svg-->
            <span
              class="ms-2 svg-icon svg-icon-muted svg-icon-2"
              style="margin-right: -7px"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
          <!--end: Attach to project Button-->
          <!--begin: Attach to project Dropdown-->
          <ul
            class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 scroll h-250px me-2"
            aria-labelledby="selectProjectToAttachProfileButton2"
            role="menu"
          >
            <div class="scroll-y h-220px px-3 py-5">
              <!--begin::Items-->
              <!--begin::Item-->
              <li class="my-4 ms-4 text-muted">My projects :</li>
              <li
                v-for="project in myActiveProjects"
                :key="project.id"
                class="d-flex align-items-center border border-0 rounded bg-hover-light p-3 pointer latestprofilesitem"
                @click="selectProject(project)"
              >
                <!--begin::Symbol-->
                <div class="symbol symbol-40px me-4 companylogo">
                  <img
                    v-if="project.clientobject.clientlogourl"
                    :src="project.clientobject.clientlogourl"
                    class=""
                    alt=""
                  />
                  <img
                    v-else
                    src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                    style="width: 50px"
                    alt=""
                  />
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <a class="d-flex flex-column" href="javascript:void(0)">
                  <span class="fs-6 text-gray-800 fw-bold">{{
                    project.positiontitle
                  }}</span>
                  <span class="fs-7 text-muted fw-bold">{{
                    project.clientobject.clientname
                  }}</span>
                </a>
                <!--end::Title-->
              </li>
              <!--end::Item-->
              <!--end:items-->
              <!--begin::Items-->
              <!--begin::Item-->
              <li class="my-4 ms-4 text-muted">Other team projects :</li>

              <li
                v-for="project in teamActiveProjects"
                :key="project.id"
                class="d-flex align-items-center border border-0 rounded bg-hover-light p-3 pointer latestprofilesitem"
                @click="selectProject(project)"
              >
                <!--begin::Symbol-->
                <div class="symbol symbol-40px me-4 companylogo">
                  <img
                    v-if="project.clientobject.clientlogourl"
                    :src="project.clientobject.clientlogourl"
                    class=""
                    alt=""
                  />
                  <img
                    v-else
                    src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                    style="width: 50px"
                    alt=""
                  />
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <a class="d-flex flex-column" href="javascript:void(0)">
                  <span class="fs-6 text-gray-800 fw-bold">{{
                    project.positiontitle
                  }}</span>
                  <span class="fs-7 text-muted fw-bold">{{
                    project.clientobject.clientname
                  }}</span>
                </a>
                <!--end::Title-->
              </li>
              <!--end::Item-->

              <!--end:items-->
            </div>
          </ul>
          <!--end: Attach to project Dropdown-->
        </div>
      </div>
      <!--end: Attach to project-->

      <div
        v-if="selectedProject"
        class="d-flex flex-stack mb-4 animate__animated animate__fadeInDown mt-5"
        style="z-index: 3"
      >
        <div class="me-5 d-flex">
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen041.svg-->
          <span class="svg-icon svg-icon-light svg-icon-2hx me-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
              />
              <rect
                x="10.8891"
                y="17.8033"
                width="12"
                height="2"
                rx="1"
                transform="rotate(-90 10.8891 17.8033)"
                fill="currentColor"
              />
              <rect
                x="6.01041"
                y="10.9247"
                width="12"
                height="2"
                rx="1"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          <div class="d-flex flex-column">
            <label class="fs-6 fw-semibold text-light">Add status</label>
            <div class="fs-7 fw-semibold text-muted">Optional</div>
          </div>
        </div>
        <div class="dropdown">
          <div
            class="btn btn-sm dropdown-toggle"
            :class="{
              'btn-secondary': !selectedStatus,
              'btn-light-primary': selectedStatus.category == 'In progress',
              'btn-light-danger': selectedStatus.category == 'Discarded',
              'btn-light-success': selectedStatus.category == 'Interested',
            }"
            data-bs-toggle="dropdown"
            id="statusMenuInExtensionBtn"
            type="button"
          >
            <span v-if="!selectedStatus">Select status</span>
            <span v-if="selectedStatus">{{ selectedStatus.status }}</span>
          </div>
          <ul
            class="scroll dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-bold py-4 fs-6 w-150px"
            aria-labelledby="statusMenuInExtensionBtn"
            id="statusMenuInExtension"
            role="menu"
          >
            <div class="scroll-y h-150px">
              <li
                v-for="status in $store.state.profileStatusList"
                :key="status.id"
                @click="selectStatus(status)"
                class="badge ms-4 me-4 mb-3 custombadgehover custompointer badge-light"
                :class="{
                  'badge-light-success': status.category == 'Interested',
                  'badge-light-primary': status.category == 'In progress',
                  'badge-light-danger': status.category == 'Discarded',
                  'badge-light':
                    status.category != 'Interested' &&
                    status.category != 'In progress' &&
                    status.category != 'Discarded',
                }"
              >
                {{ status.status }}
              </li>
              <li
                class="p-2 fs-7 text-muted"
                v-if="$store.state.profileStatusList.length == 0"
              >
                No status yet. Go to the app to create your own status list.
              </li>
            </div>
          </ul>
        </div>
      </div>

      <!--begin::Public profile ?-->
      <div class="d-flex flex-stack mb-8 mt-5">
        <!--begin::Label-->
        <div class="me-5 d-flex">
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen046.svg-->
          <span class="svg-icon svg-icon-light svg-icon-2hx me-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
              />
              <path
                d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          <div class="d-flex flex-column">
            <label class="fs-6 fw-semibold text-light">Public profile ?</label>
            <div v-if="selectedTeamAccess" class="fs-7 fw-semibold text-muted">
              Your team can view, modify but not delete this profile.
            </div>
            <div v-if="!selectedTeamAccess" class="fs-7 fw-semibold text-muted">
              Only you have access to this profile. It will not be visible to
              your colleagues.
            </div>
          </div>
        </div>
        <!--end::Label-->
        <!--begin::Switch-->
        <label
          class="form-check form-switch form-check-custom form-check-solid form-switch-sm"
        >
          <input
            class="form-check-input"
            type="checkbox"
            value="1"
            v-model="selectedTeamAccess"
          />
        </label>
        <!--end::Switch-->
      </div>
      <!--end::Public profile ?-->

      <!--begin::Notes Accordion-->
      <div
        v-if="selectedProject"
        class="accordion mb-3"
        id="extensionnotes"
        style="
          padding: 0px !important;
          margin-left: -16px !important;
          margin-top: -10px !important;
          background-color: transparent !important;
        "
      >
        <div class="d-flex flex-column">
          <!--begin::Card body-->
          <div class="bg-opacity-0 bg-primary" style="border: none">
            <button
              @click="commentTextareaFocus()"
              class="btn btn-transparent fs-6 fw-semibold text-light collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_body_2"
              aria-expanded="false"
              aria-controls="kt_accordion_2_body_2"
              style="margin-left: -2px"
            >
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen041.svg-->
              <span class="svg-icon svg-icon-light svg-icon-2hx"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x="2"
                    y="2"
                    width="20"
                    height="20"
                    rx="10"
                    fill="currentColor"
                  />
                  <rect
                    x="10.8891"
                    y="17.8033"
                    width="12"
                    height="2"
                    rx="1"
                    transform="rotate(-90 10.8891 17.8033)"
                    fill="currentColor"
                  />
                  <rect
                    x="6.01041"
                    y="10.9247"
                    width="12"
                    height="2"
                    rx="1"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
              Add notes
            </button>

            <div
              id="kt_accordion_2_body_2"
              class="accordion-collapse collapse"
              :class="{
                'mb-custom-extension-normal': !duplicateProfile,
                'mb-custom-extension-big': duplicateProfile,
              }"
              aria-labelledby="kt_accordion_2_header_1"
              data-bs-parent="#kt_accordion_2"
            >
              <div class="accordion-body">
                <!--begin: Main textarea-->
                <div class="d-flex flex-column">
                  <textarea
                    class="form-control form-control-solid"
                    rows="8"
                    name="target_details"
                    id="editProfileCommentTextarea"
                    placeholder="Write something..."
                    v-model="notestoadd"
                  ></textarea>
                </div>
                <!--end: Main textarea-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Notes Accordion-->

      <!--begin::Comment Accordion-->
      <div
        class="d-none accordion"
        id="extensioncomments"
        style="
          padding: 0px !important;
          margin-left: -16px !important;
          margin-bottom: 150px !important;
          background-color: transparent !important;
        "
      >
        <div class="d-flex flex-column">
          <!--begin::Card body-->
          <div class="bg-opacity-0 bg-primary" style="border: none">
            <button
              @click="commentTextareaFocus()"
              class="btn btn-transparent fs-6 fw-semibold text-light collapsed"
              style="margin-left: -2px"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_3_body_3"
              aria-expanded="false"
              aria-controls="kt_accordion_3_body_3"
            >
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen041.svg-->
              <span class="svg-icon svg-icon-light svg-icon-2hx"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x="2"
                    y="2"
                    width="20"
                    height="20"
                    rx="10"
                    fill="currentColor"
                  />
                  <rect
                    x="10.8891"
                    y="17.8033"
                    width="12"
                    height="2"
                    rx="1"
                    transform="rotate(-90 10.8891 17.8033)"
                    fill="currentColor"
                  />
                  <rect
                    x="6.01041"
                    y="10.9247"
                    width="12"
                    height="2"
                    rx="1"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
              Add comment
            </button>

            <div
              id="kt_accordion_3_body_3"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_2_header_1"
              data-bs-parent="#kt_accordion_2"
            >
              <div class="accordion-body">
                <!--begin: Main textarea-->
                <div class="d-flex flex-column">
                  <textarea
                    class="form-control form-control-solid"
                    rows="8"
                    style=""
                    name="target_details"
                    id="editProfileCommentTextarea"
                    placeholder="Type comment here..."
                    v-model="notestoadd"
                  ></textarea>
                </div>
                <!--end: Main textarea-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Comment Accordion-->
    </div>
    <!--end:Import Card body-->
  </div>
  <!--end::Import Card-->

  <footer
    v-if="!notALinkedInProfile"
    class="bg-dark text-white py-12 px-15 card-body d-flex flex-column fixed-bottom"
  >
    <!--begin: Import button-->
    <button
      type="button"
      @click="getLinkedIn()"
      v-if="
        $store.state.linkedinImportState == 'on' ||
        $store.state.linkedinImportState == 'off'
      "
      class="btn btn-primary"
      :data-kt-indicator="$store.state.linkedinImportState"
    >
      <span class="indicator-label"> Import profile </span>
      <span class="indicator-progress">
        Please wait...
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </button>
    <!--begin::Alerts ("imported" or "error"-->
    <button
      class="btn btn-danger btn-active-primary animate__animated animate__fadeIn"
      v-if="$store.state.linkedinImportState == 'error'"
    >
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/general/gen040.svg-->
      <span class="svg-icon svg-icon-white svg-icon-2hx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="2"
            y="2"
            width="20"
            height="20"
            rx="10"
            fill="currentColor"
          />
          <rect
            x="7"
            y="15.3137"
            width="12"
            height="2"
            rx="1"
            transform="rotate(-45 7 15.3137)"
            fill="currentColor"
          />
          <rect
            x="8.41422"
            y="7"
            width="12"
            height="2"
            rx="1"
            transform="rotate(45 8.41422 7)"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
      Unable to import the profile
    </button>
    <button
      class="btn btn-success btn-active-primary animate__animated animate__fadeIn"
      v-if="$store.state.linkedinImportState == 'done'"
    >
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr085.svg-->
      <span class="svg-icon svg-icon-white svg-icon-2hx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
      Profile imported !
    </button>
    <!--end::Alerts-->

    <!--end: Import button-->
  </footer>

  <!--end: cards (search & import)-->
</template>

<script>
import SmallCard from "./Cards/SmallCard.vue";

import {
  onSnapshot,
  query,
  collection,
  getDocs,
  where,
  doc,
  addDoc,
  updateDoc,
  getDoc,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { db } from "../firebase/index";
import store from "../store/index";
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { attachProfileToProjectExternal } from "../js/profiledetails";

import Alert from "./CardAlerts/CardAlert.vue";
import StatusDropdown from "./Dropdowns/StatusDropdown.vue";

export default {
  components: {
    SmallCard,
    Alert,
    StatusDropdown,
  },
  data() {
    return {
      duplicateProfile: "checking",
      notALinkedInProfile: false,
      selectedProject: false,
      selectedStatus: false,
      selectedTeamAccess: true,
      notestoadd: "",

      //profile data properties, only those that will be changed by the scraper API call functions.
      candidateorclient: "Candidate",
      clientvisible: false,
      currentcompany: "",
      currentcompanyliurl: "nothing here",
      currentcompanylilogo: "nothing here",
      currentjobtitle: "",
      educationlist: [],
      experiencelist: [],
      alsoviewed: [],
      linkedinsummary: "",
      firstname: "",
      lastname: "",
      linkedinurl: "",
      industry: "",
      location: "",
      country: "",
      mobilenumber: "",
      new: true,
      profilepic: "",
      tags: [],
      teamaccess: true,
      opentaskscounter: 0,
      newemailscounter: 0,
      companysize: "nodatahere",
    };
  },
  created() {
    ////Retrieve URL (.../chromeextention.html?=[LiProfileURL])
    const URL = window.location.href;

    const x = URL.split("?="); //array
    store.state.urlToPass = x[1];

    //get rid of the possible "/" at the end of the profile:
    if (store.state.urlToPass.at(-1) == "/") {
      store.state.urlToPass = store.state.urlToPass.slice(0, -1);
    }

    //handle case where the user imports a LinkedIn profile from the Experiences page
    store.state.urlToPass = store.state.urlToPass.replace(/\/details\/.*/, "");

    //Check if profile already exists in Firestore (LinkedIn Public profiles only, there is a similar function for Recruiter profiles below)
    const qForDuplicateCheck = query(
      collection(db, "profiles"),
      where("linkedinurl", "==", store.state.urlToPass),
      where("teamid", "==", store.state.userData.teamid)
    );
    getDocs(qForDuplicateCheck).then((duplicateSnap) => {
  if (duplicateSnap.size > 0) {
    duplicateSnap.forEach((doc) => {
      this.duplicateProfile = true;
    });
  } else {
    this.duplicateProfile = false;
  }
});

    //this piece creates a hybrid profile url composed of the base of a public LinkedIn profile URL + the recruiter profile identifier.
    //by feeding this hybrid URL to iScarper instead of the public URL makes it possible to scrape
    //profiles from LinkedIn Recruiter. It is a loophole that iScraper can easily turn off, so be prepared
    //to do this differently. (I tried a few things in cloud functions but was not able to convert the url myself, aka
    //get the LinkedIn public URL by following the Recruiter URL and catch the redirected URL: LinkedIn automatically redirect to the public URL from the base + recruiter URL)
    //IMPORTANT: THIS WORKS ONLY WITH ISCRAPER, it used to work with proxycurl aswell but they seemed to have already patched it

    //create the hybrid url
    if (store.state.urlToPass.includes("/talent/")) {
      const recruiterProfileIdWithQueryString =
        store.state.urlToPass.split("profile/")[1];

        console.log("is recruiter")

      const recruiterProfileId =
        recruiterProfileIdWithQueryString.split("?")[0];

      //see comments in store/index.js for explanation about this
      store.state.recruiterProfileId = recruiterProfileId;

      store.state.urlToPass =
        "https://www.linkedin.com/in/" + recruiterProfileId;

      console.log(store.state.urlToPass)

      console.log(recruiterProfileId)
      //Check if profile already exists in Firestore (this is the recruiter version, see above for public profile version)
      const qForDuplicateCheck = query(
        collection(db, "profiles"),
        where("recruiterprofileid", "==", recruiterProfileId)
      );
      getDocs(qForDuplicateCheck).then((duplicateSnap) => {
  if (duplicateSnap.size > 0) {
    duplicateSnap.forEach((doc) => {
      this.duplicateProfile = true;
    });
  } else {
    this.duplicateProfile = false;
  }
});
    }

    store.state.tabTitle = x[2];
    if (store.state.tabTitle) {
      store.state.tabTitle = store.state.tabTitle
        .replaceAll("%20", " ")
        .replaceAll("%C3%80", "À")
        .replaceAll("%C3%81", "Á")
        .replaceAll("%C3%82", "Â")
        .replaceAll("%C3%83", "Ã")
        .replaceAll("%C3%84", "Ä")
        .replaceAll("%C3%85", "Å")
        .replaceAll("%C3%86", "Æ")
        .replaceAll("%C3%87", "Ç")
        .replaceAll("%C3%88", "È")
        .replaceAll("%C3%89", "É")
        .replaceAll("%C3%8A", "Ê")
        .replaceAll("%C3%8B", "Ë")
        .replaceAll("%C3%8C", "Ì")
        .replaceAll("%C3%8D", "Í")
        .replaceAll("%C3%8E", "Î")
        .replaceAll("%C3%8F", "Ï")
        .replaceAll("%C3%90", "Ð")
        .replaceAll("%C3%91", "Ñ")
        .replaceAll("%C3%92", "Ò")
        .replaceAll("%C3%93", "Ó")
        .replaceAll("%C3%94", "Ô")
        .replaceAll("%C3%95", "Õ")
        .replaceAll("%C3%96", "Ö")
        .replaceAll("%C3%97", "×")
        .replaceAll("%C3%98", "Ø")
        .replaceAll("%C3%99", "Ù")
        .replaceAll("%C3%9A", "Ú")
        .replaceAll("%C3%9B", "Û")
        .replaceAll("%C3%9C", "Ü")
        .replaceAll("%C3%9D", "Ý")
        .replaceAll("%C3%9E", "Þ")
        .replaceAll("%C3%9F", "ß")
        .replaceAll("%C3%A0", "à")
        .replaceAll("%C3%A1", "á")
        .replaceAll("%C3%A2", "â")
        .replaceAll("%C3%A3", "ã")
        .replaceAll("%C3%A4", "ä")
        .replaceAll("%C3%A5", "å")
        .replaceAll("%C3%A6", "æ")
        .replaceAll("%C3%A7", "ç")
        .replaceAll("%C3%A8", "è")
        .replaceAll("%C3%A9", "é")
        .replaceAll("%C3%AA", "ê")
        .replaceAll("%C3%AB", "ë")
        .replaceAll("%C3%AC", "ì")
        .replaceAll("%C3%AD", "í")
        .replaceAll("%C3%AE", "î")
        .replaceAll("%C3%AF", "ï")
        .replaceAll("%C3%B0", "ð")
        .replaceAll("%C3%B1", "ñ")
        .replaceAll("%C3%B2", "ò")
        .replaceAll("%C3%B3", "ó")
        .replaceAll("%C3%B4", "ô")
        .replaceAll("%C3%B5", "õ")
        .replaceAll("%C3%B6", "ö")
        .replaceAll("%C3%B7", "÷")
        .replaceAll("%C3%B8", "ø")
        .replaceAll("%C3%B9", "ù")
        .replaceAll("%C3%BA", "ú")
        .replaceAll("%C3%BB", "û")
        .replaceAll("%C3%BC", "ü");
    } else {
      store.state.tabTitle = "Profile detected";
    }

    if (store.state.tabTitle.includes("LinkedIn")) {
      store.state.tabTitle = "Profile detected";
    }

    if (!store.state.urlToPass.includes("linkedin")) {
      this.notALinkedInProfile = true;
    }
  },
  methods: {
    selectProject(project) {
      this.selectedProject = project;
    },
    selectStatus(status) {
      this.selectedStatus = status;
      $("#statusMenuInExtensionBtn").dropdown("toggle");
    },
    commentTextareaFocus() {
      setTimeout(() => {
        $("#editProfileCommentTextarea").focus();
      }, 250);
      window.scrollTo(0, document.body.scrollHeight);
    },
    resetSearchBar() {
      this.$store.state.searchInputText = "";
      this.$store.state.searchState = "off";
    },
    showSearchDropdown() {
      this.$store.state.searchState = "on";
    },
    getLinkedIn() {
      if (this.$store.state.linkedinImportState == "off") {
        if (
          store.state.globalAppSettings.linkedinscrapeapiprovider == "iscraper"
        ) {
          this.getLiProfileWithIscraper();
        } else if (
          store.state.globalAppSettings.linkedinscrapeapiprovider == "proxycurl"
        ) {
          this.getLiProfileWithProxycurl();
        } else {
          console.log(
            "error getting linkedinscrapeapiprovider in globalappsettings."
          );
        }
      } else {
        return;
      }

      return;
    },
    getLiProfileWithProxycurl() {
      console.log("trying with proxycurl");

      this.$store.state.linkedinImportState = "on";

      //const url = store.state.urlToPass;
      const url = store.state.urlToPass;
      const body = { url };

      //replace with new link once final domain has been added?
      axios
        .post(
          "https://us-central1-kiwano-3f6de.cloudfunctions.net/getLiProfile",
          body
        )
        .then((response) => {
          console.log(response.data);

          // we do this futher down instead, to check if the attach to project function has been called. this way, the import state will wait for that and theres no risk the process is cut before the function has been called.
          // this.importState = "done";

          //handle empty city name
          let reformatLocation;
          if (!response.data.city) {
            reformatLocation = response.data.country_full_name;
          } else {
            reformatLocation =
              response.data.city + ", " + response.data.country_full_name;
          }

          let currentcompany;
          let currenttitle;
          if (response.data.experiences.length == 0) {
            currentcompany = "";
            currenttitle = "";
          } else {
            currentcompany = response.data.experiences[0].company;
            currenttitle = response.data.experiences[0].title;
          }

          /////////////////////reformat professional experiences
          const experiencelist = response.data.experiences;
          const reformatedExperienceList = [];

          //loop through all the experience items of this profile
          for (let i = 0; i < experiencelist.length; i++) {
            const reformatedExperienceItem = {};

            reformatedExperienceItem.company = experiencelist[i].company;
            reformatedExperienceItem.companyurl =
              experiencelist[i].company_linkedin_profile_url;
            reformatedExperienceItem.companylogourl =
              experiencelist[i].logo_url;
            reformatedExperienceItem.title = experiencelist[i].title;
            reformatedExperienceItem.startmonth =
              experiencelist[i].starts_at.month;
            (reformatedExperienceItem.startyear =
              experiencelist[i].starts_at.year),
              (reformatedExperienceItem.description =
                experiencelist[i].description);

            if (!experiencelist[i].ends_at) {
              reformatedExperienceItem.endyear = 3000;
              reformatedExperienceItem.endmonth = "nodatahere";
            } else {
              reformatedExperienceItem.endyear = experiencelist[i].ends_at.year;
              reformatedExperienceItem.endmonth =
                experiencelist[i].ends_at.month;
            }

            //assign an ID to each experience
            reformatedExperienceItem.id = Math.floor(Math.random() * 100000000);

            reformatedExperienceList.push(reformatedExperienceItem);
          }

          function compare(a, b) {
            if (a.endyear < b.endyear) {
              return -1;
            }
            if (a.endyear > b.endyear) {
              return 1;
            }
            return 0;
          }

          reformatedExperienceList.sort(compare);

          const sortedExperienceList = reformatedExperienceList
            .sort(compare)
            .reverse();

          ////////////////reformat education (just add and ID) to education item

          //loop through all the education items of this profile
          const educationlist = response.data.education;
          const reformatedEducationlist = [];

          for (let i = 0; i < educationlist.length; i++) {
            let reformatedEducationItem = {};

            reformatedEducationItem.degree_name = educationlist[i].degree_name;
            reformatedEducationItem.school = educationlist[i].school;
            if (educationlist[i].ends_at == null) {
              reformatedEducationItem.endyear = "nodatahere";
            } else {
              reformatedEducationItem.endyear = educationlist[i].ends_at.year;
            }
            if (!educationlist[i].starts_at) {
              reformatedEducationItem.startyear = "nodatahere";
            } else {
              reformatedEducationItem.startyear =
                educationlist[i].starts_at.year;
            }

            //assign an ID to each education item
            reformatedEducationItem.id = Math.floor(Math.random() * 100000000);

            reformatedEducationlist.push(reformatedEducationItem);
          }

          this.currentcompany = currentcompany;
          this.currentjobtitle = currenttitle;
          this.educationlist = reformatedEducationlist;
          this.experiencelist = sortedExperienceList;
          this.linkedinsummary = response.data.summary;
          this.firstname = response.data.first_name;
          this.lastname = response.data.last_name;
          this.linkedinurl = "https://www.linkedin.com/in/" + response.data.public_identifier;
          if (response.data.industry) {
            this.industry = response.data.industry;
          } else {
            this.industry = "Other";
          }
          this.location = reformatLocation;
          this.country = response.data.country_full_name;
          this.profilepic = response.data.profile_pic_url;

          //add profile to Firestore

          //we should add the company logo and url from proxycurl too... it is only added with iscraper currently
          this.saveProfileToFirestore();
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.linkedinImportState = "error";
        });
    },
    getLiProfileWithIscraper() {
      console.log("trying with iscraper");

      this.$store.state.linkedinImportState = "on";

      //with iscraper we need to get rid of the appended sub domain sometimes added by linkedin "?originalSubdomain=uk..."
      let splittedUrl = store.state.urlToPass.split("?");
      store.state.urlToPass = splittedUrl[0];

      let url = store.state.urlToPass;

      const functions = getFunctions();
      const getLiProfileWithIscraper = httpsCallable(
        functions,
        "getLiProfileWithIscraper"
      );
      getLiProfileWithIscraper(url, "nothing")
        .then((result) => {
          console.log(result.data);

          //handle empty city name
          let reformatLocation;
          if (!result.data.location.default) {
            reformatLocation = result.data.location.country;
          } else {
            reformatLocation =
              result.data.location.default +
              ", " +
              result.data.location.country;
          }

          //add company size (number of employees), only with Iscraper. Proxycurl will use the default value "nodatahere", as they don't provide the info
          let companysize = result.data.position_groups[0].company.employees;

          console.log(companysize.start + " : " + companysize.end);

          let reformatedcompanysize;
          if (companysize.start == null && companysize.end == null) {
            reformatedcompanysize = "nodatahere";
          } else if (companysize.start == null && companysize.end != null) {
            reformatedcompanysize = companysize.end;
          } else if (companysize.start != null && companysize.end == null) {
            reformatedcompanysize = companysize.start;
          } else if (companysize.start != null && companysize.end != null) {
            reformatedcompanysize = (companysize.start + companysize.end) / 2;
          }

          this.currentcompanyliurl = result.data.position_groups[0].company.url;
          this.currentcompanylilogo =
            result.data.position_groups[0].company.logo;

          /*
          let currentcompany;
          let currenttitle;
          if (result.data.experiences.length == 0) {
            currentcompany = "";
            currenttitle = "";
          } else {
            currentcompany =  result.data.experiences[0].company;
            currenttitle =  result.data.experiences[0].title;
          }
          */

          //reformat experiences

          const experienceList = [];

          for (let i = 0; i < result.data.position_groups.length; i++) {
            //let companySize = result.data.position_groups[i].company.employees.end

            for (
              let j = 0;
              j < result.data.position_groups[i].profile_positions.length;
              j++
            ) {
              let positionTitle =
                result.data.position_groups[i].profile_positions[j].title;
              let companyName =
                result.data.position_groups[i].profile_positions[j].company;
              console.log("here are the experiences: " + companyName);

              let endyear;
              if (
                result.data.position_groups[i].profile_positions[j].date.end
                  .year == null
              ) {
                endyear = "3000";
              } else {
                endyear =
                  result.data.position_groups[i].profile_positions[j].date.end
                    .year;
              }

              experienceList.push({
                company: companyName,
                title: positionTitle,
                startyear:
                  result.data.position_groups[i].profile_positions[j].date.start
                    .year,
                endyear: endyear,
                id: Math.floor(Math.random() * 100000000),
              });
            }
          }

          //reformat education

          const educationList = [];

          for (let i = 0; i < result.data.education.length; i++) {
            let endyear;

            if (result.data.education[i].date.end.year == null) {
              endyear = "nodatahere";
            } else {
              endyear = result.data.education[i].date.end.year;
            }

            educationList.push({
              school: result.data.education[i].school.name,
              degree_name: result.data.education[i].degree_name,
              startyear: result.data.education[i].date.start.year,
              endyear: endyear,
              id: Math.floor(Math.random() * 100000000),
            });
          }

          console.log(
            "Here is the first company name: " + experienceList[0].company
          );

          console.log(
            "here are the recommended profiles: " + result.data.related_profiles
          );

          this.currentcompany = experienceList[0].company;
          this.currentjobtitle = experienceList[0].title;
          this.educationlist = educationList;
          this.experiencelist = experienceList;
          this.linkedinsummary = result.data.summary;
          this.firstname = result.data.first_name;
          this.lastname = result.data.last_name;
          this.linkedinurl =
            "https://www.linkedin.com/in/" + result.data.profile_id;
          this.location = reformatLocation;
          if (result.data.industry) {
            this.industry = result.data.industry;
          } else {
            this.industry = "Other";
          }

          this.country = result.data.location.country;

          console.log("this is the industry: " + this.industry);

          if (result.data.profile_picture) {
            this.profilepic = result.data.profile_picture;
          } else {
            this.profilepic = false;
          }

          this.companysize = reformatedcompanysize;

          if (result.data.related_profiles != null) {
            for (let i = 0; i < result.data.related_profiles.length; i++) {
              this.alsoviewed.push(result.data.related_profiles[i]);
            }
          }

          console.log("this is the company size: " + reformatedcompanysize);

          //add profile to Firestore
          this.saveProfileToFirestore();
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.linkedinImportState = "error";
        });
    },
    saveProfileToFirestore() {
      //will receive the profile id below
      let profileid;

      //Add the profile to Firestore

      //////////////// IMPORTANT: this is not the only function that creates a new profile.
      //////////////// The user can also create a profile by uploading a CV. In that case,
      ///////////////  the function that will add the profile to Firestore is in profiledetails.js
      ///////////////  and is called addProfileFromCV(). It is a copy of the code below.
      ///////////////  If anything is changed here, it should also be changed in that other function,
      ///////////////  for consistency.
      let docRef = collection(db, "profiles/");
      addDoc(docRef, {
        alsoviewed: this.alsoviewed,
        candidateorclient: this.candidateorclient,
        clientvisible: this.clientvisible,
        currentcompany: this.currentcompany,
        currentcompanyliurl: this.currentcompanyliurl,
        currentcompanylilogo: this.currentcompanylilogo,
        currentjobtitle: this.currentjobtitle,
        cvurl: "",
        educationlist: this.educationlist,
        experiencelist: this.experiencelist,
        linkedinsummary: this.linkedinsummary,
        email: "",
        favourite: false,
        firstname: this.firstname,
        firsttimeshown: true,
        gdprconsentstatus: "Not requested",
        lastname: this.lastname,
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.userData.userid,
        linkedinurl: this.linkedinurl,
        location: this.location,
        country: this.country,
        mobilenumber: "",
        new: true,
        owninguser: store.state.userData.userid,
        profilepic: this.profilepic,
        tags: [],
        teamaccess: this.selectedTeamAccess,
        teamid: this.$store.state.userData.teamid,
        opentaskscounter: 0,
        newemailscounter: 0,
        companysize: this.companysize,
        industryfromlinkedin: this.industry,
        //will be added only if the imported profile comes from LinkedIn recruiter
        recruiterprofileid: store.state.recruiterProfileId,
      })
        .then((docRef) => {
          //store doc id in the profileid variable above for later use (add to project below)
          profileid = docRef.id;

          let activityLogsSubCollection = collection(
            db,
            "profiles/" + docRef.id + "/activitylogs/"
          );
          addDoc(activityLogsSubCollection, {
            typeofdata: "profile",
            timestamp: Date.now(),
            typeofupdate: "Profile created",
            byuser: store.state.userData.userid,
            owninguser: store.state.userData.userid,
          });

          if (this.profilepic) {
            const storage = getStorage();
            const storageRef = ref(
              storage,
              "userid" +
                this.$store.state.userData.userid +
                "/profile" +
                docRef.id
            );

            /////////////// Take the profile pic from the LinkedIn URL and store it in Firebase storage, then add the
            /////////////// new image url to the Profile document in Firestore

            ////IMPORTANT: This will only work with Iscraper, not Proxycurl. This is because Proxycurl uses a Blackbaze server which has CORS enabled. Whereas Iscraper uses the LinkedIn image servers directly.

            // Get the profile pic as a file blob from the LinkedIn image URL
            fetch(this.profilepic)
              .then((response) => response.blob())
              .then((blob) => {
                // Upload the image to Firebase storage
                uploadBytes(storageRef, blob).then((snapshot) => {
                  // Get the URL of the uploaded image (including the media token)
                  getDownloadURL(storageRef).then((url) => {
                    console.log("we have download url: " + url);
                    // Add this new image URL to the profile in Firestore
                    const profileRef = doc(db, "profiles", docRef.id);
                    updateDoc(profileRef, {
                      profilepic: url,
                    });
                  });
                });
              });
          } else {
            //no profile pic to upload
          }
        })
        .then(() => {
          if (this.selectedProject) {
            attachProfileToProjectExternal(
              profileid,
              this.selectedProject,
              this.$route.path,
              this.selectedStatus,
              this.selectedTeamAccess,
              this.notestoadd,
              this.alsoviewed
            );
          } else {
            this.$store.state.linkedinImportState = "done";
          }
        })
        .catch((e) => console.log(e));
    },
  },
  computed: {
    myActiveProjects() {
      const myProjects = this.$store.state.currentMyProjectList;

      const results = myProjects.filter((obj) => {
        return obj.status === "Active";
      });

      // Filtering duplicates based on some unique identifier, e.g., 'id'
      const seenIds = new Set();
      const uniqueResults = results.filter((obj) => {
        if (seenIds.has(obj.id)) {
          return false;
        } else {
          seenIds.add(obj.id);
          return true;
        }
      });

      uniqueResults.sort((a, b) => b.startedat - a.startedat);

      return uniqueResults;
    },

    teamActiveProjects() {
      const teamProjects = this.$store.state.currentTeamProjectList;

      const results = teamProjects.filter((obj) => {
        return obj.status === "Active";
      });

      // Filtering duplicates based on some unique identifier, e.g., 'id'
      const seenIds = new Set();
      const uniqueResults = results.filter((obj) => {
        if (seenIds.has(obj.id)) {
          return false;
        } else {
          seenIds.add(obj.id);
          return true;
        }
      });

      uniqueResults.sort((a, b) => b.startedat - a.startedat);

      return uniqueResults;
    },
  },
};
</script>

<style></style>
