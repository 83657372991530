<template>
  <!--
<button @click="integrateTeams()">Integrate Teams</button>
-->

  <FullCalendar
    :options="calendarOptions"
    :key="$store.state.calendarcomponentkey"
    ref="fullCalendar"
    class="ms-3 me-3"
  />
  <!--begin:event details card container-->
  <div
    id="eventdetails"
    v-if="whichslider == 'eventdetails'"
    class="pt-5 ps-6 pe-5 bg-white shadow-lg"
    style="z-index: 100; height: 100%"
    :class="{ calendarEventDrawer: true, 'calendarEvent-open': isDrawerOpen }"
  >
    <!--begin:event details wrapper-->
    <div>
      <!--begin:close event details btn-->
      <div class="d-flex">
        <div
          class="ms-auto badge h-25px customdangerbadge"
          type="button"
          @click="closeEvent()"
          @mouseenter="isCloseCrossHovered = true"
          @mouseleave="isCloseCrossHovered = false"
        >
          <div
            class="customdangerbadge"
            style="margin-top: -4px; margin-left: -5px"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/arrows/arr088.svg-->
            <span
              class="svg-icon svg-icon-1"
              :class="{
                'svg-icon-light': isCloseCrossHovered,
                'svg-icon-danger': !isCloseCrossHovered,
              }"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="7.05025"
                  y="15.5356"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(-45 7.05025 15.5356)"
                  fill="currentColor"
                />
                <rect
                  x="8.46447"
                  y="7.05029"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(45 8.46447 7.05029)"
                  fill="currentColor"
                /></svg
            ></span>
            <!--end::Svg Icon-->

            Close
          </div>
        </div>
      </div>
      <!--end:close event details btn-->

      <!--begin:event details-->
      <div v-if="$store.state.currentEventDetails">
        <!--begin:event title-->
        <div class="fs-2 mt-2">
          {{ $store.state.currentEventDetails.title }}
        </div>
        <!--end:event title-->
        <!--begin:event date and time-->
        <div class="fs-7 mt-3">
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/general/gen013.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-3 me-1"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                fill="currentColor"
              />
              <path
                d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->{{
            formatEventDateRange(
              $store.state.currentEventDetails.start,
              $store.state.currentEventDetails.end
            )
          }}
        </div>
        <!--end:event date and time-->
        <!--begin:Description-->
        <div
          v-if="$store.state.currentEventDetails.extendedProps.description"
          class="mt-6 fw-normal"
          style="font-size: 0.8rem !important"
          v-html="cleanedDescription"
        ></div>
        <div class="mt-10 me-8" v-else>
          <CardAlert
            alerttype="info"
            title="No description"
            text="We could not find any description for this event."
          ></CardAlert>
        </div>
        <!--end:Description-->
      </div>
    </div>
    <!--end:event details content-->
  </div>
  <!--end:details card container-->

  <div
    id="createevent"
    v-if="whichslider == 'createevent'"
    class="pt-5 ps-6 pe-5 bg-white shadow-lg"
    style="z-index: 100; height: 100%"
    :class="{ calendarEventDrawer: true, 'calendarEvent-open': isDrawerOpen }"
  >
    <!--begin:close add event btn-->
    <div class="d-flex">
      <div
        class="ms-auto badge h-25px customdangerbadge"
        type="button"
        @click="closeEvent()"
        @mouseenter="isCloseCrossHovered = true"
        @mouseleave="isCloseCrossHovered = false"
      >
        <div
          class="customdangerbadge"
          style="margin-top: -4px; margin-left: -5px"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/arrows/arr088.svg-->
          <span
            class="svg-icon svg-icon-1"
            :class="{
              'svg-icon-light': isCloseCrossHovered,
              'svg-icon-danger': !isCloseCrossHovered,
            }"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="7.05025"
                y="15.5356"
                width="12"
                height="2"
                rx="1"
                transform="rotate(-45 7.05025 15.5356)"
                fill="currentColor"
              />
              <rect
                x="8.46447"
                y="7.05029"
                width="12"
                height="2"
                rx="1"
                transform="rotate(45 8.46447 7.05029)"
                fill="currentColor"
              /></svg
          ></span>
          <!--end::Svg Icon-->

          Close
        </div>
      </div>
    </div>
    <!--end:close add event btn-->
    <!--begin: title-->
    <div class="fs-2 mt-2">Add Event</div>
    <!--end: title-->

    <!--begin: date-->
    <div class="mb-5 d-flex">
      <span class="me-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="16px"
          height="16px"
          viewBox="0 0 512 512"
          enable-background="new 0 0 512 512"
          xml:space="preserve"
          fill="#c4c2c2"
        >
          <path
            d="M160,240v32c0,8.844-7.156,16-16,16h-32c-8.844,0-16-7.156-16-16v-32c0-8.844,7.156-16,16-16h32  C152.844,224,160,231.156,160,240z M144,352h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C160,359.156,152.844,352,144,352z M272,224h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C288,231.156,280.844,224,272,224z M272,352h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C288,359.156,280.844,352,272,352z M400,224h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C416,231.156,408.844,224,400,224z M400,352h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C416,359.156,408.844,352,400,352z M112,96h32c8.844,0,16-7.156,16-16V16c0-8.844-7.156-16-16-16h-32c-8.844,0-16,7.156-16,16v64  C96,88.844,103.156,96,112,96z M512,128v320c0,35.344-28.656,64-64,64H64c-35.344,0-64-28.656-64-64V128c0-35.344,28.656-64,64-64  h16v16c0,17.625,14.359,32,32,32h32c17.641,0,32-14.375,32-32V64h160v16c0,17.625,14.375,32,32,32h32c17.625,0,32-14.375,32-32V64  h16C483.344,64,512,92.656,512,128z M480,192c0-17.625-14.344-32-32-32H64c-17.641,0-32,14.375-32,32v256c0,17.656,14.359,32,32,32  h384c17.656,0,32-14.344,32-32V192z M368,96h32c8.844,0,16-7.156,16-16V16c0-8.844-7.156-16-16-16h-32c-8.844,0-16,7.156-16,16v64  C352,88.844,359.156,96,368,96z"
          ></path>
        </svg>
      </span>
      {{ formatDate(startString) }}
    </div>
    <!--end: date-->

    <!--begin:form-->
    <div class="ms-5 mt-15 w-225px">
      <label class="form-label ms-3">Event name</label
      ><input
        type="text"
        id="neweventtitle"
        autocomplete="off"
        class="form-control mb-6"
        v-model="newEventTitle"
      />

      <!--begin:select start time-->
      <div>
        <label for="" class="form-label ms-3">Starting</label>
        <div class="d-flex">
          <span class="mt-4 ms-3 svg-icon svg-icon-muted svg-icon-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                fill="currentColor"
              ></path>
              <path
                d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                fill="currentColor"
              ></path></svg
          ></span>
          <select
            class="form-select custompointer border-0 w-70px"
            v-model="startHour"
            aria-label="Select example"
          >
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </select>
          <span class="mx-2 fs-6 fw-normal" style="margin-top: 9px"> | </span>
          <select
            class="form-select custompointer border-0 w-70px"
            v-model="startMinutes"
            aria-label="Select example"
          >
            <option value="00">00</option>
            <option value="05">05</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35">35</option>
            <option value="40">40</option>
            <option value="45">45</option>
            <option value="50">50</option>
            <option value="55">55</option>
          </select>
        </div>
      </div>
      <!--end:select start time-->

      <!--begin:select end time-->

      <div class="mt-10">
        <label for="" class="form-label ms-3">Ending</label>

        <div class="d-flex">
          <span class="mt-4 ms-3 svg-icon svg-icon-muted svg-icon-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                fill="currentColor"
              ></path>
              <path
                d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                fill="currentColor"
              ></path></svg
          ></span>
          <select
            class="form-select custompointer border-0 w-70px"
            v-model="endHour"
            aria-label="Select example"
          >
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </select>
          <span class="mx-2 fs-6 fw-normal" style="margin-top: 9px"> | </span>
          <select
            class="form-select custompointer border-0 w-70px"
            v-model="endMinutes"
            aria-label="Select example"
          >
            <option value="00">00</option>
            <option value="05">05</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35">35</option>
            <option value="40">40</option>
            <option value="45">45</option>
            <option value="50">50</option>
            <option value="55">55</option>
          </select>
        </div>
      </div>
      <!--end:select end time-->

      <!--begin:add event btn-->
      <div class="d-flex mt-5">
        <button
          v-if="$store.state.addingEventState == 'off'"
          @click="addEventToCalendar()"
          class="mx-auto btn btn-sm w-250px btn-primary"
        >
          Add event
        </button>
        <button
          :data-kt-indicator="$store.state.addingEventState"
          v-if="$store.state.addingEventState == 'on'"
          class="mx-auto btn btn-sm w-250px btn-primary"
        >
          <span class="indicator-label"> Add event1 </span>
          <span class="indicator-progress">
            Please wait
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <button
          v-if="$store.state.addingEventState == 'done'"
          class="mx-auto btn btn-sm w-250px btn-success"
        >
          <span>
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr085.svg-->
            <span class="svg-icon svg-icon-white svg-icon-2x"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
            Event added
          </span>
        </button>
      </div>
      <!--begin:add event btn-->
    </div>

    <!--end:form-->
  </div>
</template>

<script>
//import FullCalendar from "@fullcalendar/vue3";
//import dayGridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction";
//import timeGridPlugin from "@fullcalendar/timegrid";
import { addEventToCalendarExternal } from "../../../js/user.js";
import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CardAlert,
  },
  data() {
    return {
      whichslider: "eventdetails",
      startString: null,
      endString: null,
      startHour: false,
      startMinutes: false,
      endHour: false,
      endMinutes: false,
      isDrawerOpen: false,
      isCloseCrossHovered: false,
      newEventTitle: "",
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "timeGridWeek",
        dayHeaderFormat: { weekday: "short", day: "2-digit", month: "2-digit" },
        locale: "en-GB",
        weekends: false,
        events: this.transformEvents(this.$store.state.userCalendarEvents),
        allDaySlot: false,
        displayEventTime: false,
        slotMinTime: "08:00:00",
        eventTextColor: "#0f71a8",
        eventBackgroundColor: "#F1FAFF",
        eventBorderColor: "#009EF7",
        dayCellDidMount: function (arg) {
          if (arg.isToday) {
            arg.el.style.backgroundColor = "#F5F8FA"; // Replace '#yourColorCode' with your desired color
          }
        },
        eventContent: function (arg) {
          const MIN_EVENT_DURATION = 30; // in minutes
          const SHORT_EVENT_LIMIT = 60; // in minutes
          const MEDIUM_EVENT_LIMIT = 90; // in minutes

          let start = new Date(arg.event.start);
          let end = new Date(arg.event.end);
          let duration = (end - start) / (1000 * 60); // duration in minutes

          let title = arg.event.title;
          if (duration < SHORT_EVENT_LIMIT) {
            title = title.slice(0, 20) + (title.length > 20 ? "..." : "");
          } else if (duration <= MEDIUM_EVENT_LIMIT) {
            title = title.slice(0, 35) + (title.length > 35 ? "..." : "");
          }

          let titleElement = document.createElement("div");
          titleElement.className = "my-event-title"; // Add this line
          titleElement.innerHTML = title;
          titleElement.style.fontSize = "10px"; // Set font size here
          titleElement.style.backgroundColor = "#F1FAFF"; // optional background

          if (duration < MIN_EVENT_DURATION) {
          }

          return { domNodes: [titleElement] };
        },
        selectable: true,
        select: this.handleSlotSelect,
        validRange: this.calculateValidRange(),
        datesSet: (info) => {
          let viewStart = info.start;
          let viewEnd = info.end;

          let title = this.determineTitle(viewStart);

          let toolbarTitleElement = document.querySelector(".fc-toolbar-title");

          toolbarTitleElement.textContent = title;
          toolbarTitleElement.classList.add("ms-4");
        },
        eventClick: function (clickInfo) {
          this.handleEventClick(clickInfo.event);
        }.bind(this),
      },
    };
  },
  methods: {
    updateEvents() {
      this.$store.state.calendarcomponentkey++;
      this.calendarOptions.events = this.transformEvents(
        this.$store.state.userCalendarEvents
      );

      // Depending on your setup, you might also need to refresh or rerender the calendar
      // this.$refs.fullCalendar.getApi().refetchEvents();
    },
    determineTitle(viewStart) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let currentWeekStart = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      let nextWeekStart = new Date(currentWeekStart);
      nextWeekStart.setDate(nextWeekStart.getDate() + 7);
      let weekAfterNextStart = new Date(nextWeekStart);
      weekAfterNextStart.setDate(weekAfterNextStart.getDate() + 7);

      if (viewStart >= currentWeekStart && viewStart < nextWeekStart) {
        return "This Week";
      } else if (viewStart >= nextWeekStart && viewStart < weekAfterNextStart) {
        return "Next Week";
      } else {
        return "In 2 Weeks";
      }
    },
    calculateValidRange() {
      const today = new Date();
      const startOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      const endOfValidRange = new Date(startOfWeek);
      endOfValidRange.setDate(startOfWeek.getDate() + 20); // 14 days for 2 weeks + 6 days for the current week

      return {
        start: startOfWeek.toISOString().split("T")[0],
        end: endOfValidRange.toISOString().split("T")[0],
      };
    },
    integrateTeams() {
      integrateTeamsExternal();
    },
    handleSlotSelect(info) {
      setTimeout(() => {
        document.getElementById("neweventtitle").focus();
      }, 400);
      this.$store.state.addingEventState = "off";
      this.startString = info.startStr;
      this.endString = info.endStr;
      this.newEventTitle = "";
      this.startHour = this.formatHour(info.startStr);
      this.startMinutes = this.formatMinutes(info.startStr);
      this.endHour = this.formatHour(info.endStr);
      this.endMinutes = this.formatMinutes(info.endStr);
      console.log(this.startMinutes);
      this.endHour = this.formatHour(info.endStr);
      this.whichslider = "createevent";
      console.log(this.startString);
      this.isDrawerOpen = false;
      setTimeout(() => {
        this.isDrawerOpen = true; // Open the drawer
      }, 60);
    },
    formatHour(timeString) {
      const date = new Date(timeString);
      let hours = date.getHours();

      // Pad with zero as necessary to ensure two digits
      return hours.toString().padStart(2, "0");
    },
    formatMinutes(timeString) {
      const date = new Date(timeString);
      let minutes = date.getMinutes();

      // Pad with zero as necessary to ensure two digits
      return String(minutes).padStart(2, "0");
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        weekday: "short", // Short name of the day
        month: "short", // Short name of the month
        day: "numeric", // Numeric day of the month
      });
    },
    createTimestampsForNewEvent(
      startString,
      startHour,
      startMinutes,
      endHour,
      endMinutes
    ) {
      // Parse the date string
      const date = new Date(startString);
      const year = date.getFullYear();
      const month = date.getMonth(); // getMonth() returns 0-11
      const day = date.getDate();

      // Create Date objects for start and end times
      const startDate = new Date(year, month, day, startHour, startMinutes);
      const endDate = new Date(year, month, day, endHour, endMinutes);

      // Convert to Unix timestamps (in seconds)
      const startTimeStamp = Math.floor(startDate.getTime() / 1000);
      const endTimeStamp = Math.floor(endDate.getTime() / 1000);

      return { startTimeStamp, endTimeStamp };
    },
    addEventToCalendar() {
      const { startTimeStamp, endTimeStamp } = this.createTimestampsForNewEvent(
        this.startString,
        this.startHour,
        this.startMinutes,
        this.endHour,
        this.endMinutes
      );

      const eventObject = {
        title: this.newEventTitle, // Bound to the form input
        start: startTimeStamp,
        end: endTimeStamp,
      };

      //calling the JS function that will in turn call the cloud funciton. sending the event details (the nylas token will be sent in the JS function)
      addEventToCalendarExternal(eventObject);

      console.log(eventObject);
    },
    transformEvents(events) {
      return events.map((event) => {
        let start, end;

        if (event.when.date) {
          // For Gmail events with date only
          start = new Date(event.when.date).toISOString();
          end = new Date(event.when.date);
          end.setDate(end.getDate() + 1); // Add 1 day to make it an all-day event
          end = end.toISOString();
        } else if (event.when.start_time && event.when.end_time) {
          // For Outlook events with start and end times in Unix timestamp
          start = new Date(event.when.start_time * 1000).toISOString();
          end = new Date(event.when.end_time * 1000).toISOString();
        }

        return {
          id: event.id,
          title: event.title,
          start: start,
          end: end,
          extendedProps: {
            description: event.description,
            location: event.location,
            // Include any other properties you need
          },
        };
      });
    },
    initToolbarTitleObserver() {
      const toolbarTitleElement = document.querySelector(".fc-toolbar-title");
      if (!toolbarTitleElement) return;

      const validTitles = ["This Week", "Next Week", "In 2 Weeks"];
      let lastValidTitle = toolbarTitleElement.textContent; // Initialize with the current title

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "childList" ||
            mutation.type === "characterData"
          ) {
            const currentTitle = mutation.target.textContent;
            if (!validTitles.includes(currentTitle)) {
              mutation.target.textContent = lastValidTitle; // Revert to the last valid title
            } else {
              lastValidTitle = currentTitle; // Update the last valid title
            }
          }
        });
      });

      observer.observe(toolbarTitleElement, {
        childList: true,
        characterData: true,
        subtree: true,
      });
      this.observer = observer; // Store the observer
    },
    handleEventClick(event) {
      (this.$store.state.currentEventDetails = false),
        (this.$store.state.currentEventDetails = event);
      this.whichslider = "eventdetails";
      this.isDrawerOpen = false;
      setTimeout(() => {
        this.isDrawerOpen = true; // Open the drawer
      }, 60);
    },
    formatEventDateRange(start, end) {
      const optionsDate = { weekday: "short", day: "numeric", month: "short" };
      const optionsTime = { hour: "2-digit", minute: "2-digit" };

      let startDate = new Date(start);
      let endDate = new Date(end);

      let formattedDate = startDate.toLocaleDateString("en-GB", optionsDate);
      let formattedStartTime = startDate.toLocaleTimeString(
        "en-GB",
        optionsTime
      );
      let formattedEndTime = endDate.toLocaleTimeString("en-GB", optionsTime);

      return `${formattedDate} / ${formattedStartTime} - ${formattedEndTime}`;
    },
    closeEvent() {
      this.isDrawerOpen = false; // Close the drawer
    },
    cleanHtml(html) {
      const doc = new DOMParser().parseFromString(html, "text/html");
      doc.querySelectorAll("style").forEach((el) => el.remove());
      doc
        .querySelectorAll("[style]")
        .forEach((el) => el.removeAttribute("style"));
      return doc.body.innerHTML;
    },
  },
  computed: {
    cleanedDescription() {
      return this.cleanHtml(
        this.$store.state.currentEventDetails.extendedProps.description
      );
    },
  },

  watch: {
    
    "$store.state.userCalendarEvents": {
      handler: "updateEvents",
      deep: true,
    },
    
    "$store.state.addingEventState"(newValue) {
      if (newValue === "done") {
        setTimeout(() => {
          this.isDrawerOpen = false;
        }, 1000);
      }
    },
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  mounted() {
    this.initToolbarTitleObserver();
  },
};
</script>

<style>
.fc-event-title {
  font-size: 10px !important;
}

.fc-event:hover,
.fc-event.fc-event-start:hover,
.fc-event.fc-event-end:hover {
  background-color: #009ef7 !important;
  cursor: pointer;
}

.fc-event:hover .my-event-title {
  color: white !important; /* New text color */
  background-color: #009ef7 !important; /* New background color */
}

.fc-timegrid-slot:hover {
  background-color: #f1faff !important; /* New background color */
  cursor: pointer;
}

.calendarEventDrawer {
  transition: transform 0.3s ease;
  position: absolute;
  right: 0;
  top: 0;
  width: 300px; /* Adjust as needed */
  transform: translateX(100%);
}

.calendarEvent-open {
  transform: translateX(0%);
}

.customdangerbadge {
  color: #f1416c;
}

.customdangerbadge:hover {
  color: #ffffff;
  background-color: #f1416c;
}

#eventdetails #createevent {
  max-height: 500px; /* Adjust this value as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Disables horizontal scrolling */
}
</style>
