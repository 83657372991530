<template>
<div class="d-flex">
  <!--begin:: Avatar -->
  <div
    v-if="$store.state.userData.avatarurl"
    class="symbol symbol-circle symbol-50px overflow-hidden me-3"
  >
    <div>
      <div class="symbol-label">
        <img :src="$store.state.userData.avatarurl" alt="" class="w-100" />
      </div>
    </div>
  </div>
  <div v-if="$store.state.userData.firstname && !$store.state.userData.avatarurl" class="symbol symbol-circle symbol-50px overflow-hidden me-3">
    <div>
      <div class="symbol-label fs-4 fw-semibold text-primary">
        {{
          $store.state.userData.firstname.substring(0, 1) +
          $store.state.userData.lastname.substring(0, 1)
        }}
      </div>
    </div>
  </div>
  <!--end::Avatar-->
  <!--begin::User details-->
  <div class="d-flex flex-column w-100">
    <a class="text-gray-800 fw-bold mb-1"
      >{{ $store.state.userData.firstname }}
      {{ $store.state.userData.lastname }}</a
    >
    <span>{{ $store.state.userData.email }}</span>
  </div>
  <!--end::User details-->
</div>

    <div class="ms-18 mt-6 d-flex justify-content-start">
              <span class="text-gray-800 mb-1">
                Account type : 
                </span
      >
      <span v-if="$store.state.userData.subscriptiontype == 'free'" class="ms-2 text-gray-800 fw-bold mb-1">
        Free account
      </span>
      <span v-if="$store.state.userData.subscriptiontype == 'trial'" class="ms-2 text-gray-800 fw-bold mb-1">
        Free trial
      </span>
      <span v-if="$store.state.userData.subscriptiontype == 'paid'" class="ms-2 text-gray-800 fw-bold mb-1">
        Paid monthly plan
      </span>
    </div>


  <div class="ms-18 mt-2 d-flex align-items-center mb-5">
    <div class="d-flex justify-content-start">
              <span class="me-2 text-gray-800 mb-1">
                Team :
                </span
      >
      <span class="text-gray-800 fw-bold mb-1">{{
        $store.state.teamData.teamname
      }}</span
      ><span class="text-muted fw-bold text-muted d-block fs-7">{{
        $store.state.teamData.location
      }}</span>
    </div>
  </div>

  <CardAlert class="mt-10" alerttype="info" title="Change your email" text="If you want to change your email, please contact support@kiwanotalent.com">
  </CardAlert>

  <div class="separator my-8"></div>


<span v-if="$store.state.userData.subscriptiontype != 'paid'">
  After subcribing to our monthly plan, you will be able to manage your billing & invoices here. 
</span>

<div class="mt-3 d-flex flex-column align-items-start">
  <a :class="{'disabled': $store.state.userData.subscriptiontype != 'paid'}" class="mb-5 btn btn-light-primary btn-sm" href="https://billing.stripe.com/p/login/8wMg0J3p2bzA8ow7ss" target="_blank">
    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/finance/fin002.svg-->
<span class="svg-icon svg-icon-muted svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 7H2V11H22V7Z" fill="currentColor"/>
<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
    Manage Billing & Invoices</a>
</div>

</template>

<script>
import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
  components: { CardAlert },


};
</script>

<style>
</style>