<template>
  <!--begin:Modal button - A test button can be created like this
  <a
    href="#"
    class="btn btn-sm btn-light btn-active-primary"
    data-bs-toggle="modal"
    data-bs-target="#modal1"
  >
    {{buttonText}}
  </a>
  end:Modal button-->

  <!--begin::Modal-->
  <div class="modal fade" :id="whichModal" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog" 
    :class="{'w-500px': whichModal == 'aiprogress', 
            'h-300px': whichModal == 'aiprogress',
            'mw-300px': whichModal != 'aiprogress'
    }">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body scroll-y mx-5 mx-xl-12 pt-0 pb-5"
          style="overflow-y: inherit !important"
        >
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <h1 class="mb-3" v-if="title">{{ title }}</h1>
            <!--end::Title-->
          </div>
          <!--end::Heading-->

          <!--begin::Content-->
          <div class="mb-10">
            <UploadingFile
              v-if="whichModal == 'uploadingfilealert' || whichModal == 'sendingemail'"
            ></UploadingFile>
                        <AIProgress
              v-if="whichModal == 'aiprogress'"
            ></AIProgress>



          </div>
          <!--end::Content-->
        </div>
        <!--end::Modal body-->
        <!--begin::Modal footer
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >Close</button>
          <button
            type="button"
            class="btn btn-primary"
          >Save</button>
        </div>
        end::Modal footer-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal-->
</template>

<script>
import UploadingFile from "./ModalContents/AlertModal contents/UploadingFile.vue";
import AIProgress from "./ModalContents/AlertModal contents/AIProgress.vue";


export default {
  props: ["whichModal", "title"],
  components: {
    UploadingFile,
    AIProgress
  },
};
</script>

<style>
</style>