import { onSnapshot, query, collection, getDocs, where, doc, addDoc, updateDoc, getDoc, orderBy, deleteDoc } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

import { db } from "../firebase/index";
import store from "../store/index";

import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

//When clicking "Add new status button"
function switchToNewStatusModeExternal() {

  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to update the status list.")
    return
  } 

  store.state.statusListContainerExitAnimation = true
  store.state.showStatusArrows = false;



  setTimeout(() => {
    store.state.addNewStatusMode = true
    store.state.statusListContainerExitAnimation = false
    setTimeout(() => {
      document.getElementById("newStatusInputField").focus()
    }, 100);
  }, 500);

  //delay to let the 'Slide left' animation play
  setTimeout(() => {

    //store.state.addNewStatusMode = true

    /*
    
        //delay to make sure the element is rendered on DOM
        setTimeout(() => {
          document.getElementById("newstatusinput").focus()
        }, 100);
    
      */


  }, 100);


}

//Rearrange order of status list (move down)
function incrementProfileStatusOrderExternal(statusId, order) {


  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to update the status list.")
    return
  } 

  //define which status comes before in rank
  let precedingStatusId = store.state.profileStatusList[order - 2].id

  //apply animation to switch the status rows
  document.getElementById("statusRow" + statusId).classList.add("customProfileStatusMoveUp")
  document.getElementById("statusRow" + precedingStatusId).classList.add("customProfileStatusMoveDown")



  setTimeout(() => {

    document.getElementById("statusRow" + statusId).classList.remove("customProfileStatusMoveUp")
    document.getElementById("statusRow" + precedingStatusId).classList.remove("customProfileStatusMoveDown")


    //move the profile status up by 1 in order
    async function updateThisProfileStatusOrder() {
      const profileRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist", statusId);
      // Set the new status
      await updateDoc(profileRef, {
        order: order - 1
      });

    }
    updateThisProfileStatusOrder()


    //move the preceding profile status down by 1 in order, it takes the previous rank of the status we want to move initially
    async function updatePrecedingProfileStatusOrder() {
      const profileRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist", precedingStatusId);
      // Set the new status
      await updateDoc(profileRef, {
        order: order
      });

    }
    updatePrecedingProfileStatusOrder()




  }, 400);





}

//Rearrange order of status list (move up)
function decrementProfileStatusOrderExternal(statusId, order) {

  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to update the status list.")
    return
  } 
  

  //define which status comes next in rank
  let followingStatusId = store.state.profileStatusList[order].id


  //apply animation to switch the status rows
  document.getElementById("statusRow" + statusId).classList.add("customProfileStatusMoveDown")
  document.getElementById("statusRow" + followingStatusId).classList.add("customProfileStatusMoveUp")


  setTimeout(() => {

    document.getElementById("statusRow" + statusId).classList.remove("customProfileStatusMoveDown")
    document.getElementById("statusRow" + followingStatusId).classList.remove("customProfileStatusMoveUp")


    //move the profile status down by 1 in order
    async function updateThisProfileStatusOrder() {
      const profileRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist", statusId);
      // Set the new status
      await updateDoc(profileRef, {
        order: order + 1
      });
    }
    updateThisProfileStatusOrder()


    //move the following profile status up by 1 in order, it takes the previous rank of the status we want to move initially
    async function updateFollowingProfileStatusOrder() {
      const profileRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist", followingStatusId);
      // Set the new status
      await updateDoc(profileRef, {
        order: order
      });
    }
    updateFollowingProfileStatusOrder()

  }, 400);



}

//Open edit mode for an existing status
function activateProfileStatusEditModeExternal(statusId) {

  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to update the status list.")
    return
  } 


  document
    .getElementById("statusRow" + statusId)
    .classList.add("animate__fadeOut");

  setTimeout(() => {
    store.state.profileStatusEditMode = statusId;

    setTimeout(() => {
      document.getElementById("input" + statusId).focus();
    }, 150);

    setTimeout(() => {
      document
        .getElementById("statusRow" + statusId)
        .classList.remove("animate__fadeOut");
    }, 200);

    document
      .getElementById("statusRow" + statusId)
      .classList.add("animate__fadeIn");

    setTimeout(() => {
      document
        .getElementById("statusRow" + statusId)
        .classList.remove("animate__fadeIn");
    }, 1000);
  }, 500);
}

//Update ONLY the category of an existing status
function updateStatusCategoryExternal(statusid, newcategory) {

  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to modify the status list.")
    return
  } 

  async function updateThisProfileStatusCategory(newcategory) {
    const profileRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/", statusid);
    // Set the new status
    await updateDoc(profileRef, {
      category: newcategory
    });
  }
  updateThisProfileStatusCategory(newcategory)

}

//Confirm update of an existing status
function confirmStatusUpdateExternal(statusid, newstatus) {

  async function updateThisProfileStatusName(newstatus) {

    const profileRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/", statusid);
    // Set the new status
    await updateDoc(profileRef, {
      status: newstatus
    });
  }

  document
    .getElementById("statusRow" + statusid)
    .classList.add("animate__fadeOut");

  setTimeout(() => {
    updateThisProfileStatusName(newstatus)
    store.state.profileStatusEditMode = false;
    document
      .getElementById("statusRow" + statusid)
      .classList.remove("animate__fadeOut");
  }, 500);

}

//Delete existing status
function deleteStatusExternal(statusid, order) {

  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data.")
    return
  } 

  async function deleteTheDoc(statusid) {
    await deleteDoc(doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/", statusid));
  }
  deleteTheDoc(statusid)


  //For each Status that follows the deleted one (in order), we will remove 1 to the order. 
  //So that the deletion does not break the order sequence.       

  //get and loop through all current statuses
  async function updateStatusList() {
    console.log("4")
    const qStatusList = query(collection(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/"), where("order", ">", order));
    const querySnapshot = await getDocs(qStatusList);
    querySnapshot.forEach((document) => {

      //remove 1 to every status order
      async function remove1ToOrderOfEveryStatus(document) {
        const statusRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/", document.id);
        await updateDoc(statusRef, {
          order: document.data().order - 1
        });
        console.log("5")
      }
      remove1ToOrderOfEveryStatus(document)
    });

  }
  updateStatusList()
}

//Confirm add new status, when user clicks on green check button
function confirmAddNewStatusExternal(status, category) {

  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data.")
    return
  } 

  //Form validation
  if (status == "") {
    alert("Please enter a status")
    return false;
  }
  else if (category == "Select category") {
    alert("Please select a category")
    document.getElementById("newStatusInputField").focus()
    return false;
  }
  //Only 15 statuses allowed
  else if (store.state.profileStatusList.length == 25) {
    alert("You have reached the maximum number of 25 statuses allowed. Please delete at least one status before you add a new one.")
    return false;
  }

  //"Add new status" fades out and shows the status row instead
  store.state.addNewStatusMode = false

  //Before we add the new status to Firestore, we need to add it locally to the Vuex array: profileStatusList.
  //This because the otherwise the realtime listener in Firestore creates ugly flickering transitions that don't work
  //well with animated trasitions (fadein, fadeout...)

  //First add 1 to the each status order. This will make rank 1 available to add a new status.
  for (let i = 0; i < store.state.profileStatusList.length; i++) {
    store.state.profileStatusList[i].order = store.state.profileStatusList[i].order + 1
  }

  //Add the new status locally
  store.state.profileStatusList.unshift(
    {
      id: "newStatusTempId",
      status: status,
      category: category,
      order: 1
    }
  )



  //Now we add the status to Firestore DB. 



  //First create a delay in order to let the animated transition (fade out...) play and to let the status
  //being added locally to Vuex.
  setTimeout(() => {


    //Declare the function that will add 1 to every status order. The new status will be added with a rank as 0. 
    //But the list needs to start with the rank 1.
    async function updateStatusList() {

      //Get the status list object for the user from Firestore
      const qStatusList = query(collection(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/"));
      const querySnapshot = await getDocs(qStatusList);
      querySnapshot.forEach((document) => {

        //add 1 to every status order
        async function add1ToOrderOfEveryStatus(document) {
          const statusRef = doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/", document.id);
          await updateDoc(statusRef, {
            order: document.data().order + 1
          });


        }
        add1ToOrderOfEveryStatus(document)
      });

    }


    //First add the new status to Firestore
    let docRef = collection(db, "teams/" + store.state.userData.teamid + "/profilestatuslist/")
    addDoc(docRef, {
      status: status,
      category: category,
      order: 0
    })
      .then(() => {

        //Call function that will add 1 to every status, including the newly created one, which for now has a rank of 0.
        updateStatusList()

      })
      .then(() => {

        //After a delay (necessary to create the right animated transition flow), switch Status Arrows back to visible.
        setTimeout(() => {
          store.state.showStatusArrows = true;


        }, 500);
      })




  }, 700);





}

//Change user role (in Team modal opened from main header)
function assignUserRoleExternal(userRole, userDocId, userid) {


  //count the number of Administrators in the array
  const users = store.state.teammembers


  var adminsArray = users.filter(function (el) {
    return el.userrole == 'Administrator'
  });

  const nbrOfAdmins = adminsArray.length

  if (nbrOfAdmins == 1 && userRole == 'Operator' && userid == store.state.userData.userid) {
    alert("You are the only Administrator. You can not switch to an Operator role as there needs to be at least one Administrator in the team.")
    return
  } 

  if (store.state.userData.userrole == 'Administrator' && userRole == 'Operator' && userid == store.state.userData.userid) {
    const confirmation = window.confirm('Are you sure you want to change your own userrole from "Administrator" to "Operator" ? Please note that you will no longer be able to edit userroles (including yourself) as only Administrators can do that. Also, you will not be able to delete any data anymore.');

    if (!confirmation) {
      return; // Exit the function if the user clicks "Cancel"
    }
  }



  let userRef = doc(db, "users", userDocId)
  updateDoc(userRef, {
    userrole: userRole
  })
    .then(() => {
            //Confirmation toast
            iziToast.show({
              title: 'Userrole updated !',
              backgroundColor: '#47be7d',
              titleColor: 'white',
              theme: 'dark',
              position: 'bottomRight',
              timeout: 3000,
              icon: 'fa fa-check'
            });
    })
}

//Add a new tag to the team defined Profile tags list
const addNewProfileTagExternal = (tagname, userwhoadded) => {

  let tagRef = collection(db, "teams/" + store.state.userData.teamid + "/profiletaglist/")
  addDoc(tagRef, {
    tagname: tagname,
    addedbyuser: userwhoadded,
    timestamp: Date.now()
  })  
    .then(() => {
      //change the key so that the ProfileTags.vue component will re-render and thus update the whitelist. Doesn't update reactively.
        //this does not work when user is on ProfileId for some reason...
      store.state.tagComponentKey += 1


      //reset tagname input
      store.state.tagname = "";
    })
}

//Deletes a tag from the team defined profile tags list
const deleteTagExternal = (tagid) => {

  if (store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data.")
    return
  } 

  const teamid = store.state.userData.teamid
  deleteDoc(doc(db, "teams/" + teamid + "/profiletaglist/", tagid))
  .then(() => {
          //change the key so that the ProfileTags.vue component will re-render and thus update the whitelist. Doesn't update reactively.
        //this does not work when user is on ProfileId for some reason...
        store.state.tagComponentKey += 1
  })
}





export {

  //Switch to 'Add new status' form
  switchToNewStatusModeExternal,

  //Rearrange order of status list
  incrementProfileStatusOrderExternal,
  decrementProfileStatusOrderExternal,

  //Open edit mode for an existing status
  activateProfileStatusEditModeExternal,

  //Update ONLY the category of an existing status
  updateStatusCategoryExternal,

  //Confirm update of an existing status
  confirmStatusUpdateExternal,

  //Delete existing status
  deleteStatusExternal,

  //Confirm add new status
  confirmAddNewStatusExternal,

  //Change user role (in Team modal opened from main header, user button)
  assignUserRoleExternal,

  //Add a tag to the Team tag list. In Edit tags modal 
  //opened either from the Tag card in Profile Details or 
  //from the User menu on main header
  addNewProfileTagExternal,

  //Delete tag from the Team tag list
  deleteTagExternal,



  


}