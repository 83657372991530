<template>
  <div class="row" style="margin-bottom: -40px;">
    <div class="col col-6">
      <ProjectAndClientMetrics class="me-5"></ProjectAndClientMetrics>
    </div>
    <div class="col col-6">
      <TimeToHire v-if="$store.state.currentProjectDetails"></TimeToHire>
    </div>
  </div>
</template>

<script>
import ProjectAndClientMetrics from "../../Cards/ProjectAndClientSharedCards/ProjectAndClientMetrics.vue";
import TimeToHire from "../../Cards/TimeToHireKpi.vue";

export default {
  components: {
    ProjectAndClientMetrics,
    TimeToHire,
  },
};
</script>

<style>
</style>