<template>

<div class="my-5">
Is currently shown? : {{ $store.state.globalAppSettings.showgeneralalert }}

</div>

<input type="text" class="form-control form-control-solid mb-5" placeholder="Type HTML title here"
v-model="title"
>

      <textarea
      class="form-control form-control-solid"
      rows="10"
      style=""
      name="generalAlertText"
      id="generalAlertTextarea"
      placeholder="Type HTML content here..."
      v-model="content"
    ></textarea>

<div class="mt-10 text-muted">
Rendered HTML preview (as user sees it) :
</div>
    <div class="mt-5 border border-secondary rounded p-5" v-html="title"></div>
    <div class="mt-5 border border-secondary rounded p-5" v-html="content"></div>

    <div class="d-flex mt-8">
<div class="ms-auto">
    <button class="btn btn-sm btn-primary" @click="saveAndActivateGeneralAlert()">Save (this will make the alert Live for all users !</button>
    <button class="btn btn-sm btn-danger ms-5" @click="deactivateGeneralAlert()">Deactivate alert</button>

</div>
    </div>
</template>

<script>

import { saveAndActivateGeneralAlertExternal, deactivateGeneralAlertExternal } from "../../../js/backoffice";

export default {

    data() {
        return {
            title: "",
            content: ""
        }
    },
    methods: {
        saveAndActivateGeneralAlert() {
            saveAndActivateGeneralAlertExternal(this.title, this.content)
        },
        deactivateGeneralAlert() {
            deactivateGeneralAlertExternal()
        }
    }

}
</script>

<style>

</style>