<template>
  <!--begin::Drawers-->
  <!--begin::Activities drawer-->
  <div
    id="kt_activities"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="activities"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'lg': '300px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_activities_toggle"
    data-kt-drawer-close="#kt_activities_close"
  >
    <div class="card shadow-none rounded-0">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/general/gen046.svg-->
          <span class="me-3 svg-icon svg-icon-muted svg-icon-2x"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
              />
              <path
                d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          Help
        </h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            id="kt_activities_close"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body">
        <!--begin::Content-->
        <div>
          <p>Install our Chrome Extension to import profiles from LinkedIn</p>
          <a
            class="mb-10 btn btn-sm btn-light-primary"
            href="https://chrome.google.com/webstore/detail/kiwano/lhmbimndandekipofdfpfbajhapfimhc"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="me-1 bi bi-browser-chrome"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
              />
            </svg>
            Install Extension
          </a>
          <p>
            If you need help getting started, or if you are looking for details
            about a specific feature, check out the User Guide.
          </p>
          <a
            href="https://kiwanotalent.com/userguide"
            target="_blank"
            class="btn btn-sm btn-primary"
            >User Guide
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr095.svg-->
            <span class="svg-icon svg-icon-3 ms-2" style="margin-right: -2px"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                  fill="currentColor"
                />
                <rect
                  x="21.9497"
                  y="3.46448"
                  width="13"
                  height="2"
                  rx="1"
                  transform="rotate(135 21.9497 3.46448)"
                  fill="currentColor"
                />
                <path
                  d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </a>
          <br /><br /><br />
          <p>
            You can reach out to support for any help request by chating with us
            on this page or send an email to:
            <a href="mailto:support@kiwanotalent.com"
              >support@kiwanotalent.com</a
            >
          </p>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer py-5 text-center" id="kt_activities_footer"></div>
      <!--end::Footer-->
    </div>
  </div>
  <!--end::Activities drawer-->
  <!--begin::AI Search Chat drawer-->
   <AISearch></AISearch>
  <!--end::AI Search Chat drawer-->
  
  <!-- begin: LinkedIn Chat Drawer -->

<div 
    id="kt_drawer_chat"

    class="bg-body"
    data-kt-drawer="true" 
	data-kt-drawer-name="chat" 
	data-kt-drawer-activate="true" 
	data-kt-drawer-overlay="true" 
	data-kt-drawer-width="{default:'500px', 'md': '500px'}" 
	data-kt-drawer-direction="end" 
    data-kt-drawer-toggle="#kt_linkedinchat_toggle"
	data-kt-drawer-close="#kt_drawer_chat_close"
      style="z-index: 10000"
>

   <LinkedInChat></LinkedInChat>
    </div>
  
  <!-- end: LinkedIn Chat Drawer -->

  <!--end::Drawers-->

  <!--end::Main-->

  <!--begin::Engage drawers-->
  <!--begin::Demos drawer-->
  <div
    id="kt_engage_demos"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="explore"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_engage_demos_toggle"
    data-kt-drawer-close="#kt_engage_demos_close"
  >
    <!--begin::Card-->
    <div class="card shadow-none rounded-0 w-100">
      <!--begin::Header-->
      <div class="card-header" id="kt_engage_demos_header">
        <h3 class="card-title fw-bolder text-gray-700">Explore</h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6"
            id="kt_engage_demos_close"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body" id="kt_engage_demos_body">
        <!--begin::Content-->
        <div
          id="kt_explore_scroll"
          class="scroll-y me-n5 pe-5"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_engage_demos_body"
          data-kt-scroll-dependencies="#kt_engage_demos_header"
          data-kt-scroll-offset="5px"
        >
          <!--begin::Wrapper-->
          <div class="mb-0">
            <!--begin::Heading-->
            <div class="mb-7">
              <div class="d-flex flex-stack">
                <h3 class="mb-0">Metronic Licenses</h3>
                <a
                  href="https://themeforest.net/licenses/standard"
                  class="fw-bold"
                  target="_blank"
                  >License FAQs</a
                >
              </div>
            </div>
            <!--end::Heading-->
            <!--begin::License-->
            <div
              class="rounded border border-dashed border-gray-300 py-4 px-6 mb-5"
            >
              <div class="d-flex flex-stack">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-1">
                    <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                      Regular License
                    </div>
                    <i
                      class="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
                      data-bs-toggle="popover"
                      data-bs-custom-class="popover-dark"
                      data-bs-trigger="hover"
                      data-bs-placement="top"
                      data-bs-content="Use, by you or one client in a single end product which end users are not charged for"
                    ></i>
                  </div>
                  <div class="fs-7 text-muted">
                    For single end product used by you or one client
                  </div>
                </div>
                <div class="text-nowrap">
                  <span class="text-muted fs-7 fw-bold me-n1">$</span>
                  <span class="text-dark fs-1 fw-bolder">39</span>
                </div>
              </div>
            </div>
            <!--end::License-->
            <!--begin::License-->
            <div
              class="rounded border border-dashed border-gray-300 py-4 px-6 mb-5"
            >
              <div class="d-flex flex-stack">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-1">
                    <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                      Extended License
                    </div>
                    <i
                      class="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
                      data-bs-toggle="popover"
                      data-bs-custom-class="popover-dark"
                      data-bs-trigger="hover"
                      data-bs-placement="top"
                      data-bs-content="Use, by you or one client, in a single end product which end users can be charged for."
                    ></i>
                  </div>
                  <div class="fs-7 text-muted">
                    For single SaaS app with paying users
                  </div>
                </div>
                <div class="text-nowrap">
                  <span class="text-muted fs-7 fw-bold me-n1">$</span>
                  <span class="text-dark fs-1 fw-bolder">939</span>
                </div>
              </div>
            </div>
            <!--end::License-->
            <!--begin::License-->
            <div
              class="rounded border border-dashed border-gray-300 py-4 px-6 mb-5"
            >
              <div class="d-flex flex-stack">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-1">
                    <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                      Custom License
                    </div>
                  </div>
                  <div class="fs-7 text-muted">
                    Reach us for custom license offers.
                  </div>
                </div>
                <div class="text-nowrap">
                  <a
                    href="https://keenthemes.com/contact/"
                    class="btn btn-sm btn-success"
                    target="_blank"
                    >Contact Us</a
                  >
                </div>
              </div>
            </div>
            <!--end::License-->
            <!--begin::Purchase-->
            <a
              href="https://1.envato.market/EA4JP"
              class="btn btn-primary mb-15 w-100"
              >Buy Now</a
            >
            <!--end::Purchase-->
            <!--begin::Demos-->
            <div class="mb-0">
              <h3 class="fw-bolder text-center mb-6">Metronic Demos</h3>
              <!--begin::Row-->
              <div class="row g-5">
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo1.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo1"
                        class="btn btn-sm btn-success shadow"
                        >Demo 1</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo2.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo2"
                        class="btn btn-sm btn-success shadow"
                        >Demo 2</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo3.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo3"
                        class="btn btn-sm btn-success shadow"
                        >Demo 3</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo4.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo4"
                        class="btn btn-sm btn-success shadow"
                        >Demo 4</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo5.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo5"
                        class="btn btn-sm btn-success shadow"
                        >Demo 5</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo6.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo6"
                        class="btn btn-sm btn-success shadow"
                        >Demo 6</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo7.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo7"
                        class="btn btn-sm btn-success shadow"
                        >Demo 7</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo8.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo8"
                        class="btn btn-sm btn-success shadow"
                        >Demo 8</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo9.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo9"
                        class="btn btn-sm btn-success shadow"
                        >Demo 9</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo10.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo10"
                        class="btn btn-sm btn-success shadow"
                        >Demo 10</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo11.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo11"
                        class="btn btn-sm btn-success shadow"
                        >Demo 11</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo12.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo12"
                        class="btn btn-sm btn-success shadow"
                        >Demo 12</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo13.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo13"
                        class="btn btn-sm btn-success shadow"
                        >Demo 13</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo14.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo14"
                        class="btn btn-sm btn-success shadow"
                        >Demo 14</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo15.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo15"
                        class="btn btn-sm btn-success shadow"
                        >Demo 15</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo16.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo16"
                        class="btn btn-sm btn-success shadow"
                        >Demo 16</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo17.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo17"
                        class="btn btn-sm btn-success shadow"
                        >Demo 17</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo18.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo18"
                        class="btn btn-sm btn-success shadow"
                        >Demo 18</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-success rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo19.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo19"
                        class="btn btn-sm btn-success shadow"
                        >Demo 19</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo20.png"
                        alt="demo"
                        class="w-100"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a
                        href="https://preview.keenthemes.com/metronic8/demo20"
                        class="btn btn-sm btn-success shadow"
                        >Demo 20</a
                      >
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo21.png"
                        alt="demo"
                        class="w-100 opacity-25"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <div
                        class="badge badge-white px-6 py-4 fw-bold fs-base shadow"
                      >
                        Coming soon
                      </div>
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo22.png"
                        alt="demo"
                        class="w-100 opacity-25"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <div
                        class="badge badge-white px-6 py-4 fw-bold fs-base shadow"
                      >
                        Coming soon
                      </div>
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo23.png"
                        alt="demo"
                        class="w-100 opacity-25"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <div
                        class="badge badge-white px-6 py-4 fw-bold fs-base shadow"
                      >
                        Coming soon
                      </div>
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-6">
                  <!--begin::Demo-->
                  <div
                    class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded"
                  >
                    <div class="overlay-wrapper">
                      <img
                        src="../assets/media/demos/demo24.png"
                        alt="demo"
                        class="w-100 opacity-25"
                      />
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <div
                        class="badge badge-white px-6 py-4 fw-bold fs-base shadow"
                      >
                        Coming soon
                      </div>
                    </div>
                  </div>
                  <!--end::Demo-->
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Demos-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
  <!--end::Demos drawer-->
  <!--begin::Help drawer-->
  <div
    id="kt_help"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="help"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'350px', 'md': '525px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_help_toggle"
    data-kt-drawer-close="#kt_help_close"
  >
    <!--begin::Card-->
    <div class="card shadow-none rounded-0 w-100">
      <!--begin::Header-->
      <div class="card-header" id="kt_help_header">
        <h5 class="card-title fw-bold text-gray-600">
          Learn &amp; Get Inspired
        </h5>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon explore-btn-dismiss me-n5"
            id="kt_help_close"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body" id="kt_help_body">
        <!--begin::Content-->
        <div
          id="kt_help_scroll"
          class="hover-scroll-overlay-y"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_help_body"
          data-kt-scroll-dependencies="#kt_help_header"
          data-kt-scroll-offset="5px"
        >
          <!--begin::Support-->
          <div
            class="rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10"
          >
            <!--begin::Heading-->
            <h2 class="fw-bolder mb-5">
              Support at
              <a href="https://kiwanotalent.com/userguide" class=""
                >devs.keenthemes.com</a
              >
            </h2>
            <!--end::Heading-->
            <!--begin::Description-->
            <div class="fs-5 fw-bold mb-5">
              <span class="text-gray-500"
                >Join our developers community to find answer to your question
                and help others.</span
              >
              <a
                class="explore-link d-none"
                href="https://keenthemes.com/licensing"
                >FAQs</a
              >
            </div>
            <!--end::Description-->
            <!--begin::Link-->
            <a
              href="https://kiwanotalent.com/userguide"
              class="btn btn-lg explore-btn-primary w-100"
              >Get Support</a
            >
            <!--end::Link-->
          </div>
          <!--end::Support-->
          <!--begin::Link-->
          <div class="d-flex align-items-center mb-7">
            <!--begin::Icon-->
            <div
              class="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning"
            >
              <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
              <span
                class="svg-icon svg-icon-warning svg-icon-2x svg-icon-lg-3x"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                    fill="black"
                  />
                  <path
                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Icon-->
            <!--begin::Info-->
            <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column me-2 me-lg-5">
                <!--begin::Title-->
                <a
                  href="../../demo19/dist/documentation/getting-started.html"
                  class="text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                  >Documentation &amp; Videos</a
                >
                <!--end::Title-->
                <!--begin::Description-->
                <div class="text-muted fw-bold fs-7 fs-lg-6">
                  From guides and video tutorials, to live demos and code
                  examples to get started.
                </div>
                <!--end::Description-->
              </div>
              <!--end::Wrapper-->
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
              <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="18"
                    y="13"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(-180 18 13)"
                    fill="black"
                  />
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Link-->
          <!--begin::Link-->
          <div class="d-flex align-items-center mb-7">
            <!--begin::Icon-->
            <div
              class="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary"
            >
              <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg-->
              <span
                class="svg-icon svg-icon-primary svg-icon-2x svg-icon-lg-3x"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z"
                    fill="black"
                  />
                  <path
                    d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Icon-->
            <!--begin::Info-->
            <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column me-2 me-lg-5">
                <!--begin::Title-->
                <a
                  href="../../demo19/dist/documentation/base/utilities.html"
                  class="text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                  >Plugins &amp; Components</a
                >
                <!--end::Title-->
                <!--begin::Description-->
                <div class="text-muted fw-bold fs-7 fs-lg-6">
                  Check out our 300+ in-house components and customized
                  3rd-party plugins.
                </div>
                <!--end::Description-->
              </div>
              <!--end::Wrapper-->
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
              <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="18"
                    y="13"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(-180 18 13)"
                    fill="black"
                  />
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Link-->
          <!--begin::Link-->
          <div class="d-flex align-items-center mb-7">
            <!--begin::Icon-->
            <div
              class="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-info"
            >
              <!--begin::Svg Icon | path: icons/duotune/art/art006.svg-->
              <span class="svg-icon svg-icon-info svg-icon-2x svg-icon-lg-3x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                    fill="black"
                  />
                  <path
                    d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Icon-->
            <!--begin::Info-->
            <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column me-2 me-lg-5">
                <!--begin::Title-->
                <a
                  href="https://preview.keenthemes.com/metronic8/demo19/layout-builder.html"
                  class="text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                  >Layout Builder</a
                >
                <!--end::Title-->
                <!--begin::Description-->
                <div class="text-muted fw-bold fs-7 fs-lg-6">
                  Build your layout, preview it and export the HTML for server
                  side integration.
                </div>
                <!--end::Description-->
              </div>
              <!--end::Wrapper-->
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
              <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="18"
                    y="13"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(-180 18 13)"
                    fill="black"
                  />
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Link-->
          <!--begin::Link-->
          <div class="d-flex align-items-center mb-7">
            <!--begin::Icon-->
            <div
              class="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-danger"
            >
              <!--begin::Svg Icon | path: icons/duotune/electronics/elc009.svg-->
              <span class="svg-icon svg-icon-danger svg-icon-2x svg-icon-lg-3x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13 9V8C13 7.4 13.4 7 14 7H15C16.7 7 18 5.7 18 4V3C18 2.4 17.6 2 17 2C16.4 2 16 2.4 16 3V4C16 4.6 15.6 5 15 5H14C12.3 5 11 6.3 11 8V9H13Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M21 22H3C2.4 22 2 21.6 2 21V10C2 9.4 2.4 9 3 9H21C21.6 9 22 9.4 22 10V21C22 21.6 21.6 22 21 22ZM5 12C4.4 12 4 12.4 4 13C4 13.6 4.4 14 5 14C5.6 14 6 13.6 6 13C6 12.4 5.6 12 5 12ZM8 12C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14C8.6 14 9 13.6 9 13C9 12.4 8.6 12 8 12ZM11 12C10.4 12 10 12.4 10 13C10 13.6 10.4 14 11 14C11.6 14 12 13.6 12 13C12 12.4 11.6 12 11 12ZM14 12C13.4 12 13 12.4 13 13C13 13.6 13.4 14 14 14C14.6 14 15 13.6 15 13C15 12.4 14.6 12 14 12ZM9 15C8.4 15 8 15.4 8 16C8 16.6 8.4 17 9 17C9.6 17 10 16.6 10 16C10 15.4 9.6 15 9 15ZM12 15C11.4 15 11 15.4 11 16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16C13 15.4 12.6 15 12 15ZM15 15C14.4 15 14 15.4 14 16C14 16.6 14.4 17 15 17C15.6 17 16 16.6 16 16C16 15.4 15.6 15 15 15ZM19 18C18.4 18 18 18.4 18 19C18 19.6 18.4 20 19 20C19.6 20 20 19.6 20 19C20 18.4 19.6 18 19 18ZM7 19C7 18.4 6.6 18 6 18H5C4.4 18 4 18.4 4 19C4 19.6 4.4 20 5 20H6C6.6 20 7 19.6 7 19ZM7 16C7 15.4 6.6 15 6 15H5C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17H6C6.6 17 7 16.6 7 16ZM17 14H19C19.6 14 20 13.6 20 13C20 12.4 19.6 12 19 12H17C16.4 12 16 12.4 16 13C16 13.6 16.4 14 17 14ZM18 17H19C19.6 17 20 16.6 20 16C20 15.4 19.6 15 19 15H18C17.4 15 17 15.4 17 16C17 16.6 17.4 17 18 17ZM17 19C17 18.4 16.6 18 16 18H9C8.4 18 8 18.4 8 19C8 19.6 8.4 20 9 20H16C16.6 20 17 19.6 17 19Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Icon-->
            <!--begin::Info-->
            <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column me-2 me-lg-5">
                <!--begin::Title-->
                <a
                  href="../../demo19/dist/documentation/getting-started/changelog.html"
                  class="text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                  >What's New</a
                >
                <!--end::Title-->
                <!--begin::Description-->
                <div class="text-muted fw-bold fs-7 fs-lg-6">
                  Latest features and improvements added with our users feedback
                  in mind.
                </div>
                <!--end::Description-->
              </div>
              <!--end::Wrapper-->
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
              <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="18"
                    y="13"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(-180 18 13)"
                    fill="black"
                  />
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Link-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
  <!--end::Help drawer-->
  <!--end::Engage drawers-->

  <!--begin::Engage toolbar-->
  <div
    class="d-none engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2"
  >
    <!--begin::Demos drawer toggle-->
    <button
      id="kt_engage_demos_toggle"
      class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0"
      title="Check out 20 more demos"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      data-bs-dismiss="click"
      data-bs-trigger="hover"
    >
      <span id="kt_engage_demos_label">Demos</span>
    </button>
    <!--end::Demos drawer toggle-->
    <!--begin::Help drawer toggle-->
    <button
      id="kt_help_toggle"
      class="engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0"
      title="Learn &amp; Get Inspired"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      data-bs-dismiss="click"
      data-bs-trigger="hover"
    >
      Helpsss
    </button>
    <!--end::Help drawer toggle-->
    <!--begin::Purchase link-->
    <a
      href="https://1.envato.market/EA4JP"
      target="_blank"
      class="engage-purchase-link btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 px-5 shadow-sm rounded-top-0"
      >Buy Now</a
    >
    <!--end::Purchase link-->
  </div>
  <!--end::Engage toolbar-->

  <!--begin::Scrolltop-->
  <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
    <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
    <span class="svg-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          opacity="0.5"
          x="13"
          y="6"
          width="13"
          height="2"
          rx="1"
          transform="rotate(90 13 6)"
          fill="black"
        />
        <path
          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
          fill="black"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
  </div>
  <!--end::Scrolltop-->

  <!--begin::Modals-->

  <!--begin::Modal - Users Search-->
  <div
    class="modal fade"
    id="kt_modal_users_search"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          <!--begin::Content-->
          <div class="text-center mb-13">
            <h1 class="mb-3">Search Users</h1>
            <div class="text-muted fw-bold fs-5">
              Invite Collaborators To Your Project
            </div>
          </div>
          <!--end::Content-->
          <!--begin::Search-->
          <div
            id="kt_modal_users_search_handler"
            data-kt-search-keypress="true"
            data-kt-search-min-length="2"
            data-kt-search-enter="enter"
            data-kt-search-layout="inline"
          >
            <!--begin::Form-->
            <form
              data-kt-search-element="form"
              class="w-100 position-relative mb-5"
              autocomplete="off"
            >
              <!--begin::Hidden input(Added to disable form autocomplete)-->
              <input type="hidden" />
              <!--end::Hidden input-->
              <!--begin::Icon-->
              <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
              <span
                class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="black"
                  />
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
              <!--end::Icon-->
              <!--begin::Input-->
              <input
                type="text"
                class="form-control form-control-lg form-control-solid px-15"
                name="search"
                value=""
                placeholder="Search by username, full name or email..."
                data-kt-search-element="input"
              />
              <!--end::Input-->
              <!--begin::Spinner-->
              <span
                class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
                data-kt-search-element="spinner"
              >
                <span
                  class="spinner-border h-15px w-15px align-middle text-muted"
                ></span>
              </span>
              <!--end::Spinner-->
              <!--begin::Reset-->
              <span
                class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none"
                data-kt-search-element="clear"
              >
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                <span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="6"
                      y="17.3137"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y="6"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <!--end::Reset-->
            </form>
            <!--end::Form-->
            <!--begin::Wrapper-->
            <div class="py-5">
              <!--begin::Suggestions-->
              <div data-kt-search-element="suggestions">
                <!--begin::Heading-->
                <h3 class="fw-bold mb-5">Recently searched:</h3>
                <!--end::Heading-->
                <!--begin::Users-->
                <div class="mh-375px scroll-y me-n7 pe-7">
                  <!--begin::User-->
                  <a
                    href="#"
                    class="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="../assets/media/avatars/300-6.jpg" />
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Info-->
                    <div class="fw-bold">
                      <span class="fs-6 text-gray-800 me-2">Emma Smith</span>
                      <span class="badge badge-light">Art Director</span>
                    </div>
                    <!--end::Info-->
                  </a>
                  <!--end::User-->
                  <!--begin::User-->
                  <a
                    href="#"
                    class="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle me-5">
                      <span
                        class="symbol-label bg-light-danger text-danger fw-bold"
                        >M</span
                      >
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Info-->
                    <div class="fw-bold">
                      <span class="fs-6 text-gray-800 me-2">Melody Macy</span>
                      <span class="badge badge-light">Marketing Analytic</span>
                    </div>
                    <!--end::Info-->
                  </a>
                  <!--end::User-->
                  <!--begin::User-->
                  <a
                    href="#"
                    class="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="../assets/media/avatars/300-1.jpg" />
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Info-->
                    <div class="fw-bold">
                      <span class="fs-6 text-gray-800 me-2">Max Smith</span>
                      <span class="badge badge-light">Software Enginer</span>
                    </div>
                    <!--end::Info-->
                  </a>
                  <!--end::User-->
                  <!--begin::User-->
                  <a
                    href="#"
                    class="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="../assets/media/avatars/300-5.jpg" />
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Info-->
                    <div class="fw-bold">
                      <span class="fs-6 text-gray-800 me-2">Sean Bean</span>
                      <span class="badge badge-light">Web Developer</span>
                    </div>
                    <!--end::Info-->
                  </a>
                  <!--end::User-->
                  <!--begin::User-->
                  <a
                    href="#"
                    class="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="../assets/media/avatars/300-25.jpg" />
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Info-->
                    <div class="fw-bold">
                      <span class="fs-6 text-gray-800 me-2">Brian Cox</span>
                      <span class="badge badge-light">UI/UX Designer</span>
                    </div>
                    <!--end::Info-->
                  </a>
                  <!--end::User-->
                </div>
                <!--end::Users-->
              </div>
              <!--end::Suggestions-->
              <!--begin::Results(add d-none to below element to hide the users list by default)-->
              <div data-kt-search-element="results" class="d-none">
                <!--begin::Users-->
                <div class="mh-375px scroll-y me-n7 pe-7">
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="0"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='0']"
                          value="0"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-6.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Emma Smith</a
                        >
                        <div class="fw-bold text-muted">
                          e.smith@kpmg.com.au
                        </div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2" selected="selected">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="1"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='1']"
                          value="1"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-danger text-danger fw-bold"
                          >M</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Melody Macy</a
                        >
                        <div class="fw-bold text-muted">melody@altbox.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1" selected="selected">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="2"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='2']"
                          value="2"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-1.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Max Smith</a
                        >
                        <div class="fw-bold text-muted">max@kt.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3" selected="selected">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="3"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='3']"
                          value="3"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-5.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Sean Bean</a
                        >
                        <div class="fw-bold text-muted">sean@dellito.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2" selected="selected">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="4"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='4']"
                          value="4"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-25.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Brian Cox</a
                        >
                        <div class="fw-bold text-muted">brian@exchange.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3" selected="selected">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="5"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='5']"
                          value="5"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-warning text-warning fw-bold"
                          >C</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Mikaela Collins</a
                        >
                        <div class="fw-bold text-muted">mikaela@pexcom.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2" selected="selected">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="6"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='6']"
                          value="6"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-9.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Francis Mitcham</a
                        >
                        <div class="fw-bold text-muted">
                          f.mitcham@kpmg.com.au
                        </div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3" selected="selected">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="7"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='7']"
                          value="7"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-danger text-danger fw-bold"
                          >O</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Olivia Wild</a
                        >
                        <div class="fw-bold text-muted">
                          olivia@corpmail.com
                        </div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2" selected="selected">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="8"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='8']"
                          value="8"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-primary text-primary fw-bold"
                          >N</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Neil Owen</a
                        >
                        <div class="fw-bold text-muted">
                          owen.neil@gmail.com
                        </div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1" selected="selected">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="9"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='9']"
                          value="9"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-23.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Dan Wilson</a
                        >
                        <div class="fw-bold text-muted">dam@consilting.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3" selected="selected">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="10"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='10']"
                          value="10"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-danger text-danger fw-bold"
                          >E</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Emma Bold</a
                        >
                        <div class="fw-bold text-muted">emma@intenso.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2" selected="selected">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="11"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='11']"
                          value="11"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-12.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Ana Crown</a
                        >
                        <div class="fw-bold text-muted">ana.cf@limtel.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1" selected="selected">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="12"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='12']"
                          value="12"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-info text-info fw-bold"
                          >A</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Robert Doe</a
                        >
                        <div class="fw-bold text-muted">robert@benko.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3" selected="selected">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="13"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='13']"
                          value="13"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-13.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >John Miller</a
                        >
                        <div class="fw-bold text-muted">miller@mapple.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3" selected="selected">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="14"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='14']"
                          value="14"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-success text-success fw-bold"
                          >L</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Lucy Kunic</a
                        >
                        <div class="fw-bold text-muted">lucy.m@fentech.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2" selected="selected">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="15"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='15']"
                          value="15"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <img
                          alt="Pic"
                          src="../assets/media/avatars/300-21.jpg"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Ethan Wilder</a
                        >
                        <div class="fw-bold text-muted">ethan@loop.com.au</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1" selected="selected">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div
                    class="border-bottom border-gray-300 border-bottom-dashed"
                  ></div>
                  <!--end::Separator-->
                  <!--begin::User-->
                  <div
                    class="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id="16"
                  >
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-custom form-check-solid me-5"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='16']"
                          value="16"
                        />
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Avatar-->
                      <div class="symbol symbol-35px symbol-circle">
                        <span
                          class="symbol-label bg-light-danger text-danger fw-bold"
                          >E</span
                        >
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <a
                          href="#"
                          class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
                          >Emma Bold</a
                        >
                        <div class="fw-bold text-muted">emma@intenso.com</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Access menu-->
                    <div class="ms-2 w-100px">
                      <select
                        class="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option value="1">Guest</option>
                        <option value="2">Owner</option>
                        <option value="3" selected="selected">Can Edit</option>
                      </select>
                    </div>
                    <!--end::Access menu-->
                  </div>
                  <!--end::User-->
                </div>
                <!--end::Users-->
                <!--begin::Actions-->
                <div class="d-flex flex-center mt-15">
                  <button
                    type="reset"
                    id="kt_modal_users_search_reset"
                    data-bs-dismiss="modal"
                    class="btn btn-active-light me-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="kt_modal_users_search_submit"
                    class="btn btn-primary"
                  >
                    Add Selected Users
                  </button>
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Results-->
              <!--begin::Empty-->
              <div data-kt-search-element="empty" class="text-center d-none">
                <!--begin::Message-->
                <div class="fw-bold py-10">
                  <div class="text-gray-600 fs-3 mb-2">No users found</div>
                  <div class="text-muted fs-6">
                    Try to search by username, full name or email...
                  </div>
                </div>
                <!--end::Message-->
                <!--begin::Illustration-->
                <div class="text-center px-5">
                  <img
                    src="../assets/media/illustrations/sketchy-1/1.png"
                    alt=""
                    class="w-100 h-200px h-sm-325px"
                  />
                </div>
                <!--end::Illustration-->
              </div>
              <!--end::Empty-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Search-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Users Search-->
  <!--end::Modals-->
</template>

<script>
import Modal1 from "../components/Modals/Modal1.vue";
import AISearch from "./AiSearchChatbot.vue";
import LinkedInChat from "./LinkedInChat.vue";



export default {
  components: {
    Modal1, AISearch, LinkedInChat
  },
};
</script>

<style></style>
