<template>
  <!--end::Modal - Create App--><!--begin::Modal - Create account-->
  <div
    class="modal fade"
    id="manageportalmodal"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
    v-if="$store.state.currentProjectDetails"
  >


    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Title-->
          <div class="d-flex flex-column">
            <h2>
              {{
                $store.state.currentProjectDetails.clientobject.clientname +
                " / " +
                $store.state.currentProjectDetails.positiontitle
              }}
            </h2>
            <h6 class="mt-3">Client portal</h6>
          </div>
          <!--end::Title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y m-5" style="margin-top: 30px">
          <div class="d-flex">
            <div class="ms-16">
              <ul
                class="nav nav-tabs nav-line-tabs nav-line-tabs-2x me-5 mb-3 mb-md-0 fs-6"
              >
                <li class="nav-item w-md-140px me-0">
                  <a
                    class="nav-link active"
                    id="tab1Link"
                    data-bs-toggle="tab"
                    href="#kt_vtab_pane_1"
                    >1. Select profiles</a
                  >
                </li>
                <li class="nav-item w-md-130px me-0">
                  <a
                    class="nav-link"
                    id="tab2Link"
                    data-bs-toggle="tab"
                    href="#kt_vtab_pane_2"
                    >2. Add client users</a
                  >
                </li>
                <li class="nav-item w-md-75px">
                  <a
                    class="nav-link"
                    id="tab3Link"
                    data-bs-toggle="tab"
                    href="#kt_vtab_pane_3"
                    >3. Share</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div
            class="tab-content mt-7"
            style="margin-left: 50px; margin-right: 100px"
            id="myTabContent"
          >
            <div
              class="tab-pane fade show active"
              id="kt_vtab_pane_1"
              role="tabpanel"
            >
              <div
                class="mt-5 mb-5 d-flex border border-primary border-1 rounded pt-3 pb-1 ps-4"
              >
                <span class="me-3 text-primary">Profiles to share : </span>
                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-2"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    value="all"
                    v-model="whichprofiles"
                    @click="batchApplyClientVisibility()"
                  />
                  <span class="form-check-label text-primary">All</span>
                </label>

                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-2"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    value="none"
                    v-model="whichprofiles"
                    @click="batchApplyClientVisibility()"
                  />
                  <span class="form-check-label text-primary">None </span>
                </label>

                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-2"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    value="status"
                    v-model="whichprofiles"
                    @click="resetSelectedStatusList()"
                  />
                  <span class="form-check-label text-primary">By Status</span>
                </label>

                <div class="" v-if="whichprofiles == 'status'">
                  <div class="dropdown" style="margin-top: -9px">
                    <button
                      class="btn btn-sm btn-link btn-color-muted animate__animated animate__fadeIn dropdown-toggle"
                      id="selectstatusforportal"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      Select status
                    </button>
                    <ul
                      class="dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-bold py-4 fs-6 w-150px"
                      id="dropdownmenuselectstatusinportal"
                      aria-labelledby="selectstatusforportal"
                      role="menu"
                    >
                      <li
                        v-for="status in computedStatusList"
                        :key="status.id"
                        class="my-1"
                      >
                        <div class="d-flex ms-3">
                          <label
                            class="form-check form-check-sm form-check-custom me-2"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                              @click="addStatusToPortalList(status.id)"
                            />
                          </label>
                          <span
                            class="badge badge-sm badge-primary"
                            :class="{
                              'badge-light-success':
                                status.category == 'Interested',
                              'badge-light-primary':
                                status.category == 'In progress',
                              'badge-light-danger':
                                status.category == 'Discarded',
                              'badge-light': status.category == 'No status',
                            }"
                          >
                            {{ status.status }}
                          </span>
                        </div>
                      </li>
                      <button
                        class="mt-4 ms-4 me-4 mb-3 btn btn-sm btn-primary"
                        @click="batchApplyClientVisibility()"
                      >
                        Save
                      </button>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                class="d-none text-muted mt-4 mb-3 fs-8 p-2 border border-gray-400 rounded d-flex"
              >
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen045.svg-->
                <span class="svg-icon svg-icon-primary svg-icon-3 me-1"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="10"
                      fill="currentColor"
                    />
                    <rect
                      x="11"
                      y="17"
                      width="7"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 17)"
                      fill="currentColor"
                    />
                    <rect
                      x="11"
                      y="9"
                      width="2"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 9)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
                The profiles with "Client access" enabled will be shared with
                your client. You can fine tune this selection by
                enabling/disabling the "Client access" option below.
              </div>

              <div class="mh-350px scroll-y me-n7">
                <!--begin::Table container-->
                <div class="table-responsive">
                  <!--begin::Table-->
                  <table
                    class="table table-row-dashed table-row-gray-300 align-middle gs-3 gy-4"
                    id="profilesTable2"
                    :class="{
                      'table-hover': $store.state.currentProfileList.length > 0,
                    }"
                    style="margin-bottom: 100px !important"
                  >
                    <!--begin::Table head-->
                    <thead>
                      <tr class="fw-bolder text-muted" style="height: 65px">
                        <th class="min-w-150px" style="width: 28%">Profile</th>
                        <th class="min-w-140px" style="width: 22%">
                          Company <br />
                        </th>

                        <th class="min-w-80px" style="width: 6%">Status</th>

                        <th class="min-w-150px text-start" style="width: 15%">
                          Client access <br />
                        </th>
                      </tr>
                    </thead>
                    <!--end::Table head-->
                    <!--begin::Table body-->

                    <tbody
                      v-if="$store.state.currentProfileList.length > 0"
                      class="animate__animated animate__fadeIn"
                    >
                      <tr
                        style="cursor: pointer"
                        v-for="profile in $store.state.currentProfileList"
                        :key="profile.id"
                        :id="'profileRow' + profile.id"
                      >
                        <td>
                          <div class="d-flex align-items-center">
                            <div
                              class="d-flex flex-column symbol symbol-40px me-5"
                            >
                              <img
                                alt="Pic"
                                v-if="profile.profilepic"
                                :src="profile.profilepic"
                                onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                              />
                              <img
                                alt="Pic"
                                v-if="!profile.profilepic"
                                src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                              />
                              <span
                                v-if="profile.candidateorclient == 'Client'"
                                class="mt-2 badge badge-sm badge-light-info"
                                >Client</span
                              >
                            </div>
                            <div
                              class="d-flex justify-content-start flex-column"
                            >
                              <a class="text-dark fw-bolder fs-6">{{
                                profile.firstname +
                                " " +
                                profile.lastname.toUpperCase()
                              }}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            v-if="profile.currentcompany"
                            class="text-muted fw-bolder d-block fs-7 me-6"
                            >{{
                              profile.currentcompany.length < 20
                                ? profile.currentcompany
                                : profile.currentcompany.substring(0, 20) +
                                  "..."
                            }}</a
                          >
                        </td>

                        <td>
                          <span
                            class="badge badge-sm"
                            :class="{
                              'badge-light-success':
                                getStatusCategory(profile.statusid) ==
                                'Interested',
                              'badge-light-primary':
                                getStatusCategory(profile.statusid) ==
                                'In progress',
                              'badge-light-danger':
                                getStatusCategory(profile.statusid) ==
                                'Discarded',
                              'badge-light':
                                getStatusCategory(profile.statusid) ==
                                'No status',
                            }"
                          >
                            {{ getStatusName(profile.statusid) }}
                          </span>
                        </td>

                        <td>
                          <div
                            class="d-flex justify-content-center flex-shrink-0"
                          >
                            <div
                              class="form-check form-switch"
                              @click="updateClientAccessForProfile(profile)"
                            >
                              <input
                                class="form-check-input"
                                v-model="profile.clientvisible"
                                type="checkbox"
                                role="switch"
                                :id="profile.id + 'clientaccess'"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>

                    <!--begin: Loading profiles animation (before currentProfileList has been loaded into Vuex-->
                    <tbody v-if="$store.state.currentProfileList.length == 0">
                      <tr style="height: 200px">
                        <td></td>
                        <td>
                          <div class="ms-15 mt-10" id="preloader_1">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <!--end: Loading profiles animation-->

                    <!--begin: Loading profiles animation (when user has scrolled to bottom, loading more profiles-->
                    <tbody
                      v-if="
                        $store.state.scrolledToBottom &&
                        $store.state.currentProfileList.length >
                          $store.state.numberOfProfilesLoaded
                      "
                    >
                      <tr style="height: 50px">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <div class="ms-5 mt-10 me-20" id="preloader_1">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <!--end: Loading profiles animation-->

                    <!--begin: Loading profiles animation-->
                    <tbody v-if="!$store.state.scrolledToBottom">
                      <tr style="height: 50px">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <div class="ms-5 mt-10 me-20" id="">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <!--end: Loading profiles animation-->

                    <!--end::Table body-->
                  </table>
                  <!--end::Table-->
                </div>
                <!--end::Table container-->
              </div>

              <div class="d-flex mt-5">
                <div class="mx-auto">
                  <button
                    class="btn btn-sm btn-primary ms-3"
                    @click="goToStep2()"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="kt_vtab_pane_2" role="tabpanel">
              <!--begin::Row-->
              <div class="row mt-12">
                <div
                  v-if="
                    $store.state.currentProjectDetails
                      .authorisedclientusersforportal &&
                    $store.state.currentProjectDetails
                      .authorisedclientusersforportal.length > 0
                  "
                  class="mb-3 fs-6 fw-semibold"
                >
                  These users will have access to the selected profiles in the
                  client portal:
                </div>
                <div v-else class="mb-6 fs-6 fw-semibold">
                  Add the client users you would like to share the profiles with
                  (HR Director, Hiring manager, etc.)
                </div>
                <div
                  class="mb-4"
                  v-if="
                    $store.state.currentProjectDetails
                      .authorisedclientusersforportal
                  "
                >
                  <div
                    class="d-flex p-2"
                    v-for="user in filteredUserList"
                    :key="user.email"
                  >
                    <i
                      class="fa fa-genderless text-primary fs-1"
                      aria-hidden="true"
                    ></i>
                    <div class="d-flex flex-column">
                      <div class="d-flex">
                        <span class="ms-2 fs-6 text-dark fw-bold">
                          {{ user.firstname + " " + user.lastname }}
                        </span>
                        <div class="ms-2" style="margin-top: -3px">
                          <span
                            @click="deleteUser(user)"
                            class="ms-auto svg-icon svg-icon-danger svg-icon-xs custompointer mt-3"
                            ><svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="6"
                                y="17.3137"
                                width="16"
                                height="2"
                                rx="1"
                                transform="rotate(-45 6 17.3137)"
                                fill="currentColor"
                              ></rect>
                              <rect
                                x="7.41422"
                                y="6"
                                width="16"
                                height="2"
                                rx="1"
                                transform="rotate(45 7.41422 6)"
                                fill="currentColor"
                              ></rect>
                            </svg>
                          </span>
                        </div>
                      </div>

                      <span class="ms-2 fs-7 text-muted fw-bold">
                        {{ "(" + user.email + ")" }}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    class="mb-6 btn btn-sm btn-light-primary"
                    @click="addClientUser()"
                  >
                    <span
                      v-if="!addClientUserActiveState"
                      class="svg-icon svg-icon-muted svg-icon-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width="16"
                          height="2"
                          rx="1"
                          transform="rotate(-90 11.364 20.364)"
                          fill="currentColor"
                        ></rect>
                        <rect
                          x="4.36396"
                          y="11.364"
                          width="16"
                          height="2"
                          rx="1"
                          fill="currentColor"
                        ></rect></svg
                    ></span>
                    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-26-051612/core/html/src/media/icons/duotune/arrows/arr073.svg-->
                    <span
                      v-if="addClientUserActiveState"
                      class="svg-icon svg-icon-muted svg-icon-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    Add client user
                  </button>
                </div>
                <!--begin::Separator-->
                <div class="separator border-secondary mt-6 mb-2"></div>
                <!--end::Separator-->
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div
                class="row mb-2 mt-6 animate__fadeIn animate__animated"
                v-if="addClientUserActiveState"
              >
                <div class="fs-6 fw-semibold"></div>

                <!--begin::Select existing user dropdown-->
                <div
                  class="dropdown"
                  v-if="filteredUserListFromForThisClient.length > 0"
                >
                  <button
                    class="btn btn-sm btn-flex btn-light"
                    type="button"
                    id="selectExistingClientUser"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-26-051612/core/html/src/media/icons/duotune/arrows/arr072.svg-->
                    <span class="svg-icon svg-icon-muted svg-icon-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    Select from existing users
                  </button>
                  <ul
                    class="dropdown-menu w-md-300px py-7 px-7 menu-sub menu-sub-dropdown"
                    aria-labelledby="selectExistingClientUser"
                    role="menu"
                  >
                    <li
                      v-for="user in filteredUserListFromForThisClient"
                      :key="user.email"
                      @click="selectNewClientUser(user)"
                      class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
                    >
                      <div class="d-flex flex-column">
                        <span class="fw-bolder">{{
                          user.firstname + " " + user.lastname
                        }}</span>

                        <span>{{ "(" + user.email + ")" }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <!--end::Select existing user dropdown-->

                <!--begin::Separator-->
                <div
                  v-if="filteredUserListFromForThisClient.length > 0"
                  class="separator separator-content border-secondary my-5"
                >
                  <span class="w-40px fw-bold">Or</span>
                </div>
                <!--end::Separator-->

                <!--begin: Add user form-->
                <div class="col-sm">
                  <div class="col-sm d-flex flex-column">
                    <div class="row">
                      <div class="col-6">
                        <label for="" class="form-label ms-3"
                          >Firstname
                          <span class="required"></span>
                        </label>
                        <input
                          type="text"
                          v-model="clientfirstname"
                          class="form-control mb-6"
                        />
                      </div>
                      <div class="col-6">
                        <label for="" class="form-label ms-3"
                          >Lastname
                          <span class="required"></span>
                        </label>
                        <input
                          type="text"
                          v-model="clientlastname"
                          class="form-control mb-6"
                        />
                      </div>
                    </div>

                    <label for="" class="form-label ms-3 required">Email</label>
                    <input
                      type="text"
                      v-model="clientemail"
                      class="form-control mb-6"
                    />
                  </div>
                  <div class="d-flex">
                    <button
                      class="btn btn-sm btn-primary ms-auto"
                      @click="
                        saveNewClientUser(
                          clientfirstname,
                          clientlastname,
                          clientemail
                        )
                      "
                    >
                      Save
                    </button>
                  </div>
                </div>
                <!--end: Add user form-->
              </div>
              <!--end::Row-->

              <div class="d-flex mt-5">
                <div class="mx-auto">
                  <button
                    class="btn btn-sm btn-light"
                    @click="goToStep1()"
                    v-if="!addClientUserActiveState"
                  >
                    Back
                  </button>
                  <button
                    v-if="!addClientUserActiveState"
                    class="btn btn-sm btn-primary ms-3"
                    @click="goToStep3()"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="kt_vtab_pane_3" role="tabpanel">
              <div class="d-flex flex-column mt-10 ms-2">
                <div class="form-check form-switch mb-6">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    v-model="$store.state.currentProjectDetails.portalactive"
                    @click="toggleSharing()"
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault"
                    >Enable sharing</label
                  >
                </div>

                <div v-if="$store.state.currentProjectDetails.portalactive">
                  <a
                    class="btn btn-sm btn-link"
                    :href="
                      $store.state.baseUrl +
                      'clientauth?teamid=' +
                      $store.state.userData.teamid +
                      '&projectid=' +
                      $store.state.currentProjectDetails.id
                    "
                    target="_blank"
                  >
                    Preview portal
                    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr095.svg-->
                    <span class="svg-icon svg-icon-primary svg-icon-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                          fill="currentColor"
                        />
                        <rect
                          x="21.9497"
                          y="3.46448"
                          width="13"
                          height="2"
                          rx="1"
                          transform="rotate(135 21.9497 3.46448)"
                          fill="currentColor"
                        />
                        <path
                          d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </a>
                </div>

                <div class="mt-5">
                  <div
                    v-if="$store.state.currentProjectDetails.portalactive"
                    class="animate__animated animate__fadeIn alert bg-light-success border border-success border-3 border-dashed d-flex flex-column w-100 p-5 mb-5"
                  >
                    <button
                      class="btn btn-sm btn-success w-125px mb-5"
                      @click="copyLink()"
                    >
                      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/coding/cod007.svg-->
                      <span class="svg-icon svg-icon-muted svg-icon-2"
                        ><svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z"
                            fill="currentColor"
                          />
                          <path
                            d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                      Copy link
                    </button>

                    <!--begin::Content-->
                    <div class="d-flex flex-column pe-0">
                      <h5 class="mb-1">Send this link to your client</h5>
                      <span
                        >You can send it to all the authorised users added in
                        step 2.</span
                      >
                      <span
                        >By following the link, they will be able to access the
                        client portal for this project.</span
                      >
                    </div>
                    <!--end::Content-->
                  </div>
                </div>
              </div>

              <div class="d-flex mt-5">
                <div class="mx-auto">
                  <button class="btn btn-sm btn-light" @click="goToStep2()">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create project-->
</template>

<script>
import { batchApplyClientVisibilityInPortalExternal } from "../../js/projectdetails";

import {
  saveNewClientUserToPortalExternal,
  deleteClientUserFromPortalExternal,
  togglePortalSharingExternal,
} from "../../js/projectdetails";

import { switchClientVisibilityFromPortalModalExternal } from "../../js/profiledetails";

export default {
  data() {
    return {
      whichprofiles: "dummyvalue",
      selectedstatuslist: [],
      addClientUserActiveState: false,
      clientlastname: "",
      clientfirstname: "",
      clientemail: "",
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(
        this.$store.state.baseUrl +
          "clientauth?teamid=" +
          this.$store.state.userData.teamid +
          "&projectid=" +
          this.$store.state.currentProjectDetails.id
      );
    },
    getStatusName(statusid) {
      const statusList = this.$store.state.profileStatusList;
      const matchingStatus = statusList.find(
        (status) => status.id === statusid
      );
      if (matchingStatus) {
        const matchingStatusName = matchingStatus.status;
        return matchingStatusName;
      } else {
        return "No status";
      }
    },
    getStatusCategory(statusid) {
      const statusList = this.$store.state.profileStatusList;
      const matchingStatus = statusList.find(
        (status) => status.id === statusid
      );
      if (matchingStatus) {
        const matchingStatusCategory = matchingStatus.category;
        return matchingStatusCategory;
      } else {
        return "No status";
      }
    },
    resetSelectedStatusList() {
      this.selectedstatuslist = [];
    },
    batchApplyClientVisibility() {
      //go to next step
      //document.getElementById("tab2Link").click();

      setTimeout(() => {
        //apply selection
        if (this.whichprofiles == "all") {
          batchApplyClientVisibilityInPortalExternal("all");
        } else if (this.whichprofiles == "status") {
          batchApplyClientVisibilityInPortalExternal(
            "status",
            this.selectedstatuslist
          );
          $("#selectstatusforportal").dropdown("toggle");
        } else if (this.whichprofiles == "none") {
          batchApplyClientVisibilityInPortalExternal("none");
        }
      }, 200);
    },
    addStatusToPortalList(statusid) {
      if (this.selectedstatuslist.includes(statusid)) {
        let index = this.selectedstatuslist.indexOf(statusid);
        this.selectedstatuslist.splice(index, 1);
      } else {
        this.selectedstatuslist.push(statusid);
      }
    },
    addClientUser() {
      this.clientlastname = "";
      this.clientfirstname = "";
      this.clientemail = "";
      this.addClientUserActiveState = !this.addClientUserActiveState;
    },
    selectNewClientUser(user) {
      this.addClientUserActiveState = !this.addClientUserActiveState;
      saveNewClientUserToPortalExternal(
        user.firstname,
        user.lastname,
        user.email
      );
      $("#selectExistingClientUser").click();
    },
    saveNewClientUser(clientfirstname, clientlastname, clientemail) {
      if (!this.clientlastname || !this.clientfirstname || !this.clientemail) {
        alert("Please fill out the required fields.");
        return;
      }
      this.addClientUserActiveState = !this.addClientUserActiveState;
      saveNewClientUserToPortalExternal(
        clientfirstname,
        clientlastname,
        clientemail
      );
    },
    deleteUser(user) {
      deleteClientUserFromPortalExternal(user);
    },
    goToStep1() {
      document.getElementById("tab1Link").click();
    },
    goToStep2() {
      document.getElementById("tab2Link").click();
    },
    goToStep3() {
      document.getElementById("tab3Link").click();
    },
    goToStep4() {
      document.getElementById("tab4Link").click();
    },
    toggleSharing() {

    


      //Make sure there is at least one profile shared
      const anyProfilesShared = this.$store.state.currentProfileList.some(
        (profile) => profile.clientvisible === true
      );

      if (!anyProfilesShared) {
        //No profile is shared in this project
        alert(
          "You have not selected any profiles to share. Return to step 1."
        );
        setTimeout(() => {
          this.$store.state.currentProjectDetails.portalactive = false;
        }, 100);
        return;
      }

      //Make sure there is at least one client user added
      if (
        !this.$store.state.currentProjectDetails
          .authorisedclientusersforportal ||
        this.$store.state.currentProjectDetails.authorisedclientusersforportal
          .length == 0
      ) {
        alert("You need to add at least one client user in step 2.");
        setTimeout(() => {
          this.$store.state.currentProjectDetails.portalactive = false;
        }, 100);
        return;
      }

      
      togglePortalSharingExternal();
    },
    updateClientAccessForProfile(profile) {
      switchClientVisibilityFromPortalModalExternal(profile);
    },
  },
  computed: {
    computedStatusList() {
      const statuslist = this.$store.state.profileStatusList.sort(
        (a, b) => a.order - b.order
      );
      return statuslist;
    },
    filteredUserList() {
      const authorizedClientUsers =
        this.$store.state.currentProjectDetails.authorisedclientusersforportal;
      const filteredAuthorizedClientUsers = authorizedClientUsers.filter(
        (user) => user.hasOwnProperty("firstname")
      );
      return filteredAuthorizedClientUsers;
    },
    filteredUserListFromForThisClient() {
      const currentClientId =
        this.$store.state.currentProjectDetails.clientobject.id;
      const currentClient = this.$store.state.currentClientList.find(
        (client) => client.id === currentClientId
      );
      if (currentClient.authorisedclientusersforportal) {
        return currentClient.authorisedclientusersforportal;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
</style>