<template>



  <MobileUI v-if="$store.state.userData"></MobileUI>

</template>

<script>

import MobileUI from '../components/MobileUI.vue';

export default {

  components: {
    MobileUI
  }

}
</script>

<style>

</style>


