import store from "@/store";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail } from "firebase/auth";
import { collection, addDoc, query, updateDoc, doc, setDoc, where, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/index";
import router from "../router/index";
import { getProjects, getRecentProfilesExternal, getClients, getSingleProfile, getSingleProject } from "./getdata"
import route from "../router/index";



//used in views/Signin-View.vue component
function signInFunc(email, password) {




  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in

      //reset error message if any, for next potential login
      store.state.authError = false;

      const user = userCredential.user;

       //small screen size can be either mobile or chrome extension
       function isSmallScreenSize() {
        const maxWidth = 768; // You can set this to any value you consider as the threshold
      
  
        // Check if the screen width is less than or equal to the threshold
        if (window.innerWidth <= maxWidth) {
          return true;
        }
      
        // If the screen width is greater than the threshold, assume desktop
        return false;
      }

      //check if on mobile by user agent. not foolproof but works in most cases.
      function isMobileDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      
        // Check for common mobile device keywords
        if (/windows phone/i.test(userAgent)) {
          return true;
        }
        if (/android/i.test(userAgent)) {
          return true;
        }
        if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
          return true;
        }
      
        // If no mobile device keywords found, assume desktop
        return false;
      }
      
      
      if (isSmallScreenSize() && !isMobileDevice()) {
        //user is on chrome extension
        router.push('/extension')
      } else if (isSmallScreenSize() && isMobileDevice()) {
        //user is on mobile device
        router.push('/mobile')
      } else if (!isSmallScreenSize()) {
        //user is on desktop
                //Take user to Profile (home) route, unless they are signing in to view the Billing page

                let currentRoute = router.currentRoute.value;

                // Get path of current route
                let path = currentRoute.path;

                if(path == '/billing') {
                  return
                }

      router.push('/profiles')

      //Switch from ProfileDetails.vue to ProfileList.vue in case user was on a specific candidate and logs in to another account.
      //Some details of the profile can still be in cache. To avoid this, we force the user to ProjectList.
      //store.state.showProfileList = true
      //store.state.showProfileDetails = false
      store.state.showingProfilesAs = 'recentProfiles'


      //Fire the getRecentProfiles function, in case the user
      // signed out from an account and then signs in to another account, 
      //so that no inacessible profiles are requested by the listener. 
      //This would make the page bug.
      getRecentProfilesExternal()
      }
  



      

      


    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      store.state.authError = error.code
      console.log(error.code + " / " + error.message)
    });


}

//used in views/Signup-View.vue component
const signUpFunc = (neworexistingcompany, trialorfree, teamId, email, password) => {

  console.log(email + ' ' + password)

  let subscriptionType;

  if (trialorfree == "trial") {
    //user but who have to pay after the 2 weeks trial period
    subscriptionType = "trial"
  }
  else if (trialorfree == "free") {
    //free user: beta tester, friends, etc.
    subscriptionType = "free"
  }

  //if user is signing up with a New company
  if (neworexistingcompany == "newCompany") {
    console.log("helloooo")
    const auth = getAuth();

    createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
      .then((userCredential) => {
        // Signed in



        const user = userCredential.user;

        console.log("this is the teamname: " + store.state.signup_TeamName)

        //create team / company
        const teamsCollection = collection(db, "teams")
        addDoc(teamsCollection, {
          teamname: store.state.signup_TeamName,
          teamlogourl: store.state.signup_TeamLogoURL,
          teamcity: store.state.signup_TeamCity,
          teamcountry: store.state.signup_TeamCountry,
        })
          .then((teamDocRef) => {
            console.log("created team doc")
            //create user profile
            const userRef = doc(db, "users", user.uid)
            setDoc(userRef, {
              firstname: store.state.signup_ProfileFirstName,
              lastname: store.state.signup_ProfileLastName,
              email: store.state.signup_ProfileEmail,
              mobilenumber: store.state.signup_ProfileMobileNumber,
              firsttimeloggedin: true,
              teamid: teamDocRef.id,
              userid: user.uid,
              avatarurl: store.state.signup_ProfilePicURL,
              //either free (full account access for free, for friends or beta testers or devs etc.), paid (full access for a user who is paying, this is not updated here but in Billing.vue after the trial period), trial (user on free trial for 2 weeks, will be redirected to Billing.vue after 2 weeks)
              subscriptiontype: subscriptionType,
              signuptimestamp: Date.now(),
              userrole: "Administrator",
              emailsignature: "<p>&nbsp;</p> <p>&nbsp;</p> <p>-</p> <div>" + store.state.signup_ProfileFirstName + " " + store.state.signup_ProfileLastName + "</div> <div>" + store.state.signup_TeamName + "</div> <div>&nbsp;</div> <div>" + store.state.signup_ProfileEmail + "</div> <div>&nbsp;</div> <p><img src='" + store.state.signup_CompanyLogoURL + "' width='150' /></p>",
              proptions: {
                fields: {
                  notes: {
                    order: 5,
                    included: true
                  },
                  company: {
                    order: 2,
                    included: true
                  },
                  contactdetails: {
                    order: 6,
                    included: true
                  },
                  experience: {
                    order: 9,
                    included: true
                  },
                  linkedinurl: {
                    order: 7,
                    included: true
                  },
                  location: {
                    order: 8,
                    included: true
                  },
                  status: {
                    order: 4,
                    included: true
                  },
                  title: {
                    order: 3,
                    included: true
                  },
                  name:
                  {
                    order: 1,
                    included: true
                  }
                },
                namedisplay: "fullname",
                fileformat: "excel"
              },
            })
              .then(() => {
                //Take user to Profile (home) route
             
                  router.push('/profiles')
                  setTimeout(() => {
                    //need to reload otherwise there's a firestore rules problem as something isn't available yet
                    location.reload();                    
                  }, 300);
                  
  


              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage)
                // ..
              });
          })




        // ...
      })
      .then(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {



        } else {
          // No user is signed in.
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage)
        // ..
      });
  }

  //if user is signing up with an Existing company
  if (neworexistingcompany == "existingCompany") {
    const auth = getAuth();
    createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
      .then((userCredential) => {
        // Signed in


        const user = userCredential.user;


        //no need to create team here as it already exists

        console.log("team name is as follows: " + store.state.signup_TeamName)
    

        //create user profile
        const userRef = doc(db, "users", user.uid)
        setDoc(userRef, {
          firstname: store.state.signup_ProfileFirstName,
          lastname: store.state.signup_ProfileLastName,
          email: store.state.signup_ProfileEmail,
          mobilenumber: store.state.signup_ProfileMobileNumber,
          firsttimeloggedin: true,
          teamid: teamId,
          userid: user.uid,
          avatarurl: store.state.signup_ProfilePicURL,
          //either free (full account access for free, for friends or beta testers or devs etc.), paid (full access for a user who is paying, this is not updated here but in Billing.vue after the trial period), trial (user on free trial for 2 weeks, will be redirected to Billing.vue after 2 weeks)
          subscriptiontype: subscriptionType,
          signuptimestamp: Date.now(),
          emailsignature: "<p>&nbsp;</p> <p>&nbsp;</p> <p>-</p> <div>" + store.state.signup_ProfileFirstName + " " + store.state.signup_ProfileLastName + "</div> <div>" + store.state.signup_TeamName + "</div> <div>&nbsp;</div> <div>" + store.state.signup_ProfileEmail + "</div> <div>&nbsp;</div> <p><img src='" + store.state.signup_CompanyLogoURL + "' width='150' /></p>",
          proptions: {
            fields: {
              notes: {
                order: 5,
                included: false
              },
              company: {
                order: 2,
                included: false
              },
              contactdetails: {
                order: 8,
                included: false
              },
              experience: {
                order: 4,
                included: false
              },
              linkedinurl: {
                order: 9,
                included: false
              },
              location: {
                order: 7,
                included: false
              },
              status: {
                order: 6,
                included: false
              },
              title: {
                order: 3,
                included: false
              },
              name:
              {
                order: 1,
                included: true
              }
            },
            namedisplay: "fullname",
            fileformat: "excel"
          },
        })
          .then(() => {
            router.push('/profiles')
            setTimeout(() => {
              //need to reload otherwise there's a firestore rules problem as something isn't available yet
              location.reload();                    
            }, 300);
          })




      })
      .then(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {



        } else {
          // No user is signed in.
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }





}


const clientSignUpFunc = (email, password, teamid, projectid) => {


  //if user is signing up with a New company

  const auth = getAuth();

  createUserWithEmailAndPassword(
    auth,
    email,
    password
  )
    .then((userCredential) => {
      // Signed in

      const user = userCredential.user;


      //create user profile
      const userRef = doc(db, "users", user.uid)
      setDoc(userRef, {
        email: email,
        firsttimeloggedin: true,
        teamid: teamid,
        userid: user.uid,
        //either free (full account access for free, for friends or beta testers or devs etc.), paid (full access for a user who is paying, this is not updated here but in Billing.vue after the trial period), trial (user on free trial for 2 weeks, will be redirected to Billing.vue after 2 weeks), or client (for the client users' access to the client portal)
        subscriptiontype: "client",
        signuptimestamp: Date.now(),

      })
        .then(() => {
          //Take user to Profile (home) route
          router.push('/portal?projectid=' + projectid)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage)
          // ..
        });





      // ...
    })
    .then(() => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {



      } else {
        // No user is signed in.
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage)
      // ..
    });








}

const clientSigninFunc = (email, password, projectid) => {



  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;


      //Take user to Profile (home) route
      router.push('/portal?projectid=' + projectid)




    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });


}

//used in components/MainHeader.vue component
const signOutExternal = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {

      //return to signin page (for normal users)
      if (store.state.userData.subscriptiontype != 'client') {
        //empty user data in vuex store
        store.state.userData = {}
        //take user to sign-in route
        router.push('/signin')

      }

      //client users: return to specific client sign-in page (from portal)
      else {

        //take user to sign-in route

        store.state.clientauthstatus = 'signin' //this tells the SigninSignupClient-View.vue component to load the signin component instead of signup
        router.push('/clientauth?teamid=' + store.state.userData.teamid + '&projectid=' + store.state.currentProjectDetails.id)
      
                //empty user data in vuex store
                store.state.userData = {}
      }




    })
    .catch((error) => {
      console.log(error.message)
    });
}


const addBillingDetailsExternal = () => {
  alert("billing details added")

  store.state.signup_BillingCompanyName
  store.state.signup_BillingAddress
  store.state.signup_BillingCity
  store.state.signup_BillingPostalCode
  store.state.signup_BillingCountry
  store.state.signup_BillingVATNumber


  const userDocRef = doc(db, "users", store.state.userData.id);

  //update the firestore doc
  updateDoc(userDocRef, {
    billingcompanyname: store.state.signup_BillingCompanyName,
    billingaddress: store.state.signup_BillingAddress,
    billingcity: store.state.signup_BillingCity,
    billingpostalcode: store.state.signup_BillingPostalCode,
    billingcountry: store.state.signup_BillingCountry,
    billingvatnumber: store.state.signup_BillingVATNumber
  })
    .then(() => {
      router.push('/profiles')
    })

}

//used in components/App.vue component

const listenToAuthStateAndChanges = () => {


  //this is everything that happends when a logged-in user is detected, 
  //like when a user signs-up, signs-in, reloads the page, etc. 
  //It loads the user data, but also all the initial data (profiles, projects, clients...)
  //It also listens to any change in the Firestore collections and sends the modified data to Vuex

  //////April 2023: deprecated. It is still used in many files like profiledetails.js, but it has 
  //already created many bugs. I have replaced it with store.state.userData.userid in several places already

  const auth = getAuth();
  //STEP 1, listen to auth changes
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;

      store.state.currentUserId = uid




      //load the remaining data that should be displayed initially. 
      //this comes from getData.js
      //getProjects()
      //getClients()











    } else {
      //user not signed in
    }
  });
}

const newUserHasLoggedInExternal = () => {
  const userDocRef = doc(db, "users", store.state.userData.id);

  //update the firestore doc
  updateDoc(userDocRef, {
    firsttimeloggedin: false
  })

}

const sendResetLinkExternal = (email) => {
  const auth = getAuth();
sendPasswordResetEmail(auth, email)
  .then(() => {
    store.state.resetemailsent = true;
    setTimeout(() => {
      store.state.resetemailsent = false;
    }, 10000);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode)

    if(errorCode == "auth/user-not-found") {
      store.state.usernotfound = true;
    }

    // ..
  });
}


export {  signInFunc, 
          signUpFunc,
          clientSigninFunc, 
          clientSignUpFunc, 
          signOutExternal, 
          listenToAuthStateAndChanges, 
          addBillingDetailsExternal, 
          newUserHasLoggedInExternal,
          sendResetLinkExternal 
        }



/*


import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../firebase/index";
import router from "../router/index";



    signup() {
      const username = this.name;
      const picsrc = this.picurl;

      const auth = getAuth();
      createUserWithEmailAndPassword(
        auth,
        this.signupemail,
        this.signuppassword
      )
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          //create user document in user collection of Firestore
          async function createUserDoc() {
            const docRef = await addDoc(collection(db, "users"), {
              name: username,
              picurl: picsrc,
              userid: user.uid,
            });
            console.log("Document written with ID: ", docRef.id);
          }
          createUserDoc();

          // ...
        })
        .then(() => {
          const auth = getAuth();
          const user = auth.currentUser;

          if (user) {
            alert("user detected");
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // ...

            //store user id in Vuex store
            this.$store.state.userId = user.uid;
            alert("userId stored");

            console.log("before router");
            router.replace({ path: "/" });
            console.log("after router");
          } else {
            // No user is signed in.
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    },
    signin() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;

          //retrieve user doc from firestore based on the id above
          const q = query(collection(db, "users"), where("userid", "==", user.uid));

          async function getUserDoc() {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());

              //store user doc info in Vuex store
              store.state.userInfo = doc.data();
            });
          }

          getUserDoc();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },


import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore/lite";
import store from "../store/index";
import { db } from "../firebase/index";

       const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;

        //store user id in Vuex store
        this.$store.state.userId = uid;
        console.log("this is the user id:" + this.$store.state.userId);

        //retrieve user doc from firestore based on the id above
        const q = query(collection(db, "users"), where("userid", "==", uid));

        async function getUserDoc() {
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());

            //store user doc info in Vuex store
            store.state.userInfo = doc.data();
          });
        }

        getUserDoc();

        // ...
      } else {
        // User is signed out
        // ...
      }
    });




        signout() {

      const auth = getAuth();
      signOut(auth)
        .then(() => {
          alert('signout successful')

          //empty user data in vuex store
          store.state.userInfo = []

        })
        .catch((error) => {
          // An error happened.
        });
    },

*/