<template>
  <div >
    <transition :name="$store.state.animationname" mode="out-in">
      <!--begin:: main view-->
      <div v-if="$store.state.showMain == true" key="A">
        <!--begin: header-->
        <div class="p-3 ps-6 pt-8 d-flex">
          <img
            alt="Logo"
            src="../assets/media/logos/kiwano.png"
            class="h-40px"
          />
        </div>
        <!--end: header-->

        <!--begin::Search & User bar-->
        <div class="d-flex">
          <!--begin::Search Input Field-->
          <div class="position-relative ms-6 me-6">
            <!--begin::Input-->
            <input
              type="text"
              class="form-control form-control-solid border border-light fs-2"
              style="background-color: #11ffee00; color: white; height: 50px"
              placeholder="Quick search"
              name="searchInput"
              id="searchInput"
              autocomplete="off"
              v-model="$store.state.searchInputText"
              @keydown="showSearchDropdown()"
            />

            <!--end::Input-->

            <!--begin::search icon-->
            <div class="position-absolute translate-middle-y top-50 end-0 me-3">
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr088.svg-->
              <span
                @click="resetSearchBar()"
                v-if="$store.state.searchState == 'on'"
                class="custompointer svg-icon svg-icon-white svg-icon-2hx"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="7.05025"
                    y="15.5356"
                    width="12"
                    height="2"
                    rx="1"
                    transform="rotate(-45 7.05025 15.5356)"
                    fill="currentColor"
                  />
                  <rect
                    x="8.46447"
                    y="7.05029"
                    width="12"
                    height="2"
                    rx="1"
                    transform="rotate(45 8.46447 7.05029)"
                    fill="currentColor"
                  /></svg
              ></span>
              <!--end::Svg Icon-->
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen004.svg-->
              <span class="svg-icon svg-icon-muted svg-icon-1"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
            <!--end::search icon-->
          </div>
          <!--end::Search Input Field-->

          <!--begin::User menu-->
          <div
            class="d-flex align-items-center ms-1 me-10 ms-auto"
            id="kt_header_user_menu_toggle"
            v-if="$store.state.userData.firstname"
          >
            <!--begin::Menu wrapper-->
            <div
              v-if="$store.state.userData.avatarurl"
              class="cursor-pointer symbol symbol-45px symbol-md-40px"
              id="openUserMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img :src="$store.state.userData.avatarurl" alt="user" />
            </div>
            <div
              v-else
              class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px"
              id="openUserMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/communication/com013.svg-->
              <span class="svg-icon svg-icon-muted svg-icon-2hx"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                    fill="currentColor"
                  />
                  <rect
                    opacity="0.3"
                    x="8"
                    y="3"
                    width="8"
                    height="8"
                    rx="4"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>

            <!--begin::User account menu-->
            <div
              class="dropdown-menu dropdownFadeIn menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary menu-sub menu-sub-dropdown fw-bold py-4 fs-6 w-275px"
              aria-labelledby="openUserMenuButton"
              role="menu"
            >
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                  <!--begin::Avatar-->
                  <div
                    class="symbol symbol-50px me-5"
                    v-if="$store.state.userData.avatarurl"
                  >
                    <img alt="Logo" :src="$store.state.userData.avatarurl" />
                  </div>
                  <!--end::Avatar-->
                  <!--begin::Username-->
                  <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                      {{ $store.state.userData.firstname }}
                    </div>
                    <a
                      href="#"
                      class="fw-bold text-muted text-hover-primary fs-7"
                      >{{ $store.state.userData.email }}</a
                    >
                  </div>
                  <!--end::Username-->
                </div>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu separator-->
              <div class="separator my-2"></div>
              <!--end::Menu separator-->

              <!--begin::Menu item-->
              <div class="menu-item">
                <div class="menu-content ps-5">
                  <a class="menu-link bg-secondary ps-5" @click="signOut()"
                    >Signout</a
                  >
                </div>
              </div>
              <!--end::Menu item-->
            </div>
            <!--end::User account menu-->
            <!--end::Menu wrapper-->
          </div>
          <!--end::User menu-->
        </div>
        <!--end::Search & User bar-->

        <!--begin::Search result Card-->
        <div
          v-if="$store.state.searchState == 'on'"
          key="search"
          class="card mb-xl-8 ms-5 me-5 mt-5"
        >
          <SmallCard
            whichCard="searchfrommobileversion"
            title="Search results"
            subTitle="search"
            v-if="$store.state.userData.algoliasearchkey"
          ></SmallCard>
        </div>
        <!--end::Search result Card-->

        <!--begin: Divider-->
        <div style="margin-top: -13px" class="d-none mx-7">
          <hr style="border-top: 2px solid WhiteSmoke !important" />
        </div>
        <!--end: Divider-->

        <!--begin: recent profiles-->
        <div
          class="p-3 ps-7 pt-8 d-flex mb-10 mt-20 d-flex flex-column"
          v-if="$store.state.searchState == 'off'"
        >
          <span
            class="h1 text-light fw-normal"
            >Recent profiles</span
          >
          <span class="mb-9 text-muted h5">Viewed in desktop</span>

          <div>
            <div
              v-for="profile in latestprofiles"
              :key="profile.id"
              class="custompointer mb-6 me-5 bg-transparent d-flex align-items-center p-7 border border-0 shadow-lg border-light rounded"
              @click="navigate(profile)"
            >
              <div class="d-flex flex-column symbol symbol-55px me-5">
                <img
                  alt="Pic"
                  v-if="profile.profilepic"
                  :src="profile.profilepic"
                  onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                />
                <img
                  alt="Pic"
                  v-if="!profile.profilepic"
                  src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a class="text-light h1">{{
                  profile.firstname + " " + profile.lastname.toUpperCase()
                }}</a>
                <span
                  v-if="profile.currentcompany"
                  class="text-muted h1 w-170px"
                  >{{
                    profile.currentcompany.length < 20
                      ? profile.currentcompany
                      : profile.currentcompany.substring(0, 20) + "..."
                  }}</span
                >
              </div>
            </div>
          </div>

          <div></div>
        </div>
        <!--end: recent profiles-->
      </div>
      <!--end:: main view-->

      <!--begin::detailed profile view-->
      <div v-else key="B">
        <div class="pt-5 ps-5 mb-5" style="position: sticky; top:0; z-index: 3; background-color: #435A83;">
          <button
            class="btn btn-lg btn-transparent btn-icon mb-3"
            @click="navigate('back')"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr063.svg-->
            <span class="svg-icon svg-icon-light svg-icon-2hx"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="11"
                  width="13"
                  height="2"
                  rx="1"
                  fill="currentColor"
                />
                <path
                  d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
        </div>

        <div
          class="card card-docs flex-row-fluid mb-2 mx-5"
          id="kt_docs_content_card"
        >
          <div class="d-flex ms-10 mt-10">
            <div class="d-flex flex-column symbol symbol-70px me-5">
              <img
                alt="Pic"
                v-if="$store.state.currentProfileDetails.profilepic"
                :src="$store.state.currentProfileDetails.profilepic"
                onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
              />
              <img
                alt="Pic"
                v-if="!$store.state.currentProfileDetails.profilepic"
                src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
              />
            </div>
            <div class="d-flex flex-column">
              <h1 class="h1">
                {{ $store.state.currentProfileDetails.firstname }}
                {{ $store.state.currentProfileDetails.lastname }}
              </h1>
              <h1 class="h3 text-muted">
                {{ $store.state.currentProfileDetails.currentjobtitle }}
              </h1>
              <h1 class="h3 text-muted">
                {{ $store.state.currentProfileDetails.currentcompany }}
              </h1>
              <div class="d-flex mt-2">
                <a
                  :href="
                    'tel:' + $store.state.currentProfileDetails.mobilenumber
                  "
                  class="btn btn-icon btn-primary me-2"
                  :class="{
                    disabled: !$store.state.currentProfileDetails.mobilenumber,
                  }"
                  ><i class="bi bi-telephone-fill fs-2"></i
                ></a>
                <a
                  :href="'mailto:' + $store.state.currentProfileDetails.email"
                  class="btn btn-icon btn-primary"
                  :class="{
                    disabled: !$store.state.currentProfileDetails.email,
                  }"
                  ><i class="bi bi-envelope-fill fs-2"></i
                ></a>
                <a
                  class="btn btn-icon btn-primary ms-2"
                  :class="{
                    disabled: !$store.state.currentProfileDetails.linkedinurl,
                  }"
                  :href="$store.state.currentProfileDetails.linkedinurl"
                  target="_blank"
                  ><i class="fab fa-linkedin-in fs-1" aria-hidden="true"></i
                ></a>
              </div>
            </div>
          </div>

          <!--begin::Card Body-->
          <div
            class="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700"
          >
            <!--begin::Section-->
            <div class="pb-10">
              <!--begin::Accordion-->
              <div class="accordion accordion-icon-toggle" id="kt_accordion_2">
                <!--begin::Item-->
                <div class="mb-5">
                  <!--begin::Header-->
                  <div
                    class="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_2_item_1"
                  >
                    <span class="accordion-icon"
                      ><span class="svg-icon svg-icon-1"
                        ><svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="5"
                            fill="currentColor"
                          />
                          <path
                            d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                            fill="currentColor"
                          /></svg></span
                    ></span>
                    <h1 class="ms-4 mt-1">Experience</h1>
                  </div>
                  <!--end::Header-->

                  <!--begin::Body-->
                  <div
                    id="kt_accordion_2_item_1"
                    class="fs-2 collapse ps-5 mb-10"
                    data-bs-parent="#kt_accordion_2"
                  >
                    <div class="mt-10 fs-3">
                      <ProfileExperienceRich></ProfileExperienceRich>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Item-->

                <!--begin::Item-->
                <div class="mb-5">
                  <!--begin::Header-->
                  <div
                    class="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_2_item_2"
                  >
                    <span class="accordion-icon"
                      ><span class="svg-icon svg-icon-1"
                        ><svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="5"
                            fill="currentColor"
                          />
                          <path
                            d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                            fill="currentColor"
                          /></svg></span
                    ></span>
                    <h1 class="ms-4 mt-1">Info</h1>
                  </div>
                  <!--end::Header-->

                  <!--begin::Body-->
                  <div
                    id="kt_accordion_2_item_2"
                    class="collapse fs-6 ps-5"
                    data-bs-parent="#kt_accordion_2"
                  >
                    <div class="fs-3 mt-4 mb-4">
                      <div class="mb-3 d-flex">
                        <i class="bi bi-geo-alt-fill mt-1 me-5 fs-3"></i>
                        <span
                          v-if="$store.state.currentProfileDetails.location"
                        >
                          {{ $store.state.currentProfileDetails.location }}
                        </span>
                        <span class="text-muted" v-else>
                          (No location added)
                        </span>
                      </div>
                      <div class="mb-3 d-flex">
                        <i class="bi bi-telephone-fill me-4 mt-1 fs-3"></i>
                        <span
                          v-if="$store.state.currentProfileDetails.mobilenumber"
                        >
                          {{ $store.state.currentProfileDetails.mobilenumber }}
                        </span>
                        <span class="text-muted" v-else>
                          (No number added)
                        </span>
                      </div>
                      <div class="mb-3 d-flex">
                        <i class="bi bi-envelope-fill mt-1 me-4 fs-3"></i>
                        <span v-if="$store.state.currentProfileDetails.email">
                          {{ $store.state.currentProfileDetails.email }}
                        </span>
                        <span class="text-muted" v-else>
                          (No email added)
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Item-->

                <!--begin::Item-->
                <div class="mb-5">
                  <!--begin::Header-->
                  <div
                    class="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_2_item_3"
                  >
                    <span class="accordion-icon"
                      ><span class="svg-icon svg-icon-1"
                        ><svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="5"
                            fill="currentColor"
                          />
                          <path
                            d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                            fill="currentColor"
                          /></svg></span
                    ></span>
                    <h1 class="ms-4 mt-1">Latest project</h1>
                  </div>
                  <!--end::Header-->

                  <!--begin::Body-->
                  <div
                    id="kt_accordion_2_item_3"
                    class="collapse fs-6 ps-10"
                    data-bs-parent="#kt_accordion_2"
                  >
                    <div class="mt-7 mb-4">
                      <ProfileProjectActivity></ProfileProjectActivity>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Item-->

                <!--begin::Item-->
                <div class="mb-5">
                  <!--begin::Header-->
                  <div
                    class="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_2_item_4"
                  >
                    <span class="accordion-icon"
                      ><span class="svg-icon svg-icon-1"
                        ><svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="5"
                            fill="currentColor"
                          />
                          <path
                            d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                            fill="currentColor"
                          /></svg></span
                    ></span>
                    <h1 class="ms-4 mt-1">Comments</h1>
                  </div>
                  <!--end::Header-->

                  <!--begin::Body-->
                  <div
                    id="kt_accordion_2_item_4"
                    class="collapse fs-6 ps-10"
                    data-bs-parent="#kt_accordion_2"
                  >
                    <div class="d-flex me-5">
                      <button
                        class="btn btn-sm btn-primary ms-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#addProfileComment"
                        @click="initializeAddCommentModal()"
                      >
                        <span class="svg-icon svg-icon-muted svg-icon-2"
                          ><svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x="11.364"
                              y="20.364"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(-90 11.364 20.364)"
                              fill="currentColor"
                            ></rect>
                            <rect
                              x="4.36396"
                              y="11.364"
                              width="16"
                              height="2"
                              rx="1"
                              fill="currentColor"
                            ></rect></svg
                        ></span>
                        Add
                      </button>
                    </div>

                    <div class="mt-10 d-flex">
                      <ProfileComments></ProfileComments>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Accordion-->
            </div>
            <!--end::Section-->

            <!--end::Card Body-->
          </div>
        </div>
      </div>
      <!--end::detailed profile view-->
    </transition>
  </div>
</template>

<script>
import Search from "../components/Modals/ModalContents/Search.vue";
import SmallCard from "../components/Cards/SmallCard.vue";
import ProfileExperienceRich from "../components/Cards/ProfileCardContents/ProfileExperienceRich.vue";
import ProfileProjectActivity from "../components/Cards/ProfileCardContents/ProfileProjectActivity.vue";
import ProfileComments from "../components/Cards/ProfileCardContents/ProfileComments.vue";

import {
  getLatestViewedProfilesExternal,
  getSingleProfileExternal,
} from "../js/getdata";

import { signOutExternal } from "../js/auth.js";

export default {
  components: {
    Search,
    SmallCard,
    ProfileExperienceRich,
    ProfileProjectActivity,
    ProfileComments,
  },
  computed: {
    latestprofiles() {
      // Original array
      const originalArray = this.$store.state.latestprofilesvieweddocs;


      // Create a shallow copy of the array and reverse it
      const reversedArray = originalArray.slice().reverse();

      const uniqueProfiles = reversedArray.filter((profile, index, self) => {
        return index === self.findIndex((p) => p.id === profile.id);
      });

      return reversedArray;
    },
  },
  methods: {
    resetSearchBar() {
      this.$store.state.searchInputText = "";
      this.$store.state.searchState = "off";
    },
    showSearchDropdown() {
      this.$store.state.searchState = "on";
    },
    navigate(profile) {
      this.$store.state.showMain = !this.$store.state.showMain;
      if (profile == "back") {
        this.$store.state.animationname = "slideright";
        this.$store.state.currentProfileDetails = false;
      } else {
        this.$store.state.animationname = "slideleft";
        //this.$store.state.currentProfileDetails = profile;

        getSingleProfileExternal(profile.id);
      }
    },
    initializeAddCommentModal() {
      //this empties the textarea everytime the modal is opened
      this.$store.state.profilecommenttext = "";

      if (
        this.$store.state.currentProfileProjectBindings.length == 0 ||
        this.$store.state.currentProfileDetails.candidateorclient == "Client"
      ) {
        //if this profile does not have projects attached, do nothing
      } else {
        //this ensures that the Attach project checkbox is checked each time the modal opens
        if (this.$store.state.attachprofilecommenttoproject) {
        } else {
          document.getElementById("attachcommentroproject").click();
        }

        //this sets the Show to client Checkbox to unchecked by default
        this.$store.state.showCommentToClient = false;

        //this stores the project details of the currentLatestProfileBinding, displayd in the modal as the default project to attach
        //If the user selects another project to attach, then these values change.

        this.$store.state.selectedProjectForCommentAttachment = {
          clientname:
            this.$store.state.currentProfileProjectBindings[0].clientname,
          clientlogo:
            this.$store.state.currentProfileProjectBindings[0].clientlogo,
          positiontitle:
            this.$store.state.currentProfileProjectBindings[0].positiontitle,
          projectid:
            this.$store.state.currentProfileProjectBindings[0].projectid,
        };
      }
    },
    signOut() {
      //auth.js
      signOutExternal();
    }
  },
};
</script>

<style>
</style>


