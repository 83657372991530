<template>
  

  <ChromeExtension v-if="$store.state.userData.email"></ChromeExtension>
  <SigninFromExtension v-if="!$store.state.userData.email && showsignin"></SigninFromExtension>

  <div class="text-white ms-5 mt-5" v-if="!$store.state.userData.email && !showsignin">
                          <div style="margin-left: 40%; margin-top: 50%;" id="preloader_1">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
  </div>



</template>

<script>

import ChromeExtension from '../components/ChromeExtension.vue'
import SigninFromExtension from '../components/SigninFromExtension.vue'


export default {

    data() {
      return {
        showsignin: false
      }
    },
    components: {
        ChromeExtension, SigninFromExtension
    },
    mounted() {
      setTimeout(() => {
        this.showsignin = true
      }, 4000);
    }


}
</script>

<style>

</style>