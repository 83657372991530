<template>
  <!--begin:Modal button - A test button can be created like this
  <a
    href="#"
    class="btn btn-sm btn-light btn-active-primary"
    data-bs-toggle="modal"
    data-bs-target="#modal1"
  >
    {{buttonText}}
  </a>
  end:Modal button-->

  <!--begin::Modal-->
  <div class="modal fade" :id="whichModal" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog"
              :class="{
            'mw-650px': whichModal != 'editclientportal' && whichModal != 'projectkpis',
            'mw-900px':
              whichModal == 'editclientportal' || whichModal == 'projectkpis',
          }"
    >
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            v-if="whichModal != 'welcomeUser'"
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body scroll-y mx-5 mx-xl-12 pt-0 pb-5"
          style="overflow-y: inherit !important"
        >
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <h1 class="mb-3" :class="{'mt-9': whichModal == 'welcomeUser'}" v-if="title">{{ title }}</h1>
            <!--end::Title-->
            <!--begin::Description-->
            <div class="text-muted fw-bold fs-5" v-if="subTitle">
              {{ subTitle }}
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!--begin::Content-->
          <div class="mb-10">
            <!--begin::Content Heading-->
            <div class="fs-6 fw-bold mb-2">
              {{ cardTitle }}
            </div>
            <!--end::Content Heading-->

            <!--begin::Backoffice Generate Signup Link:  WARNING: whichModal here is defined in Backoffice.vue (view), not App.vue as the other modal-->
            <WelcomeUser v-if="whichModal == 'welcomeUser'"></WelcomeUser>
            <!--end::Backoffice Generate Signup Link-->

            <!--begin::Content Body:  whichModal here is defined in Profiles-View.vue-->
            <EditProfileStatusList
              v-if="whichModal == 'editProfileStatusList'"
            ></EditProfileStatusList>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <Search v-if="whichModal == 'search'"></Search>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <EditProfileProjectBinding
              v-if="whichModal == 'editProfileProjectBinding'"
            ></EditProfileProjectBinding>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <AddProfileComment
              v-if="whichModal == 'addProfileComment'"
            ></AddProfileComment>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <EditProfileComment
              v-if="whichModal == 'editProfileComment'"
            ></EditProfileComment>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <AddProfileTask
              v-if="whichModal == 'addProfileTask'"
              origin="profiledetails"
            ></AddProfileTask>
            <!--end::Content Body-->

            
            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <EditAccount
              v-if="whichModal == 'editAccount'"
            ></EditAccount>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <EditTeamMembers
              v-if="whichModal == 'editTeammembers'"
            ></EditTeamMembers>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <EditProfileTags
              v-if="whichModal == 'editProfileTags'"
            ></EditProfileTags>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <Email v-if="whichModal == 'email'"></Email>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <EditEmailSignature
              v-if="whichModal == 'editSignature'"
            ></EditEmailSignature>
            <!--end::Content Body-->

            <!--begin::Content Body:  whichModal here is defined in App.vue-->
            <EmailTemplates
              v-if="whichModal == 'editEmailTemplates'"
              :key="$store.state.emailTemplatesComponentKey"
            ></EmailTemplates>
            <!--end::Content Body-->

            <!--begin::Backoffice User Details:  WARNING: whichModal here is defined in Backoffice.vue (view), not App.vue-->
            <BackOfficeUserDetails
              v-if="whichModal == 'backofficedetaileduser'"
            ></BackOfficeUserDetails>
            <!--end::Backoffice User Details-->

            <!--begin::Backoffice Generate Signup Link:  WARNING: whichModal here is defined in Backoffice.vue (view), not App.vue as the other modal-->
            <BackOfficeGenerateSignupLink
              v-if="whichModal == 'generatesignuplink'"
            ></BackOfficeGenerateSignupLink>
            <!--end::Backoffice Generate Signup Link-->

            <!--begin:RecommendedProfiles-->
            <RecommendedProfiles
              v-if="whichModal == 'recommendedProfiles'"
            ></RecommendedProfiles>
            <!--end:RecommendedProfile-->

            <!--begin:Edit Project and Client Info-->
            <EditProjectAndClientInfo
              v-if="whichModal == 'editprojectandclientinfo'"
            ></EditProjectAndClientInfo>
            <!--end:Edit Project and Client Info-->

            <!--begin:Edit Project and Client Info-->
            <AddProjectOrClientNotes
              v-if="whichModal == 'addprojectorclientnotes'"
            ></AddProjectOrClientNotes>
            <!--end:Edit Project and Client Info-->

            <!--begin:Add New Profile-->
            <AddNewProfile
              v-if="whichModal == 'addnewprofile'"
            ></AddNewProfile>
            <!--end:Add New Profile-->

            <!--begin:Add New Project-->
            <AddNewProject
              v-if="whichModal == 'addnewproject'"
            ></AddNewProject>
            <!--end:Add New Project-->

            <!--begin:Add New Client-->
            <AddNewClient
              v-if="whichModal == 'addnewclient'"
            ></AddNewClient>
            <!--end:Add New Client-->

    <!--begin::Ask if project should be created too -->
            <AskIfProject
              v-if="whichModal == 'askifproject'"
            ></AskIfProject>
    <!--end::Ask if project should be created too -->

            <!--begin:Edit profile experience-->
            <EditProfileExperience
              v-if="whichModal == 'editprofileexperience'"
            ></EditProfileExperience>
            <!--end:Edit profile experience-->

                        <!--begin:GEnerate PR-->
            <GeneratePR
              v-if="whichModal == 'generatePR'"
            ></GeneratePR>
            <!--end:Generate PR-->

            <!--begin:Reset Password-->
            <ResetPassword
              v-if="whichModal == 'resetpassword'"
            ></ResetPassword>
            <!--end:Reset Password-->


            <!--begin:Reset Password-->
            <GenerateMessage
              v-if="whichModal == 'generatemessage'"
            ></GenerateMessage>
            <!--end:Reset Password-->

            <!--begin:GDPRManagement-->
            <GDPRManagement
              v-if="whichModal == 'gdprmanagement'"
            ></GDPRManagement>
            <!--end:GDPRManagement-->

              <!--begin:ProjectKpis-->
            <ProjectKpis
              v-if="whichModal == 'projectkpis'"
            ></ProjectKpis>
            <!--end:ProjectKpis-->
            
              <!--begin:EmailIntegration-->
            <EmailIntegration
              v-if="whichModal == 'emailintegration'"
            ></EmailIntegration>
            <!--end:EmailIntegration-->

              <!--begin:ConfigureGeneralAlert-->
            <ConfigureGeneralAlert
              v-if="whichModal == 'configuregeneralalert'"
            ></ConfigureGeneralAlert>
            <!--end:ConfigureGeneralAlert-->


            <!--begin:GeneralAlertMessage-->
            <GeneralAlertMessage
              v-if="whichModal == 'generalalertmessage'"
            ></GeneralAlertMessage>
            <!--end:GeneralAlertMessage-->

            <!--begin:AddGuestAccount-->
            <AddGuestAccount
              v-if="whichModal == 'addguestaccount'"
            ></AddGuestAccount>
            <!--end:AddGuestAccount-->

            

          </div>
          <!--end::Content-->
        </div>
        <!--end::Modal body-->
        <!--begin::Modal footer
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >Close</button>
          <button
            type="button"
            class="btn btn-primary"
          >Save</button>
        </div>
        end::Modal footer-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal-->
</template>

<script>
import EditProfileStatusList from "./ModalContents/EditProfileStatusList.vue";
import Search from "./ModalContents/Search.vue";
import EditProfileProjectBinding from "./ModalContents/EditProfileProjectBinding.vue";
import AddProfileComment from "./ModalContents/AddProfileComment.vue";
import AddProfileTask from "./ModalContents/AddProfileTask.vue";
import EditTeamMembers from "./ModalContents/EditTeamMembers.vue";
import EditProfileTags from "./ModalContents/EditProfileTags.vue";
import Email from "./ModalContents/Email.vue";
import EditEmailSignature from "./ModalContents/EditEmailSignature.vue";
import BackOfficeUserDetails from "./ModalContents/BackOfficeUserDetails.vue";
import EmailTemplates from "./ModalContents/EmailTemplates.vue";
import EditProfileComment from "./ModalContents/EditProfileComment.vue";
import BackOfficeGenerateSignupLink from "./ModalContents/BackOfficeGenerateSignupLink.vue";
import WelcomeUser from "./ModalContents/WelcomUser.vue";
import RecommendedProfiles from "./ModalContents/RecommendedProfiles.vue";
import EditProjectAndClientInfo from "./ModalContents/EditProjectAndClientInfo.vue";
import AddProjectOrClientNotes from "./ModalContents/AddProjectOrClientNotes.vue";
import AddNewProfile from "./ModalContents/AddNewProfile.vue";
import EditProfileExperience from "./ModalContents/EditProfileExperience.vue";
import GeneratePR from "./ModalContents/GeneratePR.vue";
import ResetPassword from "./ModalContents/ResetPassword.vue";
import GenerateMessage from "./ModalContents/GenerateMessage.vue";
import GDPRManagement from "./ModalContents/GDPRManagement.vue";
import AddNewProject from "./ModalContents/AddNewProject.vue";
import AddNewClient from "./ModalContents/AddNewClient.vue";
import EditAccount from "./ModalContents/EditAccount.vue";
import ProjectKpis from "./ModalContents/ProjectKpis.vue";
import EmailIntegration from "./ModalContents/EmailIntegration.vue";
import ConfigureGeneralAlert from "./ModalContents/ConfigureGeneralAlert.vue";
import GeneralAlertMessage from "./ModalContents/GeneralAlertMessage.vue";
import AskIfProject from "./ModalContents/AskIfProject.vue";
import AddGuestAccount from "./ModalContents/AddGuestAccount.vue";







export default {
  props: ["whichModal", "title", "subTitle", "buttonText", "cardTitle"],
  components: {
    EditProfileStatusList,
    Search,
    EditProfileProjectBinding,
    AddProfileComment,
    AddProfileTask,
    EditTeamMembers,
    EditProfileTags,
    Email,
    EditEmailSignature,
    BackOfficeUserDetails,
    EmailTemplates,
    EditProfileComment,
    BackOfficeGenerateSignupLink,
    WelcomeUser,
    RecommendedProfiles,
    EditProjectAndClientInfo,
    AddProjectOrClientNotes,
    AddNewProfile,
    EditProfileExperience,
    GeneratePR,
    ResetPassword,
    GenerateMessage,
    GDPRManagement,
    AddNewProject,
    AddNewClient,
    EditAccount,
    ProjectKpis,
    EmailIntegration,
    ConfigureGeneralAlert,
    GeneralAlertMessage,
    AskIfProject,
    AddGuestAccount

    

    
  },
};
</script>

<style>
</style>