<template>
  <div style="margin-top: -25px" v-if="$store.state.projectsForThisClient.length > 0">
    <div
      v-for="project in sortedProjects"
      :key="project.id"
      class="bg-hover-light p-2 border border-0 rounded"
      
    >
      <div @click="goToProject(project.id)" v-if="project.teamaccess == 'Public' || project.owninguser == $store.state.userData.id" class="d-flex align-items-center">
        <div class="symbol symbol-45px companylogo me-2 border-1 border-gray-300 border">
          <img
            v-if="project.clientobject.clientlogourl"
            :src="project.clientobject.clientlogourl"
            class=""
            alt=""
          />
          <img
            v-else
            src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
            class=""
            alt=""
          />
        </div>
        <div class="d-flex justify-content-start flex-column">
          <span v-if="project.positiontitle" class="text-dark fw-bolder fs-6">{{
            project.positiontitle
          }}</span>
          <span class="text-muted fw-bold text-muted d-block fs-7">{{
            project.status
          }}</span>
        </div>
      </div>
      <div class="bg-light p-3 border border-0 rounded" v-if="project.teamaccess == 'Private' && project.owninguser != $store.state.userData.id">
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/general/gen047.svg-->
<span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
<path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
<span class="text-muted ms-3">Private project</span>
        
      </div>
    </div>
  </div>
      <CardAlert
    v-else
      alerttype="info"
      title="No projects for this client"
      text="Create at least one project for this client."
      style="margin-top: -22px;"
    ></CardAlert>

</template>

<script>
import { getClientProjectsExternal } from "../../../js/clientdetails";
import { goToDetailedProjectExternal } from "../../../js/navigation";

import CardAlert from "../../CardAlerts/CardAlert.vue"

export default {
components: {
    CardAlert
},
  mounted() {
    setTimeout(() => {
      getClientProjectsExternal();
    }, 200);
  },
  computed: {
    sortedProjects() {

        
      this.$store.state.projectsForThisClient.sort((a, b) => {
        if (a.status < b.status) {
          return -1;
        }
        if (a.status > b.status) {
          return 1;
        }
        return 0;
      });

      return this.$store.state.projectsForThisClient;
    },
  },
  methods: {
    goToProject(projectid) {
        this.$router.push("/projects");
        goToDetailedProjectExternal(projectid);
    }
  }
};
</script>

<style>
</style>