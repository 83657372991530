<template>
<div class="border border-rounded">
        <Editor
        api-key="uzj6zx7zdrbapy2mocpl5ykvele0syt6hsz6siw79padif48"
        :init="{
          menubar: false,
          skin: 'snow',
        }"
        v-model="$store.state.userData.emailsignature"
      />

</div>

<div class="d-flex justify-content-end">
    <button class="btn btn-sm btn-primary mt-3"
            @click="saveSignature()"
    >Save</button>
</div>

</template>

<script>

import Editor from "@tinymce/tinymce-vue";
import { saveSignatureExternal } from "../../../js/profiledetails"

export default {
  components: {
    Editor,
  },
  methods: {
    saveSignature() {
        saveSignatureExternal()
    }
  }
}
</script>

<style>

</style>