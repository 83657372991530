<template>
  <div class="card card-xl-stretch mb-5 mb-xl-8 shadow-lg">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Header-->
      <div class="px-9 pt-7 card-rounded h-275px w-100 bg-success">
        <!--begin::Heading-->
        <div class="d-flex">
          <h3 class="card-title fw-bolder text-white">
            Time to Hire
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/general/gen046.svg-->
            <span
              class="svg-icon svg-icon-white svg-icon-2x ms-2 tooltip custompointer"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  fill="currentColor"
                />
                <path
                  d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                  fill="currentColor"
                />
              </svg>
              <span v-if="$route.path === '/projects'" class="tooltiptext">
                This KPI tracks the time it took to reach each tracked status in this project.
                You can also get global average values (all projects) for this KPI in the Dashboard tab.
              </span>
            <span v-if="$route.path === '/dashboard'" class="tooltiptext">
                This KPI tracks the average time it takes to reach each status.
                It is calculated based on all the projects you work on. Your
                other teammembers are not taken into account.
              </span>
            </span>
            <!--end::Svg Icon-->
          </h3>
        </div>
        <!--end::Heading-->

        <!--begin::Balance-->
        <div class="text-center flex-column text-white pt-15 dropdown">
          <button
            class="btn btn-sm btn-light-success"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span class="svg-icon svg-icon-muted svg-icon-5"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                  fill="currentColor"
                ></path></svg
            ></span>
            Edit status to track
          </button>
          <div
            class="dropdown-menu menu-sub menu-sub-dropdown"
            aria-labelledby="filterProfilesButton"
            id="filterProfilesDrodownMenu"
            role="menu"
          >
            <EditTimeToHireStatus></EditTimeToHireStatus>
          </div>
        </div>
        <!--end::Balance-->
      </div>
      <!--end::Header-->

      <!--begin::Items-->

      <div
        v-if="trackedStatusList.length > 0"
        class="bg-body shadow-sm card-rounded mx-9 mb-5 px-6 py-9 position-relative z-index-1"
        style="margin-top: -100px"
      >
        <!--begin::Item-->
        <div
          v-for="status in trackedStatusList"
          :key="status.id"
          class="d-flex align-items-center mb-3"
        >
          <!--begin::Description-->
          <div class="d-flex align-items-center flex-wrap w-100">
            <!--begin::Title-->
            <div class="mb-1 pe-3 flex-grow-1">
              <div class="text-gray-400 fw-semibold fs-7">Time to reach</div>

              <a
                href="#"
                class="fs-7 text-gray-800 text-hover-primary fw-bold"
                >{{ status.status }}</a
              >
            </div>
            <!--end::Title-->

            
            <!--begin::Label-->
            <div>
              <div
                v-if="!loadingtimetohire"
                class="d-flex align-items-center animate__animated animate__fadeIn"
              >
                <div
                  v-if="$store.state.avgTimeToReach[status.id] > 0 || $store.state.currentProjectDetails.timetohire"
                  class="fw-bold fs-7 text-gray-800 pe-1"
                >
        {{
            $route.path === '/projects' ? projectTimeToHire(status) :
            $route.path === '/dashboard' ? dashboardTimeToHire(status) :
            ''
        }}
                </div>
                <i class="ki-duotone ki-arrow-up fs-7 text-success ms-1"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </div>
              <div v-else>
                <div class="loading-dots">
                  <div
                    class="loading-dots--dot graydots me-1"
                    style="height: 6px !important; width: 6px !important"
                  ></div>
                  <div
                    class="loading-dots--dot graydots me-1"
                    style="height: 6px !important; width: 6px !important"
                  ></div>
                  <div
                    class="loading-dots--dot graydots"
                    style="height: 6px !important; width: 6px !important"
                  ></div>
                </div>
              </div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Description-->
        </div>
        <!--end::Item-->
      </div>
      <!--end::Items-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { calculateAvgTimeForhire } from "../../js/dashboard";

import EditTimeToHireStatus from "../Dropdowns/EditTimeToHireStatus.vue";

export default {
  data() {
    return {
      loadingtimetohire: true,
    };
  },
  components: {
    EditTimeToHireStatus,
  },

  mounted() {
    calculateAvgTimeForhire();

    setTimeout(() => {
      this.loadingtimetohire = false;
    }, 2100);
  },
  computed: {
    trackedStatusList() {
      return this.$store.state.profileStatusList
        .filter((item) => item.tracked === true)
        .reverse();
    },
  },
  methods: {
projectTimeToHire(status) {
  // Check if 'timetohire' is defined, if not return "No data"
  if (!this.$store.state.currentProjectDetails.timetohire) {
    return "No data";
  }
  
  let targetObject = this.$store.state.currentProjectDetails.timetohire.find(
    (item) => item.statusid === status.id
  );
  
  let timespanValue;
  if (targetObject) {
    timespanValue = targetObject.timespan;
    let days = Math.round(timespanValue / 86400000)
    return days + " days"
  } else {
    return "No data";
  }
},
    dashboardTimeToHire(status) {
        let days = Math.round(
                      this.$store.state.avgTimeToReach[status.id] / 86400000
                    )
        if(days > 0) {
            return days + " days"
        } else {
            return "No data"
        }
    }
  },
};
</script>

<style>
</style>