


<template>


	<!--begin::Main-->

		<!--begin::Root-->

		<!--begin::Main app UI-->
		<div class="d-flex flex-column flex-root">

			<!--begin::Page-->
			<div class="page d-flex flex-column flex-column-fluid">













				<!--begin::Main Tab Nav bar-->
				<MainTabNav></MainTabNav>
				<!--end::Main Tab Nav bar-->

				<!--begin::Wrapper : the different tabs of the app are injected here-->
        		<ProfileList v-if="($store.state.showingProfilesAs == 'recentProfiles' || $store.state.showingProfilesAs == 'projectProfiles' || $store.state.showingProfilesAs == 'searchResults')  && $store.state.userData.email"></ProfileList>
				<ProfileDetails v-if="$store.state.showingProfilesAs == 'profileDetails' && $store.state.userData.email && $store.state.currentProfileDetails"></ProfileDetails>
				<!--end::Wrapper-->
				

			</div>
			<!--end::Page-->

		</div>




		<!--end::Root-->





</template>

<script>

import ProfileList from '../components/ProfileList.vue'
import ProfileDetails from '../components/ProfileDetails.vue'
import MainTabNav from '../components/MainTabNav.vue'
import Modal1 from '../components/Modals/Modal1.vue'
import Modal2 from '../components/Modals/Modal1.vue'





export default {
    components: {
        ProfileList, ProfileDetails, MainTabNav, Modal1, Modal2
    },
	methods: {
		closemodals: function() {
			this.$store.state.showusermenu = false;
		}
	},
    created() {
    this.$store.state.currentRoute = this.$route.path
  },
  mounted() {

	  	KTMenu.createInstances();
	  	//KTDrawer.init();
		KTDrawer.createInstances();
		//KTDrawer.handleShow();
    	//KTDrawer.handleDismiss();
		KTScroll.createInstances();
		KTScrolltop.createInstances();
		KTSticky.createInstances();
		KTDialer.createInstances();
		KTImageInput.createInstances();
		KTPasswordMeter.createInstances();
		KTSwapper.createInstances();
		KTToggle.createInstances();
		KTWidgets.init()





  }


}
</script>
