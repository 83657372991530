<template>


  <div class="d-flex">

 
  </div>

  <div class="d-flex align-items-center mb-5">
    <div v-if="$store.state.teamData.teamlogourl" class="symbol symbol-45px me-5"><img
        :src="$store.state.teamData.teamlogourl"
        class=""
        alt=""  
      ></div>
    <div class="d-flex justify-content-start flex-column"><span class="text-dark fw-bolder text-hover-primary fs-6">{{ $store.state.teamData.teamname }}</span><span class="text-muted fw-bold text-muted d-block fs-7">{{ $store.state.teamData.location }}</span></div>
     <div class="ms-auto">
                 <button
                 @click="$openModal('Modal1', 'Add guest account', '')"

                class="btn btn-light-primary btn-sm h-30px h-md-40px ms-2"
        
              >
              <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: -5px;"><rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect><rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect></svg>
              Add guest account
                  </button>
    </div>
  </div>

<div class="separator my-8"></div>

<div v-if="$store.state.teammembers">
  <div
    class="d-flex mb-10"
    v-for="user in computedTeamMembers"
    :key="user.userid"
  >
    <!--begin:: Avatar -->
    <div v-if="user.avatarurl" class="symbol symbol-circle symbol-50px overflow-hidden me-3">
      <div>
        <div class="symbol-label">
          <img
            :src="user.avatarurl"
            alt=""
            class="w-100"
          >
        </div>
      </div>
    </div>
        <div v-else class="symbol symbol-circle symbol-50px overflow-hidden me-3">
      <div>
                             <div
                              class="symbol-label fs-4 fw-semibold text-primary"
                            >
                              {{
                                user.firstname.substring(0, 1) +
                                user.lastname.substring(0, 1)
                              }}
                            </div>
      </div>
    </div>

    <!--end::Avatar-->
    <!--begin::User details-->
    <div class="d-flex flex-column w-100">
      <a
        class="text-gray-800 fw-bold mb-1"
      >{{ user.firstname }} {{ user.lastname }}</a>
      <span>{{ user.email }}</span>
    </div>
    <!--begin::User details-->

    <a
      class="btn btn-sm btn-flex btn-light"
      id="userRoleSelectButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="height: 40px;"
      v-if="$store.state.userData.userrole == 'Administrator'"
    >
      {{ user.userrole }}
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
      <span class="ms-2 svg-icon svg-icon-4 m-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
            fill="black"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </a>
    <div v-else>{{ user.userrole }}</div>
    <div
      class="dropdown-menu w-300px w-md-150px py-7 dropdownFadeIn"
      aria-labelledby="taskFilterButton"
      role="menu"
      style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(39px, 85px, 0px);"
      data-popper-placement="bottom-start"
    >
      <!--v-if-->
      <div class="menu-item my-0 bg-hover-light">
        <a class="menu-link text-muted py-2 ms-2"
           @click="assignUserRole('Administrator', user.id, user.userid)"
         ><span class="">Administrator</span></a></div>
      <div class="menu-item my-0 bg-hover-light"><a
          class="menu-link text-muted py-2 active ms-2"
          @click="assignUserRole('Operator', user.id, user.userid)"
        >
          <span class="">Operator</span>
        </a></div>
    </div>
  </div>
</div>

  <CardAlert alerttype="info" title="" text="Only an administrator can change the user roles."
             v-if="$store.state.userData.userrole == 'Operator'">
  </CardAlert>



</template>

<script>

import { assignUserRoleExternal } from "../../../js/crosscomponentfeatures"
import CardAlert from "../../CardAlerts/CardAlert.vue"

export default {
  components: { CardAlert },
  methods: {
    assignUserRole(userRole, userDocId, userid) {
      assignUserRoleExternal(userRole, userDocId, userid)
    },
  },
  computed: {
    computedTeamMembers() {
      const teamWithoutClientUsers = this.$store.state.teammembers.filter(member => member.subscriptiontype !== "client");
      return teamWithoutClientUsers
    }
  }
};
</script>

<style>
</style>