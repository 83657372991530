<template>
  <div
    class="wrapper d-flex flex-column flex-row-fluid container-xxl"
    id="kt_wrapper"
  >
    <!--begin::Toolbar-->
    <Toolbar
      v-if="$store.state.showingProjectsAs == 'myProjects'"
      title="My projects"
    ></Toolbar>
    <Toolbar
      v-if="$store.state.showingProjectsAs == 'teamProjects'"
      title="Team projects"
    ></Toolbar>
        <Toolbar
      v-if="$store.state.showingProjectsAs == 'allProjects'"
      title="All projects"
    ></Toolbar>

    <!--end::Toolbar--> 
    <!--begin::Main-->

    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div class="content flex-row-fluid" id="kt_content">
        <!--begin::Card-->

        <!--begin::Tables Widget 9-->
        <div class="card mb-5 mb-xl-8">
          <!--begin::Header-->

          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-3 boxFadeIn3">
            <!--begin::Table container-->
            <div class="table-responsive">
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-hover table-row-gray-300 align-middle gs-3 gy-4"
              >
                <!--begin::Table head-->
                <thead v-if="computedSelectedProjectList.length > 0">
                  <tr class="fw-bolder text-muted" style="height: 65px">
                    <th style="width: 25%">Project</th>
                    <th style="width: 13%">Profiles</th>
                    <th style="width: 13%">Start date</th>
                    <th style="width: 13%">Status</th>
                    <th style="width: 14%">Users</th>
                  </tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody v-if="computedSelectedProjectList.length > 0">
                  <tr
                    class="animate__animated"
                    style="cursor: pointer"
                    v-for="project in computedSelectedProjectList"
                    :key="project.id"
                    :id="'projectRow' + project.id"
                    @click="goToProjectDetails(project.id)"
                  >
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-45px me-5 companylogo">
                          <img
                            v-if="project.clientobject.clientlogourl"
                            :src="project.clientobject.clientlogourl"
                            class=""
                            alt=""
                          />
                          <img
                            v-else
                            src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                            class=""
                            alt=""
                          />
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <span
                            v-if="project.positiontitle"
                            class="text-dark fw-bolder text-hover-primary fs-6"
                            >{{ project.positiontitle }}</span
                          >
                          <span
                            class="text-muted fw-bold text-muted this.clientd-block fs-7"
                            >{{ project.clientobject.clientname }}</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="
                          project.numberofpotentialcandidates +
                            project.numberofinprogress +
                            project.numberofdiscarded +
                            project.numberofnostatus >
                          0
                        "
                        class="text-muted fw-bolder d-block fs-6 me-6"
                      >
                        <span class="text-dark">{{
                          project.numberofpotentialcandidates +
                          project.numberofinprogress +
                          project.numberofdiscarded +
                          project.numberofnostatus
                        }}</span>
                        Profiles
                      </div>
                      <span v-else>No profiles</span>
                    </td>
                    <td>
                      <span
                        class="text-muted fw-bold text-muted d-block fs-7 me-6"
                        >{{
                          new Date(project.startedat).getDate() +
                          "/" +
                          (new Date(project.startedat).getMonth() + 1) +
                          "/" +
                          new Date(project.startedat).getFullYear()
                        }}</span
                      >
                    </td>

                    <td class="text-start">
                      <StatusDropdown
                        origin="projectList"
                        :elementObject="project"
                        :currentProjectStatusName="project.status"
                      ></StatusDropdown>
                    </td>
                    <td class="text-start">
                      <div class="d-flex">
                        <div
                          style="margin-right: -8px"
                          v-for="user in computedProjectUsers(project)"
                          :key="user.id"
                        >
                          <div
                            v-if="user.userprofilepic"
                            class="symbol symbol-30px symbol-circle me-5"
                          >
                            <img :src="user.userprofilepic" class="" alt="" />
                            <span
                              v-if="user.usertype == 'Owner'"
                              class="symbol-badge badge badge-circle bg-transparent start-100"
                            >
                              <img
                                src="../assets/media/icons/crown.png"
                                alt=""
                              />
                            </span>
                          </div>

                          <div
                            v-else
                            class="symbol border border-primary symbol-circle symbol-30px me-5"
                          >
                            <div
                              class="symbol-label fs-5 fw-semibold text-primary"
                            >
                              {{
                                user.firstname.substring(0, 1) +
                                user.lastname.substring(0, 1)
                              }}
                            </div>
                            <span
                              v-if="user.usertype == 'Owner'"
                              class="symbol-badge badge badge-circle bg-transparent start-100"
                            >
                              <img
                                src="../assets/media/icons/crown.png"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <div
                  v-if="computedSelectedProjectList.length == 0"
                  class="d-flex text-muted fw-bolder my-15"
                >
                  <div class="mx-auto">
                    <i class="bi bi-slash-circle me-2"></i>
                    No projects here
                  </div>
                </div>

                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
            <!--end::Table container-->
          </div>
          <!--begin::Body-->
        </div>
        <!--end::Tables Widget 9-->

        <!--end::Card-->
      </div>
      <!--end::Content-->
    </div>

    <!--end::Main-->
    <!--begin::Footer-->
    <div
      class="footer py-4 d-flex flex-column flex-md-row align-items-center justify-content-between"
      id="kt_footer"
    >
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="text-white opacity-75 fw-bold me-1">2023©</span>
        <a
          href="https://kiwanotalent.com"
          target="_blank"
          class="text-white text-hover-primary opacity-75"
          >Kiwano</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-white menu-hover-primary fw-bold order-1 opacity-75">
        <li class="menu-item">
          <a
            href="https://kiwanotalent.com"
            target="_blank"
            class="menu-link px-2"
            >About</a
          >
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
import Toolbar from "../components/Toolbar-Component.vue";
import StatusDropdown from "../components/Dropdowns/StatusDropdown.vue";
//import getDetails from "../composables/getDetails"
import { goToDetailedProjectExternal } from "../js/navigation";

export default {
  components: {
    Toolbar,
    StatusDropdown,
  },
  methods: {
    goToProjectDetails(projectid) {
      goToDetailedProjectExternal(projectid);
    },
    computedProjectUsers(project) {
      let users = []; // {.userid, .usertype, .userprofilepic}
      let owner = this.$store.state.teammembers.find(
        (member) => member.userid === project.owninguser
      );

      if(owner) {
      owner = {
        userid: owner.userid,
        usertype: "Owner",
        userprofilepic: owner.avatarurl,
        firstname: owner.firstname,
        lastname: owner.lastname,
      };

      users.push(owner);
      }



      if (project.otherusers && project.otherusers.length > 0) {
        const otherUsers = project.otherusers.map((userId) => {
          return this.$store.state.teammembers.find(
            (teamMember) => teamMember.userid === userId
          );
        });

        otherUsers.forEach((otherUser) => {
          if (otherUser) {
            const newUser = {
              userid: otherUser.userid,
              usertype: "Contributor",
              userprofilepic: otherUser.avatarurl,
              firstname: otherUser.firstname,
              lastname: otherUser.lastname,
            };
            users.push(newUser);
          }
        });
      }

      return users;
    },
  },
  computed: {
    computedMyProjectsList() {
      //filter out duplicate projects (for some reason the projects sometimes appear twice or multiple times)
      const uniqueProjects = this.$store.state.currentMyProjectList.filter(
        (project, index, self) => {
          // Check for the first occurrence of the project with the same id
          const firstIndex = self.findIndex((p) => p.id === project.id);

          // Return true if the current index is the same as the first occurrence index
          return index === firstIndex;
        }
      );

      const sortedByTimestamp = uniqueProjects.sort((a, b) => {
        if (a.startedat > b.startedat) {
          return -1;
        } else if (a.startedat < b.startedat) {
          return 1;
        } else {
          return 0;
        }
      });

      const sortByTimestampAndStatus = sortedByTimestamp.sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        } else if (a.status < b.status) {
          return -1;
        } else {
          return 0;
        }
      });

      return sortByTimestampAndStatus;
    },
    computedTeamProjectsList() {
      //filter out duplicate projects (for some reason the projects sometimes appear twice or multiple times)
      const uniqueProjects = this.$store.state.currentTeamProjectList.filter(
        (project, index, self) => {
          // Check for the first occurrence of the project with the same id
          const firstIndex = self.findIndex((p) => p.id === project.id);

          // Return true if the current index is the same as the first occurrence index
          return index === firstIndex;
        }
      );

      const sortedByTimestamp = uniqueProjects.sort((a, b) => {
        if (a.startedat > b.startedat) {
          return -1;
        } else if (a.startedat < b.startedat) {
          return 1;
        } else {
          return 0;
        }
      });

      const sortByTimestampAndStatus = sortedByTimestamp.sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        } else if (a.status < b.status) {
          return -1;
        } else {
          return 0;
        }
      });

      return sortByTimestampAndStatus;
    },
computedAllProjectsList() {
  // Merge the two lists
  const combinedProjects = [...this.computedMyProjectsList, ...this.computedTeamProjectsList];

  // Remove duplicates from the combined list
  const uniqueCombinedProjects = combinedProjects.filter(
    (project, index, self) => {
      const firstIndex = self.findIndex((p) => p.id === project.id);
      return index === firstIndex;
    }
  );

  // Sort by timestamp first
  const sortedByTimestamp = uniqueCombinedProjects.sort((a, b) => {
    if (a.startedat > b.startedat) {
      return -1;
    } else if (a.startedat < b.startedat) {
      return 1;
    } else {
      return 0;
    }
  });

  // Then sort by status
  const sortByTimestampAndStatus = sortedByTimestamp.sort((a, b) => {
    if (a.status > b.status) {
      return 1;
    } else if (a.status < b.status) {
      return -1;
    } else {
      return 0;
    }
  });

  return sortByTimestampAndStatus;
},

    computedSelectedProjectList() {
      if (this.$store.state.showingProjectsAs == "myProjects") {
        return this.computedMyProjectsList;
      } else if (this.$store.state.showingProjectsAs == "teamProjects") {
        return this.computedTeamProjectsList;
      } else if (this.$store.state.showingProjectsAs == "allProjects") {
        return this.computedAllProjectsList;
      }
    },
  },
};
</script>

<style></style>
