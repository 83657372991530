<template>
  <!--begin::Tables Widget 9-->
									<div class="card card-xl-stretch mb-5 mb-xl-8">
										<!--begin::Header-->
										<div class="card-header border-0 pt-5">
											<h3 class="card-title align-items-start flex-column">
												<span class="card-label fw-bold fs-3 mb-1">Title</span>
												<span class="text-muted mt-1 fw-semibold fs-7">Subtitle</span>
											</h3>
											<div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add a user">
												<!--begin::Main button-->
<button type="button" class="btn btn-light-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_add_task"><!--begin::Svg Icon | path: icons/duotune/files/fil005.svg--><span class="svg-icon svg-icon-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z" fill="black"></path><rect x="11" y="19" width="10" height="2" rx="1" transform="rotate(-90 11 19)" fill="black"></rect><rect x="7" y="13" width="10" height="2" rx="1" fill="black"></rect><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path></svg></span><!--end::Svg Icon-->Main Btn</button>

												<!--end::Main button-->
											</div>
										</div>
										<!--end::Header-->
										<!--begin::Body-->
										<div class="card-body py-3">

                                            Content here

										</div>
										<!--begin::Body-->
									</div>
									<!--end::Tables Widget 9-->
</template>

<script>
export default {

}
</script>

<style>

</style>