<template>
<div v-if="$store.state.totalNumberOfBindings > 0 && $store.state.currentProfileProjectBindings.length == 0" style="display: flex; justify-content: center;">

                        <div class="mt-7 mb-10 me-15" id="preloader_1">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>

</div>
<div v-else>
  <div
    key="1"
    v-if="$store.state.currentProfileProjectBindings.length > 0"
    style="margin-top: -20px"
  >
    <!--begin::latest project binding-->
    <div class="border border-5 rounded border-light">
      <div
        class="d-flex flex-column border border-0 bg-light p-3 border"
        style="
          border-radius: 5px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        "
      >
        <div
          class="ms-auto custompointer"
          style="margin-right: -20px; margin-top: -23px"
          @click.prevent="
            detachFromThisProject($store.state.currentProfileProjectBindings[0])
          "
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-07-19-150827/core/html/src/media/icons/duotune/general/gen040.svg-->
          <span class="svg-icon svg-icon-danger svg-icon-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
              />
              <rect
                x="7"
                y="15.3137"
                width="12"
                height="2"
                rx="1"
                transform="rotate(-45 7 15.3137)"
                fill="currentColor"
              />
              <rect
                x="8.41422"
                y="7"
                width="12"
                height="2"
                rx="1"
                transform="rotate(45 8.41422 7)"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
        <!--end::Svg Icon-->
        <!--begin::project info-->
        <div class="d-flex">
          <!--begin::client logo-->
          <div
            v-if="$store.state.currentProfileProjectBindings[0].clientlogo"
            class="symbol symbol-30px companylogo"
          >
            <img
              :src="$store.state.currentProfileProjectBindings[0].clientlogo"
              class="h-40 align-self-center"
              alt=""
            />
          </div>
          <div v-else class="symbol symbol-40px companylogo">
            <img
              src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
              style="width: 50px"
              alt=""
            />
          </div>
          <!--end::client logo-->

          <!--begin::client name and position title (and status when on mobile ui)-->
          <div class="d-flex flex-column ms-3">
            <a class="text-dark fw-bold fs-8">{{
              $store.state.currentProfileProjectBindings[0].positiontitle
                .length > 15
                ? $store.state.currentProfileProjectBindings[0].positiontitle.substring(
                    0,
                    15
                  ) + "..."
                : $store.state.currentProfileProjectBindings[0].positiontitle
            }}</a>

            <span class="text-muted fw-semibold d-block fs-8">{{
              $store.state.currentProfileProjectBindings[0].clientname.length >
              15
                ? $store.state.currentProfileProjectBindings[0].clientname.substring(
                    0,
                    15
                  ) + "..."
                : $store.state.currentProfileProjectBindings[0].clientname
            }}</span>
          </div>
          <!--end::client name and position title-->
        </div>
        <!--end::project info-->
      </div>

      <!--begin::StatusDropdown-->
      <div class="mt-3 ms-3">
        <StatusDropdown
          origin="profileDetailsCard"
          :elementObject="$store.state.currentProfileDetails"
          :currentProfileProjectBindingId="
            $store.state.currentProfileProjectBindings[0].bindingid
          "
          :currentProfileStatusId="
            $store.state.currentProfileProjectBindings[0].profilestatusid
          "
          :currentProjectId="
            $store.state.currentProfileProjectBindings[0].projectid
          "
        ></StatusDropdown>
      </div>
      <!--end::StatusDropdown-->

      <!--begin: Notes-->
      <div
        v-if="!$store.state.currentProfileProjectBindings[0].notes"
        class="mt-3 me-2 ms-3 mb-5 custompointer d-flex"
        @click="
          storeCurrentProfileProjectBinding(
            $store.state.currentProfileProjectBindings[0]
          )
        "
        data-bs-toggle="modal"
        data-bs-target="#editProfileProjectBinding"
      >
        <span class="bg-white badge badge-light-primary">
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="margin-top: -1px"
          >
            <rect
              opacity="0.5"
              x="11.364"
              y="20.364"
              width="16"
              height="2"
              rx="1"
              transform="rotate(-90 11.364 20.364)"
              fill="currentColor"
            ></rect>
            <rect
              x="4.36396"
              y="11.364"
              width="16"
              height="2"
              rx="1"
              fill="currentColor"
            ></rect>
          </svg>
          Add assessment notes</span
        >
      </div>
      <div
        v-if="$store.state.currentProfileProjectBindings[0].notes"
        class="custompointer ms-3 mt-5 border border-1 border-dashed border-gray-400 p-1 me-3 mb-3 fs-7"
        style="border-radius: 7px"
        @click="
          storeCurrentProfileProjectBinding(
            $store.state.currentProfileProjectBindings[0]
          )
        "
        data-bs-toggle="modal"
        data-bs-target="#editProfileProjectBinding"
      >
        <div
          class="bg-white px-2 text-muted"
          style="margin-top: -12px; margin-left: -5px; width: 128px"
        >
          Assessment notes
        </div>
        <div class="d-flex ms-2 mb-2">
          <div
            v-if="$store.state.currentProfileProjectBindings[0].notes"
            class="mt-3 text-dark"
          >
            <span class="fw-boldest"></span>
            <span
              >{{
                $store.state.currentProfileProjectBindings[0].notes.length < 30
                  ? $store.state.currentProfileProjectBindings[0].notes
                  : $store.state.currentProfileProjectBindings[0].notes.substring(
                      0,
                      60
                    ) + "..."
              }}
            </span>
          </div>
          <button
            class="btn btn-sm btn-icon bg-transparent ms-auto"
            style="flex-grow: 0; flex-shrink: 0"
            @click="
              storeCurrentProfileProjectBinding(
                $store.state.currentProfileProjectBindings[0]
              )
            "
            data-bs-toggle="modal"
            data-bs-target="#editProfileProjectBinding"
          >
            <span class="svg-icon svg-icon-6"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                  fill="currentColor"
                ></path></svg
            ></span>
          </button>
        </div>
      </div>
      <!--end: Notes-->
    </div>
    <!--end::latest project binding-->

    <!--begin::"more project bindings"-->
    <!--begin:"more project bindings" toggle-->
    <div
      class="d-flex flex-stack fs-7 py-3"
      v-if="slicedMoreProfileBindings.length > 0"
    >
      <div
        class="rotate text-muted collapsible collapsed"
        data-bs-toggle="collapse"
        href="#moreprojectbindings"
        role="button"
        aria-expanded="false"
        aria-controls="moreprojectbindings"
      >
        <span class="badge badge-sm badge-light">
          <span class="me-2">{{ slicedMoreProfileBindings.length }}</span
          >previous project(s)
          <span class="ms-2 rotate-180">
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg--><span
              class="svg-icon svg-icon-3"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="black"
                ></path></svg
            ></span>
            <!--end::Svg Icon-->
          </span>
        </span>
      </div>
    </div>
    <!--end:"more projects bindings" toggle-->

    <!--begin:"more project bindings" content-->
    <div id="moreprojectbindings" class="collapse" style="">
      <div class="mb-2 border-top-dashed border-1 border-gray-400"></div>

      <!--begin::Table-->
      <table
        class="table table-row-dashed table-row-gray-400 align-top gs-2 gy-4"
      >
        <!--begin::Table body-->
        <div>
          <div
            v-for="olderbinding in slicedMoreProfileBindings"
            :key="olderbinding.bindingid"
            class="border border-0 rounded bg-light my-3 me-3"
          >
            <div class="d-flex">
              <div class="symbol symbol-40px me-4">
                <img
                  :src="olderbinding.clientlogo"
                  class="h-40 align-self-center"
                  alt=""
                />
              </div>
              <div class="d-flex flex-column">
                <span class="text-dark fw-bold mb-1 fs-6">{{
                  olderbinding.positiontitle.length > 15
                    ? olderbinding.positiontitle.substring(0, 15) + "..."
                    : olderbinding.positiontitle
                }}</span>

                <span class="text-muted fw-semibold d-block">{{
                  olderbinding.clientname
                }}</span>
                <div class="mt-2"></div>
              </div>
              <button
                class="btn btn-sm btn-icon btn-secondary ms-auto"
                @click="storeCurrentProfileProjectBinding(olderbinding)"
                data-bs-toggle="modal"
                data-bs-target="#editProfileProjectBinding"
                style="flex-grow: 0; flex-shrink: 0"
              >
                <span class="svg-icon svg-icon-6"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                      fill="currentColor"
                    ></path></svg
                ></span>
              </button>
            </div>
          </div>
        </div>
        <!--end::Table body-->
      </table>
      <!--end::Table-->
    </div>
    <!--end:"more project bindings" content-->
    <!--end::"more project bindings"-->
  </div>
  <div
    v-if="
      legacyProjectAttachment &&
      $store.state.currentProfileProjectBindings.length > 0
    "
    class="mt-5"
  ></div>
  <div
    v-if="legacyProjectAttachment"
    class="border border-1 border-gray-400 border-dashed rounded p-5 fs-8"
  >
    <div>
      <span class="fw-boldest text-dark me-1">Legacy project </span>(migrated
      data) :
    </div>
    <div class="mt-2">
      Project name :
      <span class="fw-normal">{{ legacyProjectAttachment }}</span>
    </div>
    <div>
      Project status:
      <span class="fw-normal">{{
        $store.state.currentProfileDetails.legacystatus
      }}</span>
    </div>
    <div>
      Project notes :
      <span class="fw-normal">{{
        $store.state.currentProfileDetails.legacycomment
      }}</span>
    </div>
  </div>

  <div
    key="2"
    v-if="
      $store.state.currentProfileProjectBindings.length == 0 &&
      !legacyProjectAttachment
    "
  >
    <CardAlert
      alerttype="info"
      title="No projects yet"
      text="Click the button above to attach the profile to a project."
    ></CardAlert>
  </div>
</div>

</template>

<script>
import CardAlert from "../../CardAlerts/CardAlert.vue";
import { detachFromThisProjectExternal } from "../../../js/profiledetails";
import { storeCurrentProfileProjectBindingExternal } from "../../../js/profiledetails";
import StatusDropdown from "../../Dropdowns/StatusDropdown.vue";

export default {
  components: {
    CardAlert,
    StatusDropdown,
  },
  mounted() {},
  data: function () {
    return {
      options: {
        colors: ["#009EF7", "#F5F8FA"],
        chart: {
          offsetX: 0,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                name: {
                  show: false,
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: false,
                  fontSize: "10px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 4,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: "Total",
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
      series: [10, 90],
    };
  },
  computed: {
    sortedAllProfileBindings() {
      const allBindings = this.$store.state.currentProfileProjectBindings;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      return allBindings.sort(compare);
    },
    slicedMoreProfileBindings() {
      return this.sortedAllProfileBindings.slice(1);
    },
    routepath() {
      return this.$route.path;
    },
    legacyProjectAttachment() {
      if (this.$store.state.currentProfileDetails.projectattachments) {
        const attachments =
          this.$store.state.currentProfileDetails.projectattachments;
        let legacyAttachment = null;

        for (const attachment of attachments) {
          if (attachment.includes("(legacy)")) {
            legacyAttachment = attachment.replace("(legacy)", "").trim();
            break; // Stop the loop after finding the first occurrence
          }
        }

        return legacyAttachment;
      } else {
        return null;
      }
    },
  },
  methods: {
    showthebindings() {
      console.log(this.$store.state.currentProfileProjectBindings);
    },
    detachFromThisProject(bindingid) {
      const confirmation = window.confirm(
        "Are you sure you want to remove this profile from the project ?"
      );

      if (!confirmation) {
        return; // Exit the function if the user clicks "Cancel"
      }
      detachFromThisProjectExternal(bindingid);
    },
    storeCurrentProfileProjectBinding(binding) {
      this.$store.state.addCommentActivated = false;
      storeCurrentProfileProjectBindingExternal(binding);
    },
    correspondingStatusName(profilestatusid) {
      //this takes the profile status id from the profileprojectbinding doc and looks for the corresponding
      //status in the user/team defined status list.
      //Then it returns the name of the status insead of the id, so that it becomes readable by user

      let allstatuses = this.$store.state.profileStatusList;
      let statusid = profilestatusid;

      //If no status has been assigned yet, the profilestatusid in Firestore and Vuex is 'xxx' by default
      if (statusid == "xxx") {
        return "No status";
      }
      //If a status has been assigned...
      else {
        //look for the corresponding status
        let statusobject = allstatuses.find((o) => o.id === statusid);

        //if there is no corresponding status in the statuslist because the status has been deleted by user from the statuslist...
        if (statusobject == undefined) {
          return "No status";
        }
        //if there is a corresponding status in the statuslist...
        else {
          //return status name
          return statusobject.status;
        }
      }
    },
    correspondingStatusCategory(profilestatusid) {
      for (let i = 0; i < this.$store.state.profileStatusList.length; i++) {
        if (this.correspondingStatusName(profilestatusid) == "No status") {
          return "No status";
        }
        if (
          this.correspondingStatusName(profilestatusid) ==
          this.$store.state.profileStatusList[i].status
        ) {
          return this.$store.state.profileStatusList[i].category;
        }
      }
    },
  },
};
</script>

<style></style>
