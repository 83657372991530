<template>

  <!--begin::Timeline-->
  <div class="timeline-label">
    <!--begin::Item-->
    <div v-for="exp in $store.state.currentProfileDetails.experiencelist" :key="exp.company" class="timeline-item">
      <!--begin::Label-->
      <div v-if="exp.endyear != 3000" class="timeline-label text-gray-800 fs-7">{{ exp.endyear }} - {{ exp.startyear }}</div>
      <div v-if="exp.endyear == 3000" class="timeline-label text-gray-800 fs-7">Today - {{ exp.startyear }}</div>

      <!--end::Label-->
      <!--begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-primary fs-1"></i>
      </div>
      <!--end::Badge-->
      <!--begin::Text-->
      <div class="d-flex flex-column">
        <div class="timeline-content d-flex">
          <span class="fw-bold text-gray-800 ps-3">{{ exp.company }}</span>
        </div>
        <div class="fw-mormal timeline-content text-muted ps-3">{{ exp.title }}</div>
      </div>

      <!--end::Text-->
    </div>
    <!--end::Item-->
  </div>
  <!--end::Timeline-->

<div v-if="$store.state.currentProfileDetails.educationlist.length > 0" class="fs-5 text-dark fw-bold mt-13 mb-6">

Education

</div>



    <!--begin::Timeline-->
  <div class="timeline-label">
    <!--begin::Item-->
    <div v-for="edu in $store.state.currentProfileDetails.educationlist" :key="edu.degree_name" class="timeline-item">
      <!--begin::Label-->
      <div v-if="edu.ends_at" class="timeline-label text-gray-800 fs-7">{{ edu.ends_at.year }} - {{ edu.starts_at.year }}</div>
      <div v-else class="timeline-label text-gray-800 fs-7"></div>


      <!--end::Label-->
      <!--begin::Badge-->
      <div class="timeline-badge">
        <i class="fa fa-genderless text-warning fs-1"></i>
      </div>
      <!--end::Badge-->
      <!--begin::Text-->
      <div class="d-flex flex-column">
        <div class="timeline-content d-flex">
          <span class="fw-bold text-gray-800 ps-3">{{ edu.school }}</span>
        </div>
        <div class="fw-mormal timeline-content text-muted ps-3">{{ edu.degree_name }}</div>
      </div>

      <!--end::Text-->
    </div>
    <!--end::Item-->
  </div>
  <!--end::Timeline-->

</template>

<script>
export default {

  computed: {
    computedExperienceList() {
      const experiences = this.$store.state.currentProfileDetails.experiencelist
      const sortedArray = experiences.sort((a,b) =>  (a.ends_at.year > b.ends_at.year) ? 1 : -1);
      return sortedArray

    }
  }

};
</script>

<style>
</style>