<template>
  <div
    id="kt_header"
    class="header"
    data-kt-sticky="true"
    data-kt-sticky-name="header"
    data-kt-sticky-offset="{default: '200px', lg: '300px'}"
  >
    <!--begin::Container-->
    <div
      class="header-top-container container-xxl sabi d-flex flex-grow-1 flex-stack"
    >
      <!--begin::Header Logo-->
      <div class="d-flex align-items-center me-5">
        <!--begin::Heaeder menu toggle-->

        <!--end::Heaeder menu toggle-->
        <!--begin::Logo-->
        <div class="d-flex">
          <a href="https://app.kiwanotalent.com">
            <!--begin::Desktop modes-->
            <img
              alt="Logo"
              src="../assets/media/logos/kiwano.png"
              class="d-none d-lg-inline-block h-50px"
            />
            <!--end::Desktop modes-->
            <!--begin::Table & mobile modes-->
            <img
              alt="Logo"
              src="../assets/media/logos/kiwanosmall.png"
              class="d-lg-none h-50px mt-10 ms-10"
            />
            <!--end::Table & mobile modes-->
          </a>
          <!--begin::Signup subtitle Signup-->
          <div v-if="currentRouteName == '/signup'">
            <div
              class="h-30px mt-3 ms-2"
              style="border-left: 2px solid white"
            ></div>
            <div style="margin-top: -28px">
              <h1 class="ms-7 text-white">Signup</h1>
            </div>
          </div>
          <!--end::Signup subtitle Signup-->
          <!--begin::Signup subtitle Billing-->
          <div v-if="currentRouteName == '/billing'">
            <div
              class="h-30px mt-3 ms-2"
              style="border-left: 2px solid white"
            ></div>
            <div style="margin-top: -28px">
              <h1 class="ms-7 text-white">Subscribe</h1>
            </div>
          </div>
          <!--end::Signup subtitle Billing-->
        </div>
        <!--end::Logo-->
      </div>
      <!--end::Header Logo-->

      <!--begin::Toolbar wrapper-->
      <div
        class="topbar d-flex align-items-stretch flex-shrink-0"
        id="kt_topbar"
        v-if="
          currentRouteName != '/signup' &&
          currentRouteName != '/billing' &&
          currentRouteName != '/signin' &&
          currentRouteName != '/clientauth' &&
          currentRouteName != '/paid'
        "
      >
        <!--begin: Search-->

        <div class="d-flex flex-column mt-6" style="height: 60px">
          <!--begin::Input wrapper-->
          <div class="w-lg-100 position-relative">
            <!--begin::Input-->

            <input
              type="text"
              class="form-control form-control-solid fs-7"
              style="
                background-color: #11ffee00;
                border: solid;
                border-color: rgb(191 192 196);
                border-width: 1px;
                color: white;
                height: 40px;
                width: 300px;
              "
              placeholder="Search profiles"
              name="searchInput"
              id="searchInput"
              autocomplete="off"
              v-model="$store.state.searchInputText"
              @keydown="showSearchDropdown()"
            />

            <!--end::Input-->

            <!--begin::search icon-->
            <div
              class="position-absolute translate-middle-y top-50 end-0 d-flex"
            >
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr088.svg-->
              <span
                @click="resetSearchBar()"
                :style="{
                  visibility:
                    $store.state.searchInputText.length > 0
                      ? 'visible'
                      : 'hidden',
                }"
                class="custompointer svg-icon svg-icon-white svg-icon-2hx"
                style="margin-top: 3px"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="7.05025"
                    y="15.5356"
                    width="12"
                    height="2"
                    rx="1"
                    transform="rotate(-45 7.05025 15.5356)"
                    fill="currentColor"
                  />
                  <rect
                    x="8.46447"
                    y="7.05029"
                    width="12"
                    height="2"
                    rx="1"
                    transform="rotate(45 8.46447 7.05029)"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen004.svg-->
              <span
                style="margin-top: 8px"
                class="svg-icon svg-icon-muted svg-icon-1"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->

              <div
                class="btn text-light btn-custom btn-sm h-30px h-md-40px ms-2"
                style="border-radius: 00px 10px 10px 0px"
                data-bs-toggle="modal"
                data-bs-target="#advancedsearch"
              >
                Advanced
              </div>
            </div>
            <!--end::search icon-->
          </div>
          <!--end::Input wrapper-->
        </div>

        <button
          id="searchDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="
            visibility: hidden;
            width: 1px;
            height: 1px;
            margin-right: -12px;
          "
        ></button>
        <div
          class="dropdown-menu dropdown-menu-end menu-sub menu-sub-dropdown mt-18"
          aria-labelledby="searchDropdown"
          id="searchDropdownMenu"
          role="menu"
          @click.stop
        >
          <SmallCard
            whichCard="searchfromdesktopversion"
            title="Search results"
            subTitle="search"
            v-if="$store.state.userData.algoliasearchkey"
          ></SmallCard>
          <div
            class="p-8 w-400px"
            v-if="!$store.state.userData.algoliasearchkey"
          >
            <CardAlert
              alerttype="info"
              title="Please be patient"
              text="The search feature is being activated for your account. For security reasons, this is done manually. It can take up to a few hours."
            ></CardAlert>
          </div>
        </div>
        <!--end:Search-->

        <!--begin::"Add data" button-->
        <div class="d-flex align-items-center ms-2 ms-lg-3">
          <button
            class="btn btn-custom btn-sm h-30px h-md-40px"
            style="width: 110px"
            id="newDataDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="d-flex">
              <div>
                <span class="text-white">Add data </span>
              </div>
              <div style="margin-right: -10px">
                <span class="ms-2 svg-icon svg-icon-4 m-0"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                      fill="black"
                    ></path></svg
                ></span>
              </div>
            </div>
          </button>
          <ul
            class="dropdown-menu w-md-150px py-7 px-7 menu-sub menu-sub-dropdown"
            aria-labelledby="newDataDropdown"
            role="menu"
          >
            <li
              class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
              data-bs-toggle="modal"
              data-bs-target="#addnewprofile"
            >
              <svg
                class="me-2"
                style="margin-left: -5px"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>

              Profile
            </li>
            <li
              class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
              data-bs-toggle="modal"
              data-bs-target="#addnewproject"
            >
              <svg
                class="me-2"
                style="margin-left: -5px"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>

              Project
            </li>
            <li
              class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem bg-hover-light"
              data-bs-toggle="modal"
              data-bs-target="#addnewclient"
            >
              <svg
                class="me-2"
                style="margin-left: -5px"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>

              Client
            </li>
          </ul>
        </div>
        <!--end::"Add data" button-->

        <!--begin::Help-->
        <div class="d-flex align-items-center ms-2 ms-lg-3">
          <!--begin::Drawer toggle-->
          <div
            class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px"
            id="kt_activities_toggle"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen046.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-1"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  fill="currentColor"
                />
                <path
                  d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Drawer toggle-->
        </div>
        <!--end::Help-->

        <!--begin::Notification-->
        <div
          class="d-flex align-items-center ms-1 ms-lg-3"
          id="notificationsdiv"
          v-if="$store.state.userData.firstname"
        >
          <!--begin::Menu wrapper-->

          <div
            class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px position-relative pulse"
            id="openNotifications2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen007.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-1"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                  fill="currentColor"
                />
                <path
                  d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
            <span
              v-if="$store.state.numberofnotifications > 0"
              class="pulse-ring"
            ></span>
            <span
              v-if="$store.state.numberofnotifications > 0"
              class="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger"
              >{{ $store.state.numberofnotifications }}</span
            >
          </div>

          <!--begin:: menu-->
          <ul
            class="dropdown-menu dropdown-menu-end dropdownFadeIn menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary menu-sub menu-sub-dropdown fw-bold py-4 fs-6 w-425px"
            aria-labelledby="openUserMenuButton"
            role="menu"
          >
            <SmallCard
              whichCard="notifications"
              title="Notifications"
              subTitle="none"
              v-if="$store.state.userData.firstname"
            ></SmallCard>
          </ul>
          <!--end:: menu-->
          <!--end::Menu wrapper-->
        </div>
        <!--end::Notification-->

        <!--begin::Calendar-->
        <div
          class="d-flex align-items-center ms-1 ms-lg-3"
          id="calendardiv"
          v-if="false && $store.state.userData.nylasemailtoken"
        >
          <div
            class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px pulse me-2"
            id="calendardropdownbtn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
            @click="loadCalendar()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              width="22px"
              height="22px"
              viewBox="0 0 512 512"
              enable-background="new 0 0 512 512"
              xml:space="preserve"
              fill="#ffffff"
            >
              <path
                d="M160,240v32c0,8.844-7.156,16-16,16h-32c-8.844,0-16-7.156-16-16v-32c0-8.844,7.156-16,16-16h32  C152.844,224,160,231.156,160,240z M144,352h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C160,359.156,152.844,352,144,352z M272,224h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C288,231.156,280.844,224,272,224z M272,352h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C288,359.156,280.844,352,272,352z M400,224h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C416,231.156,408.844,224,400,224z M400,352h-32c-8.844,0-16,7.156-16,16v32c0,8.844,7.156,16,16,16h32c8.844,0,16-7.156,16-16v-32  C416,359.156,408.844,352,400,352z M112,96h32c8.844,0,16-7.156,16-16V16c0-8.844-7.156-16-16-16h-32c-8.844,0-16,7.156-16,16v64  C96,88.844,103.156,96,112,96z M512,128v320c0,35.344-28.656,64-64,64H64c-35.344,0-64-28.656-64-64V128c0-35.344,28.656-64,64-64  h16v16c0,17.625,14.359,32,32,32h32c17.641,0,32-14.375,32-32V64h160v16c0,17.625,14.375,32,32,32h32c17.625,0,32-14.375,32-32V64  h16C483.344,64,512,92.656,512,128z M480,192c0-17.625-14.344-32-32-32H64c-17.641,0-32,14.375-32,32v256c0,17.656,14.359,32,32,32  h384c17.656,0,32-14.344,32-32V192z M368,96h32c8.844,0,16-7.156,16-16V16c0-8.844-7.156-16-16-16h-32c-8.844,0-16,7.156-16,16v64  C352,88.844,359.156,96,368,96z"
              />
            </svg>
          </div>
          <div
            class="dropdown-menu dropdownFadeIn menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary menu-sub menu-sub-dropdown fw-bold py-4 fs-6 h-550px w-900px"
            aria-labelledby="calendardropdownbtn"
            role="menu"
            style="box-shadow: 0px 0px 70px 10px rgba(82, 63, 105, 0.3) !important; overflow:hidden;"
            

          >
          <div class="d-flex" style="margin-top:25%;" v-if="!$store.state.userCalendarEvents">
             <div class="mx-auto lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
            <Calendar v-if="initiateFullCalendar == 'go' && $store.state.userCalendarEvents"></Calendar>
          </div>
        </div>
        <!--end::Calendar-->

        <!--begin::User menu-->
        <div
          class="d-flex align-items-center ms-1 ms-lg-3"
          id="kt_header_user_menu_toggle"
          v-if="$store.state.userData.firstname"
        >
          <!--begin::Menu wrapper-->
          <div
            v-if="$store.state.userData.avatarurl"
            class="cursor-pointer symbol symbol-circle symbol-35px symbol-md-40px"
            id="openUserMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img :src="$store.state.userData.avatarurl" alt="user" />
          </div>
          <div
            v-else
            class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px"
            id="openUserMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="symbol-label fs-5 fw-semibold text-white">
              {{
                $store.state.userData.firstname.substring(0, 1) +
                $store.state.userData.lastname.substring(0, 1)
              }}
            </div>
          </div>

          <!--begin::User account menu-->
          <div
            class="dropdown-menu dropdownFadeIn menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary menu-sub menu-sub-dropdown fw-bold py-4 fs-6 w-275px"
            aria-labelledby="openUserMenuButton"
            role="menu"
          >
            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <div class="menu-content d-flex align-items-center px-3">
                <!--begin::Avatar-->
                <div
                  class="symbol symbol-50px me-5"
                  v-if="$store.state.userData.avatarurl"
                >
                  <img alt="Logo" :src="$store.state.userData.avatarurl" />
                </div>
                <!--end::Avatar-->
                <!--begin::Username-->
                <div class="d-flex flex-column">
                  <div class="fw-bolder d-flex align-items-center fs-5">
                    {{ $store.state.userData.firstname }}
                  </div>
                  <a
                    href="#"
                    class="fw-bold text-muted text-hover-primary fs-7"
                    >{{ $store.state.userData.email }}</a
                  >
                </div>
                <!--end::Username-->
              </div>
            </div>
            <!--end::Menu item-->

            <!--begin::Menu separator-->
            <div class="separator my-2"></div>
            <!--end::Menu separator-->
            <!--begin::Menu item-->
            <div
              class="menu-item px-5"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="left-start"
            >
              <a
                href=""
                class="menu-link px-5"
                id="editAccountMenuLink"
                data-bs-toggle="modal"
                data-bs-target="#editAccount"
              >
                <span class="menu-title position-relative">My account</span>
              </a>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div
              class="menu-item px-5"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="left-start"
            >
              <a
                href=""
                class="menu-link px-5"
                id="editTeammembersMenuLink"
                data-bs-toggle="modal"
                data-bs-target="#editTeammembers"
              >
                <span class="menu-title position-relative">My team</span>
              </a>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu separator-->
            <div class="separator my-2"></div>
            <!--end::Menu separator-->
            <!--begin::Menu item-->
            <div
              class="menu-item px-5"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="left-start"
            >
              <a
                href=""
                class="menu-link px-5"
                id="editTeammembersMenuLink"
                data-bs-toggle="modal"
                data-bs-target="#editProfileStatusList"
              >
                <span class="menu-title position-relative"
                  >Profile Status list</span
                >
              </a>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div
              class="menu-item px-5"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="left-start"
            >
              <a
                href=""
                class="menu-link px-5"
                id=""
                data-bs-toggle="modal"
                data-bs-target="#editProfileTags"
              >
                <span class="menu-title position-relative">Profile Tags</span>
              </a>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-5 my-1">
              <a
                href="/metronic8/demo1/../demo1/account/settings.html"
                class="menu-link px-5"
                data-bs-toggle="modal"
                data-bs-target="#editEmailTemplates"
                @click="initializeTemplateModal()"
                >Email templates</a
              >
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-5">
              <a
                href="/metronic8/demo1/../demo1/authentication/flows/basic/sign-in.html"
                class="menu-link px-5"
                data-bs-toggle="modal"
                data-bs-target="#editSignature"
                >Email signature</a
              >
            </div>
            <!--end::Menu item-->

            <!--begin::Menu item-->
            <div class="menu-item px-5">
              <a
                href="/metronic8/demo1/../demo1/authentication/flows/basic/sign-in.html"
                class="menu-link px-5"
                data-bs-toggle="modal"
                data-bs-target="#gdprmanagement"
                @click="getNumberOfProfilesWhoDeclined()"
                >GDPR Management</a
              >
            </div>
            <!--end::Menu item-->
            <!--begin::Menu separator-->
            <div class="separator my-2"></div>
            <!--end::Menu separator-->
                       <!--begin::Menu item-->
            <div class="menu-item px-5">
              <a
                class="menu-link px-5"
                data-bs-toggle="modal"
                data-bs-target="#emailintegration"
                >Email integration</a
              >
            </div>
            <!--end::Menu item-->           
            <!--begin::Menu item-->
            <div class="menu-item px-5">
              <a
                class="menu-link px-5"
                data-bs-toggle="modal"
                data-bs-target="#emailintegration"
                >LinkedIn integration</a
              >
            </div>
            <!--end::Menu item-->
                        <!--begin::Menu separator-->
            <div class="separator my-2"></div>
            <!--end::Menu separator-->
            <!--begin::Menu item-->
            <div class="menu-item">
              <div class="menu-content ps-5">
                <a class="menu-link ps-5" @click="signOut()">Signout</a>
              </div>
            </div>
            <!--end::Menu item-->
          </div>
          <!--end::User account menu-->
          <!--end::Menu wrapper-->
        </div>
        <!--end::User menu-->
        <!--begin::Heaeder menu toggle-->
        <!--end::Heaeder menu toggle-->
      </div>
      <!--<p>{{$store.state.userInfo.email}}</p>-->
      <!--end::Toolbar wrapper-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore/lite";
import store from "../store/index";
import { db } from "../firebase/index";
import { signOutExternal, listenToAuthStateAndChanges } from "../js/auth";
import { openProfileStatusListExternal } from "../js/mainheader";
import { countnumberofprofileswhohavedeclinedExternal, fetchCalendarEventsExternal } from "../js/getdata.js";
import SmallCard from "./Cards/SmallCard.vue";
import CardAlert from "./CardAlerts/CardAlert.vue";
import Calendar from "./Cards/EverywhereCardContents/Calendar.vue";

export default {
  data() {
    return {
      initiateFullCalendar: 'wait'
    };
  },
  components: {
    SmallCard,
    CardAlert,
    Calendar,
  },
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
  },
  mounted() {
    //imported from auth.js
    listenToAuthStateAndChanges();
  },
  methods: {
    loadCalendar() {
      this.$store.state.calendarcomponentkey++
      this.initiateFullCalendar = 'go'
      //store.state.userCalendarEvents = false;
      fetchCalendarEventsExternal(this.$store.state.userData.nylasemailtoken)
    },
    resetSearchBar() {
      this.$store.state.searchInputText = "";
    },
    signOut() {
      //auth.js
      signOutExternal();
    },
    openProfileStatusList() {
      openProfileStatusListExternal();
    },
    initializeTemplateModal() {
      this.$store.state.currentEmailTemplate.body = "";
      this.$store.state.currentEmailTemplate.name = "";
      this.$store.state.currentEmailTemplate.id = "";
      //don't know why but the reset only works this way
      setTimeout(() => {
        this.$store.state.currentEmailTemplate = {};
      }, 1000);

      //this switch determines which buttons should be shown in EditTemplates.vue:
      //Either just Save (no existing template loaded), or Update/Save to new (existing template loaded)
      //When the template modal is loaded, make sure only the Save button is displayed
      this.$store.state.existingTemplateLoaded = false;
    },
    showSearchDropdown() {
      $("#searchDropdown").dropdown("show");
      //document.getElementById('searchInput').focus()
    },
    getNumberOfProfilesWhoDeclined() {
      countnumberofprofileswhohavedeclinedExternal();
    },
  },
};
</script>

<style></style>
