<template>
  <!--begin::Title-->
  <div
    class="d-flex flex-wrap gap-2 justify-content-between mb-8"
    style="margin-top: -20px"
  >
    <div class="d-flex align-items-center flex-wrap gap-2">
      <!--begin::Heading-->
      <h2 class="fw-semibold me-3 my-1">
        <span v-if="$store.state.currentEmailMessage">
          {{ $store.state.currentEmailMessage.emailsubject }}
        </span>
        <span
          v-if="
            !$store.state.currentEmailMessage &&
            $store.state.showingProfilesAs == 'profileDetails'
          "
        >
          New email to...
        </span>
        <span
          v-if="
            !$store.state.currentEmailMessage &&
            ($store.state.showingProfilesAs == 'recentProfiles' ||
              $store.state.showingProfilesAs == 'projectProfiles' ||
              $store.state.showingProfilesAs == 'searchResults')
          "
        >
          New email
        </span>
      </h2>
      <!--end::Heading-->
    </div>
  </div>
  <!--end::Title-->
  <!--begin::Message accordion-->
  <div data-kt-inbox-message="message_wrapper">
    <!--begin::Message header-->
    <div
      class="d-flex flex-wrap gap-2 flex-stack"
      data-kt-inbox-message="header"
    >
      <!--begin::Author-->
      <div
        class="d-flex align-items-center"
        v-if="
          $store.state.currentEmailMessage ||
          (!$store.state.currentEmailMessage &&
            $store.state.showingProfilesAs == 'profileDetails')
        "
      >
        <!--begin::Avatar-->
        <div class="symbol symbol-50 me-4">
          <img
            v-if="
              $store.state.currentEmailMessage.emailfrom ==
                $store.state.currentProfileDetails.email ||
              !$store.state.currentEmailMessage
            "
            :src="$store.state.currentProfileDetails.profilepic"
            alt=""
            onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
          />
          <img
            v-if="
              $store.state.currentEmailMessage.emailfrom ==
              $store.state.userData.email
            "
            :src="$store.state.userData.avatarurl"
            alt=""
          />
        </div>
        <!--end::Avatar-->
        <div class="pe-5">
          <!--begin::Author details-->
          <div class="d-flex flex-column gap-1">
            <a
              class="fw-bold text-dark"
              v-if="
                $store.state.currentEmailMessage.emailfrom ==
                  $store.state.currentProfileDetails.email ||
                !$store.state.currentEmailMessage
              "
              >{{ $store.state.currentProfileDetails.firstname }}
              {{ $store.state.currentProfileDetails.lastname }}</a
            >
            <a
              class="fw-bold text-dark"
              v-if="
                $store.state.currentEmailMessage.emailfrom ==
                $store.state.userData.email
              "
              >{{ $store.state.userData.firstname }}
              {{ $store.state.userData.lastname }}</a
            >
            <div class="d-flex" v-if="$store.state.currentEmailMessage">
              <!--begin::Svg Icon | path: icons/duotune/abstract/abs050.svg-->
              <span class="svg-icon svg-icon-7 svg-icon-success me-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
                </svg>
              </span>
              <!--end::Svg Icon-->
              <span class="text-muted fw-bold">{{
                emailDate($store.state.currentEmailMessage.timestamp)
              }}</span>
            </div>
            <div
              v-if="
                !$store.state.currentEmailMessage &&
                $store.state.currentProfileDetails.currentjobtitle &&
                $store.state.currentProfileDetails.currentcompany
              "
            >
              {{
                $store.state.currentProfileDetails.currentjobtitle.length < 30
                  ? $store.state.currentProfileDetails.currentjobtitle
                  : $store.state.currentProfileDetails.currentjobtitle.substring(
                      0,
                      30
                    ) + "..."
              }}
              <br />
              {{
                $store.state.currentProfileDetails.currentcompany.length < 30
                  ? $store.state.currentProfileDetails.currentcompany
                  : $store.state.currentProfileDetails.currentcompany.substring(
                      0,
                      30
                    ) + "..."
              }}
            </div>
            <span class="text-muted" v-if="$store.state.currentEmailMessage">{{
              convertTimestamp($store.state.currentEmailMessage.timestamp)
            }}</span>
          </div>
          <!--end::Author details-->
        </div>
      </div>
      <!--end::Author-->
      <!--begin: number of recipients (in case of batch email)-->
      <div
        style="margin-top: -20px"
        v-if="
          !$store.state.currentEmailMessage &&
          ($store.state.showingProfilesAs == 'recentProfiles' ||
            $store.state.showingProfilesAs == 'projectProfiles' ||
            $store.state.showingProfilesAs == 'searchResults')
        "
      >
        <span class="text-muted"
          >Will be sent to
          <span class="badge badge-circle badge-light-primary me-1 ms-1">{{
            $store.state.selectedProfiles.length -
            $store.state.unauthorisedRecipients
          }}</span
          >selected profile(s)</span
        >
        <CardAlert
          class="mt-6"
          v-if="$store.state.unauthorisedRecipients > 0"
          alerttype="danger"
          title="Warning!"
          :text="
            'You selected ' +
            $store.state.selectedProfiles.length +
            ' profiles, but ' +
            $store.state.unauthorisedRecipients +
            ' of them are either a client or do not have an email. Please note that they will not receive this email.'
          "
        ></CardAlert>
      </div>
      <!--begin: number of recipients (in case of batch email)-->

      <div class="ms-auto d-flex">
        <!--begin::Mark as unread button-->
        <button
          v-if="
            $store.state.currentEmailMessage &&
            $store.state.currentEmailMessage.emailfrom !=
              $store.state.userData.email
          "
          class="btn btn-sm btn-light-primary"
          @click="markAsUnread($store.state.currentEmailMessage)"
        >
          <!--begin::Svg Icon | path: icons/duotune/abstract/abs050.svg-->
          <span class="svg-icon svg-icon-7 svg-icon-success me-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
            </svg>
          </span>
          <!--end::Svg Icon-->
          Mark as Unread
        </button>
        <!--end::Mark as unread button-->

        <!--begin::Attachments button, legacy-->
        <!--this one is legacy, theres another one-->
        <div
          v-if="
            $store.state.currentEmailMessage.attachments &&
            $store.state.currentEmailMessage.attachments.length == 1
          "
          class="d-none dropdown"
        >
          <button
            class="ms-4 btn btn-sm btn-primary"
            type="button"
            id="attachmentsDropdown"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span
              ><!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/communication/com008.svg--><span
                class="svg-icon svg-icon-muted svg-icon-3 me-2"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                    fill="currentColor"
                  ></path></svg></span
              ><!--end::Svg Icon--></span
            >
            {{ $store.state.currentEmailMessage.attachments.length }}
            <span class="ms-2">
              File<span
                v-if="$store.state.currentEmailMessage.attachments.length > 1"
                >s</span
              >
            </span>
            <span class="ms-2 svg-icon svg-icon-4 m-0"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="black"
                ></path></svg
            ></span>
          </button>

          <div
            class="dropdown-menu menu-sub menu-sub-dropdown dropdownFadeIn p-5"
            aria-labelledby="attachmentsDropdown"
            role="menu"
          >
            <div class="d-flex flex-column">
              <!--begin::Button-->
              <button
                class="btn btn-sm btn-light me-3 w-150px mb-3"
                @click="
                  addAsCV(
                    $store.state.currentEmailMessage.attachments[0],
                    $store.state.currentEmailMessage.profileid
                  )
                "
              >
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-06-08-055059/core/html/src/media/icons/duotune/files/fil016.svg-->
                <span class="svg-icon svg-icon-muted svg-icon-2x"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z"
                      fill="currentColor"
                    />
                    <path
                      d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
                <span class="fs-7">Add as CV</span>
              </button>
              <!--end::Button-->
              <!--begin::Button-->
              <a
                class="btn btn-sm btn-light me-3 w-150px mb-3"
                :href="$store.state.currentEmailMessage.attachments[0].url"
                target="_blank"
              >
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-06-08-055059/core/html/src/media/icons/duotune/files/fil017.svg-->
                <span class="svg-icon svg-icon-muted svg-icon-2x"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H13Z"
                      fill="currentColor"
                    />
                    <path
                      d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H8L11.3 17.7C11.7 18.1 12.3 18.1 12.7 17.7L16 14.4H13Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
                <span class="fs-7">Download</span>
              </a>
              <!--end::Button-->
            </div>
          </div>
        </div>
        <!--end::Attachments button, legacy-->

        <!--begin::Attachments button -->

        <div
          v-if="
            $store.state.currentEmailMessage.attachments &&
            $store.state.currentEmailMessage.attachments.length > 0 &&
            $store.state.currentEmailMessage.attachments[0].fileName
          "
          class="dropdown"
        >
          <button
            class="ms-4 btn btn-sm btn-primary"
            type="button"
            id="attachmentsDropdown"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span
              ><!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/communication/com008.svg--><span
                class="svg-icon svg-icon-muted svg-icon-3 me-2"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                    fill="currentColor"
                  ></path></svg></span
              ><!--end::Svg Icon--></span
            >
            {{ $store.state.currentEmailMessage.attachments.length }}
            <span class="ms-2">
              File<span
                v-if="$store.state.currentEmailMessage.attachments.length > 1"
                >s</span
              >
            </span>
            <span class="ms-2 svg-icon svg-icon-4 m-0"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="black"
                ></path></svg
            ></span>
          </button>

          <div
            class="dropdown-menu menu-sub menu-sub-dropdown dropdownFadeIn p-5 w-600px"
            aria-labelledby="attachmentsDropdown"
            role="menu"
          >
            <div
              class="mt-10"
              v-for="email in $store.state.currentEmailMessage.attachments"
              :key="email.timestamp"
            >
              <div class="d-flex align-items-center mb-2">
                <div
                  class="border border-1 border-primary rounded p-2 bg-light"
                >
                  <svg
                    v-if="email.fileName && email.fileName.includes('.doc')"
                    width="35px"
                    height="35px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 468.293 468.293"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #e1e6e9"
                      d="M337.335,0H95.219C84.874,0,76.488,8.386,76.488,18.732v430.829c0,10.345,8.386,18.732,18.732,18.732  H401.17c10.345,0,18.732-8.386,18.732-18.732V82.567L337.335,0z"
                    ></path>
                    <rect
                      x="48.39"
                      y="258.067"
                      style="fill: #27a2db"
                      width="371.512"
                      height="128.3"
                    ></rect>
                    <g>
                      <path
                        style="fill: #ebf0f3"
                        d="M182.722,293.744c7.567,6.85,11.342,16.377,11.342,28.583c0,12.201-3.665,21.861-11.004,28.971   c-7.339,7.115-18.571,10.67-33.687,10.67h-26.056v-78.501h26.952C164.343,283.467,175.164,286.894,182.722,293.744z    M180.702,322.66c0-17.968-10.291-26.952-30.881-26.952h-13.252v53.793h14.714c9.505,0,16.789-2.262,21.843-6.795   C178.179,338.179,180.702,331.498,180.702,322.66z"
                      ></path>
                      <path
                        style="fill: #ebf0f3"
                        d="M276.828,351.129c-7.933,7.75-17.739,11.625-29.419,11.625s-21.486-3.875-29.419-11.625   c-7.942-7.745-11.908-17.406-11.908-28.971c0-11.57,3.966-21.226,11.908-28.976c7.933-7.75,17.739-11.62,29.419-11.62   s21.486,3.87,29.419,11.62c7.942,7.75,11.908,17.406,11.908,28.976C288.736,333.723,284.77,343.383,276.828,351.129z    M267.122,301.997c-5.356-5.538-11.927-8.307-19.713-8.307c-7.787,0-14.358,2.769-19.713,8.307   c-5.346,5.543-8.024,12.26-8.024,20.161s2.678,14.618,8.024,20.156c5.356,5.543,11.927,8.312,19.713,8.312   c7.787,0,14.358-2.769,19.713-8.312c5.346-5.538,8.024-12.256,8.024-20.156S272.469,307.539,267.122,301.997z"
                      ></path>
                      <path
                        style="fill: #ebf0f3"
                        d="M341.296,349.95c4.56,0,8.49-0.763,11.79-2.298c3.29-1.535,6.736-3.989,10.336-7.357l8.527,8.76   c-8.308,9.208-18.397,13.814-30.26,13.814c-11.872,0-21.715-3.82-29.538-11.456c-7.823-7.636-11.735-17.296-11.735-28.976   s3.985-21.409,11.963-29.2c7.969-7.782,18.041-11.675,30.205-11.675s22.327,4.492,30.488,13.476l-8.417,9.208   c-3.747-3.592-7.284-6.1-10.62-7.526c-3.327-1.421-7.238-2.134-11.735-2.134c-7.933,0-14.595,2.568-19.987,7.695   c-5.392,5.127-8.088,11.68-8.088,19.654c0,7.974,2.678,14.636,8.033,19.987C327.615,347.277,333.957,349.95,341.296,349.95z"
                      ></path>
                    </g>
                    <polygon
                      style="fill: #2d93ba"
                      points="48.39,386.364 76.488,412.491 76.488,386.364 "
                    ></polygon>
                    <polygon
                      style="fill: #ebf0f3"
                      points="337.336,82.567 419.902,82.567 337.335,0 "
                    ></polygon>
                    <polygon
                      style="fill: #d5d6db"
                      points="353.221,82.567 419.902,121.255 419.902,82.567 "
                    ></polygon>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <svg
                    v-if="email.fileName && email.fileName.includes('.pdf')"
                    width="35px"
                    height="35px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #e2e5e7"
                      d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
                    ></path>
                    <path
                      style="fill: #b0b7bd"
                      d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
                    ></path>
                    <polygon
                      style="fill: #cad1d8"
                      points="480,224 384,128 480,128 "
                    ></polygon>
                    <path
                      style="fill: #f15642"
                      d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"
                    ></path>
                    <g>
                      <path
                        style="fill: #ffffff"
                        d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48   c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z    M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
                      ></path>
                      <path
                        style="fill: #ffffff"
                        d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296   c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
                      ></path>
                      <path
                        style="fill: #ffffff"
                        d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68   h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912   c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
                      ></path>
                    </g>
                    <path
                      style="fill: #cad1d8"
                      d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
                    ></path>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  {{ email.fileName }}
                </div>
                <!--begin::Button-->
                <button
                  class="ms-auto btn btn-sm btn-light me-3 mb-3"
                  @click="
                    addAsCV(email, $store.state.currentEmailMessage.profileid)
                  "
                >
                  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-06-08-055059/core/html/src/media/icons/duotune/files/fil016.svg-->
                  <span class="svg-icon svg-icon-muted svg-icon-1x"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <span class="fs-9">Add as CV</span>
                </button>
                <!--end::Button-->
                <!--begin::Button-->
                <a
                  class="btn btn-sm btn-light me-3 mb-3"
                  :href="email.url"
                  target="_blank"
                >
                  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-06-08-055059/core/html/src/media/icons/duotune/files/fil017.svg-->
                  <span class="svg-icon svg-icon-muted svg-icon-1x"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H13Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM13 14.4V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V14.4H8L11.3 17.7C11.7 18.1 12.3 18.1 12.7 17.7L16 14.4H13Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <span class="fs-9">Download</span>
                </a>
                <!--end::Button-->
              </div>
            </div>
          </div>
        </div>
        <!--end::Attachments button (2+ attachments)-->

        <!--begin::Delete button-->
        <a
          v-if="$store.state.currentEmailMessage"
          class="ms-4 btn btn-sm btn-light-danger"
          @click="deleteThiEmail()"
        >
          Delete email
          <i class="ms-2 bi bi-trash fs-4"></i
        ></a>
        <!--end::Delete button-->
      </div>
    </div>
    <!--end::Message header-->

    <!--begin::Message content-->

    <div
      v-if="
        $store.state.currentEmailMessage &&
        $store.state.currentEmailMessage.emailfrom ==
          $store.state.currentProfileDetails.email
      "
      class="p-5 rounded border border-secondary mt-10"
      v-html="$store.state.currentEmailMessage.emailbodyhtml"
    ></div>
    <div
      v-if="
        $store.state.currentEmailMessage &&
        $store.state.currentEmailMessage.emailfrom ==
          $store.state.userData.email
      "
      class="p-5 rounded border border-secondary mt-10"
      v-html="$store.state.currentEmailMessage.emailbody"
    ></div>

    <!--end::Message content-->
  </div>
  <!--end::Message accordion-->

  <div
    v-if="
      $store.state.currentEmailMessage.emailfrom != $store.state.userData.email
    "
    class="d-flex align-items-center mt-12"
  >
    <h2 class="fw-semibold" v-if="$store.state.currentEmailMessage">Reply</h2>
    <div class="d-flex"></div>
    <button
      v-if="
        !$store.state.currentEmailMessage &&
        ($store.state.showingProfilesAs == 'recentProfiles' ||
          $store.state.showingProfilesAs == 'projectProfiles' ||
          $store.state.showingProfilesAs == 'searchResults')
      "
      class="btn btn-sm btn-light mt-3"
      @click="insertFirstName()"
    >
      Insert first name
    </button>
    <button
      v-if="
        !$store.state.currentEmailMessage &&
        ($store.state.showingProfilesAs == 'recentProfiles' ||
          $store.state.showingProfilesAs == 'projectProfiles' ||
          $store.state.showingProfilesAs == 'searchResults')
      "
      class="ms-3 me-3 btn btn-sm btn-light mt-3"
      @click="insertLastName()"
    >
      Insert last name
    </button>
    <button
      v-if="
        !$store.state.currentEmailMessage &&
        ($store.state.showingProfilesAs == 'recentProfiles' ||
          $store.state.showingProfilesAs == 'projectProfiles' ||
          $store.state.showingProfilesAs == 'searchResults')
      "
      class="ms-3 me-3 btn btn-sm btn-light mt-3"
      @click="insertGDPRLink()"
    >
      Insert GDPR link
    </button>

    <button
      v-if="
        ($store.state.currentEmailMessage &&
          $store.state.currentEmailMessage.emailfrom !=
            $store.state.userData.email) ||
        !$store.state.currentEmailMessage
      "
      class="btn btn-sm btn-light-primary me-2 ms-auto"
      id="selectTemplateBtn"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Templates
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
      <span class="ms-2 svg-icon svg-icon-4 m-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
            fill="black"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </button>
    <ul
      class="dropdown-menu w-250px w-md-250px py-7 px-7 dropdownFadeIn"
      aria-labelledby="selectTemplateBtn"
      role="menu"
    >
      <!--begin::Items-->
      <div class="scroll-y mh-150px mh-lg-200px">
        <!--begin::Item-->
        <li class="d-flex align-items-center mb-1 py-2 ps-3 pointer">
          <!--begin::Title-->

          <button
            class="btn btn-sm btn-light-primary"
            data-bs-toggle="modal"
            data-bs-target="#editEmailTemplates"
            @click="initializeTemplateModal()"
          >
            <span class="svg-icon svg-icon-primary svg-icon-7"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                  fill="currentColor"
                ></path></svg
            ></span>
            Edit templates
          </button>

          <!--end::Title-->
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li
          v-if="$store.state.emailtemplates.length == 0"
          class="d-flex bg-hover-light align-items-center py-2 ps-3 pointer"
        >
          No templates. Click above to create one.
        </li>
        <!--end::Item-->

        <!--begin::Item-->
        <li
          v-for="template in $store.state.emailtemplates"
          :key="template.id"
          class="d-flex bg-hover-light align-items-center py-2 ps-3 pointer"
        >
          <!--begin::Title-->
          <a
            class="d-flex flex-column"
            href="javascript:void(0)"
            @click="useEmailTemplate(template.id)"
          >
            <span class="fs-6 text-gray-800 fw-bold">{{
              template.name.length < 30
                ? template.name
                : template.name.substring(0, 30) + "..."
            }}</span>
          </a>
          <!--end::Title-->
        </li>
        <!--end::Item-->
      </div>
      <!--end:items-->
    </ul>
    <button
      class="btn btn-sm btn-light-primary"
      data-bs-toggle="modal"
      data-bs-target="#editSignature"
      @click="closeThisModal()"
    >
      Edit signature
    </button>
  </div>

  <!--begin::Form-->
  <form
    id="kt_inbox_reply_form"
    class="rounded border border-secondary mt-5"
    @submit.prevent="sendEmail()"
    v-if="
      $store.state.currentEmailMessage.emailfrom ==
        $store.state.currentProfileDetails.email ||
      !$store.state.currentEmailMessage
    "
  >
    <!--begin::Body-->
    <div class="d-block">
      <!--begin::Subject-->
      <div class="border-bottom">
        <input
          class="form-control border-0 px-8 min-h-45px"
          name="compose_subject"
          placeholder="Subject"
          v-model="$store.state.emailSubject"
        />
      </div>
      <!--end::Subject-->
      <!--begin::Message-->
      <Editor
        api-key="uzj6zx7zdrbapy2mocpl5ykvele0syt6hsz6siw79padif48"
        :init="{
          menubar: false,
          skin: 'snow',
        }"
        v-model="$store.state.emailBody"
      />
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
    <div class="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top">
      <!--begin::Actions-->
      <div class="d-flex align-items-center me-3 ms-auto">
        <!--begin::Upload attachement-->
        <label for="inputeFileForEmail" v-if="!$store.state.fileToSend" class="">
          <span
            class="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable"
            id="kt_inbox_reply_attachments_select"
          >
            <!--begin::Svg Icon | path: icons/duotune/communication/com008.svg-->
            <span class="svg-icon svg-icon-2 m-0">
              <!--begin::Svg Icon | path: icons/duotune/communication/com008.svg-->
              <span class="svg-icon svg-icon-2 m-0">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
              <!--end::Svg Icon-->
            </span>
            <!--end::Svg Icon-->
          </span>
        </label>
        <input
          id="inputeFileForEmail"
          class="d-none"
          type="file"
          @change="handleFileUpload()"
        />

        <span v-if="$store.state.fileToSend" class="me-3 bg-light border rounded pe-3 p-2">
                    <span
            class=""
            id="kt_inbox_reply_attachments_select"
          >
            <!--begin::Svg Icon | path: icons/duotune/communication/com008.svg-->
            <span class="svg-icon svg-icon-2 m-0">
              <!--begin::Svg Icon | path: icons/duotune/communication/com008.svg-->
              <span class="svg-icon svg-icon-2 m-0">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
              <!--end::Svg Icon-->
            </span>
            <!--end::Svg Icon-->
          </span>
          {{ $store.state.fileToSend.name }}
          <a class="ms-2 h-25px mt-1" @click="$store.state.fileToSend = ''" type="button"><i class="bi bi-trash fs-4 text-danger"></i></a>
        </span>

        <!--end::Upload attachement-->

        <!--begin::end-->
        <div class="btn-group">
          <!--begin::Submit-->
          <button class="btn btn-primary btn-sm fs-bold px-6" type="submit">
            Send
          </button>
          <!--end::Submit-->
        </div>
        <!--end::send-->
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Footer-->
  </form>
  <!--end::Form-->
</template>

<script>
import { sendEmailExternal } from "../../../js/profiledetails";
import {
  deleteThiEmailExternal,
  markAsUnreadExternal,
  addAsCVExternal,
  uploadFileExternal,
} from "../../../js/profiledetails";
import CardAlert from "../../CardAlerts/CardAlert.vue";

import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    Editor,
    CardAlert,
  },
  methods: {
    closeThisModal() {
      $("#email").modal("toggle");
    },
    handleFileUpload() {
      this.$store.state.fileToSend = document.getElementById("inputeFileForEmail").files[0];
      console.log("THIS IS THE FILE: ", this.$store.state.fileToSend);
    },
    sendEmail() {
      //TODO1: Problems with Outlook sometimes when batch sending. To avoid this I added a random delay before any email is sent, in
      //the send email function in profiledetails.js. Not ideal as the user could close the page before the loop has finished in profilelist.vue.
      //So if this delay really fixes the Exchange issue, we should move this whole sendemail workflow to the backend (cloud functions).
      //FIXED THIS: by integrating Nylas. doesn't seem to have this problem when sending emails with Nylas.

      //TODO2: if we want to make it possible to read/send emails from the notification center
      //then we need to create a new Vuex property, something like originNotification: false/true
      //otherwise the logic below won't work, because it has 2 ways of sending emails
      //depending on if user is on profileList or profileDetails. And the notifications
      //are available everywhere.
      //we need to do something like:
      // if (this.$store.state.showingProfilesAs == "profileDetails" && !this.$store.state.originNotifcation)...

      //close the modal

      if (!this.$store.state.emailSubject) {
        alert("Please add a subject to your email.");
        return;
      }

      $("#email").modal("toggle");

    

      //send to single profile from profiledetails
      if (this.$store.state.showingProfilesAs == "profileDetails") {
        //we need to pass these elements, even if they are accessible in vuex, because the same function is used for batch emailing,
        //and when sending multiple emails, there could be a sync problem between Firestore and Vuex.
        //so it is possible that a wrong message body is sent to a profile
        sendEmailExternal(
          this.$store.state.currentProfileDetails,
          this.$store.state.emailSubject,
          this.$store.state.emailBody
        );
      }
      //send batch email to selected profiles in projectlist
      else if (
        this.$store.state.showingProfilesAs == "projectProfiles" ||
        this.$store.state.showingProfilesAs == "recentProfiles" ||
        this.$store.state.showingProfilesAs == "searchResults"
      ) {
        //variable to check if it is the first loop iteration, see loop below
        let firstIteration = true;

        //loop through selected profiles
        for (let i = 0; i < this.$store.state.selectedProfiles.length; i++) {
          //for each profile:

          //find profile object in currentProfileList
          let index = this.$store.state.currentProfileList.findIndex(
            (object) => {
              return object.id === this.$store.state.selectedProfiles[i];
            }
          );

          //assign profile object to currentProfileDetails
          this.$store.state.currentProfileDetails =
            this.$store.state.currentProfileList[index];

          //check that the profile is authorised to receive an email (if candidate and has email)
          if (
            this.$store.state.currentProfileDetails.candidateorclient ==
              "Client" ||
            !this.$store.state.currentProfileDetails.email
          ) {
            //do nothing
          } else {
            //replace first and last name in store.state.emailBody.

            //When the email is sent to only one profile in profiledetails, the inserted variables (first and last name) in emailBody
            //are already replaced in the useEmailTemplate() method in this component.
            //but when sending to multiple profiles here, we need to keep {{firstName}}/{{lastName}} like this, so
            //that it can be replaced by each profile's details.
            //To do that, we store the original emailBody (with {{firstname}}... instead of the name of a profile) in another property "emailBodyCache" on the first iteration of this loop,
            //then we refer to that new property "emailBodyCache" for every other iteration, instead of emailBody.
            //Because emailBody changes at the first iteration and we would not have access to {{firstName}}/{{lastName}} on the second iteration

            //perpare personal data for replacements
            let firstname = this.$store.state.currentProfileDetails.firstname;
            let lastname = this.$store.state.currentProfileDetails.lastname;
            let currentjobtitle =
              this.$store.state.currentProfileDetails.currentjobtitle;
            let currentcompany =
              this.$store.state.currentProfileDetails.currentcompany;
            let email = this.$store.state.currentProfileDetails.email;
            let phone = this.$store.state.currentProfileDetails.phone;
            //for the profilepic we need to replace querystring characters as ?, = and &, so that they are not interpreted as querystring characters in the final querystring built
            //they are then decoded in GDPRCandidatePortal-View again to rebuild the original URL.
            let profilepic = this.$store.state.currentProfileDetails.profilepic
              .replace(/\?/g, "XXXXX")
              .replace(/=/g, "YYYYY")
              .replace(/&/g, "ZZZZZ");
            let profileid = this.$store.state.currentProfileDetails.id;

            let gdprcandidatelink =
              this.$store.state.baseUrl +
              "/gdprexternal?firstname=" +
              firstname +
              "&lastname=" +
              lastname +
              "&currentjobtitle=" +
              currentjobtitle +
              "&currentcompany=" +
              currentcompany +
              "&email=" +
              email +
              "&phone=" +
              phone +
              "&profilepic=" +
              profilepic +
              "&profileid=" +
              profileid;

            if (firstIteration == true) {
              firstIteration = false;

              this.$store.state.emailBodyCache = this.$store.state.emailBody;
              this.$store.state.emailBody = this.$store.state.emailBody
                //we don't handle the title case issue here like in usetemplate() for profiledetails. we should...
                .replace(/{{last name}}/g, lastname)
                .replace(/{{first name}}/g, firstname)
                .replace(
                  /{{GDPR candidate link}}/g,
                  '<a href="' +
                    gdprcandidatelink +
                    '">Accept / Decline personal data storage</a>'
                );
            } else {
              this.$store.state.emailBody = this.$store.state.emailBodyCache;
              this.$store.state.emailBody = this.$store.state.emailBody
                .replace(/{{last name}}/g, lastname)
                .replace(/{{first name}}/g, firstname)
                .replace(
                  /{{GDPR candidate link}}/g,
                  '<a href="' +
                    gdprcandidatelink +
                    '" target="_blank">Accept / Decline personal data storage</a>'
                );
            }

            //fire send function for this profile
            sendEmailExternal(
              this.$store.state.currentProfileDetails,
              this.$store.state.emailSubject,
              this.$store.state.emailBody
            );
          }
        }
      }
    },
    convertTimestamp(timestamp) {
      const date = new Date(timestamp);
      const localDate = date.toLocaleString();

      return localDate;
    },
    emailDate(emaildate) {
      const itemDate = new Date(emaildate).getDate();
      const itemMonth = new Date(emaildate).getMonth();
      const itemYear = new Date(emaildate).getFullYear();

      const currentDate = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      if (itemDate == currentDate) {
        return "Today";
      } else if (
        itemDate == currentDate - 1 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "Yesterday";
      } else if (
        itemDate == currentDate - 2 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "2 days ago";
      } else if (
        itemDate == currentDate - 3 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "3 days ago";
      } else {
        const monthName = new Date(emaildate).toLocaleString("en-US", {
          month: "short",
        });
        const finalDate = monthName + ". " + itemDate;

        return finalDate;
      }
    },
    deleteThiEmail() {
      deleteThiEmailExternal();
    },
    initializeTemplateModal() {
      this.$store.state.currentEmailTemplate.body = "";
      this.$store.state.currentEmailTemplate.name = "";
      this.$store.state.currentEmailTemplate.id = "";
      //don't know why but the reset only works this way
      setTimeout(() => {
        this.$store.state.currentEmailTemplate = {};
      }, 1000);

      //this switch determines which buttons should be shown in EditTemplates.vue:
      //Either just Save (no existing template loaded), or Update/Save to new (existing template loaded)
      //When the template modal is loaded, make sure only the Save button is displayed
      this.$store.state.existingTemplateLoaded = false;
    },
    insertFirstName() {
      console.log("1");
      tinyMCE.execCommand("mceInsertContent", false, "{{first name}}");
      console.log("2");
      return false;
    },
    insertLastName() {
      tinyMCE.execCommand("mceInsertContent", false, "{{last name}}");
      return false;
    },
    insertGDPRLink() {
      tinyMCE.execCommand("mceInsertContent", false, "{{GDPR candidate link}}");
      return false;
    },
    useEmailTemplate(templateid) {
      //if click came from the email modal in ProfileDetails.vue
      if (this.$store.state.showingProfilesAs == "profileDetails") {
        //convert first and last names to Title Case for uniformed variable replacement in templates
        //also prepare other data variables for GDPR link
        let firstname = this.$store.state.currentProfileDetails.firstname;
        let lastname = this.$store.state.currentProfileDetails.lastname;
        let currentjobtitle =
          this.$store.state.currentProfileDetails.currentjobtitle;
        let currentcompany =
          this.$store.state.currentProfileDetails.currentcompany;
        let email = this.$store.state.currentProfileDetails.email;
        let phone = this.$store.state.currentProfileDetails.phone;
        let profilepic;
        if (!profilepic) {
          profilepic = "undefined";
        } else {
          profilepic = this.$store.state.currentProfileDetails.profilepic
            .replace(/\?/g, "XXXXX")
            .replace(/=/g, "YYYYY")
            .replace(/&/g, "ZZZZZ");
        }

        let profileid = this.$store.state.currentProfileDetails.id;

        let gdprcandidatelink =
          this.$store.state.baseUrl +
          "/gdprexternal?firstname=" +
          firstname +
          "&lastname=" +
          lastname +
          "&currentjobtitle=" +
          currentjobtitle +
          "&currentcompany=" +
          currentcompany +
          "&email=" +
          email +
          "&phone=" +
          phone +
          "&profilepic=" +
          profilepic +
          "&profileid=" +
          profileid;

        if (firstname.includes(" ")) {
          function toTitleCase(firstname) {
            return firstname
              .toLowerCase()
              .split(" ")
              .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(" ");
          }
          firstname = toTitleCase(firstname);
        } else if (firstname.includes("-")) {
          function toTitleCase(firstname) {
            return firstname
              .toLowerCase()
              .split("-")
              .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join("-");
          }
          firstname = toTitleCase(firstname);
        } else {
          let firstnamearray = firstname.split("");
          firstname =
            firstnamearray[0].toUpperCase() +
            firstnamearray.slice(1).join("").toLowerCase();
        }

        if (lastname.includes(" ")) {
          function toTitleCase(lastname) {
            return lastname
              .toLowerCase()
              .split(" ")
              .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(" ");
          }
          lastname = toTitleCase(lastname);
        } else if (lastname.includes("-")) {
          function toTitleCase(lastname) {
            return lastname
              .toLowerCase()
              .split("-")
              .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join("-");
          }
          lastname = toTitleCase(lastname);
        } else {
          let lastnamearray = lastname.split("");
          lastname =
            lastnamearray[0].toUpperCase() +
            lastnamearray.slice(1).join("").toLowerCase();
        }

        const alltemplates = this.$store.state.emailtemplates;
        let index;
        index = alltemplates.findIndex((object) => {
          return object.id === templateid;
        });
        //if we are on a new email... (New Email button clicked)
        if (!this.$store.state.currentEmailMessage) {
          this.$store.state.emailBody =
            alltemplates[index].body
              .replace(/{{last name}}/g, lastname)
              .replace(/{{first name}}/g, firstname)
              .replace(
                /{{GDPR candidate link}}/g,
                '<a href="' +
                  gdprcandidatelink +
                  '">Accept / Decline personal data storage</a>'
              ) +
            "<p></p>" +
            this.$store.state.userData.emailsignature;
        }
        //if we are on an existing loaded email... (Existing email clicked)
        else if (this.$store.state.currentEmailMessage) {
          this.$store.state.emailBody =
            alltemplates[index].body
              .replace(/{{last name}}/g, lastname)
              .replace(/{{first name}}/g, firstname)
              .replace(
                /{{GDPR candidate link}}/g,
                '<a href="' +
                  gdprcandidatelink +
                  '">Accept / Decline personal data storage</a>'
              ) +
            "<p></p><p></p>" +
            this.$store.state.userData.emailsignature +
            "<br>On " +
            Date(
              this.$store.state.currentEmailMessage.timestamp
            ).toLocaleString("en-US") +
            "<br><" +
            this.$store.state.currentEmailMessage.emailfrom +
            "> wrote: " +
            this.$store.state.currentEmailMessage.emailbodyhtml;
        }
      }
      //if click came from the email modal in ProfileList.vue (batch emails)
      //then we don't replace personal data as it needs to stay {{firstname}}...
      //so that it can be changed for each profile in the sendemail() method
      else {
        const alltemplates = this.$store.state.emailtemplates;
        let index;
        index = alltemplates.findIndex((object) => {
          return object.id === templateid;
        });

        this.$store.state.emailBody =
          alltemplates[index].body +
          "<p></p>" +
          this.$store.state.userData.emailsignature;
      }
    },
    markAsUnread(email) {
      markAsUnreadExternal(email);
    },
    addAsCV(fileobject, profileid) {
      addAsCVExternal(fileobject, profileid);
    },
  },
};
</script>

<style></style>
