import "bootstrap/dist/css/bootstrap.css";
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import InstantSearch from 'vue-instantsearch/vue3/es';
import VueApexCharts from "vue3-apexcharts";

// Create the Vue app instance
const app = createApp(App);

// Define the global modal opening function
app.config.globalProperties.$openModal = function (modalName, title, buttonText) {
    // Close all previously open modals by resetting the modal state
    this.showModal = false;
    this.currentModalComponent = null;
    this.modalTitle = '';
    this.modalButtonText = '';

    // Ensure the UI updates before opening the new modal
    this.$nextTick(() => {
        // Set the current active modal details
        this.currentModalComponent = modalName;
        this.modalTitle = title;
        this.modalButtonText = buttonText;
        
        // Open the new modal
        this.showModal = true;
    });
};

// Use various plugins and mount the app
app.use(store).use(router).use(InstantSearch).use(VueApexCharts).mount('#app');
