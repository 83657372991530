<template>
  <div
    class="toolbar d-flex flex-stack flex-wrap py-2 gap-2 bg-muted border-top"
    style="border-radius: 0;"
    id="kt_toolbarbatch"
  >
    <!--begin::Page title-->

    <!--end::Page title-->
    <!--begin::Actions-->
    <div class="d-flex py-1">
      <p class="me-5 mt-3">
        <span class="badge badge-circle badge-light-primary me-2">{{
          $store.state.selectedProfiles.length
        }}</span>
        profile(s) selected :
      </p>



    <select class="d-none" v-model="industry" @change="batchApplyIndustry">
      <option disabled value="">Please select a category</option>
      <option v-for="(item, index) in items" :key="index" :value="item.label">{{ item.label }}</option>
    </select>
    <p class="d-none">Selected industry: {{ industry }}</p>



      <!--begin: Batch add tags-->
      <div class="dropdown">
        <!--begin::Batch add tag Button-->
        <a
          class="badge badge-sm badge-light-primary mt-3 me-4 dropdown-toggle"
          type="button"
          id="batchaddtag"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
          >Add tag
        </a>
        <!--end::Batch add tag Button-->

        <!--begin::Tag dropdown-->
        <div
          class="
            dropdown-menu
            menu-sub menu-sub-dropdown menu-column
            w-250px
            p-5
            closeontagclick
          "
          aria-labelledby="batchaddtag"
          role="menu"
        >
          <div class="d-flex flex-wrap align-items-start mb-4">
            <form
              @submit.prevent="addNewTag()"
              class="d-flex justify-content-between h-40px"
            >
              <input
                class="form-control w-150px"
                type="text"
                placeholder="New tag"
                v-model="$store.state.tagname"
                id="editProfielTagInput2"
              />
              <button
                class="btn btn-sm btn-icon btn-light-primary ms-5"
                type="submit"
              >
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/arrows/arr085.svg-->
                <span class="svg-icon svg-icon-muted svg-icon-2x"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </button>
            </form>
            <div
              v-for="tag in $store.state.profileTagList"
              :key="tag.timestamp"
            >
              <!--begin::Tag name-->
              <div class="mt-3 me-3">
                <span
                  href="#"
                  class="badge badge-lg bg-hover-secondary badge-light"
                  @click="batchAddProfileTag(tag.tagname)"
                >
                  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr087.svg-->
                  <span class="svg-icon svg-icon-muted svg-icon-3 me-1"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="11"
                        y="18"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11 18)"
                        fill="currentColor"
                      />
                      <rect
                        x="6"
                        y="11"
                        width="12"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      /></svg
                  ></span>
                  <!--end::Svg Icon-->
                  {{ tag.tagname }}
                </span>
              </div>
              <!--end::Tag name-->
            </div>
          </div>
        </div>
        <!--end::Tag dropdown-->
      </div>
      <!--begin: Batch add tags-->

      <!--begin: Batch add tasks-->
      <div class="dropdown">
        <!--begin::Batch add task Button-->
        <a
          class="badge badge-sm badge-light-primary mt-3 me-4 dropdown-toggle"
          type="button"
          id="batchaddtask"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
          @click="loadOrResetTaskDropdown()"
          >Add task
        </a>
        <!--end::Batch add task Button-->

        <!--begin::task dropdown-->
        <div
          class="
            dropdown-menu
            menu-sub menu-sub-dropdown menu-column
            w-300px
            p-5
          "
          id="batchaddtaskdropdown"
          aria-labelledby="batchaddtask"
          role="menu"
        >
          <AddProfileTask origin="batchmanipulatebanner"></AddProfileTask>
        </div>
        <!--end::task dropdown-->
      </div>
      <!--begin: Batch add tasks-->

      <!--begin: Batch add comment-->
      <div class="dropdown">
        <!--begin::Batch add comment Button-->
        <a
          v-if="$store.state.showingProfilesAs == 'projectProfiles'"
          class="badge badge-sm badge-light-primary mt-3 me-4 dropdown-toggle"
          type="button"
          id="batchaddcomment"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
          @click="initializeAddCommentModal()"
          >Add comment
        </a>
        <!--end::Batch add comment Button-->

        <!--begin::comment dropdown-->
        <div
          class="
            dropdown-menu
            menu-sub menu-sub-dropdown menu-column
            w-300px
            p-5
          "
          id="batchaddcommentdropdown"
          aria-labelledby="batchaddcomment"
          role="menu"
        >
          <AddProfileComment></AddProfileComment>
        </div>
        <!--end::comment dropdown-->
      </div>
      <!--begin: Batch add comment-->

      <!--begin::Batch update status (only project profiles)-->
      <StatusDropdown
        origin="batchManipulateBanner"
        elementObject="noNeedForThisHere"
        currentProfileProjectBindingId="noNeedForThisHere"
        currentProfileStatusId="noNeedForThisHere"
        currentProjectId="noNeedForThisHere"
      ></StatusDropdown>
      <!--end::Batch update status-->

      <!--begin::Batch attach to projects-->
      <!--begin::Batch attach to project Button-->
      <div class="dropdown">
        <a
          class="badge badge-sm badge-light-primary mt-3 me-4 dropdown-toggle"
          type="button"
          id="batchattachtoproject"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
          >Attach to project
        </a>
        <ul
          class="dropdown-menu w-300px w-md-350px py-7 px-7 dropdownFadeIn"
          aria-labelledby="batchattachtoproject"
          role="menu"
        >
          <!--begin::Heading-->
          <div class="d-flex flex-stack fw-bold mb-3">
            <!--begin::Label-->
            <span class="text-muted fs-6 me-2">Created by me :</span>
            <!--end::Label-->
          </div>
          <!--end::Heading-->
          <!--begin::Items-->
          <div class="scroll-y mh-150px mh-lg-325px">
            <!--begin::Item-->
            <li
              v-for="project in myActiveProjects"
              :key="project.id"
              @click="batchAttachProfilesToProject(project)"
              class="
                d-flex
                align-items-center
                mb-1
                py-2
                ps-3
                pointer
                latestprofilesitem
              "
            >
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <img :src="project.clientobject.clientlogourl" alt="" />
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <a class="d-flex flex-column" href="javascript:void(0)">
                <span class="fs-6 text-gray-800 fw-bold">{{
                  project.positiontitle
                }}</span>
                <span class="fs-7 text-muted fw-bold">{{
                  project.clientobject.clientname
                }}</span>
              </a>
              <!--end::Title-->
            </li>
            <!--end::Item-->
          </div>
          <!--end:items-->

          <!--begin::Heading-->
          <div class="d-flex flex-stack fw-bold mb-3 mt-3">
            <!--begin::Label-->
            <span class="text-muted fs-6 me-2">Created by colleagues :</span>
            <!--end::Label-->
          </div>
          <!--end::Heading-->
          <!--begin::Items-->
          <div class="scroll-y mh-150px mh-lg-325px">
            <!--begin::Item-->
            <li
              v-for="project in teamActiveProjects"
              :key="project.id"
              @click="batchAttachProfilesToProject(project)"
              class="
                d-flex
                align-items-center
                mb-1
                py-2
                ps-3
                pointer
                latestprofilesitem
              "
            >
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <img :src="project.clientobject.clientlogourl" alt="" />
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <a class="d-flex flex-column" href="javascript:void(0)">
                <span class="fs-6 text-gray-800 fw-bold">{{
                  project.positiontitle
                }}</span>
                <span class="fs-7 text-muted fw-bold">{{
                  project.clientobject.clientname
                }}</span>
              </a>
              <!--end::Title-->
            </li>
            <!--end::Item-->
          </div>
          <!--end:items-->
        </ul>
      </div>
      <!--end::Batch attach to project Button-->
      <!--end::Batch attach to projects-->

      <!--begin::Batch send email-->
      <!--begin: New email button-->
      <a
        v-if="$store.state.userData.nylasemailtoken"
        class="badge badge-sm badge-light-primary mt-3 custompointer"
        style="height: 23px"
        @click="initializeNewEmailModal()"
      >
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr075.svg-->
        <span class="svg-icon svg-icon-primary svg-icon-8"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.5"
              x="11.364"
              y="20.364"
              width="16"
              height="2"
              rx="1"
              transform="rotate(-90 11.364 20.364)"
              fill="currentColor"
            />
            <rect
              x="4.36396"
              y="11.364"
              width="16"
              height="2"
              rx="1"
              fill="currentColor"
            /></svg
        ></span>
        <!--end::Svg Icon-->
        New email
      </a>
      <!--end: New email button-->
      <!--end::Batch send email-->

      <!--begin: Delete profiles button-->
      <a
        class="ms-2 badge badge-sm badge-light-danger mt-3 custompointer"
        style="height: 23px"
        @click="deleteProfiles()"
      >
      Delete profiles
      </a>
      <!--end: Delete profiles button-->

      <div
        class="btn btn-sm btn-icon btn-active-color-primary"
        @click="closeBatchManipulateBanner()"
      >
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg--><span
          class="svg-icon svg-icon-1"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.5"
              x="6"
              y="17.3137"
              width="16"
              height="2"
              rx="1"
              transform="rotate(-45 6 17.3137)"
              fill="currentColor"
            ></rect>
            <rect
              x="7.41422"
              y="6"
              width="16"
              height="2"
              rx="1"
              transform="rotate(45 7.41422 6)"
              fill="currentColor"
            ></rect></svg></span
        ><!--end::Svg Icon-->
      </div>
    </div>
    <!--end::Actions-->
  </div>
</template>

<script>
import StatusDropdown from "./Dropdowns/StatusDropdown.vue";
import AddProfileTask from "./Modals/ModalContents/AddProfileTask.vue";
import AddProfileComment from "./Modals/ModalContents/AddProfileComment.vue";
import {
  batchApplyNewStatusExternal,
  closeBatchManipulateBannerExternal,
  batchAddProfileTagExternal,
  batchApplyProfileIndustry
} from "../js/profilelist";
import { addNewProfileTagExternal } from "../js/crosscomponentfeatures";
import { attachProfileToProjectExternal, deleteProfileExternal } from "../js/profiledetails";

export default {
  components: { StatusDropdown, AddProfileTask, AddProfileComment },
  mounted() {},

  data() {
    return {
      industry: '',
        items: [
  {value: 1, label: 'Aerospace & Defense'},
  {value: 2, label: 'Automotive'},
  {value: 3, label: 'B2B Services'},
  {value: 4, label: 'B2C Services'},
  {value: 5, label: 'Banking / Insurance / Financial Services'},
  {value: 6, label: 'Building Materials'},
  {value: 7, label: 'Construction / Civil Engineering'},
  {value: 8, label: 'Consumer Goods / Apparel / Luxury'},
  {value: 9, label: 'Creative Services'},
  {value: 10, label: 'Education'},
  {value: 11, label: 'Energy & Utilities'},
  {value: 12, label: 'Entertainment & Sports'},
  {value: 13, label: 'Environmental Services'},
  {value: 14, label: 'High-Tech Manufacturing'},
  {value: 15, label: 'Hospital & Health Care'},
  {value: 16, label: 'Human Resources'},
  {value: 17, label: 'Industrial (other)'},
  {value: 18, label: 'Life Science'},
  {value: 19, label: 'Logistics'},
  {value: 20, label: 'Machinery'},
  {value: 21, label: 'Marketing & Advertising'},
  {value: 22, label: 'Media'},
  {value: 23, label: 'NGO & Non profit'},
  {value: 24, label: 'Other'},
  {value: 25, label: 'Packaging'},
  {value: 26, label: 'Plastics'},
  {value: 27, label: 'Professional Services'},
  {value: 28, label: 'Public Administration'},
  {value: 29, label: 'Raw materials'},
  {value: 30, label: 'Real Estate'},
  {value: 31, label: 'Research'},
  {value: 32, label: 'Retail & Wholesale'},
  {value: 33, label: 'Software & Technology'},
  {value: 34, label: 'Telecommunications'},
  {value: 35, label: 'Tourism / Hospitality'},
  {value: 36, label: 'Transportation'},
  {value: 37, label: 'Web & Digital'}
]
    }
  },


  methods: {
    batchApplyIndustry() {
          batchApplyProfileIndustry(this.industry)
          // Add your custom logic here
        },
    batchApplyNewStatus(status) {
      batchApplyNewStatusExternal(status);
    },
    closeBatchManipulateBanner() {
      closeBatchManipulateBannerExternal();
    },
    batchAddProfileTag(tagvalue) {
      batchAddProfileTagExternal(tagvalue);

      //Close the dropdown
      $("#batchaddtag").dropdown("toggle");
    },
    addNewTag() {
      addNewProfileTagExternal(
        this.$store.state.tagname,
        this.$store.state.userData.userid
      );

      $(".closeontagclick").dropdown("hide");
    },
    loadOrResetTaskDropdown() {
      this.$store.state.currentprofiletaskduedate = "";
      this.$store.state.profiletasktext = "";
      this.$store.state.currentprofiletaskassigneduser =
        this.$store.state.userData;
      setTimeout(() => {
        document.getElementById("profileTaskTextarea").focus();
      }, 500);
    },
    initializeAddCommentModal() {
      //this empties the textarea everytime the modal is opened
      this.$store.state.profilecommenttext = "";
      //focus
      document.getElementById("profileCommentTextarea").focus();
    },
    batchAttachProfilesToProject(project) {
      //initialize the counter
      //this counter keeps track of how many profiles already had the project attached,
      //so that the alert shows only once
      this.$store.state.alertCount = 0;

      //loop through each selected profile (each array item is only the ID, not the whole profile object),
      //and fire the function for each profile
      for (let i = 0; i < this.$store.state.selectedProfiles.length; i++) {
        //function located in profiledetails.js
        attachProfileToProjectExternal(
          this.$store.state.selectedProfiles[i],
          project
        );
      }

      //close the dropdown
      $("#batchattachtoproject").dropdown("hide");
    },
    initializeNewEmailModal() {
      //as the click came from the batch send emails feature, first make sure all selected prfofiles 1. are candidates and 2. have an email
      let selectedProfileIds = this.$store.state.selectedProfiles;
      let currentProfileList = this.$store.state.currentProfileList;

      //this will store the number of profiles who can receive an email: those who are CANDIDATES (vs CLIENT) and who HAVE AN EMAIL in their profile
      this.$store.state.unauthorisedRecipients = 0
      
      
      for (let i = 0; i < selectedProfileIds.length; i++) {

        let index = currentProfileList.findIndex((object) => {
          return object.id === selectedProfileIds[i];
        });



        if(currentProfileList[index].candidateorclient == 'Client') {
          //increment the number of unauthorised recipients because one client was found
          this.$store.state.unauthorisedRecipients = this.$store.state.unauthorisedRecipients + 1
        }
        else if (currentProfileList[index].email == "") {
          //increment the number of unauthorised recipients because one profile found who did not have an email
          this.$store.state.unauthorisedRecipients = this.$store.state.unauthorisedRecipients + 1
        }

      }

              if(this.$store.state.unauthorisedRecipients == selectedProfileIds.length) {
          alert("You can not send emails to these profiles as they are either clients or do not have an email in their profile.")
        }
        else {
        $("#email").modal("show")
        }
      



      //Clear previous email in modal, if any
      this.$store.state.currentEmailMessage = "";

      //Clear previous attachments if any
      this.$store.state.fileToSend = "";

      //prepare form
      this.$store.state.emailSubject = "";
      this.$store.state.emailBody =
        "<p></p><p></p>" + this.$store.state.userData.emailsignature;

      console.log(this.$store.state.currentEmailMessage);
    },
    deleteProfiles() {

        const confirmation = window.confirm('Are you sure you want to delete these profiles?');

  if (!confirmation) {
    return; // Exit the function if the user clicks "Cancel"
  }

      for (let i = 0 ; i < this.$store.state.selectedProfiles.length ; i++) {
        deleteProfileExternal(this.$store.state.selectedProfiles[i])
      }
    }
  },
  computed: {
    myActiveProjects() {
      const myProjects = this.$store.state.currentMyProjectList;

      const results = myProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    teamActiveProjects() {
      const teamProjects = this.$store.state.currentTeamProjectList;

      const results = teamProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
  },
};
</script>

<style>
</style>