<template>
  <div>
    <div class="d-flex">
      <button
        class="mx-auto btn btn-sm btn-light-primary mb-20"
        style="margin-top: -20px"
        @click="addExperience()"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.5"
            x="11.364"
            y="20.364"
            width="16"
            height="2"
            rx="1"
            transform="rotate(-90 11.364 20.364)"
            fill="currentColor"
          ></rect>
          <rect
            x="4.36396"
            y="11.364"
            width="16"
            height="2"
            rx="1"
            fill="currentColor"
          ></rect>
        </svg>
        Add current experience
      </button>
    </div>

    <TransitionGroup name="list" tag="div">
      <div
        v-for="experience in $store.state.currentProfileDetails.experiencelist"
        :key="experience.id"
        class="mb-10 ps-5 pe-5"
      >
        <div class="row">
          <div class="col-2 w-100px d-flex mh-40px">
            <i
              class="fa fa-genderless text-primary fs-1 me-3 mt-3"
              aria-hidden="true"
            ></i>

            <input
              :id="experience.id"
              type="text"
              class="form-control fs-7"
              v-model="experience.startyear"
              placeholder="Start"
              @keyup="showSaveBtnExp(experience.id)"
            />
          </div>

          <div
            v-if="experience.endyear != '3000'"
            class="col-2 mw-80px me-5"
            style="margin-left: -10px"
          >
            <input
              type="text"
              class="form-control fs-7"
              v-model="experience.endyear"
              placeholder="End"
              @keyup="showSaveBtnExp(experience.id)"

            />
          </div>
          <div v-else class="col-2 mw-90px me-3" style="margin-left: -10px">
            <input
              id="newEndYear"
              type="text"
              class="form-control fs-7"
              placeholder="Today"
              v-model="newEndYearInputValue"
              @keydown="updateEndYear(experience)"
              @keyup="showSaveBtnExp(experience.id)"

            />
          </div>
          <div class="col-6">
            <div class="row">
              <input
                type="text"
                class="form-control fs-7"
                v-model="experience.company"
                placeholder="(Company)"
                @keyup="showSaveBtnExp(experience.id)"
              />
            </div>
            <div class="row mt-3">
              <input
                type="text"
                class="form-control fs-7 text-muted"
                v-model="experience.title"
                placeholder="(Title)"
                @keyup="showSaveBtnExp(experience.id)"
              />
            </div>
          </div>
          <div class="col-1 d-flex">

            <a
              class="ms-2 h-25px mt-1"
              @click="deleteExperience(experience)"
              type="button"
            >
              <i class="bi bi-trash fs-4 text-danger"></i
            ></a>
            <a
              class="ms-3 h-25px"
              @click="addRowBelow(experience, 'experience')"
              type="button"
            >
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr075.svg--><span
                class="svg-icon svg-icon-primary svg-icon-2"
                ><svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-90 11.364 20.364)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="4.36396"
                    y="11.364"
                    width="16"
                    height="2"
                    rx="1"
                    fill="currentColor"
                  ></rect></svg></span
              ><!--end::Svg Icon-->
            </a>
          </div>
        </div>
        <div
          v-if="showSave && currentExp == experience.id"
          class="d-flex row mt-3 animate__animated animate__fadeIn"
        >
          <div class="col-5"></div>
          <button
            class="col-2 ms-auto btn btn-sm btn-light me-2"
            @click="cancelEdit()"
          >
            Cancel
          </button>
          <button
            class="col-2 btn btn-sm btn-primary me-7"
            @click="saveExperience()"
          >
            Save
          </button>
          <div class="col-1"></div>
        </div>
      </div>
    </TransitionGroup>

    <div class="d-flex">
      <h3 class="mx-auto mt-10">Education</h3>
    </div>

    <div class="d-flex">
      <button
        class="mx-auto btn btn-sm btn-light-primary mt-3"
        @click="addEducation()"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.5"
            x="11.364"
            y="20.364"
            width="16"
            height="2"
            rx="1"
            transform="rotate(-90 11.364 20.364)"
            fill="currentColor"
          ></rect>
          <rect
            x="4.36396"
            y="11.364"
            width="16"
            height="2"
            rx="1"
            fill="currentColor"
          ></rect>
        </svg>
        Add education
      </button>
    </div>


    <TransitionGroup name="list" tag="div">
    <div
      v-for="education in $store.state.currentProfileDetails.educationlist"
      :key="education.id"
      class="mt-10 mb-10 ps-5 pe-5"
    >
      <div class="row">
        <div class="col-2 w-100px d-flex mh-40px">
          <i
            class="fa fa-genderless text-warning fs-1 me-3 mt-3"
            aria-hidden="true"
          ></i>

          <input
            type="text"
            class="form-control fs-7"
            v-model="education.startyear"
            placeholder="Start"
            @keyup="showSaveBtnEdu(education.id)"
          />
        </div>

        <div
          v-if="education.endyear != 'nodatahere'"
          class="col-2 mw-80px me-5"
          style="margin-left: -10px"
        >
          <input
            type="text"
            class="form-control fs-7"
            v-model="education.endyear"
            placeholder="End"
            @keyup="showSaveBtnEdu(education.id)"
          />
        </div>
        <div v-else class="col-2 mw-90px me-3" style="margin-left: -10px">
          <input
            id="emptyEducationEndYear"
            type="text"
            class="form-control fs-7"
            placeholder="End"
            @keyup="showSaveBtnEdu(education.id)"
          />
        </div>
        <div class="col-6">
          <div class="row">
            <input
              type="text"
              class="form-control fs-7"
              v-model="education.school"
              placeholder="(School)"
              @keyup="showSaveBtnEdu(education.id)"
            />
          </div>
          <div class="row mt-3">
            <input
              type="text"
              class="form-control fs-7 text-muted"
              v-model="education.degree_name"
              placeholder="(Degree name)"
              @keyup="showSaveBtnEdu(education.id)"
            />
          </div>
        </div>
        <div class="col-1">
          <a class="ms-4 btn btn-sm btn-icon btn-light-danger"
          @click="deleteEducation(education)"
            ><i class="bi bi-trash fs-4"></i
          ></a>
                      <button
              class="ms-4 mt-4 btn btn-icon btn-sm btn-flex btn-light-primary"
              @click="addRowBelow(education, 'education')"
              type="button"
            >
              <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr075.svg--><span
                class="svg-icon svg-icon-muted svg-icon-2"
                ><svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-90 11.364 20.364)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="4.36396"
                    y="11.364"
                    width="16"
                    height="2"
                    rx="1"
                    fill="currentColor"
                  ></rect></svg></span
              ><!--end::Svg Icon-->
            </button>
        </div>
      </div>
              <div
          v-if="showSave && currentEdu == education.id"
          class="d-flex row mt-3 animate__animated animate__fadeIn"
        >
          <div class="col-5"></div>
          <button
            class="col-2 ms-auto btn btn-sm btn-light me-2"
            @click="cancelEdit()"
          >
            Cancel
          </button>
          <button
            class="col-2 btn btn-sm btn-primary me-7"
            @click="saveEducation()"
          >
            Save
          </button>
          <div class="col-1"></div>
        </div>
    </div>
    </TransitionGroup>
    
  </div>
</template>

<script>
import {
  saveExperienceExternal,
  deleteExpExternal,
  saveEducationExternal,
  deleteEducationExternalFromForm,
  updateEndYearExternal
} from "../../../js/profiledetails";

export default {
  data() {
    return {
      showSave: false,
      currentExp: false,
      currentEdu: false,
      newEndYearInputValue: "Today"
    };
  },
  methods: {
    updateEndYear(expOjbect) {
      this.newEndYearInputValue = ""
      updateEndYearExternal(expOjbect, this.newEndYearInputValue)
    },
    addExperience() {
      this.$store.state.currentProfileDetails.experiencelist.unshift({
        endyear: "",
        startyear: "",
        company: "",
        title: "",
        id: Math.floor(Math.random() * 100000000),
      });
      setTimeout(() => {
        document
          .getElementById(
            this.$store.state.currentProfileDetails.experiencelist[0].id
          )
          .focus();
      }, 100);
    },
        addEducation() {
      this.$store.state.currentProfileDetails.educationlist.unshift({
        endyear: "",
        startyear: "",
        school: "",
        degree_name: "",
        id: Math.floor(Math.random() * 100000000),
      });
      setTimeout(() => {
        document
          .getElementById(
            this.$store.state.currentProfileDetails.educationlist[0].id
          )
          .focus();
      }, 100);
    },
    showSaveBtnExp(id) {
      this.currentExp = id;
      this.showSave = true;
    },
    showSaveBtnEdu(id) {
      this.currentEdu = id;
      this.showSave = true;
    },
    cancelEdit() {
      this.showSave = false;
    },
    saveExperience() {
      saveExperienceExternal();
      this.showSave = false;
    },
    saveEducation() {
      saveEducationExternal();
      this.showSave = false;
    },
    deleteExperience(experience) {
      deleteExpExternal(experience);
    },
    deleteEducation(education) {
      deleteEducationExternalFromForm(education);
    },
    addRowBelow(exporeduitem, type) {

      if(type == "experience") {
      var experienceList = this.$store.state.currentProfileDetails.experiencelist;
      var index = experienceList.findIndex((item) => item.id === exporeduitem.id);
      experienceList.splice(index + 1, 0, {
                "company": "",
                "title": "",
                "id": Math.floor(Math.random() * 100000000),
                "startyear": "",
                "endyear": ""
      });

      }
      else if (type == "education") {
              var educationList = this.$store.state.currentProfileDetails.educationlist;
      var index = educationList.findIndex((item) => item.id === exporeduitem.id);
      educationList.splice(index + 1, 0, {
                "school": "",
                "degree_name": "",
                "id": Math.floor(Math.random() * 100000000),
                "startyear": "",
                "endyear": ""
      });
      }



    },
  },
};
</script>

<style>
</style>