<template>
  <Billing v-if="$store.state.userData.userid && $store.state.userData.subscriptiontype == 'trial'"></Billing>
  <div class="mt-20" v-if="!$store.state.userData.userid">
  <SignInView></SignInView>
  </div>

</template>

<script>
import Billing from '../components/Billing-Component.vue';
import SignInView from '../views/Signin-View.vue';

export default {
     components: {
        Billing,
        SignInView,
     },
     

     
}
</script>

<style>

</style>