<template>

<div class="d-flex justify-content-around mb-5" style="margin-top: -30px;">
        <button 
            v-if="$store.state.existingTemplateLoaded"
            class="btn btn-sm btn-primary mt-3"
            @click="emptyTemplate()"
    >New template</button>

</div>
    <div class="d-flex">
<button class="btn btn-sm btn-light mt-3" @click="insertFirstName()">Insert first name</button>
    <button class="ms-3 me-3 btn btn-sm btn-light mt-3" @click="insertLastName()">Insert last name</button>
    <button class="ms-3 me-3 btn btn-sm btn-light mt-3" @click="insertLastGDPRLink()">Insert GDPR link</button>
    <button
      class="btn btn-sm btn-light-primary me-2 ms-auto"
      id="selectTemplateBtn"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Update an existing template
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
      <span class="ms-2 svg-icon svg-icon-4 m-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
            fill="black"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </button>
    <ul
      class="dropdown-menu w-250px w-md-250px py-7 px-7 dropdownFadeIn"
      aria-labelledby="selectTemplateBtn"
      role="menu"
    >
      <!--begin::Items-->
      <div class="scroll-y mh-150px mh-lg-200px">

        <!--begin::Item-->
        <li
          v-if="!$store.state.emailtemplates || $store.state.emailtemplates.length == 0"
          class="
            d-flex
            align-items-center
            mb-1
            py-2
            ps-3
            pointer
            latestprofilesitem
          "
        >
          <!--begin::Title-->
          <a class="d-flex flex-column" href="javascript:void(0)">
            <span 
              class="fs-6 text-gray-800">No templates yet. Create one below.</span>
          </a>
          <!--end::Title-->
        </li>
        <!--end::Item-->
                <!--begin::Item-->
   
        <!--end::Item-->
        <!--begin::Item-->
        <li
          v-for="template in $store.state.emailtemplates" :key="template.id"
          @click="loadTemplate(template.id)"
          class="
            d-flex
            bg-hover-light
            align-items-center
            py-2 
            ps-3
            pointer
          "
        >
          <!--begin::Title-->
          <a class="d-flex flex-column custompointer"
              
              >
            <span class="fs-6 text-gray-800 fw-bold">{{ template.name.length < 30 ? template.name : template.name.substring(0,30)+'...'}}</span>
          </a>
          <!--end::Title-->
          <div class="ms-auto" @click.stop="deleteTemplate(template.id)"><button class="me-3 btn btn-icon btn-sm btn-light-danger"><i class="bi bi-trash fs-4"></i></button></div>
        </li>
        <!--end::Item-->
      </div>
      <!--end:items-->
    </ul>
    </div>


  <!--begin::Form-->
  <div
    id="kt_inbox_reply_form2"
    class="rounded border border-secondary mt-5"
  >
    <div class="d-block">
      <!--begin::Subject-->
      <div class="border-bottom">
        <input
          class="form-control border-0 px-8 min-h-45px"
          name="compose_templatename"
          placeholder="Add template name"
          v-model="$store.state.currentEmailTemplate.name"
        />
      </div>
      <!--end::Subject-->
      <!--begin::Message-->
      <Editor
        api-key="uzj6zx7zdrbapy2mocpl5ykvele0syt6hsz6siw79padif48"
        :init="{
          menubar: false, 
          skin: 'snow',
        }"
        v-model="$store.state.currentEmailTemplate.body"
      />
    </div>
  </div>
<div class="d-flex justify-content-end">
      
    <button 
            v-if="!$store.state.existingTemplateLoaded"
            class="btn btn-sm btn-primary mt-3"
            @click="saveNewTemplate()"
    >Add template</button>
            <button 
            v-if="$store.state.existingTemplateLoaded"
            class="btn btn-sm btn-light-primary mt-3 me-3"
            @click="saveNewTemplate()"
    >Update template</button>

</div>
</template>

<script>

import Editor from "@tinymce/tinymce-vue";
import { saveNewTemplateExternal } from "../../../js/user";
import { loadTemplateExternal } from "../../../js/user";
import { deleteTemplateExternal } from "../../../js/user";



export default {
    components: {
    Editor,
  },
  methods: {
    insertFirstName() {
      tinyMCE.execCommand('mceInsertContent',false,'{{first name}}');return false;
    },
    insertLastName() {
      tinyMCE.execCommand('mceInsertContent',false,'{{last name}}');return false;
    },
    insertLastGDPRLink() {
      tinyMCE.execCommand("mceInsertContent", false, "{{GDPR candidate link}}");
      return false;
    },
    saveNewTemplate() {
      this.$store.state.existingTemplateLoaded = false
      saveNewTemplateExternal()
    },
    loadTemplate(templateid) {
      
      //show "Update"/"Save as new" buttons
      this.$store.state.existingTemplateLoaded = true
      
      loadTemplateExternal(templateid)
    },
    emptyTemplate() {
          this.$store.state.currentEmailTemplate.body = ""
          this.$store.state.currentEmailTemplate.name = ""

          //show only "Save" button
          this.$store.state.existingTemplateLoaded = false

    },
    deleteTemplate(templateid) {
      deleteTemplateExternal(templateid)
      this.emptyTemplate()
    }
  }

}
</script>

<style>

</style>