<template>
  <hr class="mb-7 text-muted" style="margin-top: -18px" />
  <div class="row">

    <!--begin::Main options & GDPR (left column)-->
    <div class="col" :class="{'col-4': $store.state.showingProfilesAs == 'projectProfiles', 
                   'col-6': $store.state.showingProfilesAs == 'recentProfiles' ||
                    $store.state.showingProfilesAs == 'searchResults'}">
      <div class="mb-3">
        <span class="fw-bold">Main options: </span>
      </div>

      <label
        class="
          form-check form-check-sm form-check-custom form-check-solid
          me-5
          mb-2
        "
      >
        <input class="form-check-input" type="checkbox" v-model="$store.state.addedByMe" />
        <span class="form-check-label">Added by me</span>
      </label>
      <label
        class="
          form-check form-check-sm form-check-custom form-check-solid
          me-5
          mb-2
        "
      >
        <input class="form-check-input" type="checkbox" v-model="$store.state.visibleToClient" />
        <span class="form-check-label">Visible to client</span>
      </label>

      <div class="mb-3 mt-9">
              <label
        class="
          form-check form-check-sm form-check-custom form-check-solid
          me-5
          mb-2
        "
      >
        <input class="form-check-input" type="checkbox" v-model="$store.state.gdprFilterApplied" />
        <span class="fw-bold ms-2">GDPR status </span>
      </label>
      </div>

      <div v-if="$store.state.gdprFilterApplied">
      <label
        class="
          form-check form-check-sm form-check-custom form-check-solid
          me-5
          mb-2
        "
      >
        <input class="form-check-input" type="radio" value="Not requested" v-model="$store.state.gdprFilterValue" />
        <span class="form-check-label">Not requested</span>
      </label>
      <label
        class="
          form-check form-check-sm form-check-custom form-check-solid
          me-5
          mb-2
        "
      >
        <input class="form-check-input" type="radio" value="Pending request" v-model="$store.state.gdprFilterValue" />
        <span class="form-check-label">Pending</span>
      </label>
      <label
        class="
          form-check form-check-sm form-check-custom form-check-solid
          me-5
          mb-2
        "
      >
        <input class="form-check-input" type="radio" value="Consent given" v-model="$store.state.gdprFilterValue" />
        <span class="form-check-label">Granted</span>
      </label>
      <label
        class="
          form-check form-check-sm form-check-custom form-check-solid
          me-5
          mb-2
        "
      >
        <input class="form-check-input" type="radio" value="Consent declined" v-model="$store.state.gdprFilterValue" />
        <span class="form-check-label">Denied</span>
      </label>
      </div>
    </div>
    <!--end::Main options & GDPR (left column)-->

    <!--begin::Tags (center column)-->
    <div class="col" :class="{'col-4': $store.state.showingProfilesAs == 'projectProfiles', 
                   'col-6': $store.state.showingProfilesAs == 'recentProfiles' ||
                    $store.state.showingProfilesAs == 'searchResults'}">

     <div class="mb-3">
        <span class="fw-bold">Tags: </span>
      </div>

      <div v-if="$store.state.profileTagList.length > 0" class="d-flex flex-wrap align-items-start mb-4">
        <div v-for="tag in $store.state.profileTagList" :key="tag.timestamp">
          <!--begin::Tag name-->
          <div class="d-flex">
            <label
              class="
                form-check form-check-sm form-check-custom form-check-solid
                me-2
                mb-2
              "
            >
              <input class="form-check-input" type="checkbox" @click="addTagToProfileFilter(tag.tagname)" value="1" />
            </label>
            <div class="me-3">
              <span
                href="#"
                class="badge badge-sm bg-hover-secondary badge-light"
              >
                {{ tag.tagname }}
              </span>
            </div>
          </div>

          <!--end::Tag name-->
        </div>
      </div>
      <div v-else class="w-125px p-3 bg-light border rounded">
        You haven't created any tags yet
      </div>
      
    </div>
    <!--end::Tags (center column)-->

    <!--begin::Status (right column)-->
    <div class="col" :class="{'col-4': $store.state.showingProfilesAs == 'projectProfiles', 
                   'col-6': $store.state.showingProfilesAs == 'recentProfiles' ||
                    $store.state.showingProfilesAs == 'searchResults'}" v-if="$store.state.showingProfilesAs == 'projectProfiles'">
      <div class="mb-3">
        <span class="fw-bold">Profile status: </span>
      </div>


      <div v-for="status in $store.state.profileStatusList" :key="status.id">
        <div class="d-flex">
          <label
            class="
              form-check form-check-sm form-check-custom form-check-solid
              me-2
              mb-2
            "
          >
            <input class="form-check-input" type="checkbox" value="1" @click="addStatusToProfileFilter(status.id)"/>
          </label>
          <span
            class="badge badge-light mb-3"
            :class="{
              'badge-light-primary': status.category == 'In progress',
              'badge-light-danger': status.category == 'Discarded',
              'badge-light-success': status.category == 'Interested',
            }"
          >
            {{ status.status }}
          </span>
        </div>
      </div>
    </div>
    <!--end::Status (right column)-->



    
  </div>
</template>

<script>
export default {

methods: {
  addTagToProfileFilter(tagname) {
    if(this.$store.state.tagFilterArray.includes(tagname)) {
      
        let index = this.$store.state.tagFilterArray.indexOf(tagname)
        this.$store.state.tagFilterArray.splice(index, 1)

    }
    else {
    this.$store.state.tagFilterArray.push(tagname)
    }
  },
  addStatusToProfileFilter(statusid) {

    if(this.$store.state.statusFilterArray.includes(statusid)) {
      
        let index = this.$store.state.statusFilterArray.indexOf(statusid)
        this.$store.state.statusFilterArray.splice(index, 1)

    }
    else {
    this.$store.state.statusFilterArray.push(statusid)
    }

  }

}

};
</script>

<style>
</style>