import { initializeApp } from "firebase/app";
//import { getFirestore } from 'firebase/firestore'; Changed this to (see below). Otherwise i get the error: "Could not reach Cloud Firestore backend. Backend didn't respond within 10 seconds."
import { initializeFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration

 //Production
 
 /*
const firebaseConfig = {
  apiKey: "AIzaSyAGlsOJFLEcQ1EGkXw2gAiQd5m2XrlZF-4",
  authDomain: "kiwano-3f6de.firebaseapp.com",
  projectId: "kiwano-3f6de",
  storageBucket: "kiwano-3f6de.appspot.com",
  messagingSenderId: "594972142418",
  appId: "1:594972142418:web:46a353d397711452e7288b",
  storageBucket: "kiwano-3f6de"
};
*/


const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};






//Development
/*
const firebaseConfig = {
  apiKey: "AIzaSyB1mxngetkTMOD-2SdxnSO3JD_lh-mq-24",
  authDomain: "kiwano-dev.firebaseapp.com",
  projectId: "kiwano-dev",
  storageBucket: "kiwano-dev.appspot.com",
  messagingSenderId: "198022297396",
  appId: "1:198022297396:web:d5e63319be07edee66d761"
};
*/





const app = initializeApp(firebaseConfig);
//const db = getFirestore(app); Changed this to (see below). Otherwise i get the error: "Could not reach Cloud Firestore backend. Backend didn't respond within 10 seconds."
const db = initializeFirestore(app, { experimentalForceLongPolling: true })


// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

export { db };

