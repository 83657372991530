<template>
  blablabla
  <input type="text" v-model="$store.state.searchterm">
  <button @click="loadClient()">load</button>
  <button @click="execute()">execute</button>

  <div>
    Images
    <div v-for="image in $store.state.imageurls" :key="image">
      <img :src="image" style="width: 100px;" alt="">
    </div>
  </div>
</template>

<script>
          import store from "../store/index";
export default {
  data() {
    return {};
  },

//https://developers.google.com/custom-search/v1/reference/rest/v1/cse/list?hl=en&apix_params=%7B%22cx%22%3A%22b1cf97567f978d10c%22%2C%22hq%22%3A%22logo%20square%22%2C%22q%22%3A%22danone%22%2C%22searchType%22%3A%22image%22%7D&apix=true
  methods: {
    loadClient() {
      gapi.client.setApiKey("AIzaSyAGlsOJFLEcQ1EGkXw2gAiQd5m2XrlZF-4");
      return gapi.client
        .load(
          "https://content.googleapis.com/discovery/v1/apis/customsearch/v1/rest"
        )
        .then(
          function () {
            console.log("GAPI client loaded for API");
          },
          function (err) {
            console.error("Error loading GAPI client for API", err);
          }
        );
    },
    execute() {
      return gapi.client.search.cse
        .list({
      "cx": "b1cf97567f978d10c",
      "hq": "logo",
      "q": store.state.searchterm,
      "searchType": "image",
      "siteSearchFilter": "i"
        })
        .then(
          function (response) {
  
            // Handle the results here (response.result has the parsed body).
            console.log("Response", response);
            console.log(response.result.items[0].link);

            store.state.imageurls.push(response.result.items[0].link);
            store.state.imageurls.push(response.result.items[1].link);
            store.state.imageurls.push(response.result.items[2].link);
           
          },
          function (err) {
            console.error("Execute error", err);
          }
        );
    },
  },

  mounted() {
    gapi.load("client");
  },
};
</script>

<style>
</style>