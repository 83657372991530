<template>


  			<!--begin::Card-->
            <div class="card mb-5 mb-xl-8 boxFadeIn3">

                <ProfileId v-if="($store.state.currentRoute === '/profiles' || $store.state.currentRoute === '/')"></ProfileId>
                <ProjectId v-if="$store.state.currentRoute == '/projects'"></ProjectId>

                          </div>
            <!--end::Card-->
</template>

<script>

import ProfileId from "./ProfileCardContents/ProfileId.vue"
import ProjectId from "./ProjectCardContents/ProjectId.vue"

export default {

    components: {
        ProfileId,
        ProjectId
    }

}
</script>

<style>

</style>