<template>

  <ais-instant-search
    :search-client="searchClient"
    :index-name="'profilesForTeam' + $store.state.userData.teamid"
  >
    <div class="d-flex mb-10">
      <ais-clear-refinements class="mx-auto mb-4">
        <template #default="{ refine }">
          <button
            @click="
              refine();
              deleteSearchString();
              collapseAccordion();
              minExperience = null;
              maxExperience = null;
              searchMade = false;
            "
            class="btn btn-light-danger btn-sm"
            style="margin-top: -30px"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr097.svg-->
            <span
              class="svg-icon svg-icon-danger svg-icon-2"
              style="margin-left: -7px; margin-right: -1px"
              ><svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="9.39844"
                  y="20.7144"
                  width="16"
                  height="2.66667"
                  rx="1.33333"
                  transform="rotate(-45 9.39844 20.7144)"
                  fill="currentColor"
                />
                <rect
                  x="11.2852"
                  y="9.40039"
                  width="16"
                  height="2.66667"
                  rx="1.33333"
                  transform="rotate(45 11.2852 9.40039)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
            Clear Search
          </button>
        </template>
      </ais-clear-refinements>
    </div>
    <!--begin:hidden search box (operated through v-model value in mainheader input)-->
    <ais-search-box
      class="d-none mb-4"
      v-model="$store.state.searchInputText"
      :class-names="{
        'ais-SearchBox-input':
          'search-input form-control form-control-flush ps-10',
      }"
    />
    <!--begin:hidden search box (operated through v-model value in mainheader input)-->

    <div class="row">
      <div
        class="col-5 border border-0 custom-shadow border-secondary rounded p-5 h-440px gradient-bottom"
      >
        <div class="scroll-y mh-375px mh-lg-375px pe-5">
          <div class="d-flex mb-4">
            <h3>Keywords</h3>
          </div>

          <input
            type="text"
            class="form-control mb-10"
            v-model="$store.state.searchInputText"
            @keydown="performSearch"
          />

          <div class="d-flex mb-4">
            <h3>Filters</h3>
          </div>

          <!--begin::Accordion-->
          <div class="accordion" id="kt_accordion_1">
            <ais-state-results>
              <template v-slot="{ state }">
                <div class="accordion-item">
                  <h2
                    class="accordion-header custom-accordion-header"
                    id="kt_accordion_1_header_1"
                  >
                    <button
                      class="accordion-button custom-accordion-button fs-6 fw-semibold collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_1"
                      aria-expanded="true"
                      aria-controls="kt_accordion_1_body_1"
                    >
                      Tags
                      <span
                        class="ms-4 bullet bullet-dot h-6px w-6px bg-primary"
                        v-show="
                          state.disjunctiveFacetsRefinements.tags &&
                          state.disjunctiveFacetsRefinements.tags.length > 0
                        "
                      ></span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_1"
                    class="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_1"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div class="accordion-body">
                      <ais-refinement-list
                        attribute="tags"
                        v-slot="{ items, refine }"
                      >
                          <div class="d-flex flex-column mb-4">
                          <div
                            v-for="item in showAllTagsItems
                              ? items
                              : items.slice(0, 5)"
                            :key="item.value"
                            class="align-items-start"
                          >
                            <!--begin::Tag name-->
                            <div class="d-flex my-1">
                              <label
                                class="form-check form-check-sm form-check-custom form-check-solid me-2 mb-2"
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :value="item.value"
                                  :checked="item.isRefined"
                                  @change="refine(item.value); applyAtLeastOneFilter()"

                                />
                              </label>
                              <div class="me-3 d-flex justify-content-center">
                                <span
                                  href="#"
                                  class="badge badge-sm bg-hover-secondary badge-light"
                                  v-text="item.value"
                                  style="white-space: normal; line-height: 1.5; text-align: left;"
                                ></span>
                              </div>
                            </div>
                            <!--end::Tag name-->
                          </div>
                          <div class="mt-3 w-100 text-center">
                            <button
                              v-if="items.length > 5"
                              class="btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                              @click="toggleShowAllTagsItems"
                            >
                              {{ showAllTagsItems ? "Show less" : "Show more" }}
                            </button>
                          </div>
                        </div>
                      </ais-refinement-list>
                    </div>
                  </div>
                </div>
              </template>
            </ais-state-results>

            <ais-state-results>
              
              <template v-slot="{ state }">
                <div class="accordion-item">
                  <h2
                    class="accordion-header custom-accordion-header"
                    id="kt_accordion_1_header_2"
                  >
                    <button
                      class="accordion-button custom-accordion-button fs-6 fw-semibold collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_2"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_2"
                    >
                      Years of experience
                      <span
                        class="ms-4 bullet bullet-dot h-6px w-6px bg-primary"
                        v-show="minExperience || maxExperience"
                      ></span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_2"
                    class="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_2"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div class="accordion-body">
                      <div class="">
                        <ais-range-input
                          attribute="experiencemetadata.careerYears"
                          :min="0"
                          :max="45"
                        >
                          <template v-slot="{ currentRefinement, refine }">
                            <div class="d-flex">
                              <input
                                placeholder="Min."
                                class="form-control w-50 fs-7"
                                type="number"
                                v-model="minExperience"
                                @input="
                                  refine({
                                    min: $event.target.value,
                                    max: currentRefinement.max,
                                  })
                                "
                              />
                              <input
                                placeholder="Max."
                                class="ms-3 form-control w-50 fs-7"
                                type="number"
                                v-model="maxExperience"
                                @input="
                                  refine({
                                    min: currentRefinement.min,
                                    max: $event.target.value,
                                  })
                                "
                              />
                            </div>
                          </template>
                        </ais-range-input>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </ais-state-results>

            <ais-state-results>
              <template v-slot="{ state }">
                <div class="accordion-item">
                  <h2
                    class="accordion-header custom-accordion-header"
                    id="kt_accordion_1_header_3"
                  >
                    <button
                      class="accordion-button custom-accordion-button fs-6 fw-semibold collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_3"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_3"
                    >
                      Industry
                      <span
                        class="ms-4 bullet bullet-dot h-6px w-6px bg-primary"
                        v-show="
                          state.disjunctiveFacetsRefinements
                            .industrytransformed &&
                          state.disjunctiveFacetsRefinements.industrytransformed
                            .length > 0
                        "
                      ></span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_3"
                    class="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_3"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div class="accordion-body">
                      <ais-refinement-list attribute="industrytransformed" v-slot="{ items, refine }">
                                              <div class="d-flex flex-column mb-4">
                          <div
                            v-for="item in showAllIndustryItems
                              ? items
                              : items.slice(0, 5)"
                            :key="item.value"
                            class="align-items-start"
                          >
                            <!--begin::Industry name-->
                            <div class="d-flex my-1">
                              <label
                                class="form-check form-check-sm form-check-custom form-check-solid me-2 mb-2"
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :value="item.value"
                                  :checked="item.isRefined"
                                  @change="refine(item.value); applyAtLeastOneFilter()"

                                />
                              </label>
                              <div class="me-3 d-flex justify-content-center">
                                <span
                                  href="#"
                                  class="badge badge-sm bg-hover-secondary badge-light"
                                  v-text="item.value"
                                  style="white-space: normal; line-height: 1.5; text-align: left;"
                                ></span>
                              </div>
                            </div>
                            <!--end::Industry name-->
                          </div>
                          <div class="mt-3 w-100 text-center">
                            <button
                              v-if="items.length > 5"
                              class="btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                              @click="toggleShowAllIndustryItems"
                            >
                              {{ showAllIndustryItems ? "Show less" : "Show more" }}
                            </button>
                          </div>
                        </div>
                      </ais-refinement-list>
                    </div>
                  </div>
                </div>
              </template>
            </ais-state-results>

            <ais-state-results>
              <template v-slot="{ state }">
                <div class="accordion-item">
                  <h2
                    class="accordion-header custom-accordion-header"
                    id="kt_accordion_1_header_4"
                  >
                    <button
                      class="accordion-button custom-accordion-button fs-6 fw-semibold collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_4"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_4"
                    >
                      Functions
                      <span
                        class="ms-4 bullet bullet-dot h-6px w-6px bg-primary"
                        v-show="
                          state.disjunctiveFacetsRefinements.functioncategory &&
                          state.disjunctiveFacetsRefinements.functioncategory
                            .length > 0
                        "
                      ></span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_4"
                    class="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_4"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div class="accordion-body">
                      <ais-refinement-list attribute="functioncategory" v-slot="{ items, refine }">
                                                  <div class="d-flex flex-column mb-4">
                          <div
                            v-for="item in showAllFunctionsItems
                              ? items
                              : items.slice(0, 5)"
                            :key="item.value"
                            class="align-items-start"
                          >
                            <!--begin::Tag name-->
                            <div class="d-flex my-1">
                              <label
                                class="form-check form-check-sm form-check-custom form-check-solid me-2 mb-2"
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :value="item.value"
                                  :checked="item.isRefined"
                                  @change="refine(item.value); applyAtLeastOneFilter()"

                                />
                              </label>
                              <div class="me-3 d-flex justify-content-center">
                                <span
                                  href="#"
                                  class="badge badge-sm bg-hover-secondary badge-light"
                                  v-text="item.value"
                                  style="white-space: normal; line-height: 1.5; text-align: left;"
                                ></span>
                              </div>
                            </div>
                            <!--end::Tag name-->
                          </div>
                          <div class="mt-3 w-100 text-center">
                            <button
                              v-if="items.length > 5"
                              class="btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                              @click="toggleShowAllFunctionsItems"
                            >
                              {{ showAllFunctionsItems ? "Show less" : "Show more" }}
                            </button>
                          </div>
                        </div>
                      </ais-refinement-list>
                    </div>
                  </div>
                </div>
              </template>
            </ais-state-results>

            <ais-state-results>
              <template v-slot="{ state }">
                <div class="accordion-item">
                  <h2
                    class="accordion-header custom-accordion-header"
                    id="kt_accordion_1_header_5"
                  >
                    <button
                      class="accordion-button custom-accordion-button fs-6 fw-semibold collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_5"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_5"
                    >
                      My Projects
                      <span
                        class="ms-4 bullet bullet-dot h-6px w-6px bg-primary"
                        v-show="
                          state.disjunctiveFacetsRefinements
                            .projectattachments &&
                          state.disjunctiveFacetsRefinements.projectattachments
                            .length > 0
                        "
                      ></span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_5"
                    class="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_5"
                    data-bs-parent="#kt_accordion_1"
                  >
                  
<div class="accordion-body">
<ais-refinement-list attribute="projectattachments" searchable>
    <template v-slot:searchBox="{ refine }">
        <ais-search-box
            placeholder="Search for projects..."
            :class-names="{
                'ais-SearchBox-input': 'my-custom-input-class',
                'ais-SearchBox-submit': 'my-custom-submit-class',
                'ais-SearchBox-reset': 'my-custom-reset-class',
            }"
            @input="refine"
        />
    </template>
    <template v-slot:item="{ item, refine }">
        <div class="align-items-start">
            <!--begin::Project name-->
            <div class="d-flex my-1">
                <label
                    class="form-check form-check-sm form-check-custom form-check-solid me-2 mb-2"
                >
                    <input
                        class="form-check-input"
                        type="checkbox"
                        :value="item.value"
                        :checked="item.isRefined"
                        @change="refine(item.value); applyAtLeastOneFilter()"
                    />
                </label>
                <div class="me-3 d-flex justify-content-center">
                    <span
                        href="#"
                        class="badge badge-sm bg-hover-secondary badge-light"
                        v-text="item.value"
                    ></span>
                </div>
            </div>
            <!--end::Project name-->
        </div>
    </template>
</ais-refinement-list>
        </div>
                  </div>
                </div>
              </template>
            </ais-state-results>
          </div>
          <!--end::Accordion-->
        </div>
      </div>

      <div class="col-6 ms-5 p-5 h-400px">
        <h3 class="mb-4">
          <span v-if="searchMade || minExperience || maxExperience" class="badge badge-sm badge-circle badge-primary me-2">{{$store.state.searchResults.length}}</span>
          Results</h3>
          <div class="mb-4" v-if="(searchMade || minExperience || maxExperience) && $store.state.searchResults.length == 100">
          <span class="text-danger fs-8">We found over 100 results for this search. But Kiwano only returns the first 100 results. Try refining your search.</span>
          </div>
        <!--begin:results-->
        <ais-hits
          v-if="(searchMade || minExperience || maxExperience) && transformItems.length > 0"
          :transform-items="transformItems"
        >
          <template v-slot:item="{ item }">
            <div
              class="container bg-hover-light"
              style="margin-top: -18px; margin-bottom: -18px"
            >
              <!-- Begin result row (profile row) -->
              <div
                class="row justify-content-between py-2"
                @click="goToDetailedProfile(item.objectID)"
                @click.stop=""
              >
                <!-- Begin profile info (pic, name, title & company) -->
                <div class="d-flex align-items-center">
                  <div
                    class="symbol symbol-40px me-5"
                    style="margin-left: -27px"
                  >
                    <img
                      alt="Pic"
                      v-if="item.profilepic"
                      :src="item.profilepic"
                      onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                    />
                    <img
                      alt="Pic"
                      v-if="!item.profilepic"
                      src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                    />
                  </div>
                  <div class="d-flex justify-content-start flex-column">
                    <a class="text-dark fw-bolder fs-8">{{
                      item.firstname + " " + item.lastname.toUpperCase()
                    }}</a>
                    <span
                      v-if="item.currentjobtitle"
                      class="text-muted fw-bold d-block fs-8"
                      >{{
                        item.currentjobtitle.length < 20
                          ? item.currentjobtitle
                          : item.currentjobtitle.substring(0, 20) + "..."
                      }}</span
                    >
                    <span
                      v-if="item.currentcompany"
                      class="text-muted fw-bold d-block fs-8"
                      >{{
                        item.currentcompany.length < 20
                          ? item.currentcompany
                          : item.currentcompany.substring(0, 20) + "..."
                      }}</span
                    >
                  </div>
                </div>
                <!-- End profile info (pic, name, title & company) -->
              </div>
              <!-- End result row (profile row) -->
            </div>
          </template>
        </ais-hits>
        <!--end:results-->
        <!--begin:See All results Button-->
        <div class="d-flex justify-content-center flex-nowrap mt-6"  v-if="(searchMade || minExperience || maxExperience) && transformItems.length > 0">
          <button
            class="btn btn-sm btn-primary"
            v-if="
              $store.state.searchResults.length > 3 && origin != 'mobileversion'
            "
            @click="sendResultsToStore()"
          >
            See all results
          </button>
        </div>
        <!--end:See All results Button-->

        <div v-if="!searchMade && !minExperience && !maxExperience">
                    <CardAlert
            class="mt-6"
            alerttype="info"
            title="No results yet."
            :text="'Begin your search by entering criteria on the left.'"
          ></CardAlert>
   
        </div>

        <!--begin:"Nothing found" Alert-->
        <div
          v-if="$store.state.searchResults == 0 && origin == 'desktopversion'"
          style="width: 460px !important"
        >
          <CardAlert
            class="mt-6"
            alerttype="info"
            title="No profiles found"
            :text="'Try something else !'"
          ></CardAlert>
        </div>
        <div
          v-if="$store.state.searchResults == 0 && origin != 'desktopversion'"
        >
          <CardAlert
            class="mt-6"
            alerttype="info"
            title="No profiles found"
            :text="'Try something else !'"
          ></CardAlert>
        </div>
        <!--end:"Nothing found" Alert-->
      </div>
    </div>

    <div class="mt-4"></div>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite-min.css";
import { goToDetailedProfileExternal } from "../../../js/navigation";
import { getSearchResultsExternal } from "../../../js/getdata";
import { createWidgetMixin } from "vue-instantsearch/vue3/es";
import { connectMenu } from "instantsearch.js/es/connectors";
import { connectHits } from "instantsearch.js/es/connectors";
import store from "../../../store/index";
import CardAlert from "../../CardAlerts/CardAlert.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { AisClearRefinements } from "vue-instantsearch/vue3/es";

export default {
  props: ["origin"],
  components: {
    CardAlert,
    VueSlider,
    AisClearRefinements,
  },
  mounted() {},
  data() {
    return {
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APPID,
        this.$store.state.userData.algoliasearchkey
      ),
      inputtext: "",
      showAllProjectItems: false,
      showAllIndustryItems: false,
      showAllFunctionsItems: false,
      showAllTagsItems: false,
      totalRange: { min: 1, max: 45 },
      minExperience: null,
      maxExperience: null,
      searchMade: false,
    };
  },
  methods: {
    performSearch() {
      this.searchMade = true;
    },
    performRefinement() {
      alert("hello");
      this.searchMade = true;
    },
    isRangeFiltered(state) {
      if (state.numericRefinements["experiencemetadata.careerYears"]) {
        let minRefinement = state.numericRefinements[
          "experiencemetadata.careerYears"
        ][">="]
          ? state.numericRefinements["experiencemetadata.careerYears"][">="][0]
          : null;
        let maxRefinement = state.numericRefinements[
          "experiencemetadata.careerYears"
        ]["<="]
          ? state.numericRefinements["experiencemetadata.careerYears"]["<="][0]
          : null;
        return !(
          minRefinement == this.totalRange.min &&
          maxRefinement == this.totalRange.max
        );
      }
      return false;
    },
    deleteSearchString() {
      this.$store.state.searchInputText = "";
    },
    collapseAccordion() {
      $(".collapse").collapse("hide");
    },
    getRefinementLabel(projectId) {
      const project = this.$store.state.currentMyProjectList.find(
        (project) => project.id === projectId
      );
      if (project) {
        const clientName = project.clientobject.clientname;
        const positionTitle = project.positiontitle;

        const truncatedClientName =
          clientName.length > 12
            ? clientName.substring(0, 12) + "..."
            : clientName;
        const truncatedPositionTitle =
          positionTitle.length > 12
            ? positionTitle.substring(0, 12) + "..."
            : positionTitle;
        return `${truncatedClientName}: ${truncatedPositionTitle}`;
      }
      return "";
    },
    toggleShowAllProjectItems() {
      this.showAllProjectItems = !this.showAllProjectItems;
    },
    toggleShowAllIndustryItems() {
      this.showAllIndustryItems = !this.showAllIndustryItems;
    },
    toggleShowAllTagsItems() {
      this.showAllTagsItems = !this.showAllTagsItems;
    },
    toggleShowAllFunctionsItems() {
      this.showAllFunctionsItems = !this.showAllFunctionsItems;
    },
    applyAtLeastOneFilter() {
      //used to determine if the results should be shown or not. by default they are always shown, so we need to v-if render the results only when a filter is applied or when a search term has been entered
      this.searchMade = true;
    },
    

    toValue(value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max,
      ];
    },

    transformItems(items) {
      store.state.searchResults = [];

      for (let i = 0; i < items.length; i++) {
        let profile = items[i];
        profile.id = items[i].objectID;
        profile.statusorder = 0;
        if (
          profile.owninguser == store.state.userData.userId ||
          profile.teamaccess
        ) {
          store.state.searchResults.push(profile);
        }
      }

      const filteredItems = items.filter((item) => {
        return (
          item.owninguser === store.state.userData.userId || item.teamaccess
        );
      });

      return filteredItems.slice(0, 4);
    },
    sendResultsToStore() {
      
      store.state.searchOn = true;

      //in case the SelectAll checkbox was checked, uncheck it
      if(document.getElementById("selectAllBtn")) {
        document.getElementById("selectAllBtn").checked = false;
      }

      //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
      store.state.selectedProfiles = [];

      getSearchResultsExternal();

      $('#advancedsearch').modal('toggle')

    },

    storeSelectedProfile(profileid) {
      alert(profileid);
    },
    goToDetailedProfile(profileId) {
      $("#searchDropdown").dropdown("hide");

      goToDetailedProfileExternal(profileId);
    },
  },
};
</script>

<style>
</style>




