<template>
                  <div class="mb-6">

                  <!--begin::Tags-->


                  <input
                    id="profiletagelement"
                    class="border border-secondary ps-2 border-2 border-dashed rounded "
                    v-model="$store.state.currentProfileDetails.tags"
                    type="text"
                    name="tags-disabled-user-input"
                    placeholder="Click here to add tags"
                  >
                </div>

                <!--end::Tags-->
</template>

<script>

import Tagify from "@yaireo/tagify";
import { addProfileTagExternal } from "../../../js/profiledetails"
import { deleteProfileTagExternal } from "../../../js/profiledetails"


export default {
      data() {
    return {

    };
  },
  mounted() {



    const profileid = this.$store.state.currentProfileDetails.id

        var input = document.getElementById("profiletagelement");
    var tagify = new Tagify(input, {
      whitelist: this.profileTagNames,
      userInput: false,
      enforceWhitelist: true,
      dropdown: {
        maxItems: 30, // <- mixumum allowed rendered suggestions
        classname: "tagify__inline__suggestions", // <- custom classname for this dropdown, so it could be targeted
        enabled: 0, // <- show suggestions on focus
        closeOnSelect: true, // <- do not hide the suggestions dropdown once an item has been selected
      },
    });
    tagify.on("add", function (e) {
      addProfileTagExternal(e.detail.data.value, profileid)
    });
    tagify.on("remove", function (e) {
      deleteProfileTagExternal(e.detail.data.value, profileid)
    });
  },
  computed: {
    profileTagNames() {
      const tagNames = [];

      for(let i = 0; i < this.$store.state.profileTagList.length; i++) {
        tagNames.push(this.$store.state.profileTagList[i].tagname)
      }
      return tagNames
    }
  }


}
</script>

<style>

</style>