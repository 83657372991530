<template>


<!--begin::Row-->
            <div class="row mb-8">
                <!--begin::Col-->
                <div class="col-xl-4">
                    <div class="fs-6 text-muted fw-bold mt-2 mb-3">Progress report</div>
                </div>
                <!--end::Col-->

                <!--begin::Col-->
                <div class="col-xl-8">
                      <div>
    <button class="btn btn-sm btn-light" @click="goToProgressReport($store.state.currentProjectDetails.id)">
                <svg
          
          width="30px"
          height="30px"
          class="me-3"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
        >
          <path
            style="fill: #e2e5e7"
            d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
          />
          <path
            style="fill: #b0b7bd"
            d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
          />
          <polygon style="fill: #cad1d8" points="480,224 384,128 480,128 " />
          <path
            style="fill: #84bd5a"
            d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"
          />
          <g>
            <path
              style="fill: #ffffff"
              d="M144.336,326.192l22.256-27.888c6.656-8.704,19.584,2.416,12.288,10.736   c-7.664,9.088-15.728,18.944-23.408,29.04l26.096,32.496c7.04,9.6-7.024,18.8-13.936,9.328l-23.552-30.192l-23.152,30.848   c-6.528,9.328-20.992-1.152-13.696-9.856l25.712-32.624c-8.064-10.112-15.872-19.952-23.664-29.04   c-8.048-9.6,6.912-19.44,12.8-10.464L144.336,326.192z"
            />
            <path
              style="fill: #ffffff"
              d="M197.36,303.152c0-4.224,3.584-7.808,8.064-7.808c4.096,0,7.552,3.6,7.552,7.808v64.096h34.8   c12.528,0,12.8,16.752,0,16.752H205.44c-4.48,0-8.064-3.184-8.064-7.792v-73.056H197.36z"
            />
            <path
              style="fill: #ffffff"
              d="M272.032,314.672c2.944-24.832,40.416-29.296,58.08-15.728c8.704,7.024-0.512,18.16-8.192,12.528   c-9.472-6-30.96-8.816-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,43.008c-0.896,32.496-47.968,33.248-65.632,18.672   c-4.24-3.456-4.096-9.072-1.792-12.544c3.328-3.312,7.024-4.464,11.392-0.88c10.48,7.152,37.488,12.528,39.392-5.648   C321.28,339.632,268.064,351.008,272.032,314.672z"
            />
          </g>
          <path
            style="fill: #cad1d8"
            d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
          />
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
        Generate</button>
  </div>
                </div>
                <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-8">
                <!--begin::Col-->
                <div class="col-xl-3">
                    <div class="fs-6 text-muted fw-bold mt-2 mb-3">Client Portal</div>
                </div>
                <!--end::Col-->

                <!--begin::Col-->
                <div class="col-xl-8">
                    <!--begin::Row-->
                    <div class="row ms-9">
                        <!--begin::Col-->
                       
                            <label class="btn border border-success border-dashed d-flex text-start p-6"
                            v-if="$store.state.currentProjectDetails.portalactive">
   
                                <span class="ms-2">
                                    <span class="fs-4 fw-bold mb-1 d-block text-success">Active</span>
                                    <span class="fw-semibold fs-7 text-gray-600">
                                        Profiles from this project shared with client. <br> <button
                                            data-bs-toggle="modal"
    data-bs-target="#manageportalmodal"
    @click="loadProfilesForThisProject()"
                                         class="mt-3 btn btn-sm btn-light-success">
                                            <span class="svg-icon svg-icon-muted svg-icon-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path><path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path></svg></span>
                                            Edit</button>
                                    </span>
                                </span>
                            </label>
                        
                        <!--end::Col-->
                    </div>
                    <!--end::Row-->

                    <!--begin::Row-->
                    <div class="row ms-9">
                        <!--begin::Col-->
                       
                            <label class="btn border border-danger border-dashed d-flex text-start p-6"
                            v-if="!$store.state.currentProjectDetails.portalactive">
   
                                <span class="ms-2">
                                    <span class="fs-4 fw-bold mb-1 d-block text-danger">Not active</span>
                                    <span class="fw-semibold fs-7 text-gray-600">
                                        Project not shared <br> with client. <br> <button
                                                                                    data-bs-toggle="modal"
    data-bs-target="#manageportalmodal"
    @click="loadProfilesForThisProject()"
                                         class="mt-3 btn btn-sm btn-light-danger">
                                            <span class="svg-icon svg-icon-muted svg-icon-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path><path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path></svg></span>
                                            Edit</button>
                                    </span>
                                </span>
                            </label>
                        
                        <!--end::Col-->
                    </div>
                    <!--end::Row-->

                </div>
                <!--end::Col-->
            </div>
            <!--end::Row-->

</template>

<script>

import { getProfilesForThisProjectExternal } from "../../../js/getdata";


export default {

methods: {
    goToProgressReport(projectid) {
        this.$router.push('/profiles');


      //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
      this.$store.state.selectedProfiles = [];

      getProfilesForThisProjectExternal(projectid);

     $('#generatePR').modal('show');

    },
    loadProfilesForThisProject() {
      document.getElementById("tab1Link").click();
      getProfilesForThisProjectExternal(this.$store.state.currentProjectDetails.id);
    }
}

}
</script>

<style>

</style>