<template>
  <!--begin::Tag list-->

  <div>
    <form
      @submit.prevent="addNewTag()"
      class="d-flex justify-content-between h-40px mt-20 mb-10"
    >
      <input
        class="form-control w-300px"
        type="text"
        placeholder="Add a tag"
        v-model="$store.state.tagname"
        id="editProfielTagInput"
      />
      <button class="btn btn-sm btn-icon btn-primary ms-5" type="submit">
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/arrows/arr085.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-2x"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </button>
    </form>

    <CardAlert
      alerttype="info"
      title="Important!"
      text="The Taglist is common to all team members. You can add a maximum of 30 tags."
    ></CardAlert>

    <!--begin::column headers-->
    <div class="d-flex justify-content-between mt-5 border-bottom py-2 px-8 h3">
      <span>Tag</span>
      <span>Added by</span>
    </div>
    <!--end::column headers-->

    <!--begin: Vue list animation-->
    <TransitionGroup name="list" tag="div" v-if="$store.state.profileTagList">
      <!--begin: Tag list-->
      <div
        class="d-flex align-items-start mb-4 mt-10 px-8"
        v-for="tag in sortedTagList"
        :key="tag.id"
      >
        <!--begin::Tag name-->
        <div class="flex-grow-1">
          <span href="#" class="badge badge-lg badge-light"
            >{{ tag.tagname }}

            <!--begin::Svg Icon (delete btn) | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr011.svg-->
            <span
              class="svg-icon svg-icon-muted ms-3 svg-icon-xs custompointer mt-1"
              @click="deleteTag(tag.id)"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon (delete btn)-->
          </span>
        </div>
        <!--end::Tag name-->

        <!--begin: User who added-->
        <div
          v-if="userAvatar(tag.addedbyuser)"
          class="symbol symbol-circle symbol-25px"
        >
          <span class="me-2 fs-7 text-muted">{{
            userName(tag.addedbyuser)
          }}</span>
          <img :src="userAvatar(tag.addedbyuser)" alt="img" />
        </div>
        <div
          v-if="!userAvatar(tag.addedbyuser)"
          class="symbol symbol-circle symbol-25px"
        >
          <span class="me-2 fs-7 text-muted">{{
            userName(tag.addedbyuser)
          }}</span>
          <img src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
 alt="img" />
        </div>
        <!--end: User who added-->
      </div>
      <!--end: Tag list-->
    </TransitionGroup>
    <!--end: Vue list animation-->
  </div>
  <!--end:Tag list-->
</template>

<script>
import CardAlert from "../../CardAlerts/CardAlert.vue";
import { addNewProfileTagExternal } from "../../../js/crosscomponentfeatures";
import { deleteTagExternal } from "../../../js/crosscomponentfeatures";

export default {
  data() {
    return {};
  },
  components: { CardAlert },
  methods: {
    addNewTag() {
      //warning > 30 tags in this team
      if (this.$store.state.profileTagList.length == 30) {
        alert(
          "You have added 30 tags already. Please delete at least one tag to add a new one."
        );
        return;
      }

      addNewProfileTagExternal(
        this.$store.state.tagname,
        this.$store.state.userData.userid
      );
    },
    deleteTag(tagid) {
      deleteTagExternal(tagid);
    },
    userAvatar(userid) {
      const arr = this.$store.state.teammembers;

      const index = arr.map((object) => object.userid).indexOf(userid);

      //using "?" here solves a problem: used to get an error "cannot access property of undefined"
      return arr[index]?.avatarurl;
    },
    userName(userid) {
      const arr = this.$store.state.teammembers;

      const index = arr.findIndex((item) => item.userid === userid);

      //using "?" here solves a problem: used to get an error "cannot access property of undefined"
      const firstname = arr[index]?.firstname;

      return firstname;
    },
  },
  computed: {
    sortedTagList() {
      const tagList = this.$store.state.profileTagList;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      tagList.sort(compare);

      return tagList;
    },
  },
};
</script>

<style>
</style>