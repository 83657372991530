
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  connectFirestoreEmulator,
  collectionGroup,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  increment,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db } from "../firebase/index";

import store from "../store/index";

import { getProfilesForThisProjectExternal } from "./getdata";

import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import axios from "axios";

import { getFunctions, httpsCallable } from "firebase/functions";

import { getSearchResultsExternal } from "./getdata";


const updateProjectStatusExternal = (statusname, elementObject) => {
  let projectRef = doc(db, "projects", elementObject.id);
  updateDoc(projectRef, {
    status: statusname,
  }).then(() => {
    if (store.state.showingProjectsAs == "projectDetails") {
      store.state.currentProjectDetails.status = statusname;

      //Confirmation toast
      iziToast.show({
        title: "Project status updated !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    }
  });

  $("#buttoninprojectDetailsCard" + elementObject.id).dropdown("toggle");
};

const updateProjectTeamAccessExternal = (teamaccess) => {
  let projectRef = doc(db, "projects", store.state.currentProjectDetails.id);
  updateDoc(projectRef, {
    teamaccess: teamaccess,
  }).then(() => {
    store.state.currentProjectDetails.teamaccess = teamaccess;

    //Confirmation toast
    iziToast.show({
      title: "Team access updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });

  $("#updateTeamAccess" + store.state.currentProjectDetails.id).dropdown(
    "toggle"
  );
};

const saveProjectInfoExternal = () => {
  if (!store.state.currentProjectDetails.positiontitle) {
    alert("You must add a job title!");
    return;
  }

  let projectRef = doc(db, "projects", store.state.currentProjectDetails.id);
  updateDoc(projectRef, {
    positiontitle: store.state.currentProjectDetails.positiontitle,
    location: store.state.currentProjectDetails.location,
  }).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Project info updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });

  $("#editprojectandclientinfo").modal("toggle");
};

const getRecentCandidateProfilesExternal = () => {
  store.state.profilesInProjectForLatestProfilesFeature = [];

  const qLatestProfiles = query(
    collectionGroup(db, "profileprojectbindings"),
    where("projectid", "==", store.state.currentProjectDetails.id)
  );

  getDocs(qLatestProfiles).then((bindingSnapshots) => {
    bindingSnapshots.forEach((doc) => {
      getDoc(doc.ref.parent.parent).then((profileSnap) => {
        let profile = profileSnap.data();
        profile.id = profileSnap.id;

        store.state.profilesInProjectForLatestProfilesFeature.push(profile);
      });
    });
  });
};

const saveProjectNotesExternal = () => {
  let projectRef = doc(db, "projects", store.state.currentProjectDetails.id);
  updateDoc(projectRef, {
    notes: store.state.currentProjectDetails.notes,
  }).then(() => {
    $("#addprojectorclientnotes").modal("toggle");

    //Confirmation toast
    iziToast.show({
      title: "Project notes updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

const uploadProjectFileExternal = async () => {
  let file;
  //if the call came from the AIAsistant card, then launch AI flow

  file = document.getElementById("uploadProjectFile").files[0];

  const filenameParts = file.name.split(".");
  const filetype = filenameParts[filenameParts.length - 1];

  if (
    filetype !== "doc" &&
    filetype !== "docx" &&
    filetype !== "pdf" &&
    filetype !== "xls" &&
    filetype !== "xlsx" &&
    filetype !== "ppt" &&
    filetype !== "pptx"
  ) {
    alert(
      "This file type is not supported. Please upload one of the following file types: Word, Excel, CSV, PowerPoint, or PDF."
    );
    return;
  }

  console.log("IN PROGRESS...");

  $("#uploadingfilealert").modal("show");

  const storage = getStorage();
  const storageRef = ref(
    storage,
    "userid" +
      store.state.userData.userid +
      "fileforproject" +
      store.state.currentProjectDetails.id +
      file.name
  );

  await uploadBytes(storageRef, file).then(async (snapshot) => {
    console.log("Uploaded a blob or file!");
    store.state.aiprogressstep = 2;

    const url = await getDownloadURL(storageRef);

    const projectRef = doc(
      db,
      "projects",
      store.state.currentProjectDetails.id
    );

    await updateDoc(projectRef, {
      files: arrayUnion({
        name: file.name,
        url: url,
        type: filetype,
        id: Math.floor(Math.random() * 100000000),
      }),
    });

    console.log("DONE... URL: " + url);

    iziToast.show({
      title: "File uploaded !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    setTimeout(() => {
      $("#uploadingfilealert").modal("hide");
    }, 900);
  });
};

const aiSearchExternal = async () => {
  //Clear previous progress state if any
  store.state.aiprogressstep = "off";

  //Reset the "aisearchresults" property of the Project doc in firestore
  //So that when the user uploads a new job description, the previous matching profiles are deleted
  const checkAndResetAISearchResults = async () => {

    const projectDocRef = doc(db, "projects", store.state.currentProjectDetails.id);
  
    // Fetch the document
    const docSnapshot = await getDoc(projectDocRef);
  
    // Check if the document exists
    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();
  
      // Check if aisearchresults field exists and has more than 0 items
      if (
        docData.hasOwnProperty("aisearchresults") &&
        Array.isArray(docData.aisearchresults) &&
        docData.aisearchresults.length > 0
      ) {
        // Reset aisearchresults to an empty array
        await updateDoc(projectDocRef, {
          aisearchresults: [],
        });
      }
    } else {
      console.log("No such document!");
    }
  };

  await checkAndResetAISearchResults();


  //Upload file to DOM
  let file;
  file = document.getElementById("uploadJobDesc").files[0];
  const filenameParts = file.name.split(".");
  const filetype = filenameParts[filenameParts.length - 1];

  if (filetype !== "docx" && filetype !== "pdf") {
    alert(
      "This file type is not supported. Please upload either a .docx (NOT .doc) file, or a .pdf file."
    );
    store.state.aiprogressstep = 'off';
    return;
  }

  $("#aiprogress").modal("show");

  store.state.aiprogressstep = 1;

  // Read the file
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  reader.onload = async () => {
    const arrayBuffer = reader.result;
    const bytes = new Uint8Array(arrayBuffer);
    let binary = "";
    bytes.forEach((byte) => (binary += String.fromCharCode(byte)));
    const base64 = window.btoa(binary);

    store.state.aiprogressstep = 2;

    //Convert file to text only
    try {
      const response = await axios.post(
        "https://us-central1-kiwano-3f6de.cloudfunctions.net/convertUploadedFilesToText",
        {
          file: base64,
          type: filetype,
        }
      );

      store.state.aiprogressstep = 3;

      //Function to scan, understand the file and search for matching profiles in DB
      const handleFileAndSearchWithAi = (text) => {
        store.state.aiprogressstep = 3;

        const prompt1 = "The following text is a job description: ";
        const industries =
          "Aerospace & Defense, Automotive, B2B Services, B2C Services, Banking / Insurance / Financial Services, Building Materials, Chemical Industry, Construction / Civil Engineering, Consumer Goods / Apparel / Luxury, Creative Services, Education, Energy & Utilities, Entertainment & Sports, Environmental Services, Food & Beverage, High-Tech Manufacturing, Hospital & Health Care, Human Resources, Industrial (other), IT & Security, Legal Services, Life Science, Logistics, Machinery, Marketing & Advertising, Media, NGO & Non profit, Other, Packaging, Plastics, Professional Services, Public Administration, Raw materials, Real Estate, Research, Retail & Wholesale, Security Services, Software & Technology, Telecommunications, Tourism / Hospitality, Transportation, Web & Digital, Wellness / Spa / Fitness";
        const functionCategories =
          "Sales, Technical Expertise, Marketing, R&D / Innovation / Engineering, IT, HR, Finance, Operations, Technical Services, Supply Chain & Logistics, Customer Services, P&L Management, Project Management, Production, Purchasing, Consulting, Quality, Legal, Communication";
        const prompt2 =
          "  Now, I want you to do 4 things. 1. please tell me which 3 industries this job description is about. 2. Tell me which type of professional function this job description relates to. For example, Engineering, Technical, Sales, Marketing, P&L management... 3. Tell me a the minimum years of experience required for this job. 4. Give me 6 synonyms of the function category you found that I can expect in the candidates' job title. VERY IMPORTANT: DO NOT INCLUDE ANY OF THESE WORDS: General, Director, Directeur, Directrice, Head, Managing, Manager, Junior. ALSO IMPORTANT: DO NOT REPEAT THE SAME WORD TWICE IN THE LIST.  Return your answer as a javascript object like this: {industries: [industryName1, industryName2, industryName3], functioncategory: functionName, minyears: 10, keywords: [keywords1, keyword2, etc.]}";
        let tempResult;
        const instructions = prompt1 + text + prompt2;

        const analyseWithGPT = async () => {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ content: instructions }),
          };

          try {
            store.state.aiprogressstep = 5;
            const response = await fetch(
              "https://europe-west1-kiwano-3f6de.cloudfunctions.net/askOpenAIGPT",
              requestOptions
            );
            store.state.aiprogressstep = 6;
            const data = await response.json();
            console.log(data);
            console.log(data.data.message);

            tempResult = data.data.message;

            function addQuotesToKeys(str) {
              return str.replace(/([\w]+):/g, '"$1":');
            }

            // Use the addQuotesToKeys function to modify tempResult
            const validJsonString = addQuotesToKeys(tempResult);

            // Now parse it to a JavaScript object
            const jsObject = JSON.parse(validJsonString);

            console.log(jsObject);

            store.state.aiprogressstep = 7;

            const analyseWithGPT2 = async () => {
              const followUpInstructions =
                "I want you to do 3 things: 1. Select from the following list: " +
                industries +
                "which two items are the closest related to these industries: " +
                jsObject.industries[0] +
                ", " +
                jsObject.industries[1] +
                ", " +
                jsObject.industries[2] +
                ". Store the result as 'industrytransformed'. I will refer to it later. " +
                "2. Now, determine wich one of the following function categories " +
                functionCategories +
                " is the closest related to the term: " +
                jsObject.functioncategory +
                " 3. Given this array of job titles: Here is the array:  " +
                jsObject.keywords[0] +
                ", " +
                jsObject.keywords[1] +
                ", " +
                jsObject.keywords[2] +
                ", " +
                jsObject.keywords[3] +
                ", " +
                jsObject.keywords[4] +
                ", " +
                jsObject.keywords[5] +
                ": perform the following transformations: a. Loop through each job title in the array. b. Remove any of the following specific words from each title: 'General', 'Director', 'Directeur', 'Directrice', 'Head', 'Managing', 'Manager', 'Junior'. c. After removing these words, keep only the first remaining word from each modified job title. d. Store the resulting list of first words as an array named 'keywords'. " +
                "    Return your answer in the form of a js object holding 4 items: industrytransformed (the industries you found in my list), functionCategory, keywords and experiencemetadata.careerYears. The last key should have the value " +
                jsObject.minyears;
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ content: followUpInstructions }),
              };

              try {
                const response = await fetch(
                  "https://europe-west1-kiwano-3f6de.cloudfunctions.net/askOpenAIGPT",
                  requestOptions
                );
                console.log("called function");
                const data = await response.json();
                console.log(data);
                console.log(data.data.message);
                const searchCriteriaTemp = JSON.parse(data.data.message);
                console.log(searchCriteriaTemp);
                store.state.aiprogressstep = 8;
                //search matching candidates with Algolia

                const searchCandidates = async () => {
                  const functionsInstance = getFunctions();
                  const searchCandidatesFunction = httpsCallable(
                    functionsInstance,
                    "aiSearchWithAlgolia"
                  );

                  //get Algolia index name
                  const algoliaIndexName =
                    "profilesForTeam" + store.state.userData.teamid;

                  const searchCriteria = {
                    indexName: algoliaIndexName,
                    industrytransformed: searchCriteriaTemp.industrytransformed,
                    functionCategory: searchCriteriaTemp.functionCategory,
                    experiencemetadata: {
                      careerYears:
                        searchCriteriaTemp.experiencemetadata.careerYears,
                    },
                  };

                  console.log(
                    "here are the criteria: " + JSON.stringify(searchCriteria)
                  );
                  store.state.aiprogressstep = 9;

                  try {
                    const result = await searchCandidatesFunction(
                      searchCriteria
                    );
                    console.log("Candidates: ", result.data);

                    ////Save AI search results to the firebase Project doc

                    
                    // Extract IDs from result.data
                    const idsArray = result.data.map((item) => item.objectID);

                    // Document ID from Vuex store
                    const docId = store.state.currentProjectDetails.id;

                    // Firestore document reference
                    const docRef = doc(db, "projects", docId);


                    if(idsArray.length == 0) {
                                          // Update aisearchresults field
                    try {
                      await updateDoc(docRef, {
                        aisearchresults: 'noprofilesfound',
                        uploadedjobdescname: file.name
                      });
                      console.log("Document successfully updated!");
                    } catch (error) {
                      console.error("Error updating document: ", error);
                    }
                    } else {
                                          // Update aisearchresults field
                    try {
                      await updateDoc(docRef, {
                        aisearchresults: arrayUnion(...idsArray),
                        uploadedjobdescname: file.name
                      });
                      console.log("Document successfully updated!");
                    } catch (error) {
                      console.error("Error updating document: ", error);
                    }
                    }



                   

                    setTimeout(() => {
                      store.state.aiprogressstep = "done";
                    }, 500);
                    $("#aiprogress").modal("hide");
                  } catch (error) {
                    console.error("Error calling cloud function: ", error);
                    throw error;
                  }
                };

                searchCandidates();

                //this.$store.state.generateProgressState = "done";
                //this.$store.state.generatedOutreachMessage = data.data.message;
              } catch (error) {
                console.error(`Error: ${error.message}`);
              }
            };

            analyseWithGPT2();

            //this.$store.state.generateProgressState = "done";
            //this.$store.state.generatedOutreachMessage = data.data.message;
          } catch (error) {
            console.error(`Error: ${error.message}`);
          }
        };

        analyseWithGPT();
      };

      //Call file handle function depending on filetype
      let rawText = response.data;
      let textContent;

      if (filetype == "docx") {
        function extractTextFromHtml(html) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = html;
          // Remove all <img> tags
          Array.from(tempDiv.querySelectorAll("img")).forEach((img) =>
            img.remove()
          );
          store.state.aiprogressstep = 4;
          return tempDiv.textContent || tempDiv.innerText || "";
        }

        textContent = extractTextFromHtml(rawText.html);
        console.log("this is the text only: " + textContent); // Output should be "This is a test"

        //call the handle function with textContent
        handleFileAndSearchWithAi(textContent);
      } else if (filetype == "pdf") {
        textContent = rawText.pdfText;

        store.state.aiprogressstep = 4;

        //Check if text is too long
        
        if(textContent.length > 10000) {
          store.state.aiprogressstep = 10;
          return;
        }
        
 
        console.log(textContent)
        handleFileAndSearchWithAi(textContent);
      }
    } catch (error) {
      console.error("Error calling cloud function:", error);
    }
  };
};

const deleteFileExternal = async (file) => {
  if (store.state.userData.userrole == "Administrator") {
    const confirmation = window.confirm(
      "Are you sure you want to delete this file? This action can not be undone."
    );

    if (!confirmation) {
      return; // Exit the function if the user clicks "Cancel"
    }
  } else if (store.state.userData.userrole == "Operator") {
    alert(
      "Only Administrators can delete files. You have an Operator role. Please consult with one of the Administrators in your team."
    );
    return;
  }

  const storage = getStorage();
  let storageRef;

  if (file.url.includes("fileforproject")) {
    storageRef = ref(
      storage,
      "userid" +
        store.state.userData.userid +
        "fileforproject" +
        store.state.currentProjectDetails.id +
        file.name
    );
  } else if (file.url.includes("fileforclient")) {
    storageRef = ref(
      storage,
      "userid" +
        store.state.userData.userid +
        "fileforclient" +
        store.state.currentClientDetails.id +
        file.name
    );
  }

  try {
    await deleteObject(storageRef);
    console.log("File deleted successfully");

    let fileIndex;

    if (file.url.includes("fileforproject")) {
      // Find the index of the file in the Vuex array
      fileIndex = store.state.currentProjectDetails.files.findIndex(
        (f) => f.id === file.id
      );

      // If the file was found in the array, remove it
      if (fileIndex !== -1) {
        store.state.currentProjectDetails.files.splice(fileIndex, 1);
      }

      // Update Firestore
      const projectRef = doc(
        db,
        "projects",
        store.state.currentProjectDetails.id
      );

      await updateDoc(projectRef, {
        files: store.state.currentProjectDetails.files,
      });

      console.log("Firestore document updated successfully");

      //Confirmation toast
      iziToast.show({
        title: "File deleted !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    } else if (file.url.includes("fileforclient")) {
      // Find the index of the file in the Vuex array
      fileIndex = store.state.currentClientDetails.files.findIndex(
        (f) => f.id === file.id
      );

      // If the file was found in the array, remove it
      if (fileIndex !== -1) {
        store.state.currentClientDetails.files.splice(fileIndex, 1);
      }

      // Update Firestore
      const clientRef = doc(db, "clients", store.state.currentClientDetails.id);

      await updateDoc(clientRef, {
        files: store.state.currentClientDetails.files,
      });

      console.log("Firestore document updated successfully");

      //Confirmation toast
      iziToast.show({
        title: "File deleted !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    }
  } catch (error) {
    console.error("Failed to delete file", error);
  }
};

//here: this functions add two items to the array: the full user object (in order to display the user in the list), AND the useremail only as a decond element. This makes it possible to check if the user has access when they signin to the portal. As in Firestore you can't check for the exinstence of an object, only a single element.
//then: it also adds the client user to an array within the Client doc, so that the user can select them for another project without having to add them every time again manually
const saveNewClientUserToPortalExternal = (
  clientfirstname,
  clientlastname,
  clientemail
) => {
  let projectRef = doc(db, "projects", store.state.currentProjectDetails.id);
  updateDoc(projectRef, {
    authorisedclientusersforportal: arrayUnion({
      firstname: clientfirstname,
      lastname: clientlastname,
      email: clientemail,
    }),
  })
    .then(() => {
      let projectRef = doc(
        db,
        "projects",
        store.state.currentProjectDetails.id
      );
      updateDoc(projectRef, {
        authorisedclientusersforportal: arrayUnion(clientemail),
      });
    })
    .then(() => {
      let clientRef = doc(
        db,
        "clients",
        store.state.currentProjectDetails.clientobject.id
      );
      updateDoc(clientRef, {
        authorisedclientusersforportal: arrayUnion({
          firstname: clientfirstname,
          lastname: clientlastname,
          email: clientemail,
        }),
      });

      //Confirmation toast
      iziToast.show({
        title: "Client user added to portal !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    });
};

//Deletes 2 elements, see why above
const deleteClientUserFromPortalExternal = (user) => {
  let projectRef = doc(db, "projects", store.state.currentProjectDetails.id);
  updateDoc(projectRef, {
    authorisedclientusersforportal: arrayRemove(user),
  }).then(() => {
    let projectRef = doc(db, "projects", store.state.currentProjectDetails.id);
    updateDoc(projectRef, {
      authorisedclientusersforportal: arrayRemove(user.email),
    }).then(() => {
      //Confirmation toast
      iziToast.show({
        title: "Client user removed from portal !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    });
  });
};

const batchApplyClientVisibilityInPortalExternal = (
  whichprofiles,
  statuslist
) => {
  if (whichprofiles == "all") {
    for (let i = 0; i < store.state.currentProfileList.length; i++) {
      const profileRef = doc(
        db,
        "profiles",
        store.state.currentProfileList[i].id
      );
      updateDoc(profileRef, {
        clientvisible: true,
      });
    }

    //Confirmation toast
    iziToast.show({
      title: "Client access updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    setTimeout(() => {
      getProfilesForThisProjectExternal(store.state.currentProjectDetails.id);
    }, 900);
  }
  if (whichprofiles == "status") {
    for (let i = 0; i < store.state.currentProfileList.length; i++) {
      const profileRef = doc(
        db,
        "profiles",
        store.state.currentProfileList[i].id
      );
      updateDoc(profileRef, {
        clientvisible: false,
      });
    }

    //Confirmation toast
    iziToast.show({
      title: "Client access updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    setTimeout(() => {
      for (let i = 0; i < statuslist.length; i++) {
        const filteredList = store.state.currentProfileList.filter((item) => {
          return item.statusid === statuslist[i];
        });

        for (let j = 0; j < filteredList.length; j++) {
          const profileRef = doc(db, "profiles", filteredList[j].id);
          updateDoc(profileRef, {
            clientvisible: true,
          });
        }
      }

      setTimeout(() => {
        getProfilesForThisProjectExternal(store.state.currentProjectDetails.id);
      }, 900);
    }, 900);
  } else if (whichprofiles == "none") {
    for (let i = 0; i < store.state.currentProfileList.length; i++) {
      const profileRef = doc(
        db,
        "profiles",
        store.state.currentProfileList[i].id
      );
      updateDoc(profileRef, {
        clientvisible: false,
      });
    }

    //Confirmation toast
    iziToast.show({
      title: "Client access updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    setTimeout(() => {
      getProfilesForThisProjectExternal(store.state.currentProjectDetails.id);
    }, 900);
  }
};

const togglePortalSharingExternal = () => {
  const projectRef = doc(db, "projects", store.state.currentProjectDetails.id);
  updateDoc(projectRef, {
    portalactive: !store.state.currentProjectDetails.portalactive,
  }).then(() => {
    if (store.state.currentProjectDetails.portalactive) {
      //Confirmation toast
      iziToast.show({
        title: "Client portal activated (for this project) !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    } else {
      //Confirmation toast
      iziToast.show({
        title: "Client portal de-activated (for this project) !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    }
  });
};

const toggleMentionClientExternal = (projectid, boleanValue) => {
  let projectRef = doc(db, "projects", projectid);
  updateDoc(projectRef, {
    chatgptmentionsclientname: boleanValue,
  });
};

const toggleIsFollowUpMsgExternal = (projectid, boleanValue) => {
  let projectRef = doc(db, "projects", projectid);
  updateDoc(projectRef, {
    chatgptisfollowupmsg: boleanValue,
  });
};

const addChatGptKeyWordsExternal = (projectid, keywords) => {
  let projectRef = doc(db, "projects", projectid);
  updateDoc(projectRef, {
    chatgptkeywords: keywords,
  });
};

const setLanguageExternal = (projectid, language) => {
  let projectRef = doc(db, "projects", projectid);
  updateDoc(projectRef, {
    chatgptlanguage: language,
  });
};

//add a new user (from the team) to the project for collaboration
const addUserExternal = (userid, projectid) => {
  let projectRef2 = doc(db, "projects", projectid);
  updateDoc(projectRef2, {
    otherusers: arrayUnion(userid),
  }).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "User added to project !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

const deleteUserFromProjectExternal = (userid, projectid) => {
  let projectRef3 = doc(db, "projects", projectid);
  updateDoc(projectRef3, {
    otherusers: arrayRemove(userid),
  }).then(() => {
    const projectIndex = store.state.currentMyProjectList.findIndex(
      (project) => project.id === projectid
    );

    if (projectIndex !== -1) {
      store.state.currentMyProjectList.splice(projectIndex, 1);
    }

    //Confirmation toast
    iziToast.show({
      title: "User removed from project !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

const joinProjectExternal = (currentuserid, projectid) => {
  let projectRef4 = doc(db, "projects", projectid);
  updateDoc(projectRef4, {
    otherusers: arrayUnion(currentuserid),
  }).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Project joined !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

const deleteProjectExternal = async (project, origin) => {
  if (store.state.userData.userrole !== "Administrator") {
    alert(
      "Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data."
    );
    return;
  }

  if (origin == "deleteclientfunction") {
    //don't show alert if the function was triggered a client delete action. it would just confuse the user.
  } else {
    const confirmation = window.confirm(
      "Are you sure you want to delete this project?"
    );

    if (!confirmation) {
      return; // Exit the function if the user clicks "Cancel"
    }
  }

  store.state.showProjectDetails = false;
  store.state.showingProjectsAs = "myProjects";
  store.state.currentProjectDetails = false;

  try {
    await deleteDoc(doc(db, "projects", project.id));
    console.log("Project document deleted successfully");

    iziToast.show({
      title: "Project deleted !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    const clientRef = doc(db, "clients", project.clientobject.id);

    const updateDocIfExists = async () => {
      const docSnap = await getDoc(clientRef);

      try {
        if (docSnap.exists()) {
          console.log("doc exists");
          await updateDoc(clientRef, {
            numberofprojects: increment(-1),
          });
        } else {
          console.log("doc doesn't exist, probably already deleted ?");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    updateDocIfExists();

    console.log("about to define the bindings query");
    const profileProjectBindingsQuery = query(
      collectionGroup(db, "profileprojectbindings"),
      where("teamid", "==", store.state.userData.teamid),
      where("projectid", "==", project.id)
    );

    console.log("about to fetch the bindings documents");
    const querySnapshot = await getDocs(profileProjectBindingsQuery);
    querySnapshot.forEach(async (document) => {
      console.log("fetched the binding document");

      const profileid = document.data().profileid;

      const docRef = doc(
        db,
        "profiles/" + profileid + "/profileprojectbindings/" + document.id
      );

      try {
        await updateDoc(docRef, {
          notes: "WARNING: Project and/or client deleted by user!",
        });
        console.log(`Binding Document with ID ${document.id} updated`);

        //it would make more sense to delete the binding instead of updating it. but for some reason deleting doesn't work.
        //i get the deletion confirmation, but the doc still exists in the DB. So we update it instead.
        //In any case, this binding will not show as the related project is deleted and throws an error in getdata.js
      } catch (error) {
        console.error("Error deleting binding document: ", error);
      }
    });
  } catch (error) {
    console.error("Error deleting project document: ", error);
  }
};



const addLinkedProjectExternal = async (lakesideProjectId, clientProjectId) => {
  
  const functions = getFunctions();  // If you initialized getFunctions in another file, make sure to import it correctly here.
  const linkFunction = httpsCallable(functions, "linkProjects");

  try {
    const result = await linkFunction({ lakesideProjectId, clientProjectId });
    if (result.data.success) {
      console.log("Successfully linked projects");
      
      return true;
    } else {
      console.error("Failed to link projects", result.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error linking projects:", error);
    return false;
  }
};

export {
  updateProjectStatusExternal,
  updateProjectTeamAccessExternal,
  saveProjectInfoExternal,
  getRecentCandidateProfilesExternal,
  saveProjectNotesExternal,
  uploadProjectFileExternal,
  deleteFileExternal,
  saveNewClientUserToPortalExternal,
  deleteClientUserFromPortalExternal,
  batchApplyClientVisibilityInPortalExternal,
  togglePortalSharingExternal,
  //generate outreach message with ChatGPT (options select functions)
  toggleMentionClientExternal,
  toggleIsFollowUpMsgExternal,
  addChatGptKeyWordsExternal,
  setLanguageExternal,
  //manage users in projec details
  addUserExternal,
  deleteUserFromProjectExternal,
  joinProjectExternal,
  deleteProjectExternal,
  aiSearchExternal,
  addLinkedProjectExternal
};
