<template>
  <!--begin::Card body-->
  <div class="card-body">
    <div class="row">
      <div class="col-6 me-8">
        <!--begin::User Info-->
        <div class="d-flex flex-start">
          <!--begin::Logo-->
          <div
            class="
              d-flex
              flex-column
              symbol symbol-75px symbol-rounded
              mb-7
              me-6
            "
          >
            <img
              v-if="$store.state.currentProfileDetails.profilepic"
              :src="$store.state.currentProfileDetails.profilepic"
              alt="image"
              onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
            />
            <img
              alt="Pic"
              v-if="!$store.state.currentProfileDetails.profilepic"
              src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
            />

 blablabla
          </div>
          <!--end::Logo-->
          <!--begin::Info-->
          <div class="d-flex flex-start flex-column">
            <div class="d-flex">
              <input
                id="newfirstname"
                v-on:keyup="modifyFirstName()"
                v-on:blur="resizeFirstAndLastname()"
                type="text"
                placeholder="+Add first name"
                autocomplete="off"
                spellcheck="false"
                class="
                  fs-3
                  text-gray-800
                  fw-bolder
                  customdottedunderlined
                  custominput
                  stretchyInput
                "
                :style="{ width: firstnameInputWidth + 'px' }"
                v-model="$store.state.currentProfileDetails.firstname"
              />
              <input
                v-on:keyup="modifyLastName()"
                v-on:blur="resizeFirstAndLastname()"
                type="text"
                placeholder="+Add last name"
                autocomplete="off"
                spellcheck="false"
                class="
                  fs-3
                  text-gray-800
                  fw-bolder
                  customdottedunderlined
                  custominput
                  stretchyInput
                "
                :style="{ width: lastnameInputWidth + 'px' }"
                v-model="$store.state.currentProfileDetails.lastname"
              />
              <!--begin GDPR consent given::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen048.svg-->
<span data-bs-toggle="tooltip" data-bs-placement="top" :title="'GDPR consent given on ' + Date($store.state.currentProfileDetails.gdprconsentdate)" v-if="$store.state.currentProfileDetails.gdprconsentstatus == 'Consent given'">
<span class="svg-icon svg-icon-success mt-1 svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"/>
<path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"/>
</svg>
</span>
</span>

<!--end::GDPR consent given, Svg Icon-->
            </div>
            <!--$store.state.currentProfileDetails.lastname.toUpperCase()-->
            <span
              v-if="!showTitleInputSwitch"
              class="fs-6 text-gray-400 fw-bolder customdottedunderlined"
              style="margin-left: 2px"
              @click="showTitleInput()"
              >blabla</span
            >
            <input
              v-if="showTitleInputSwitch"
              id="newtitleinput"
              v-on:blur="blurTitleInput()"
              v-on:keyup="modifyTitle()"
              @paste="modifyTitle()"
              type="text"
              spellcheck="false"
              placeholder="+Add title"
              autocomplete="off"
              class="
                fs-6
                text-gray-400
                fw-bolder
                customdottedunderlined
                custominput
              "
              style="margin-top: -1px; margin-bottom: -1px"
              v-model="$store.state.currentProfileDetails.currentjobtitle"
            />

            <input
              v-on:keyup="modifyCompany()"
              type="text"
              placeholder="+Add company"
              spellcheck="false"
              class="
                fs-6
                text-gray-800
                fw-bolder
                mb-3
                customdottedunderlined
                custominput
              "
              v-model="$store.state.currentProfileDetails.currentcompany"
            />

            <div>
              <ProfileTags class="me-3" style="width: 250px" :key="$store.state.tagComponentKey" @mouseover="showEditTaglist = true" @mouseleave="toggleEditTagListWithDelay()"></ProfileTags>
              <div v-if="showEditTaglist" class="text-start me-4 text-muted fs-8 text-hover-primary custompointer animate__fadeIn animate__animated" style="margin-top: -13px;"
                          data-bs-toggle="modal"
          data-bs-target="#editProfileTags"
          @click="initializeEditTagsModalForm()"
              >
                <i class="bi bi-pencil-fill fs-8 me-2"></i>Edit taglist</div>
            </div>
            
          </div>
          <!--end::Project title-->

          <!--begin::Position-->
          <div class="mb-9">
            <!--begin::Badge-->
            <!--begin::Badge-->
          </div>
          <!--end::Position-->
        </div>
        <!--end::User Info-->
      </div>

      <div class="col-5 fw-mormal timeline-content text-muted ps-3 ms-8">
        <div class="d-flex mb-12">
          <span class="">
            
          </span>
          

               
    
        </div>

        <div class="ms-2">
          <i class="bi bi-telephone-fill"></i>
          <input class="ms-2 customdottedunderlined custominput"
            spellcheck="false"
            style="width: 150px;"
            placeholder="+Add phone number"
            
          />
          
        </div>
        <div class="ms-2 d-flex">
          <i class="bi bi-envelope-fill mt-2"></i>

          <textarea id="profileemailinput" style="overflow:hidden; resize: none; width: 250px;" class=" ms-2 customdottedunderlined custominput"
            spellcheck="false"
            placeholder="+Add email"
          />
        </div>
        <div class="ms-2 d-flex">
          <i class="bi bi-geo-alt-fill mt-2"></i>

          <textarea style="overflow:hidden; resize: none; width: 250px;" class="ms-2 customdottedunderlined custominput"
          spellcheck="false"
          placeholder="+Add location"
            
          />
        </div>
        <div class="d-flex" style="margin-bottom: -29px">
          <span
            id="firstnameSpan"
            class="fs-3 fw-bolder bg-transparent text-transparent"
            >{{ $store.state.currentProfileDetails.firstname }}</span
          >
          <span
            id="lastnameSpan"
            class="fs-3 fw-bolder bg-transparent text-transparent"
            >{{ $store.state.currentProfileDetails.lastname }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <!--end::Card body-->
</template>

<script>
import ProfileTags from "../ProfileCardContents/ProfileTags.vue";
import { modifyFirstNameExternal } from "../../../js/profiledetails";
import { modifyLastNameExternal } from "../../../js/profiledetails";
import { modifyTitleExternal } from "../../../js/profiledetails";
import { modifyCompanyExternal } from "../../../js/profiledetails";
import { modifyPhoneExternal } from "../../../js/profiledetails";
import { modifyEmailExternal } from "../../../js/profiledetails";
import { modifyLocationExternal } from "../../../js/profiledetails";






export default {
  data() {
    return {
      showEditTaglist: false,
    }
  },
  mounted() {


  },
  components: {
    ProfileTags,
  },
  methods: {
    toggleEditTagListWithDelay() {
      setTimeout(() => {
          this.showEditTaglist = false
      }, 1000);
    },
    goToLinkedinProfile() {
      window.open(this.$store.state.currentProfileDetails.linkedinurl,'_blank');
    },

    //in profildedetails.js
    modifyFirstName() {
      modifyFirstNameExternal();
    },
    //in profildedetails.js
    modifyLastName() {
      modifyLastNameExternal();
    },
    //in profildedetails.js
    modifyTitle() {
      modifyTitleExternal();
    },
    showTitleInput() {
      this.showTitleInputSwitch = true;
      setTimeout(() => {
        document.getElementById("newtitleinput").focus();
      }, 400);
    },
    blurTitleInput() {
            if(this.$store.state.currentProfileDetails.currentjobtitle == "") {
        return
      }
      else {
        this.showTitleInputSwitch = false;
      }
    },
    //in profildedetails.js
    modifyTitle() {
      modifyTitleExternal();
    },
    //in profildedetails.js
    modifyCompany() {
      modifyCompanyExternal();
    },
        //in profildedetails.js
    modifyPhone() {
      modifyPhoneExternal();
    },
        //in profildedetails.js
    modifyEmail() {
      modifyEmailExternal();
    },
        //in profildedetails.js
    modifyLocation() {
      modifyLocationExternal();
    },
    resizeFirstAndLastname() {
      this.firstnameInputWidth =
        document.getElementById("firstnameSpan").offsetWidth + 9;
      this.lastnameInputWidth =
        document.getElementById("lastnameSpan").offsetWidth + 9;

      const firstname = this.$store.state.currentProfileDetails.firstname;
      const lastname = this.$store.state.currentProfileDetails.lastname;

      if (firstname == "") {
        this.firstnameInputWidth = 150;
      }

      if (lastname == "") {
        this.lastnameInputWidth = 150;
      }
    },
        initializeEditTagsModalForm() {
      //reset input value
      this.$store.state.tagname = "";

      //input focus on modal show is handled from App.vue

    },
  },
  data() {
    return {
      firstnameInputWidth: 0,
      lastnameInputWidth: 0,
      showTitleInputSwitch: false,
      showCompanyInputSwitch: false,
      showPhoneNumberInputSwitch: false,
      showEmailInputSwitch: false,
      showLocationInputSwitch: false,
    };
  },
};
</script>

<style>
</style>