<template>
  <div class="dropdown">
    <!--Status display & Dropdown button-->

    <!--in Batch manipulate banner-->
    <a
      v-if="
        origin === 'batchManipulateBanner' &&
        $store.state.showingProfilesAs == 'projectProfiles'
      "
      class="badge badge-sm badge-light-primary mt-3 custompointer me-4 dropdown-toggle"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Update status
    </a>

    <!--in Edit Profile project modal-->
    <span
      v-if="origin === 'profileDetailsModal'"
      class="btn btn-sm dropdown-toggle"
      :class="{
        'btn-light-success': computedStatusCategory == 'Interested',
        'btn-light-primary': computedStatusCategory == 'In progress',
        'btn-light-danger': computedStatusCategory == 'Discarded',
        'btn-light':
          computedStatusCategory != 'Interested' &&
          computedStatusCategory != 'In progress' &&
          computedStatusCategory != 'Discarded',
      }"
      type="button"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{
        computedStatus.length < 30
          ? computedStatus
          : computedStatus.substring(0, 30) + "..."
      }}
    </span>

    <!--in Profile Project Activity card on ProfileDetails.vue-->
    <span
      v-if="origin === 'profileDetailsCard'"
      class="badge dropdown-toggle"
      :class="{
        'badge-success': computedStatusCategory == 'Interested',
        'badge-primary': computedStatusCategory == 'In progress',
        'badge-danger': computedStatusCategory == 'Discarded',
        'badge-secondary':
          computedStatusCategory != 'Interested' &&
          computedStatusCategory != 'In progress' &&
          computedStatusCategory != 'Discarded',
      }"
      type="button"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-boundary="viewport"
      data-bs-display="static"
    >
      {{
        computedStatus.length < 30
          ? computedStatus
          : computedStatus.substring(0, 30) + "..."
      }}
    </span>

    <!--in Profile Row (ProfileList.vue)-->
    <span
      v-if="origin === 'projectProfileList'"
      class="badge dropdown-toggle w-130px"
      :class="{
        'badge-light-success': computedStatusCategory == 'Interested',
        'badge-light-primary': computedStatusCategory == 'In progress',
        'badge-light-danger': computedStatusCategory == 'Discarded',
        'badge-light':
          computedStatusCategory != 'Interested' &&
          computedStatusCategory != 'In progress' &&
          computedStatusCategory != 'Discarded',
      }"
      type="button"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click.stop=""
    >
      {{
        computedStatus.length < 30
          ? computedStatus
          : computedStatus.substring(0, 30) + "..."
      }}
    </span>

    <!--in Project Row (ProjectList.vue)-->
    <span
      v-if="origin === 'projectList'"
      class="badge dropdown-toggle"
      :class="{
        'badge-light-success': this.currentProjectStatusName == 'Active',
        'badge-light-danger': this.currentProjectStatusName == 'Closed',
      }"
      type="button"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click.stop=""
    >
      {{ this.currentProjectStatusName }}
    </span>

    <!--in Client Row (ClientList.vue)-->
    <span
      v-if="origin === 'clientList'"
      class="badge dropdown-toggle"
      :class="{
        'badge-light-success': this.currentClientStatusName == 'Client',
        'badge-light-warning': this.currentClientStatusName == 'Lead',
      }"
      type="button"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click.stop=""
    >
      {{ this.currentClientStatusName }}
    </span>

    <!--in Project Details Info card-->
    <span
      v-if="origin === 'projectDetailsCard'"
      class="badge dropdown-toggle"
      :class="{
        'badge-light-success': this.computedStatus == 'Active',
        'badge-light-danger': this.computedStatus == 'Closed',
      }"
      type="button"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ computedStatus }}
    </span>

    <!--in Client Details Info card-->
    <span
      v-if="origin === 'clientDetailsCard'"
      class="badge dropdown-toggle mt-2"
      :class="{
        'badge-light-success': this.computedStatus == 'Client',
        'badge-light-warning': this.computedStatus == 'Lead',
      }"
      type="button"
      :id="'buttonin' + origin + elementObject.id"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ computedStatus }}
    </span>

    <!--Dropdown menu-->

    <ul
      class="dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-bold py-4 fs-6 w-200px"
      :id="computedId"
      :aria-labelledby="'buttonin' + origin + elementObject.id"
      role="menu"
    >
      <li
        v-if="
          origin == 'batchManipulateBanner' ||
          origin == 'profileDetailsModal' ||
          origin == 'profileDetailsCard' ||
          origin == 'projectProfileList'
        "
        class="badge ms-4 me-4 mb-3 custombadgehover badge-light"
        style="cursor: pointer"
        @click.stop=""
        data-bs-toggle="modal"
        data-bs-target="#editProfileStatusList"
      >
        <a>
          <!--begin::Svg Icon | path: assets/media/icons/duotune/art/art005.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-7 ms-2"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                fill="currentColor"
              />
              <path
                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                fill="currentColor"
              /></svg
          ></span>
          <!--end::Svg Icon-->
          Edit status list
        </a>
      </li>
      <li
        v-if="
          origin == 'batchManipulateBanner' ||
          origin == 'profileDetailsModal' ||
          origin == 'profileDetailsCard' ||
          origin == 'projectProfileList'
        "
        class="badge ms-4 me-4 mb-3 custombadgehover badge-light"
        style="cursor: pointer"
        @click.stop="emitStatusClick('no status')"
      >
        <a> No status </a>
      </li>
      <li
        @click.stop="emitStatusClick(status)"
        class="badge ms-4 me-4 mb-3 custombadgehover"
        :class="{
          'badge-light-primary': status.category == 'In progress',
          'badge-light-danger':
            status.category == 'Discarded' || status.status == 'Closed',
          'badge-light-success':
            status.category == 'Interested' ||
            status.status == 'Active' ||
            status.status == 'Client',
          'badge-light-warning': status.status == 'Lead',
        }"
        style="cursor: pointer"
        v-for="status in computedStatusList"
        :key="status.id"
      >
        <a>

          {{
            status.status.length < 30
              ? status.status
              : status.status.substring(0, 30) + "..."
          }}
           <span class="ms-1" style="opacity: 0.3;" v-if="$store.state.lakesideStatusList.includes(status.id)">
           <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="11" height="11" viewBox="0 0 30 30">
    <path d="M 15 2 C 11.145666 2 8 5.1456661 8 9 L 8 11 L 6 11 C 4.895 11 4 11.895 4 13 L 4 25 C 4 26.105 4.895 27 6 27 L 24 27 C 25.105 27 26 26.105 26 25 L 26 13 C 26 11.895 25.105 11 24 11 L 22 11 L 22 9 C 22 5.2715823 19.036581 2.2685653 15.355469 2.0722656 A 1.0001 1.0001 0 0 0 15 2 z M 15 4 C 17.773666 4 20 6.2263339 20 9 L 20 11 L 10 11 L 10 9 C 10 6.2263339 12.226334 4 15 4 z"></path>
</svg>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  batchApplyNewStatusExternal,
  applyProfileStatusExternal,
} from "../../js/profilelist";
import { applyStatusForThisProfileProjectBinding } from "../../js/profiledetails";
import { batchUpdateProfileStatusExternal } from "../../js/profilelist";
import { updateProjectStatusExternal } from "../../js/projectdetails";
import { updateClientStatusExternal } from "../../js/clientdetails";

import { updateProfileStatusExternal } from "../../js/profiledetails";

export default {
  data() {
    return {};
  },
  props: [
    "origin",
    "elementObject",
    "currentProfileProjectBindingId",
    "currentProfileStatusId",
    "currentProjectStatusName",
    "currentClientStatusName",
    "currentProjectId",
  ],
  methods: {
    emitStatusClick(statusobject) {
      if (this.origin == "batchManipulateBanner") {
        //close the dropdown in the modal. it doesn't close by itself.
        $("#buttonin" + this.origin + this.elementObject.id).dropdown("hide");

        //in profilelist.js
        batchUpdateProfileStatusExternal(statusobject);
      }

      if (
        this.origin == "profileDetailsCard" ||
        this.origin == "profileDetailsModal" ||
        this.origin == "projectProfileList"
      ) {

        //in profiledetails.js





        updateProfileStatusExternal(
          this.origin,
          this.elementObject,
          this.currentProfileProjectBindingId,
          statusobject,
          this.currentProjectId
        );

        //close the dropdown in the modal. it doesn't close by itself.
        $("#buttonin" + this.origin + this.elementObject.id).dropdown("hide");

        //this updates the object serving the current binding in the "Edit project profile binding" modal, so that it updates in realtime
        if (this.origin == "profileDetailsModal") {
          this.$store.state.currentProfileProjectBinding.profilestatusid =
            statusobject.id;
        }
      }

      if (this.origin == "projectList") {
        //from projectdetails.js
        updateProjectStatusExternal(statusobject.status, this.elementObject);
        //close the dropdown in the modal. it doesn't close by itself.
        $("#buttonin" + this.origin + this.elementObject.id).dropdown("hide");
      }
      if (this.origin == "projectDetailsCard") {
        //from projectdetails.js
        updateProjectStatusExternal(statusobject.status, this.elementObject);
      }
      if (this.origin == "clientDetailsCard") {
        //from clientdetails.js
        updateClientStatusExternal(statusobject.status, this.elementObject);
      }
      if (this.origin == "clientList") {
        //from clientdetails.js
        updateClientStatusExternal(statusobject.status, this.elementObject);
        console.log(this.elementObject.id);
        $("#buttoninclientList" + this.elementObject.id).dropdown("toggle");
      }
    },
  },
  mounted() {},
  computed: {
    computedId() {
      if (this.origin == "batchManipulateBanner") {
        return "batchchangestatussubmenu";
      } else if (this.origin == "profileDetailsModal") {
        return "statussubmenuinmodal" + this.elementObject.id;
      } else {
        return "statussubmenu" + this.elementObject.id;
      }
    },
    computedStatusCategory() {
      for (let i = 0; i < this.$store.state.profileStatusList.length; i++) {
        if (
          this.computedStatus == this.$store.state.profileStatusList[i].status
        ) {
          return this.$store.state.profileStatusList[i].category;
        }
      }
    },
    computedStatusList() {
      if (
        this.origin == "batchManipulateBanner" ||
        this.origin == "profileDetailsModal" ||
        this.origin == "profileDetailsCard" ||
        this.origin == "projectProfileList"
      ) {
        return this.$store.state.profileStatusList;
      } else if (
        this.origin == "projectList" ||
        this.origin == "projectDetailsCard"
      ) {
        return this.$store.state.projectStatusList;
      } else if (
        this.origin == "clientList" ||
        this.origin == "clientDetailsCard"
      ) {
        return this.$store.state.clientStatusList;
      }
    },
    computedStatus() {
      //this takes the profile status id and looks for the corresponding
      //status in the team defined status list.
      //Then it returns the name of the status insead of the id, so that it becomes readable by user

      let allstatuses = this.$store.state.profileStatusList;

      let statusid;

      if (
        this.origin == "profileDetailsCard" ||
        this.origin == "profileDetailsModal" ||
        this.origin == "projectProfileList"
      ) {
        statusid = this.currentProfileStatusId;

        //If no status has been assigned yet, the profilestatusid in Firestore and Vuex is 'xxx' by default
        if (statusid == "xxx") {
          return "No status";
        }
        //If a status has been assigned...
        else {
          //look for the corresponding status
          let statusobject = allstatuses.find((o) => o.id === statusid);

          //if there is no corresponding status in the statuslist because the status has been deleted by user from the statuslist...
          if (statusobject == undefined) {
            return "No status";
          }
          //if there is a corresponding status in the statuslist...
          else {
            //return status name
            return statusobject.status;
          }
        }
      } else if (this.origin == "projectDetailsCard") {
        return this.elementObject.status;
      } else if (this.origin == "clientDetailsCard") {
        return this.elementObject.clientstatus;
      }
    },
  },
};
</script>

<style></style>
