<template>
  <!--begin::Items-->
  <div class="py-2">
    <!--begin::Item-->
    <div class="d-flex flex-stack" v-if="$store.state.currentProfileDetails.candidateorclient == 'Candidate'">
      <div class="d-flex">
        <div class="d-flex flex-column">
          <a
            href="#"
            class="fs-5 text-dark text-hover-primary fw-bold"
          >Show client</a>
          <div class="fs-6 fw-semibold text-gray-400">Should the client see this profile?</div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            name="notifications"
            v-model="$store.state.currentProfileDetails.clientvisible"
            @click="switchClientVisibility()"
          />
        </div>
      </div>
    </div>
    <!--end::Item-->
    <div class="separator separator-dashed my-5"></div>
    <!--begin::Item-->
    <div class="d-flex flex-stack">
      <div class="d-flex">
        <div class="d-flex flex-column">
          <a
            href="#"
            class="fs-5 text-dark text-hover-primary fw-bold"
          >Public profile</a>
          <div class="fs-6 fw-semibold text-gray-400">Share this profile with your team</div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            name="notifications"
            v-model="$store.state.currentProfileDetails.teamaccess"
            @click="switchTeamAccess()"
          />
        </div>
      </div>
    </div>
    <!--end::Item-->
    <div class="separator separator-dashed my-5"></div>
    <!--begin::Item-->
    <div class="d-flex flex-stack">
      <div class="d-flex">
        <div class="d-flex flex-column">
          <a
            href="#"
            class="fs-5 text-dark text-hover-primary fw-bold"
          >Profile type</a>
          <div class="d-flex mt-6">
            <input
              class="form-check-input"
              type="radio"
              value="Candidate"
              id="candidate"
              v-model="$store.state.currentProfileDetails.candidateorclient"
              @click="updateCandidateOrClientSwitch()"
            />

            <label
              class="form-check-label ms-2 fs-6 fw-semibold text-gray-400"
              for="candidate"
            >
              Candidate
            </label>
          </div>
          <div class="d-flex mt-2">

            <input
              class="form-check-input"
              type="radio"
              value="Client"
              id="client"
              v-model="$store.state.currentProfileDetails.candidateorclient"
              @click="updateCandidateOrClientSwitch()"
            />
            <label
              class="form-check-label ms-2 fs-6 fw-semibold text-gray-400"
              for="client"
            >
              Client
            </label>
          </div>

        </div>
      </div>

    </div>
    <!--end::Item-->
    <div class="separator separator-dashed my-5"></div>
    <!--begin::Item-->
    <div class="d-flex flex-stack">
      <div class="d-flex">
        <div class="d-flex flex-column">
          <a
            href="#"
            class="fs-5 text-dark text-hover-primary fw-bold"
          >GDPR Status</a>
          <div class="fs-6 fw-semibold text-gray-400">Has this profile consented to having <br> his/her personal details stored ?</div>
          <div class="d-flex mt-6">

            <input
              class="form-check-input"
              type="radio"
              value="Not requested"
              id="notrequestedradio"
              v-model="$store.state.currentProfileDetails.gdprconsentstatus"
              @click="updateGdprStatus()"
            />

            <label
              class="form-check-label ms-2 fs-6 fw-semibold text-gray-400"
              for="notrequestedradio"
            >
              Not requested
            </label>
          </div>
          <div class="d-flex mt-2">

            <input
              class="form-check-input"
              type="radio"
              value="Pending request"
              id="pendingradio"
              v-model="$store.state.currentProfileDetails.gdprconsentstatus"
              @click="updateGdprStatus()"

            />
            <label
              class="form-check-label ms-2 fs-6 fw-semibold text-gray-400"
              for="pendingradio"
            >
              Pending request
            </label>
          </div>
          <div class="d-flex mt-3">
            <input
              class="form-check-input"
              type="radio"
              value="Consent given"
              id="consentedradio"
              v-model="$store.state.currentProfileDetails.gdprconsentstatus"
              @click="updateGdprStatus()"
            />

            <label
              class="form-check-label ms-2 fs-6 fw-semibold text-gray-400"
              for="consentedradio"
            >
              Consent given
            </label>
          </div>
          <div class="d-flex mt-3">

            <input
              class="form-check-input"
              type="radio"
              value="Consent declined"
              id="declinedradio"
              v-model="$store.state.currentProfileDetails.gdprconsentstatus"
              @click="updateGdprStatus()"
            />

            <label
              class="form-check-label ms-2 fs-6 fw-semibold text-gray-400"
              for="declinedradio"
            >
              Consent declined
            </label>
          </div>
          <div class="mt-6">
            <button class="btn btn-sm btn-light">Send request</button>
          </div>

        </div>
      </div>

    </div>
    <!--end::Item-->

    
  </div>
  <!--end::Items-->
</template>

<script>

import { switchClientVisibilityExternal } from "../../../js/profiledetails"
import { switchTeamAccessExternal } from "../../../js/profiledetails"
import { updateCandidateOrClientSwitchExternal } from "../../../js/profiledetails"
import { updateGdprStatusExternal } from "../../../js/profiledetails"



export default {

  methods: {
    switchClientVisibility() {
      //WARNING! This does not update live in the UI as the currentProfileDetails are not being listened to in realtime.
      //If 2 users are on the same profile, and user1 updates clientvisibility, then user2 will not see any change until page reload
      switchClientVisibilityExternal()
    },
    switchTeamAccess() {
      //WARNING! This does not update live in the UI as the currentProfileDetails are not being listened to in realtime.
      //If 2 users are on the same profile, and user1 updates teamaccess, then user2 will not see any change until page reload
      switchTeamAccessExternal()
    },
    updateCandidateOrClientSwitch() {
      updateCandidateOrClientSwitchExternal()
    },
    updateGdprStatus() {
      updateGdprStatusExternal()
    }
  }


};
</script>

<style>
</style>