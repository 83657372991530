<template>

  <div
    class="wrapper d-flex flex-column flex-row-fluid container-xxl"
    id="kt_wrapper"
    style="z-index: 1"
  >
    <!--begin::Toolbar-->
    <Toolbar
    ref="toolbar"
      v-if="
        $store.state.showingProfilesAs == 'recentProfiles' &&
        ($store.state.currentRoute == '/profiles' ||
          $store.state.currentRoute == '/')
      "
      title="Recent profiles"
      class="sticky-top shadow-xs"
      style="z-index: 3"
    ></Toolbar>
    <Toolbar
     ref="toolbar"
      v-if="
        $store.state.showingProfilesAs == 'projectProfiles' &&
        ($store.state.currentRoute == '/profiles' ||
          $store.state.currentRoute == '/')
      "
      title=""
      class="sticky-top shadow-xs"
      style="z-index: 3"
    ></Toolbar>
    <Toolbar
     ref="toolbar"
      v-if="
        $store.state.showingProfilesAs == 'searchResults' &&
        ($store.state.currentRoute == '/profiles' ||
          $store.state.currentRoute == '/')
      "
      title="Search results"
      class="sticky-top shadow-xs"
      style="z-index: 3"
    ></Toolbar>
    <!--end::Toolbar-->
    <!--begin::Batch manipulate Banner-->

    <BatchManipulateBanner
      class="animate__animated animate__fadeIn shadow-sm"
      style="z-index: 2; position: sticky; top: 75px"
      v-if="$store.state.selectedProfiles.length > 0 && $store.state.showingProfilesAs != 'projectProfiles'"
    ></BatchManipulateBanner>

        <BatchManipulateBanner
      class="animate__animated animate__fadeIn shadow-sm"
      style="z-index: 2; position: sticky; top: 89px"
      v-if="$store.state.selectedProfiles.length > 0 && $store.state.showingProfilesAs == 'projectProfiles'"
    ></BatchManipulateBanner>

    <!--end::Batch manipulate Banner-->
    <!--begin::Main-->

    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div class="content flex-row-fluid" id="kt_content">
        <!--begin::Card-->

        <!--begin::Tables Widget 9-->
        <div class="card mb-5 mb-xl-8">
          <!--begin::Header-->

          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-3">
            <!--begin::Table container-->
            <div class="table-responsive" v-if="!$store.state.emptyProfileList">
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-3 gy-4"
                id="profilesTable"
                :class="{
                  'table-hover': $store.state.currentProfileList.length > 0,
                }"
                style="margin-bottom: 100px !important"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="fw-bolder text-muted" style="height: 65px">
                    <th class="w-25px">
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                        style="margin-bottom: 19px"
                      >
                        <input
                          class="form-check-input"
                          id="selectAllBtn"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          @click.stop="selectAllProfiles()"
                        />
                      </div>
                    </th>
                    <th class="min-w-150px" style="width: 25%">Select all</th>
                    <th class="min-w-140px" style="width: 12%">
                      Company <br />
                    </th>
                    <th
                      v-if="
                        $store.state.showingProfilesAs == 'recentProfiles' ||
                        $store.state.showingProfilesAs == 'searchResults'
                      "
                      class="min-w-140px"
                      style="width: 19%"
                    >
                      <span>Latest project</span>
                    </th>
                    <th
                      v-if="$store.state.showingProfilesAs == 'projectProfiles'"
                    >
                      <span>Status</span>
                    </th>
                    <th class="min-w-80px" style="width: 19%">
                      Latest comment <br />
                    </th>

                    <th class="min-w-120px">Tags <br /></th>
                    <th class="min-w-100px text-end">Profile links <br /></th>
                  </tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->

                <tbody
                  v-if="
                    $store.state.currentProfileList.length > 0 &&
                    !$store.state.emptyProfileList
                  "
                  class="animate__animated animate__fadeIn"
                >
                  <tr
                    style="cursor: pointer"
                    :class="{
                      'bg-secondary':
                        profile.id == $store.state.currentProfileDetails.id && profileDarkBG
                    }"
                    v-for="profile in computedLoadedProfiles"
                    :key="profile.id"
                    :id="'profileRow' + profile.id"
                  >
                    <td>
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input widget-9-check"
                          type="checkbox"
                          @click.stop="selectProfile(profile.id)"
                          value="1"
                        />
                      </div>
                    </td>
                    <td @click="goToDetailedProfile(profile.id)">
                      <div class="d-flex align-items-center">
                        <div class="d-flex flex-column symbol symbol-35px me-5">
                          <img
                            alt="Pic"
                            v-if="profile.profilepic && !profile.profilepic.includes('GoogleAccessId') && !profile.profilepic.includes('media.licdn.com')"
                            :src="profile.profilepic"
                            onerror="this.onerror=null; this.src='/assets/media/anonavatar.png'"
                          />
                          <img
                            alt="Pic"
                            v-if="!profile.profilepic || profile.profilepic.includes('GoogleAccessId') || profile.profilepic.includes('media.licdn.com')"
                            src="/assets/media/anonavatar.png"
                          />
                          <span
                            v-if="profile.candidateorclient == 'Client'"
                            class="mt-2 badge badge-sm badge-light-info"
                            >Client</span
                          >
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <a class="text-dark fw-bolder fs-6">{{
                            profile.firstname +
                            " " +
                            profile.lastname.toUpperCase()
                 
                          }}</a>
                          <span
                            v-if="profile.currentjobtitle"
                            class="text-muted fw-bold d-block fs-7 w-170px"
                            >{{
                              profile.currentjobtitle.length < 20
                                ? profile.currentjobtitle
                                : profile.currentjobtitle.substring(0, 20) +
                                  "..."
                            }}</span
                          >
                        </div>
                      </div>
                    </td>
                    <td @click="goToDetailedProfile(profile.id)">
                      <a
                        v-if="profile.currentcompany"
                        class="text-muted fw-bolder d-block fs-7 me-6"
                        >{{
                          profile.currentcompany.length < 20
                            ? profile.currentcompany
                            : profile.currentcompany.substring(0, 20) + "..."
                        }}</a
                      >
                    </td>
                    <td
                      @click="goToDetailedProfile(profile.id)"
                      v-if="
                        $store.state.showingProfilesAs == 'recentProfiles' ||
                        $store.state.showingProfilesAs == 'searchResults'
                      "
                    >
                      <div
                        class="d-flex"
                        v-if="profile.latestbindingclientname"
                      >
                        <span
                          class="bullet bullet-dot h-6px w-6px me-2 mt-5"
                          :class="{
                            'bg-success':
                              profile.lateststatuscategory ==
                              'Interested',
                            'bg-primary':
                              profile.lateststatuscategory == 'In progress',
                            'bg-danger':
                              profile.lateststatuscategory == 'Discarded',
                            'bg-white':
                              profile.lateststatuscategory == 'no status',
                          }"
                        ></span>
                        <div class="symbol symbol-35px companylogo">
                          <img
                            v-if="profile.latestbindingclientlogo"
                            :src="profile.latestbindingclientlogo"
                            class="h-40 align-self-center border border-secondary"
                            alt=""
                          />
                          <img
                            v-else
                            src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                            class="h-40 align-self-center border border-secondary"
                            alt=""
                          />
                        </div>
                        <div class="d-flex flex-column ms-3">
                          <a class="text-muted fw-bolder fs-7">{{
                            profile.latestbindingclientname.length < 10
                              ? profile.latestbindingclientname
                              : profile.latestbindingclientname.substring(
                                  0,
                                  10
                                ) + "..."
                          }}</a>

                          <span class="fw-semibold d-block fs-7 text-muted"
                            >{{
                              profile.latestbindingposition.length < 15
                                ? profile.latestbindingposition
                                : profile.latestbindingposition.substring(
                                    0,
                                    15
                                  ) + "..."
                            }}
                          </span>
                        </div>
                      </div>
                      <div v-else class="me-9 ms-5">
                        <i class="bi bi-slash-circle"></i>
                      </div>
                    </td>
                    <td
                      @click="goToDetailedProfile(profile.id)"
                      v-if="$store.state.showingProfilesAs == 'projectProfiles'"
                    >
                      <!--begin::StatusDropdown-->

                      <StatusDropdown
                        origin="projectProfileList"
                        :elementObject="profile"
                        :currentProfileProjectBindingId="profile.bindingid"
                        :currentProfileStatusId="profile.statusid"
                        :currentProjectId="profile.bindingprojectid"
                      ></StatusDropdown>
                      <!--end::StatusDropdown-->
                    </td>
                    <td
                      class="text-muted fs-7"
                      v-if="profile.latestcommenttext"
                      @click="goToDetailedProfile(profile.id)"
                    >
                      <button
                        v-if="
                          $store.state.showingProfilesAs == 'projectProfiles'
                        "
                        type="button"
                        class="border-0 bg-white"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Warning! This comment is the latest one for this profile. It does not necessarily belong to this project."
                      >
                        <i class="text-danger bi bi-exclamation-triangle"></i>
                      </button>

                      "{{
                        profile.latestcommenttext.length < 20
                          ? profile.latestcommenttext
                          : profile.latestcommenttext.substring(0, 40) + "..."
                      }}"
                    </td>
                    <td
                      class="text-muted"
                      v-else
                      @click="goToDetailedProfile(profile.id)"
                    >
                      <div class="me-9 ms-2">
                        <i class="bi bi-slash-circle"></i>
                      </div>
                    </td>
                    <td class="text-start">
                      <!--legacy indicators
                      <div class="d-flex">
                        <div
                          class="ms-3 me-6 d-flex"
                          v-if="profile.newemailscounter > 0"
                        >
                          <div>
                            <i class="bi bi-envelope"></i>
                          </div>
                          <div style="margin-top: -20px">
                            <span
                              class="bullet bullet-dot h-6px w-6px bg-danger me-2 mt-5"
                            ></span>
                          </div>
                        </div>

                        <div
                          class="ms-2 me-6 d-flex"
                          v-if="profile.opentaskscounter > 0"
                        >
                          <div>
                            <i class="bi bi-list-task"></i>
                          </div>
                          <div style="margin-top: -20px">
                            <span
                              class="bullet bullet-dot h-6px w-6px bg-danger me-2 mt-5"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <span
                          class="badge badge-sm badge-light-primary"
                          v-if="profile.stateofmind == 'Open'"
                          >Open</span
                        >
                      </div>
                      -->
                      <div v-if="profile.tags && profile.tags.length > 0" class="d-flex flex-wrap mt-2">
                        <div
                          class="me-3 mt-2 badge badge-sm badge-light"
                          v-for="tag in profile.tags"
                          :key="tag"
                        >
                          <i class="bi bi-tag-fill"></i>
                          <span class="ms-1">{{ tag }}</span>
                        </div>
                      </div>
                                           <div v-else class="me-9 ms-2">
                        <i class="bi bi-slash-circle"></i>
                      </div>

                      <div class="d-flex">
                        <!-- need to shift to Status in Projectdata subcollection first
                      <StatusDropdown
                        origin="projectProfileList"
                        :profileId="profile.id"
                        :profileStatus="profile.status"
                      ></StatusDropdown>
                      -->

                        <!--begin: Status has been deleted - Warning tooltip-->
                        <div
                          class="d-none customtooltip ms-2 mt-1"
                          v-if="
                            !$store.state.statusNameArrayList.includes(
                              profile.status
                            )
                          "
                        >
                          <i
                            class="bi bi-exclamation-triangle text-danger"
                            @mouseover="$store.state.statusWarning = true"
                            @mouseleave="$store.state.statusWarning = false"
                          ></i>

                          <div
                            class="d-flex flex-column customtooltiptext fs-7 animate__animated animate__faster"
                            :class="{
                              animate__fadeIn: $store.state.statusWarning,
                            }"
                          >
                            <a
                              class="btn btn-sm btn-light-primary mb-4 mt-2"
                              @click.stop=""
                              data-bs-toggle="modal"
                              data-bs-target="#editProfileStatusList"
                              >Edit status list</a
                            >
                            <span>
                              This status has been deleted from the status list.
                              It will not appear in the filter or search options
                              anymore. Nor will it be taken into account in the
                              dashboard KPIs. Please select another status or
                              re-add to the status list.
                            </span>
                          </div>
                        </div>
                        <!--end: Status has been deleted Warning-->
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end flex-shrink-0">
                                                <a
                          v-if="profile.cvfile && Object.keys(profile.cvfile).length > 0"
                          href=""
                          class="btn btn-light-primary btn-sm btn-icon fw-boldest fs-7"
                          data-bs-toggle="modal"
                          data-bs-target="#fileviewer"
                          @click="storeFileForDisplay(profile.cvfile)"
                          >CV</a
                        >
                        <a
                          :href="profile.linkedinurl"
                          target="_blank"
                          class="btn btn-icon btn-light-primary btn-sm ms-2"
                        >
                          <i class="fab fa-linkedin-in"></i>
                        </a>

                      </div>
                    </td>
                  </tr>
                </tbody>

                <!--begin: Loading profiles animation (before currentProfileList has been loaded into Vuex-->
                <tbody
                  v-if="
                    $store.state.currentProfileList.length == 0 &&
                    !$store.state.emptyProfileList == true
                  "
                >
                  <tr style="height: 200px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div class="ms-5 mt-10 me-20" id="preloader_1">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <!--end: Loading profiles animation-->

                <!--begin: Loading profiles animation (when user has scrolled to bottom, loading more profiles-->
                <tbody
                  v-if="
                    $store.state.scrolledToBottom &&
                    $store.state.currentProfileList.length >
                      $store.state.numberOfProfilesLoaded
                  "
                >
                  <tr style="height: 50px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div class="ms-5 mt-10 me-20" id="preloader_1">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <!--end: Loading profiles animation-->

                <!--begin: Loading profiles animation-->
                <tbody v-if="!$store.state.scrolledToBottom">
                  <tr style="height: 50px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div class="ms-5 mt-10 me-20" id="">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <!--end: Loading profiles animation-->

                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
            <!--end::Table container-->
            <!--begin: "No profiles" message-->
            <div
              v-if="$store.state.emptyProfileList"
              style="height: 200px; margin-top: 7%"
            >
              <span
                class="text-muted fw-bold"
                style="margin-left: 32%"
                v-if="$store.state.showingProfilesAs == 'recentProfiles'"
              >
                <i class="bi bi-slash-circle me-2"></i>
                You have not added any profiles yet! Try our Chrome
                extension!</span
              >
              <span
                class="text-muted fw-bold"
                style="margin-left: 40%"
                v-if="$store.state.showingProfilesAs == 'projectProfiles'"
              >
                <i class="bi bi-slash-circle me-2"></i>
                No profiles here!</span
              >
            </div>
            <!--end: "No profiles" message-->
          </div>
          <!--begin::Body-->
        </div>
        <!--end::Tables Widget 9-->

        <!--end::Card-->
      </div>
      <!--end::Content-->
    </div>

    <!--end::Main-->
    <!--begin::Footer-->
    <div
      class="footer py-4 d-flex flex-column flex-md-row align-items-center justify-content-between"
      id="kt_footer"
    >
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="text-white opacity-75 fw-bold me-1">2023©</span>
        <a
          href="https://kiwanotalent.com"
          target="_blank"
          class="text-white text-hover-primary opacity-75"
          >Kiwano</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-white menu-hover-primary fw-bold order-1 opacity-75">
        <li class="menu-item">
          <a
            href="https://kiwanotalent.com"
            target="_blank"
            class="menu-link px-2"
            >About</a
          >
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>

import { provide } from "vue";

import Toolbar from "../components/Toolbar-Component.vue";
import StatusDropdown from "../components/Dropdowns/StatusDropdown.vue";
import BatchManipulateBanner from "../components/BatchManipulateBanner.vue";
import {
  getRecentProfilesExternal,
  unsubscribeToRecentProfilesExternal,
  getSingleProfile,
} from "../js/getdata";
import {
  applyProfileStatusExternal,
  selectProfileExternal,
  selectAllProfilesExternal,
} from "../js/profilelist";
import { goToDetailedProfileExternal } from "../js/navigation";
import { newUserHasLoggedInExternal } from "../js/auth";
import store from "../store/index";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
    provide() {
    return {
      isSticky: () => this.isSticky,
    };
  },
  data: () => ({
    options: ["A", "B", "C"],
    value: "B",
    waitWhileLoadingMoreProfiles: false,
    //loadedProfiles: store.state.currentProfileList.slice(2, 5)
    profileDarkBG: false, //highlight profile when clicking back to profilelist from profiledetails
    isSticky: false,
  }),
  components: {
    Toolbar,
    BatchManipulateBanner,
    StatusDropdown,
  },
  created() {
    store.state.currentRoute = this.$route.path;
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$store.state.isSticky = false;
  },
  computed: {
    //this is not ideal as I think it reloads the whole slice of currentProfileLIst every time. Ideally only the next slice should be loaded,
    //but to achieve this we would have to be able to push only the next slice into this computed property. Apparently the way to go
    //is by using a set method, but we were not able to make it work. So the only benefit of this is to speed up the initial render of
    //the first batch. But then, the more the user scrolls down, the longer the loading time for every new slice...
    computedLoadedProfiles() {
      if (
        store.state.numberOfProfilesLoaded ==
        store.state.numberOfProfilesLoadedInitially
      ) {
        return store.state.currentProfileList.slice(
          0,
          store.state.numberOfProfilesLoadedInitially
        );
      } else if (
        store.state.numberOfProfilesLoaded ==
        store.state.numberOfProfilesLoadedInitially +
          store.state.numberOfProfileToAdd
      ) {
        return store.state.currentProfileList.slice(
          0,
          store.state.numberOfProfilesLoadedInitially +
            store.state.numberOfProfileToAdd
        );
      } else if (
        store.state.numberOfProfilesLoaded ==
        store.state.numberOfProfilesLoadedInitially +
          store.state.numberOfProfileToAdd * 2
      ) {
        return store.state.currentProfileList.slice(
          0,
          store.state.numberOfProfilesLoadedInitially +
            store.state.numberOfProfileToAdd * 2
        );
      } else if (
        store.state.numberOfProfilesLoaded ==
        store.state.numberOfProfilesLoadedInitially +
          store.state.numberOfProfileToAdd * 3
      ) {
        return store.state.currentProfileList.slice(
          0,
          store.state.numberOfProfilesLoadedInitially +
            store.state.numberOfProfileToAdd * 3
        );
      } else if (
        store.state.numberOfProfilesLoaded >
        store.state.numberOfProfilesLoadedInitially +
          store.state.numberOfProfileToAdd * 3
      ) {
        return store.state.currentProfileList;
      }
    },
  },
  mounted() {

window.addEventListener("scroll", this.handleScroll);

    //scroll down to where the user was before going to the detailed profile, so that they don't have to look for the profile in the list
    if (this.$store.state.hitBack) {
      window.scrollTo(0, this.$store.state.profileListScrollPosition);
      this.$store.state.hitBack = false;
      this.profileDarkBG = true;
      setTimeout(() => {
        this.profileDarkBG = false;
      }, 3000);
    }

    //clear selected profiles list and Vuex switch
    store.state.selectAllProfilesChecked = false;
    let selectedProfiles = store.state.selectedProfiles;
    selectedProfiles.splice(0, selectedProfiles.length);

    //check if user is logging in for the first time. if so, then mark them as loggedin so they won't get the WelcomeUser modal anymore
    //this is here in profilelist.vue because the Profile tab  / route is the default one shown on log-in. So it has to be here.
    setTimeout(() => {
      if (store.state.userData.firsttimeloggedin) {
        newUserHasLoggedInExternal();
        // Configure the modal to not close when clicking the backdrop or pressing the `Esc` key
$("#welcomeUser").modal({
  backdrop: 'static',
  keyboard: false
});

// Show the modal
$("#welcomeUser").modal("show");
      }
    }, 1000);

    //listen to scroll event: load more profiles when on page botttom
    this.scroll();
  },

  methods: {
    copyToClipboard(profile) {
      console.log(profile)
    },
    logYScroll() {
      this.$store.state.profileListScrollPosition = window.scrollY;
    },
    storeFileForDisplay(file) {
      this.$store.state.fileToShow = false;
      this.$store.state.fileToShow = file;
    },
    scrapeLi() {
      const functions = getFunctions();
      const getLiProfile = httpsCallable(functions, "getLiProfileWithIscraper");
      getLiProfile(
        "https://www.linkedin.com/in/julie-valette-b1bb561/",
        "nothing"
      )
        .then((result) => {
          console.log(result.data);
        })
        .catch((e) => console.log(e));
    },

    createJson() {
      let finalObject = [];
      for (let i = 0; i < this.$store.state.currentProfileList.length; i++) {
        finalObject.push();
      }
      console.log(finalObject);
    },
    goToDetailedProfile(profileId) {
      this.logYScroll();
      goToDetailedProfileExternal(profileId);
    },
    applyProfileStatus(status, profileid) {
      //import from js/profilelist.js
      applyProfileStatusExternal(status, profileid);
    },
    unsubscribeToRecentProfiles() {
      unsubscribeToRecentProfilesExternal();
    },
    selectProfile(profileId) {
      selectProfileExternal(profileId);
    },
    selectAllProfiles() {
      selectAllProfilesExternal();
    },
    computedStatus(statusid) {
      //this takes the profile status id and looks for the corresponding
      //status in the team defined status list.
      //Then it returns the name of the status insead of the id, so that it becomes readable by user

      let allstatuses = store.state.profileStatusList;

      //If no status has been assigned yet, the profilestatusid in Firestore and Vuex is 'xxx' by default
      if (statusid == "xxx") {
        return "No status";
      }
      //If a status has been assigned...
      else {
        //look for the corresponding status
        let statusobject = allstatuses.find((o) => o.id === statusid);

        //if there is no corresponding status in the statuslist because the status has been deleted by user from the statuslist...
        if (statusobject == undefined) {
          return "No status";
        }
        //if there is a corresponding status in the statuslist...
        else {
          //return status name
          return statusobject.status;
        }
      }
    },
    profilesSortedByLastName() {
      function compare(a, b) {
        if (a.lastname < b.lastname) {
          return -1;
        }
        if (a.lastname > b.lastname) {
          return 1;
        }
        return 0;
      }
    },
    //listen to scroll event: load more profiles when on page botttom
    scroll() {
      window.onscroll = () => {
        store.state.scrolledToBottom =
          Math.floor(
            Math.max(
              window.pageYOffset,
              document.documentElement.scrollTop,
              document.body.scrollTop
            ) + window.innerHeight
          ) >
          document.getElementById("app").offsetHeight - 80;

        if (
          store.state.scrolledToBottom &&
          !this.waitWhileLoadingMoreProfiles
        ) {
          this.waitWhileLoadingMoreProfiles = true;
          console.log("reached bottom");
          setTimeout(() => {
            if (
              store.state.currentProfileList.length >
              store.state.numberOfProfilesLoaded
            ) {
              store.state.numberOfProfilesLoaded =
                store.state.numberOfProfilesLoaded +
                store.state.numberOfProfileToAdd;

              //if Select all profiles is checked, then click twice on the checkbox to make sure that the newly loaded profiles are checked too
              if (store.state.selectAllProfilesChecked) {
                document.getElementById("selectAllBtn").click();
                setTimeout(() => {
                  //delay because otherwise the second click is not detected
                  document.getElementById("selectAllBtn").click();
                }, 200);
              }
            }

            //prevents the scroll to bottom event to fire for every pixel after pre-bottom (document.getElementById("app").offsetHeight - 80;)
            this.waitWhileLoadingMoreProfiles = false;
          }, 500);
        }
      };
    },
    handleScroll() {
      const toolbarElement = this.$refs.toolbar.$el;
      const toolbarRect = toolbarElement.getBoundingClientRect();
      const isStickyNow = toolbarRect.top <= 0;

      if (isStickyNow !== this.$store.state.isSticky) {
        this.$store.state.isSticky = isStickyNow;
      }
    },
  },
};
</script>



