<template>

  <!--begin: Page Header-->
  <div class="row mt-10 ms-10">
    <div class="d-flex">
      <div class="">
        <img height="50" src="../assets/media/logos/kiwano.png" alt="" />
      </div>
    </div>
  </div>
  <!--end: Page Header-->

  <Transition name="fade" mode="out-in">
  <div v-if="!hasaccepted && !hasdeclined" class="d-flex">
    <div class="mx-auto d-flex flex-column mt-15 fs-5" style="width: 40%">
      <div class="mb-10 text-light">
        At <span class="fw-boldest">{{ $store.state.teamData.teamname }}</span
        >, we value your privacy and are committed to protecting your personal
        information. In accordance with the General Data Protection Regulation
        (GDPR), this page to provides you with the option to
        accept or decline the storage of your personal data.
        <br><br>
        Please reach out to your contact at <span class="fw-boldest">{{ $store.state.teamData.teamname }}</span
        > for more information on our privacy policy.
      </div>

      <!--begin::Contacts-->
      <div class="card card-flush" id="kt_contacts_main">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <i class="ki-duotone ki-badge fs-1 me-2"
              ><span class="path1"></span><span class="path2"></span
              ><span class="path3"></span><span class="path4"></span
              ><span class="path5"></span
            ></i>
            <h2>Personal details</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Profile-->
          <div class="d-flex gap-7 align-items-center">
            <!--begin::Avatar-->
            <div v-if="profilepic != '(No data)'" class="symbol symbol-circle symbol-100px">
              <img :src="profilepic" alt="image" />
            </div>
            <!--end::Avatar-->

            <!--begin::Contact details-->
            <div class="d-flex flex-column gap-2">
              <!--begin::Name-->
              <h3 class="mb-0">{{ firstname }} {{ lastname }}</h3>
              <!--end::Name-->

              <!--begin::Email-->
              <div class="d-flex align-items-center">
                <i class="ki-duotone ki-sms fs-2"
                  ><span class="path1"></span><span class="path2"></span></i
                ><a href="#" class="text-muted text-hover-primary"
                  >Email: {{ email }}</a
                >
              </div>
              <!--end::Email-->

              <!--begin::Phone-->
              <div class="d-flex align-items-center">
                <i class="ki-duotone ki-phone fs-2"
                  ><span class="path1"></span><span class="path2"></span></i
                ><a href="#" class="text-muted text-hover-primary"
                  >Phone: {{ phone }}</a
                >
              </div>
              <!--end::Phone-->
            </div>
            <!--end::Contact details-->
          </div>
          <!--end::Profile-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contacts-->

          <div class="mx-auto mt-10">
            <button class="me-7 btn btn-lg btn-danger" @click="sendConsentStatus(profileid, 'Decline')">
        Decline
      </button>
      <button class="btn btn-lg btn-success" @click="sendConsentStatus(profileid, 'Accept')">Accept</button
      >
    </div>
    </div>


  </div>

  <div v-else>


    <div class="mx-auto d-flex flex-column fs-5" style="width: 40%; margin-top: 10%">
      <div class="mb-5 text-light">
        We appreciate your preferences. For inquiries about their privacy policy, kindly consult  <span class="fw-boldest">{{ $store.state.teamData.teamname }}.</span
        >  You reserve the right to request them to modify or remove your personal data at any time.
      </div>

 

  
    </div>


  

  </div>
  </Transition>
</template>

<script>
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  data() {
    return {
      firstname: "",
      lastname: "",
      currentjobtitle: "",
      currentcompany: "",
      email: "",
      phone: "",
      profilepic: "",
      profileid: "",
      hasaccepted: false,
      hasdeclined: false,
    };
  },

  mounted() {
    //query string example: http://localhost:8080/#/gdprexternal?firstname=Bruno&lastname=Tailleur&currentjobtitle=Directeur&currentcompany=Danone&email=bruno@sqdf.com&phone=+101545&profileid=123456789azerty

    //get te query string. if the profile has a profile picture, a second "?" will be included in the picture url,
    //in that case we need to add the third element of the array

    let queryString = window.location.href.split("?")[1];

    const queryStringArray = queryString.split("&");

    //the object holding the query string parameters
    //params: teamid (not used yet), projectid, teamlogourl
    let params = {};

    for (let i = 0; i < queryStringArray.length; i++) {
      const thisParamArray = queryStringArray[i].split("=");
      const key = thisParamArray[0];
      const value = thisParamArray[1];
      params[key] = value;
    }

    this.firstname = params.firstname;

    if (params.firstname == "undefined") {
      this.firstname = "(No data)";
    } else {
      this.firstname = params.firstname
          .replaceAll("%20", " ")
        .replaceAll("%C3%80", "À")
        .replaceAll("%C3%81", "Á")
        .replaceAll("%C3%82", "Â")
        .replaceAll("%C3%83", "Ã")
        .replaceAll("%C3%84", "Ä")
        .replaceAll("%C3%85", "Å")
        .replaceAll("%C3%86", "Æ")
        .replaceAll("%C3%87", "Ç")
        .replaceAll("%C3%88", "È")
        .replaceAll("%C3%89", "É")
        .replaceAll("%C3%8A", "Ê")
        .replaceAll("%C3%8B", "Ë")
        .replaceAll("%C3%8C", "Ì")
        .replaceAll("%C3%8D", "Í")
        .replaceAll("%C3%8E", "Î")
        .replaceAll("%C3%8F", "Ï")
        .replaceAll("%C3%90", "Ð")
        .replaceAll("%C3%91", "Ñ")
        .replaceAll("%C3%92", "Ò")
        .replaceAll("%C3%93", "Ó")
        .replaceAll("%C3%94", "Ô")
        .replaceAll("%C3%95", "Õ")
        .replaceAll("%C3%96", "Ö")
        .replaceAll("%C3%97", "×")
        .replaceAll("%C3%98", "Ø")
        .replaceAll("%C3%99", "Ù")
        .replaceAll("%C3%9A", "Ú")
        .replaceAll("%C3%9B", "Û")
        .replaceAll("%C3%9C", "Ü")
        .replaceAll("%C3%9D", "Ý")
        .replaceAll("%C3%9E", "Þ")
        .replaceAll("%C3%9F", "ß")
        .replaceAll("%C3%A0", "à")
        .replaceAll("%C3%A1", "á")
        .replaceAll("%C3%A2", "â")
        .replaceAll("%C3%A3", "ã")
        .replaceAll("%C3%A4", "ä")
        .replaceAll("%C3%A5", "å")
        .replaceAll("%C3%A6", "æ")
        .replaceAll("%C3%A7", "ç")
        .replaceAll("%C3%A8", "è")
        .replaceAll("%C3%A9", "é")
        .replaceAll("%C3%AA", "ê")
        .replaceAll("%C3%AB", "ë")
        .replaceAll("%C3%AC", "ì")
        .replaceAll("%C3%AD", "í")
        .replaceAll("%C3%AE", "î")
        .replaceAll("%C3%AF", "ï")
        .replaceAll("%C3%B0", "ð")
        .replaceAll("%C3%B1", "ñ")
        .replaceAll("%C3%B2", "ò")
        .replaceAll("%C3%B3", "ó")
        .replaceAll("%C3%B4", "ô")
        .replaceAll("%C3%B5", "õ")
        .replaceAll("%C3%B6", "ö")
        .replaceAll("%C3%B7", "÷")
        .replaceAll("%C3%B8", "ø")
        .replaceAll("%C3%B9", "ù")
        .replaceAll("%C3%BA", "ú")
        .replaceAll("%C3%BB", "û")
        .replaceAll("%C3%BC", "ü");
    }

    if (params.lastname == "undefined") {
      this.lastname = "(No data)";
    } else {
      this.lastname = params.lastname
          .replaceAll("%20", " ")
        .replaceAll("%C3%80", "À")
        .replaceAll("%C3%81", "Á")
        .replaceAll("%C3%82", "Â")
        .replaceAll("%C3%83", "Ã")
        .replaceAll("%C3%84", "Ä")
        .replaceAll("%C3%85", "Å")
        .replaceAll("%C3%86", "Æ")
        .replaceAll("%C3%87", "Ç")
        .replaceAll("%C3%88", "È")
        .replaceAll("%C3%89", "É")
        .replaceAll("%C3%8A", "Ê")
        .replaceAll("%C3%8B", "Ë")
        .replaceAll("%C3%8C", "Ì")
        .replaceAll("%C3%8D", "Í")
        .replaceAll("%C3%8E", "Î")
        .replaceAll("%C3%8F", "Ï")
        .replaceAll("%C3%90", "Ð")
        .replaceAll("%C3%91", "Ñ")
        .replaceAll("%C3%92", "Ò")
        .replaceAll("%C3%93", "Ó")
        .replaceAll("%C3%94", "Ô")
        .replaceAll("%C3%95", "Õ")
        .replaceAll("%C3%96", "Ö")
        .replaceAll("%C3%97", "×")
        .replaceAll("%C3%98", "Ø")
        .replaceAll("%C3%99", "Ù")
        .replaceAll("%C3%9A", "Ú")
        .replaceAll("%C3%9B", "Û")
        .replaceAll("%C3%9C", "Ü")
        .replaceAll("%C3%9D", "Ý")
        .replaceAll("%C3%9E", "Þ")
        .replaceAll("%C3%9F", "ß")
        .replaceAll("%C3%A0", "à")
        .replaceAll("%C3%A1", "á")
        .replaceAll("%C3%A2", "â")
        .replaceAll("%C3%A3", "ã")
        .replaceAll("%C3%A4", "ä")
        .replaceAll("%C3%A5", "å")
        .replaceAll("%C3%A6", "æ")
        .replaceAll("%C3%A7", "ç")
        .replaceAll("%C3%A8", "è")
        .replaceAll("%C3%A9", "é")
        .replaceAll("%C3%AA", "ê")
        .replaceAll("%C3%AB", "ë")
        .replaceAll("%C3%AC", "ì")
        .replaceAll("%C3%AD", "í")
        .replaceAll("%C3%AE", "î")
        .replaceAll("%C3%AF", "ï")
        .replaceAll("%C3%B0", "ð")
        .replaceAll("%C3%B1", "ñ")
        .replaceAll("%C3%B2", "ò")
        .replaceAll("%C3%B3", "ó")
        .replaceAll("%C3%B4", "ô")
        .replaceAll("%C3%B5", "õ")
        .replaceAll("%C3%B6", "ö")
        .replaceAll("%C3%B7", "÷")
        .replaceAll("%C3%B8", "ø")
        .replaceAll("%C3%B9", "ù")
        .replaceAll("%C3%BA", "ú")
        .replaceAll("%C3%BB", "û")
        .replaceAll("%C3%BC", "ü");
    }

    if (params.currentjobtitle == "undefined") {
      this.currentjobtitle = "(No data)";
    } else {
      this.currentjobtitle = params.currentjobtitle.replace(/%20/g, " ");
    }

    if (params.currentcompany == "undefined") {
      this.currentcompany = "(No data)";
    } else {
      this.currentcompany = params.currentcompany.replace(/%20/g, " ");
    }

    if (params.email == "undefined") {
      this.email = "(No data)";
    } else {
      this.email = params.email;
    }

    if (params.phone == "undefined") {
      this.phone = "(No data)";
    } else {
      this.phone = params.phone;
    }

    if (params.profilepic == "undefined") {
      this.profilepic = "(No data)";
    } else {
      this.profilepic = params.profilepic
        .replace(/XXXXX/g, "?")
        .replace(/YYYYY/g, "=")
        .replace(/ZZZZZ/g, "&");
    }

    this.profileid = params.profileid;
    console.log(this.profileid)


  },

  methods: {
    sendConsentStatus(profileid, consentstatus) {
      //const url = store.state.urlToPass;

      console.log(profileid);

      const dataToPass = {
        profileid: profileid,
        consentstatus: consentstatus,
      };

      const functions = getFunctions();
      const getConsentStatusFromCandidate = httpsCallable(
        functions,
        "getConsentStatusFromCandidate"
      );
      getConsentStatusFromCandidate(dataToPass, "nothing");

      if(consentstatus == "Accept") {
        this.hasaccepted = true; 
      } else if (consentstatus == "Decline") {
        this.hasdeclined = true; 
      }

      /*
      const datatopass = profileid
      const body = { datatopass };

      axios
        .post(
          "https://us-central1-kiwano-3f6de.cloudfunctions.net/receiveConsentStatusFromCandidate",
          body
        )
        .then((response) => {
          console.log(response.data);

          })
        .catch((err) => {
          console.log(err);
        });

        */
    },
  },
};
</script>

<style>
</style>