<template>
  <!--begin::Mixed Widget 5-->
  <div class="card card-xxl-stretch mb-7">
    <!--begin::Header-->
    <div class="card-header border-0 py-1">
      <h3
        class="card-title align-items-start flex-column"
        :class="{
          'mt-4':
            whichCard == 'profiletimeline' ||
            whichCard == 'profileexperience' ||
            whichCard == 'emails' ||
            whichCard == 'filterprofilelist',
            'mt-8': whichCard == 'profiletasks' || subTitle,
        }"
      >
        <span
          v-if="
            whichCard == 'searchfromchromeextension' ||
            whichCard == 'searchfromdesktopversion'
          "
          class="card-label fw-bolder fs-3 mb-1 mt-6"
          >{{ title }}</span
        >

        <span
          v-if="
            whichCard != 'searchfromchromeextension' &&
            whichCard != 'searchfromdesktopversion' &&
            whichCard != 'searchfrommobileversion'
          "
          class="card-label fw-bolder fs-3 mb-1"
          >{{ title }}</span
        >
        <span
          v-if="subTitle == 'search' && whichCard != 'searchfrommobileversion'"
          class="text-muted fw-bold fs-7 mt-2"
        >
          <span class="badge badge-circle badge-primary">
            {{ $store.state.searchResults.length }}
          </span>
          profiles found
        </span>
        <span
          v-if="subTitle != 'none' && subTitle != 'search'"
          class="text-muted fw-bold fs-7"
          >{{ subTitle }}</span
        >

        <!--begin: SUB HEADER (LEFT) BUTTONS IN CARDS-->

        <!--begin: Tasks Filter button-->
        <span
          v-if="whichCard == 'profiletasks'"
          class="badge badge-light mt-1 custompointer"
          data-bs-toggle="dropdown"
          id="taskFilterButton"
        >
          <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-5">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <!--end::Svg Icon-->
          Show/hide
        </span>

        <div
          v-if="whichCard == 'profiletasks'"
          class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-200px py-7 dropdownFadeIn"
          aria-labelledby="taskFilterButton"
          role="menu"
        >
          <div
            class="menu-item my-0 bg-hover-light"
            v-if="!$store.state.showcompletedtasks"
            @click="
              $store.state.showcompletedtasks =
                !$store.state.showcompletedtasks;
              $store.state.showonlymytasks = false;
            "
          >
            <a class="menu-link text-muted py-2">
              <span class="menu-icon" data-kt-element="icon">
                <i
                  class="bi bi-eye-fill text-primary"
                  style="font-size: 1.5rem"
                ></i>
              </span>
              <span class="">Show completed</span>
            </a>
          </div>

          <div
            class="menu-item my-0 bg-hover-light"
            v-if="$store.state.showcompletedtasks"
            @click="
              $store.state.showcompletedtasks =
                !$store.state.showcompletedtasks;
              $store.state.showonlymytasks = false;
            "
          >
            <a class="menu-link text-muted py-2">
              <span class="menu-icon" data-kt-element="icon">
                <i
                  class="bi bi-eye-slash-fill text-primary"
                  style="font-size: 1.5rem"
                ></i>
              </span>
              <span class="">Hide completed</span>
            </a>
          </div>

          <div class="menu-item my-0 bg-hover-light">
            <a
              class="menu-link text-muted py-2 active"
              @click="
                $store.state.showcompletedtasks = false;
                $store.state.showonlymytasks = true;
              "
              v-if="!$store.state.showonlymytasks"
            >
              <div
                class="timeline-icon symbol symbol-circle symbol-20px me-3 ms-1"
              >
                <img :src="$store.state.userData.avatarurl" alt="img" />
              </div>
              <span class="">My tasks only</span>
            </a>
          </div>
          <div class="menu-item my-0 bg-hover-light">
            <a
              class="menu-link text-muted py-2 active"
              @click="
                $store.state.showcompletedtasks = false;
                $store.state.showonlymytasks = false;
              "
              v-if="$store.state.showonlymytasks"
            >
              <div
                class="timeline-icon symbol symbol-circle symbol-20px me-3 ms-1"
              >
                <img :src="$store.state.teamData.logourl" alt="img" />
              </div>
              <span class="">Everyone's tasks</span>
            </a>
          </div>
        </div>
        <!--end: Tasks Filter button-->

        <!--begin: Profilelist filter strict mode button, not used currently-->
        <div
          v-if="whichCard == 'filterprofilelist'"
          class="d-none form-check form-switch form-switch-sm form-check-custom form-check-solid mt-1"
        >
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            name="notifications"
            v-model="$store.state.strictMode"
          />
          <label class="form-check-label fs-7 text-muted">Strict mode</label>
        </div>
        <!--end: Profilelist filter strict mode button, not used currently-->

        <!--end: SUB HEADER (LEFT) BUTTONS IN CARDS-->
      </h3>

      <!--begin: TOP RIGHT ACTION BUTTONS IN CARDS-->

      <div class="card-toolbar">
        <!--begin::Main button-->

        <!--begin: Attach to project Button-->
        <button
          v-if="whichCard == 'projectprofileactivity'"
          class="btn btn-sm btn-flex btn-light-primary"
          type="button"
          id="selectProjectToAttachProfileButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Add to a project
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
          <span class="ms-2 svg-icon svg-icon-4 m-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                fill="black"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <ul
          class="dropdown-menu w-300px w-md-350px py-7 px-7 dropdownFadeIn"
          aria-labelledby="selectProjectToAttachProfileButton"
          role="menu"
        >
          <div class="scroll-y mh-150px mh-lg-325px">
            <!--begin::Heading-->
            <div class="d-flex flex-stack fw-bold mb-3">
              <!--begin::Label-->
              <span class="text-muted fs-6 me-2">My projects :</span>
              <!--end::Label-->
            </div>
            <!--end::Heading-->
            <!--begin::Items-->
            <div class="">
              <!--begin::Item-->
              <li
                v-for="project in myActiveProjects"
                :key="project.id"
                @click="
                  attachProfileToProject(
                    $store.state.currentProfileDetails.id,
                    project
                  )
                "
                class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem"
              >
                <!--begin::Symbol-->
                <div class="symbol symbol-40px me-4 companylogo">
                  <img
                    v-if="project.clientobject.clientlogourl"
                    :src="project.clientobject.clientlogourl"
                    :class="{
                      customfaded:
                        $store.state.currentProfileProjectBindings.filter(
                          (e) => e.projectid === project.id
                        ).length > 0,
                    }"
                    alt=""
                  />
                  <img
                    v-else
                    src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                    :class="{
                      customfaded:
                        $store.state.currentProfileProjectBindings.filter(
                          (e) => e.projectid === project.id
                        ).length > 0,
                    }"
                    alt=""
                  />
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <a class="d-flex flex-column" href="javascript:void(0)">
                  <span
                    class="fs-6 text-gray-800 fw-bold"
                    :class="{
                      customfaded:
                        $store.state.currentProfileProjectBindings.filter(
                          (e) => e.projectid === project.id
                        ).length > 0,
                    }"
                    >{{ project.positiontitle }}</span
                  >
                  <span
                    class="fs-7 text-muted fw-bold"
                    :class="{
                      customfaded:
                        $store.state.currentProfileProjectBindings.filter(
                          (e) => e.projectid === project.id
                        ).length > 0,
                    }"
                    >{{ project.clientobject.clientname }}</span
                  >
                </a>
                <!--end::Title-->
              </li>
              <!--end::Item-->
            </div>
            <!--end:items-->

            <!--begin::Heading-->
            <div class="d-flex flex-stack fw-bold mb-3 mt-3">
              <!--begin::Label-->
              <span class="text-muted fs-6 me-2"
                >Other projects from team :</span
              >
              <!--end::Label-->
            </div>
            <!--end::Heading-->
            <!--begin::Items-->
            <div class="">
              <!--begin::Item-->
              <li
                v-for="project in teamActiveProjects"
                :key="project.id"
                @click="
                  attachProfileToProject(
                    $store.state.currentProfileDetails.id,
                    project
                  )
                "
                class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem"
              >
                <!--begin::Symbol-->
                <div class="symbol symbol-40px me-4 companylogo">
                  <img
                    :src="project.clientobject.clientlogourl"
                    :class="{
                      customfaded:
                        $store.state.currentProfileProjectBindings.filter(
                          (e) => e.projectid === project.id
                        ).length > 0,
                    }"
                    alt=""
                  />
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <a class="d-flex flex-column" href="javascript:void(0)">
                  <span
                    class="fs-6 text-gray-800 fw-bold"
                    :class="{
                      customfaded:
                        $store.state.currentProfileProjectBindings.filter(
                          (e) => e.projectid === project.id
                        ).length > 0,
                    }"
                    >{{ project.positiontitle }}</span
                  >
                  <span
                    class="fs-7 text-muted fw-bold"
                    :class="{
                      customfaded:
                        $store.state.currentProfileProjectBindings.filter(
                          (e) => e.projectid === project.id
                        ).length > 0,
                    }"
                    >{{ project.clientobject.clientname }}</span
                  >
                </a>
                <!--end::Title-->
              </li>
              <!--end::Item-->
            </div>
            <!--end:items-->
          </div>
        </ul>
        <!--end: Attach to project Button-->

        <!--begin: Add profile comment Button-->
        <button
          v-if="whichCard == 'profilecomments'"
          class="btn btn-icon btn-flex btn-light-primary"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addProfileComment"
          @click="initializeAddCommentModal()"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr075.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width="16"
                height="2"
                rx="1"
                transform="rotate(-90 11.364 20.364)"
                fill="currentColor"
              />
              <rect
                x="4.36396"
                y="11.364"
                width="16"
                height="2"
                rx="1"
                fill="currentColor"
              /></svg
          ></span>
          <!--end::Svg Icon-->
        </button>
        <!--end: Add profile comment Button-->

        <!--begin: Add task-->
        <button
          v-if="whichCard == 'profiletasks'"
          class="btn btn-sm btn-flex btn-light-primary"
          type="button"
          id="openAddTaskButton"
          data-bs-toggle="modal"
          data-bs-target="#addProfileTask"
          @click="loadOrResetTaskModal()"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr075.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width="16"
                height="2"
                rx="1"
                transform="rotate(-90 11.364 20.364)"
                fill="currentColor"
              />
              <rect
                x="4.36396"
                y="11.364"
                width="16"
                height="2"
                rx="1"
                fill="currentColor"
              /></svg
          ></span>
          <!--end::Svg Icon-->
          Add task
        </button>
        <!--end: Add task-->

        <!--begin: Profile tags edit button-->
        <a
          v-if="whichCard === 'profiletags'"
          class="btn btn-light-primary btn-sm"
          style="margin-bottom: -6px"
          id="openEditTagsButton"
          data-bs-toggle="modal"
          data-bs-target="#editProfileTags"
          @click="initializeEditTagsModalForm()"
        >
          Edit taglist
        </a>
        <!--end: Profile tags edit button-->

        <!--begin: New email button-->
        <button
          v-if="
            whichCard == 'emails' && $store.state.currentProfileDetails.email && $store.state.userData.nylasemailtoken
          "
          class="mt-3 btn btn-sm btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#email"
          @click="initializeNewEmailModal()"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr075.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width="16"
                height="2"
                rx="1"
                transform="rotate(-90 11.364 20.364)"
                fill="currentColor"
              />
              <rect
                x="4.36396"
                y="11.364"
                width="16"
                height="2"
                rx="1"
                fill="currentColor"
              /></svg
          ></span>
          <!--end::Svg Icon-->
          New email
        </button>
        <!--end: New email button-->

        <!--begin: Edit Project/Client infos-->
        <a
          v-if="whichCard == 'info'"
          class="mt-3 btn btn-sm btn-icon btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#editprojectandclientinfo"
          style="z-index: 3"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/art/art005.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                fill="currentColor"
              />
              <path
                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </a>
        <!--end: Edit Project/Client infos-->

        <!--begin: Edit Profile Experience-->
        <a
          v-if="whichCard == 'profileexperiencerich'"
          class="mt-3 btn btn-sm btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#editprofileexperience"
          style="z-index: 3"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/art/art005.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-6"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                fill="currentColor"
              />
              <path
                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          <span class="ms-1">Edit</span>
        </a>
        <!--end: Edit Profile Experience-->

        <!--begin: Upload Project/Client file-->
        <label
          for="uploadProjectFile"
          class="btn btn-sm btn-light-primary"
          v-if="whichCard == 'files'"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/arrows/arr078.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="12.75"
                y="4.25"
                width="12"
                height="2"
                rx="1"
                transform="rotate(90 12.75 4.25)"
                fill="currentColor"
              />
              <path
                d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                fill="currentColor"
              />
              <path
                opacity="0.3"
                d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          Upload file
        </label>
        <input
          v-if="whichCard == 'files'"
          id="uploadProjectFile"
          class="d-none"
          type="file"
          @change="uploadFile()"
        />
        <!--end: Upload Project/Client file-->

        <!--begin: Add project / client note -->
        <button
          v-if="whichCard == 'notes'"
          class="btn btn-sm btn-icon btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#addprojectorclientnotes"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr075.svg-->
          <span
            v-if="
              ($store.state.currentRoute == '/clients' &&
                !$store.state.currentClientDetails.notes) ||
              ($store.state.currentRoute == '/projects' &&
                !$store.state.currentProjectDetails.notes)
            "
            class="svg-icon svg-icon-muted svg-icon-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width="16"
                height="2"
                rx="1"
                transform="rotate(-90 11.364 20.364)"
                fill="currentColor"
              />
              <rect
                x="4.36396"
                y="11.364"
                width="16"
                height="2"
                rx="1"
                fill="currentColor"
              /></svg
          ></span>
          <!--end::Svg Icon-->

          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/art/art005.svg-->
          <span v-else class="svg-icon svg-icon-muted svg-icon-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                fill="currentColor"
              />
              <path
                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <!--end: Add project / client note -->

        <!--begin::Add project user-->
        <button
          class="btn btn-light-primary btn-sm"
          type="button"
          id="addprojectuserdropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          v-if="
            whichCard == 'projectusers' &&
            $store.state.userData.userid ==
              $store.state.currentProjectDetails.owninguser &&
              $store.state.currentProjectDetails.teamaccess == 'Public'
          "
        >
          <div class="symbol symbol-circle symbol-25px">
            Add user
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
            <span class="ms-1 svg-icon svg-icon-4 m-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
        </button>
        <div
          class="dropdown-menu menu-sub menu-sub-dropdown dropdownFadeIn p-5"
          aria-labelledby="addprojectuserdropdown"
        >
          <div
            v-if="computedProjectUsers.length > 0"
            class="d-flex flex-column"
          >
            <div
              class="btn btn-sm bg-gray-100 bg-hover-gray-600 me-3 w-150px mb-3"
              @click="addUser(user.userid)"
              v-for="user in computedProjectUsers"
              :key="user.userid"
            >
              <div class="d-flex align-items-center">
                <div
                  v-if="user.avatarurl"
                  class="symbol symbol-circle symbol-30px mt-1"
                >
                  <img
                    :src="user.avatarurl"
                    class="h-30 align-self-center"
                    alt=""
                  />
                </div>
                <div v-else class="symbol symbol-circle symbol-30px mt-1">
                  <div class="symbol-label fs-4 fw-semibold text-primary">
                    {{
                      user.firstname.substring(0, 1) +
                      user.lastname.substring(0, 1)
                    }}
                  </div>
                </div>
                <a class="ms-2 menu-link text-muted py-1"
                  ><span class="text-dark fw-bolder fs-6">{{
                    user.firstname
                  }}</span></a
                >
              </div>
            </div>
          </div>
          <div v-else>No other users.</div>
        </div>
        <!--end::Add project user-->

        <!--begin::Join project-->
        <button
          @click="joinProject()"
          class="btn btn-sm btn-light-primary"
          v-if="
            whichCard == 'projectusers' &&
            $store.state.userData.userid !=
              $store.state.currentProjectDetails.owninguser &&
            $store.state.currentProjectDetails &&
            !$store.state.currentProjectDetails.otherusers.includes(
              $store.state.userData.userid
            ) && 
            $store.state.currentProjectDetails.teamaccess == 'Public'
          "
        >
          Join project
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr076.svg-->
          <span
            class="svg-icon svg-icon-primary svg-icon-2"
            style="margin-right: -2px"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                width="12"
                height="2"
                rx="1"
                transform="matrix(-1 0 0 1 15.5 11)"
                fill="currentColor"
              />
              <path
                d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                fill="currentColor"
              />
              <path
                d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <!--end::Join project-->

        <!--begin::leave project-->
        <button
          @click="leaveProject()"
          class="btn btn-sm btn-light-danger"
          v-if="
            whichCard == 'projectusers' &&
            $store.state.userData.userid !=
              $store.state.currentProjectDetails.owninguser &&
            $store.state.currentProjectDetails &&
            $store.state.currentProjectDetails.otherusers.includes(
              $store.state.userData.userid
            )
          "
        >
          Leave project
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr077.svg-->
          <span
            class="svg-icon svg-icon-danger svg-icon-2"
            style="margin-right: -2px"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="4"
                y="11"
                width="12"
                height="2"
                rx="1"
                fill="currentColor"
              />
              <path
                d="M5.86875 11.6927L7.62435 10.2297C8.09457 9.83785 8.12683 9.12683 7.69401 8.69401C7.3043 8.3043 6.67836 8.28591 6.26643 8.65206L3.34084 11.2526C2.89332 11.6504 2.89332 12.3496 3.34084 12.7474L6.26643 15.3479C6.67836 15.7141 7.3043 15.6957 7.69401 15.306C8.12683 14.8732 8.09458 14.1621 7.62435 13.7703L5.86875 12.3073C5.67684 12.1474 5.67684 11.8526 5.86875 11.6927Z"
                fill="currentColor"
              />
              <path
                d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <!--end::leave project-->

        <!--begin::Clear all notifications TODO-->
        <button
          @click="clearAll()"
          class="d-none btn btn-sm btn-light-primary"
          v-if="
            whichCard == 'notifications' &&
            $store.state.numberofnotifications > 0
          "
        >
          Clear all
        </button>
        <!--end::Clear all notifications-->

        <button
          @click="closeSearch()"
          class="btn btn-sm btn-icon btn-primary"
          v-if="whichCard == 'searchfromchromeextension'"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr088.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-2hx"
            ><svg
              width="24"
              height="24"
              style="margin-left: -5px; margin-right: -5px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="7.05025"
                y="15.5356"
                width="12"
                height="2"
                rx="1"
                transform="rotate(-45 7.05025 15.5356)"
                fill="currentColor"
              />
              <rect
                x="8.46447"
                y="7.05029"
                width="12"
                height="2"
                rx="1"
                transform="rotate(45 8.46447 7.05029)"
                fill="currentColor"
              /></svg
          ></span>
          <!--end::Svg Icon-->
        </button>

        <button
          @click="resetProfileFilter()"
          class="btn btn-sm btn-light me-3"
          v-if="whichCard == 'filterprofilelist'"
        >
          Reset
        </button>

        <button
          @click="filterProfiles()"
          class="btn btn-sm btn-primary"
          v-if="whichCard == 'filterprofilelist'"
        >
          Apply
        </button>

        <!--end::Main button-->
      </div>

      <!--end: TOP RIGHT ACTION BUTTONS IN CARDS-->
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <Emails v-if="whichCard == 'emails'"></Emails>
      <ProfileSettings v-if="whichCard == 'profileSettings'"></ProfileSettings>
      <ProfileProjectActivity
        v-if="whichCard == 'projectprofileactivity'"
      ></ProfileProjectActivity>
      <ProfileClientIfClient
        v-if="whichCard == 'profileclientifclient'"
      ></ProfileClientIfClient>
      <ProfileTimeline
        v-if="whichCard == 'profiletimeline'"
        location="profiledetailsTimelineCard"
      ></ProfileTimeline>

      <ProfileComments
        v-if="whichCard == 'profilecomments'"
        location="profiledetailsCommentCard"
      ></ProfileComments>

      <ProfileTasks
        v-if="whichCard == 'profiletasks'"
        origin="profiledetails"
      ></ProfileTasks>
      <ProfileExperience
        v-if="whichCard == 'profileexperience'"
      ></ProfileExperience>
      <ProfileExperienceRich
        v-if="whichCard == 'profileexperiencerich'"
      ></ProfileExperienceRich>
      <FilterProfileList
        v-if="whichCard == 'filterprofilelist'"
        :key="$store.state.profileFilterComponentKey"
      ></FilterProfileList>
      <ProfileTags v-if="whichCard == 'profiletags'"></ProfileTags>
      <Search
        v-if="whichCard == 'searchfromdesktopversion'"
        origin="desktopversion"
      ></Search>
      <div
        v-if="whichCard == 'searchfrommobileversion'"
        style="margin-top: -65px"
      >
        <Search
          v-if="whichCard == 'searchfrommobileversion'"
          origin="mobileversion"
        ></Search>
      </div>
      <Search
        v-if="whichCard == 'searchfromchromeextension'"
        origin="chromeextension"
      ></Search>

      <Notifications v-if="whichCard == 'notifications'"></Notifications>

      <Info v-if="whichCard == 'info'"></Info>

      <LatestProfiles v-if="whichCard == 'latestprofiles'"></LatestProfiles>
      <LatestProfiles v-if="whichCard == 'clientcontacts'"></LatestProfiles>
      <RelatedProjects v-if="whichCard == 'relatedprojects'"></RelatedProjects>
      <Files v-if="whichCard == 'files'"></Files>
      <Notes v-if="whichCard == 'notes'"></Notes>
      <ProjectComments v-if="whichCard == 'projectcomments'"></ProjectComments>

      <ProjectUsers v-if="whichCard == 'projectusers'"></ProjectUsers>

      <ProjectClientReporting
        v-if="whichCard == 'projectclientreporting'"
      ></ProjectClientReporting>

      <ProjectAnalytics
        v-if="whichCard == 'projectanalytics'"
      ></ProjectAnalytics>

      <TasksInDashboard
        v-if="whichCard == 'tasksindashboard'"
      ></TasksInDashboard>

      <EmailsInDashboard
        v-if="whichCard == 'emailsindashboard'"
      ></EmailsInDashboard>

      <AIAssistant
        v-if="whichCard == 'aiassistant'"
      ></AIAssistant>

      


    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 5-->
</template>

<script>
import ProfileId from "./ProfileCardContents/ProfileId.vue";
import ProfileSettings from "./ProfileCardContents/ProfileSettings.vue";
import Emails from "./ProfileCardContents/Emails.vue";
import ProfileProjectActivity from "./ProfileCardContents/ProfileProjectActivity.vue";
import ProfileTimeline from "./ProfileCardContents/ProfileTimeline.vue";
import ProfileComments from "./ProfileCardContents/ProfileComments.vue";
import Search from "../Modals/ModalContents/Search.vue";
import ProfileTasks from "./ProfileCardContents/ProfileTasks.vue";
import ProfileExperience from "./ProfileCardContents/ProfileExperience.vue";
import ProfileTags from "./ProfileCardContents/ProfileTags.vue";
import ProfileExperienceRich from "./ProfileCardContents/ProfileExperienceRich.vue";
import ProfileClientIfClient from "./ProfileCardContents/ProfileClientIfClient.vue";
import FilterProfileList from "./ProfileCardContents/FilterProfileList.vue";
import AIAssistant from "./ProjectCardContents/AIAssistant.vue";

import LatestProfiles from "./ProjectAndClientSharedCards/LatestProfiles.vue";
import Files from "./ProjectAndClientSharedCards/ProjectAndClientFiles.vue";
import ProjectComments from "./ProjectCardContents/ProjectComments.vue";

import RelatedProjects from "./ClientCardContents/RelatedProjects.vue";
import Info from "./ProjectAndClientSharedCards/Info.vue";
import Notes from "./ProjectAndClientSharedCards/Notes.vue";

import ProjectClientReporting from "./ProjectCardContents/ProjectClientReporting.vue";

import Notifications from "./EverywhereCardContents/Notifications.vue";

import ProjectAnalytics from "./ProjectCardContents/ProjectAnalytics.vue";

import ProjectUsers from "./ProjectCardContents/ProjectUsers.vue";

import TasksInDashboard from "./DashboardCardContents/TasksInDashboard.vue";

import EmailsInDashboard from "./DashboardCardContents/EmailsInDashboard.vue";

import { filterProfileListExternal } from "../../js/profilelist";

import { attachProfileToProjectExternal } from "../../js/profiledetails";

import {
  uploadProjectFileExternal,
  addUserExternal,
  joinProjectExternal,
  deleteUserFromProjectExternal,
} from "../../js/projectdetails";
import { uploadClientFileExternal } from "../../js/clientdetails";

import { getProfilesForThisProjectExternal } from "../../js/getdata";

export default {
  props: ["whichCard", "title", "subTitle", "buttonText"],
  components: {
    ProfileId,
    ProfileSettings,
    Emails,
    ProfileProjectActivity,
    ProfileTimeline,
    ProfileComments,
    ProfileTasks,
    ProfileExperience,
    ProfileTags,
    ProfileExperienceRich,
    ProfileClientIfClient,
    Search,
    FilterProfileList,
    LatestProfiles,
    Files,
    ProjectComments,
    Info,
    RelatedProjects,
    Notes,
    ProjectClientReporting,
    Notifications,
    ProjectUsers,
    ProjectAnalytics,
    TasksInDashboard,
    EmailsInDashboard,
    AIAssistant,
  },
  methods: {
    uploadFile() {
      if (this.$store.state.currentRoute == "/projects") {
        uploadProjectFileExternal();
      } else if (this.$store.state.currentRoute == "/clients") {
        uploadClientFileExternal();
      }
    },
    resetProfileFilter() {
      $("#filterProfilesButton").dropdown("hide");

      //re-renders the component so that all checkboxes are unchecked
      this.$store.state.profileFilterComponentKey =
        this.$store.state.profileFilterComponentKey + 1;

      console.log(this.$store.state.cachedProfileList);

      //restore the profile list without the filters
      getProfilesForThisProjectExternal(
        this.$store.state.currentProjectDetailsForProfileList.id
      );

      //reset this property to false to get rid of the empty profiles message
      this.$store.state.emptyProfileList = false;

      //makes the blue bullet dissapear on the filter button in toolbar
      this.$store.state.filterAppliedToProfiles = false;

      //empty filter options in vuex that are v-modeled to the checkboxes
      this.$store.state.addedByMe = false;
      this.$store.state.visibleToClient = false;
      this.$store.state.gdprFilterValue = false;
      this.$store.state.tagFilterArray = [];
      this.$store.state.statusFilterArray = [];
    },
    filterProfiles() {
      $("#filterProfilesButton").dropdown("hide");
      this.$store.state.filterAppliedToProfiles = true;
      filterProfileListExternal();
    },
    closeSearch() {
      //when a search is done from ChromeExtension ONLY. Closes the search card and shows the Import card instead
      this.$store.state.searchState = "off";
      this.$store.state.searchInputText = "";
    },
    attachProfileToProject(profileid, project) {
      //located in profiledetails.js
      attachProfileToProjectExternal(profileid, project);

      //close the dropdown
      $("#selectProjectToAttachProfileButton").dropdown("hide");
    },
    initializeAddCommentModal() {
      //this empties the textarea everytime the modal is opened
      this.$store.state.profilecommenttext = "";

      if (
        this.$store.state.currentProfileProjectBindings.length == 0 ||
        this.$store.state.currentProfileDetails.candidateorclient == "Client"
      ) {
        //if this profile does not have projects attached, do nothing
      } else {
        //this ensures that the Attach project checkbox is checked each time the modal opens
        if (this.$store.state.attachprofilecommenttoproject) {
        } else {
          document.getElementById("attachcommentroproject").click();
        }

        //this sets the Show to client Checkbox to unchecked by default
        this.$store.state.showCommentToClient = false;

        //this stores the project details of the currentLatestProfileBinding, displayd in the modal as the default project to attach
        //If the user selects another project to attach, then these values change.


        //add the "linked project" or "no linked project" so the function in profiledetails.js knows whether
        //it should duplicate the comment or not
        let hasLinkedProject;
        if (this.$store.state.currentProfileProjectBindings[0].crossaccountid) {
          hasLinkedProject = true;
        } else {
          hasLinkedProject = false;
        }

        this.$store.state.selectedProjectForCommentAttachment = {
          clientname:
            this.$store.state.currentProfileProjectBindings[0].clientname,
          clientlogo:
            this.$store.state.currentProfileProjectBindings[0].clientlogo,
          positiontitle:
            this.$store.state.currentProfileProjectBindings[0].positiontitle,
          projectid:
            this.$store.state.currentProfileProjectBindings[0].projectid,
          haslinkedproject: hasLinkedProject
          
        };
      }
    },
    loadOrResetTaskModal() {
      this.$store.state.currentprofiletaskduedate = "";
      this.$store.state.profiletasktext = "";
      this.$store.state.currentprofiletaskassigneduser =
        this.$store.state.userData;
      setTimeout(() => {
        document.getElementById("profileTaskTextarea").focus();
      }, 500);
    },
    initializeEditTagsModalForm() {
      //reset input value
      this.$store.state.tagname = "";

      //input focus on modal show is handled from App.vue
    },
    initializeNewEmailModal() {
      //Clear previous email in modal, if any
      this.$store.state.currentEmailMessage = "";

      //Clear attachment if any
      this.$store.state.fileToSend = "";

      //prepare form
      this.$store.state.emailSubject = "";
      this.$store.state.emailBody =
        "<p></p><p></p>" + this.$store.state.userData.emailsignature;
    },
    addUser(userid) {
      addUserExternal(userid, this.$store.state.currentProjectDetails.id);
    },
    joinProject() {
      joinProjectExternal(
        this.$store.state.userData.userid,
        this.$store.state.currentProjectDetails.id
      );
    },
    leaveProject() {
      deleteUserFromProjectExternal(
        this.$store.state.userData.userid,
        this.$store.state.currentProjectDetails.id
      );
    },
    clearAll() {
      //clear all notifications, TODO
    },
  },

  computed: {
myActiveProjects() {
  const myProjects = this.$store.state.currentMyProjectList;

  const filteredResults = myProjects.filter((obj, index, self) => {
    // Filter elements based on the first occurrence of each unique id
    return index === self.findIndex((el) => el.id === obj.id && el.status === "Active");
  });

  const results = filteredResults.sort((a, b) => {
    if (a.startedat > b.startedat) {
      return -1;
    } else if (a.startedat < b.startedat) {
      return 1;
    } else {
      return 0;
    }
  });

  return results;
},

    teamActiveProjects() {
      const teamProjects = this.$store.state.currentTeamProjectList;

      const results = teamProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    computedProjectUsers() {
      if (
        this.$store.state.teammembers &&
        this.$store.state.currentProjectDetails
      ) {
        let projectUsers = this.$store.state.teammembers.filter(
          (member) =>
            member.subscriptiontype != "client" &&
            member.userid != this.$store.state.currentProjectDetails.owninguser
        );
        return projectUsers;
      } else {
        return [];
      }
    },
  },
};
</script>

<style></style>
