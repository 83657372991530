<template>
  <form action="" autocomplete="off">
    <!--begin::Input group (first name)-->
    <div class="mb-9">
      <!--begin::Label-->
      <label class="d-flex align-items-center form-label">
        <span class="required">First name</span>
        <i
          class="d-none fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="popover"
          data-bs-trigger="hover"
          data-bs-html="true"
          data-bs-content="<div class='p-4 rounded bg-light'> <div class='d-flex flex-stack text-muted mb-4'> <i class='fas fa-university fs-3 me-3'></i> <div class='fw-bold'>INCBANK **** 1245 STATEMENT</div> </div> <div class='d-flex flex-stack fw-semibold text-gray-600'> <div>Amount</div> <div>Transaction</div> </div> <div class='separator separator-dashed my-2'></div> <div class='d-flex flex-stack text-dark fw-bold mb-2'> <div>USD345.00</div> <div>KEENTHEMES*</div> </div> <div class='d-flex flex-stack text-muted mb-2'> <div>USD75.00</div> <div>Hosting fee</div> </div> <div class='d-flex flex-stack text-muted'> <div>USD3,950.00</div> <div>Payrol</div> </div> </div>"
          data-kt-initialized="1"
        ></i>
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <input
        name=""
        class="form-control form-control-lg form-control-solid"
        :class="{
          'border-danger': $store.state.signup_ProfileFirstNameFieldIssue,
        }"
        v-model="$store.state.signup_ProfileFirstName"
        placeholder=""
        @keydown="signup_ProfileFirstNameFieldIssueFalse()"
      />
      <!--end::Input-->
      <div
        v-if="$store.state.signup_ProfileFirstNameFieldIssue"
        class="fv-plugins-message-container invalid-feedback"
      >
        Please enter first name.
      </div>
      <!--begin::Hint-->
      <div class="d-none form-text">Will be used for billing</div>
      <!--end::Hint-->
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group (first name)-->

    <!--begin::Input group (last name)-->
    <div class="fv-row mb-9 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="d-flex align-items-center form-label">
        <span class="required">Last name</span>
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <input
        name=""
        class="form-control form-control-lg form-control-solid"
        :class="{
          'border-danger': $store.state.signup_ProfileLastNameFieldIssue,
        }"
        v-model="$store.state.signup_ProfileLastName"
        @keydown="signup_ProfileLastNameFieldIssueFalse()"
      />
      <!--end::Input-->
      <div
        v-if="$store.state.signup_ProfileLastNameFieldIssue"
        class="fv-plugins-message-container invalid-feedback"
      >
        Please enter last name.
      </div>
    </div>
    <!--end::Input group (last name)-->

    <!--begin::Input group (company logo URL)-->
    <div class="mb-9">
      <!--begin::Label-->
      <label class="d-flex flex-column align-items-start form-label">
        <span class=""
          >Profile picture <span class="text-muted">(optional)</span></span
        >
        <!--begin::Hint-->

        <div class="form-text">
          Copy/paste the URL of your profile picture from LinkedIn for example
        </div>
        <!--end::Hint-->
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <div class="d-flex">
        <input
          name=""
          class="form-control form-control-lg form-control-solid"
          style="height: 20px"
          v-model="$store.state.signup_ProfilePicURL"
        />
        <div>
          <img
            class="ms-20"
            width="80"
            :src="$store.state.signup_ProfilePicURL"
            alt=""
          />
        </div>
      </div>

      <!--end::Input-->

      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group (company logo URL)-->

    <!--begin::Input group (email)-->
    <div class="fv-row mb-9 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="d-flex align-items-center form-label">
        <span class="required">Email</span>
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <input
        name=""
        class="form-control form-control-lg form-control-solid"
        :class="{
          'border-danger':
            $store.state.signup_ProfileEmailFieldIssue ||
            $store.state.signup_ProfileEmailsDontMatch,
        }"
        v-model="$store.state.signup_ProfileEmail"
        @keydown="signup_ProfileEmailFieldIssueFalse()"
        autocomplete="off"
      />
      <!--end::Input-->

      <div
        v-if="$store.state.signup_ProfileEmailFieldIssue"
        class="fv-plugins-message-container invalid-feedback"
      >
        Please enter email.
      </div>
    </div>
    <!--end::Input group (email)-->

    <!--begin::Input group (Confirm Email)-->
    <form action="" autocomplete="off">
      <div class="fv-row mb-9 fv-plugins-icon-container">
        <!--begin::Label-->
        <label class="d-flex align-items-center form-label">
          <span class="required">Confirm Email</span>
        </label>
        <!--end::Label-->
        <!--begin::Input-->
        <input
          name="qdsfqoqsdifjsdf"
          id="qsdfqsdfqsdfqsfrg"
          class="form-control form-control-lg form-control-solid"
          :class="{
            'border-danger':
              $store.state.signup_ProfileConfirmEmailFieldIssue ||
              $store.state.signup_ProfileEmailsDontMatch,
          }"
          v-model="$store.state.signup_ProfileConfirmEmail"
          @keydown="signup_ProfileConfirmEmailFieldIssueFalse()"
          autocomplete="nope"
        />
        <!--end::Input-->
        <div
          v-if="$store.state.signup_ProfileConfirmEmailFieldIssue"
          class="fv-plugins-message-container invalid-feedback"
        >
          Please confirm email.
        </div>
        <div
          v-if="$store.state.signup_ProfileEmailsDontMatch"
          class="fv-plugins-message-container invalid-feedback"
        >
          Emails do not match
        </div>
      </div>
    </form>
    <!--end::Input group (Confirm Email)-->

    <!--begin::Input group (Password)-->
    <div class="fv-row mb-9 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="d-flex align-items-center form-label">
        <span class="required">Password</span>
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <div class="d-flex">
        <input
          name=""
          :type="pwInputType"
          class="form-control form-control-lg form-control-solid"
          :class="{
            'border-danger':
              ($store.state.signup_ProfilePasswordFieldIssue ||
              $store.state.signup_ProfilePwDontMatch ||
              $store.state.signup_ProfilePwTooShort) && $store.state.signup_ProfilePassword,
          }"
          v-model="$store.state.signup_ProfilePassword"
          autocomplete="off"
          @keydown="signup_ProfilePasswordFieldIssueFalse()"
        />
        <span class="ms-4 btn btn-icon btn-light" @click="togglePwVisibility()">
          <i v-if="pwInputType == 'password'" class="bi bi-eye fs-2"></i>
          <i v-if="pwInputType == 'text'" class="bi bi-eye-slash fs-2"></i>
        </span>
      </div>
      <!--end::Input-->
      <div
        v-if="$store.state.signup_ProfilePasswordFieldIssue"
        class="fv-plugins-message-container invalid-feedback"
      >
        Please choose password.
      </div>
      <div
        v-if="
          $store.state.signup_ProfilePassword && (passwordStrength < 3)
        "
        class="fv-plugins-message-container invalid-feedback"
      >
        Password too weak
      </div>
      <div
        class="mt-5 mb-2 ms-1"
        v-if="passwordStrength !== null && $store.state.signup_ProfilePassword"
      >
        {{
          ["Very Weak", "Very Weak", "Weak", "Strong", "Very Strong"][
            passwordStrength
          ]
        }}
      </div>
      <div
        class="ms-1 h-8px w-300px bg-light rounded"
        v-if="$store.state.signup_ProfilePassword"
      >
        <div
          class="rounded h-8px"
          :class="{
            'bg-danger': passwordStrength == 0 || passwordStrength == 1,
            'bg-warning': passwordStrength == 2,
            'bg-success': passwordStrength == 3 || passwordStrength == 4,
          }"
          role="progressbar"
          :style="{ width: getWidthBasedOnStrength() }"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
    <!--end::Input group (Password)-->

    <!--begin::Input group (Confirm Password)-->
    <div class="fv-row mb-9 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="d-flex align-items-center form-label">
        <span class="required">Confirm Password</span>
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <div class="d-flex">
        <input
          name=""
          :type="pwInputType"
          class="form-control form-control-lg form-control-solid"
          :class="{
            'border-danger':
              $store.state.signup_ProfileConfirmPasswordFieldIssue ||
              $store.state.signup_ProfilePwDontMatch,
          }"
          v-model="$store.state.signup_ProfileConfirmPassword"
          @keydown="signup_ProfileConfirmPasswordFieldIssueFalse()"
          autocomplete="off"
        />
        <span class="ms-4 btn btn-icon btn-light" @click="togglePwVisibility()">
          <i v-if="pwInputType == 'password'" class="bi bi-eye fs-2"></i>
          <i v-if="pwInputType == 'text'" class="bi bi-eye-slash fs-2"></i>
        </span>
      </div>
      <!--end::Input-->

      <div
        v-if="$store.state.signup_ProfileConfirmPasswordFieldIssue"
        class="fv-plugins-message-container invalid-feedback"
      >
        Please confirm password.
      </div>
      <div
        v-if="$store.state.signup_ProfilePwDontMatch"
        class="fv-plugins-message-container invalid-feedback"
      >
        Passwords do not match
      </div>
    </div>
    <!--end::Input group (Confirm Password)-->

    <!--begin::Input group (mobile number)-->
    <div class="fv-row mb-9 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="d-flex align-items-center form-label">
        <span class="required">Mobile number</span>
      </label>
      <!--end::Label-->
      <!--begin::Input-->
      <input
        name=""
        class="form-control form-control-lg form-control-solid"
        :class="{
          'border-danger': $store.state.signup_ProfileMobileNumberFieldIssue,
        }"
        v-model="$store.state.signup_ProfileMobileNumber"
        @keydown="signup_ProfileMobileNumberFieldIssueFalse()"
      />
      <!--end::Input-->
      <!--begin::Hint-->
      <div class="form-text">Please include country code</div>
      <!--end::Hint-->
      <div
        v-if="$store.state.signup_ProfileMobileNumberFieldIssue"
        class="fv-plugins-message-container invalid-feedback"
      >
        Please confirm mobile number.
      </div>
      <div class="fv-plugins-message-container invalid-feedback"></div>
    </div>
    <!--end::Input group (mobile number)-->
  </form>
</template>

<script>
import zxcvbn from "zxcvbn";

export default {
  data() {
    return {
      //toggles the input type of password & confirm password fields for visibility
      pwInputType: "password",
      passwordStrength: null,
    };
  },

watch: {
  "$store.state.signup_ProfilePassword": {
    immediate: true,
    handler(password) {
      this.passwordStrength = zxcvbn(password).score;
      console.log(this.passwordStrength); // first statement
      this.updateStrength();
    },
  },
},

  methods: {
    updateStrength() {
    this.$store.state.passwordStrength = this.passwordStrength;
    if (this.passwordStrength > 3 || this.passwordStrength == null) {
      this.$store.state.signup_ProfilePwTooShort = false;
    } else if (this.passwordStrength < 3 && this.passwordStrength != null) {
      this.$store.state.signup_ProfilePwTooShort = true;
    }
    console.log(this.passwordStrength); // second statement
  },
    getWidthBasedOnStrength() {
      switch (this.passwordStrength) {
        case 0:
          return "20%";
        case 1:
          return "40%";
        case 2:
          return "60%";
        case 3:
          return "80%";
        case 4:
          return "100%";
        default:
          return "20%";
      }
    },
    togglePwVisibility() {
      if (this.pwInputType == "password") {
        this.pwInputType = "text";
      } else if (this.pwInputType == "text") {
        this.pwInputType = "password";
      }
    },

    //methods to reset the fied issue to false when an empty field is filled out after alert
    signup_ProfileFirstNameFieldIssueFalse() {
      this.$store.state.signup_ProfileFirstNameFieldIssue = false;
    },
    signup_ProfileLastNameFieldIssueFalse() {
      this.$store.state.signup_ProfileLastNameFieldIssue = false;
    },
    signup_ProfileEmailFieldIssueFalse() {
      this.$store.state.signup_ProfileEmailFieldIssue = false;
    },
    signup_ProfileConfirmEmailFieldIssueFalse() {
      this.$store.state.signup_ProfileConfirmEmailFieldIssue = false;
    },
    signup_ProfilePasswordFieldIssueFalse() {
      this.$store.state.signup_ProfilePasswordFieldIssue = false;
    },
    signup_ProfileConfirmPasswordFieldIssueFalse() {
      this.$store.state.signup_ProfileConfirmPasswordFieldIssue = false;
    },
    signup_ProfileMobileNumberFieldIssueFalse() {
      this.$store.state.signup_ProfileMobileNumberFieldIssue = false;
    },
  },
};
</script>

<style></style>
