<template>
<Transition name="fade" mode="out-in">
  <div
    class="border border-primary rounded p-5"
    v-if="$store.state.generateProgressState == 'done'"
  >
    <div class="scroll h-375px px-5">
      <div v-html="computedGeneratedMessage"></div>
    </div>
  </div>

  <div v-else>
    <div class="d-flex mt-12">

    </div>

    <div class="d-flex">
      <div class="mt-10">
        <h3 class="mb-5">Context</h3>
        <div v-if="$store.state.currentProfileProjectBindings.length > 0">
          <div class="d-flex align-items-center mt-3">
            <div class="d-inline-flex border border-3 rounded p-2">
              <div class="symbol symbol-40px">
                <img
                  :src="
                    $store.state.currentProfileProjectBindings[0].clientlogo
                  "
                  class="h-40 align-self-center border border-secondary"
                  alt=""
                />
              </div>
              <div class="d-flex flex-column ms-3">
                <a class="text-muted fw-bolder fs-7">{{
                  $store.state.currentProfileProjectBindings[0].clientname
                }}</a
                ><span class="fw-semibold d-block fs-7 text-muted">{{
                  $store.state.currentProfileProjectBindings[0].positiontitle
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 ms-8">
        <h3 class="mb-5">Choose language</h3>

        <div class="d-flex flex-wrap w-300px mt-3">
          <!--begin: English-->
          <div
            class="my-1 me-3 form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="radio"
              id="radioEnglish"
              value="English"
              v-model="$store.state.currentProjectDetails.chatgptlanguage"
              @click="setLanguage('English')"
            /><label class="form-check-label" for="radioEnglish">
              <img
                width="30"
                src="https://img.icons8.com/fluency/48/null/great-britain-circular.png"
              />
            </label>
          </div>
          <!--end: English-->
          <!--begin: German-->
          <div
            class="my-1 me-3 form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="radio"
              id="radioGerman"
              value="German"
              v-model="$store.state.currentProjectDetails.chatgptlanguage"
              @click="setLanguage('German')"
            /><label class="form-check-label" for="radioGerman">
              <img
                width="30"
                src="https://img.icons8.com/fluency/48/null/germany-circular.png"
              />
            </label>
          </div>
          <!--end: German-->
          <!--begin: French-->
          <div
            class="my-1 me-3 form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="radio"
              id="radioFrench"
              value="French"
              v-model="$store.state.currentProjectDetails.chatgptlanguage"
              @click="setLanguage('French')"
            /><label class="form-check-label" for="radioFrench">
              <img
                width="30"
                src="https://img.icons8.com/fluency/48/null/france-circular.png"
              />
            </label>
          </div>
          <!--end: French-->
          <!--begin: Dutch-->
          <div
            class="my-1 me-3 form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="radio"
              id="radioDutch"
              value="Dutch"
              v-model="$store.state.currentProjectDetails.chatgptlanguage"
              @click="setLanguage('Dutch')"
            /><label class="form-check-label" for="radioDutch">
              <img
                width="30"
                src="https://img.icons8.com/fluency/48/null/netherlands-circular.png"
              />
            </label>
          </div>
          <!--end: Dutch-->
          <!--begin: Italian-->
          <div
            class="my-1 me-3 form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="radio"
              id="radioItalian"
              value="Italian"
              v-model="$store.state.currentProjectDetails.chatgptlanguage"
              @click="setLanguage('Italian')"
            /><label class="form-check-label" for="radioItalian">
              <img
                width="30"
                src="https://img.icons8.com/fluency/48/null/italy-circular.png"
              />
            </label>
          </div>
          <!--end: Italian-->
          <!--begin: Spanish-->
          <div
            class="my-1 me-3 form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="radio"
              id="radioSpanish"
              value="Spanish"
              v-model="$store.state.currentProjectDetails.chatgptlanguage"
              @click="setLanguage('Spanish')"
            /><label class="form-check-label" for="radioSpanish">
              <img
                width="30"
                src="https://img.icons8.com/fluency/48/null/spain-circular.png"
              />
            </label>
          </div>
          <!--end: Spanish-->
          <!--begin: Swedish-->
          <div
            class="my-1 me-3 form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="radio"
              id="radioSwedish"
              value="Swedish"
              v-model="$store.state.currentProjectDetails.chatgptlanguage"
              @click="setLanguage('Swedish')"
            /><label class="form-check-label" for="radioSwedish">
              <img
                width="30"
                src="https://img.icons8.com/fluency/48/null/sweden-circular.png"
              />
            </label>
          </div>
          <!--end: Swedish-->
        </div>
      </div>
    </div>

    <div class="row mt-7">
      <div class="col-10">
        <h3 class="">Options</h3>
        <div class="d-flex flex-wrap mt-5">
          <div class="d-flex align-items-center">
     
            <div class="my-2 form-check form-switch">
              <label class="form-check-label" for="mentionCompanySwitch">
                Mention client company name </label
              ><input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="mentionCompanySwitch"
                v-model="
                  $store.state.currentProjectDetails.chatgptmentionsclientname
                "
                @click="toggleMentionClient()"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">

            <div class="d-none my-2 form-check form-switch">
              <label class="form-check-label" for="followUpMessageSwitch">
                I have tried to reach out before, this is a new attempt </label
              ><input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="followUpMessageSwitch"
                v-model="
                  $store.state.currentProjectDetails.chatgptisfollowupmsg
                "
                @click="toggleIsFollowUpMsg()"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">

            <div class="d-flex my-2">
              <input
                type="text"
                class="form-control form-control-sm border border-primary"
                placeholder="Add attractive key-words for this job"
                style="width: 330px"
                v-model="$store.state.currentProjectDetails.chatgptkeywords"
              /><button
                type="button"
                class="ms-3 btn btn-sm btn-light-primary"
                @click="addChatGptKeyWords()"
              >
                Add
              </button>
            </div>
          </div>
          <div class="ms-1 mt-1">
            <span class="text-muted fs-7"
              >Ex.: "full P&L responsibility, fast-growing environment" or
              "global leader in home appliances, key position, growth"...</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</Transition>
  <!--begin::"generate" button-->
  <div class="d-flex mt-10">
    <div class="mx-auto" v-if="$store.state.generateProgressState == 'ready'">

      <button class="btn btn-xl btn-primary mx-auto" @click="callOpenAi()">
        Generate !
      </button>
    
    </div>
    <div
      class="mx-auto"
      v-if="$store.state.generateProgressState == 'generating'"
    >
      <button
        type="button"
        v-if="$store.state.generateProgressState == 'generating'"
        class="btn btn-ls btn-primary"
      >
        <span>
          Generating...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <div class="mx-auto" v-if="$store.state.generateProgressState == 'done'">
              <button class="btn btn-sm btn-light-primary animate__animated animate__fadeIn"
              @click="$store.state.generateProgressState = 'ready'"
              >
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr002.svg-->
<span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z" fill="currentColor"/>
<path opacity="0.3" d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
        Back
      </button>
      <button class="ms-3 btn btn-sm btn-light-primary animate__animated animate__fadeIn"
      @click="copyMessage()"
      >
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/general/gen028.svg-->
<span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="currentColor"/>
    <rect x="3" y="6" width="14" height="16" rx="3" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
        Copy
      </button>
            <button class="ms-3 btn btn-sm btn-light-primary animate__animated animate__fadeIn"
            @click="newEmailWithChatGPTMessage()"
            >
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/communication/com002.svg-->
<span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z" fill="currentColor"/>
<path d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
        New email
      </button>
    </div>
  </div>
  <!--end::"generate" button-->
  <div class="d-flex" v-if="$store.state.generateProgressState != 'done'">
        <div class="d-flex flex-column ms-auto" style="margin-bottom: -30px; margin-right: -25px;">
        <div>
          <span class="fs-8 me-1 text-muted"> Powered by </span>
        </div>
        <div style="margin-top: -2px;">
          <img
            style="width: 80px; opacity: 0.3;"
            src="/assets/media/logos/openai.jpg"
            alt=""
          />
        </div>
      </div>
  </div>

</template>

<script>
import { toggleMentionClientExternal } from "../../../js/projectdetails";
import { toggleIsFollowUpMsgExternal } from "../../../js/projectdetails";
import { addChatGptKeyWordsExternal } from "../../../js/projectdetails";
import { setLanguageExternal } from "../../../js/projectdetails";
import { newEmailWithChatGPTMessageExternal } from "../../../js/profiledetails";

export default {
  methods: {
    callOpenAi() {
      //make sure a language is selected
      if (!this.$store.state.currentProjectDetails.chatgptlanguage) {
        alert("Please select a language first.");
        return;
      }

      this.$store.state.generateProgressState = "generating";

      const instructions = this.aiPromptBuild;

      const callLinkedInMessageIdeas = async () => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ content: instructions }),
        };

        try {
          const response = await fetch(
            "https://europe-west1-kiwano-3f6de.cloudfunctions.net/askOpenAIGPT",
            requestOptions
          );
          const data = await response.json();
          console.log(data);
          this.$store.state.generateProgressState = "done";
          this.$store.state.generatedOutreachMessage = data.data.message;
        } catch (error) {
          console.error(`Error: ${error.message}`);
        }
      };

      callLinkedInMessageIdeas();
    },

    toggleMentionClient() {
      if (this.$store.state.currentProjectDetails.chatgptmentionsclientname) {
        toggleMentionClientExternal(
          this.$store.state.currentProjectDetails.id,
          false
        );
      } else {
        toggleMentionClientExternal(
          this.$store.state.currentProjectDetails.id,
          true
        );
      }
    },
    toggleIsFollowUpMsg() {
      if (this.$store.state.currentProjectDetails.chatgptisfollowupmsg) {
        toggleIsFollowUpMsgExternal(
          this.$store.state.currentProjectDetails.id,
          false
        );
      } else {
        toggleIsFollowUpMsgExternal(
          this.$store.state.currentProjectDetails.id,
          true
        );
      }
    },
    addChatGptKeyWords() {
      addChatGptKeyWordsExternal(
        this.$store.state.currentProjectDetails.id,
        this.$store.state.currentProjectDetails.chatgptkeywords
      );
    },
    setLanguage(language) {
      setLanguageExternal(this.$store.state.currentProjectDetails.id, language);
    },
    copyMessage() {
        navigator.clipboard.writeText(this.$store.state.generatedOutreachMessage)
    },
    newEmailWithChatGPTMessage() {
        newEmailWithChatGPTMessageExternal()
    }
  },
  computed: {
    myActiveProjects() {
      const myProjects = this.$store.state.currentMyProjectList;

      const results = myProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    teamActiveProjects() {
      const teamProjects = this.$store.state.currentTeamProjectList;

      const results = teamProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    computedExperienceAsString() {
      if (!this.$store.state.currentProfileDetails.experiencelist) {
        return "nothing";
      } else {
        const formattedString =
          this.$store.state.currentProfileDetails.experiencelist
            .map(
              (item) =>
                `Company: ${item.company}\nTitle: ${item.title}\nStart Year: ${item.startyear}\nEnd Year: ${item.endyear}\nID: ${item.id}`
            )
            .join("\n\n");
        return formattedString;
      }
    },
    aiPromptBuild() {
      //convert options into prompt elements

      let mentionClientorNot = " for one of my clients. ";
      if (this.$store.state.currentProjectDetails.chatgptmentionsclientname) {
        mentionClientorNot =
          "for " +
          this.$store.state.currentProjectDetails.clientobject.clientname +
          ".";
      }

      let keyWords = " ";
      if (this.$store.state.currentProjectDetails.chatgptkeywords) {
        keyWords =
          "4. Then briefly describe the position using the following keywords, and translate in the appropriate language: " +
          this.$store.state.currentProjectDetails.chatgptkeywords +
          ". ";
      }

      let ifFollowUpMessageTrue = " ";
      if (this.$store.state.currentProjectDetails.chatgptisfollowupmsg) {
        ifFollowUpMessageTrue =
          "Also mention that I have tried to reach out before and that this a new attempt. ";
      }

      const currentYear = new Date().getFullYear();
      let currentExpStartYear =
        this.$store.state.currentProfileDetails.experiencelist[0].startyear;
      if (currentExpStartYear == currentYear) {
        currentExpStartYear = " since this year";
      } else if (currentExpStartYear == currentYear - 1) {
        currentExpStartYear = " since last year";
      } else if (currentExpStartYear == currentYear - 2) {
        currentExpStartYear = " since 2 years ago";
      } else if (currentExpStartYear < currentYear - 2) {
        currentExpStartYear = " since " + currentExpStartYear;
      }

      //build the prompt

      let prompt =
        "I am working for an executive search firm and as a headhunter I would like to reach out to a candidate for a career opportunity as " +
        this.$store.state.currentProfileProjectBindings[0].positiontitle +
        ", based in " +
        this.$store.state.currentProjectDetails.location +
        mentionClientorNot +
        "Can you write a customized reach-out messages in " +
        this.$store.state.currentProjectDetails.chatgptlanguage +
        " ? " +
        "The message should first make a reference to the main industry of the profile and their main skill. Then make a reference to the fact they they are serving as " +
        this.$store.state.currentProfileDetails.experiencelist[0]
          .currentjobtitle +
        " at " +
        this.$store.state.currentProfileDetails.experiencelist[0]
          .currentcompany +
        currentExpStartYear +
        ", like this: 'I noticed you are serving as [title] at [company] [" +
        currentExpStartYear +
        "]'. Adding since [" +
        currentExpStartYear +
        "] is very important.  Here is the candidate's experience: " +
        this.computedExperienceAsString +
        " Based on the experience above, you should refence one of the previous companies they worked for. " +
        ifFollowUpMessageTrue +
        keyWords +
        "IMPORTANT: do absolutely NOT be too enthousiastic. ABSOLUTELY AVOID words like 'extremely', 'exceptional', 'i was struck by', 'ideal candidate' and so on." +
        "IMPORTANT: address the candidate by their name." +
        " Finally, address the candidate by appropriate gender title, either Mr or Mrs depending on the firstname, and add the last name which is: " +
        this.$store.state.currentProfileDetails.lastname +
        "Sign the message with my first and last name, which are: " +
        this.$store.state.userData.firstname +
        " " +
        this.$store.state.userData.lastname;

        let prompt2 =
        "I am working for an executive recruitment firm and I would like to reach out to "+ this.$store.state.currentProfileDetails.firstname + " " + this.$store.state.currentProfileDetails.lastname + "  a potential candidate for a client’s position." +
        "Write a reach out message in "+ this.$store.state.currentProjectDetails.chatgptlanguage +" built according to the following instructions : "+

        "1.  Present myself. Use either  'a consultant'. (IMPORTANT: alternate between these 2 options) at  "+ this.$store.state.teamData.teamname +", but WITHOUT MENTIONING MY NAME" +
        "2.  Then briefly outline the subject: discussing a new career opportunity. This is the opportunity: "+ this.$store.state.currentProjectDetails.positiontitle + mentionClientorNot +
        "3.  Then mention the profile's current experience. VERY IMPORTANT: mention: "+currentExpStartYear+" when mentioning the current experience.  " +
        keyWords +
	    "5. VERY IMPORTANT: Then, IDENTIFY THE MAIN INDUSTRY OR SKILL in the profile's experience and say that this would fit well with this opportunity. For example: 'your sales management skills' OR 'your extensive knowledge of the automotive industry in Europe' " +
	    "6.  Then finish by saying something like this or a variation : « Would you have few moments to discuss this opportunity in the coming days ? » " +
	    "7.  Sign with Best regards, " + this.$store.state.userData.firstname + " " + this.$store.state.userData.lastname + 
      "8. To reference the profile's experience, skills and industry, you should use their experience as a base. Here is the experience: " +
        this.computedExperienceAsString +
      "9. You should address the candidate with 'Dear Mr' or 'Dear Mrs' depending on the gender, which you can infer from the firstname."
      "10. In order to help you, here are 2 examples of message that you should use as inspiration: " +
      "Message A:  Dear Mr XXX, I represent a recruitment firm and I am reaching out to you to discuss a career opportunity (Managing Director Western Europe) for one of our clients. Our client is an important group in XXX. I noticed that you have been serving as XXX at XXX since 2020. I beleive your experience would be a strong asset for our client, which offers the opportunity to manage the sales subsidiaries and drive the organisational change at European level. I can imagine you already have a busy schedule for the coming days. What about a 15 min call for an initial contact ? Please let me know what works best for you this week. Best regards,"+
      "Message B: Dear Mr XXXI am working for an executive recruitment firm in Paris, and I would like to get in touch regarding an international position for one of our clients. I have noted that you are serving as XXX at XXX since 2 years ago. Our client is a key player in the XXX industry and I believe your background in XXX would be a strong asset for this role.Would you have a moment to discuss the project in the coming days?" +
"Best regards,"


      return prompt2;
    },
    computedGeneratedMessage() {
      let message = this.$store.state.generatedOutreachMessage.replace(
        /\n/g,
        "<br>"
      );
      return message;
    },
  },
};
</script>

<style>
</style>