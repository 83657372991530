<template>
  <!--begin: Edit project info Form fields-->
  <div v-if="$store.state.currentRoute == '/projects'">
    <div
      v-if="
        $store.state.currentRoute == '/projects' &&
        $store.state.currentProjectDetails
      "
      class="d-flex mb-9"
    >
      <div class="symbol symbol-40px">
        <img
          v-if="$store.state.currentProjectDetails.clientobject.clientlogourl"
          :src="$store.state.currentProjectDetails.clientobject.clientlogourl"
          class="h-40 align-self-center"
          alt=""
        />
        <img
          v-else
          src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
          class="h-40 align-self-center"
          alt=""
        />
      </div>
      <span class="ms-4 text-muted fw-semibold d-block mt-3">{{
        $store.state.currentProjectDetails.clientobject.clientname
      }}</span>
    </div>

    <div class="mb-7">
      <label for="exampleFormControlInput1" class="required form-label"
        >Job title</label
      >
      <input
        v-model="$store.state.currentProjectDetails.positiontitle"
        type="text"
        class="form-control form-control-solid"
        placeholder="Example input"
      />
    </div>
    <div class="mb-7">
      <label for="exampleFormControlInput1" class="form-label">Location</label>
      <input
        v-model="$store.state.currentProjectDetails.location"
        type="text"
        class="form-control form-control-solid"
        placeholder="Example input"
      />
    </div>
  </div>
  <!--end: Edit project info Form fields-->

  <!--begin: Edit client info Form fields-->
  <div v-if="$store.state.currentRoute == '/clients'">
    <div
      v-if="
        $store.state.currentRoute == '/clients' &&
        $store.state.currentClientDetails
      "
      class="d-flex mb-9"
    >
      <div class="symbol symbol-40px">
        <img
          v-if="$store.state.currentClientDetails.clientlogourl"
          :src="$store.state.currentClientDetails.clientlogourl"
          class="h-40 align-self-center"
          alt=""
        />
        <img
          v-else
          src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
          class="h-40 align-self-center"
          alt=""
        />
      </div>
      <span class="ms-4 text-muted fw-semibold d-block mt-3">{{
        $store.state.currentClientDetails.clientname
      }}</span>
    </div>

    <div class="mb-7">
      <label for="exampleFormControlInput1" class="required form-label"
        >Client name</label
      >
      <input
        v-model="$store.state.currentClientDetails.clientname"
        type="text"
        class="form-control form-control-solid"
        placeholder="Example input"
      />
    </div>
    <div class="mb-7">
      <label for="exampleFormControlInput1" class="form-label"
        >Client logo URL</label
      >
      <input
        v-model="$store.state.currentClientDetails.clientlogourl"
        type="text"
        class="form-control form-control-solid"
        placeholder="Example input"
      />
    </div>
    <div class="mb-7">
      <label for="exampleFormControlInput1" class="form-label">Location</label>
      <input
        v-model="$store.state.currentClientDetails.location"
        type="text"
        class="form-control form-control-solid"
        placeholder="Example input"
      />
    </div>
  </div>
  <!--end: Edit client info Form fields-->

  <hr style="color: grey" />

  <div class="d-flex mt-10">
    <div class="ms-auto">
      <button class="btn btn-small btn-light" @click="closeModal()">
        Cancel
      </button>
      <button
        v-if="$store.state.currentRoute == '/projects'"
        class="ms-3 btn btn-small btn-primary"
        @click="saveProjectInfo()"
      >
        Save
      </button>
      <button
        v-if="$store.state.currentRoute == '/clients'"
        class="ms-3 btn btn-small btn-primary"
        @click="saveClientInfo()"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { saveProjectInfoExternal } from "../../../js/projectdetails";
import { saveClientInfoExternal } from "../../../js/clientdetails";

export default {
  methods: {
    closeModal() {
      $("#editprojectandclientinfo").modal("toggle");
    },
    saveProjectInfo() {
      saveProjectInfoExternal();
    },
    saveClientInfo() {
      saveClientInfoExternal();
    },
  },
};
</script>

<style></style>
