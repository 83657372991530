<template>

  <div
    class="text-end"
    v-if="routepath != '/mobile'"
    style="margin-bottom: -17px; margin-right: -5px;"
  >
    <!--begin:detach button-->
    <a
      class="ms-4 btn btn-sm btn-icon btn-light-danger"
      @click.stop="detachFromThisProject($store.state.currentProfileProjectBinding)"
    ><i class="bi bi-trash fs-4"></i></a>
    <!--end:detach button-->
  </div>
  <div class="row border rounded border-dashed border-gray-400 me-3 mb-7" v-if="routepath != '/mobile'">

    <div class="d-flex flex-column mb-5 mt-8 ms-6 col-5" >
      <div class="d-flex flex-row">
        <div class="symbol symbol-40px me-3">
          <img
            :src="$store.state.currentProfileDetails.profilepic"
            class="align-self-center"
            alt=""
          />
        </div>
        <div>
          <a
            href="#"
            class="text-dark fw-bold text-hover-primary fs-6"
          >{{ $store.state.currentProfileDetails.firstname }} {{ $store.state.currentProfileDetails.lastname }}</a>
          <div class="">
            <span class="text-muted fw-semibold d-block">{{ $store.state.currentProfileDetails.currentjobtitle }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-1 mt-10 ps-3 h1 me-3">
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-18-143101/core/html/src/media/icons/duotune/arrows/arr001.svg-->
      <span class="svg-icon svg-icon-muted svg-icon-2x"><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
            fill="currentColor"
          />
          <path
            opacity="0.3"
            d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>

    <div class="d-flex flex-column mb-5 mt-8 col-5">
      <div class="d-flex flex-row">
        <div class="symbol symbol-40px me-3 companylogo">
          <img
            :src="$store.state.currentProfileProjectBinding.clientlogo"
            class="align-self-center"
            alt=""
          />
        </div>
        <div>
          <a
            href="#"
            class="text-dark fw-bold text-hover-primary fs-6"
          >{{ $store.state.currentProfileProjectBinding.clientname }}</a>
          <div class="">
            <span class="text-muted fw-semibold d-block">{{ $store.state.currentProfileProjectBinding.positiontitle }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row">

    <div class="d-flex flex-column mb-8 mt-5 col-10 pe-4">
      <label class="fw-semibold mb-2" :class="{'fs-6': routepath != '/mobile', 'fs-2': routepath == '/mobile'}">Status</label>
      <div class="d-inline-flex">
        <!--begin::StatusDropdown-->
        <StatusDropdown
          v-if="$store.state.currentProfileProjectBinding.profilestatusid"
          origin="profileDetailsModal"
          :elementObject="$store.state.currentProfileDetails"
          :currentProfileProjectBindingId="$store.state.currentProfileProjectBinding.bindingid"
          :currentProfileStatusId="$store.state.currentProfileProjectBinding.profilestatusid"
          :currentProjectId="$store.state.currentProfileProjectBinding.projectid"
        ></StatusDropdown>
        <!--end::StatusDropdown-->
      </div>
    </div>
             <!--begin:Go to LinkedIn profile Btn-->
          <a
            v-if="$store.state.currentProfileDetails.linkedinurl"
            @click="goToLinkedinProfile()"
            class="col-1 btn btn-icon btn-light-primary btn-sm ms-12 mt-5"
            ><i class="fab fa-linkedin-in" aria-hidden="true"></i
          ></a>
          <!--end:Go to LinkedIn profile Btn-->

    <div class="d-none d-flex flex-column mb-8 mt-5 col-8 pe-7">

      <div class="d-flex">
        <label class="fs-6 fw-semibold mb-2">Match %</label>
        <div
          class="ms-auto"
          v-if="$store.state.currentProfileProjectBinding.match > -1"
        >

          <span>{{ $store.state.currentProfileProjectBinding.match }}%</span>
          <span
            class="ms-3 badge badge-light custombadgehover custompointer"
            v-if="$store.state.currentProfileProjectBinding.match != -1"
            @click="resetMatch($store.state.currentProfileProjectBinding.bindingid)"
          >Reset</span>
        </div>

      </div>
      <input
        v-model="$store.state.currentProfileProjectBinding.match"
        v-if="$store.state.currentProfileProjectBinding.match"
        type="range"
        class="slider mt-3"
        @change="updateMatch()"
      />
    </div>

  </div>

  <div class="d-flex flex-column mb-8 mt-5">
    <label class="fw-semibold mb-2" :class="{'fs-6': routepath != '/mobile', 'fs-2': routepath == '/mobile'}">Notes</label>
    <textarea
      v-model="$store.state.currentProfileProjectBinding.notes"
      class="form-control form-control-solid"
      :class="{'fs-6': routepath != '/mobile', 'fs-2': routepath == '/mobile'}"
      rows="8"
      name="target_details"
      placeholder="Type somes notes here"
      @change="saveNotes()"
    ></textarea>
    <button class="ms-auto mt-5 btn btn-sm btn-primary" data-bs-dismiss="modal">Save</button>
  </div>
  



</template>

<script>
import { updateMatchExternal } from "../../../js/profiledetails";
import { saveNotesExternal } from "../../../js/profiledetails";
import { resetMatchExternal } from "../../../js/profiledetails";
import { detachFromThisProjectExternal } from "../../../js/profiledetails";
import { addProfileCommentExternal } from "../../../js/profiledetails";
import StatusDropdown from "../../Dropdowns/StatusDropdown.vue";

export default {
  components: {
    StatusDropdown,
  },
  methods: {
        goToLinkedinProfile() {
      window.open(
        this.$store.state.currentProfileDetails.linkedinurl,
        "_blank"
      );
    },
    updateMatch() {
      updateMatchExternal();
    },
    resetMatch() {
      resetMatchExternal();
    },
    saveNotes() {
      saveNotesExternal();
    },
    detachFromThisProject(bindingobject) {
      detachFromThisProjectExternal(bindingobject);
      $('#editProfileProjectBinding').modal('toggle')
    },
    showCommentForm() {
      this.$store.state.addCommentActivated = true

      this.$store.state.profilecommenttext = ""

    },
    addProfileComment(profileid, commenttext) {


      //before saving the comment, this stores the project details of the currentLatestProfileBinding, displayd in the modal as the default project to attach
      //If the user selects another project to attach, then these values change.
      this.$store.state.selectedProjectForCommentAttachment = {
        clientname: this.$store.state.currentProfileProjectBinding.clientname,
        clientlogo: this.$store.state.currentProfileProjectBinding.clientlogo,
        positiontitle: this.$store.state.currentProfileProjectBinding.positiontitle,
        projectid: this.$store.state.currentProfileProjectBinding.projectid,
      }

      //this makes sure a project attachement should be displayed in ProfileComments.vue
      this.$store.state.attachprofilecommenttoproject = true


      //after 1 second, this resets to false to make sure that the checkbox is clicked programatically when the "Add comment" is opened next time,
      //so that the checkbox is checked on modal open
      //Not ideal, but I can't find a better way to do this right now.
      setTimeout(() => {
        this.$store.state.attachprofilecommenttoproject = false
      }, 1000);

      //this hides the comment form and brings back the comments
      this.$store.state.addCommentActivated = false;

      addProfileCommentExternal(profileid, commenttext)
    }
  },
  computed: {
    routepath() {
      return this.$route.path;
    },
  }
};
</script>

<style>
</style>