<template>
      <div
        v-if="
          (($store.state.currentRoute === '/profiles' || $store.state.currentRoute === '/') &&
          ( $store.state.showingProfilesAs == 'projectProfiles' ||
            $store.state.showingProfilesAs == 'searchResults')) ||
            this.$route.path === '/portal'
        "
        class="dropdown me-2"
      >
        <button
          class="btn btn-sm btn-flex"
          :class="{
            'btn-light': this.$route.path != '/portal',
            'btn-outline': this.$route.path == '/portal',
            'btn-outline-dashed': this.$route.path == '/portal',
            'btn-outline-light': this.$route.path == '/portal',
          }"
          type="button"
          id="sortProfilesButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/arrows/arr070.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8"
                y="9"
                width="3"
                height="10"
                rx="1.5"
                fill="currentColor"
              />
              <rect
                opacity="0.5"
                x="13"
                y="5"
                width="3"
                height="14"
                rx="1.5"
                fill="currentColor"
              />
              <rect
                x="18"
                y="11"
                width="3"
                height="8"
                rx="1.5"
                fill="currentColor"
              />
              <rect
                x="3"
                y="13"
                width="3"
                height="6"
                rx="1.5"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          Sort
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
          <span class="ms-2 svg-icon svg-icon-4 m-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                fill="black"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
                    <ul
          class="dropdown-menu w-md-200px py-7 px-7 menu-sub menu-sub-dropdown"
          aria-labelledby="sortProfilesButton"
          role="menu"
        >

          <li
            class="
              d-flex
              align-items-center
              mb-1
              py-2
              ps-3
              pointer
              latestprofilesitem
              bg-hover-light
            "
            @click="sortProfilesBy('lastname')"
          >
            <i class="bi bi-sort-alpha-down me-3 fs-1"></i>
            by Last Name
          </li>
          <li
            v-if="this.$route.path != '/portal'"
            class="
              d-flex
              align-items-center
              mb-1
              py-2
              ps-3
              pointer
              latestprofilesitem
              bg-hover-light
            "
            @click="sortProfilesBy('recent')"
          >
            <i class="bi bi-arrow-counterclockwise me-3 fs-1"></i>
            by Recent
          </li>
          <li
            class="
              d-flex
              align-items-center
              mb-1
              py-2
              ps-3
              pointer
              latestprofilesitem
              bg-hover-light
            "
            @click="sortProfilesBy('company')"
          >
            <i class="bi bi-building me-3 fs-2"></i>
            by Company
          </li>
          <li
            v-if="$store.state.showingProfilesAs == 'projectProfiles'"
            id="sortProfilesByStatusBtn"
            class="
              d-flex
              align-items-center
              mb-1
              py-2
              ps-3
              pointer
              latestprofilesitem
              bg-hover-light
            "
            @click="sortProfilesBy('status')"
          >
            <div style="margin-right: 13px">
              <span
                class="bullet h-10px w-4px bg-success"
                style="margin-right: 2px"
              ></span>
              <span
                class="bullet h-10px w-4px bg-primary"
                style="margin-right: 2px"
              ></span>
              <span class="bullet h-10px w-4px bg-danger"></span>
            </div>
            by Status
          </li>
        </ul>
      </div>
  <div>

  </div>
</template>

<script>

import { sortProfileListExternal } from "../../../js/profilelist";

export default {
    methods: {
            sortProfilesBy(sortKey) {
      sortProfileListExternal(sortKey);
    },
    }
}
</script>

<style>

</style>