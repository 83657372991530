<template>
  <div
    v-if="computedFiles && computedFiles.length > 0"
    class="d-flex flex-column mb-9"
    style="margin-top: -15px"
  >
    <!--begin::File-->
    <div
      v-for="file in computedFiles"
      :key="file.id"
      class="
        d-flex
        align-items-center
        bg-hover-light
        p-2
        border
        rounded
        border-0
      "
      
      @click="openModal($event), storeFileForDisplay(file)"
    >
      <!--begin::Icon-->
      <div class="symbol symbol-30px me-5">
        <svg
          v-if="file.type == 'pdf'"
          width="35px"
          height="35px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
        >
          <path
            style="fill: #e2e5e7"
            d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
          />
          <path
            style="fill: #b0b7bd"
            d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
          />
          <polygon style="fill: #cad1d8" points="480,224 384,128 480,128 " />
          <path
            style="fill: #f15642"
            d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"
          />
          <g>
            <path
              style="fill: #ffffff"
              d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48   c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z    M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
            />
            <path
              style="fill: #ffffff"
              d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296   c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
            />
            <path
              style="fill: #ffffff"
              d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68   h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912   c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
            />
          </g>
          <path
            style="fill: #cad1d8"
            d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
          />
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
        <svg
          v-if="file.type == 'doc' || file.type == 'docx'"
          width="35px"
          height="35px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 468.293 468.293"
          style="enable-background: new 0 0 468.293 468.293"
          xml:space="preserve"
        >
          <path
            style="fill: #e1e6e9"
            d="M337.335,0H95.219C84.874,0,76.488,8.386,76.488,18.732v430.829c0,10.345,8.386,18.732,18.732,18.732  H401.17c10.345,0,18.732-8.386,18.732-18.732V82.567L337.335,0z"
          />
          <rect
            x="48.39"
            y="258.067"
            style="fill: #27a2db"
            width="371.512"
            height="128.3"
          />
          <g>
            <path
              style="fill: #ebf0f3"
              d="M182.722,293.744c7.567,6.85,11.342,16.377,11.342,28.583c0,12.201-3.665,21.861-11.004,28.971   c-7.339,7.115-18.571,10.67-33.687,10.67h-26.056v-78.501h26.952C164.343,283.467,175.164,286.894,182.722,293.744z    M180.702,322.66c0-17.968-10.291-26.952-30.881-26.952h-13.252v53.793h14.714c9.505,0,16.789-2.262,21.843-6.795   C178.179,338.179,180.702,331.498,180.702,322.66z"
            />
            <path
              style="fill: #ebf0f3"
              d="M276.828,351.129c-7.933,7.75-17.739,11.625-29.419,11.625s-21.486-3.875-29.419-11.625   c-7.942-7.745-11.908-17.406-11.908-28.971c0-11.57,3.966-21.226,11.908-28.976c7.933-7.75,17.739-11.62,29.419-11.62   s21.486,3.87,29.419,11.62c7.942,7.75,11.908,17.406,11.908,28.976C288.736,333.723,284.77,343.383,276.828,351.129z    M267.122,301.997c-5.356-5.538-11.927-8.307-19.713-8.307c-7.787,0-14.358,2.769-19.713,8.307   c-5.346,5.543-8.024,12.26-8.024,20.161s2.678,14.618,8.024,20.156c5.356,5.543,11.927,8.312,19.713,8.312   c7.787,0,14.358-2.769,19.713-8.312c5.346-5.538,8.024-12.256,8.024-20.156S272.469,307.539,267.122,301.997z"
            />
            <path
              style="fill: #ebf0f3"
              d="M341.296,349.95c4.56,0,8.49-0.763,11.79-2.298c3.29-1.535,6.736-3.989,10.336-7.357l8.527,8.76   c-8.308,9.208-18.397,13.814-30.26,13.814c-11.872,0-21.715-3.82-29.538-11.456c-7.823-7.636-11.735-17.296-11.735-28.976   s3.985-21.409,11.963-29.2c7.969-7.782,18.041-11.675,30.205-11.675s22.327,4.492,30.488,13.476l-8.417,9.208   c-3.747-3.592-7.284-6.1-10.62-7.526c-3.327-1.421-7.238-2.134-11.735-2.134c-7.933,0-14.595,2.568-19.987,7.695   c-5.392,5.127-8.088,11.68-8.088,19.654c0,7.974,2.678,14.636,8.033,19.987C327.615,347.277,333.957,349.95,341.296,349.95z"
            />
          </g>
          <polygon
            style="fill: #2d93ba"
            points="48.39,386.364 76.488,412.491 76.488,386.364 "
          />
          <polygon
            style="fill: #ebf0f3"
            points="337.336,82.567 419.902,82.567 337.335,0 "
          />
          <polygon
            style="fill: #d5d6db"
            points="353.221,82.567 419.902,121.255 419.902,82.567 "
          />
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
        <svg
          v-if="file.type == 'xls' || file.type == 'xlsx' || file.type == 'csv'"
          width="35px"
          height="35px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
        >
          <path
            style="fill: #e2e5e7"
            d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
          />
          <path
            style="fill: #b0b7bd"
            d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
          />
          <polygon style="fill: #cad1d8" points="480,224 384,128 480,128 " />
          <path
            style="fill: #84bd5a"
            d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"
          />
          <g>
            <path
              style="fill: #ffffff"
              d="M144.336,326.192l22.256-27.888c6.656-8.704,19.584,2.416,12.288,10.736   c-7.664,9.088-15.728,18.944-23.408,29.04l26.096,32.496c7.04,9.6-7.024,18.8-13.936,9.328l-23.552-30.192l-23.152,30.848   c-6.528,9.328-20.992-1.152-13.696-9.856l25.712-32.624c-8.064-10.112-15.872-19.952-23.664-29.04   c-8.048-9.6,6.912-19.44,12.8-10.464L144.336,326.192z"
            />
            <path
              style="fill: #ffffff"
              d="M197.36,303.152c0-4.224,3.584-7.808,8.064-7.808c4.096,0,7.552,3.6,7.552,7.808v64.096h34.8   c12.528,0,12.8,16.752,0,16.752H205.44c-4.48,0-8.064-3.184-8.064-7.792v-73.056H197.36z"
            />
            <path
              style="fill: #ffffff"
              d="M272.032,314.672c2.944-24.832,40.416-29.296,58.08-15.728c8.704,7.024-0.512,18.16-8.192,12.528   c-9.472-6-30.96-8.816-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,43.008c-0.896,32.496-47.968,33.248-65.632,18.672   c-4.24-3.456-4.096-9.072-1.792-12.544c3.328-3.312,7.024-4.464,11.392-0.88c10.48,7.152,37.488,12.528,39.392-5.648   C321.28,339.632,268.064,351.008,272.032,314.672z"
            />
          </g>
          <path
            style="fill: #cad1d8"
            d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
          />
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
        <svg
          v-if="file.type == 'ppt' || file.type == 'pptx'"
          width="35px"
          height="35px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
        >
          <path
            style="fill: #e2e5e7"
            d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
          />
          <path
            style="fill: #b0b7bd"
            d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
          />
          <polygon style="fill: #cad1d8" points="480,224 384,128 480,128 " />
          <path
            style="fill: #f15642"
            d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"
          />
          <g>
            <path
              style="fill: #ffffff"
              d="M105.456,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48   c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.568,8.816-8.176,8.816c-4.224,0-8.688-3.184-8.688-8.816v-72.032   H105.456z M122.336,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H122.336z"
            />
            <path
              style="fill: #ffffff"
              d="M191.616,303.152c0-4.224,3.328-8.832,8.704-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48   c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816V303.152z    M208.496,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H208.496z"
            />
            <path
              style="fill: #ffffff"
              d="M301.68,311.472h-22.368c-11.136,0-11.136-16.368,0-16.368h60.496c11.392,0,11.392,16.368,0,16.368   h-21.232v64.608c0,11.12-16.896,11.392-16.896,0V311.472z"
            />
          </g>
          <path
            style="fill: #cad1d8"
            d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
          />
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </div>
      <!--end::Icon-->

      <!--begin::File name-->
      <div class="text-muted fs-6">
        <span>{{
          file.name.length < 25 ? file.name : file.name.substring(0, 25) + "..."
        }}</span>
      </div>
      <!--end::File name-->


      <!--begin::Menu-->

      <i
        class="ms-auto text-muted text-hover-danger me-3 fs-5 bi bi-trash"
        @click.stop="deleteFile(file)"
      ></i>


      <!--end::Menu-->
    </div>
    <!--end::File-->
  </div>
  <CardAlert
    v-else
    alerttype="info"
    title="No files"
    text="Click the button above to upload a file"
  >
  </CardAlert>
</template>

<script>
import CardAlert from "../../CardAlerts/CardAlert.vue";
import { deleteFileExternal } from "../../../js/projectdetails";



export default {
  components: {
    CardAlert,
  },

  methods: {
    openModal(event) {
    if (event.target.tagName === 'I') {
      return;
    }
    var myModal = new bootstrap.Modal(document.getElementById('fileviewer'));
    myModal.show();
  },
    storeFileForDisplay(file) {
      this.$store.state.fileToShow = false;
      this.$store.state.fileToShow = file;
    },
    deleteFile(file) {
      //This function lives in projectdetails.js and is also used both for project files and client files
      deleteFileExternal(file)
    },
  },
  computed: {
    computedFiles() {
      if (this.$store.state.currentRoute == "/projects") {
        if (!this.$store.state.currentProjectDetails.files) {
          return false;
        } else {
          this.$store.state.currentProjectDetails.files.reverse();
          return this.$store.state.currentProjectDetails.files;
        }
      } else if (this.$store.state.currentRoute == "/clients") {
        if (!this.$store.state.currentClientDetails.files) {
          return false;
        } else {
          this.$store.state.currentClientDetails.files.reverse();
          return this.$store.state.currentClientDetails.files;
        }
      }
    },
  },
};
</script>

<style>
</style>