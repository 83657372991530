<template>

<div style="margin-top: -20px;">
  
  <!--begin: Empty Profiles/Client contacts alerts-->
    <CardAlert
    v-if="$store.state.currentRoute == '/projects' && computedRecentProfiles.length == 0"
      alerttype="info"
      title="No profiles in this project yet"
      text="Attach at least one profile to this project"
    ></CardAlert>
        <CardAlert
    v-if="$store.state.currentRoute == '/clients' && computedClientContacts.length == 0"
      alerttype="info"
      title="No client contacts yet"
      text="Add at least one contact for this client"
    ></CardAlert>
      <!--end: Empty Profiles/Client contacts alerts-->


<!--begin: in Projectdetails-->



<div v-if="$store.state.currentRoute == '/projects' && computedRecentProfiles.length > 0">
  <div v-for="profile in computedRecentProfiles" :key="profile.id">


                          <div class="bg-hover-light d-flex align-items-center p-2 border border-0 rounded" @click="goToDetailedProfile(profile.id)" v-if="computedRecentProfiles.length > 0 && profile.candidateorclient == 'Candidate'">
                        <div class="d-flex flex-column symbol symbol-45px me-5">
                          <img
                            alt="Pic"
                            v-if="profile.profilepic"
                            :src="profile.profilepic"
                            onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                          />
                          <img
                            alt="Pic"
                            v-if="!profile.profilepic"
                            src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                          />
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <a class="text-dark fw-bolder fs-6">{{
                            profile.firstname +
                            " " +
                            profile.lastname.toUpperCase()
                          }}</a>
                          <span
                            v-if="profile.currentcompany"
                            class="text-muted fw-bold d-block fs-7 w-170px"
                            >{{
                                    profile.currentcompany.length < 20
                            ? profile.currentcompany
                            : profile.currentcompany.substring(0, 20) + "..."
                            }}</span
                          >
                        </div>
                      </div>

  </div>

</div>
<!--end: in Projectdetails-->

<!--begin: in Clientdetails-->
<div v-if="$store.state.currentRoute == '/clients'">
  <div v-for="profile in $store.state.clientContactsForThisClient" :key="profile.id">
    <div class="bg-hover-light d-flex align-items-center p-2 border border-0 rounded" @click="goToDetailedProfile(profile.id)">
                        <div class="d-flex flex-column symbol symbol-45px me-5">
                          <img
                            alt="Pic"
                            v-if="profile.profilepic"
                            :src="profile.profilepic"
                            onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                          />
                          <img
                            alt="Pic"
                            v-if="!profile.profilepic"
                            src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                          />
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <a class="text-dark fw-bolder fs-6">{{
                            profile.firstname +
                            " " +
                            profile.lastname.toUpperCase()
                          }}</a>
                          <span
                            v-if="profile.currentjobtitle"
                            class="text-muted fw-bold d-block fs-7 w-170px"
                            >{{
                                    profile.currentjobtitle.length < 20
                            ? profile.currentjobtitle
                            : profile.currentjobtitle.substring(0, 20) + "..."
                            }}</span
                          >
                        </div>
                      </div>
  </div>
</div>
<!--end: in Clientdetails-->


</div>


<div class="d-flex justify-content-center mt-4" v-if="$store.state.currentRoute == '/projects' && computedRecentProfiles.length > 0">
  <button class="btn btn-sm btn-light-primary" @click="getProfilesForThisProject($store.state.currentProjectDetails.id)">Go to profiles</button>
</div>


</template>

<script>

import { getRecentCandidateProfilesExternal } from "../../../js/projectdetails"
import { goToDetailedProfileExternal } from "../../../js/navigation"
import { getProfilesForThisProjectExternal } from "../../../js/getdata"
import { getClientContactsExternal } from "../../../js/clientdetails"
import CardAlert from "../../CardAlerts/CardAlert.vue";


export default {

  components: {
    CardAlert
  },
  data() {
    return {
    }
  },

  mounted() {

    if(this.$store.state.currentRoute == '/projects') {
    setTimeout(() => {
      this.getRecentCandidateProfiles()
    }, 200);
    }

    if(this.$store.state.currentRoute == '/clients') {
          setTimeout(() => {
      this.getClientContacts()
    }, 200);
    }


  },
  computed: {
    computedRecentProfiles() {


this.$store.state.profilesInProjectForLatestProfilesFeature.sort(function(a, b) {
  return b.latestupdatetimestamp - a.latestupdatetimestamp;
});

const firstThreeElements = this.$store.state.profilesInProjectForLatestProfilesFeature.slice(0, 3);


return firstThreeElements

    },
    computedClientContacts() {
      return this.$store.state.clientContactsForThisClient
    }
  },

  methods: {
        goToDetailedProfile(profileId) {
      this.$router.push("/profiles");
      goToDetailedProfileExternal(profileId);
    },
    getProfilesForThisProject(projectid) {
      this.$router.push("/profiles");
      getProfilesForThisProjectExternal(projectid)
    },
    getRecentCandidateProfiles() {
      getRecentCandidateProfilesExternal()
    },
    getClientContacts() {
      getClientContactsExternal()
    }
  }

}
</script>

<style>

</style>