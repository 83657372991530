<template>
  <div
    class="wrapper d-flex flex-column flex-row-fluid container-xxl"
    id="kt_wrapper"
  >
    <!--begin::Toolbar-->
    <Toolbar title="Dashboard"></Toolbar>
    <!--end::Toolbar-->
    <!--begin::Main-->
    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div class="content flex-row-fluid" id="kt_content">
        <!--begin::Row-->
        <div class="row g-5 g-xl-8 d-flex">


          <!--begin::Col-->
          <div class="col-xl-4">
            <ProfileActivityKpi v-if="$store.state.userData.userid" origin="dashboardmain" ></ProfileActivityKpi>
          </div>
          <!--end::Col-->

                    <!--begin::Col-->
          <div v-if="$store.state.userData.userid" class="col-xl-4">
            <SmallCard
             whichCard = "tasksindashboard"
             title="Profile tasks"
             subTitle="Last 30 Uncompleted"
            ></SmallCard>
          </div>
          <!--end::Col-->

                    <!--begin::Col-->
          <div class="col-xl-4">
            <SmallCard
             whichCard = "emailsindashboard"
             title="Inbox"
             subTitle="Last 30 emails"
            ></SmallCard>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Content-->
    </div>

    <!--end::Main-->
    <!--begin::Footer-->
    <div
      class="d-none footer py-4 d-flex flex-column flex-md-row align-items-center justify-content-between"
      id="kt_footer"
    >
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="text-white opacity-75 fw-bold me-1">2022©</span>
        <a
          href="https://kiwanotalent.com"
          target="_blank"
          class="text-white text-hover-primary opacity-75"
          >Kiwano</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-white menu-hover-primary fw-bold order-1 opacity-75">
        <li class="menu-item">
          <a
            href="https://kiwanotalent.com"
            target="_blank"
            class="menu-link px-2"
            >About</a
          >
        </li>
        <li class="menu-item">
          <a
            href="https://kiwanotalent.com/userguide"
            target="_blank"
            class="menu-link px-2"
            >Support</a
          >
        </li>
        <li class="menu-item">
          <a
            href="https://1.envato.market/EA4JP"
            target="_blank"
            class="menu-link px-2"
            >Purchase</a
          >
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
import Toolbar from "./Toolbar-Component.vue";
import ProfileActivityKpi from "./Cards/ProfileActivityKpi.vue";
import SmallCard from "./Cards/SmallCard.vue";

export default {
  components: {
    Toolbar,
    ProfileActivityKpi,
    SmallCard
  },
  mounted() {
    //var KTWidgets = require('../../public/assets/js/custom/widgets')
    //KTWidgets.init()
    //var KTUtil = require('../../public/assets/js/scripts.bundle')
    //var KTMenu = require('../../public/assets/js/scripts.bundle')
    //KTMenu.init()
  },

};
</script>

<style>
</style>