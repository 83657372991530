<template>

  <!--begin::Main-->
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">

      <!--begin::Body-->
      <div class="d-flex flex-column flex-lg-row-fluid">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <!--begin::Wrapper-->
          <div class="w-lg-500px p-10 p-lg-15 mx-auto">

              <!--begin::Heading-->
              <div class="text-center mb-10">
                <!--begin::Title-->
                <h1 class="text-light mb-3">Log in to Kiwano</h1>
                <!--end::Title-->
              </div>
              <!--begin::Heading-->
              <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-light">Email</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  v-model="signinemail"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="email"
                  autocomplete="off"
                />
                <span class="badge badge-danger badge-sm mt-3" v-if="$store.state.authError == 'auth/invalid-email'">Invalid email</span>
          
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack mb-2">
                  <!--begin::Label-->
                  <label class="form-label fw-bolder text-light fs-6 mb-0">Password</label>
                  <!--end::Label-->
                  <!--begin::Link-->
                  <a
                  href="#"
                    class="link-muted fs-6 fw-bolder"
                    data-bs-toggle="modal"
                    data-bs-target="#resetpassword"
                  >Forgot Password ?</a>
                  <!--end::Link-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Input-->
                <input
                  v-model="signinpassword"
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  name="password"
                  autocomplete="off"
                />
                
                <span class="badge badge-danger badge-sm mt-3" v-if="$store.state.authError == 'auth/wrong-password'">Wrong password</span>


                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="text-center">

              </div>
              <!--end::Actions-->
            
        
            <!--begin::Submit button-->
            <button
              @click="signInMethod"
              class="btn btn-lg btn-primary w-100 mb-5"
            >
              <span class="indicator-label">Continue</span>
              <span class="indicator-progress">Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            <!--end::Submit button-->

          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
        <!--begin::Footer-->
        <div class="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <!--begin::Links-->
          <div class="d-flex flex-center text-muted fw-bold fs-6">
            Kiwano 2023 - All rights reserved
          </div>
          <!--end::Links-->
        </div>
        <!--end::Footer-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
  <!--end::Root-->
  <!--end::Main-->

</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../firebase/index";
import router from "../router/index";
import { signInFunc } from "../js/auth"

export default {
  data() {
    return {
      signinemail: "",
      signinpassword: ""
    };
  },
  methods: {

      signInMethod() {
          //imported from jsfunctions/auth.js
          signInFunc(this.signinemail, this.signinpassword)
      }


  },
  mounted() {
      
  },
};
</script>

<style>
</style>