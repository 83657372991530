import { collection, getDocs, query, where, arrayUnion, arrayRemove } from 'firebase/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { db } from "../firebase/index";
import store from "../store/index";

import { updateProfileStatusExternal } from "../js/profiledetails"
import { getSingleProjectExternal } from "../js/getdata"

import { saveAs } from "file-saver";

const axios = require('axios');

import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';


/*
//apply new profile status
function applyProfileStatusExternal(status, profileid, origin) {
    //test

    //change the profile status
    async function updateProfileStatus() {
        const profileRef = doc(db, "candidateprofiles", profileid);
        // Set the new status
        await updateDoc(profileRef, {
            status: status.status
        });
    }
    updateProfileStatus()


    //animation to show the user that the profile updated
    document.getElementById("profileRow" + profileid).classList.add("customUpdatedRowAnimation")
    setTimeout(() => {
        document.getElementById("profileRow" + profileid).classList.remove("customUpdatedRowAnimation")
    }, 1000);


    if (origin == "batchManipulateBanner") {



    }
    else if (origin == "profileRow") {
        //close dropdown when menu item clicked (when status changed within a profile row)
        document.getElementById("status" + profileid).classList.remove("show")
        document.getElementById("submenu" + profileid).classList.remove("show")
    }



}
*/

////////////////////////////////
/*Batch manipulate profiles*///
///////////////////////////////
//Please note: apart from these functions, 
//in ProfileList on Mounted:
//the SelectedProfiles array is cleared, 
//and the selectAllProfilesChecked property
//is set back to false. 
//This is important as it prevents weird behaviour when
//the user goes to a detailed profile and then returns
//to ProfileList.


//Select one single profie
function selectProfileExternal(profileId) {

    let selectedProfiles = store.state.selectedProfiles

    //this will be the index of the profile id if it is already selected, otherwise it will be -1
    const indexOfAlreadySelectedProfile = selectedProfiles.indexOf(profileId)

    //if the profile is not already selected -> select profile
    if (indexOfAlreadySelectedProfile == -1) {
        //add profile id to array
        selectedProfiles.push(profileId)

    }
    //if the profile is already selected -> Uncheck profile row
    else {
        //remove the profile id from the array
        selectedProfiles.splice(indexOfAlreadySelectedProfile, 1)

    }

}

//Select all profiles
function selectAllProfilesExternal() {


    let currentProfileList = store.state.currentProfileList;
    let selectedProfiles = store.state.selectedProfiles;

    //switches the "Select all" property (Vuex store)
    store.state.selectAllProfilesChecked = !store.state.selectAllProfilesChecked


    //Select all profiles
    if (store.state.selectAllProfilesChecked) {
        selectedProfiles.splice(0, selectedProfiles.length)
        for (let i = 0; i < currentProfileList.length; i++) {
            selectedProfiles.push(currentProfileList[i].id)
        }
    }
    //Deselect all profiles
    else {
        selectedProfiles.splice(0, selectedProfiles.length)
    }



}

//Add tag to multiple profiles (BatchManipulateBanner)
function batchAddProfileTagExternal(tagvalue) {

    let selectedProfiles = store.state.selectedProfiles
    //loop through selected profiles
    for (let i = 0; i < selectedProfiles.length; i++) {

        let profileid = store.state.selectedProfiles[i]

        //Update Firestore with the updated Vuex array
        let profileRef = doc(db, "profiles", profileid)
        updateDoc(profileRef, {
            tags: arrayUnion(tagvalue)
        })
            .then(() => {
                //Confirmation toast
                iziToast.show({
                    title: 'Tag added to profile !',
                    backgroundColor: '#47be7d',
                    titleColor: 'white',
                    theme: 'dark',
                    position: 'bottomRight',
                    timeout: 3000,
                    icon: 'fa fa-check'
                });
            })


        /////Update the profile in Vuex as there is no realtime listener attached to the profile in Firestore

        // find the item in currentProfileList whose .id property matches selectedProfile
        let profile = store.state.currentProfileList.find(profile => profile.id === store.state.selectedProfiles[i]);

        // if the profile was found and it has a 'tags' property
        if (profile && profile.tags) {
            // only add the tagvalue if it's not already in the tags array
            if (!profile.tags.includes(tagvalue)) {
                profile.tags.push(tagvalue);
            } else {
                // Log or handle the case when the tagvalue is already present in the tags array
                console.log('Tagvalue already present in the tags array');
            }
        } else {
            // Log or handle the case when the profile wasn't found or doesn't have a 'tags' property
            console.log('Profile not found or it does not have a tags property');
        }

    }


}


//Temporary helper dev function to add industry to profiles already existing in DB. Not accessible to users.
function batchApplyProfileIndustry(industry) {

    let selectedProfiles = store.state.selectedProfiles
    //loop through selected profiles
    for (let i = 0; i < selectedProfiles.length; i++) {

        let profileid = store.state.selectedProfiles[i]

        //Update Firestore with the updated Vuex array
        let profileRef = doc(db, "profiles", profileid)
        updateDoc(profileRef, {
            industrytransformed: industry
        })
            .then(() => {
                console.log("applied industry")
            })
    }
}


//Close the "Batch manipulate" banner
function closeBatchManipulateBannerExternal() {


    //if select all is already checked
    if (store.state.selectAllProfilesChecked) {
        //only 1 click to deselect all
        document.getElementById("selectAllBtn").click()
    }
    //if select all is not already checked
    else {
        //1 click to select all + 1 click to uncheck all
        document.getElementById("selectAllBtn").click()
        document.getElementById("selectAllBtn").click()
    }

}


//Update profile status of multiple selected profiles (when showing Projectprofiles only, not Recent profiles)
function batchUpdateProfileStatusExternal(statusobject) {



    const selectedProfileIds = store.state.selectedProfiles


    const currentProjectId = store.state.currentProjectDetailsForProfileList.id

    for (let i = 0; i < selectedProfileIds.length; i++) {

        console.log(selectedProfileIds[i])

        const profileRef = doc(db, "profiles", selectedProfileIds[i]);
        getDoc(profileRef)
            .then((profileDoc) => {


                let profile = profileDoc.data()
                profile.id = profileDoc.id


                const qFindBinding = query(collection(db, "profiles/" + selectedProfileIds[i] + "/profileprojectbindings"), where('projectid', '==', currentProjectId));
                getDocs(qFindBinding)
                    .then((bindingSnapshots) => {
                        bindingSnapshots.forEach((bindingDoc) => {


                            //in projectdetails.js
                            updateProfileStatusExternal("batchManipulateBanner", profile, bindingDoc.id, statusobject, bindingDoc.data().projectid)

                        });
                    })








            })





        /*
        const qFindBinding = query(collection(db, "profiles/" + selectedProfileIds[i] + "/profileprojectbindings"), where('projectid', '==', currentProjectId));
        getDocs(qFindBinding)
        .then((bindingSnapshots) => {
            bindingSnapshots.forEach((bindingDoc) => {
                console.log("here is the binding : " + bindingDoc.id, " => ", bindingDoc.data());

                const profileProjectBindingRef = doc(db, "profiles/" + selectedProfileIds[i] + "/profileprojectbindings", bindingDoc.id);
                updateDoc(profileProjectBindingRef, {
                  profilestatusid: statusobject.id
                })
                .then(() => {
                  //confirmation goes here
                  console.log("batch changed status")
                })
              });
        })
        */

    }



}

/* not used, we do a loop on the single attach profile to project function instead in profiledetails.js
function batchAttachProfileToProject(project) {


  const qProfileProjectBindings = query(collection(db, "profiles/" + profileid + "/profileprojectbindings"));
  getDocs(qProfileProjectBindings)
    .then((bindingsSnapshot) => {
      //create temporary & local binding array to store the bindings (not to pollute the existing array, if it exists)
      let tempBindings = [];
      bindingsSnapshot.forEach((bindingDoc) => {
        //add the bindings
        tempBindings.push(bindingDoc.data())
      })
      //make sure we are on profilelist before building the bindings array in Vuex
      //otherwise the bindings already exist in the array
      if (store.state.showingProfilesAs == 'projectProfiles' || store.state.showingProfilesAs == 'recentProfiles') {
        store.state.currentProfileProjectBindings = tempBindings
      }
      // now we have the correct currentProfileProjectBindings array whether the click came from profilelist or profiledetails


    })
}
*/


function sortProfileListExternal(sortKey) {
    if (sortKey == "lastname") {
        function compare(a, b) {
            if (a.lastname < b.lastname) {
                return -1;
            }
            if (a.lastname > b.lastname) {
                return 1;
            }
            return 0;
        }

        store.state.currentProfileList.sort(compare);
    }
    if (sortKey == "recent") {
        function compare(a, b) {
            if (a.latestupdatetimestamp > b.latestupdatetimestamp) {
                return -1;
            }
            if (a.latestupdatetimestamp < b.latestupdatetimestamp) {
                return 1;
            }
            return 0;
        }

        store.state.currentProfileList.sort(compare);
    }
    if (sortKey == "company") {
        function compare(a, b) {
            if (a.currentcompany < b.currentcompany) {
                return -1;
            }
            if (a.currentcompany > b.currentcompany) {
                return 1;
            }
            return 0;
        }

        store.state.currentProfileList.sort(compare);
    }
    if (sortKey == "status") {

        //this is a tricky one as we need to check to the corresponding status in the team's status list, 
        //retrieve the order, 
        //then sort the profiles on that specific order
        //Because the order of the different statuses is defined by the users


        //create variable to store the list of statuses AND the statuses with its order as first characters
        let profileStatusListWithOrderAsFirstCharacter = []

        //build this new array with 'break' between the order and the status id, so that we can split it later to retrieve the order
        for (let i = 0; i < store.state.profileStatusList.length; i++) {
            profileStatusListWithOrderAsFirstCharacter.push({ "statuswithorder": store.state.profileStatusList[i].order + "break" + store.state.profileStatusList[i].id, "statusid": store.state.profileStatusList[i].id })
        }

        //now find the corresponding status from this new array for each profile
        for (let i = 0; i < store.state.currentProfileList.length; i++) {
            let index = profileStatusListWithOrderAsFirstCharacter.findIndex(item => item.statusid === store.state.currentProfileList[i].statusid);

            //if no status is found it means that the status is "No status", then keep status order at 9000 so it will always come last
            let statusorder = 9000
            if (index == -1) {
                //do nothing, statusorder will remain 9000
            }
            else {
                //split the new array to retrieve the order only
                let tempArray = profileStatusListWithOrderAsFirstCharacter[index].statuswithorder.split("break")
                statusorder = tempArray[0]
            }

            //apply status order to the profile. the statusorder property is added to currentProfileList in each function that builds it (in getData.js), with value 0. it is never added to Firestore
            store.state.currentProfileList[i].statusorder = statusorder * 1
        }

        //now that each profile has the correct order of its status assigned to the statusorder property in currentProfileList, 
        //we can simply run a sort function based on that statusorder
        function compare(a, b) {
            if (a.statusorder < b.statusorder) {
                return -1;
            }
            if (a.statusorder > b.statusorder) {
                return 1;
            }
            return 0;
        }

        //sort the profiles
        store.state.currentProfileList.sort(compare);


    }
}

function filterProfileListExternal() {

    /*
    if(store.state.cachedProfileList.length > 0) {
        store.state.currentProfileList = store.state.cachedProfileList
    }
    */

    let filteredProfiles = store.state.currentProfileList


    if (store.state.addedByMe) {
        filteredProfiles = filteredProfiles.filter(element => {
            return element.owninguser === store.state.userData.userid;
        });
    }
    if (store.state.visibleToClient) {
        filteredProfiles = filteredProfiles.filter(element => {
            return element.clientvisible === true;
        });
    }
    if (store.state.gdprFilterValue) {
        filteredProfiles = filteredProfiles.filter(element => {
            return element.gdprconsentstatus === store.state.gdprFilterValue;
        });
    }


    if (store.state.tagFilterArray.length > 0) {
        filteredProfiles = filteredProfiles.filter(e1 => {
            return e1.tags.some(e2 => store.state.tagFilterArray.includes(e2))
        });

    }

    if (store.state.statusFilterArray.length > 0) {
        filteredProfiles = filteredProfiles.filter(element => {
            return store.state.statusFilterArray.includes(element.statusid);
        });

    }


    store.state.currentProfileList = filteredProfiles
    if (store.state.currentProfileList.length == 0) {
        store.state.emptyProfileList = true;
    }

    //arr1.some(r=> arr2.includes(r))



}

//Not used at the moment
function applyMatchExternal(profile) {
    alert(profile.firstname + " " + profile.lastname)
}

function hideProfileExternal(profileobj) {


    const projectRef = doc(db, "projects", store.state.currentProjectDetailsForProfileList.id);
    getDoc(projectRef)
        .then((projectSnap) => {


            //add this profile to the discardedrecommendedprofiles array both in Firestore and Vuex (because no realtime update for the project doc)
            //the computed property in profilelist will exlude profiles who are in this array
            updateDoc(projectRef, {
                discardedrecommendedprofiles: arrayUnion(profileobj.profile_id)
            })
                .then(() => {
                    store.state.currentProjectDetailsForProfileList.discardedrecommendedprofiles.push(profileobj.profile_id)

                    let idToMatch = profileobj.profile_id;

                    let index = store.state.recommendedProfiles.findIndex(function (profile) {
                        return profile.profile_id === idToMatch;
                    });

                    if (index !== -1) {
                        store.state.recommendedProfiles.splice(index, 1);
                    }
                })






        })

}



function exportToExcelExternal() {


    //get general styles

    let fontFamily = "Calibri"
    let smallFontSize = "9"
    let normalFontSize = ""
    let titleFontSize = "20"
    let themeColorLight = "ffe5e5e4"
    let themeColorDark = "ff535353"
    let borderColor = "ffcccccc"


    if (store.state.userData.proptions.themecolor == undefined || store.state.userData.proptions.themecolor == "gray") {
        themeColorLight = "ffe5e5e4"
        themeColorDark = "ff535353"

    }
    if (store.state.userData.proptions.themecolor == "green") {
        themeColorLight = "ffdfefcf"
        themeColorDark = "ff3e5b1f"

    }
    else if (store.state.userData.proptions.themecolor == "red") {
        themeColorLight = "ffdc9d9b"
        themeColorDark = "ffa43b39"
    }
    else if (store.state.userData.proptions.themecolor == "blue") {

        themeColorLight = "ffbdcedc"
        themeColorDark = "ff4b7190"

    }






    //this changes the "new" property key into "newProfile" for each profile in the list, as new seems to be a reserved
    //keyword and can not be filtered out below. 
    //also merge firstname and lastname
    const transformedProfiles = store.state.currentProfileList.map(
        (obj) => {
            const {
                new: newProfile,
                firstname,
                lastname,
                statusid,
                ...otherProperties
            } = obj;
            const status = store.state.profileStatusList.find((statusObj) => statusObj.id === statusid);
            return {
                newProfile,
                statusid: status ? status.status : "",
                fullname: `${firstname} ${lastname}`,
                lastnameinitial: `${firstname} ${lastname.substring(0, 1) + "."}`,
                initials: `${firstname.substring(0, 1) + "."} ${lastname.substring(0, 1) + "."}`,
                ...otherProperties,
            };
        }
    );


    //this filters out all the fields that should not appear in the report
    //not used anymore but we keep it because the transformation of profile experience is important.
    //could be refactored in the future
    const filteredProfiles = transformedProfiles.map((profile) => {
        const {
            //Below: fields to filter out
            favourite,
            companysize,
            opentaskscounter,
            newemailscounter,
            profilepic,
            currentcompanylilogo,
            linkedinsummary,
            candidateorclient,
            cvurl,
            currentcompanyliurl,
            experiencemetadata,
            firsttimeshown,
            teamaccess,
            teamid,
            cvfile,
            gdprconsentstatus,
            latestupdatebyuser,
            //cannot add the "new" field as it seems to be a reserved keywork, so the function above changed the "new" key to "newProfile"
            newProfile,
            experiencelist,
            alsoviewed,
            educationlist,
            clientvisible,
            stateofmind,
            tags,
            owninguser,
            latestupdatetimestamp,
            id,
            statusorder,
            latestbindingclientlogo,
            lateststatuscategory,
            latestbindingclientname,
            latestbindingprojectid,
            latestbindingposition,
            latestcommenttext,
            cvfexperiencelist,
            bindingid,
            bindingprojectid,
            clientlocation,
            clientname,
            clientlogo,
            clientstatus,
            comments,
            notes,
            currentcompany,
            currentjobtitle,

            ...rest

        } = profile;

        const filteredProfile = {
            ...rest
        };




        //Conditionally add fields to the destructuring assignment above if the user has selected them to be included in the PR       
        //not used anymore but we keep it because the transformation of profile experience is important.
        //could be refactored in the future
        if (store.state.userData.proptions.fields.notes) {
            filteredProfile.notes = profile.notes
        }
        if (store.state.userData.proptions.fields.company) {
            filteredProfile.currentcompany = profile.currentcompany
        }
        if (store.state.userData.proptions.fields.title) {
            filteredProfile.currentjobtitle = profile.currentjobtitle
        }
        if (store.state.userData.proptions.fields.experience) {
            //transform experience from array to string with line breaks (\n)
            if (!profile.experiencelist[0]) {
                filteredProfile.experiencelist = "No data"
            }
            else {
                let tempexperiencelist = ""
                for (let i = 0; i < profile.experiencelist.length; i++) {
                    if (profile.experiencelist[i].endyear == 3000 || profile.experiencelist[i].endyear == "3000") {
                        tempexperiencelist = tempexperiencelist + profile.experiencelist[i].startyear + "-" + "Today" + ": " + profile.experiencelist[i].title + ", " + profile.experiencelist[i].company + "\n\n"

                    }
                    else {
                        tempexperiencelist = tempexperiencelist + profile.experiencelist[i].startyear + "-" + profile.experiencelist[i].endyear + ": " + profile.experiencelist[i].title + ", " + profile.experiencelist[i].company + "\n\n"

                    }
                }
                filteredProfile.experiencelist = tempexperiencelist
            }


        }


        return filteredProfile;
    });




    //create object of the fields the user wants to include
    let includedFields = {}

    if (store.state.userData.proptions.fields.name.included) {
        includedFields.name = store.state.userData.proptions.fields.name
    }
    if (store.state.userData.proptions.fields.company.included) {
        includedFields.company = store.state.userData.proptions.fields.company
    }
    if (store.state.userData.proptions.fields.title.included) {
        includedFields.title = store.state.userData.proptions.fields.title
    }
    if (store.state.userData.proptions.fields.experience.included) {
        includedFields.experience = store.state.userData.proptions.fields.experience
    }
    if (store.state.userData.proptions.fields.status.included) {
        includedFields.status = store.state.userData.proptions.fields.status
    }
    if (store.state.userData.proptions.fields.notes.included) {
        includedFields.notes = store.state.userData.proptions.fields.notes
    }
    if (store.state.userData.proptions.fields.location.included) {
        includedFields.location = store.state.userData.proptions.fields.location
    }
    if (store.state.userData.proptions.fields.contactdetails.included) {
        includedFields.contactdetails = store.state.userData.proptions.fields.contactdetails
    }
    if (store.state.userData.proptions.fields.linkedinurl.included) {
        includedFields.linkedinurl = store.state.userData.proptions.fields.linkedinurl
    }

    //rename order from 1 to A for ex., so that we can tell Exceljs in which column to write each field
    let nameOrder = ""
    if (store.state.userData.proptions.fields.name.order == 1) {
        nameOrder = "A"
    }
    else if (store.state.userData.proptions.fields.name.order == 2) {
        nameOrder = "B"
    }
    else if (store.state.userData.proptions.fields.name.order == 3) {
        nameOrder = "C"
    }
    else if (store.state.userData.proptions.fields.name.order == 4) {
        nameOrder = "D"
    }
    else if (store.state.userData.proptions.fields.name.order == 5) {
        nameOrder = "E"
    }
    else if (store.state.userData.proptions.fields.name.order == 6) {
        nameOrder = "F"
    }
    else if (store.state.userData.proptions.fields.name.order == 7) {
        nameOrder = "G"
    }
    else if (store.state.userData.proptions.fields.name.order == 8) {
        nameOrder = "H"
    }
    else if (store.state.userData.proptions.fields.name.order == 9) {
        nameOrder = "I"
    }



    let companyOrder = ""
    if (store.state.userData.proptions.fields.company.order == 1) {
        companyOrder = "A"
    }
    else if (store.state.userData.proptions.fields.company.order == 2) {
        companyOrder = "B"
    }
    else if (store.state.userData.proptions.fields.company.order == 3) {
        companyOrder = "C"
    }
    else if (store.state.userData.proptions.fields.company.order == 4) {
        companyOrder = "D"
    }
    else if (store.state.userData.proptions.fields.company.order == 5) {
        companyOrder = "E"
    }
    else if (store.state.userData.proptions.fields.company.order == 6) {
        companyOrder = "F"
    }
    else if (store.state.userData.proptions.fields.company.order == 7) {
        companyOrder = "G"
    }
    else if (store.state.userData.proptions.fields.company.order == 8) {
        companyOrder = "H"
    }
    else if (store.state.userData.proptions.fields.company.order == 9) {
        companyOrder = "I"
    }



    let titleOrder = ""
    if (store.state.userData.proptions.fields.title.order == 1) {
        titleOrder = "A"
    }
    else if (store.state.userData.proptions.fields.title.order == 2) {
        titleOrder = "B"
    }
    else if (store.state.userData.proptions.fields.title.order == 3) {
        titleOrder = "C"
    }
    else if (store.state.userData.proptions.fields.title.order == 4) {
        titleOrder = "D"
    }
    else if (store.state.userData.proptions.fields.title.order == 5) {
        titleOrder = "E"
    }
    else if (store.state.userData.proptions.fields.title.order == 6) {
        titleOrder = "F"
    }
    else if (store.state.userData.proptions.fields.title.order == 7) {
        titleOrder = "G"
    }
    else if (store.state.userData.proptions.fields.title.order == 8) {
        titleOrder = "H"
    }
    else if (store.state.userData.proptions.fields.title.order == 9) {
        titleOrder = "I"
    }


    let statusOrder = ""
    if (store.state.userData.proptions.fields.status.order == 1) {
        statusOrder = "A"
    }
    else if (store.state.userData.proptions.fields.status.order == 2) {
        statusOrder = "B"
    }
    else if (store.state.userData.proptions.fields.status.order == 3) {
        statusOrder = "C"
    }
    else if (store.state.userData.proptions.fields.status.order == 4) {
        statusOrder = "D"
    }
    else if (store.state.userData.proptions.fields.status.order == 5) {
        statusOrder = "E"
    }
    else if (store.state.userData.proptions.fields.status.order == 6) {
        statusOrder = "F"
    }
    else if (store.state.userData.proptions.fields.status.order == 7) {
        statusOrder = "G"
    }
    else if (store.state.userData.proptions.fields.status.order == 8) {
        statusOrder = "H"
    }
    else if (store.state.userData.proptions.fields.status.order == 9) {
        statusOrder = "I"
    }


    let notesOrder = ""
    if (store.state.userData.proptions.fields.notes.order == 1) {
        notesOrder = "A"
    }
    else if (store.state.userData.proptions.fields.notes.order == 2) {
        notesOrder = "B"
    }
    else if (store.state.userData.proptions.fields.notes.order == 3) {
        notesOrder = "C"
    }
    else if (store.state.userData.proptions.fields.notes.order == 4) {
        notesOrder = "D"
    }
    else if (store.state.userData.proptions.fields.notes.order == 5) {
        notesOrder = "E"
    }
    else if (store.state.userData.proptions.fields.notes.order == 6) {
        notesOrder = "F"
    }
    else if (store.state.userData.proptions.fields.notes.order == 7) {
        notesOrder = "G"
    }
    else if (store.state.userData.proptions.fields.notes.order == 8) {
        notesOrder = "H"
    }
    else if (store.state.userData.proptions.fields.notes.order == 9) {
        notesOrder = "I"
    }




    let experienceOrder = ""
    if (store.state.userData.proptions.fields.experience.order == 1) {
        experienceOrder = "A"
    }
    else if (store.state.userData.proptions.fields.experience.order == 2) {
        experienceOrder = "B"
    }
    else if (store.state.userData.proptions.fields.experience.order == 3) {
        experienceOrder = "C"
    }
    else if (store.state.userData.proptions.fields.experience.order == 4) {
        experienceOrder = "D"
    }
    else if (store.state.userData.proptions.fields.experience.order == 5) {
        experienceOrder = "E"
    }
    else if (store.state.userData.proptions.fields.experience.order == 6) {
        experienceOrder = "F"
    }
    else if (store.state.userData.proptions.fields.experience.order == 7) {
        experienceOrder = "G"
    }
    else if (store.state.userData.proptions.fields.experience.order == 8) {
        experienceOrder = "H"
    }
    else if (store.state.userData.proptions.fields.experience.order == 9) {
        experienceOrder = "I"
    }




    let locationOrder = ""
    if (store.state.userData.proptions.fields.location.order == 1) {
        locationOrder = "A"
    }
    else if (store.state.userData.proptions.fields.location.order == 2) {
        locationOrder = "B"
    }
    else if (store.state.userData.proptions.fields.location.order == 3) {
        locationOrder = "C"
    }
    else if (store.state.userData.proptions.fields.location.order == 4) {
        locationOrder = "D"
    }
    else if (store.state.userData.proptions.fields.location.order == 5) {
        locationOrder = "E"
    }
    else if (store.state.userData.proptions.fields.location.order == 6) {
        locationOrder = "F"
    }
    else if (store.state.userData.proptions.fields.location.order == 7) {
        locationOrder = "G"
    }
    else if (store.state.userData.proptions.fields.location.order == 8) {
        locationOrder = "H"
    }
    else if (store.state.userData.proptions.fields.location.order == 9) {
        locationOrder = "I"
    }




    let contactdetailsOrder = ""
    if (store.state.userData.proptions.fields.contactdetails.order == 1) {
        contactdetailsOrder = "A"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 2) {
        contactdetailsOrder = "B"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 3) {
        contactdetailsOrder = "C"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 4) {
        contactdetailsOrder = "D"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 5) {
        contactdetailsOrder = "E"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 6) {
        contactdetailsOrder = "F"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 7) {
        contactdetailsOrder = "G"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 8) {
        contactdetailsOrder = "H"
    }
    else if (store.state.userData.proptions.fields.contactdetails.order == 9) {
        contactdetailsOrder = "I"
    }



    let linkedinurlOrder = ""
    if (store.state.userData.proptions.fields.linkedinurl.order == 1) {
        linkedinurlOrder = "A"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 2) {
        linkedinurlOrder = "B"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 3) {
        linkedinurlOrder = "C"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 4) {
        linkedinurlOrder = "D"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 5) {
        linkedinurlOrder = "E"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 6) {
        linkedinurlOrder = "F"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 7) {
        linkedinurlOrder = "G"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 8) {
        linkedinurlOrder = "H"
    }
    else if (store.state.userData.proptions.fields.linkedinurl.order == 9) {
        linkedinurlOrder = "I"
    }








    //start writing the Excel doc

    const ExcelJS = require('exceljs');

    const workbook = new ExcelJS.Workbook();


    // create new sheet with pageSetup settings for A4 - landscape
    const worksheet = workbook.addWorksheet('sheet', {
        pageSetup: { paperSize: 9, orientation: 'landscape', fitToWidth: 1, fitToHeight: 0 },

    });

    //worksheet.pageSetup.printArea = 'A1:I20';

    // make worksheet visible
    worksheet.state = 'visible';

    // Set Print Area for a sheet
    //keep for later: worksheet.pageSetup.printArea = 'A1:G20';










    /////////////write data


    for (let i = 0; i < filteredProfiles.length; i++) {


        //NAME

        let cellName = worksheet.getCell(nameOrder + (i + 5));
        if (store.state.userData.proptions.namedisplay == "fullname") {
            cellName.value = "\n" + filteredProfiles[i].fullname
        }
        else if (store.state.userData.proptions.namedisplay == "lastnameinitial") {
            cellName.value = "\n" + filteredProfiles[i].lastnameinitial
        }
        else if (store.state.userData.proptions.namedisplay == "initials") {
            cellName.value = "\n" + filteredProfiles[i].initials
        }



        //set style for corresponding column
        worksheet.getColumn(nameOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
        worksheet.getColumn(nameOrder).border = {
            top: { style: 'thin', color: { argb: borderColor } },
            left: { style: 'thin', color: { argb: borderColor } },
            bottom: { style: 'thin', color: { argb: borderColor } },
            right: { style: 'thin', color: { argb: borderColor } }
        }
        worksheet.getColumn(nameOrder).width = 22
        worksheet.getColumn(nameOrder).font = {
            name: fontFamily,
            size: 10,
            bold: true,
        }


        //COMPANY

        if (store.state.userData.proptions.fields.company.included) {
            let cellCompany = worksheet.getCell(companyOrder + (i + 5));
            cellCompany.value = "\n" + filteredProfiles[i].currentcompany

            //set style for corresponding column
            worksheet.getColumn(companyOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(companyOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(companyOrder).width = 22
            worksheet.getColumn(companyOrder).font = {
                name: fontFamily,
                size: 10,
            }
        }


        //TITLE

        if (store.state.userData.proptions.fields.title.included) {
            let cellTitle = worksheet.getCell(titleOrder + (i + 5));
            cellTitle.value = "\n" + filteredProfiles[i].currentjobtitle + "\n"

            //set style for corresponding column
            worksheet.getColumn(titleOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(titleOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(titleOrder).width = 26
            worksheet.getColumn(titleOrder).font = {
                name: fontFamily,
                size: 10,
            }
        }


        //STATUS

        if (store.state.userData.proptions.fields.status.included) {
            let cellStatus = worksheet.getCell(statusOrder + (i + 5));
            cellStatus.value = "\n" + filteredProfiles[i].statusid

            //set style for corresponding column
            worksheet.getColumn(statusOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(statusOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(statusOrder).width = 15
            worksheet.getColumn(statusOrder).font = {
                name: fontFamily,
                size: 10,
                bold: true,
                italic: true
            }
        }


        //NOTES

        if (store.state.userData.proptions.fields.notes.included) {
            let cellNotes = worksheet.getCell(notesOrder + (i + 5));

            /* 
            //Before when we were including comments. We replaced this with Notes instead
                        let tempComments = ""
                        for (let j = 0; j < filteredProfiles[i].comments.length; j++) {
                            if (filteredProfiles[i].notes[j].showtoclient) {
                                tempComments = tempComments + "- " + filteredProfiles[i].comment[j].commenttext + "\n\n"
                            }
                        }
                        cellComments.value = "\n" + tempComments
                        */

            cellNotes.value = "\n" + filteredProfiles[i].notes + "\n"



            //set style for corresponding column
            worksheet.getColumn(notesOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(notesOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(notesOrder).width = 26
            worksheet.getColumn(notesOrder).font = {
                name: fontFamily,
                size: 10,
            }
        }


        //LOCATION

        if (store.state.userData.proptions.fields.location.included) {
            let cellLocation = worksheet.getCell(locationOrder + (i + 5));
            cellLocation.value = "\n" + filteredProfiles[i].location + "\n"
            if (cellLocation.value.includes(", null")) {
                cellLocation.value = cellLocation.value.replace(", null", "");
            }

            //set style for corresponding column
            worksheet.getColumn(locationOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(locationOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(locationOrder).width = 22
            worksheet.getColumn(locationOrder).font = {
                name: fontFamily,
                size: 10,
            }
        }


        //LINKEDIN URL

        if (store.state.userData.proptions.fields.linkedinurl.included) {
            let cellLI = worksheet.getCell(linkedinurlOrder + (i + 5));
            cellLI.value = {
                text: '\n' + filteredProfiles[i].linkedinurl,
                hyperlink: filteredProfiles[i].linkedinurl,
                tooltip: 'LinkedIn profile'
            };

            cellLI.font = {
                color: { argb: 'ff39a3c8' },
                underline: true,
            };

            //set style for corresponding column
            worksheet.getColumn(linkedinurlOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(linkedinurlOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(linkedinurlOrder).width = 18
            worksheet.getColumn(linkedinurlOrder).font = {
                name: fontFamily,
                size: 10,
                color: { argb: themeColorDark },
                underline: true
            }

        }


        //CONTACT DETAILS

        if (store.state.userData.proptions.fields.contactdetails.included) {
            let cellPhone = worksheet.getCell(contactdetailsOrder + (i + 5));
            cellPhone.value = "\n" + filteredProfiles[i].mobilenumber + "\n" + filteredProfiles[i].email

            //set style for corresponding column
            worksheet.getColumn(contactdetailsOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(contactdetailsOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(contactdetailsOrder).width = 18
            worksheet.getColumn(contactdetailsOrder).font = {
                name: fontFamily,
                size: 10,
            }

        }


        //EXPERIENCE

        if (store.state.userData.proptions.fields.experience.included) {
            let cellExp = worksheet.getCell(experienceOrder + (i + 5));
            cellExp.value = "\n" + filteredProfiles[i].experiencelist

            //set style for corresponding column
            worksheet.getColumn(experienceOrder).alignment = { wrapText: true, vertical: 'top', horizontal: 'left', width: 120, indent: 1 };
            worksheet.getColumn(experienceOrder).border = {
                top: { style: 'thin', color: { argb: borderColor } },
                left: { style: 'thin', color: { argb: borderColor } },
                bottom: { style: 'thin', color: { argb: borderColor } },
                right: { style: 'thin', color: { argb: borderColor } }
            }
            worksheet.getColumn(experienceOrder).width = 40
            worksheet.getColumn(experienceOrder).font = {
                name: fontFamily,
                size: 9,
            }



        }




    }





    //Write title and headers

    const projectId = store.state.currentProfileList[0].bindingprojectid

    //search for corresponding project in my projects
    let project = store.state.currentMyProjectList.find(project => project.id === projectId);

    //search for corresponding project in team projects
    if (project == undefined) {
        project = store.state.currentTeamProjectList.find(project => project.id === projectId);
    }

    //search for last column in order to set the merged range for title display
    const maxOrder = Object.values(includedFields).reduce((max, field) => {
        return Math.max(max, field.order);
    }, 0);

    let lastCol = ""
    let twoColsBeforeLast = ""
    let oneColBeforeLast = ""
    let oneAfterLast = ""

    if (maxOrder == 1) {
        alert("You need to include at least 4 fields.")
        return
    }
    else if (maxOrder == 2) {
        alert("You need to include at least 4 fields.")
        return
    }
    else if (maxOrder == 3) {
        alert("You need to include at least 4 fields.")
        return
    }
    else if (maxOrder == 4) {
        lastCol = "D"
        twoColsBeforeLast = "B"
        oneColBeforeLast = "C"
        oneAfterLast = "E"
    }
    else if (maxOrder == 5) {
        lastCol = "E"
        twoColsBeforeLast = "C"
        oneColBeforeLast = "D"
        oneAfterLast = "F"
    }
    else if (maxOrder == 6) {
        lastCol = "F"
        twoColsBeforeLast = "D"
        oneColBeforeLast = "E"
        oneAfterLast = "G"
    }
    else if (maxOrder == 7) {
        lastCol = "G"
        twoColsBeforeLast = "E"
        oneColBeforeLast = "F"
        oneAfterLast = "H"
    }
    else if (maxOrder == 8) {
        lastCol = "H"
        twoColsBeforeLast = "F"
        oneColBeforeLast = "G"
        oneAfterLast = "I"
    }
    else if (maxOrder == 9) {
        lastCol = "I"
        twoColsBeforeLast = "G"
        oneColBeforeLast = "H"
        oneAfterLast = "J"
    }

    //merge columns for title display + write and style title
    worksheet.mergeCells('A2:' + oneColBeforeLast + '2');



    worksheet.getCell('A2').value = "\n" + project.clientobject.clientname + "\n" + project.positiontitle + "\n"
    worksheet.getRow(2).height = 110;
    worksheet.getRow(1).height = 15;

    worksheet.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffffff' },
    }

    worksheet.getCell('A2').font = {
        name: fontFamily,
        size: titleFontSize,
        color: { argb: themeColorDark }
    }

    //merge, style and fill second col with date
    worksheet.mergeCells('A3:' + lastCol + '3');

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let MM = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    worksheet.getCell('A3').value = dd + "/" + MM + "/" + yyyy
    worksheet.getCell('A3').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffffff' },
    }
    worksheet.getCell('A3').font = {
        name: fontFamily,
        size: smallFontSize,
        color: { argb: themeColorDark }
    }
    worksheet.getRow(3).height = 32;


    //style and write headers

    worksheet.getRow(4).height = 37;
    worksheet.getRow(4).font = {
        size: 10,
        bold: true
    }


    const incluededFieldsArray = Object.values(includedFields);

    worksheet.getCell('A4').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: themeColorDark },
    }
    worksheet.getCell('A4').font = {
        name: fontFamily,
        color: { argb: themeColorLight },
        bold: true
    }


    if (incluededFieldsArray.length > 1) {
        worksheet.getCell('B4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('B4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }

    if (incluededFieldsArray.length > 2) {
        worksheet.getCell('C4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('C4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }

    if (incluededFieldsArray.length > 3) {
        worksheet.getCell('D4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('D4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }


    if (incluededFieldsArray.length > 4) {
        worksheet.getCell('E4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('E4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }


    if (incluededFieldsArray.length > 5) {
        worksheet.getCell('F4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('F4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }

    if (incluededFieldsArray.length > 6) {
        worksheet.getCell('G4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('G4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }

    if (incluededFieldsArray.length > 7) {
        worksheet.getCell('H4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('H4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }


    if (incluededFieldsArray.length > 8) {
        worksheet.getCell('I4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: themeColorDark },
        }
        worksheet.getCell('I4').font = {
            name: fontFamily,
            color: { argb: themeColorLight },
            bold: true
        }
    }




    worksheet.getRow(4).alignment = { vertical: 'middle', indent: 1 }

    if (store.state.userData.proptions.fields.name.included) {
        worksheet.getCell(nameOrder + 4).value = "Name"
    }
    if (store.state.userData.proptions.fields.company.included) {
        worksheet.getCell(companyOrder + 4).value = "Company"
    }
    if (store.state.userData.proptions.fields.title.included) {
        worksheet.getCell(titleOrder + 4).value = "Title"
    }
    if (store.state.userData.proptions.fields.status.included) {
        worksheet.getCell(statusOrder + 4).value = "Status"
    }
    if (store.state.userData.proptions.fields.notes.included) {
        worksheet.getCell(notesOrder + 4).value = "Notes"
    }
    if (store.state.userData.proptions.fields.location.included) {
        worksheet.getCell(locationOrder + 4).value = "Location"
    }
    if (store.state.userData.proptions.fields.experience.included) {
        worksheet.getCell(experienceOrder + 4).value = "Experience"
    }
    if (store.state.userData.proptions.fields.linkedinurl.included) {
        worksheet.getCell(linkedinurlOrder + 4).value = "Profile link"
    }
    if (store.state.userData.proptions.fields.contactdetails.included) {
        worksheet.getCell(contactdetailsOrder + 4).value = "Contact details"
    }


    //style borders


    /*
    
    
    
      worksheet.getCell('A2').border = {
        top: {style:'double', color: {argb:themeColorDark}},
        left: {style:'double', color: {argb:themeColorDark}}, 
        right: {style:'double', color: {argb:themeColorDark}}   
      };
    
      worksheet.getColumn(1).border = {
        left: {style:'double', color: {argb:themeColorDark}}
      };
    
      worksheet.mergeCells('A1:' + lastCol + '1');
    
      worksheet.getCell('A1').border = {
        right: {style:'double', color: {argb:'FFFFFFFF'}},
        bottom: {style:'double', color: {argb:themeColorDark}}
      };
    
    */





    worksheet.mergeCells('A1:' + lastCol + '1');
    worksheet.getCell('A1').border = {
        top: { style: 'double', color: { argb: 'ffffffff' } },
        right: { style: 'double', color: { argb: 'ffffffff' } },
        left: { style: 'double', color: { argb: 'ffffffff' } },
        bottom: { style: 'double', color: { argb: themeColorDark } }
    };

    worksheet.getCell('A2').border = {
        bottom: { style: 'double', color: { argb: 'ffffffff' } },
    };

    worksheet.getCell(lastCol + '2').border = {
        left: { style: 'thin', color: { argb: 'ffffffff' } },
        bottom: { style: 'thin', color: { argb: 'ffffffff' } },
    };

    worksheet.getRow(3).border = {
        top: { style: 'thin', color: { argb: 'ffffffff' } },
    };

    worksheet.getColumn(oneAfterLast).border = {
        left: { style: 'thin', color: { argb: themeColorLight } },
    };

    //check that the order values are contiguous
    //i tried a few things but wasn't able to find an easy way. 
    //TODO later



    //check that each column have a unique order
    function checkDuplicateOrders(includedFields) {
        let orders = [];
        for (let field in includedFields) {
            let order = includedFields[field].order;
            if (orders.includes(order)) {
                return true;
            }
            orders.push(order);
        }
        return false;
    }

    if (checkDuplicateOrders(includedFields)) {
        alert("It seems that two or more fields have the same order. You need to assign a unique order to each field.")
        return
    }






    async function addImageAndWriteFile() {
        console.log(store.state.teamData.teamlogourl)

        var lastColWidth = worksheet.getColumn(lastCol).width

        // "xxx" should be replaced with the client logo 
        //VERY IMPORTANT: if the src below starts with http and not https, the user will not be able to export the PR, error
        //the client 

        let companyLogo = "https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/White_box_55x90.png/1280px-White_box_55x90.png"
        
        
        
        if (store.state.teamData.teamlogourl) {
            companyLogo = store.state.teamData.teamlogourl
            console.log(companyLogo)
        }

        // add image to workbook by buffer
        const imageBuffer = await axios.get(companyLogo, { responseType: 'arraybuffer' });
        const imageId = workbook.addImage({
            buffer: imageBuffer.data,
            extension: 'png',
        });

        var imageHeight;
        var imageWidth;

        var image = new Image();
        image.src = companyLogo;

        image.onload = function () {
            imageHeight = this.naturalHeight;
            imageWidth = this.naturalWidth;

            var newImageWidth

            var aspectRatio = imageWidth / imageHeight;


            newImageWidth = lastColWidth * 3


            var newImageHeight = newImageWidth / aspectRatio;


            if (store.state.userData.proptions.logo) {
                worksheet.addImage(imageId, {
                    tl: { col: ((maxOrder - 1)*1.3), row: 1.8 },
                    ext: { width: newImageWidth, height: newImageHeight }
                });

            }



            //create file
            async function exportToExcel() {
                const buffer = await workbook.xlsx.writeBuffer();
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const fileExtension = '.xlsx';
                const blob = new Blob([buffer], { type: fileType });
                saveAs(blob, project.clientobject.clientname + " - " + project.positiontitle + " - PR" + fileExtension);


                //Confirmation toast
                iziToast.show({
                    title: 'Progress Report Downloaded !',
                    backgroundColor: '#47be7d',
                    titleColor: 'white',
                    theme: 'dark',
                    position: 'bottomRight',
                    timeout: 3000,
                    icon: 'fa fa-check'
                });



            }

            exportToExcel();


        };


    }


    addImageAndWriteFile()
















    /*
       
    
        const XLSX = require('sheetjs-style');
    
    
        //define column order
        //var headers = ["fullname", "currentcompany", "currentjobtitle", "statusid", "location", "comments", "linkedinurl", "mobilenumber", "email"]
        var headers = ["fullname"]
    
        console.log("here are the options: " + JSON.stringify(store.state.userData.proptions, null, 2));
    
        if(store.state.userData.proptions.fields.company) {
            headers.push("currentcompany")
        }
        if(store.state.userData.proptions.fields.title) {
            headers.push("currentjobtitle")
        }
        if(store.state.userData.proptions.fields.status) {
            headers.push("statusid")
        }
        if(store.state.userData.proptions.fields.location) {
            headers.push("location")
        }
        if(store.state.userData.proptions.fields.comments) {
            headers.push("comments")
        }
        if(store.state.userData.proptions.fields.linkedinurl) {
            headers.push("linkedinurl")
        }
        if(store.state.userData.proptions.fields.contactdetails) {
            headers.push("mobilenumber")
        }
        if(store.state.userData.proptions.fields.contactdetails) {
            headers.push("email")
        }
        if(store.state.userData.proptions.fields.experience) {
            headers.push("experiencelist")
        }
    
    
    
    
        //create worksheet and workbook
        var ws = XLSX.utils.json_to_sheet(filteredProfiles, { header: headers, origin: "A4" });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    
        //add custom header names
        let Heading = [['Name']];
    
    
        if(store.state.userData.proptions.fields.company) {
            Heading[0].push(['Company'])
        }
        if(store.state.userData.proptions.fields.title) {
            Heading[0].push("Title")
        }
        if(store.state.userData.proptions.fields.status) {
            Heading[0].push("Status")
        }
        if(store.state.userData.proptions.fields.location) {
            Heading[0].push("Location")
        }
        if(store.state.userData.proptions.fields.comments) {
            Heading[0].push("Comments")
        }
        if(store.state.userData.proptions.fields.linkedinurl) {
            Heading[0].push("Profile")
        }
        if(store.state.userData.proptions.fields.contactdetails) {
            Heading[0].push("Phone")
        }
        if(store.state.userData.proptions.fields.contactdetails) {
            Heading[0].push("Email")
        }
        if(store.state.userData.proptions.fields.experience) {
            Heading[0].push("Experience")
        }
        
    
    
    
        XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A4' });
    
        //set cols/rows width and height
        ws['!cols'] = [];
        ws['!rows'] = [];
    
        ws['!cols'] = [
            { 'width': 30 }, // width for col A
            { 'width': 30 }, // width for col B
        ];
    
        ws['!rows'] = [
            { 'hpt': 60 },// height for row 1
            { 'hpt': 20 },]; //height for row 2
    
    
        
    
    
    
    
    
        
    
        
    if(store.state.showingProfilesAs == "projectProfiles") {
     
    const projectId = store.state.currentProfileList[0].bindingprojectid
    const project = store.state.currentMyProjectList.find(project => project.id === projectId);
     
    XLSX.utils.sheet_add_aoa(ws, [
    [project.clientobject.clientname + " / " + project.positiontitle ]
    ], {origin: "A1"});
    
    
    
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let MM = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
     
    
    XLSX.utils.sheet_add_aoa(ws, [
    [`${dd}/${MM}/${yyyy}`]
    ], {origin: "A2"});
     
    
    const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
    ];
    
    ws["!merges"] = merge;
    
    
    const range = [
        { s: { r: 1, c: 2 }, e: { r: 4, c: 5 } },
        ];
    
    ws["E5"].s = {									// set the style for target cell
        font: {
          name: 'Calibri',
          sz: 24,
          bold: true,
          color: { rgb: "FFFFAA00" }
        },
        alignment: {
            wrapText: true, vertical: 'top', horizontal: 'left'
        }
      };
    
    //for the colors, they are in ARGB format. Need to convert it first from hex, through this site for ex.:
    //https://www.myfixguide.com/color-converter/
    
      ws["A4"].s = {									// set the style for target cell
        font: {
          name: 'Calibri',
          sz: 14,
          bold: true,
          color: { rgb: "FFFFAA00" },
        },
        fill: { patternType: "solid",
        fgColor: { rgb: "ffdadada"}
              }
      };
    
    //generate XLSX file and trigger a download 
    XLSX.writeFile(wb, "data.xlsx");
    
    
    }
    
        /*
              if(store.state.showingProfilesAs == "recentProfiles") {
                
             
          
                XLSX.utils.sheet_add_aoa(ws, [
                  ["Recent profiles"]
                ], {origin: "A1"});
        
        
                let today = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let MM = String(today.getMonth() + 1).padStart(2, '0');
                let yyyy = today.getFullYear();
         
        
                XLSX.utils.sheet_add_aoa(ws, [
                    [`${dd}/${MM}/${yyyy}`]
                  ], {origin: "A2"});
          
        
        
                //generate XLSX file and trigger a download 
                XLSX.writeFile(wb, "data.xlsx");
        
        
              }
              */













}




export {
    //applyProfileStatusExternal,
    //Batch manipulate banner
    selectProfileExternal,
    selectAllProfilesExternal,
    //batchApplyNewStatusExternal,
    closeBatchManipulateBannerExternal,
    batchAddProfileTagExternal,
    batchUpdateProfileStatusExternal,
    //batchAttachProfileToProject,
    sortProfileListExternal,
    filterProfileListExternal,
    applyMatchExternal,
    hideProfileExternal,
    exportToExcelExternal,
    batchApplyProfileIndustry
}