<template>

  <!--begin::Content Body-->




    <!--begin::Add new status Button-->
    <div
      class="d-flex mb-7"
      style="justify-content: center; margin-top: -15px;"
    >
      <button
        @click="switchToNewStatusMode()"
        type="button"
        class="btn btn-primary btn-sm"
      >
        <i class="bi bi-plus-lg"></i>
        Add new status</button>
    </div>
    <!--end::Add new status Button-->


    <!--begin:Title Row-->
    <div
      class="d-flex py-4 border-bottom border-gray-300 border-bottom-dashed"
      style="justify-content: space-between;"
    >
      <div class="d-flex">
        <div class="fw-bold"
        >
          Status
        </div>
      </div>

      <div class="d-flex">
        <div class="me-7 fw-bold">
          Category <span class="text-danger	">*</span>
        </div>
        <div class="fw-bold">
          Action
        </div>
      </div>

    </div>
    <!--end::Title Row-->





    <!--begin::status table-->
    <div
      class="mh-250px min-h-250px scroll-y me-n7 pe-7"
    >


      <!--begin::add new status-->
      <div
        class="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed animate__animated animate__faster"
        v-if="$store.state.addNewStatusMode"
      >

        <!--begin::add new status Input-->
        <div
          v-if="$store.state.addNewStatusMode"
          style="width: 230px;"
          class="ms-11"
        >
          <input
            type="text"
            class="form-control focusfakeclass"
            v-model="this.newStatusInput"
            style="height: 35px; border-radius: 0.70rem; font-size: 0.9rem"
            id="newStatusInputField"
          />
        </div>
        <!--end::add new status Input-->


        <!--begin::Category & button group -->
        <div class="d-flex">

          <!--begin::Category-->
          <div class="w-190px">



            <!--begin::Category menu: Edit mode -->
            <span
              class="btn btn-sm btn-light-primary dropdown-toggle me-3"
              type="button"
              id="statuscategoryselect"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{this.newStatusSelectedCategory}}
            </span>
            <!--end::Category menu: Edit mode -->

            <!--begin::Category menu: dropdown menu -->
            <ul
              class="dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-bold py-4 fs-6 w-150px"
              aria-labelledby="statuscategoryselect"
              role="menu"
            >

              <li
                @click="updateNewStatusCateogry('In progress')"
                class="badge badge-light ms-4 me-4 mb-3 custombadgehover"
                style="cursor: pointer;"
              >
                <a>In progress</a>
              </li>
              <li
                @click="updateNewStatusCateogry('Interested')"
                class="badge badge-light ms-4 me-4 mb-3 custombadgehover"
                style="cursor: pointer;"
              >
                <a>Interested</a>
              </li>
              <li
                @click="updateNewStatusCateogry('Discarded')"
                class="badge badge-light ms-4 me-4 mb-3 custombadgehover"
                style="cursor: pointer;"
              >
                <a>Discarded</a>
              </li>

            </ul>
            <!--begin::Category menu: dropdown menu -->

          </div>
          <!--end::Category-->

          <!--begin::right end Button -->


          <!--begin::Check/save button -->
          <div>
            <a
              @click="confirmAddNewStatus(this.newStatusInput, this.newStatusSelectedCategory)"
              class="ms-4 btn btn-icon btn-sm btn-light-success"
            >
              <i class="bi bi-check"></i>
            </a>
          </div>
          <!--end::Check/save button -->

          <!--begin::right end Button -->

        </div>
        <!--end::Category & button group -->

      </div>
      <!--end::add new Status-->


      <!--begin::Status Row-->
      <div
        :id="'statusRow' + status.id"
        class="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed animate__animated"
        :class="{
                  customProfileStatusMoveDown: $store.state.statusListContainerExitAnimation,
                  'animate__fadeIn': status.id == 'newStatusTempId' 
                }"
        v-for="status in $store.state.profileStatusList"
        :key="status.id"
        :style="{'justify-content: flex-start !important;': $store.state.profileStatusEditMode == status.id}"
      >

        <!--begin::Status Row : Edit mode : Status input-->
        <div
          v-if="$store.state.profileStatusEditMode == status.id"
          style="width: 220px;"
          class="ms-11"
        >
          <input
            type="text"
            :id="'input' + status.id"
            class="form-control focusfakeclass"
            :placeholder="status.status"
            v-model="this.statusInput"
            style="height: 35px; border-radius: 0.70rem; font-size: 0.9rem"
          />
        </div>
        <!--end::Status Row : Edit mode : Status input-->

        <!--begin::Status Row : Read mode : Order rank & Status -->
        <div
          class="d-flex"
          v-if="$store.state.profileStatusEditMode != status.id"
        >

          <!--begin::Status Row : Read mode : Order rank -->
          <div class="d-flex flex-column animate__animated"
                v-if="status.id != 'newStatusTempId' && $store.state.showStatusArrows"
                :class="{
                          animate__fadeOut: !$store.state.showStatusArrows, 
                          animate__fadeIn: $store.state.showStatusArrows,
                        }"
              
          >
            <i
              v-if="status.order > 1"
              class="customiconbtn fs-3 bi bi-arrow-up-short"
              @click="incrementProfileStatusOrder(status.id, status.order)"
            ></i>
            <i
              v-if="$store.state.profileStatusList.length != status.order  && status.order != 0"
              class="customiconbtn fs-3 bi bi-arrow-down-short"
              @click="decrementProfileStatusOrder(status.id, status.order)"
            ></i>
          </div>
          <!--end::Status Row : Read mode : Order rank -->

          <!--begin::Status Row : Read mode : Status -->
          <div class="d-flex align-items-center"
                :class="{ 'customProfileStatusMargin' : !$store.state.showStatusArrows,
                          'ms-5' : $store.state.showStatusArrows
                        }">

            <div
              class="badge me-1 mb-3 custombadgehover"
              :class="{
						'badge-light-primary': status.category == 'In progress',
					 	'badge-light-success': status.category == 'Interested',
					 	'badge-light-danger': status.category == 'Discarded'
					 }"
              style="cursor: pointer;"
            >
              <a>
                {{status.status.length < 25 ? status.status : status.status.substring(0, 25) + '...'}}
                                 <span class="ms-1" style="opacity: 0.3;" v-if="$store.state.lakesideStatusList.includes(status.id)">
           <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="11" height="11" viewBox="0 0 30 30">
    <path d="M 15 2 C 11.145666 2 8 5.1456661 8 9 L 8 11 L 6 11 C 4.895 11 4 11.895 4 13 L 4 25 C 4 26.105 4.895 27 6 27 L 24 27 C 25.105 27 26 26.105 26 25 L 26 13 C 26 11.895 25.105 11 24 11 L 22 11 L 22 9 C 22 5.2715823 19.036581 2.2685653 15.355469 2.0722656 A 1.0001 1.0001 0 0 0 15 2 z M 15 4 C 17.773666 4 20 6.2263339 20 9 L 20 11 L 10 11 L 10 9 C 10 6.2263339 12.226334 4 15 4 z"></path>
</svg>
          </span>
              </a>
            </div>
          </div>
          <!--end::Status Row : Read mode : Status -->

          <!--begin::Edit btn-->
          <div>
            <div
              class="ms-2 btn btn-sm btn-icon btn-light animate__animated"
              :class="{'animate__fadeOut' : $store.state.addNewStatusMode}"
              @click="activateProfileStatusEditMode(status.id)"
            >
              <!--begin::Svg Icon | path: assets/media/icons/duotune/art/art005.svg-->
              <span class="svg-icon svg-icon-5"><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                    fill="currentColor"
                  />
                </svg></span>

              <!--end::Svg Icon-->
            </div>
          </div>
          <!--end::Edit btn-->

        </div>
        <!--end::Status Row : Read mode : Order rank & Status -->

        <!--begin::Category & button group -->
        <div class="d-flex">

          <!--begin::Category-->
          <div class="w-190px">

            <!--begin::Category menu: Read mode -->
            <span
              v-if="$store.state.profileStatusEditMode != status.id"
              class="btn btn-sm btn-light dropdown-toggle me-3"
              type="button"
              id="statuscategoryselect"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{status.category}}
            </span>
            <!--end::Category menu: Read mode -->

            <!--begin::Category menu: Edit mode -->
            <span
              v-if="$store.state.profileStatusEditMode == status.id"
              class="btn btn-sm btn-light-primary dropdown-toggle me-3"
              type="button"
              id="statuscategoryselect"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{selectedCategory}}
            </span>
            <!--end::Category menu: Edit mode -->

            <!--begin::Category menu: dropdown menu -->
            <ul
              class="dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-bold py-4 fs-6 w-150px"
              aria-labelledby="statuscategoryselect"
              role="menu"
            >

              <li
                @click="updateStatusCategory(status.id, 'In progress')"
                class="badge badge-light ms-4 me-4 mb-3 custombadgehover"
                style="cursor: pointer;"
              >
                <a>In progress</a>
              </li>
              <li
                @click="updateStatusCategory(status.id, 'Interested')"
                class="badge badge-light ms-4 me-4 mb-3 custombadgehover"
                style="cursor: pointer;"
              >
                <a>Interested</a>
              </li>
              <li
                @click="updateStatusCategory(status.id, 'Discarded')"
                class="badge badge-light ms-4 me-4 mb-3 custombadgehover"
                style="cursor: pointer;"
              >
                <a>Discarded</a>
              </li>

            </ul>
            <!--end::Category menu: dropdown menu -->

          </div>
          <!--end::Category-->


          <!--begin::right end Button -->

          <!--begin::Delete button -->
          <div
            v-if="$store.state.profileStatusEditMode != status.id"
            @click="deleteStatus(status.id, status.order)"
          >
            <a class="ms-4 btn btn-sm btn-icon btn-light-danger">
              <i class="bi bi-trash fs-4"></i>
            </a>
          </div>
          <!--end::Delete button -->

          <!--begin::Check/save button -->
          <div v-if="$store.state.profileStatusEditMode == status.id">
            <a
              @click="confirmStatusUpdate(status.id, this.statusInput)"
              class="ms-4 btn btn-icon btn-sm btn-light-success"
            >
              <i class="bi bi-check"></i>
            </a>
          </div>
          <!--end::Check/save button -->

          <!--begin::right end Button -->

        </div>
        <!--end::Category & button group -->

      </div>
      <!--end::Status Row-->

      <div class="mt-12" v-if="$store.state.profileStatusList.length == 0">
      <CardAlert
      alerttype="info"
      title="Empty status list"
      text="Click 'Add status' above."
    ></CardAlert>
      </div>

    </div>
    <!--end::status table-->

  

  <!--end::Content Body-->

</template>

<script>
import {
  switchToNewStatusModeExternal,
  incrementProfileStatusOrderExternal,
  decrementProfileStatusOrderExternal,
  activateProfileStatusEditModeExternal,
  updateStatusCategoryExternal,
  confirmStatusUpdateExternal,
  deleteStatusExternal,
  confirmAddNewStatusExternal,
} from "../../../js/crosscomponentfeatures";

import CardAlert from "../../CardAlerts/CardAlert.vue"

import {
  onSnapshot,
  query,
  collection,
  getDocs,
  where,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import { db } from "../../../firebase/index";

export default {
  components: {
    CardAlert
  },
  data() {
    return {
      statusInput: "",
      newStatusInput: "",
      selectedCategory: "Select category",
      newStatusSelectedCategory: "Select category",
    };
  },

  methods: {
    switchToNewStatusMode() {
      switchToNewStatusModeExternal();
    },
    incrementProfileStatusOrder(statusId, order) {
      incrementProfileStatusOrderExternal(statusId, order);
    },
    decrementProfileStatusOrder(statusId, order) {
      decrementProfileStatusOrderExternal(statusId, order);
    },
    activateProfileStatusEditMode(statusId) {
      activateProfileStatusEditModeExternal(statusId);
    },
    updateStatusCategory(statusid, newcategory) {
      updateStatusCategoryExternal(statusid, newcategory);
      this.selectedCategory = newcategory;
    },
    updateNewStatusCateogry(newstatuscategory) {
      this.newStatusSelectedCategory = newstatuscategory;
    },
    confirmStatusUpdate(statusid, newstatus) {
      this.statusInput = "";
      this.selectedCategory = "Select category";
      confirmStatusUpdateExternal(statusid, newstatus);
    },
    deleteStatus(statusid, order) {
      deleteStatusExternal(statusid, order);
    },
    confirmAddNewStatus(statusname, category) {
      confirmAddNewStatusExternal(statusname, category);
      this.newStatusSelectedCategory = "Select category";
      this.newStatusInput = "";
    },
  },
};
</script>

<style>
</style>