


<template>


	<!--begin::Main-->

		<!--begin::Root-->

		<!--begin::Main app UI-->
		<div class="d-flex flex-column flex-root">

			<!--begin::Page-->
			<div class="page d-flex flex-column flex-column-fluid">



				<!--begin::Main Tab Nav bar-->
				<MainTabNav></MainTabNav>
				<!--end::Main Tab Nav bar-->


				
				<!--begin::Wrapper : the different tabs of the app are injected here-->
        		<ClientList v-if="$store.state.showingClientsAs == 'myClients'"></ClientList>
				    <ClientDetails v-if="$store.state.showingClientsAs == 'clientDetails' && $store.state.currentClientDetails"></ClientDetails>
				<!--end::Wrapper-->
				

			</div>
			<!--end::Page-->

		</div>




		<!--end::Root-->





</template>

<script>

import ClientList from '../components/ClientList.vue'
import ClientDetails from '../components/ClientDetails.vue'
import MainTabNav from '../components/MainTabNav.vue'
import Toolbar from '../components/Toolbar-Component.vue'





export default {
			  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
    components: {
        ClientList, Toolbar, ClientDetails, MainTabNav
    },
	methods: {
		closemodals: function() {
			this.$store.state.showusermenu = false;
		}
	},
    created() {
    this.$store.state.currentRoute = this.$route.path
  },
  mounted() {

      	  console.log('before init')
	  	KTMenu.createInstances();
	  	//KTDrawer.init();
		KTDrawer.createInstances();
		//KTDrawer.handleShow();
    	//KTDrawer.handleDismiss();
		KTScroll.createInstances();
		KTScrolltop.createInstances();
		KTSticky.createInstances();
		KTDialer.createInstances();
		KTImageInput.createInstances();
		KTPasswordMeter.createInstances();
		KTSwapper.createInstances();
		KTToggle.createInstances();
		KTWidgets.init()
	  console.log('after init')


  }


}
</script>
