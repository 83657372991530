<template>
  <!--begin::Card body-->
  <div class="card-body">
    <div class="row">
      <div class="col-6 me-8">
        <!--begin::User Info-->
        <div class="d-flex flex-start">
          <!--begin::Logo-->
          <div
            class="d-flex flex-column symbol symbol-75px symbol-rounded mb-7 me-6"
          >
            <img
              v-if="$store.state.currentProfileDetails.profilepic"
              :src="$store.state.currentProfileDetails.profilepic"
              alt="image"
              @click="copyDetails()"
              onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
            />
            <img
              alt="Pic"
              v-if="!$store.state.currentProfileDetails.profilepic"
              src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
            />
            <div
              class="d-flex mt-3 ms-4"
              v-if="
                $store.state.currentProfileDetails.candidateorclient == 'Client'
              "
            >
              <span class="ms-2 text-muted">Client</span>
            </div>
            <div
              class="d-flex mt-3"
              style="margin-left: -3px"
              v-if="
                $store.state.currentProfileDetails.candidateorclient ==
                'Candidate'
              "
            >
              <span class="ms-2 text-muted">Candidate</span>
            </div>
          </div>
          <!--end::Logo-->
          <!--begin::Info-->
          <div class="d-flex flex-start flex-column">
            <div class="d-flex">
              <input
                id="newfirstname"
                v-on:keyup="modifyFirstName()"
                v-on:blur="resizeFirstAndLastname()"
                type="text"
                placeholder="+Add first name"
                autocomplete="off"
                spellcheck="false"
                class="fs-3 text-gray-800 fw-bolder customdottedunderlined custominput stretchyInput"
                :style="{ width: firstnameInputWidth + 'px' }"
                v-model="$store.state.currentProfileDetails.firstname"
              />
              <input
                v-on:keyup="modifyLastName()"
                v-on:blur="resizeFirstAndLastname()"
                type="text"
                placeholder="+Add last name"
                autocomplete="off"
                spellcheck="false"
                class="fs-3 text-gray-800 fw-bolder customdottedunderlined custominput stretchyInput"
                :style="{ width: lastnameInputWidth + 'px' }"
                v-model="$store.state.currentProfileDetails.lastname"
              />
              <!--begin GDPR consent given::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen048.svg-->
              <span
                class="badge badge-sm badge-light-success ms-1"
                v-if="
                  $store.state.currentProfileDetails.gdprconsentstatus ==
                  'Consent given'
                "
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="
                    'GDPR consent given on ' +
                    new Date($store.state.currentProfileDetails.gdprconsentdate)
                  "
                  v-if="
                    $store.state.currentProfileDetails.gdprconsentstatus ==
                    'Consent given'
                  "
                >
                  <span
                    class="svg-icon svg-icon-success svg-icon-2"
                    style="margin-left: -5px"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                GDPR Consent
              </span>
              <!--end::GDPR consent given, Svg Icon-->
              <!--begin GDPR consent given::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen048.svg-->
              <span
                class="badge badge-sm badge-light-danger ms-1"
                v-if="
                  $store.state.currentProfileDetails.gdprconsentstatus ==
                  'Consent declined'
                "
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="
                    'GDPR consent declined on ' +
                    new Date($store.state.currentProfileDetails.gdprconsentdate)
                  "
                  v-if="
                    $store.state.currentProfileDetails.gdprconsentstatus ==
                    'Consent declined'
                  "
                >
                  <span
                    class="svg-icon svg-icon-danger svg-icon-2"
                    style="margin-left: -5px"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
                GDPR Declined
              </span>
              <!--end::GDPR consent given, Svg Icon-->
            </div>
            <!--$store.state.currentProfileDetails.lastname.toUpperCase()-->
            <span
              v-if="!showTitleInputSwitch"
              class="fs-6 text-gray-400 fw-bolder customdottedunderlined"
              style="margin-left: 2px"
              @click="showTitleInput()"
              >{{
                $store.state.currentProfileDetails.currentjobtitle.length < 30
                  ? $store.state.currentProfileDetails.currentjobtitle
                  : $store.state.currentProfileDetails.currentjobtitle.substring(
                      0,
                      30
                    ) + "..."
              }}</span
            >
            <input
              v-if="showTitleInputSwitch"
              id="newtitleinput"
              v-on:blur="blurTitleInput()"
              v-on:keyup="modifyTitle()"
              @paste="modifyTitle()"
              type="text"
              spellcheck="false"
              placeholder="+Add title"
              autocomplete="off"
              class="fs-6 text-gray-400 fw-bolder customdottedunderlined custominput"
              style="margin-top: -1px; margin-bottom: -1px"
              v-model="$store.state.currentProfileDetails.currentjobtitle"
            />

            <input
              v-on:keyup="modifyCompany()"
              type="text"
              placeholder="+Add company"
              spellcheck="false"
              class="fs-6 text-gray-800 fw-bolder mb-3 customdottedunderlined custominput"
              v-model="$store.state.currentProfileDetails.currentcompany"
            />

            <div>
              <ProfileTags
                class="me-3"
                style="width: 250px"
                :key="$store.state.tagComponentKey"
                @mouseover="showEditTaglist = true"
                @mouseleave="toggleEditTagListWithDelay()"
              ></ProfileTags>
              <div
                v-if="showEditTaglist"
                class="text-start me-4 text-muted fs-8 text-hover-primary custompointer animate__fadeIn animate__animated"
                style="margin-top: -13px"
                data-bs-toggle="modal"
                data-bs-target="#editProfileTags"
                @click="initializeEditTagsModalForm()"
              >
                <i class="bi bi-pencil-fill fs-8 me-2"></i>Edit taglist
              </div>
            </div>
          </div>
          <!--end::Project title-->

          <!--begin::Position-->
          <div class="mb-9">
            <!--begin::Badge-->
            <!--begin::Badge-->
          </div>
          <!--end::Position-->
        </div>
        <!--end::User Info-->
      </div>

      <div class="col-5 fw-mormal timeline-content text-muted ps-3 ms-8">
        <div class="d-flex mb-6">
          <span class=""> </span>

          <!--begin:Go to LinkedIn profile Btn-->
          <a
            v-if="$store.state.currentProfileDetails.linkedinurl"
            @click="goToLinkedinProfile()"
            class="btn btn-icon btn-light-primary btn-sm ms-2 ms-auto"
            ><i class="fab fa-linkedin-in" aria-hidden="true"></i
          ></a>
          <!--end:Go to LinkedIn profile Btn-->

          <!--begin:Add LinkedIn profile Btn-->
          <a
            v-else
            @click="showAddLiProfileInput()"
            class="btn btn-light-primary btn-sm ms-2 ms-auto"
          >
            <svg
              style="margin-left: -5px"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width="16"
                height="2"
                rx="1"
                transform="rotate(-90 11.364 20.364)"
                fill="currentColor"
              ></rect>
              <rect
                x="4.36396"
                y="11.364"
                width="16"
                height="2"
                rx="1"
                fill="currentColor"
              ></rect>
            </svg>

            <i class="ms-2 fab fa-linkedin-in" aria-hidden="true"></i>
          </a>
          <!--end:Add LinkedIn profile Btn-->
          <!--begin:Show CV Btn-->
          <a
            v-if="$store.state.currentProfileDetails.cvfile && $store.state.currentProfileDetails.cvfile.name"
            href=""
            class="btn btn-light-primary btn-sm btn-icon fw-boldest fs-7 ms-3"
            data-bs-toggle="modal"
            data-bs-target="#fileviewer"
            @mouseover="showDeleteCV = true"
            @mouseleave="toggleShowCVWithDelay()"
            @click="
              storeFileForDisplay($store.state.currentProfileDetails.cvfile)
            "
            >CV</a
          >
          <!--end:Show CV Btn-->

          <!--begin: Upload CV Button-->
          <label
            v-else
            for="uploadCVButton"
            class="btn btn-sm btn-light-primary ms-2"
          >
            <svg
              style="margin-left: -5px"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width="16"
                height="2"
                rx="1"
                transform="rotate(-90 11.364 20.364)"
                fill="currentColor"
              ></rect>
              <rect
                x="4.36396"
                y="11.364"
                width="16"
                height="2"
                rx="1"
                fill="currentColor"
              ></rect>
            </svg>
            Add CV
          </label>
          <input
            id="uploadCVButton"
            class="d-none"
            type="file"
            @change="uploadCV()"
          />
          <!--end: Upload CV Button-->

          <!--begin:Show Files Btn-->
          <div
            class="dropdown"
            style="z-index: 10000"
            v-if="$store.state.currentProfileDetails.profilefiles && $store.state.currentProfileDetails.profilefiles.length > 0"
          >
            <button
              id="showProfileFilesBtn"
              type="button"
              class="btn btn-light-primary btn-sm fs-7 ms-3 dropdown-toggle"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              {{ $store.state.currentProfileDetails.profilefiles.length }}
              File(s)
            </button>
            <div
              class="dropdown-menu dropdown-downwards p-5"
              aria-labelledby="showProfileFilesBtn"
            >
              <a
                class="dropdown-item border rounded border-0 text-primary fw-bolder custompointer my-2 py-2 mb-3"
              >
                <label for="uploadFileButton" class="custompointer ms-3">
                  <svg
                    style="margin-left: -5px"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="11.364"
                      y="20.364"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11.364 20.364)"
                      fill="currentColor"
                    ></rect>
                    <rect
                      x="4.36396"
                      y="11.364"
                      width="16"
                      height="2"
                      rx="1"
                      fill="currentColor"
                    ></rect>
                  </svg>
                  Add new file
                </label>
              </a>
              <a
                class="d-flex"
                v-for="file in $store.state.currentProfileDetails.profilefiles"
                :key="file.id"
                href="#"
              >
                <div
                  class="dropdown-item my-2 py-1 border rounded border-0"
                  @click="storeFileForDisplay(file)"
                  data-bs-toggle="modal"
                  data-bs-target="#fileviewer"
                >
                  <svg
                    v-if="file.type == 'doc'"
                    width="28px"
                    height="28px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 468.293 468.293"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #e1e6e9"
                      d="M337.335,0H95.219C84.874,0,76.488,8.386,76.488,18.732v430.829c0,10.345,8.386,18.732,18.732,18.732  H401.17c10.345,0,18.732-8.386,18.732-18.732V82.567L337.335,0z"
                    ></path>
                    <rect
                      x="48.39"
                      y="258.067"
                      style="fill: #27a2db"
                      width="371.512"
                      height="128.3"
                    ></rect>
                    <g>
                      <path
                        style="fill: #ebf0f3"
                        d="M182.722,293.744c7.567,6.85,11.342,16.377,11.342,28.583c0,12.201-3.665,21.861-11.004,28.971   c-7.339,7.115-18.571,10.67-33.687,10.67h-26.056v-78.501h26.952C164.343,283.467,175.164,286.894,182.722,293.744z    M180.702,322.66c0-17.968-10.291-26.952-30.881-26.952h-13.252v53.793h14.714c9.505,0,16.789-2.262,21.843-6.795   C178.179,338.179,180.702,331.498,180.702,322.66z"
                      ></path>
                      <path
                        style="fill: #ebf0f3"
                        d="M276.828,351.129c-7.933,7.75-17.739,11.625-29.419,11.625s-21.486-3.875-29.419-11.625   c-7.942-7.745-11.908-17.406-11.908-28.971c0-11.57,3.966-21.226,11.908-28.976c7.933-7.75,17.739-11.62,29.419-11.62   s21.486,3.87,29.419,11.62c7.942,7.75,11.908,17.406,11.908,28.976C288.736,333.723,284.77,343.383,276.828,351.129z    M267.122,301.997c-5.356-5.538-11.927-8.307-19.713-8.307c-7.787,0-14.358,2.769-19.713,8.307   c-5.346,5.543-8.024,12.26-8.024,20.161s2.678,14.618,8.024,20.156c5.356,5.543,11.927,8.312,19.713,8.312   c7.787,0,14.358-2.769,19.713-8.312c5.346-5.538,8.024-12.256,8.024-20.156S272.469,307.539,267.122,301.997z"
                      ></path>
                      <path
                        style="fill: #ebf0f3"
                        d="M341.296,349.95c4.56,0,8.49-0.763,11.79-2.298c3.29-1.535,6.736-3.989,10.336-7.357l8.527,8.76   c-8.308,9.208-18.397,13.814-30.26,13.814c-11.872,0-21.715-3.82-29.538-11.456c-7.823-7.636-11.735-17.296-11.735-28.976   s3.985-21.409,11.963-29.2c7.969-7.782,18.041-11.675,30.205-11.675s22.327,4.492,30.488,13.476l-8.417,9.208   c-3.747-3.592-7.284-6.1-10.62-7.526c-3.327-1.421-7.238-2.134-11.735-2.134c-7.933,0-14.595,2.568-19.987,7.695   c-5.392,5.127-8.088,11.68-8.088,19.654c0,7.974,2.678,14.636,8.033,19.987C327.615,347.277,333.957,349.95,341.296,349.95z"
                      ></path>
                    </g>
                    <polygon
                      style="fill: #2d93ba"
                      points="48.39,386.364 76.488,412.491 76.488,386.364 "
                    ></polygon>
                    <polygon
                      style="fill: #ebf0f3"
                      points="337.336,82.567 419.902,82.567 337.335,0 "
                    ></polygon>
                    <polygon
                      style="fill: #d5d6db"
                      points="353.221,82.567 419.902,121.255 419.902,82.567 "
                    ></polygon>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <svg
                    v-if="file.type == 'pdf'"
                    width="28px"
                    height="28px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #e2e5e7"
                      d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
                    ></path>
                    <path
                      style="fill: #b0b7bd"
                      d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
                    ></path>
                    <polygon
                      style="fill: #cad1d8"
                      points="480,224 384,128 480,128 "
                    ></polygon>
                    <path
                      style="fill: #f15642"
                      d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"
                    ></path>
                    <g>
                      <path
                        style="fill: #ffffff"
                        d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48   c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z    M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
                      ></path>
                      <path
                        style="fill: #ffffff"
                        d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296   c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
                      ></path>
                      <path
                        style="fill: #ffffff"
                        d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68   h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912   c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
                      ></path>
                    </g>
                    <path
                      style="fill: #cad1d8"
                      d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
                    ></path>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <span class="ms-2">
                    {{ file.name.length < 23 ? file.name : file.name.substring(0, 23) + '...' }}
                  </span>
                </div>
                <button @click="deleteFile(file)" class="bg-white btn btn-sm btn-icon">
                <i   class="bi bi-trash text-danger mt-1"></i>

                </button>
        
              </a>
            </div>
          </div>
          <!--end:Show Files Btn-->

          <!--begin: Upload Files Button-->
          <label
            v-else
            for="uploadFileButton"
            class="btn btn-sm btn-light-primary ms-2"
          >
            <svg
              style="margin-left: -5px"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="11.364"
                y="20.364"
                width="16"
                height="2"
                rx="1"
                transform="rotate(-90 11.364 20.364)"
                fill="currentColor"
              ></rect>
              <rect
                x="4.36396"
                y="11.364"
                width="16"
                height="2"
                rx="1"
                fill="currentColor"
              ></rect>
            </svg>
            Add file
          </label>
          <input
            id="uploadFileButton"
            class="d-none"
            type="file"
            @change="uploadFile()"
          />
          <!--end: Upload Files Button-->
        </div>
        <div
          v-if="showDeleteCV"
          class="text-center ms-15 mb-4 text-muted fs-8 text-hover-danger custompointer animate__fadeIn animate__animated"
          style="margin-top: -13px"
          @click="deleteCV()"
        >
          <i class="bi bi-trash-fill fs-8 me-2"></i>Delete CV
        </div>

        <div
          v-if="$store.state.showAddLiInput"
          class="mb-4 d-flex animate__animated animate__fadeIn"
          style="margin-top: -10px"
        >
          <input
            type="text"
            id="addLiProfileInput"
            v-model="$store.state.currentProfileDetails.linkedinurl"
            placeholder="Paste LinkedIn URL"
            class="form form-control w-180px"
          />
          <button
            class="btn btn-icon w-60px btn-light ms-2"
            @click="cancelAddLinkedLiInput()"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-26-051612/core/html/src/media/icons/duotune/arrows/arr097.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-2hx"
              ><svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="9.39844"
                  y="20.7144"
                  width="16"
                  height="2.66667"
                  rx="1.33333"
                  transform="rotate(-45 9.39844 20.7144)"
                  fill="currentColor"
                />
                <rect
                  x="11.2852"
                  y="9.40039"
                  width="16"
                  height="2.66667"
                  rx="1.33333"
                  transform="rotate(45 11.2852 9.40039)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
          <button
            class="btn btn-icon w-60px btn-primary ms-2"
            @click="addLiProfile()"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-26-051612/core/html/src/media/icons/duotune/arrows/arr085.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-2hx"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
        </div>

        <div class="ms-2">
          <i class="bi bi-telephone-fill"></i>
          <input
            class="ms-2 customdottedunderlined custominput"
            v-on:keyup="modifyPhone()"
            spellcheck="false"
            style="width: 150px"
            v-model="$store.state.currentProfileDetails.mobilenumber"
            placeholder="+Add phone number"
          />
        </div>
        <div class="ms-2 d-flex">
          <i class="bi bi-envelope-fill mt-2"></i>

          <textarea
            id="profileemailinput"
            style="overflow: hidden; resize: none; width: 250px"
            class="ms-2 customdottedunderlined custominput"
            v-on:keyup="modifyEmail()"
            spellcheck="false"
            v-model="$store.state.currentProfileDetails.email"
            placeholder="+Add email"
          />
        </div>
        <div class="ms-2 d-flex">
          <i class="bi bi-geo-alt-fill mt-2"></i>

          <textarea
            style="overflow: hidden; resize: none; width: 250px"
            class="ms-2 customdottedunderlined custominput"
            v-on:keyup="modifyLocation()"
            spellcheck="false"
            placeholder="+Add location"
            v-model="$store.state.currentProfileDetails.location"
          />
        </div>
        <div class="d-flex" style="margin-bottom: -29px">
          <span
            id="firstnameSpan"
            class="fs-3 fw-bolder bg-transparent text-transparent"
            >{{ $store.state.currentProfileDetails.firstname }}</span
          >
          <span
            id="lastnameSpan"
            class="fs-3 fw-bolder bg-transparent text-transparent"
            >{{ $store.state.currentProfileDetails.lastname }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <!--end::Card body-->
</template>

<script>
import ProfileTags from "../ProfileCardContents/ProfileTags.vue";
import { modifyFirstNameExternal } from "../../../js/profiledetails";
import { modifyLastNameExternal } from "../../../js/profiledetails";
import { modifyTitleExternal } from "../../../js/profiledetails";
import { modifyCompanyExternal } from "../../../js/profiledetails";
import { modifyPhoneExternal } from "../../../js/profiledetails";
import { modifyEmailExternal } from "../../../js/profiledetails";
import { modifyLocationExternal } from "../../../js/profiledetails";
import { uploadCvExternal } from "../../../js/profiledetails";
import { deleteCvExternal } from "../../../js/profiledetails";
import { uploadFileExternal } from "../../../js/profiledetails";
import { addLiProfileExternal } from "../../../js/profiledetails";
import { deleteFileExternal } from "../../../js/profiledetails";



export default {
  data() {
    return {
      showEditTaglist: false,
      showDeleteCV: false,
    };
  },
  mounted() {
    setTimeout(() => {
      const firstname = this.$store.state.currentProfileDetails.firstname;
      const lastname = this.$store.state.currentProfileDetails.lastname;
      const title = this.$store.state.currentProfileDetails.currentjobtitle;

      if (firstname == "" && lastname != "") {
        this.firstnameInputWidth = 150;
        this.lastnameInputWidth =
          document.getElementById("lastnameSpan").offsetWidth + 9;
      } else if (firstname != "" && lastname == "") {
        this.lastnameInputWidth = 150;
        this.firstnameInputWidth =
          document.getElementById("firstnameSpan").offsetWidth + 9;
      } else if (firstname == "" && lastname == "") {
        this.lastnameInputWidth = 150;
        this.firstnameInputWidth = 150;
      } else {
        this.firstnameInputWidth =
          document.getElementById("firstnameSpan").offsetWidth + 9;
        this.lastnameInputWidth =
          document.getElementById("lastnameSpan").offsetWidth + 9;
      }

      if (title == "") {
        this.showTitleInputSwitch = true;
      }

      const emailinput = document.getElementById("profileemailinput");
      emailinput.style.height = "1px";
      emailinput.style.height = emailinput.scrollHeight + "px";
    }, 0);
    //IMPORTANT: there used to be a 300 milsec delay here. don't remember why. but it make the page load look bad.
    //I turned it off and it seems to work fine... more testing needed.
  },
  components: {
    ProfileTags,
  },
  methods: {
    copyDetails() {
      //this is a secret feature. click on the profile picture and it will copy the main info of the profile to
      //paste it in an email.
      let textToCopy =
        this.$store.state.currentProfileDetails.firstname +
        " " +
        this.$store.state.currentProfileDetails.lastname +
        "\n" +
        this.$store.state.currentProfileDetails.currentjobtitle +
        "\n" +
        this.$store.state.currentProfileDetails.currentcompany +
        "\n\n" +
        this.$store.state.currentProfileDetails.mobilenumber +
        "\n" +
        this.$store.state.currentProfileDetails.email +
        "\n" +
        this.$store.state.currentProfileDetails.linkedinurl +
        "\n\n" +
        this.$store.state.currentProfileProjectBindings[0].notes;

      navigator.clipboard.writeText(textToCopy);
    },
    deleteCV() {
      deleteCvExternal();
    },
    toggleShowCVWithDelay() {
      setTimeout(() => {
        this.showDeleteCV = false;
      }, 1000);
    },
    storeFileForDisplay(file) {
      this.$store.state.fileToShow = false;
      this.$store.state.fileToShow = file;
    },
    uploadCV() {
      uploadCvExternal();
    },
    uploadFile() {
      uploadFileExternal();
    },
    deleteFile(file) {
      deleteFileExternal(file)
    },
    toggleEditTagListWithDelay() {
      setTimeout(() => {
        this.showEditTaglist = false;
      }, 1000);
    },
    goToLinkedinProfile() {
      window.open(
        this.$store.state.currentProfileDetails.linkedinurl,
        "_blank"
      );
    },

    //in profildedetails.js
    modifyFirstName() {
      modifyFirstNameExternal();
    },
    //in profildedetails.js
    modifyLastName() {
      modifyLastNameExternal();
    },
    //in profildedetails.js
    modifyTitle() {
      modifyTitleExternal();
    },
    showTitleInput() {
      this.showTitleInputSwitch = true;
      setTimeout(() => {
        document.getElementById("newtitleinput").focus();
      }, 400);
    },
    blurTitleInput() {
      if (this.$store.state.currentProfileDetails.currentjobtitle == "") {
        return;
      } else {
        this.showTitleInputSwitch = false;
      }
    },
    //in profildedetails.js
    modifyTitle() {
      modifyTitleExternal();
    },
    //in profildedetails.js
    modifyCompany() {
      modifyCompanyExternal();
    },
    //in profildedetails.js
    modifyPhone() {
      modifyPhoneExternal();
    },
    //in profildedetails.js
    modifyEmail() {
      modifyEmailExternal();
    },
    //in profildedetails.js
    modifyLocation() {
      modifyLocationExternal();
    },
    resizeFirstAndLastname() {
      this.firstnameInputWidth =
        document.getElementById("firstnameSpan").offsetWidth + 9;
      this.lastnameInputWidth =
        document.getElementById("lastnameSpan").offsetWidth + 9;

      const firstname = this.$store.state.currentProfileDetails.firstname;
      const lastname = this.$store.state.currentProfileDetails.lastname;

      if (firstname == "") {
        this.firstnameInputWidth = 150;
      }

      if (lastname == "") {
        this.lastnameInputWidth = 150;
      }
    },
    initializeEditTagsModalForm() {
      //reset input value
      this.$store.state.tagname = "";

      //input focus on modal show is handled from App.vue
    },
    showAddLiProfileInput() {
      this.$store.state.showAddLiInput = true;
      setTimeout(() => {
        document.getElementById("addLiProfileInput").focus();
      }, 100);
    },
    cancelAddLinkedLiInput() {
      this.$store.state.showAddLiInput = false;
    },
    addLiProfile() {
      addLiProfileExternal();
      this.$store.state.showAddLiInput = false;
    },
  },
  data() {
    return {
      firstnameInputWidth: 0,
      lastnameInputWidth: 0,
      showTitleInputSwitch: false,
      showCompanyInputSwitch: false,
      showPhoneNumberInputSwitch: false,
      showEmailInputSwitch: false,
      showLocationInputSwitch: false,
    };
  },
};
</script>

<style></style>
