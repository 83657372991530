<template>
  <!--begin::Alert-->
  <div
    v-if="$store.state.numberofdeclined > 0"
    class="alert bg-light-danger border border-danger d-flex flex-column flex-sm-row p-5 mb-10 border-dashed"
  >
    <!--begin::Icon-->
    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/general/gen050.svg-->
    <span class="svg-icon svg-icon-danger svg-icon-2hx me-3"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
          fill="currentColor"
        />
        <rect
          x="9"
          y="13.0283"
          width="7.3536"
          height="1.2256"
          rx="0.6128"
          transform="rotate(-45 9 13.0283)"
          fill="currentColor"
        />
        <rect
          x="9.86664"
          y="7.93359"
          width="7.3536"
          height="1.2256"
          rx="0.6128"
          transform="rotate(45 9.86664 7.93359)"
          fill="currentColor"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
    <!--end::Icon-->
    <!--begin::Wrapper-->
    <div class="d-flex flex-column pe-0 pe-sm-10">
      <!--begin::Title-->
      <h5 class="mb-1">
        {{ $store.state.numberofdeclined }} profiles have declined consent
      </h5>
      <!--end::Title-->

      <!--begin::Content-->
      <span
        >These profiles should be deleted to comply with GDPR regulation.</span
      >
      <!--end::Content-->
      <div class="mt-5">
        <div v-if="$store.state.numberofdeclined > 100" class="fs-8 ms-1 mb-4">
          (When clicking the button below, you will see the first 100 profiles
          who declined. Delete them first, and you will be able to display the
          next 100 by coming back here and clicking the button again.)
        </div>
        <button
          @click="displayGDPRDeclinedProfiles()"
          class="btn btn-sm btn-danger"
        >
          Display the profiles
        </button>
      </div>
    </div>

    <!--end::Wrapper-->
  </div>
  <!--end::Alert-->

  <!--begin::Alert-->
  <div
    v-else
    class="alert bg-light-success border border-success d-flex flex-column flex-sm-row p-5 mb-10 border-dashed"
  >
    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/general/gen048.svg-->
    <span class="svg-icon svg-icon-success svg-icon-2x me-3"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
          fill="currentColor"
        />
        <path
          d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
          fill="currentColor"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
    <!--begin::Wrapper-->
    <div class="d-flex flex-column pe-0 pe-sm-10">
      <!--begin::Title-->
      <h5 class="mb-1">
        At the moment no profiles have declined to store their personal data
      </h5>
      <!--end::Title-->

      <!--begin::Content-->
      <span
        >For confidentiality reasons, we only check the profiles you added
        yourself here. Your colleagues could have profiles who declined and who
        should be deleted. Please take into consideration.
      </span>
      <!--end::Content-->
    </div>

    <!--end::Wrapper-->
  </div>
  <!--end::Alert-->

  <div class="mt-10">
    <button class="btn btn-sm btn-light-primary">
      <i class="bi bi-arrow-counterclockwise text-primary me-1"></i>

      Manage old data
    </button>
    <span class="ms-4 fs-8 text-primary">(Coming soon)</span>
  </div>
  <div class="mt-2 ms-2 fs-7 text-muted">
    Profiles that have not explicitly given their consent should be deleted
    after a certain period of time, to be determined by you and your data
    policy. You will soon be able to display these profiles so that you can
    delete them.
  </div>
  <div class="mt-10">
    <button
      class="btn btn-sm btn-light-primary"
      @click="initializeAndOpenTemplateModal()"
    >
      <span class="svg-icon svg-icon-muted svg-icon-3"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.5"
            x="11.364"
            y="20.364"
            width="16"
            height="2"
            rx="1"
            transform="rotate(-90 11.364 20.364)"
            fill="currentColor"
          ></rect>
          <rect
            x="4.36396"
            y="11.364"
            width="16"
            height="2"
            rx="1"
            fill="currentColor"
          ></rect></svg
      ></span>
      Add GDPR Email template
    </button>
  </div>
  <div class="ms-2 mt-5">
    <span class="text-muted">
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/general/gen046.svg-->
      <span class="svg-icon svg-icon-muted svg-icon-2x"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="2"
            y="2"
            width="20"
            height="20"
            rx="10"
            fill="currentColor"
          />
          <path
            d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
      How to send GDPR consent requests to profiles? </span
    ><a style="text-decoration: underline" href="google.com" target="_blank"
      >Read our quick guide.</a
    >
  </div>
</template>

<script>
import { displayGDPRDeclinedProfilesExternal } from "../../../js/getdata";

export default {
  methods: {
    displayGDPRDeclinedProfiles() {
      displayGDPRDeclinedProfilesExternal();
    },
    initializeAndOpenTemplateModal() {
      
      $('#gdprmanagement').modal('hide')
      this.$store.state.currentEmailTemplate.body = "(This is a sample text, to be deleted: <br><br> To create a GDPR consent request template, follow these steps. <br>1. Write some text here to inform the profile about the process. Providing a link to your privacy policy is a good practice. <br> 2. Give the template a name in the field above. <br> 3. Click 'Insert GDPR Link' above. When the email is sent, this will be transformed into a clickable link to a page where the profile can Accept/Decline to have their data stored by your firm. Their preference will be automatically updated in their detailed profile (next to their name).<br>4. Click 'Add template' below. Now you can use this template in any email, whether it is an email to a single profile, or an email campaign to multiple profiles.)";
      this.$store.state.currentEmailTemplate.name = "GDPR Consent Request";
      this.$store.state.currentEmailTemplate.id = "";
      //don't know why but the reset only works this way
      setTimeout(() => {
        this.$store.state.currentEmailTemplate = {};
      }, 1000);

      //this switch determines which buttons should be shown in EditTemplates.vue:
      //Either just Save (no existing template loaded), or Update/Save to new (existing template loaded)
      //When the template modal is loaded, make sure only the Save button is displayed
      this.$store.state.existingTemplateLoaded = false;

      $('#editEmailTemplates').modal('show')

    }
  },
};
</script>

<style>
</style>