import { createStore } from 'vuex'

export default createStore({
  state: {


    baseUrl: "https://app.kiwanotalent.com/#",

    //in case of wrong email or passW, to show error message.
    authError: false,

    //these two properties are used when the user is on Profiledetails and clicks Back to Profilelist. 
    //profileListScrollPosition is used to cache the scroll position in profilelist.vue
    //hitBack determines whether the profilelist component is being loaded because of a Back click from Profiledetails / toolbar-component, in which case
    //the screen needs to scroll down to the clicked profile, or if profilelist.vue is beine loaded because user clicked on the Profiles tab for ex., in which 
    //there should be no scroll.
    profileListScrollPosition: 0,
    hitBack: false,

    screenWidth: 0,

    //object of app settings common to all users. Like "showing alert", a modal that shows important update or issue information for all users...
    //or which API should be used to scrape the LinkedIn profiles, in case one fails...
    //retrieved by a function in getData.js, which is called in a hook in App.vue
    globalAppSettings: {},

    showusermenu: false,
    statusMenuClose: false,
    reLaunchListenToAuthFunction: false,

    //assigned in auth.js
    currentUserId: "",

    showProjectProfiles: false,
    currentMyProjectList : [],
    currentTeamProjectList: [],
    currentProfileList : [],

    //if there are no profiles to show. This switches to true when profiles are added to currentProfileList above.
    //shows empty profile message in Profilelist.vue instead of loading animation running forever
    emptyProfileList: false,

    currentClientList : [],
    currentProfileDetails: false, //reassigned to an object with profile details, when user clicks on a profile row in ProfilesList.vue
    currentProfileActivityLogs: [],
    currentProfileProjectBindings: [],
    currentProfileProjectBinding: [],
    currentLatestProfileProjectBinding: [],
    currentProjectDetails: false,
    currentClientDetails: [],

    //this indicates how the profiles/projects/clients should be rendered: either as a list of Recent profiles, a list of the profiles in a Project, or as a single profile details
    showingProfilesAs: "recentProfiles",
    showingProjectsAs: "myProjects",
    showingClientsAs: "myClients",
    //when user clicks on a profile row to go to the detailed profile, we store the previous "showingProfilesAs" value, so that when then the user clicks on Back, they go back to the last view they had
    profilesViewInCache: "recentProfiles",



    //showProfileList: true,
    //showProfileDetails: false,
    showProjectList: true,
    showProjectDetails: false,
    showClientList: true,
    showClientDetails: false,
    profileStatusEditMode: false,
    showRecentProfiles: true,
    showProfilesFromThisProject: false,

    //This switch determines if fade in or fade out class should be applied to the warning in ProfileList.vue that alerts on the fact that this status has been deleted
    statusWarning: false,

    //This switch determines if user is presented with the list of existing statuses (false), or a form to add a new status (true)
    addNewStatusMode: false,

    //Generic utility: when true a fade out animation is added to the elements that require it. Not yet used.
    activateFadeOut: false,

    statusListContainerExitAnimation: false,
    addNewStatusContainerEnterAnimation: false,
    showStatusArrows: true,

    selectedProfiles: [], //stores checkbox-selected Profiles in ProfileList.vue
    selectAllProfilesChecked: false, //this determines whether the checkbox above the rows in Profilelist is checked or not

    //populated in BatchManipulateBanner.vue and rendered in Email.vue:
    //when the New email button is clicked from BatchManipulateBanner, we need to check if all selected profiles have an email and are candidates, not clients
    //this property will store the number of profiles who can receive an email: those who are CANDIDATES (vs CLIENT) and who HAVE AN EMAIL in their profile
    authorisedRecipients: 0,

    userData: {},
    profileStatusList: [],
    statusNameArrayList: [],
    projectStatusList: [
                          {status: "Active"},
                          {status: "Closed"},
                        ],
    clientStatusList: [
                          {status: "Client"},
                          {status: "Lead"},
                        ],
    profileTagList: [],
    profileTagNameList: [],

    //in ChromeExtension.vue: the URL of the LinkedIn profile received via iframe url param, from the Chrome extension files
    urlToPass: "",

    //In ChromeExtension when a user wants to import a profile from LinkedIn Recruiter: this is used to check if the profile already exists.
    //For Public LinkedIn profiles, we can simply use the LinkedIn URL. But in recruiter we do not know it before it has been scraped.
    recruiterProfileId: "",

    //the cleaned profile title to show in the extension
    tabTitle: "",
    
    //user input in the "profile comment" modal --> used in Modals/ModalContents/AddProfileComment.vue and ChromeExtension.vue 
    //(and probably MobileVersion later, TODO)
    profilecommenttext: "",

    //list of all the comments for the current profile
    currentProfileComments: [],

    //toggle used to check or uncheck the option to attach a profile comment to a project. 
    // --> used in Modals/ModalContents/AddProfileComment.vue and Cards/SmallCard.vue
    attachprofilecommenttoproject: false,

    //toggle used to check or uncheck the option to show the profile comment to the client (will appear in client portal)
    showCommentToClient: false,

    //this stores the current profile comment when it is clicked in ProfileDetails.vue, so that the modal that opens can display the related comment data
    currentProfileComment: "",

    //When the user opens the "Add profile comment" modal, this is assigned an object containing the project Client, Position Title and Clientlogo, and ProjectId.
    //These will then be written on the Comment document in Firestore, to be rendered in the Comment list in ProfileDetails and in the ProjectActivity modal.
    selectedProjectForCommentAttachment: "nothing assigned yet",

    //This toggle triggers the display of the "Add comment" form in Edit profile / projects modal
    addCommentActivated: false,

    //This is the user input for the Profile task text
    // --> used in Modals/Modalcontents/AddProfileTask.vue
    profiletasktext: "",

    //Profile task Due date: this lets the user pick a due date for the task. When a user clicks on a task in the task list, 
    //the property below is assigned with the corresponding due date
    //--> used in Modals/Modalcontents/AddProfileTask.vue
    currentprofiletaskduedate: "",

    //this store a user assigned to a task in the Add task modal, so before the task is created. In profiledetails.js
    currentprofiletaskassigneduser: {},

    //array of the profile tasks for the currently loaded profile
    currentProfileTasks: [],

    //determines if completed tasks should be shown or not
    //--> used in Cards/ProfileCardContents/ProfileTasks.vue
    showcompletedtasks: false,

    //determines if only current user's tasks should be shown or not
    //--> used in Cards/ProfileCardContents/ProfileTasks.vue
    showonlymytasks: false,


    //toggles display between All profile timeline items and Only comments
    showonlyprofilecomments: false,

    //receives data from Firebase in getdata.js (inside getuserdata function) on initial load
    //complete user documents from the user collection with corresponding team id
    teammembers: undefined,

    //receives data from Firebase in getdata.js (inside getuserdata function) on initial load
    teamData: {},

    //the name of the tag in the user input of Edit Profile Tags
    tagname: "",

    //this key is assigned to the ProfileTags.vue component inside ProfileDetails.vue. The purpose is that we can dynalically change
    //this key and thus force re-rendering of the component. This updates the Tags whitelist when a new tag is added. 
    //Otherwise, adding a new tag will not reactively update the taglist. Not sure why exactly.
    tagComponentKey: 1,


    //Batch attach project to profiles: this counter keeps track of how many profiles already had the clicked project attached,
    //so that the message (below) only shows once
    //is re-initialized each time a project is clicked in the batchattachproject dropdown in BatchManipulateBanner.vue
    alertCount : 0,

    //the text entered in the profile search bar on top of page (in mainheader.vue)
    searchInputText: "",

    //the search results returned by Algolia. WARNING: on pageload, this is always populated even if no search term has been entered
    searchResults: [],

    //see above, this checks if the user has actually entered a Profile search query. So that we can prevent the user to click on "Search results" in the Profilelist view selector in Toolbar
    searchOn: false,

    //in ChromeExtension.vue and in MobileVersion: either "on" or "off".
    //determines if the search component should be displayed or not. Toggle by keydown in search input
    searchState: "off",


    //used to initialize the inputs (subject and body) in the email modals (email and newemail)
    emailSubject: "",
    emailBody: "",

    //used in Email.vue when batch sending an email to multiple profiles
                //When the email is sent to only one profile in profiledetails, the inserted variables (first and last name) in emailBody
                //are already replaced in the useEmailTemplate() method in this component.
                //but when sending to multiple profiles here, we need to keep {{firstName}}/{{lastName}} like this, so 
                //that it can be replaced by each profile's details.
                //To do that, we store the original emailBody in another property on the first iteration of this loop,
                //then we refer to that new property for every other iteration, instead of emailBody. 
                //Because emailBody changes at the first iteration and we would not have access to {{firstName}}/{{lastName}} on the second iteration 
    emailBodyCache: "",

//Emails for One single profile only (Profiles tab)
    emailsin: [],
    emailsout: [],

//LinkedIn chat content
    lichatcontent: ["noclassicchatyet", "norecruiterchatyet"],

//Emails for All profiles, latest 30, (Dashboard tab)
    allemailsin: [],


//Latest 30 uncompleted profile tasks assigned to user (Dashboard tab)
alltasks: [],

//Loading state of the tasks in the Dashboard tab
tasksInDashboardLoading: true,

    //array of emails TO or FROM profile
    emailmessages: [],

    //this stores the current project when the user selects a project in profilelist, so that it doesn't collide with the currentProjectDetails in the projects tab
    //assigned in getdata.js
    currentProjectDetailsForProfileList: false,


    //currently loaded email (to display within the Email.vue modal)
    currentEmailMessage: {},

    //used in Email.vue and EmailTemplate.vue,
    currentEmailTemplate: {},

    //this switch determines which buttons should be shown in EditTemplates.vue (emails): 
    //Either just Save (no existing template loaded), or Update/Save to new (existing template loaded)
    existingTemplateLoaded: false,

    //used to force re-render templates component so that the templates array isn't messed up when loading a new template
    emailTemplatesComponentKey: 1,

    //array of the users email templates
    emailtemplates: [],

    //list of all users and teams for back office. Used in backoffice.js / Backoffice.vue (view)
    userList: [],
    teamList: [],
    backofficeUserDetails: {},



    //this is true when user has scrolled to the bottom of the page. enables to load more profiles, projects, clients
    scrolledToBottom: false,
    //set to 5 here in development. In production, should be set to 50 at least.
    numberOfProfilesLoadedInitially: 30,
    numberOfProfilesLoaded: 30,
    numberOfProfileToAdd: 30,


    ////////signup form: this is reinitialized on pageload, and when a user signs up
    
    //company
    signup_TeamName: "",
    signup_TeamLogoURL: "",
    signup_TeamCity: "",
    signup_TeamCountry: "",



    //profile
    signup_ProfileFirstName: "",
    signup_ProfileLastName: "",
    signup_ProfilePicURL: "",
    signup_ProfileEmail: "",
    signup_ProfileConfirmEmail: "",
    signup_ProfilePassword: "",
    signup_ProfileConfirmPassword: "",
    signup_ProfileMobileNumber: "",

    //billing

    signup_BillingCompanyName: "",
    signup_BillingAddress: "",
    signup_BillingCity: "",
    signup_BillingPostalCode: "",
    signup_BillingCountry: "",
    signup_BillingVATNumber: "",

    
	  //indicates if there is a problem with a required field, empyty for example
	  signup_CompanyNameFieldIssue: false,
    signup_CompanyLogoURLFieldIssue: false,
    signup_CompanyAddressFieldIssue: false,
    signup_CompanyCityFieldIssue: false,
    signup_CompanyPostalCodeFieldIssue: false,
    signup_CompanyCountryFieldIssue: false,
    signup_CompanyVATNumberFieldIssue: false,
    signup_ProfileFirstNameFieldIssue: false,
    signup_ProfileLastNameFieldIssue: false,
    signup_ProfileEmailFieldIssue: false,
    signup_ProfileConfirmEmailFieldIssue: false,
    signup_ProfilePasswordFieldIssue: false,
    signup_ProfileConfirmPasswordFieldIssue: false,
    signup_ProfileMobileNumberFieldIssue: false,
    signup_BillingCompanyNameFieldIssue: false,
    signup_BillingAddressFieldIssue: false,
    signup_BillingCityFieldIssue: false,
    signup_BillingPostalCodeFieldIssue: false,
    signup_BillingCountryFieldIssue: false,
    signup_BillingVATNumberFieldIssue: false,
    signup_ProfileEmailsDontMatch: false,
    signup_ProfilePwDontMatch: false,
    signup_ProfilePwTooShort: false,



/// test data
imageurls: [],
newclientlogosearch: "",

//////profile list filter

//saves currentProfileList before filter so we can restore it when filters are cleared
cachedProfileList: [],

//to re-render the filter card when user clicks on reset
profileFilterComponentKey: 1,

//filter applied?
  //when a filter is applied (button clicked in SmallCard.vue, this property becomes true, which displays a small blue bullet in the filter button in the toolbar)
filterAppliedToProfiles: false,

//filter options
addedByMe: false,
visibleToClient: false,
gdprFilterApplied: false,
gdprFilterValue: "",
tagFilterArray: [],
statusFilterArray: [],

//////sort profiles key (not used for the moment)
profileSortKey: "",

//array of profile ids that should be scanned for automatic profile match in profilelist / showingprofilesas = projectprofiles
profilestomatch : [],

//recommended profiles for this project (in profile list)
recommendedProfiles: [],

//recommended profiles for this project (in project details)
recommendedProfilesInProjectDetails: [],

//mainly used to check for recommended profiles that already exist in project. this variable is necessary and the user can be on project1's details in the Projects tab, while looking at profiles from a different project in the Profiles tab. so currentProfileList is not always related to the same proejct as in the projects tab
projectProfilesInProjectDetails: [],

//to display preloaded in AI search card as long as all profiles have not been counted
allRecommendedProfilesCounted: 'off',
    

//contains all profiles in a specific project. this array is used in LatestProfiles.vue within projectdetails, in order to display the last 3 modified profiles
//logic is in projectdetails.js
profilesInProjectForLatestProfilesFeature: [],

//contains all client contacts for the currentClient. this array is used in LastProfiles.vue within clientdetails, in order to display the client contacts for this client.
//logic is in clientdetails.js
clientContactsForThisClient: [],

numberOfProjectsForThisClient: {
  total: 0,
  active: 0,
  closed: 0
},

//this contains the projects for one specific client, to be rendered in the RelatedProfiles card in clientdetails.
//js-firestore logic in clientdetails.js
projectsForThisClient: false,


//the file object to be displayed in the file viewer component (Projects & Clients). False by default
fileToShow: false,

tempProfile: false,

showAddLiInput: false,

//used in the AddNewProfile component when importing a CV to parse. Also in profiledetails.js
//There is also an 'importState' variable used in the Chromeextension, but it is a local variable. Please be aware as 
//it can be misleading.
importState: "off",

//used in Chrome extension
linkedinImportState: "off",

//lakeside Status list to be copied to firms working with Lakeside
lakesideStatusList: [
  "60a2YAEDRSPcrhayQb9R",
  "6EUZRaInhvW25Vg8OhMW",
  "9CKxXTyQQAWWpWRSwWH2",
  "C1wPhBsW7zJgvGItAhPg",
  "C5m1P4glhoPFXwLRI2wS",
  "CDkAEb2mJYQpBCSu7Aew",
  "DS47cJMwTl6hJ8j7dt9N",
  "bPCCGVLSN3x51p449Wic",
  "mjAmKjil7K4o8ijzEVGB",
  "uRClkO4hw3bGiQBdFhaY",
  "vKR3biq6TwyUxZYn7u23",
  "xxx"
],

//client auth
clientauthstatus: "home",


//reset password workflow
resetemailsent: false,
usernotfound: false,


//for mobile UI: the Firestore docs of the 3 latest viewed profiles
// logic is in navigation.js
latestprofilesvieweddocs: [],


//determines if the mobile UI should slide left or right depending on the veiw (main or detailed profile)
animationname: "slideleft",

//determines which view should be active in mobile UI: main or detailed profile
showMain: true,


//ChatGPT generating outreach message Progress state
generateProgressState: 'ready',
generatedOutreachMessage: "",


//number of profiles who declined consent, to be displayed in the GDPR management modal
numberofdeclined: false,



clientcommentsnotificationsOwnProjects: [],
clientcommentsnotificationsJoinedProjects: [],


//counts the number of notifications in a computed property in Notifications.vue to be displayed in MainHeader.vue
numberofnotifications: 0,


//these are used for the profiles preview widget on the left side of the profiledetails.vue component

isSticky: false,
profileListLeftPreviewKey: 1,
profilePreviewHoverOn: false,



//dashboard data

//will be populated with the profile activity for this user over the last 12 months
userMonthlyActivity: [],
userActivityForThisMonth: 0,
userMonthlyNewProjects: [],
userProjectsForThisMonth: 0,

avgTimeToReach: {},
timeToHireComponentKey: 1,
profileActivityComponentKey: 1,
newProjectsComponentKey: 1,


//outgoing email attachment, uploaded by user
fileToSend: "",


passwordStrength: null,



aiprogressstep: "off",


fetchingAiSearchResultsProgress: "off",

//determines if the Profilelistleftpreview component should be displayed (if the screen if wide enough)
//Logic is in App.vue in a created hook
browserwidth: 0,


//confirms that the terms have been accepted and stored in firestore
termsacceptancestored: false,

//fetched in getdata.js if the user has connected their email
userCalendarEvents: false,

//current event
currentEventDetails: false,

//add event flow state
addingEventState: "off",

calendarcomponentkey: 1,

showModal: false,


  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
