<template>
  <div
    class="notice d-flex rounded p-6"
    :class="{
      'bg-light-primary': alerttype == 'info',
      'bg-light-warning': alerttype == 'warning' || alerttype == 'hint',
      'bg-light-danger': alerttype == 'danger',
      'bg-success': alerttype == 'success',
    }"
  >
    <!--begin::Icon-->

    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/metronic/releases/2022-07-14-092914/core/html/src/media/icons/duotune/general/gen044.svg-->
    <span
      class="svg-icon svg-icon-2tx me-4"
      :class="{
        'svg-icon-primary': alerttype == 'info',
        'svg-icon-warning': alerttype == 'warning' || alerttype == 'hint',
        'svg-icon-danger': alerttype == 'danger',
      }"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.3"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="10"
          fill="currentColor"
        />
        <rect
          x="11"
          y="14"
          width="7"
          height="2"
          rx="1"
          transform="rotate(-90 11 14)"
          fill="currentColor"
        />
        <rect
          x="11"
          y="17"
          width="2"
          height="2"
          rx="1"
          transform="rotate(-90 11 17)"
          fill="currentColor"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
    <!--end::Icon-->

    <!--begin::Wrapper-->
    <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
      <!--begin::Content-->
      <div class="mb-3 mb-md-0 fw-semibold">
        <h4
          class="fw-bold fs-7"
          :class="{
            'text-gray-900': alerttype == 'info',
            'text-danger': alerttype == 'danger',
          }"
        >
          {{ title }}
        </h4>
        <div class="fs-7 pe-7"
                       :class="{
            'text-gray-700': alerttype == 'info',
            'text-danger': alerttype == 'danger',
          }"
        >{{ text }}</div>
      </div>
      <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
</template>

<script>
export default {
  props: ["alerttype", "title", "text"],
};
</script>

<style></style>
