<template>

<div class="d-flex">
<div class="fs-2 fw-bolder mx-auto mb-10" style="margin-top: -30px;" v-html="$store.state.globalAppSettings.generalalerttitle">

</div>

</div>

  <div v-if="$store.state.globalAppSettings" class="mt-5 p-7 mx-10 fs-6 text-muted" v-html="$store.state.globalAppSettings.generalalertmessage"></div>


<div class="d-flex mt-8">
    <div class="mx-auto">
        <button class="btn btn-lg btn-primary" @click="alertMessageIsRead()">
            Got it!
        </button>
    </div>
</div>

</template>

<script>

import { alertMessageIsReadExternal } from "../../../js/user";

export default {

  methods: {
    alertMessageIsRead() {
        $("#generalalertmessage").modal("hide");
        alertMessageIsReadExternal()
    }
  }

}
</script>

<style>

</style>