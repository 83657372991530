<template>


  <div class="ms-20 lds-ring"><div></div><div></div><div></div><div></div></div>

</template>

<script>
export default {

}
</script>

<style>

</style>