<template>
  <div class="mt-20">
    <h4 class="mb-4 text-muted">Option 1. Chrome extension (the best way)</h4>

    <span
      >Use our Chrome Extension to quickly add a profile from a LinkedIn page.
      If you haven't already, click here to install the Extension in one click.
      Then just open the extension when on a LinkedIn profile page and follow
      the steps.</span
    >

    <div class="d-flex mt-10">
      <div class="mx-auto">
        <a class="btn btn-sm btn-light-primary"             
            href="https://chrome.google.com/webstore/detail/kiwano/lhmbimndandekipofdfpfbajhapfimhc"
            target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="me-1 bi bi-browser-chrome"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
            />
          </svg>
          Install Extension
        </a>
      </div>
    </div>
  </div>

  <!--begin::Separator-->
  <div class="separator separator-content border-dark separator-dotted my-15">
    Or
  </div>
  <!--end::Separator-->
  <!--end::Separator-->

  <div class="mt-10">
    <h4 class="mb-4 text-muted">Option 2. Upload & parse a CV</h4>

    <span
      >Click below to select a CV file. The CV will be parsed automatically and
      a new profile will be created.</span
    >

    <div class="d-flex">
      <div class="mx-auto" v-if="$store.state.importState == 'off'">
        <!--begin: Upload Project file-->
        <label
          for="inputCvToCreateProfile"
          class="btn btn-sm btn-light-primary"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/arrows/arr078.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-3"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="12.75"
                y="4.25"
                width="12"
                height="2"
                rx="1"
                transform="rotate(90 12.75 4.25)"
                fill="currentColor"
              />
              <path
                d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                fill="currentColor"
              />
              <path
                opacity="0.3"
                d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          Upload CV
        </label>
        <input
          id="inputCvToCreateProfile"
          class="d-none"
          type="file"
          @change="inputCvToCreateProfile()"
        />
        <!--end: Upload Project file-->
      </div>
      
      <div class="mx-auto" v-if="$store.state.importState == 'on'">
        <button
          type="button"
          v-if="$store.state.importState == 'on'"
          class="btn btn-sm btn-light-primary"
          :data-kt-indicator="$store.state.importState"
        >
          <span class="indicator-label"> Import profile </span>
          <span class="indicator-progress">
            Uploading...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
      <div class="mx-auto" v-if="$store.state.importState == 'done'">
        <button
          class="btn btn-sm btn-success animate__animated animate__fadeIn"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/arrows/arr085.svg-->
          <span class="svg-icon svg-icon-white svg-icon-2x"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          CV uploaded & profile created!
        </button>
      </div>
    </div>
  </div>

  <!--begin::Separator-->
  <div class="separator separator-content border-dark separator-dotted my-15">
    Or
  </div>
  <!--end::Separator-->
  <!--end::Separator-->

  <div class="mt-10">
    <h4 class="mb-4 text-muted">Option 3. Add profile manually</h4>

    <span
      >You can also use the form below to add a profile manually. For example
      when no CV or LinkedIn profile is available.</span
    >

    <!--begin: Add user form-->
    <div class="col-sm mt-10">
      <div class="col-sm d-flex flex-column">
        <div class="row">
          <div class="col-6">
            <label for="" class="form-label ms-3"
              >Lastname
              <span class="required"></span>
            </label>
            <input type="text" v-model="lastname" class="form-control mb-6" />
          </div>
          <div class="col-6">
            <label for="" class="form-label ms-3"
              >Firstname
              <span class="required"></span>
            </label>
            <input type="text" v-model="firstname" class="form-control mb-6" />
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label for="" class="form-label ms-3">Current title</label>
            <input
              type="text"
              v-model="currenttitle"
              class="form-control mb-6"
            />
          </div>
          <div class="col-6">
            <label for="" class="form-label ms-3">Current company</label>
            <input
              type="text"
              v-model="currentcompany"
              class="form-control mb-6"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label for="" class="form-label ms-3">Email</label>
            <input type="text" v-model="email" class="form-control mb-6" />
          </div>
          <div class="col-6">
            <label for="" class="form-label ms-3">Phone</label>
            <input type="text" v-model="phone" class="form-control mb-6" />
          </div>
        </div>

        <label for="" class="form-label ms-3">Location</label>
        <input type="text" v-model="profileLocation" class="form-control mb-6" />

        <label for="" class="form-label ms-3">Profile picture URL</label>
        <input type="text" v-model="profilepicurl" class="form-control mb-6" />
      </div>
    </div>
    <!--end: Add user form-->

    <div class="d-flex mt-3">
      <div class="mx-auto">
        <button
          class="btn btn-sm btn-primary"
          @click="addProfileFromManualForm()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>


import {   addProfileFromManualFormExternal,
inputCvToCreateProfileExternal
 } from "../../../js/createdata";

export default {
  data() {
    return {
      lastname: "",
      firstname: "",
      currenttitle: "",
      currentcompany: "",
      email: "",
      phone: "",
      profileLocation: "",
      profilepicurl: "",
    };
  },

  methods: {
    inputCvToCreateProfile() {
      this.$store.state.importState = "on";
      inputCvToCreateProfileExternal();
    },
    logProfile() {
      console.log(this.$store.state.currentProfileDetails);
    },
    addProfileFromManualForm() {
      addProfileFromManualFormExternal(
        this.lastname,
        this.firstname,
        this.currenttitle,
        this.currentcompany,
        this.email,
        this.phone,
        this.profileLocation,
        this.profilepicurl
      );
    },
  },
};
</script>

<style>
</style>