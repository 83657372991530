<template>


<div class="d-flex align-items-center flex-column mt-3 w-100">
    <div v-if="$store.state.aiprogressstep == 10">
            <CardAlert
      alerttype="danger"
      title="File too big"
      text="The file you uploaded was too large. Try to upload a smaller file. Typically: a 1 or 2 page job description that includes the company description, the key assignments and the profile criteria."
    ></CardAlert>
    </div>
            <div v-if="$store.state.aiprogressstep != 10" class="d-flex justify-content-between fw-bold fs-6 text-muted opacity-75 w-100 mt-auto mb-4">
                    <span v-if="$store.state.aiprogressstep == 1">Uploading file...</span>
    <span v-if="$store.state.aiprogressstep == 2">Uploading file...</span>
    <span v-if="$store.state.aiprogressstep == 3">File uploaded !</span>
    <span v-if="$store.state.aiprogressstep == 4"
      >Analysing job description...</span
    >
    <span v-if="$store.state.aiprogressstep == 5"
      >Analysing job description...</span
    >
    <span v-if="$store.state.aiprogressstep == 6"
      >Analysing job description...</span
    >
    <span v-if="$store.state.aiprogressstep == 7"
      >Searching for profiles...</span
    >
    <span v-if="$store.state.aiprogressstep == 8">Searching for profiles...</span>
     <span v-if="$store.state.aiprogressstep == 9">Almost done...</span>


            </div>

                                     <div v-if="$store.state.aiprogressstep != 10" class="h-8px mx-3 w-100 bg-gray-200 rounded">
                <div class="bg-primary smooth-progress rounded h-8px progress-bar-gradient" role="progressbar" :style="'width: ' + loadingPercentage + '%;    '" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>   


        </div>


</template>

<script>

import CardAlert from '../../../CardAlerts/CardAlert.vue';

export default {
  components: {
    CardAlert,
  },
computed: {
  loadingPercentage() {
    switch (this.$store.state.aiprogressstep) {
      case 'off':
        return 0;
      case 1:
        return 10;
      case 2:
        return 20;
      case 3:
        return 30;
      case 4:
        return 40;
      case 5:
        return 50;
      case 6:
        return 60;
      case 7:
        return 70;
      case 8:
        return 80;
      case 9:
        return 100;
      default:
        return 0; // or some default value
    }
  }
}


};
</script>

<style></style>
