<template>
  <div
    class="border border-1 border-gray-300 rounded p-6 d-flex flex-column"
    style="margin-top: -20px"
  >
    <span class="fw-bolder text-gray-400"
      >From your database
      <span
        class="svg-icon svg-icon-muted svg-icon-1 ms-1 tooltip custompointer"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="2"
            y="2"
            width="20"
            height="20"
            rx="10"
            fill="currentColor"
          ></rect>
          <path
            d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
            fill="currentColor"
          ></path></svg
        ><span class="tooltiptext">
          Upload a job description and find relevant profiles already in your
          database.
        </span></span
      >
    </span>

    <div
      class="ms-3 text-danger fs-7 mt-3"
      v-if="
        $store.state.currentProjectDetails.aisearchresults ==
          'noprofilesfound' &&
        ($store.state.aiprogressstep == 'off' ||
          $store.state.aiprogressstep == 'done')
      "
    >
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/arrows/arr015.svg-->
      <span class="svg-icon svg-icon-danger svg-icon-1hx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
            fill="currentColor"
          />
          <path
            d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
      No similar profiles
    </div>

    <button
      @click="fetchAISearchResultsForProject"
      v-if="
        $store.state.currentProjectDetails.aisearchresults &&
        Array.isArray($store.state.currentProjectDetails.aisearchresults) &&
        $store.state.currentProjectDetails.aisearchresults.length > 0
      "
      type="button"
      class="mt-5 btn btn-light-primary btn-sm"
      :data-kt-indicator="$store.state.fetchingAiSearchResultsProgress"
    >
      <span class="indicator-label">
        <span class="badge badge-sm badge-circle badge-primary me-2">{{
          $store.state.currentProjectDetails.aisearchresults.length
        }}</span>
        Similar profiles
      </span>
      <span class="indicator-progress">
        Please wait...
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </button>

    <div
      class="p-3 text-muted mt-2 fs-8 d-flex flex-column"
      v-if="
        $store.state.currentProjectDetails.uploadedjobdescname &&
        ($store.state.aiprogressstep == 'off' ||
          $store.state.aiprogressstep == 'done')
      "
    >
      <span class="mb-1">Based on:</span>
      <span>
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/files/fil003.svg-->
        <span class="svg-icon svg-icon-primary svg-icon-1hx me-2"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
              fill="currentColor"
            />
            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
          </svg>
        </span>
        <!--end::Svg Icon-->
        {{ $store.state.currentProjectDetails.uploadedjobdescname }}</span
      >
    </div>

    <!--begin: Upload Project/Client file-->
    <label for="uploadJobDesc" class="btn btn-sm btn-light mt-6">
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/arrows/arr078.svg-->
      <span class="svg-icon svg-icon-muted svg-icon-3"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="12.75"
            y="4.25"
            width="12"
            height="2"
            rx="1"
            transform="rotate(90 12.75 4.25)"
            fill="currentColor"
          />
          <path
            d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
            fill="currentColor"
          />
          <path
            opacity="0.3"
            d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
      Upload job description
    </label>
    <input
      id="uploadJobDesc"
      class="d-none"
      type="file"
      @change="launchAISearch()"
    />
    <!--end: Upload Project/Client file-->
  </div>

  <div
    class="border border-1 border-gray-300 rounded p-6 mt-5 d-flex flex-column"
  >
    <span class="fw-bolder text-gray-400 mb-6"
      >From LinkedIn
      <span
        class="svg-icon svg-icon-muted svg-icon-1 ms-1 tooltip custompointer"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="2"
            y="2"
            width="20"
            height="20"
            rx="10"
            fill="currentColor"
          ></rect>
          <path
            d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
            fill="currentColor"
          ></path></svg
        ><span class="tooltiptext">
          Additional LinkedIn profiles that could match your search criteria.
        </span></span
      >
    </span>

    <button
      data-bs-toggle="modal"
      data-bs-target="#recommendedProfiles"
      class="btn btn-sm btn-light-primary"
      v-if="
        $store.state.allRecommendedProfilesCounted == 'done' &&
        $store.state.recommendedProfilesInProjectDetails.length > 0
      "
    >
      <span class="badge badge-sm badge-circle badge-primary me-2">{{
        $store.state.recommendedProfilesInProjectDetails.length
      }}</span>
      Similar profiles
    </button>

    <button
      class="btn btn-sm btn-light-primary"
      v-if="this.$store.state.allRecommendedProfilesCounted == 'off'"
      @click="checkForRecommendedProfilesFromLinkedIn()"
    >
      Launch search
    </button>
    <CardAlert
      v-if="
        $store.state.allRecommendedProfilesCounted == 'done' &&
        $store.state.recommendedProfilesInProjectDetails.length == 0
      "
      alerttype="danger"
      title="No profiles found"
      text="Kiwano learns from your own actions. Add a few more profiles to this project and you should see new profiles recommended here."
    ></CardAlert>
    <div class="d-flex justify-content-center">
      <div
        v-if="this.$store.state.allRecommendedProfilesCounted == 'counting'"
        class="lds-ring-small pb-9"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { aiSearchExternal } from "../../../js/projectdetails";
import {
  checkForRecommendedProfilesFromLinkedInExternal,
  fetchAndShowAISearchResultsForProjectExternal,
} from "../../../js/getdata";
import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
  components: {
    CardAlert,
  },
  methods: {
    launchAISearch() {
      const userConfirmed = window.confirm(
        "This feature uses an external AI model where the data might be accessed for training purposes. If your job description contains highly sensitive data, you might want to create an anonymised version of it before uploading it here. Click OK if you want to procede, or CANCEL if you want to stop the upload."
      );

      if (userConfirmed) {
        aiSearchExternal();
      } else {
      }
    },
    checkForRecommendedProfilesFromLinkedIn() {
      checkForRecommendedProfilesFromLinkedInExternal();
    },
    fetchAISearchResultsForProject() {
      fetchAndShowAISearchResultsForProjectExternal(
        this.$store.state.currentProjectDetails.id
      );
    },
  },
};
</script>

<style></style>
