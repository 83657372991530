<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-xxl">
      <!--begin::Card-->
      <div class="card">
        <div class="p-5">
          <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
                >Users</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2"
                >Teams</a
              >
            </li>
            <!-- begin: Buttons -->
            <div class="ms-auto d-flex">
              <!--begin: Attach system account to a team (for debugging mainly)-->
              <!--begin::Button-->
              <button
                class="btn me-4 btn-sm btn-light"
                id="selectteamtoenter"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Enter a team
              </button>
              <!--end::Button-->

              <!--begin Dropdown menu-->
              <div
                class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px dropdownFadeIn"
                aria-labelledby="selectteamtoenter"
                role="menu"
              >
                <div class="p-5 pb-2">Teams :</div>
                <div
                  class="ps-5 pt-5"
                  v-for="team in $store.state.teamList"
                  :key="team.id"
                >
                  <div class="d-flex">
                    <span class="badge badge-sm badge-light">
                      {{ team.teamname }}
                    </span>
                    <div class="ms-auto me-5">
                      <button
                        class="badge badge-sm badge-light-primary border-0 me-3 bg-hover-primary text-hover-white"
                        @click="enterTeam(team)"
                      >
                        Go !
                      </button>
                    </div>
                  </div>

                  <br />
                </div>
              </div>
              <!--end Dropdown menu-->
              <!--end: Attach system account to a team (for debugging mainly)-->

              <!--begin: Add Lakeside status list to a team-->
              <!--begin::Button-->
              <button
                class="btn me-4 btn-sm btn-light"
                id="copystatuslist"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Copy status list to team
              </button>
              <!--end::Button-->

              <!--begin Dropdown menu-->
              <div
                class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px dropdownFadeIn"
                aria-labelledby="copystatuslist"
                role="menu"
              >
                <div class="p-5 pb-2">Teams :</div>
                <div
                  class="ps-5 pt-5"
                  v-for="team in $store.state.teamList"
                  :key="team.id"
                >
                  <div class="d-flex">
                    <span class="badge badge-sm badge-light">
                      {{ team.teamname }}
                    </span>
                    <div class="ms-auto me-5">
                      <button
                        class="badge badge-sm badge-light-primary border-0 me-3 bg-hover-primary text-hover-white"
                        @click="copyStatusList(team)"
                      >
                        Copy list
                      </button>
                    </div>
                  </div>

                  <br />
                </div>
              </div>
              <!--end Dropdown menu-->
              <!--end: Add Lakeside status list to a team -->

              <!--begin: Upload data for Firestore -->
              <div
                class="border border-1 border-primary border-dashed rounded p-2 d-flex me-3"
              >
                <input
                  type="file"
                  class="d-none"
                  id="inputMigrationFileToUpload"
                  @change="uploadFile"
                  ref="fileUpload"
                />
                <label
                  for="inputMigrationFileToUpload"
                  v-if="!selectedFile && !showUploadPercentage"
                >
                  <span class="btn btn-sm btn-light"
                    >Upload migration CSV file</span
                  >
                </label>
                <div v-else>
                  <span class="btn btn-sm btn-light">
                    {{ selectedFile.name }}
                    <a
                      class="ms-2 h-25px mt-1"
                      @click="selectedFile = null"
                      type="button"
                      ><i class="bi bi-trash fs-4 text-danger"></i
                    ></a>
                  </span>
                </div>
                <div
                  class="dropdown ms-3"
                  v-if="selectedFile && !showUploadPercentage"
                >
                  <button
                    class="btn btn-sm btn-transparent border border-1 border-gray-500 text-muted"
                    type="button"
                    id="clientuserprofilebtn"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="!teamForUpload">Select team</span>
                    <span v-else>Team: {{ teamNameForUpload }}</span>
                  </button>
                  <ul
                    class="dropdown-menu py-2 menu-sub menu-sub-dropdown border border-0"
                    aria-labelledby="clientuserprofilebtn"
                    role="menu"
                  >
                    <li
                      class="custompointer badge badge-sm badge-light my-2 mx-4 bg-hover-primary text-hover-light"
                      v-for="team in $store.state.teamList"
                      :key="team.id"
                      @click="
                        teamForUpload = team.id;
                        teamNameForUpload = team.teamname;
                      "
                    >
                      {{ team.teamname }}
                    </li>
                  </ul>
                </div>
                <button
                  class="btn btn-sm btn-primary ms-3"
                  v-if="selectedFile && teamForUpload"
                  @click="submitFile"
                >
                  Import to Firestore (caution!)
                </button>
                <div
                  class="btn btn-info btn-sm w-100px ms-3"
                  v-if="showUploadPercentage"
                >
                  {{ migrationUploadPercentage }} %
                </div>
              </div>
              <!--end: Upload data for Firestore -->

              <!--begin: Configure general Alert -->
              <!--begin::Button-->
              <button
                class="btn me-4 btn-sm btn-light"
                id="configureGeneralAlert"
                data-bs-toggle="modal"
                data-bs-target="#configuregeneralalert"
              >
                Configure General Alert
              </button>
              <!--end::Button-->
              <!--end: Configure general Alert -->

              <!--begin: Generate Signup Link Button-->
              <!--begin::Button-->
              <button
                class="btn me-4 btn-sm btn-light"
                id="generatesignupbtn"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Generate Signup Link
              </button>
              <!--end::Button-->

              <!--begin Dropdown menu-->
              <div
                class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px dropdownFadeIn"
                aria-labelledby="generatesignupbtn"
                role="menu"
              >
                <div class="p-5 mt-5">
                  New user / new company :
                  <div class="d-flex">
                    <div class="mt-5">
                      <button
                        class="badge badge-sm badge-light-primary border-0 me-3 bg-hover-primary text-hover-white"
                        @click="generateSignupUrl('newCompany', 'trial', 'no')"
                      >
                        Trial
                      </button>
                      <button
                        class="badge badge-sm badge-light-primary border-0 bg-hover-primary text-hover-white"
                        @click="generateSignupUrl('newCompany', 'free', 'no')"
                      >
                        Free
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="p-5 pb-2">New user / existing company :</div>
                <div
                  class="ps-5 pt-5"
                  v-for="team in $store.state.teamList"
                  :key="team.id"
                >
                  <div class="d-flex">
                    <span class="badge badge-sm badge-light">
                      {{ team.teamname }}
                    </span>
                    <div class="ms-auto me-5">
                      <button
                        class="badge badge-sm badge-light-primary border-0 me-3 bg-hover-primary text-hover-white"
                        @click="
                          generateSignupUrl('existingCompany', 'trial', team)
                        "
                      >
                        Trial
                      </button>
                      <button
                        class="badge badge-sm badge-light-primary border-0 bg-hover-primary text-hover-white"
                        @click="
                          generateSignupUrl('existingCompany', 'free', team)
                        "
                      >
                        Free
                      </button>
                    </div>
                  </div>

                  <br />
                </div>
              </div>
              <!--end Dropdown menu-->
              <!--end: Generate Signup Link Button-->
            </div>
            <!-- begin: Buttons -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!--begin: tab contents-->
  <div class="tab-content" id="myTabContent">
    <!--begin: Users tab-->
    <div
      class="mt-10 tab-pane fade show active"
      id="kt_tab_pane_1"
      role="tabpanel"
    >
      <!--begin: Users tab content-->
      <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-xxl">
          <!--begin::Card-->
          <div class="card">
            <!--begin::Card header-->
            <div class="card-header border-0 pt-6">
              <!--begin::Card title-->
              <div class="card-title">
                <!--begin::Search-->
                <div class="d-flex align-items-center position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="currentColor"
                      ></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input
                    type="text"
                    data-kt-user-table-filter="search"
                    class="form-control form-control-solid w-250px ps-14"
                    placeholder="Search user"
                  />
                </div>
                <!--end::Search-->
              </div>
              <!--begin::Card title-->
              <!--begin::Card toolbar-->
              <div class="card-toolbar">
                <!--begin::Toolbar-->
                <div
                  class="d-flex justify-content-end"
                  data-kt-user-table-toolbar="base"
                ></div>
                <!--end::Toolbar-->
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body py-4">
              <!--begin::Table-->
              <div
                id="kt_table_users_wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div class="table-responsive">
                  <table
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                    id="kt_table_users"
                  >
                    <!--begin::Table head-->
                    <thead>
                      <!--begin::Table row-->
                      <tr
                        class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"
                      >
                        <th
                          class="w-10px pe-2 sorting_disabled"
                          rowspan="1"
                          colspan="1"
                          aria-label="
															
																
															
														"
                          style="width: 29.9px"
                        >
                          <div
                            class="form-check form-check-sm form-check-custom form-check-solid me-3"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-kt-check="true"
                              data-kt-check-target="#kt_table_users .form-check-input"
                              value="1"
                            />
                          </div>
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="User: activate to sort column ascending"
                          style="width: 260.675px"
                        >
                          User
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Role: activate to sort column ascending"
                          style="width: 152.375px"
                        >
                          Role
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Last login: activate to sort column ascending"
                          style="width: 152.375px"
                        >
                          Team
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Two-step: activate to sort column ascending"
                          style="width: 152.375px"
                        >
                          Algolia Search Key (team key)
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Joined Date: activate to sort column ascending"
                          style="width: 200.65px"
                        >
                          Joined Date
                        </th>
                        <th
                          class="text-end min-w-100px sorting_disabled"
                          rowspan="1"
                          colspan="1"
                          aria-label="Actions"
                          style="width: 122.35px"
                        >
                          Details
                        </th>
                      </tr>
                      <!--end::Table row-->
                    </thead>
                    <!--end::Table head-->
                    <!--begin::Table body-->
                    <tbody class="text-gray-600 fw-semibold">
                      <tr
                        class="odd"
                        v-for="user in sortedUsers"
                        :key="user.id"
                      >
                        <!--begin::Checkbox-->
                        <td>
                          <div
                            class="form-check form-check-sm form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                            />
                          </div>
                        </td>
                        <!--end::Checkbox-->
                        <!--begin::User=-->
                        <td class="d-flex align-items-center">
                          <!--begin:: Avatar -->
                          <div
                            class="symbol symbol-circle symbol-50px overflow-hidden me-3"
                          >
                            <a
                              href="/metronic8/demo1/../demo1/apps/user-management/users/view.html"
                            >
                              <div class="symbol-label">
                                <img
                                  :src="user.avatarurl"
                                  alt="Emma Smith"
                                  class="w-100"
                                />
                              </div>
                            </a>
                          </div>
                          <!--end::Avatar-->
                          <!--begin::User details-->
                          <div class="d-flex flex-column">
                            <a class="text-gray-800 text-hover-primary mb-1"
                              >{{ user.firstname }} {{ user.lastname }}</a
                            >
                            <span>{{ user.email }}</span>
                          </div>
                          <!--begin::User details-->
                        </td>
                        <!--end::User=-->
                        <!--begin::Role=-->
                        <td>{{ user.userrole }}</td>
                        <!--end::Role=-->
                        <!--begin::Last login=-->
                        <td data-order="2022-09-29T15:46:57+02:00">
                          <div class="badge badge-light fw-bold">
                            {{ user.teamname }}
                          </div>
                        </td>
                        <!--end::Last login=-->
                        <!--begin::Two step=-->
                        <td>
                          <span
                            v-if="user.algoliasearchkey"
                            class="badge badge-light"
                          >
                            {{ user.algoliasearchkey }}
                          </span>
                          <div v-else class="d-flex">
                            <input
                              class="form-control form-control-sm"
                              type="text"
                              :id="'keyinput' + user.id"
                              value=""
                            />
                            <button
                              class="ms-2 btn btn-sm btn-light-primary"
                              @click="saveSearchKeyToProfile(user.id)"
                            >
                              Save
                            </button>
                          </div>
                        </td>
                        <!--end::Two step=-->
                        <!--begin::Joined-->
                        <td data-order="2022-10-25T22:10:00+02:00">Todo</td>
                        <!--begin::Joined-->
                        <!--begin::Action=-->
                        <td class="text-end">
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#backofficedetaileduser"
                            class="btn btn-light btn-active-light-primary btn-sm"
                            @click="initializeUserDetailsModal(user)"
                          >
                            Details
                          </button>
                        </td>
                        <!--end::Action=-->
                      </tr>
                    </tbody>
                    <!--end::Table body-->
                  </table>
                </div>
                <div class="row">
                  <div
                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                  ></div>
                  <div
                    class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="kt_table_users_paginate"
                    >
                      <ul class="pagination">
                        <li
                          class="paginate_button page-item previous disabled"
                          id="kt_table_users_previous"
                        >
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            ><i class="previous"></i
                          ></a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >1</a
                          >
                        </li>
                        <li class="paginate_button page-item">
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="2"
                            tabindex="0"
                            class="page-link"
                            >2</a
                          >
                        </li>
                        <li class="paginate_button page-item">
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="3"
                            tabindex="0"
                            class="page-link"
                            >3</a
                          >
                        </li>
                        <li
                          class="paginate_button page-item next"
                          id="kt_table_users_next"
                        >
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="4"
                            tabindex="0"
                            class="page-link"
                            ><i class="next"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Content container-->
      </div>
      <!--end: Users tab content-->
    </div>
    <!--end: Users tab-->
    <!--begin: Teams tab-->
    <div class="tab-pane fade mt-10" id="kt_tab_pane_2" role="tabpanel">
      <!--begin: Teams tab content-->
      <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-xxl">
          <!--begin::Card-->
          <div class="card">
            <!--begin::Card header-->
            <div class="card-header border-0 pt-6">
              <!--begin::Card title-->
              <div class="card-title"></div>
              <!--begin::Card title-->
              <!--begin::Card toolbar-->
              <div class="card-toolbar">
                <!--begin::Toolbar-->
                <div
                  class="d-flex justify-content-end"
                  data-kt-user-table-toolbar="base"
                ></div>
                <!--end::Toolbar-->
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body py-4">
              <!--begin::Table-->
              <div
                id="kt_table_users_wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div class="table-responsive">
                  <table
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                    id="kt_table_users"
                  >
                    <!--begin::Table head-->
                    <thead>
                      <!--begin::Table row-->
                      <tr
                        class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"
                      >
                        <th
                          class="w-10px pe-2 sorting_disabled"
                          rowspan="1"
                          colspan="1"
                          aria-label="
															
																
															
														"
                          style="width: 29.9px"
                        >
                          <div
                            class="form-check form-check-sm form-check-custom form-check-solid me-3"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              data-kt-check="true"
                              data-kt-check-target="#kt_table_users .form-check-input"
                              value="1"
                            />
                          </div>
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="User: activate to sort column ascending"
                          style="width: 260.675px"
                        >
                          Teamname
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Role: activate to sort column ascending"
                          style="width: 152.375px"
                        >
                          Team ID
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Last login: activate to sort column ascending"
                          style="width: 152.375px"
                        >
                          Algolia search API
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Two-step: activate to sort column ascending"
                          style="width: 152.375px"
                        >
                          Two-step
                        </th>
                        <th
                          class="min-w-125px sorting"
                          tabindex="0"
                          aria-controls="kt_table_users"
                          rowspan="1"
                          colspan="1"
                          aria-label="Joined Date: activate to sort column ascending"
                          style="width: 200.65px"
                        >
                          Joined Date
                        </th>
                        <th
                          class="text-end min-w-100px sorting_disabled"
                          rowspan="1"
                          colspan="1"
                          aria-label="Actions"
                          style="width: 122.35px"
                        >
                          Details
                        </th>
                      </tr>
                      <!--end::Table row-->
                    </thead>
                    <!--end::Table head-->
                    <!--begin::Table body-->
                    <tbody class="text-gray-600 fw-semibold">
                      <tr
                        class="odd"
                        v-for="team in $store.state.teamList"
                        :key="team.id"
                      >
                        <!--begin::Checkbox-->
                        <td>
                          <div
                            class="form-check form-check-sm form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                            />
                          </div>
                        </td>
                        <!--end::Checkbox-->
                        <!--begin::team details=-->
                        <td class="d-flex align-items-center">
                          <!--begin:: Avatar -->
                          <div
                            class="symbol symbol-circle symbol-50px overflow-hidden me-3"
                          >
                            <a
                              href="/metronic8/demo1/../demo1/apps/user-management/users/view.html"
                            >
                              <div class="symbol-label">
                                <img
                                  :src="team.teamlogourl"
                                  alt="Emma Smith"
                                  class="w-100"
                                />
                              </div>
                            </a>
                          </div>
                          <!--end::Avatar-->
                          <!--begin::team details-->
                          <div class="d-flex flex-column">
                            <a
                              href="/metronic8/demo1/../demo1/apps/user-management/users/view.html"
                              class="text-gray-800 text-hover-primary mb-1"
                              >{{ team.teamname }}</a
                            >
                            <span
                              >{{ team.teamcity }}, {{ team.teamcountry }}</span
                            >
                          </div>
                          <!--begin::team details-->
                        </td>
                        <!--end::team details=-->
                        <!--begin::team id=-->
                        <td>
                          <div class="d-flex">
                            <span class="badge badge-light fw-bold">
                              {{ team.id }}
                            </span>
                            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen028.svg-->
                            <span
                              @click="copyTeamId(team.id)"
                              class="ms-2 custompointer svg-icon svg-icon-muted svg-icon-2"
                              ><svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="7"
                                  y="2"
                                  width="14"
                                  height="16"
                                  rx="3"
                                  fill="currentColor"
                                />
                                <rect
                                  x="3"
                                  y="6"
                                  width="14"
                                  height="16"
                                  rx="3"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <!--end::Svg Icon-->
                          </div>
                        </td>
                        <!--end::team id=-->
                        <!--begin::Last login=-->
                        <td data-order="2022-09-29T15:46:57+02:00">
                          <div class="d-flex">
                            <div class="badge badge-light fw-bold">
                              {{ team.algoliasearchkey }}
                            </div>
                            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-10-09-043348/core/html/src/media/icons/duotune/general/gen028.svg-->
                            <span
                              @click="
                                copyAlgoliaSearchKey(team.algoliasearchkey)
                              "
                              class="ms-2 custompointer svg-icon svg-icon-muted svg-icon-2"
                              ><svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="7"
                                  y="2"
                                  width="14"
                                  height="16"
                                  rx="3"
                                  fill="currentColor"
                                />
                                <rect
                                  x="3"
                                  y="6"
                                  width="14"
                                  height="16"
                                  rx="3"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <!--end::Svg Icon-->
                          </div>
                        </td>
                        <!--end::Last login=-->
                        <!--begin::Two step=-->
                        <td></td>
                        <!--end::Two step=-->
                        <!--begin::Joined-->
                        <td data-order="2022-10-25T22:10:00+02:00">
                          25 Oct 2022, 10:10 pm
                        </td>
                        <!--begin::Joined-->
                        <!--begin::Action=-->
                        <td class="text-end">
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#backofficedetaileduser"
                            class="btn btn-light btn-active-light-primary btn-sm"
                            @click="initializeUserDetailsModal(team)"
                          >
                            Details
                          </button>
                        </td>
                        <!--end::Action=-->
                      </tr>
                    </tbody>
                    <!--end::Table body-->
                  </table>
                </div>
                <div class="row">
                  <div
                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                  ></div>
                  <div
                    class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="kt_table_users_paginate"
                    >
                      <ul class="pagination">
                        <li
                          class="paginate_button page-item previous disabled"
                          id="kt_table_users_previous"
                        >
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            ><i class="previous"></i
                          ></a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >1</a
                          >
                        </li>
                        <li class="paginate_button page-item">
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="2"
                            tabindex="0"
                            class="page-link"
                            >2</a
                          >
                        </li>
                        <li class="paginate_button page-item">
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="3"
                            tabindex="0"
                            class="page-link"
                            >3</a
                          >
                        </li>
                        <li
                          class="paginate_button page-item next"
                          id="kt_table_users_next"
                        >
                          <a
                            href="#"
                            aria-controls="kt_table_users"
                            data-dt-idx="4"
                            tabindex="0"
                            class="page-link"
                            ><i class="next"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Content container-->
      </div>
      <!--end: Teams tab content-->
    </div>
    <!--end: Teams tab-->
  </div>
  <!--end: tab contents-->

  <!--begin: Offcanvas-->
  <!--begin::Modal - Detailed user -->
  <Modal1
    whichModal="backofficedetaileduser"
    title="User details"
    buttonText="no button"
  ></Modal1>
  <!--end::Modal - Detailed user-->
  <!--begin::Modal - Detailed user -->
  <Modal1
    whichModal="generatesignuplink"
    title="Generate Signup Link"
    buttonText="no button"
  ></Modal1>
  <!--end::Modal - Detailed user-->

  <!--end: Offcanvas-->
</template>

<script>
import { getUsersExternal } from "../js/backoffice";
import { getTeamsExternal } from "../js/backoffice";
import Modal1 from "../components/Modals/Modal1.vue";
import {
  backOfficeActivityLogsExternal,
  enterTeamExternal,
  saveSearchKeyToProfileExternal,
  copyStatusListExternal,
} from "../js/backoffice";

import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import axios from "axios";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default {
  data() {
    return {
      selectedFile: null,
      teamForUpload: null,
      teamNameForUpload: null,
      migrationUploadPercentage: 0,
      showUploadPercentage: false,
    };
  },
  components: {
    Modal1,
  },
  methods: {
    copyStatusList(team) {
      copyStatusListExternal(team);
    },
    enterTeam(team) {
      enterTeamExternal(team);
    },
    uploadFile(event) {
      this.selectedFile = event.target.files[0];
    },
    async submitFile() {
      if (!this.selectedFile) {
        console.error("No file selected.");
        return;
      }

      const confirmation = window.confirm(
        "Are you sure you want to import this CSV data into the client s team?"
      );

      if (!confirmation) {
        return; // Exit the function if the user clicks "Cancel"
      }

      const storage = getStorage();
      const storageRef = ref(
        storage,
        `migrationuploads/team${this.teamForUpload}${this.selectedFile.name}`
      );

      const uploadTask = uploadBytesResumable(storageRef, this.selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.showUploadPercentage = true;

          //track progress
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          this.migrationUploadPercentage = progress;
        },
        (error) => {
          console.error("There was an error uploading the file.", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            this.teamForUpload = null;
            this.teamNameForUpload = null;
            this.selectedFile = null;
            this.showUploadPercentage = false;

            //Confirmation toast
            iziToast.show({
              title: "Data sent to Cloud Functions !",
              backgroundColor: "#47be7d",
              titleColor: "white",
              theme: "dark",
              position: "bottomRight",
              timeout: 3000,
              icon: "fa fa-check",
            });
          });
        }
      );
    },
    initializeUserDetailsModal(user) {
      //clear cache
      this.$store.state.backofficeUserDetails = {};

      //load user
      this.$store.state.backofficeUserDetails = user;

      //load recent user logs
      backOfficeActivityLogsExternal();
    },
    generateSignupUrl(neworexistingcompany, trialorfree, team) {
      const baseUrl = this.$store.state.baseUrl + "/signup?customquerystr?";
      let finalUrl = "";

      //params: 1.Join? 2. paid? 3. companyname 4. companylogourl 5. companycity 6. companycountry

      //new company
      if (neworexistingcompany == "newCompany") {
        finalUrl =
          baseUrl +
          neworexistingcompany +
          "&param" +
          trialorfree +
          "&param&param&param&param";
      }
      //existing company
      else if (neworexistingcompany == "existingCompany") {
        finalUrl =
          baseUrl +
          neworexistingcompany +
          "&param" +
          trialorfree +
          "&param" +
          team.teamname +
          "&param" +
          team.teamlogourl +
          "&param" +
          team.teamcity +
          "&param" +
          team.teamcountry +
          "&param" +
          team.id;
      }

      //copy url to clipboard
      navigator.clipboard.writeText(finalUrl);
      alert("Signup URL copied");
    },
    copyAlgoliaSearchKey(text) {
      navigator.clipboard.writeText(text);
      alert("API Key copied");
    },
    copyTeamId(text) {
      navigator.clipboard.writeText(text);
      alert("Team id copied");
    },
    saveSearchKeyToProfile(userdocid) {
      const keyvalue = document.getElementById("keyinput" + userdocid).value;
      saveSearchKeyToProfileExternal(keyvalue, userdocid);
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.path === "/backoffice") {
      // If navigating to the protected route, ask for the password
      const password = window.prompt(
        "Please enter the password for this route:"
      );

      axios
        .post(
          "https://us-central1-kiwano-3f6de.cloudfunctions.net/checkPasswordForBackoffice",
          {
            password: password,
          }
        )
        .then((response) => {
          if (response.data.accessGranted) {
            // Password correct, allow the navigation
            next();
          } else {
            // Password incorrect, redirect to home
            next("/");
          }
        })
        .catch((error) => {
          console.error("Error while checking password:", error);
          // If there was an error checking the password, redirect to home
          next("/");
        });
    } else {
      // If not navigating to the protected route, allow the navigation
      next();
    }
  },
  mounted() {
    //from in Backoffice.js
    getUsersExternal();
    getTeamsExternal();
  },
  computed: {
    sortedUsers() {
      const allUsers = this.$store.state.userList;
      function compare(a, b) {
        if (a.teamname < b.teamname) {
          return -1;
        }
        if (a.teamname > b.teamname) {
          return 1;
        }
        return 0;
      }
      const sortedUsers = allUsers.sort(compare);
      return sortedUsers;
    },
  },
};
</script>

<style></style>
