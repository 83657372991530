


  <template>

  <!--begin::Content Body-->

  <!--begin::status table-->
  <div class="h-200px scroll-y mx-3 my-5">
    <!--begin::Status Row-->
    <div
      :id="'statusRow' + status.id"
      class="d-flex flex-stack py-2 border-bottom border-gray-300 border-bottom-dashed animate__animated"
      v-for="status in $store.state.profileStatusList"
      :key="status.id"
    >
      <!--begin::Status Row : Read mode : Order rank & Status -->
      <div class="d-flex">
        <!--begin::Status Row : Read mode : Status -->
        <div class="d-flex align-items-center">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input widget-9-check"
              type="checkbox"
              @click.stop="selectStatusToTrack(status)"
              v-model="status.tracked"
            />
          </div>
          <div
            class="badge me-1 ms-3"
            :class="{
              'badge-light-primary': status.category == 'In progress',
              'badge-light-success': status.category == 'Interested',
              'badge-light-danger': status.category == 'Discarded',
            }"
          >
            <a>
              {{
                status.status.length < 25
                  ? status.status
                  : status.status.substring(0, 25) + "..."
              }}
            </a>
          </div>
        </div>
        <!--end::Status Row : Read mode : Status -->
      </div>
      <!--end::Status Row : Read mode : Order rank & Status -->
    </div>
    <!--end::Status Row-->
  </div>
  <!--end::status table-->



  <!--end::Content Body-->
</template>


<script>
import { selectStatusToTrackExternal } from "../../js/dashboard.js";

export default {
  methods: {
    selectStatusToTrack(status) {
      selectStatusToTrackExternal(status);
    },
  },
};
</script>

<style>
</style>