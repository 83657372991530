<template>
  <div
    class="wrapper d-flex flex-column flex-row-fluid container-xxl"
    id="kt_wrapper"
  >
    <!--begin::Toolbar-->
    <Toolbar title="Clients"></Toolbar>
    <!--end::Toolbar-->
    <!--begin::Main-->
    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div class="content flex-row-fluid" id="kt_content">
        <!--begin::Card-->

        <!--begin::Tables Widget 9-->
        <div class="card mb-5 mb-xl-8">
          <!--begin::Header-->

          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-3 boxFadeIn3">
            <!--begin::Table container-->
            <div class="table-responsive">
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-hover table-row-gray-300 align-middle gs-3 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="fw-bolder text-muted" style="height: 65px">
                    <th style="width: 25%">Client</th>
                    <th style="width: 13%">Projects</th>
                    <th style="width: 13%">Added</th>
                    <th style="width: 13%">Status</th>
                    <th style="width: 14%">Owner</th>
                  </tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr
                    style="cursor: pointer"
                    v-for="client in computedClientList"
                    :key="client.id"
                    class="pointer"
                    :id="'clientRow' + client.id"
                    @click="goToClientDetails(client.id)"
                  >
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-45px me-5 companylogo">
                          <img
                            v-if="client.clientlogourl"
                            :src="client.clientlogourl"
                            class=""
                            alt=""
                          />
                          <img
                            v-else
                            src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                            class=""
                            alt=""
                          />
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <span
                            class="text-dark fw-bolder text-hover-primary fs-6"
                            >{{ client.clientname }}</span
                          >
                          <span
                            class="text-muted fw-bold text-muted d-block fs-7"
                            >Paris</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        href="#"
                        class="text-muted fw-bolder d-block fs-6 me-6"
                        ><span class="text-dark">{{
                          client.numberofprojects
                        }}</span
                        ><span v-if="client.numberofprojects == 1">
                          Project</span
                        ><span v-else> Projects</span></span
                      >
                    </td>
                    <td>
                      <span
                        class="text-muted fw-bold text-muted d-block fs-7 me-6"
                        >{{
                          new Date(client.createdat).getDate() +
                          "/" +
                          (new Date(client.createdat).getMonth() + 1) +
                          "/" +
                          new Date(client.createdat).getFullYear()
                        }}</span
                      >
                    </td>
                    <td class="text-start">
                      <StatusDropdown
                        origin="clientList"
                        :elementObject="client"
                        :currentClientStatusName="client.clientstatus"
                      ></StatusDropdown>
                    </td>
                    <td>
                      <div class="d-flex justify-content-start flex-shrink-0">
                        <div class="d-flex">
                          <div
                            v-if="computedClientOwner(client).userprofilepic && $store.state.teammembers"
                            class="symbol symbol-30px symbol-circle me-5"
                          >
                            <img
                              :src="computedClientOwner(client).userprofilepic"
                              class=""
                              alt=""
                            />
                            <span
                              v-if="
                                computedClientOwner(client).usertype == 'Owner'
                              "
                              class="symbol-badge badge badge-circle bg-transparent start-100"
                            >
                              <img
                                src="../assets/media/icons/crown.png"
                                alt=""
                              />
                            </span>
                          </div>

                          <div
                            v-if="!computedClientOwner(client).userprofilepic && $store.state.teammembers"
                            class="symbol border border-primary symbol-circle symbol-30px me-5"
                          >
                            <div
                              class="symbol-label fs-5 fw-semibold text-primary"
                            >
                              {{
  (computedClientOwner(client).firstname || '').substring(0, 1) +
  (computedClientOwner(client).lastname || '').substring(0, 1)
                              }}
                            </div>
                            <span
                              v-if="
                                computedClientOwner(client).usertype == 'Owner'
                              "
                              class="symbol-badge badge badge-circle bg-transparent start-100"
                            >
                              <img
                                src="../assets/media/icons/crown.png"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>

              <!--end::Table-->
            </div>
            <!--end::Table container-->
          </div>
          <!--begin::Body-->
        </div>
        <!--end::Tables Widget 9-->

        <!--end::Card-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Main-->
    <!--begin::Footer-->
    <div
      class="footer py-4 d-flex flex-column flex-md-row align-items-center justify-content-between"
      id="kt_footer"
    >
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="text-white opacity-75 fw-bold me-1">2023©</span>
        <a
          href="https://kiwanotalent.com"
          target="_blank"
          class="text-white text-hover-primary opacity-75"
          >Kiwano</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-white menu-hover-primary fw-bold order-1 opacity-75">
        <li class="menu-item">
          <a
            href="https://kiwanotalent.com"
            target="_blank"
            class="menu-link px-2"
            >About</a
          >
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
import Toolbar from "../components/Toolbar-Component.vue";
import StatusDropdown from "../components/Dropdowns/StatusDropdown.vue";
import { goToDetailedClientExternal } from "../js/navigation";

export default {
  components: {
    Toolbar,
    StatusDropdown,
  },
  computed: {
    computedClientList() {
      const filteredAndSortedClientList = this.$store.state.currentClientList
        .filter(
          (item) =>
            item.owninguser === this.$store.state.userData.userid ||
            item.teamaccess === "Public"
        )
        .sort((a, b) => b.createdat - a.createdat);
      return filteredAndSortedClientList;
    },
  },
  methods: {
    goToClientDetails(clientid) {
      this.$store.state.showingClientsAs = "clientDetails";

      goToDetailedClientExternal(clientid);
    },
computedClientOwner(client) {
  console.log("Checking for owning user:", client.owninguser);
console.log("Available team members:", this.$store.state.teammembers);
  let owner = this.$store.state.teammembers.find(
    (member) => member.userid === client.owninguser
  );

  if (!owner) {
    console.error("Owner not found");
    return {};
  }

  console.log("owner user id is: " + owner.userid);

   

  owner = {
    userid: owner.userid,
    usertype: "Owner",
    userprofilepic: owner.avatarurl,
    firstname: owner.firstname,
    lastname: owner.lastname,
  };

  return owner;
},
  },
};
</script>

<style>
</style>