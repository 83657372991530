<template>
  <div class="row d-flex">
    <div class="col-12">
      <!--begin: Select project-->
      <div class="d-flex mb-4">
        <div
          class="dropdown"
          :class="{ 'mx-auto': !selectedClient, 'ms-5': selectedClient }"
        >
          <!--begin: Select project Button-->
          <button
            class="btn btn-sm dropdown d-flex border border-dashed border-primary"
            type="button"
            id="selectProjectToAttachProfileButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="text-muted fs-6" v-if="!selectedClient"
              >Select Client</span
            >

            <div
              v-if="selectedClient"
              class="d-flex align-items-center pointer latestprofilesitem"
            >
              <!--begin::Symbol-->
              <div
                v-if="selectedClient.clientlogourl"
                class="symbol symbol-40px me-4 companylogo"
              >
                <img :src="selectedClient.clientlogourl" alt="" />
              </div>
              <div v-else class="symbol symbol-40px me-4 companylogo">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                  alt=""
                />
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div
                class="d-flex flex-column align-items-start"
                href="javascript:void(0)"
              >
                <span class="fs-6 text-dark fw-bold">{{
                  selectedClient.clientname
                }}</span>
              </div>
              <!--end::Title-->
            </div>

            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-30-131017/core/html/src/media/icons/duotune/arrows/arr072.svg-->
            <span
              class="ms-2 svg-icon svg-icon-muted svg-icon-2"
              style="margin-right: -7px"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </button>
          <!--end: Select project Button-->
          <!--begin: Select project Dropdown-->
          <ul
            class="dropdown-menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 scroll h-400px me-2"
            aria-labelledby="selectProjectToAttachProfileButton2"
            role="menu"
          >
            <div class="scroll-y h-220px px-3 py-5">
              <!--begin::Items-->
              <!--begin::Item-->
              <li class="my-4 ms-2">
                <button
                  class="btn btn-sm btn-light-primary"
                  @click="goToNewClient()"
                >
                  <span class="svg-icon svg-icon-muted svg-icon-2"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      ></rect></svg
                  ></span>
                  New client
                </button>
              </li>
              <li
                v-for="client in $store.state.currentClientList"
                :key="client.id"
                class="d-flex align-items-center bg-hover-light border border-0 rounded p-3 pointer latestprofilesitem"
                @click="selectClient(client)"
              >
                <!--begin::Symbol-->
                <div
                  v-if="client.clientlogourl"
                  class="symbol symbol-40px me-4 companylogo"
                >
                  <img :src="client.clientlogourl" alt="" />
                </div>
                <div v-else class="symbol symbol-40px me-4 companylogo">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
                    alt=""
                  />
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <a class="d-flex flex-column" href="javascript:void(0)">
                  <span class="fs-6 text-gray-800 fw-bold">{{
                    client.clientname
                  }}</span>
                </a>
                <!--end::Title-->
              </li>
              <!--end::Item-->
              <!--end:items-->
            </div>
          </ul>
          <!--end: Select project Dropdown-->
        </div>
      </div>
      <!--end: Select project-->
    </div>
  </div>

  <div
    class="row mt-6 animate__animated animate__fadeIn ms-2"
    v-if="selectedClient"
  >
    <div class="col-8">
      <label for="" class="form-label ms-3">Position title</label>
      <input
        type="text"
        v-model="positiontitle"
        id="positiontitleinput"
        class="form-control mb-6"
        autocomplete="off"
      />
    </div>
  </div>

  <div
    class="row mt-6 animate__animated animate__fadeIn ms-2"
    v-if="selectedClient"
  >
    <div class="col-4">
      <label for="" class="form-label ms-3">Location</label>
      <input
        type="text"
        v-model="location"
        id="clientlocationinput"
        class="form-control mb-6"
        autocomplete="off"
      />
    </div>
    <div class="col-3 d-none">
      <label for="" class="form-label ms-3">Status</label>

      <select
        class="form-select custompointer"
        v-model="status"
        aria-label="Select example"
      >
        <option>Select</option>
        <option value="Active">Active</option>
        <option value="Closed">Closed</option>
      </select>
    </div>
        <div class="col-5">
          <label for="" class="form-label ms-3">Who can see this project ?</label>
          <select class="form-select custompointer text-muted" v-model="teamaccess" aria-label="Select example">
            <option value="Public">
              &#128275; Me and my team</option>
            <option value="Private">&#x1F512; Only me</option>
          </select>
        </div>
  </div>
  <div
    class="row animate__animated animate__fadeIn ms-5"
    style="margin-top: -10px"
    v-if="teamaccess != 'Select'"
  >
    <div class="col-7"></div>
        <div class="col-4 ms-3">
        <span class="fs-7 text-muted" v-if="teamaccess == 'Private'">
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/general/gen044.svg-->
<span class="svg-icon svg-icon-muted svg-icon-sm"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/>
<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
          Selecting this option can make collaboration difficult. It is intended for highly sensitive data that should not be seen by anyone but you.</span>
        </div>
  </div>

  <div class="row mt-15" v-if="selectedClient">
    <div class="d-flex">
      <div class="mx-auto d-flex">
        <button class="btn btn-sm btn-light-danger" @click="resetForm()">
          Reset
        </button>
        <button class="ms-3 btn btn-sm btn-primary" @click="saveProject()">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { addProjectExternal } from "../../../js/createdata";

export default {
  data() {
    return {
      selectedClient: "",
      positiontitle: "",
      location: "",
      status: "Active",
      teamaccess: "Public",
    };
  },

  computed: {
    computedMyProjectsList() {
      //filter out duplicate projects (for some reason the projects sometimes appear twice or multiple times)
      const uniqueProjects = this.$store.state.currentMyProjectList.filter(
        (project, index, self) => {
          // Check for the first occurrence of the project with the same id
          const firstIndex = self.findIndex((p) => p.id === project.id);

          // Return true if the current index is the same as the first occurrence index
          return index === firstIndex;
        }
      );

      const sortedByTimestamp = uniqueProjects.sort((a, b) => {
        if (a.startedat > b.startedat) {
          return -1;
        } else if (a.startedat < b.startedat) {
          return 1;
        } else {
          return 0;
        }
      });

      const sortByTimestampAndStatus = sortedByTimestamp.sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        } else if (a.status < b.status) {
          return -1;
        } else {
          return 0;
        }
      });

      return sortByTimestampAndStatus;
    },
  },
  methods: {
    selectClient(client) {
      this.selectedClient = client;
      setTimeout(() => {
        document.getElementById("positiontitleinput").focus();
      }, 200);
    },
    goToNewClient() {
      $("#addnewproject").modal("hide");
      $("#addnewclient").modal("show");
    },
    resetForm() {
      this.selectedClient = "";
      this.positiontitle = "";
      this.location = "";
      this.status = "Select";
      this.teamaccess = "Public";
    },
    saveProject() {
      let client = this.selectedClient;
      let positiontitle = this.positiontitle;
      let location = this.location;
      let status = this.status;
      let teamaccess = this.teamaccess;

      //Add to Firestore
      addProjectExternal(client, positiontitle, location, status, teamaccess);

      //Close modal
      $("#addnewproject").modal("hide");

      //Reset form for next time
      this.selectedClient = "";
      this.positiontitle = "";
      this.location = "";
      this.status = "Select";
      this.teamaccess = "Public";
    },
  },
};
</script>

<style>
</style>