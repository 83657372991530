<template>


  <!--begin::List Widget 2-->
  <div class="card card-xl-stretch mb-xl-8">
    <!--begin::Body-->
    <div class="card-body pt-2">
      <!--begin::Item-->
      <TransitionGroup name="list" tag="div">
        <div v-if="($store.state.recommendedProfiles.length > 0 && $store.state.currentRoute == '/profiles') || ($store.state.recommendedProfilesInProjectDetails.length > 0 && $store.state.currentRoute == '/projects')">
        <div
          v-for="profile in recommendedProfiles"
          :key="profile.occurencekey"
          class="d-flex align-items-center mb-7"
        >
          <!--begin::Avatar-->
          <div class="symbol symbol-50px me-5">
            <img v-if="profile.image_url" :src="profile.image_url" class="" alt=""
                onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"

             />
                                      <img
                            alt="Pic"
                            v-if="!profile.image_url"
                            src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                          />
          </div>
          <!--end::Avatar-->
          <!--begin::Text-->
          <div class="w-200px">
            <a href="#" class="text-dark fw-bold text-hover-primary fs-6"
              >{{ profile.first_name }} {{ profile.last_name }}</a
            >
            <span class="text-muted d-block fw-bold">{{ profile.sub_title }}</span>
          </div>
          <!--end::Text-->
          <div class="d-flex ms-auto">
            <a
              :href="'https://www.linkedin.com/in/' + profile.profile_id"
              target="_blank"
              class="me-3 btn btn-icon btn-light-primary btn-sm ms-2"
              ><i class="fab fa-linkedin-in" aria-hidden="true"></i
            ></a>
            <a
              class="btn btn-icon btn-sm btn-light-danger"
              @click="hideProfile(profile)"
              ><i class="bi bi-trash"></i
            ></a>
          </div>
        </div>
        </div>
        <div v-if="($store.state.recommendedProfiles.length == 0 && $store.state.currentRoute == '/profiles') || ($store.state.recommendedProfilesInProjectDetails.length == 0 && $store.state.currentRoute == '/projects')">
            No suggested profiles.
        </div>

      </TransitionGroup>
      <!--end::Item-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::List Widget 2-->
</template>

<script>
import { hideProfileExternal } from "../../../js/profilelist";

export default {
  methods: {
    hideProfile(profileobj) {
      hideProfileExternal(profileobj);
    },
  },
  computed: {
    recommendedProfiles() {
      if(this.$store.state.currentRoute == '/profiles') {
        return this.$store.state.recommendedProfiles;
      } else if (this.$store.state.currentRoute == '/projects') {
        return this.$store.state.recommendedProfilesInProjectDetails;
      }
    }
  }
};
</script>

<style>
</style>