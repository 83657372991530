<template>
<div v-if="$store.state.currentProjectDetails.teamaccess == 'Public'">
  <div v-if="computedOwningUser">
    <div class="d-flex align-items-center">
      <div v-if="computedOwningUser.avatarurl" class="ms-2 symbol symbol-circle symbol-40px">
        <img :src="computedOwningUser.avatarurl" alt="img" />
      </div>
      <div v-else class="ms-2 symbol symbol-circle symbol-40px">
        <div
                              class="symbol-label fs-4 fw-semibold text-primary"
                            >
                              {{
                                computedOwningUser.firstname.substring(0, 1) +
                                computedOwningUser.lastname.substring(0, 1)
                              }}
                            </div>
      </div>

      <span class="ms-3 text-dark fw-bolder fs-6">{{
        computedOwningUser.firstname
      }}</span>
      <span class="ms-auto ms-3 mt-1 badge badge-sm badge-primary">Owner</span>
    </div>
  </div>

  <div v-if="$store.state.currentProjectDetails.otherusers">
    <div
      class="d-flex align-items-center animate__animated animate__fadeIn mt-5"
      v-for="userid in $store.state.currentProjectDetails.otherusers"
      :key="userid"
    >
      <div v-if="computedOtherUser(userid).avatarurl" class="ms-2 symbol symbol-circle symbol-40px">
        <img :src="computedOtherUser(userid).avatarurl" alt="img" />
      </div>
            <div v-else class="ms-2 symbol symbol-circle symbol-40px">
        <div
                              class="symbol-label fs-4 fw-semibold text-primary"
                            >
                              {{
                                computedOtherUser(userid).firstname.substring(0, 1) +
                                computedOtherUser(userid).lastname.substring(0, 1)
                              }}
                            </div>
      </div>

      <span class="ms-3 text-dark fw-bolder fs-6">{{
        computedOtherUser(userid).firstname
      }}</span>
      <span class="ms-auto ms-3 mt-1 badge badge-sm badge-light-primary"
        >Contributor</span
      >
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr088.svg-->
      <span
        v-if="
          $store.state.currentProjectDetails.owninguser ==
          $store.state.userData.userid
        "
        @click="deleteUserFromProject(userid)"
        class="custompointer svg-icon svg-icon-danger svg-icon-2hx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.5"
            x="7.05025"
            y="15.5356"
            width="12"
            height="2"
            rx="1"
            transform="rotate(-45 7.05025 15.5356)"
            fill="currentColor"
          />
          <rect
            x="8.46447"
            y="7.05029"
            width="12"
            height="2"
            rx="1"
            transform="rotate(45 8.46447 7.05029)"
            fill="currentColor"
          /></svg
      ></span>
      <!--end::Svg Icon-->
    </div>
  </div>

  <div
    class="text-muted fs-7 mt-7 ms-2 border border-primary border-dotted p-3 rounded"
  >
    <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/general/gen045.svg-->
    <span class="svg-icon svg-icon-primary svg-icon-2"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.3"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="10"
          fill="currentColor"
        />
        <rect
          x="11"
          y="17"
          width="7"
          height="2"
          rx="1"
          transform="rotate(-90 11 17)"
          fill="currentColor"
        />
        <rect
          x="11"
          y="9"
          width="2"
          height="2"
          rx="1"
          transform="rotate(-90 11 9)"
          fill="currentColor"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
    When users join/are added to a project, they will see the project in "My
    projects". Also, they will get notified when a client comments on a profile
    in the client portal.
  </div>
</div>
<div v-else>
      <CardAlert
      alerttype="info"
      title="Private project"
      text="To share this project with other users, you need to set it to 'Public' first. You can do so in the Info card above."
    ></CardAlert>
</div>

</template>

<script>
import { deleteUserFromProjectExternal } from "../../../js/projectdetails.js";
import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
  components: {
    CardAlert
  },
  methods: {
    computedOtherUser(userid) {
      let thisUser = this.$store.state.teammembers.find(
        (member) => member.userid === userid
      );

      return thisUser;
    },
    deleteUserFromProject(userid) {
      //"add user" function is in SmallCard.vue as it is in the header, not in the card content here
      deleteUserFromProjectExternal(
        userid,
        this.$store.state.currentProjectDetails.id
      );
    },
  },
  computed: {
    computedOwningUser() {
      let owningUser = this.$store.state.teammembers.find(
        (member) =>
          member.userid === this.$store.state.currentProjectDetails.owninguser
      );

      return owningUser;
    },
  },
};
</script>

<style>
</style>