import {
  collection,
  addDoc,
  getDocs,
  setDoc,
  query,
  where,
  connectFirestoreEmulator,
  collectionGroup,
  getCountFromServer,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  limit,
  orderBy,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../firebase/index";
import store from "../store/index";

import {
  getSingleProfileExternal,
  getProfilesForThisProjectExternal,
} from "../js/getdata";

import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import axios from "axios";

//Helper function
//When a profile is linked to a client or lakeside profile, update it when general profile info changes
//This function is called from different functions below
const updateLinkedProfiles = (profileId) => {
  const functions = getFunctions();
  const updateProfiles = httpsCallable(functions, "updateDuplicateProfiles");
  updateProfiles({ profileId })
    .then((result) => {
    })
    .catch((error) => {
      console.log("Error:", error);
    });
};

//this function will be called every time the user updates a field in
//the profile document that can potentially be exported in a progress report.
//it is important to update currentProfileDetails, otherwise
//the progress report will be exported without the updated data,
//as there is no realtime link between profiledetails and profilelist.
const updateProfileInVuexProfileList = (fieldtoupdate) => {
  const index = store.state.currentProfileList.findIndex(
    (profile) => profile.id === store.state.currentProfileDetails.id
  );

  if (index !== -1) {
    store.state.currentProfileList[index][fieldtoupdate] =
      store.state.currentProfileDetails[fieldtoupdate];
  }
};

//Helper function to update the duplicate profile when Client/Lakeside collaborate on a project

//called from Components/Cards/SmallCard.vue in the Main button section
const attachProfileToProjectExternal = (
  profileid,
  project,
  routepath,
  selectedstatus,
  teamaccess,
  notestoadd,
  relatedprofiles
) => {

  //we'll need these later
  //Generate profile cross id
  let profileCrossAccountId;
  function generateRandomProfileCrossID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomID = "profilecrossid";

    for (let i = 0; i < 15; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      randomID += chars[randomIndex];
    }
    return randomID;
  }
  profileCrossAccountId = generateRandomProfileCrossID();

  //Generate binding cross id
  let bindingCrossAccountId;
  function generateRandomBindingCrossID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let bindingRandomID = "bindingcrossid";

    for (let i = 0; i < 15; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      bindingRandomID += chars[randomIndex];
    }
    return bindingRandomID;
  }
  bindingCrossAccountId = generateRandomBindingCrossID();

  //first we need to get the profile bindings.
  //If the click came from profiledetails, then the bindings are already stored in currentProfileProjectBindings in Vuex (getData.js, getSingleProfile function)
  //But if the click came from profilelist, then there is nothing stored in this variable. So we need to first store the bindings in this same variable
  const qProfileProjectBindings = query(
    collection(db, "profiles/" + profileid + "/profileprojectbindings")
  );
  getDocs(qProfileProjectBindings).then((bindingsSnapshot) => {
    //create temporary & local binding array to build the bindings array (not to pollute the existing array, if it exists)
    let tempBindings = [];
    bindingsSnapshot.forEach((bindingDoc) => {
      //final step to build the bindingsarray, we need to add the project details, originally absent from the bindings Firestore doc,
      //but present in currentProfileProjectBindings when built by getData.js from projectdetails

      const currentProjectList = store.state.currentMyProjectList.concat(
        store.state.currentTeamProjectList
      );

      let index = currentProjectList.findIndex((object) => {
        return object.id === bindingDoc.data().projectid;
      });

      if (index == -1) {
        return;
      }

      //define a single binding
      let binding = bindingDoc.data();
      binding.id = bindingDoc.id;
      binding.metadata = bindingDoc.metadata;

      //get the related project of this binding
      const relatedProject = currentProjectList[index];

      let relatedProjectObject = {
        clientlogo: relatedProject.clientobject.clientlogourl,
        clientname: relatedProject.clientobject.clientname,
        positiontitle: relatedProject.positiontitle,
        projectid: binding.projectid,
        bindingid: binding.id,
        timestamp: binding.timestamp,
        match: binding.match,
        notes: binding.notes,
        profilestatusid: binding.profilestatusid,
        metadata: binding.metadata,
      };

      //add the bindings
      tempBindings.push(relatedProjectObject);
    });
    //make sure we are on profilelist before building the bindings array in Vuex
    //otherwise the bindings already exist in the array
    if (
      store.state.showingProfilesAs == "projectProfiles" ||
      store.state.showingProfilesAs == "recentProfiles" ||
      store.state.showingProfilesAs == "searchResults"
    ) {
      store.state.currentProfileProjectBindings = tempBindings;
    }

    // now we have the correct currentProfileProjectBindings array whether the click came from profilelist or profiledetails

    //code now continues inside this then() method so that we keep everything in sync

    //check if current project is already attached to this project
    if (
      store.state.currentProfileProjectBindings.filter(
        (e) => e.projectid === project.id
      ).length > 0
    ) {
      if (store.state.alertCount == 0) {
        alert(
          "The project was already attached to one or more selected profiles!"
        );
      }
      //increment alertCount variable defined in Vuex to avoid the message below showing multiple times
      //in case more than 1 profile already had this project attached
      store.state.alertCount = store.state.alertCount + 1;

      return;
    }

    //if the click came from profile list, then store the related profile as currentProfileDetails in Vuex
    if (
      (store.state.showingProfilesAs == "projectProfiles" ||
        store.state.showingProfilesAs == "recentProfiles" ||
        store.state.showingProfilesAs == "searchResults") &&
      routepath != "/extension"
    ) {
      //TODO: maybe add 'searchResults' here later, so that it works with search?
      let index = store.state.currentProfileList.findIndex((object) => {
        return object.id === profileid;
      });
      store.state.currentProfileDetails = store.state.currentProfileList[index];

      store.state.currentProfileDetails.id = profileid;
    }


    //before creating the binding document: if the function is called from the Chrome Extension, currentProfileDetails will be undefined. We need to create two paths below so that the data can be added to firestore below in every scenario
    let profileTeamAccess;
    let owningUser;
    let profileStatusId;
    let profileStatusCategory;
    let notestoadd2;
    //The click comes from extension
    if (routepath == "/extension") {
      profileTeamAccess = teamaccess;
      owningUser = store.state.userData.userid;
      if (!selectedstatus) {
        profileStatusId = "xxx";
        profileStatusCategory = "no status";
      } else {
        profileStatusId = selectedstatus.id;
        profileStatusCategory = selectedstatus.category;
      }
      if (notestoadd) {
        notestoadd2 = notestoadd;
      } else {
        notestoadd2 = "";
      }
    } else {
      //The click does not come from extension
      profileTeamAccess = store.state.currentProfileDetails.teamaccess;
      owningUser = store.state.currentProfileDetails.owninguser;
      profileStatusId = "xxx";
      (profileStatusCategory = "no status"), (notestoadd2 = "");
    }

 

    //create the binding document
    let bidingssubcollection = collection(
      db,
      "profiles/" + profileid + "/profileprojectbindings/"
    );
    addDoc(bidingssubcollection, {
      //here it would be easier, for DOM rendering purposes, to use the project object which holds all info about the project and the client (logo, client name, position title...)
      //but the problem is that if User updates the Client (or the Project), then we would have to update potentially 1000X profiles to keep the data in sync.
      //It seems cheaper to only store the id here, and then query Firestore when a user comes to ProfileDetails, in order to show the related data
      //from the Projects and Clients collections. At least it is a safer way pricing wise.
      projectid: project.id,
      profileid: profileid,
      timestamp: Date.now(),
      teamid: store.state.userData.teamid,
      userwhoattached: store.state.userData.userid,
      profileteamaccess: profileTeamAccess,
      owninguser: owningUser,
      match: -1,
      notes: notestoadd2,
      profilestatusid: profileStatusId,
      profilestatuscategory: profileStatusCategory,
      crossaccountid: bindingCrossAccountId,
      crossaccountprofileid: profileCrossAccountId,
    })
      .then(() => {
        //Confirmation toast
        if (routepath != "/extension") {
          iziToast.show({
            title: "Added to project !",
            backgroundColor: "#47be7d",
            titleColor: "white",
            theme: "dark",
            position: "bottomRight",
            timeout: 3000,
            icon: "fa fa-check",
          });
        }

        //now update the numberofnostatus count in the project document (to render the stats in projectdetails.vue)
        //AND Add recommended profiles from alsoviewed (from function argument)

        if (profileStatusCategory == "no status") {
          const qnumberOfNoStatus = query(
            collectionGroup(db, "profileprojectbindings"),
            where("projectid", "==", project.id),
            where("profilestatuscategory", "==", "no status")
          );
          getCountFromServer(qnumberOfNoStatus).then((statusSnap) => {
            let numberOfProfiles = statusSnap.data().count;
            //now that we have the numbers, update the project profiles counts
            updateDoc(doc(db, "projects", project.id), {
              numberofnostatus: numberOfProfiles,
            }).then(() => {
              //project updated
            });
          });
        } else if (profileStatusCategory == "Interested") {
          const qnumberOfInterested = query(
            collectionGroup(db, "profileprojectbindings"),
            where("projectid", "==", project.id),
            where("profilestatuscategory", "==", "Interested")
          );
          getCountFromServer(qnumberOfInterested).then((statusSnap) => {
            let numberOfProfiles = statusSnap.data().count;

            //now that we have the numbers, update the project profiles counts
            updateDoc(doc(db, "projects", project.id), {
              numberofpotentialcandidates: numberOfProfiles,
            }).then(() => {
              //project updated
            });
          });
        } else if (profileStatusCategory == "Discarded") {
          const qnumberOfDiscarded = query(
            collectionGroup(db, "profileprojectbindings"),
            where("projectid", "==", project.id),
            where("profilestatuscategory", "==", "Discarded")
          );
          getCountFromServer(qnumberOfDiscarded).then((statusSnap) => {
            let numberOfProfiles = statusSnap.data().count;

            //now that we have the numbers, update the project profiles counts
            updateDoc(doc(db, "projects", project.id), {
              numberofdiscarded: numberOfProfiles,
            }).then(() => {
              //alert("project updated!")
            });
          });
        } else if (profileStatusCategory == "In progress") {
          const qnumberOfInProgress = query(
            collectionGroup(db, "profileprojectbindings"),
            where("projectid", "==", project.id),
            where("profilestatuscategory", "==", "In progress")
          );
          getCountFromServer(qnumberOfInProgress).then((statusSnap) => {
            let numberOfProfiles = statusSnap.data().count;

            //now that we have the numbers, update the project profiles counts
            updateDoc(doc(db, "projects", project.id), {
              numberofinprogress: numberOfProfiles,
            }).then(() => {
              //alert("project updated!")
            });
          });
        }
      })
      .then(() => {
        //create activity log
        let activityLogsSubCollection = collection(
          db,
          "profiles/" + profileid + "/activitylogs/"
        );

        addDoc(activityLogsSubCollection, {
          typeofdata: "profile",
          timestamp: Date.now(),
          typeofupdate: "Attached to project",
          attachproject: true,
          clientname: project.clientobject.clientname,
          positiontitle: project.positiontitle,
          clientlogo: project.clientobject.clientlogourl,
          byuser: store.state.userData.userid,
          owninguser: owningUser,
          profileid: profileid,
        }).then(() => {});
      })
      .then(() => {
        //Update the Profile document in Firestore

        if (!store.state.currentProfileDetails.crossaccountid) {
          //Cross account ID doesn't already exist
          //Use the variable created at the beginning of this function
        } else {
          //Cross account ID already exists, do not overwrite, use existing
          profileCrossAccountId =
            store.state.currentProfileDetails.crossaccountid;
        }

        let profileRef = doc(db, "profiles", profileid);
        updateDoc(profileRef, {
          latestupdatetimestamp: Date.now(),
          latestupdatebyuser: store.state.userData.userid,
          latestbindingclientname: project.clientobject.clientname,
          latestbindingclientlogo: project.clientobject.clientlogourl,
          latestbindingposition: project.positiontitle,
          latestbindingprojectid: project.id,
          lateststatuscategory: profileStatusCategory,
          //add this project to the array for indexing and filtering in advanced search
          projectattachments: arrayUnion(
            project.clientobject.clientname + " / " + project.positiontitle
          ),
          crossaccountid: profileCrossAccountId,
        }).then(() => {
          //if click came from search results, or if searchresults is the view in cache, there is no snapshot listener to update the data in profilelist.
          //we need to update the data manually
          if (
            store.state.showingProfilesAs == "searchResults" ||
            store.state.profilesViewInCache == "searchResults"
          ) {
            const index = store.state.currentProfileList.findIndex((object) => {
              return object.id === profileid;
            });

            store.state.currentProfileList[index].latestupdatetimestamp =
              Date.now();
            store.state.currentProfileList[index].latestupdatebyuser =
              store.state.currentUserId;
            store.state.currentProfileList[index].latestbindingclientname =
              project.clientobject.clientname;
            store.state.currentProfileList[index].latestbindingclientlogo =
              project.clientobject.clientlogourl;
            store.state.currentProfileList[index].latestbindingposition =
              project.positiontitle;
            store.state.currentProfileList[index].latestbindingprojectid =
              project.id;
            store.state.currentProfileList[index].lateststatuscategory =
              "no status";
          }

          ///////// Duplicate to client/Lakeside account account if there is a linked project
          // Read the updated profile document
          if (project.linkedproject) {
            //This project is linked to another project (Lakeside or Client). Continue...
          } else {
            //This project is not linked to another project (Lakeside or Client). Stop duplicate flow!
            store.state.linkedinImportState = "done";
            return;
          }
          getDoc(profileRef)
            .then((docSnap) => {
              if (docSnap.exists()) {
                // Now access to profile doc

                setTimeout(() => {
                  let projectRef = doc(db, "projects", project.id);
                  getDoc(projectRef).then((projectSnap) => {
                    if (projectSnap.exists()) {
                      // Now access to project doc with updated counts

                      //Define things to send

                      //Define profile
                      let profileToDuplicate = docSnap.data();
                      profileToDuplicate.id = docSnap.id;
                      profileToDuplicate.latestbindingprojectid =
                        project.linkedproject;
                      //Empty tags
                      profileToDuplicate.tags = [];

                      //Define updated project info (counts)
                      let updatedProject = projectSnap.data();

         

                      //Define project id to target in client account
                      let projectToTarget = project.linkedproject;

                      //Define binding to duplicate
                      let bindingToDuplicate = {
                        projectid: project.id,
                        profileid: profileid,
                        crossaccountid: bindingCrossAccountId,
                        crossaccountprofileid:
                          profileToDuplicate.crossaccountid,
                        timestamp: Date.now(),
                        teamid: store.state.userData.teamid,
                        userwhoattached: store.state.userData.userid,
                        profileteamaccess: profileTeamAccess,
                        owninguser: owningUser,
                        match: -1,
                        notes: notestoadd2,
                        profilestatusid: profileStatusId,
                        profilestatuscategory: profileStatusCategory,
                      };

                      //Define "Added tag" to add to the profile to duplicate
                      let userWhoAdded = store.state.userData.userid
                      let tag = false
                      if(userWhoAdded == "Gpw9XN59XlV8reJkaDags3igDur2") {
                        tag = "Added by DH"
                      } else if(userWhoAdded == "iMkiWYEB9PbSkjLTHMlmGWjFuJS2") {
                        tag = "Added by VBL"
                      } else if(userWhoAdded == "2WUdpmVtGtX3PwZU9HKo4g6MYOj2") {
                        tag = "Added by TT"
                      }

                      if(tag) {
                      //Update profile to duplicate with "Added by" tag
                      let profileRef = doc(db, "profiles", profileid);
                      updateDoc(profileRef, {
                        tags: arrayUnion(
                          tag
                        ),
                        
                      })

                      }

                      //Client or Lakeside user/team will be fetched in the cloud function (because of firestore rules)
                      //Activity log will be created in the cloud function
                      //The cloud function checks if the profile exists with the LinkedIn URL, not ideal but good enough. Only simple way to check for possible duplicate.

                      //Define the cloud function call
                      const functions = getFunctions();
                      const duplicateProfile = httpsCallable(
                        functions,
                        "duplicateProfile"
                      );

                      async function callDuplicateProfile() {
                        try {
                          const result = await duplicateProfile({
                            profileToDuplicate,
                            updatedProject,
                            projectToTarget,
                            bindingToDuplicate,
                            bindingCrossAccountId,
                            userWhoAdded
                          });
                          store.state.linkedinImportState = "done";
                        } catch (error) {
                          console.error("Error calling function:", error);
                        }
                      }

                      // Invoke the function
                      callDuplicateProfile();
                    } else {
                      console.log("Project document not found");
                    }
                  });
                }, 400);
              } else {
                console.log("Profile document not found!");
              }
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        });
      });
  });
};

//called from Components/Cards/ProfileCardContents/ProfileProjectActivity.vue
const detachFromThisProjectExternal = (bindingobject) => {
  if (store.state.userData.userrole != "Administrator") {
    alert(
      "Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data."
    );
    return;
  }

  const profileid = store.state.currentProfileDetails.id;

  deleteDoc(
    doc(
      db,
      "profiles/" + profileid + "/profileprojectbindings/",
      bindingobject.bindingid
    )
  )
    .then(() => {
      //Confirmation toast
      iziToast.show({
        title: "Removed from project !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });

      //create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + profileid + "/activitylogs/"
      );

      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "Removed from project",
        byuser: store.state.currentUserId,
        owninguser: store.state.currentProfileDetails.owninguser,
        attachproject: true,
        clientname: bindingobject.clientname,
        positiontitle: bindingobject.positiontitle,
        clientlogo: bindingobject.clientlogo,
        profileid: profileid,
      }).then(() => {});
    })
    .then(() => {
      //update profile document with Latestupdated (timestamp & user who updated) + latestbding info needs to be updated aswell for display in Recent profiles (profileslist.vue)

      //delay to make sure the vuex array is updated
      setTimeout(() => {
        //if the detached project was the ONLY attachment, the array in Vuex will be empty.
        //we handle that case here
        if (store.state.currentProfileProjectBindings.length == 0) {
          //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
          let profileRef = doc(db, "profiles", profileid);
          updateDoc(profileRef, {
            latestupdatetimestamp: Date.now(),
            latestupdatebyuser: store.state.currentUserId,
            latestbindingclientlogo: "",
            latestbindingclientname: "",
            latestbindingposition: "",
            lateststatuscategory: "no status",
            //remove binding project from the following array, for advanced search filter
            projectattachments: arrayRemove(bindingobject.projectid),
          }).then(() => {
            //if click came from search results, or if searchresults is the view in cache, there is no snapshot listener to update the data in profilelist.
            //we need to update the data manually
            if (
              store.state.showingProfilesAs == "searchResults" ||
              store.state.profilesViewInCache == "searchResults"
            ) {
              const index = store.state.currentProfileList.findIndex(
                (object) => {
                  return object.id === profileid;
                }
              );
              store.state.currentProfileList[index].latestupdatetimestamp =
                Date.now();
              store.state.currentProfileList[index].latestupdatebyuser =
                store.state.currentUserId;
              store.state.currentProfileList[index].latestbindingclientname =
                "";
              store.state.currentProfileList[index].latestbindingclientlogo =
                projectDoc.data().clientobject.clientlogourl;
              store.state.currentProfileList[index].latestbindingposition = "";
              store.state.currentProfileList[index].latestbindingprojectid = "";
              store.state.currentProfileList[index].lateststatuscategory = "";
            }
          });
        } else {
          const newLatestProfileBindingProjectId =
            store.state.currentProfileProjectBindings[0].projectid;

          //get the related project to add the project details in the activity log
          const projectRef = doc(
            db,
            "projects",
            newLatestProfileBindingProjectId
          );
          getDoc(projectRef).then((projectDoc) => {
       


            //get the category of current status

            let statuscategory;
            //if there is no status
            if (
              store.state.currentProfileProjectBindings[0].profilestatusid ==
              "xxx"
            ) {
              statuscategory = "no status";
            }
            //if there is a status
            else {
              statuscategory = store.state.profileStatusList.find(
                (x) =>
                  x.id ===
                  store.state.currentProfileProjectBindings[0].profilestatusid
              ).category;
            }

            //Prepare Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)

            //Update the Profile document
            let profileRef = doc(db, "profiles", profileid);
            updateDoc(profileRef, {
              latestupdatetimestamp: Date.now(),
              latestupdatebyuser: store.state.currentUserId,
              latestbindingclientlogo:
                projectDoc.data().clientobject.clientlogourl,
              latestbindingclientname:
                projectDoc.data().clientobject.clientname,
              latestbindingposition: projectDoc.data().positiontitle,
              lateststatuscategory: statuscategory,
              //remove binding project from the following array, for advanced search filter
              projectattachments: arrayRemove(
                projectDoc.data().clientobject.clientname +
                  " / " +
                  projectDoc.data().positiontitle
              ),
            }).then(() => {
              //if click came from search results, or if searchresults is the view in cache, there is no snapshot listener to update the data in profilelist.
              //we need to update the data manually
              if (
                store.state.showingProfilesAs == "searchResults" ||
                store.state.profilesViewInCache == "searchResults"
              ) {
                const index = store.state.currentProfileList.findIndex(
                  (object) => {
                    return object.id === profileid;
                  }
                );


                store.state.currentProfileList[index].latestupdatetimestamp =
                  Date.now();
                store.state.currentProfileList[index].latestupdatebyuser =
                  store.state.currentUserId;
                store.state.currentProfileList[index].latestbindingclientname =
                  projectDoc.data().clientobject.clientname;
                store.state.currentProfileList[index].latestbindingclientlogo =
                  projectDoc.data().clientobject.clientlogourl;
                store.state.currentProfileList[index].latestbindingposition =
                  projectDoc.data().positiontitle;
                store.state.currentProfileList[index].latestbindingprojectid =
                  projectDoc.id;
                store.state.currentProfileList[index].lateststatuscategory =
                  statuscategory;
              }
            });
          });
        }

        //count the number of profiles in each status category within the bindings subcollection

        let numberOfPotentialCandidates;
        let numberOfDiscarded;
        let numberOfInProgress;
        let numberOfNoStatus;

        const qNumberOfPotentialCandidates = query(
          collectionGroup(db, "profileprojectbindings"),
          where("projectid", "==", bindingobject.projectid),
          where("profilestatuscategory", "==", "Interested")
        );
        getCountFromServer(qNumberOfPotentialCandidates).then(
          (potentialsSnap) => {
            numberOfPotentialCandidates = potentialsSnap.data().count;

            const qNumberOfDiscarded = query(
              collectionGroup(db, "profileprojectbindings"),
              where("projectid", "==", bindingobject.projectid),
              where("profilestatuscategory", "==", "Discarded")
            );
            getCountFromServer(qNumberOfDiscarded)
              .then((discardedSnap) => {
                numberOfDiscarded = discardedSnap.data().count;

                const qnumberOfInProgress = query(
                  collectionGroup(db, "profileprojectbindings"),
                  where("projectid", "==", bindingobject.projectid),
                  where("profilestatuscategory", "==", "In progress")
                );
                getCountFromServer(qnumberOfInProgress)
                  .then((inProgressSnap) => {
                    numberOfInProgress = inProgressSnap.data().count;

                    const qnumberOfNoStatus = query(
                      collectionGroup(db, "profileprojectbindings"),
                      where("projectid", "==", bindingobject.projectid),
                      where("profilestatuscategory", "==", "no status")
                    );
                    getCountFromServer(qnumberOfNoStatus)
                      .then((noStatusSnap) => {
                        numberOfNoStatus = noStatusSnap.data().count;

                        let projectRef = doc(
                          db,
                          "projects",
                          bindingobject.projectid
                        );

                        //now that we have the numbers, update the project profiles counts
                        updateDoc(projectRef, {
                          numberofpotentialcandidates:
                            numberOfPotentialCandidates,
                          numberofdiscarded: numberOfDiscarded,
                          numberofinprogress: numberOfInProgress,
                          numberofnostatus: numberOfNoStatus,
                        }).then(() => {
                          //alert("project updated!")
                        });
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        );
      }, 700);
    });

  //delete the profile from Profilelist in Vuex if the user views project profiles in profilelist.vue
  if (store.state.profilesViewInCache == "projectProfiles") {
    const index = store.state.currentProfileList.findIndex(
      (profile) => profile.id === store.state.currentProfileDetails.id
    );

    store.state.currentProfileList.splice(index, 1);
  }
};

//called from Components/Cards/ProfileCardContents/ProfileProjectActivity.vue
const storeCurrentProfileProjectBindingExternal = (binding) => {
  store.state.currentProfileProjectBinding = binding;
};

/* Rating/scoring feature not in use for the moment (!!! removed from export at the end of this file)
const updateMatchExternal = () => {
  //TODO: update profile last update Timestamp and User if it is relevant to consider this as an update when getting recently updated profiles


  const profileProjectBindingRef = doc(db, "profiles/" + store.state.currentProfileDetails.id + "/profileprojectbindings", store.state.currentProfileProjectBinding.bindingid);




  updateDoc(profileProjectBindingRef, {
    match: store.state.currentProfileProjectBinding.match * 1,
  })



}

const resetMatchExternal = () => {
  const profileProjectBindingRef = doc(db, "profiles/" + store.state.currentProfileDetails.id + "/profileprojectbindings", store.state.currentProfileProjectBinding.bindingid);

  //update the firestore doc
  updateDoc(profileProjectBindingRef, {
    match: -1
  })
    //update the Vuex data manually as there is no realtime listener for this feature
    .then(() => {
      store.state.currentProfileProjectBinding.match = -1
    })
}
*/

const saveNotesExternal = () => {
  const profileProjectBindingRef = doc(
    db,
    "profiles/" +
      store.state.currentProfileDetails.id +
      "/profileprojectbindings",
    store.state.currentProfileProjectBinding.bindingid
  );
  updateDoc(profileProjectBindingRef, {
    notes: store.state.currentProfileProjectBinding.notes,
  }).then(() => {

    //If there is a linked project, then update the linked project binding with the new notes.
    if (store.state.currentProfileProjectBinding.crossaccountid) {
      const functions = getFunctions();
      const updateBinding = httpsCallable(functions, "updateBinding");

      updateBinding({
        bindingid: store.state.currentProfileProjectBinding.bindingid,
        profileid: store.state.currentProfileDetails.id,
      })
        .then((result) => {
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      //no linked project (binding) to update
    }

    //Rest of the function
    const index = store.state.currentProfileList.findIndex(
      (profile) => profile.id === store.state.currentProfileDetails.id
    );

    setTimeout(() => {
      //need delay because of a sync issue
      if (index !== -1) {

        if (
          store.state.currentProfileProjectBinding.notes ||
          store.state.currentProfileProjectBinding.notes != undefined
        ) {
          store.state.currentProfileList[index].notes =
            store.state.currentProfileProjectBinding.notes;
     
       

          store.state.currentProfileList[index].notes = " ";
        
        }
      }
    }, 1000);

    //create activity log
    let activityLogsSubCollection = collection(
      db,
      "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
    );

    addDoc(activityLogsSubCollection, {
      typeofdata: "profile",
      timestamp: Date.now(),
      typeofupdate: "New notes in a project",
      byuser: store.state.currentUserId,
      owninguser: store.state.currentProfileDetails.owninguser,
      commenttext: store.state.currentProfileProjectBinding.notes,
      attachproject: true,
      clientname: store.state.currentProfileProjectBinding.clientname,
      positiontitle: store.state.currentProfileProjectBinding.positiontitle,
      clientlogo: store.state.currentProfileProjectBinding.clientlogo,
      profileid: store.state.currentProfileDetails.id,
    })
      .then(() => {
        //Confirmation toast
        iziToast.show({
          title: "Notes saved !",
          backgroundColor: "#47be7d",
          titleColor: "white",
          theme: "dark",
          position: "bottomRight",
          timeout: 3000,
          icon: "fa fa-check",
        });
      })
      .then(() => {
        //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
        let profileRef = doc(
          db,
          "profiles",
          store.state.currentProfileDetails.id
        );
        updateDoc(profileRef, {
          latestupdatetimestamp: Date.now(),
          latestupdatebyuser: store.state.currentUserId,
          latestbindingclientlogo: "",
          latestbindingclientname: "",
          latestbindingprojectid: "",
        });
      });
  });
};

const updateProfileStatusExternal = (
  origin,
  profileobject,
  bindingid,
  statusobject,
  projectid
) => {
  const projectRef = doc(db, "projects", projectid);

  //time to hire
  getDoc(projectRef).then((projectDoc) => {
    let exists = false;

    if (!projectDoc.data().timetohire) {
      //there is no timetohire property in the project doc in firestore
      exists = false;
    } else {
      //there is a timetohire property in the project doc in firestore, at least one timetohire has been assigned
      for (let i = 0; i < projectDoc.data().timetohire.length; i++) {
        if (projectDoc.data().timetohire[i].statusid === statusobject.id) {
          exists = true;
          break;
        }
      }
    }

    if (exists) {
      //the selected status has already been assigned in timetohire
    } else {
      //the selected status has not yet been assigned in timetohire
      // Get the current timestamp in milliseconds
      let currentTime = new Date().getTime();

      // Subtract the timestamp found in `store.state.currentProjectDetails.startedat`
      let timeSpan = currentTime - projectDoc.data().startedat;

      // Update the document
      updateDoc(projectRef, {
        timetohire: arrayUnion({
          statusid: statusobject.id,
          timespan: timeSpan,
        }),
      })
        .then(() => {
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    }

    // Find the document with the id of `projectid` within the "projects" collection
  });

  //define binding ref
  const bindingRef = doc(
    db,
    "profiles/" + profileobject.id + "/profileprojectbindings",
    bindingid
  );

  //define if a status was clicked or if it was "No status"
  let thereIsAStatus = true;
  if (statusobject == "no status") {
    thereIsAStatus = false;
  }

  //get the related project
  getDoc(projectRef)
    .then((projectDoc) => {
      //Sync with client or Lakeside if the project has a linked project
      let syncBindings;

      if (projectDoc.data().linkedproject) {
        //Define sync function between Lakeside and client, to be used later
        syncBindings = () => {
          //If connected project, update the binding on the Lakeside or Client account
          //FIRST CHECK IF THIS PROJECT IS CONNECTED

          const originalProfileId = profileobject.id;

          const functions = getFunctions();
          const updateBinding = httpsCallable(functions, "updateBinding");

          updateBinding({ bindingid: bindingid, profileid: originalProfileId })
            .then((result) => {
            })
            .catch((error) => {
              console.error("An error occurred:", error);
              alert("An error occurred!");
            });
        };
      } else {
        syncBindings = () => {
        };
      }

      if (origin == "profileDetailsCard" || origin == "profileDetailsModal") {
        if (!thereIsAStatus) {
          updateDoc(bindingRef, {
            profilestatusid: "xxx",
            profilestatuscategory: "no status",
          })
            .then(() => {
              //sync bindings
              syncBindings();

              //Confirmation toast
              iziToast.show({
                title: "Profile status updated !",
                backgroundColor: "#47be7d",
                titleColor: "white",
                theme: "dark",
                position: "bottomRight",
                timeout: 3000,
                icon: "fa fa-check",
              });

              ////update the project profiles counts to render stats in projectdetails.vue

              //count the number of profiles in each status category within the bindings subcollection

              let numberOfPotentialCandidates;
              let numberOfDiscarded;
              let numberOfInProgress;
              let numberOfNoStatus;

              const qNumberOfPotentialCandidates = query(
                collectionGroup(db, "profileprojectbindings"),
                where("projectid", "==", projectid),
                where("profilestatuscategory", "==", "Interested")
              );
              getCountFromServer(qNumberOfPotentialCandidates).then(
                (potentialsSnap) => {
                  numberOfPotentialCandidates = potentialsSnap.data().count;

                  const qNumberOfDiscarded = query(
                    collectionGroup(db, "profileprojectbindings"),
                    where("projectid", "==", projectid),
                    where("profilestatuscategory", "==", "Discarded")
                  );
                  getCountFromServer(qNumberOfDiscarded)
                    .then((discardedSnap) => {
                      numberOfDiscarded = discardedSnap.data().count;

                      const qnumberOfInProgress = query(
                        collectionGroup(db, "profileprojectbindings"),
                        where("projectid", "==", projectid),
                        where("profilestatuscategory", "==", "In progress")
                      );
                      getCountFromServer(qnumberOfInProgress)
                        .then((inProgressSnap) => {
                          numberOfInProgress = inProgressSnap.data().count;

                          const qnumberOfNoStatus = query(
                            collectionGroup(db, "profileprojectbindings"),
                            where("projectid", "==", projectid),
                            where("profilestatuscategory", "==", "no status")
                          );
                          getCountFromServer(qnumberOfNoStatus)
                            .then((noStatusSnap) => {
                              numberOfNoStatus = noStatusSnap.data().count;

                              //now that we have the numbers, update the project profiles counts
                              updateDoc(projectRef, {
                                numberofpotentialcandidates:
                                  numberOfPotentialCandidates,
                                numberofdiscarded: numberOfDiscarded,
                                numberofinprogress: numberOfInProgress,
                                numberofnostatus: numberOfNoStatus,
                              }).then(() => {
                                //alert("project updated!")
                              });
                            })
                            .catch((e) => {
                              console.log(e);
                            });
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              );
            })
            .then(() => {
              //we don't add an acitivity log here, doesn't seem necessary for a "No status" update.
            })
            .then(() => {
              //this project is the most recent one
              if (
                bindingid ==
                store.state.currentProfileProjectBindings[0].bindingid
              ) {
                let profileRef = doc(db, "profiles", profileobject.id);
                updateDoc(profileRef, {
                  latestupdatetimestamp: Date.now(),
                  latestupdatebyuser: store.state.currentUserId,
                  lateststatuscategory: "no status",
                });
              }
              //this project is NOT the most recent one
              if (
                bindingid !=
                store.state.currentProfileProjectBindings[0].bindingid
              ) {
                let profileRef = doc(db, "profiles", profileobject.id);
                updateDoc(profileRef, {
                  latestupdatetimestamp: Date.now(),
                  latestupdatebyuser: store.state.currentUserId,
                });
              }
            });
        } else if (thereIsAStatus) {
          //in case of a project linked between Lakeside & client, and in case of a status that only the client has, ask if want to proceed
          if (
            projectDoc.data().linkedproject &&
            !store.state.lakesideStatusList.includes(statusobject.id)
          ) {
            const userConfirmedStatusAlert = window.confirm(
              "This status is used by your team only. It is not used by Lakeside. If you apply this status to this profile, it will not be seen by your Lakeside partner. Proceed anyway? "
            );
            if (userConfirmedStatusAlert) {
              // Continue function ...
            } else {
              // Stop the function
              return;
            }
          }

          //update the status in the binding
          updateDoc(bindingRef, {
            profilestatusid: statusobject.id,
            profilestatuscategory: statusobject.category,
          })
            .then(() => {
              //sync bindings
              syncBindings();

              //Confirmation toast
              iziToast.show({
                title: "Profile status updated !",
                backgroundColor: "#47be7d",
                titleColor: "white",
                theme: "dark",
                position: "bottomRight",
                timeout: 3000,
                icon: "fa fa-check",
              });

              //update the project profiles counts to render stats in projectdetails.vue

              //count the number of profiles in each status category within the bindings subcollection

              let numberOfPotentialCandidates;
              let numberOfDiscarded;
              let numberOfInProgress;
              let numberOfNoStatus;

              const qNumberOfPotentialCandidates = query(
                collectionGroup(db, "profileprojectbindings"),
                where("projectid", "==", projectid),
                where("profilestatuscategory", "==", "Interested")
              );
              getCountFromServer(qNumberOfPotentialCandidates).then(
                (potentialsSnap) => {
                  numberOfPotentialCandidates = potentialsSnap.data().count;

                  const qNumberOfDiscarded = query(
                    collectionGroup(db, "profileprojectbindings"),
                    where("projectid", "==", projectid),
                    where("profilestatuscategory", "==", "Discarded")
                  );
                  getCountFromServer(qNumberOfDiscarded)
                    .then((discardedSnap) => {
                      numberOfDiscarded = discardedSnap.data().count;

                      const qnumberOfInProgress = query(
                        collectionGroup(db, "profileprojectbindings"),
                        where("projectid", "==", projectid),
                        where("profilestatuscategory", "==", "In progress")
                      );
                      getCountFromServer(qnumberOfInProgress)
                        .then((inProgressSnap) => {
                          numberOfInProgress = inProgressSnap.data().count;

                          const qnumberOfNoStatus = query(
                            collectionGroup(db, "profileprojectbindings"),
                            where("projectid", "==", projectid),
                            where("profilestatuscategory", "==", "no status")
                          );
                          getCountFromServer(qnumberOfNoStatus)
                            .then((noStatusSnap) => {
                              numberOfNoStatus = noStatusSnap.data().count;

                              //now that we have the numbers, update the project profiles counts
                              updateDoc(projectRef, {
                                numberofpotentialcandidates:
                                  numberOfPotentialCandidates,
                                numberofdiscarded: numberOfDiscarded,
                                numberofinprogress: numberOfInProgress,
                                numberofnostatus: numberOfNoStatus,
                              }).then(() => {
                                //alert("project updated!")
                              });
                            })
                            .catch((e) => {
                              console.log(e);
                            });
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              );
            })
            .then(() => {
              //update the project profiles counts to render stats in projectdetails.vue

              //count the number of profiles in each status category within the bindings subcollection

              let numberOfPotentialCandidates;
              let numberOfDiscarded;
              let numberOfInProgress;
              let numberOfNoStatus;

              const qNumberOfPotentialCandidates = query(
                collectionGroup(db, "profileprojectbindings"),
                where("projectid", "==", projectid),
                where("profilestatuscategory", "==", "Interested")
              );
              getCountFromServer(qNumberOfPotentialCandidates).then(
                (potentialsSnap) => {
                  numberOfPotentialCandidates = potentialsSnap.data().count;

                  const qNumberOfDiscarded = query(
                    collectionGroup(db, "profileprojectbindings"),
                    where("projectid", "==", projectid),
                    where("profilestatuscategory", "==", "Discarded")
                  );
                  getCountFromServer(qNumberOfDiscarded)
                    .then((discardedSnap) => {
                      numberOfDiscarded = discardedSnap.data().count;

                      const qnumberOfInProgress = query(
                        collectionGroup(db, "profileprojectbindings"),
                        where("projectid", "==", projectid),
                        where("profilestatuscategory", "==", "In progress")
                      );
                      getCountFromServer(qnumberOfInProgress)
                        .then((inProgressSnap) => {
                          numberOfInProgress = inProgressSnap.data().count;

                          const qnumberOfNoStatus = query(
                            collectionGroup(db, "profileprojectbindings"),
                            where("projectid", "==", projectid),
                            where("profilestatuscategory", "==", "no status")
                          );
                          getCountFromServer(qnumberOfNoStatus)
                            .then((noStatusSnap) => {
                              numberOfNoStatus = noStatusSnap.data().count;

                              //now that we have the numbers, update the project profiles counts
                              updateDoc(projectRef, {
                                numberofpotentialcandidates:
                                  numberOfPotentialCandidates,
                                numberofdiscarded: numberOfDiscarded,
                                numberofinprogress: numberOfInProgress,
                                numberofnostatus: numberOfNoStatus,
                              }).then(() => {
                                //alert("project updated!")
                              });
                            })
                            .catch((e) => {
                              console.log(e);
                            });
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              );
            })
            .then(() => {
              //add an acitivity log
              let activityLogsSubCollection = collection(
                db,
                "profiles/" + profileobject.id + "/activitylogs/"
              );
              addDoc(activityLogsSubCollection, {
                typeofdata: "profile",
                timestamp: Date.now(),
                typeofupdate: "New status in a project",
                byuser: store.state.currentUserId,
                owninguser: store.state.currentProfileDetails.owninguser,
                attachproject: true,
                clientname: projectDoc.data().clientobject.clientname,
                positiontitle: projectDoc.data().positiontitle,
                clientlogo: projectDoc.data().clientobject.clientlogourl,
                projectid: projectDoc.id,
                statusid: statusobject.id,
                profileid: profileobject.id,
              });
            })
            .then(() => {
              setTimeout(() => {
                //delay to make sure the Vuex bidning array has been filled
                //this project is the most recent one
                if (
                  bindingid ==
                  store.state.currentProfileProjectBindings[0].bindingid
                ) {
                  let profileRef = doc(db, "profiles", profileobject.id);
                  updateDoc(profileRef, {
                    latestupdatetimestamp: Date.now(),
                    latestupdatebyuser: store.state.currentUserId,
                    lateststatuscategory: statusobject.category,
                  });
                }
                //this project is NOT the most recent one
                if (
                  bindingid !=
                  store.state.currentProfileProjectBindings[0].bindingid
                ) {
                  let profileRef = doc(db, "profiles", profileobject.id);
                  updateDoc(profileRef, {
                    latestupdatetimestamp: Date.now(),
                    latestupdatebyuser: store.state.currentUserId,
                  });
                }
              }, 500);
            });
        }
      } else if (
        origin == "projectProfileList" ||
        origin == "batchManipulateBanner"
      ) {
        if (!thereIsAStatus) {
          updateDoc(bindingRef, {
            profilestatusid: "xxx",
            profilestatuscategory: "no status",
          })
            .then(() => {
              //sync bindings
              syncBindings();

              //Confirmation toast
              iziToast.show({
                title: "Profile status updated !",
                backgroundColor: "#47be7d",
                titleColor: "white",
                theme: "dark",
                position: "bottomRight",
                timeout: 3000,
                icon: "fa fa-check",
              });

              //update the project profiles counts to render stats in projectdetails.vue

              //count the number of profiles in each status category within the bindings subcollection

              let numberOfPotentialCandidates;
              let numberOfDiscarded;
              let numberOfInProgress;
              let numberOfNoStatus;

              const qNumberOfPotentialCandidates = query(
                collectionGroup(db, "profileprojectbindings"),
                where("projectid", "==", projectid),
                where("profilestatuscategory", "==", "Interested")
              );
              getCountFromServer(qNumberOfPotentialCandidates).then(
                (potentialsSnap) => {
                  numberOfPotentialCandidates = potentialsSnap.data().count;

                  const qNumberOfDiscarded = query(
                    collectionGroup(db, "profileprojectbindings"),
                    where("projectid", "==", projectid),
                    where("profilestatuscategory", "==", "Discarded")
                  );
                  getCountFromServer(qNumberOfDiscarded)
                    .then((discardedSnap) => {
                      numberOfDiscarded = discardedSnap.data().count;

                      const qnumberOfInProgress = query(
                        collectionGroup(db, "profileprojectbindings"),
                        where("projectid", "==", projectid),
                        where("profilestatuscategory", "==", "In progress")
                      );
                      getCountFromServer(qnumberOfInProgress)
                        .then((inProgressSnap) => {
                          numberOfInProgress = inProgressSnap.data().count;

                          const qnumberOfNoStatus = query(
                            collectionGroup(db, "profileprojectbindings"),
                            where("projectid", "==", projectid),
                            where("profilestatuscategory", "==", "no status")
                          );
                          getCountFromServer(qnumberOfNoStatus)
                            .then((noStatusSnap) => {
                              numberOfNoStatus = noStatusSnap.data().count;

                              //now that we have the numbers, update the project profiles counts
                              updateDoc(projectRef, {
                                numberofpotentialcandidates:
                                  numberOfPotentialCandidates,
                                numberofdiscarded: numberOfDiscarded,
                                numberofinprogress: numberOfInProgress,
                                numberofnostatus: numberOfNoStatus,
                              }).then(() => {
                                //alert("project updated!")
                              });
                            })
                            .catch((e) => {
                              console.log(e);
                            });
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              );
            })
            .then(() => {
              //we don't add an acitivity log here, doesn't seem necessary for a "No status" update.
            })
            .then(() => {
              //this project is the most recent one
              if (projectDoc.id == profileobject.latestbindingprojectid) {
                let profileRef = doc(db, "profiles", profileobject.id);
                updateDoc(profileRef, {
                  latestupdatetimestamp: Date.now(),
                  latestupdatebyuser: store.state.currentUserId,
                  lateststatuscategory: "no status",
                });
              }
              //this project is NOT the most recent one
              if (projectDoc.id != profileobject.latestbindingprojectid) {
                let profileRef = doc(db, "profiles", profileobject.id);
                updateDoc(profileRef, {
                  latestupdatetimestamp: Date.now(),
                  latestupdatebyuser: store.state.currentUserId,
                });
              }
            });
        } else if (thereIsAStatus) {
          //in case of a project linked between Lakeside & client, and in case of a status that only the client has, ask if want to proceed
          if (
            projectDoc.data().linkedproject &&
            !store.state.lakesideStatusList.includes(statusobject.id)
          ) {
            const userConfirmedStatusAlert = window.confirm(
              "This status is used by your team only. It is not used by Lakeside. If you apply this status to this profile, it will not be seen by your Lakeside partner. Proceed anyway? "
            );
            if (userConfirmedStatusAlert) {
              // Continue function ...
            } else {
              // Stop the function
              return;
            }
          }

          updateDoc(bindingRef, {
            profilestatusid: statusobject.id,
            profilestatuscategory: statusobject.category,
          })
            .then(() => {
              //sync bindings
              syncBindings();

              //Confirmation toast
              iziToast.show({
                title: "Profile status updated !",
                backgroundColor: "#47be7d",
                titleColor: "white",
                theme: "dark",
                position: "bottomRight",
                timeout: 3000,
                icon: "fa fa-check",
              });

              //update the project profiles counts to render stats in projectdetails.vue

              //count the number of profiles in each status category within the bindings subcollection

              let numberOfPotentialCandidates;
              let numberOfDiscarded;
              let numberOfInProgress;
              let numberOfNoStatus;

              const qNumberOfPotentialCandidates = query(
                collectionGroup(db, "profileprojectbindings"),
                where("projectid", "==", projectid),
                where("profilestatuscategory", "==", "Interested")
              );
              getCountFromServer(qNumberOfPotentialCandidates).then(
                (potentialsSnap) => {
                  numberOfPotentialCandidates = potentialsSnap.data().count;

                  const qNumberOfDiscarded = query(
                    collectionGroup(db, "profileprojectbindings"),
                    where("projectid", "==", projectid),
                    where("profilestatuscategory", "==", "Discarded")
                  );
                  getCountFromServer(qNumberOfDiscarded)
                    .then((discardedSnap) => {
                      numberOfDiscarded = discardedSnap.data().count;

                      const qnumberOfInProgress = query(
                        collectionGroup(db, "profileprojectbindings"),
                        where("projectid", "==", projectid),
                        where("profilestatuscategory", "==", "In progress")
                      );
                      getCountFromServer(qnumberOfInProgress)
                        .then((inProgressSnap) => {
                          numberOfInProgress = inProgressSnap.data().count;

                          const qnumberOfNoStatus = query(
                            collectionGroup(db, "profileprojectbindings"),
                            where("projectid", "==", projectid),
                            where("profilestatuscategory", "==", "no status")
                          );
                          getCountFromServer(qnumberOfNoStatus)
                            .then((noStatusSnap) => {
                              numberOfNoStatus = noStatusSnap.data().count;

                              //now that we have the numbers, update the project profiles counts
                              updateDoc(projectRef, {
                                numberofpotentialcandidates:
                                  numberOfPotentialCandidates,
                                numberofdiscarded: numberOfDiscarded,
                                numberofinprogress: numberOfInProgress,
                                numberofnostatus: numberOfNoStatus,
                              }).then(() => {
                                // alert("project updated!")
                              });
                            })
                            .catch((e) => {
                              console.log(e);
                            });
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              );
            })
            .then(() => {
              //add an acitivity log
              let activityLogsSubCollection = collection(
                db,
                "profiles/" + profileobject.id + "/activitylogs/"
              );
              addDoc(activityLogsSubCollection, {
                typeofdata: "profile",
                timestamp: Date.now(),
                typeofupdate: "New status in a project",
                byuser: store.state.currentUserId,
                owninguser: profileobject.owninguser,
                attachproject: true,
                clientname: projectDoc.data().clientobject.clientname,
                positiontitle: projectDoc.data().positiontitle,
                clientlogo: projectDoc.data().clientobject.clientlogourl,
                projectid: projectDoc.id,
                statusid: statusobject.id,
                profileid: profileobject.id,
              });
            })
            .then(() => {
              setTimeout(() => {
                //delay to make sure the Vuex bidning array has been filled
                //this project is the most recent one
                if (projectDoc.id == profileobject.latestbindingprojectid) {
                  let profileRef = doc(db, "profiles", profileobject.id);
                  updateDoc(profileRef, {
                    latestupdatetimestamp: Date.now(),
                    latestupdatebyuser: store.state.currentUserId,
                    lateststatuscategory: statusobject.category,
                  });
                }
                //this project is NOT the most recent one
                if (projectDoc.id != profileobject.latestbindingprojectid) {
                  let profileRef = doc(db, "profiles", profileobject.id);
                  updateDoc(profileRef, {
                    latestupdatetimestamp: Date.now(),
                    latestupdatebyuser: store.state.currentUserId,
                  });
                }
              }, 500);
            });
        }
      }
    })
    .then(() => {
      //if the current profile list stored in Vuex (currentProfileList) is a project profile list, then we need to update the array manually.
      //Because getProjectProfiles in getData.js does not listen to changes in the profiles collection, but in the projectprofilebindings subcollection
      //This also applies when showing searchResults as there is no realtime listener for this view
      if (
        store.state.profilesViewInCache == "searchResults" ||
        store.state.profilesViewInCache == "recentProfiles" ||
        store.state.profilesViewInCache == "projectProfiles"
      ) {
        let currentProfileList = store.state.currentProfileList;
        let index = currentProfileList.findIndex((object) => {
          return object.id === profileobject.id;
        });
        store.state.currentProfileList[index].lateststatuscategory =
          statusobject.category;
      }
    });

  /*
    const bindingRef = doc(db, "profiles/" + profileid + "/profileprojectbindings", bindingid);
    if (statusobject == "no status") {
      updateDoc(bindingRef, {
        profilestatusid: "xxx"
      })
      .then(() => {
        let profileRef = doc(db, "profiles", profileid)
        updateDoc(profileRef, {
          latestupdatetimestamp: Date.now(),
          latestupdatebyuser: store.state.currentUserId,
          lateststatuscategory: "no status"
        })
      })
    }
    else {
      updateDoc(bindingRef, {
        profilestatusid: statusobject.id
      })
        .then(() => {
          //we need to update the id here, because the currentProfileProjectBinding is being updated manually when user click on the + button in the card.
          //this syncs the status display in the modal and in the card.
          store.state.currentProfileProjectBinding.profilestatusid = statusobject.id
  
          console.log(store.state.currentProfileProjectBindings)
  
          ///////////add activity log
          //get the related profile in order to add an activity log
          const profileRef = doc(db, "profiles", profileid);
          getDoc(profileRef)
            .then((result) => {
              if (result.exists()) {
                store.state.currentProfileDetails = result.data()
                store.state.currentProfileDetails.id = result.id
  
                //get the related profile binding in order to get the related project
                getDoc(bindingRef)
                  .then((bindingSnap) => {
                    if (bindingSnap.exists()) {
  
                      //get the related project to add the project details in the activity log
                      const projectRef = doc(db, "projects", bindingSnap.data().projectid);
                      getDoc(projectRef)
                    .then((projectDoc) => {
                      console.log('this is the project: ' + projectDoc.data().positiontitle)
  
                      //add an acitivity log
                      let activityLogsSubCollection = collection(db, "profiles/" + profileid + "/activitylogs/")
  
                      addDoc(activityLogsSubCollection, {
                        typeofdata: "profile",
                        timestamp: Date.now(),
                        typeofupdate: "New status in a project",
                        byuser: store.state.currentUserId,
                        owninguser: store.state.currentProfileDetails.owninguser,
                        attachproject: true,
                        clientname: projectDoc.data().clientobject.clientname,
                        positiontitle: projectDoc.data().positiontitle,
                        clientlogo: projectDoc.data().clientobject.clientlogourl,
                        projectid: bindingSnap.data().projectid,
                        statusid: statusobject.id
                      })
                    })
                    }
                    else {
                      console.log("binding not found")
                    }
                  })
              }
            })
  
  
          
  
          /////////////update profile document
          //check: is the current binding the most recent binding added? the most recent project attached?
  
          //first delay 500ms to make sure currentProfileProjectBindings has been reloaded in Vuex
          setTimeout(() => {
            console.log("first bindingid : " + bindingid)
            console.log(store.state.currentProfileProjectBindings)
            if (bindingid == store.state.currentProfileProjectBindings[0].bindingid) {
              //if yes: then we should update the profile document in firestore in order to show the color of the status category in profilelist.vue (for Recent profiles only)
              //and also update the timestamp and latestupdatedbyuser
              let profileRef = doc(db, "profiles", profileid)
              updateDoc(profileRef, {
                latestupdatetimestamp: Date.now(),
                latestupdatebyuser: store.state.currentUserId,
                lateststatuscategory: statusobject.category
              })
            }
            else {
              //if no: only update timestamp and user of latest update
              updateDoc(profileRef, {
                latestupdatetimestamp: Date.now(),
                latestupdatebyuser: store.state.currentUserId,
              })
            }
          }, 500);
  
  
  
        })
  
    }
  
  */
};

const addProfileCommentExternal = (profileid) => {
  //selected project to attach or not
  let clientname;
  let positiontitle;
  let clientlogo;
  let projectid;
  let showtoclient;

  if (
    store.state.currentProfileProjectBindings.length == 0 ||
    store.state.currentProfileDetails.candidateorclient == "Client" ||
    store.state.showingProfilesAs == "recentProfiles" ||
    store.state.showingProfilesAs == "searchResults"
  ) {
    clientname = "";
    positiontitle = "";
    clientlogo = "";
    projectid = "";
    showtoclient = "";
  } else {
    clientname = store.state.selectedProjectForCommentAttachment.clientname;
    positiontitle =
      store.state.selectedProjectForCommentAttachment.positiontitle;
    clientlogo = store.state.selectedProjectForCommentAttachment.clientlogo;
    projectid = store.state.selectedProjectForCommentAttachment.projectid;
    showtoclient = store.state.showCommentToClient;
  }

  //add the comment
  let commentRef = collection(
    db,
    "profiles/" + profileid + "/profilecomments/"
  );

  addDoc(commentRef, {
    timestamp: Date.now(),
    userwhocomments: store.state.currentUserId,
    profileteamaccess: store.state.currentProfileDetails.teamaccess,
    owninguser: store.state.currentProfileDetails.owninguser,
    commenttext: store.state.profilecommenttext,
    attachproject: store.state.attachprofilecommenttoproject,
    clientname: clientname,
    positiontitle: positiontitle,
    clientlogo: clientlogo,
    projectid: projectid,
    showtoclient: showtoclient,
    //we add the profileid so that we can bind the comments to the right profiles when rendering all profiles from a specific project in profilelist.vue
    profileid: profileid,
  })
    .then((commentRef2) => {
      const commentId = commentRef2.id;
      const newDocRef = doc(commentRef, commentId); // construct the document reference

      // Fetch the document
      return getDoc(newDocRef);
    })
    .then((commentSnap) => {
      //close the modal (if comment is added from profiledetails.vue)
      $("#addProfileComment").modal("hide");
      //close the dropdown (if comment is added from batchmanipulatebanner.vue)
      $("#batchaddcomment").dropdown("hide");



      //add comment to Lakeside/client if there is a Linked project.
      if (
        store.state.selectedProjectForCommentAttachment.haslinkedproject &&
        store.state.attachprofilecommenttoproject
      ) {
        // there is a linked project

        //Define the function
        const duplicateComment = async () => {
   

          // Prepare arguments
          const selectedproject =
            store.state.selectedProjectForCommentAttachment;
          const comment = commentSnap.data();
          const user = store.state.userData;
          const profile = store.state.currentProfileDetails;

          // Create callable reference to the cloud function
          const functions = getFunctions();
          const addCommentToDuplicatedProfile = httpsCallable(
            functions,
            "addCommentToDuplicatedProfile"
          );

          try {
            // Call the cloud function and get result
            const result = await addCommentToDuplicatedProfile({
              selectedproject,
              comment,
              user,
              profile,
            });

            if (result.data.success) {
        
            } else {
              console.error("Failed to add comment:", result.data.error);
            }
          } catch (error) {
            console.error("Error calling the function:", error);
          }
        };

        //Call the function
        duplicateComment();
      } else {
        //no linked project
      }

      //create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + profileid + "/activitylogs/"
      );

      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "New comment",
        byuser: store.state.currentUserId,
        owninguser: store.state.currentProfileDetails.owninguser,
        commenttext: store.state.profilecommenttext,
        attachproject: store.state.attachprofilecommenttoproject,
        clientname: clientname,
        positiontitle: positiontitle,
        clientlogo: clientlogo,
        projectid: projectid,
        commentid: commentSnap.id,
        profileid: profileid,
      }).then(() => {});
    })
    .then(() => {
      //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
      let profileRef = doc(db, "profiles", profileid);
      updateDoc(profileRef, {
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.currentUserId,
        latestcommenttext: store.state.profilecommenttext,
      }).then(() => {
        //if the current profile list stored in Vuex (currentProfileList) is a project profile list, then we need to update the array manually.
        //Because getProjectProfiles in getData.js does not listen to changes in the profiles collection, but in the projectprofilebindings subcollection
        //This also applies when showing searchResults as there is no realtime listener for this view
        if (
          store.state.showingProfilesAs == "projectProfiles" ||
          store.state.profilesViewInCache == "projectProfiles" ||
          store.state.showingProfilesAs == "searchResults" ||
          store.state.profilesViewInCache == "searchResults"
        ) {
          let currentProfileList = store.state.currentProfileList;
          let index = currentProfileList.findIndex((object) => {
            return object.id === profileid;
          });
          store.state.currentProfileList[index].latestcommenttext =
            store.state.profilecommenttext;
        }
      });
    });
};

const addProfileCommentFromClientExternal = (
  profile,
  commenttext,
  commentid
) => {
  //add the comment
  let commentRef = doc(
    db,
    "profiles/" + profile.id + "/profilecomments/",
    commentid
  );

  setDoc(commentRef, {
    timestamp: Date.now(),
    userwhocomments: store.state.userData.userid,
    userisaclient: true,
    readstatus: "unread",
    profileteamaccess: profile.teamaccess,
    owninguser: profile.owninguser,
    commenttext: commenttext,
    attachproject: true,
    clientname: store.state.currentProjectDetails.clientobject.clientname,
    positiontitle: store.state.currentProjectDetails.positiontitle,
    clientlogo: store.state.currentProjectDetails.clientobject.clientlogourl,
    projectid: store.state.currentProjectDetails.id,
    showtoclient: true,
    //we add the profileid so that we can bind the comments to the right profiles when rendering all profiles from a specific project in profilelist.vue
    profileid: profile.id,
  }).then((commentSnap) => {
    //confirmation notification goes here

    //add notification here

    //create activity log
    let activityLogsSubCollection = collection(
      db,
      "profiles/" + profile.id + "/activitylogs/"
    );

    addDoc(activityLogsSubCollection, {
      typeofdata: "profile",
      timestamp: Date.now(),
      typeofupdate: "New comment from Client",
      byuser: store.state.currentUserId,
      owninguser: profile.owninguser,
      commenttext: commenttext,
      attachproject: true,
      clientname: store.state.currentProjectDetails.clientobject.clientname,
      positiontitle: store.state.currentProjectDetails.positiontitle,
      clientlogo: store.state.currentProjectDetails.clientobject.clientlogourl,
      projectid: store.state.currentProjectDetails.id,
      profileid: profile.id,
    }).then(() => {});
  });
};

const changeSelectedProjectForCommentAttachementExternal = (
  projectid,
  clientname,
  clientlogo,
  positiontitle,
  linkedproject
) => {

  if (linkedproject) {
    store.state.selectedProjectForCommentAttachment.haslinkedproject = true;
  } else {
    store.state.selectedProjectForCommentAttachment.haslinkedproject = false;
  }

  store.state.selectedProjectForCommentAttachment.clientname = clientname;
  store.state.selectedProjectForCommentAttachment.positiontitle = positiontitle;
  store.state.selectedProjectForCommentAttachment.clientlogo = clientlogo;
  store.state.selectedProjectForCommentAttachment.projectid = projectid;
};

const changeSelectedProjectForCommentAttachementExternalInEditModal = (
  projectid,
  clientname,
  clientlogo,
  positiontitle
) => {
  let profileid = store.state.currentProfileDetails.id;

  let commentRef = doc(
    db,
    "profiles/" + profileid + "/profilecomments/",
    store.state.currentProfileComment.id
  );

  //give time to .attachproject to be updated in Vuex before updating in Firestore
  setTimeout(() => {
    updateDoc(commentRef, {
      clientlogo: clientlogo,
      clientname: clientname,
      projectid: projectid,
      positiontitle: positiontitle,
    }).then(() => {
      store.state.currentProfileComment.clientlogo = clientlogo;
      store.state.currentProfileComment.clientname = clientname;
      store.state.currentProfileComment.projectid = projectid;
      store.state.currentProfileComment.positiontitle = positiontitle;
    });
  }, 500);
};

const deleteProfileCommentExternal = (commentid, profileid) => {
  if (store.state.userData.userrole != "Administrator") {
    alert(
      "Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data."
    );
    return;
  }

  let profileId;

  if (profileid) {
    //delete action comes from Portal
    profileId = profileid;
  } else {
    //delete action comes from Profilelist
    profileId = store.state.currentProfileDetails.id;
  }

  deleteDoc(doc(db, "profiles/" + profileId + "/profilecomments/", commentid))
    .then(() => {
      //error is here
    })
    .catch((error) => {
      // Log error if deleteDoc fails
      console.log("Error deleting comment doc: ", error);
    })
    .then(() => {
      if (!window.location.href.includes("portal")) {
  
        let profileRef = doc(db, "profiles", profileId);


        //prevents store.state.currentProfileComments[0] from being undefined when there are no more comments, which would throw an error.
        let previousCommentText;
        if (store.state.currentProfileComments[0] != undefined) {
          previousCommentText =
            store.state.currentProfileComments[0].commenttext;
        } else {
          previousCommentText = "";
        }

        updateDoc(profileRef, {
          latestupdatetimestamp: Date.now(),
          latestupdatebyuser: store.state.currentUserId,
          //updates the latest comment with the previous one. this array is already sliced in the ProfileList.vue component before this function is called
          //so we can just pick the first (most recent) item from the sliced array here
          latestcommenttext: previousCommentText,
        }).then(() => {
          //when batch adding comments within a project (showing projectProfiles), the realtime listener
          //listens to the binding collection, not the profiles collection.
          //therefore we need to update the opentaskcounter in Vuex manually
          //This also applies to searchResults as there is no realtime listener for it
          if (
            store.state.profilesViewInCache == "projectProfiles" ||
            store.state.profilesViewInCache == "searchResults"
          ) {
            let index = store.state.currentProfileList.findIndex((object) => {
              return object.id === profileid;
            });
            store.state.currentProfileList[index].latestcommenttext = "";
          }
        });
      }
    });
};

const addProfileTaskExternal = (profileid) => {


  let tasksscollection = collection(
    db,
    "profiles/" + profileid + "/profiletasks/"
  );
  addDoc(tasksscollection, {
    text: store.state.profiletasktext,
    duedate: store.state.currentprofiletaskduedate,
    completed: false,
    profileid: profileid,
    assigneduserobj: store.state.currentprofiletaskassigneduser,
    byuser: store.state.userData.userid,
    readstatus: "unread",
    timestamp: Date.now(),
  })
    .then(() => {
      //if the click came from the modal in ProfileDetails, close the modal
      if (origin == "profiledetails") {
        $("#addProfileTask").modal("toggle");
      }
      //if click came from the dropdown in BatchManiuplateBanner, close the dropdown
      else if (origin == "batchmanipulatebanner") {
        $("#batchaddtask").dropdown("hide");
      }

      //confirmation notification goes here

      //create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
      );

      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "New task",
        byuser: store.state.userData.userid,
        //owninguser: store.state.currentProfileDetails.owninguser, --> is this useful? Commented out because it would break when called from BatchManipulatebanner, as there are multiple profiles and not just the currenProfileDetails
        text: store.state.profiletasktext,
        duedate: store.state.currentprofiletaskduedate,
        commenttext: store.state.profiletasktext,
        duedate: store.state.currentprofiletaskduedate,
        assigneduserobj: store.state.currentprofiletaskassigneduser,
        profileid: profileid,
      }).then(() => {});
    })
    .then(() => {
      /*
      //in order to update the open tasks counter in the profile document (to show in ProfileList.vue),
      //we need to make sure that the Vuex ProfileDetails data is synced with Firestore, as there is no realtime listener attached
      //to the profile details.

      const originalTaskList = store.state.currentProfileTasks;
      const results = originalTaskList.filter((obj) => {
        return (
          obj.assigneduserobj.userid === store.state.userData.userid && !obj.completed
        );
      });
      const currentOpenTasks = results.length;
      */

      //Get current Profile document to retrieve the opentaskscounter
      let profileRef = doc(db, "profiles", profileid);
      let tasksCount;
      getDoc(profileRef)
        .then((profileSnap) => {
          tasksCount = profileSnap.data().opentaskscounter;

          //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
          //and also update the tasks counter
          updateDoc(profileRef, {
            latestupdatetimestamp: Date.now(),
            latestupdatebyuser: store.state.currentUserId,
            opentaskscounter: tasksCount + 1,
          });
        })
        .then(() => {
          //when batch adding tasks within a project (showing projectProfiles), the realtime listener
          //listens to the binding collection, not the profiles collection.
          //therefore we need to update the opentaskcounter in Vuex manually
          //This also applies to searchResults as there is no realtime listener for it
          if (
            (origin == "batchmanipulatebanner" &&
              (store.state.showingProfilesAs == "projectProfiles" ||
                store.state.showingProfilesAs == "searchResults")) ||
            (origin != "batchmanipulatebanner" &&
              (store.state.profilesViewInCache == "projectProfiles" ||
                store.state.profilesViewInCache == "searchResults"))
          ) {
            let index = store.state.currentProfileList.findIndex((object) => {
              return object.id === profileid;
            });
            store.state.currentProfileList[index].opentaskscounter =
              tasksCount + 1;
          }
        });
    });
};

const handleProfileTaskCheckBoxExternal = (taskobject) => {
  let profileid = taskobject.profileid;

  //before the click the task was completed, so now store it as not completed in Firestore
  if (taskobject.completed) {
    const profileTaskRef = doc(
      db,
      "profiles/" + profileid + "/profiletasks",
      taskobject.id
    );

    updateDoc(profileTaskRef, {
      completed: false,
      readstatus: "read",
    }).then(() => {
      //confirmation alert goes here
    });
  }
  //before the click the task was uncompleted, so now store it as completed in Firestore
  else if (!taskobject.completed) {
    const profileTaskRef = doc(
      db,
      "profiles/" + profileid + "/profiletasks",
      taskobject.id
    );

    updateDoc(profileTaskRef, {
      completed: true,
    }).then(() => {
      //confirmation alert goes here

      //create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
      );

      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "Task completed",
        byuser: store.state.currentUserId,
        owninguser: store.state.userData.userid,
        duedate: store.state.currentprofiletaskduedate,
        commenttext: taskobject.text,
        profileid: profileid,
      })
        .then(() => {
          //in order to update the open tasks counter in the profile document (to show in ProfileList.vue),
          //we need to make sure that the Vuex ProfileDetails data is synced with Firestore, as there is no realtime listener attached
          //to the profile details.

          const originalTaskList = store.state.currentProfileTasks;
          const results = originalTaskList.filter((obj) => {
            return (
              obj.assigneduserobj.userid === store.state.userData.userid &&
              !obj.completed
            );
          });
          const currentOpenTasks = results.length;

          //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
          let profileRef = doc(db, "profiles", profileid);
          updateDoc(profileRef, {
            latestupdatetimestamp: Date.now(),
            latestupdatebyuser: store.state.currentUserId,
            opentaskscounter: currentOpenTasks,
          });
        })
        .then(() => {
          //when showing projectProfiles, the realtime listener
          //listens to the binding collection, not the profiles collection.
          //therefore we need to update the opentaskcounter in Vuex manually
          //so that when the user returns from profiledetails to profilelist,
          //the profile line will be updated with one task less in the counter
          //This also applies when showing searchResults as there is no realtime listener for this view
          if (
            store.state.profilesViewInCache == "projectProfiles" ||
            store.state.profilesViewInCache == "searchResults"
          ) {
            let index = store.state.currentProfileList.findIndex((object) => {
              return object.id === profileid;
            });
            store.state.currentProfileList[index].opentaskscounter =
              store.state.currentProfileList[index].opentaskscounter - 1;
          }
        });
    });
  }
};

const deleteProfileTaskExternal = (taskid) => {
  if (store.state.userData.userrole != "Administrator") {
    alert(
      "Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data."
    );
    return;
  }

  const profileid = store.state.currentProfileDetails.id;

  deleteDoc(doc(db, "profiles/" + profileid + "/profiletasks/", taskid))
    .then(() => {
      //in order to update the open tasks counter in the profile document (to show in ProfileList.vue),
      //we need to make sure that the Vuex ProfileDetails data is synced with Firestore, as there is no realtime listener attached
      //to the profile details.

      const originalTaskList = store.state.currentProfileTasks;
      const results = originalTaskList.filter((obj) => {
        return (
          obj.assigneduserobj.userid === store.state.userData.userid &&
          !obj.completed
        );
      });
      const currentOpenTasks = results.length;

      //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
      let profileRef = doc(db, "profiles", profileid);
      updateDoc(profileRef, {
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.currentUserId,
        opentaskscounter: currentOpenTasks,
      });
    })
    .then(() => {
      //when showing projectProfiles, the realtime listener
      //listens to the binding collection, not the profiles collection.
      //therefore we need to update the opentaskcounter in Vuex manually
      //so that when the user returns from profiledetails to profilelist,
      //the profile line will be updated with one task less in the counter
      //This also applies when showing searchResults as there is no realtime listener for this view
      if (
        store.state.profilesViewInCache == "projectProfiles" ||
        store.state.profilesViewInCache == "searchResults"
      ) {
        let index = store.state.currentProfileList.findIndex((object) => {
          return object.id === profileid;
        });
        store.state.currentProfileList[index].opentaskscounter =
          store.state.currentProfileList[index].opentaskscounter - 1;
      }
    });
};

//this assigns a user to a task in the Add task modal, so before the task is created
//WARNING: when a user reassigns a task to a different user than themself, then the Profile doc is not updated (opentaskscounter).
//This means there could in some rare cases be a gap between the number in ProfileList and ProfileDetails. It is a minor problem,
//to be left like this for now.
const reAssignUserExternal = (user) => {
  store.state.currentprofiletaskassigneduser = user;
};

//this assigns a new user to a task in the Task card in ProfileDetails.vue, so it updates the task after it has been created
const reAssignUserAfterAddExternal = (user, task) => {
  const profileid = task.profileid;

  let userRef = doc(db, "profiles/" + profileid + "/profiletasks/", task.id);
  updateDoc(userRef, {
    assigneduserobj: user,
  })
    .then(() => {
      //create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + profileid + "/activitylogs/"
      );

      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "Task reassigned",
        byuser: store.state.userData.userid,
        owninguser: store.state.userData.userid,
        commenttext: task.text,
        attachproject: false,
        assigneduserobj: user,
        profileid: profileid,
      });
    })
    .then(() => {
      //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
      let profileRef = doc(db, "profiles", profileid);
      updateDoc(profileRef, {
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.userData.userid,
      });

      //close the dropdown
      $("#batchaddtaskdropdown").dropdown("close");
    });
};

const addProfileTagExternal = (tagvalue) => {
  let profileTags = store.state.currentProfileDetails.tags;

  if (profileTags.includes(tagvalue)) {
    //for some strange reason, when updating the profile status in a project / profile binding, it adds one of the tags to the taglist.
    //this prevents this behaviour
    return false;
  } else {
    //Push new tag to Vuex data
    profileTags.push(tagvalue);

    //Update Firestore with the updated Vuex array
    let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
    updateDoc(profileRef, {
      tags: profileTags,
    });
  }
};

const deleteProfileTagExternal = (tagvalue, profileid) => {
  let profileTags = store.state.currentProfileDetails.tags;

  //In the profile tags, find the tag to be removed
  let index = profileTags.indexOf(tagvalue);

  //Remove the Tag from Vuex
  profileTags.splice(index, 1);

  //Update Firestore with the updated Vuex array
  let profileRef = doc(db, "profiles", profileid);
  updateDoc(profileRef, {
    tags: profileTags,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("tags");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const uploadAttachmentFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  const accessToken = store.state.userData.nylasemailtoken;

  try {
    const response = await axios({
      method: "post",
      url: "https://api.nylas.com/files",
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });


    return response.data[0].id; // the data seems to be an array, so we take the first element's id
  } catch (error) {
    console.error("Error uploading file:", error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error details:", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error setting up request:", error.message);
    }
  }
};

const sendEmailExternal = async (profileObject, emailSubject, emailBody) => {
  let emailsoutcollection = collection(
    db,
    "users/" + store.state.userData.id + "/emailsout/"
  );
  addDoc(emailsoutcollection, {
    emailto: profileObject.email,
    emailfrom: store.state.userData.email,
    emailsubject: emailSubject,
    emailbody: emailBody,
    timestamp: Date.now(),
  })
    .then(() => {
      $("#sendingemail").modal("show");

      // Create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
      );
      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "New email sent",
        byuser: store.state.currentUserId,
        owninguser: profileObject.owninguser,
        commenttext: emailSubject,
        profileid: store.state.currentProfileDetails.id,
      }).then(() => {
        if (store.state.showingProfilesAs == "profileDetails") {
          getSingleProfileExternal(store.state.currentProfileDetails.id);
        }
      });
    })
    .then(() => {
      // Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
      let profileid = profileObject.id;
      let profileRef = doc(db, "profiles", profileid);
      updateDoc(profileRef, {
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.currentUserId,
      });
    })
    .then(async () => {
      const file_id = await uploadAttachmentFile(store.state.fileToSend);

      // Send email using Nylas API
      const nylasEndpoint = "https://api.nylas.com/send";
      const accessToken = store.state.userData.nylasemailtoken; // Replace with the actual Nylas access token

      // Then, send the email with the uploaded file id

      let emailData;

      if (store.state.fileToSend) {
        emailData = {
          subject: emailSubject,
          to: [
            {
              email: profileObject.email,
              name: profileObject.firstname + " " + profileObject.lastname,
            },
          ],
          from: [
            {
              email: store.state.userData.email,
              name:
                store.state.userData.firstname +
                " " +
                store.state.userData.lastname,
            },
          ],
          reply_to: [
            {
              name:
                store.state.userData.firstname +
                " " +
                store.state.userData.lastname,
              email: store.state.userData.email,
            },
          ],
          body: emailBody,
          file_ids: [file_id], // use the file id from the upload response
        };
      } else {
        emailData = {
          subject: emailSubject,
          to: [
            {
              email: profileObject.email,
              name: profileObject.firstname + " " + profileObject.lastname,
            },
          ],
          from: [
            {
              email: store.state.userData.email,
              name:
                store.state.userData.firstname +
                " " +
                store.state.userData.lastname,
            },
          ],
          reply_to: [
            {
              name:
                store.state.userData.firstname +
                " " +
                store.state.userData.lastname,
              email: store.state.userData.email,
            },
          ],
          body: emailBody,
        };
      }

      axios
        .post(nylasEndpoint, emailData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          $("#sendingemail").modal("hide");
          // Confirmation toast
          iziToast.show({
            title: "Email sent !",
            backgroundColor: "#47be7d",
            titleColor: "white",
            theme: "dark",
            position: "bottomRight",
            timeout: 3000,
            icon: "fa fa-check",
          });
        })
        .catch((error) => {
          console.error("Error sending email via Nylas:", error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("Error details:", error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.error("No response received:", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error setting up request:", error.message);
          }
        });
    });
};

const markAsReadExternal = (email) => {
  let readEmailRef = doc(
    db,
    "users/" + store.state.userData.id + "/emailsin/",
    email.id
  );
  updateDoc(readEmailRef, {
    readstatus: "read",
  }).then(() => {
    //Get current Profile document to retrieve the newemailscounter
    let profileRef = doc(db, "profiles/" + email.profileid);
    let newEmailsCount;
    getDoc(profileRef).then((profileSnap) => {
      //update newemailscounter in the Profile doc
      newEmailsCount = profileSnap.data().newemailscounter;
      updateDoc(profileRef, {
        newemailscounter: newEmailsCount - 1,
      });
    });
  });
};

//used in the Email.vue modal, the user has the option to mark as unread, useful when user wants to keep emails in the notifications after opening
const markAsUnreadExternal = (email) => {


  let readEmailRef = doc(
    db,
    "users/" + store.state.userData.id + "/emailsin/",
    email.id
  );
  updateDoc(readEmailRef, {
    readstatus: "unread",
  }).then(() => {
    //Get current Profile document to retrieve the newemailscounter
    let profileRef = doc(db, "profiles/" + email.profileid);
    let newEmailsCount;
    getDoc(profileRef).then((profileSnap) => {
      //update newemailscounter in the Profile doc
      newEmailsCount = profileSnap.data().newemailscounter;
      updateDoc(profileRef, {
        newemailscounter: newEmailsCount + 1,
      });
    });
  });
};

const deleteThiEmailExternal = () => {
  const emailid = store.state.currentEmailMessage.id;

  //if it is an email "out"
  if (store.state.currentEmailMessage.emailfrom == store.state.userData.email) {
    deleteDoc(
      doc(db, "users/" + store.state.userData.id + "/emailsout", emailid)
    ).then(() => {
      //close the modal
      $("#email").modal("toggle");

      //confirmation goes here
    });
  }

  //if it is an email "in"
  if (
    store.state.currentEmailMessage.emailfrom ==
    store.state.currentProfileDetails.email
  ) {
    deleteDoc(
      doc(db, "users/" + store.state.userData.id + "/emailsin", emailid)
    ).then(() => {
      //close the modal
      $("#email").modal("toggle");

      //confirmation goes here
    });
  }
};

const saveSignatureExternal = () => {
  //close the modal
  $("#editSignature").modal("toggle");

  //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
  let userRef = doc(db, "users", store.state.userData.id);
  updateDoc(userRef, {
    emailsignature: store.state.userData.emailsignature,
  }).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Email signature updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

//this saves the profile email address from the Emails card in ProfileDetails.vue. The email can be added in the Profile details asweell. This is a more obvious way, a shortcut
const saveProfileEmailExternal = (profileemailinput) => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  if (!profileemailinput.includes(".") || !profileemailinput.includes("@")) {
    alert("Please enter a valid email address.");
    return;
  }
  updateDoc(profileRef, {
    email: profileemailinput,
  }).then(() => {
    //When a profile is loaded in store.state.currentProfileDetails, it is not a realtime snapshot, it does not update dynamically, contrary to most other getData functions.
    //So we need to update the Vuex value manually here so it shows on the front end
    store.state.currentProfileDetails.email = profileemailinput;

    getSingleProfileExternal(store.state.currentProfileDetails.id);
  });
};



//switches the client visibility status of the profile. should the client see this profile?
const switchClientVisibilityExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    clientvisible: !store.state.currentProfileDetails.clientvisible,
  })
    .then(() => {
      //create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
      );

      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "Switched client visibility",
        byuser: store.state.currentUserId,
        owninguser: store.state.currentProfileDetails.owninguser,
        profileid: store.state.currentProfileDetails.id,
      });
    })
    .then(() => {
      //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
      let profileRef = doc(
        db,
        "profiles",
        store.state.currentProfileDetails.id
      );
      updateDoc(profileRef, {
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.currentUserId,
      });
    });
};

const switchClientVisibilityFromPortalModalExternal = (profile) => {
  let profileRef = doc(db, "profiles", profile.id);
  updateDoc(profileRef, {
    clientvisible: !profile.clientvisible,
  });
};

//switches the teamacces status of the profile. should the other teammembers see this profile?
const switchTeamAccessExternal = () => {
  //first, check that the user is the owning user (creator) of this profile
  if (
    store.state.userData.userid != store.state.currentProfileDetails.owninguser
  ) {
    alert(
      "It seems you're trying to update the team access settings for a profile that was created by a colleague, not by you. Only the original creator of a profile has the authorization to change its team access settings. Please reach out to the profile owner for any modifications"
    );
    //restore the swith button as it will be locally switched but the click.
    setTimeout(() => {
      store.state.currentProfileDetails.teamaccess = true;
    }, 500);
    return;
  }

  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    teamaccess: !store.state.currentProfileDetails.teamaccess,
  })
    .then(() => {
      //create activity log
      let activityLogsSubCollection = collection(
        db,
        "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
      );

      addDoc(activityLogsSubCollection, {
        typeofdata: "profile",
        timestamp: Date.now(),
        typeofupdate: "Switched teamaccess",
        byuser: store.state.currentUserId,
        owninguser: store.state.currentProfileDetails.owninguser,
        profileid: store.state.currentProfileDetails.id,
      });
    })
    .then(() => {
      //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
      let profileRef = doc(
        db,
        "profiles",
        store.state.currentProfileDetails.id
      );
      updateDoc(profileRef, {
        latestupdatetimestamp: Date.now(),
        latestupdatebyuser: store.state.currentUserId,
      });
    });
};

const updateCandidateOrClientSwitchExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);

  //delay to make sure the value has updated in Vuex before sending it to Firestore
  setTimeout(() => {
    updateDoc(profileRef, {
      candidateorclient: store.state.currentProfileDetails.candidateorclient,
    })
      .then(() => {
        //create activity log
        let activityLogsSubCollection = collection(
          db,
          "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
        );

        addDoc(activityLogsSubCollection, {
          typeofdata: "profile",
          timestamp: Date.now(),
          typeofupdate: "Updated profile type to: ",
          commenttext: store.state.currentProfileDetails.candidateorclient,
          byuser: store.state.currentUserId,
          owninguser: store.state.currentProfileDetails.owninguser,
          profileid: store.state.currentProfileDetails.id,
        });
      })
      .then(() => {
        //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
        let profileRef = doc(
          db,
          "profiles",
          store.state.currentProfileDetails.id
        );
        updateDoc(profileRef, {
          latestupdatetimestamp: Date.now(),
          latestupdatebyuser: store.state.currentUserId,
          latestbindingclientlogo: "",
          latestbindingclientname: "",
          latestbindingprojectid: "",
        });
      });
  }, 500);
};

const updateGdprStatusExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);

  //delay to make sure the value has updated in Vuex before sending it to Firestore
  setTimeout(() => {
    updateDoc(profileRef, {
      gdprconsentstatus: store.state.currentProfileDetails.gdprconsentstatus,
    })
      .then(() => {
        //create activity log
        let activityLogsSubCollection = collection(
          db,
          "profiles/" + store.state.currentProfileDetails.id + "/activitylogs/"
        );

        addDoc(activityLogsSubCollection, {
          typeofdata: "profile",
          timestamp: Date.now(),
          typeofupdate: "Updated GDPR status to : ",
          commenttext: store.state.currentProfileDetails.gdprconsentstatus,
          byuser: store.state.currentUserId,
          owninguser: store.state.currentProfileDetails.owninguser,
          profileid: store.state.currentProfileDetails.id,
        });
      })
      .then(() => {
        //update locally first as getSingleProfile function in profiledetails.js doesn't update in realtime
        store.state.currentProfileDetails.gdprconsentdate = Date.now();

        //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
        let profileRef = doc(
          db,
          "profiles",
          store.state.currentProfileDetails.id
        );
        updateDoc(profileRef, {
          latestupdatetimestamp: Date.now(),
          latestupdatebyuser: store.state.currentUserId,
          gdprconsentdate: Date.now(),
        });
      });
  }, 500);
};

//called from ProfileDetails/IdCard/ProfileId. Sends the new firstname in input to Firestore
const modifyFirstNameExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    firstname: store.state.currentProfileDetails.firstname,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("firstname");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//called from ProfileDetails/IdCard/ProfileId. Sends the new lastname in input to Firestore
const modifyLastNameExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    lastname: store.state.currentProfileDetails.lastname,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("lastname");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//called from ProfileDetails/IdCard/ProfileId. Sends the new lastname in input to Firestore
const modifyTitleExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    currentjobtitle: store.state.currentProfileDetails.currentjobtitle,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("currentjobtitle");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//called from ProfileDetails/IdCard/ProfileId. Sends the new lastname in input to Firestore
const modifyCompanyExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    currentcompany: store.state.currentProfileDetails.currentcompany,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("currentcompany");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//called from ProfileDetails/IdCard/ProfileId. Sends the new lastname in input to Firestore
const modifyPhoneExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    mobilenumber: store.state.currentProfileDetails.mobilenumber,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("mobilenumber");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//called from ProfileDetails/IdCard/ProfileId. Sends the new lastname in input to Firestore
const modifyEmailExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    email: store.state.currentProfileDetails.email,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("email");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//called from ProfileDetails/IdCard/ProfileId. Sends the new lastname in input to Firestore
const modifyLocationExternal = () => {
  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    location: store.state.currentProfileDetails.location,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("location");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const deleteProExperienceExternal = () => {
  if (store.state.userData.userrole != "Administrator") {
    alert(
      "Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data."
    );
    return;
  }

  let profileid = store.state.currentProfileDetails.id;

  let profileRef = doc(db, "profiles", profileid);
  updateDoc(profileRef, {
    experiencelist: store.state.currentProfileDetails.experiencelist,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("experiencelist");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const deleteEducationExternal = () => {
  let profileid = store.state.currentProfileDetails.id;

  let profileRef = doc(db, "profiles", profileid);
  updateDoc(profileRef, {
    educationlist: store.state.currentProfileDetails.educationlist,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("experiencelist");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//this updates the checkbox used to determine whether the comment should be visible to the client or not
const updateShowToClientCheckboxExternal = (commentid, showtoclient) => {
  let profileid = store.state.currentProfileDetails.id;

  let commentRef = doc(
    db,
    "profiles/" + profileid + "/profilecomments/",
    commentid
  );
  updateDoc(commentRef, {
    showtoclient: !showtoclient,
  }).then(() => {});
};

const toggleattachcommentineditmodalExternal = () => {
  let profileid = store.state.currentProfileDetails.id;

  let commentRef = doc(
    db,
    "profiles/" + profileid + "/profilecomments/",
    store.state.currentProfileComment.id
  );

  //give time to .attachproject to be updated in Vuex before updating in Firestore
  setTimeout(() => {
    updateDoc(commentRef, {
      attachproject: store.state.currentProfileComment.attachproject,
    }).then(() => {});
  }, 500);
};

const updateCommentTextExternal = () => {
  let profileid = store.state.currentProfileDetails.id;

  let commentRef = doc(
    db,
    "profiles/" + profileid + "/profilecomments/",
    store.state.currentProfileComment.id
  );

  updateDoc(commentRef, {
    commenttext: store.state.currentProfileComment.commenttext,
  }).then(() => {
    //Update "lastupdatetimestamp" in the Profile document in Firestore (mainly for sorting purposes --> get recent profiles)
    let profileRef = doc(db, "profiles", profileid);
    updateDoc(profileRef, {
      latestupdatetimestamp: Date.now(),
      latestupdatebyuser: store.state.currentUserId,
      latestcommenttext: store.state.currentProfileComment.commenttext,
    }).then(() => {
      //if the current profile list stored in Vuex (currentProfileList) is a project profile list, then we need to update the array manually.
      //Because getProjectProfiles in getData.js does not listen to changes in the profiles collection, but in the projectprofilebindings subcollection
      //This also applies for searchResults as there is not realtime listener attached in this case
      if (
        store.state.showingProfilesAs == "projectProfiles" ||
        store.state.profilesViewInCache == "projectProfiles" ||
        store.state.showingProfilesAs == "searchResults" ||
        store.state.profilesViewInCache == "searchResults"
      ) {
        let currentProfileList = store.state.currentProfileList;
        let index = currentProfileList.findIndex((object) => {
          return object.id === profileid;
        });
        store.state.currentProfileList[index].latestcommenttext =
          store.state.currentProfileComment.commenttext;
      }
    });
  });

  //close the modal
  $("#editProfileComment").modal("hide");
};

const assignClientToProfileExternal = (clientid) => {
  let clientlist = store.state.currentClientList;

  let filteredclientlist = clientlist.filter((obj) => {
    //first check if the comment has a project reference
    return obj.id === clientid;
  });

  const client = filteredclientlist[0];

  let profileid = store.state.currentProfileDetails.id;

  let profileRef = doc(db, "profiles", profileid);

  updateDoc(profileRef, {
    clientname: client.clientname,
    clientlogo: client.clientlogourl,
    clientlocation: client.location,
    clientstatus: client.clientstatus,
  }).then(() => {
    store.state.currentProfileDetails.clientname = client.clientname;
    store.state.currentProfileDetails.clientlogo = client.clientlogourl;
    (store.state.currentProfileDetails.clientlocation = client.location),
      (store.state.currentProfileDetails.clientstatus = client.clientstatus);

    //Confirmation toast
    iziToast.show({
      title: "Added to client !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });

  //close the modal
  //$('#editProfileComment').modal('hide')
};

const deleteProfileExternal = (profileid) => {
  //show confirmation modal only if click came from profile details. if it comes from Batchmanipulatebanner,
  //another modal is shown in Batchmanipulatebanner.vue
  if (store.state.showingProfilesAs == "profileDetails") {
    if (store.state.userData.userrole != "Administrator") {
      alert(
        "Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data."
      );
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to delete this profile?"
    );

    if (!confirmation) {
      return; // Exit the function if the user clicks "Cancel"
    }
  }

  const qProfileProjectBindings = query(
    collection(db, "profiles/" + profileid + "/profileprojectbindings")
  );
  getDocs(qProfileProjectBindings).then((bindingsSnapshot) => {
    bindingsSnapshot.forEach((bindingDoc) => {
      //store the project id before the binding is deleted. this will allow us to update the Metrics counters in projectdetails
      let projectid = bindingDoc.data().projectid;

      deleteDoc(
        doc(
          db,
          "profiles/" + profileid + "/profileprojectbindings",
          bindingDoc.id
        )
      ).then(() => {
        //now that the binding is deleted, update the profiles count for the Metrics component in profiledetails

        let numberOfPotentialCandidates;
        let numberOfDiscarded;
        let numberOfInProgress;
        let numberOfNoStatus;

        const qNumberOfPotentialCandidates = query(
          collectionGroup(db, "profileprojectbindings"),
          where("projectid", "==", projectid),
          where("profilestatuscategory", "==", "Interested")
        );
        getCountFromServer(qNumberOfPotentialCandidates).then(
          (potentialsSnap) => {
            numberOfPotentialCandidates = potentialsSnap.data().count;

            const qNumberOfDiscarded = query(
              collectionGroup(db, "profileprojectbindings"),
              where("projectid", "==", projectid),
              where("profilestatuscategory", "==", "Discarded")
            );
            getCountFromServer(qNumberOfDiscarded)
              .then((discardedSnap) => {
                numberOfDiscarded = discardedSnap.data().count;

                const qnumberOfInProgress = query(
                  collectionGroup(db, "profileprojectbindings"),
                  where("projectid", "==", projectid),
                  where("profilestatuscategory", "==", "In progress")
                );
                getCountFromServer(qnumberOfInProgress)
                  .then((inProgressSnap) => {
                    numberOfInProgress = inProgressSnap.data().count;

                    const qnumberOfNoStatus = query(
                      collectionGroup(db, "profileprojectbindings"),
                      where("projectid", "==", projectid),
                      where("profilestatuscategory", "==", "no status")
                    );
                    getCountFromServer(qnumberOfNoStatus)
                      .then((noStatusSnap) => {
                        numberOfNoStatus = noStatusSnap.data().count;

                        let projectRef = doc(db, "projects", projectid);

                        //now that we have the numbers, update the project profiles counts
                        updateDoc(projectRef, {
                          numberofpotentialcandidates:
                            numberOfPotentialCandidates,
                          numberofdiscarded: numberOfDiscarded,
                          numberofinprogress: numberOfInProgress,
                          numberofnostatus: numberOfNoStatus,
                        }).then(() => {
                          //project updated
                        });
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        );
      });
    });
  });

  const qProfileComments = query(
    collection(db, "profiles/" + profileid + "/profilecomments")
  );
  getDocs(qProfileComments).then((commentSnapshot) => {
    commentSnapshot.forEach((commentDoc) => {
      deleteDoc(
        doc(db, "profiles/" + profileid + "/profilecomments", commentDoc.id)
      ).then(() => {});
    });
  });

  const qProfileActivityLogs = query(
    collection(db, "profiles/" + profileid + "/activitylogs")
  );
  getDocs(qProfileActivityLogs).then((activityLogSnapshot) => {
    activityLogSnapshot.forEach((activityLogDoc) => {
      deleteDoc(
        doc(db, "profiles/" + profileid + "/activitylogs", activityLogDoc.id)
      ).then(() => {});
    });
  });

  deleteDoc(doc(db, "profiles", profileid)).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Profile deleted !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    if (store.state.showingProfilesAs == "profileDetails") {
      store.state.showingProfilesAs = store.state.profilesViewInCache;

      //update number of profiles count for the stats component in projectdetails

      //delete from profilelist in Vuex (no realtime listener on search results)
      const profileIdToDelete = profileid;
      const index = store.state.currentProfileList.findIndex(
        (profile) => profile.id === profileIdToDelete
      );
      if (index !== -1) {
        store.state.currentProfileList.splice(index, 1);
      }
    } else {
      //delete from profilelist in Vuex (no realtime listener on search results)
      const profileIdToDelete = profileid;
      const index = store.state.currentProfileList.findIndex(
        (profile) => profile.id === profileIdToDelete
      );
      if (index !== -1) {
        store.state.currentProfileList.splice(index, 1);
      }
    }
  });
};

const uploadCvExternal = () => {
  const file = document.getElementById("uploadCVButton").files[0];
  const filetype = file.name.split(".")[1];

  if (
    filetype != "doc" &&
    filetype != "docx" &&
    filetype != "pdf" &&
    filetype != "xls" &&
    filetype != "xlsx" &&
    filetype != "ppt" &&
    filetype != "pptx"
  ) {
    alert(
      "This file type is not supported. Please upload one of the following file types: Word, Excel, CSV, PowerPoint or PDF."
    );
    returnd;
  }

  const storage = getStorage();
  const storageRef = ref(
    storage,
    "userid" +
      store.state.userData.userid +
      "cvforprofile" +
      store.state.currentProfileDetails.id +
      "filename" +
      file.name
  );

  $("#uploadingfilealert").modal("show");

  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, file).then((snapshot) => {

    getDownloadURL(storageRef)
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        /*
            // This can be downloaded directly:
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            */
        const profileRef = doc(
          db,
          "profiles",
          store.state.currentProfileDetails.id
        );

        updateDoc(profileRef, {
          cvfile: {
            name: file.name,
            url: url,
            type: filetype,
            id: Math.floor(Math.random() * 100000000),
          },
        });

        //as there is not realtime listener attached to currentProfileDetails, we need to update the Vuex data manually
        //with the uploaded CV
        //Important to note that the ID here is different than the ID assigned in the Firestore doc, as here another ID is
        //generated again. Does not seem to be a problem as ID is used only to distinguish files in the Projects and Clients v-for
        //directive.

        store.state.currentProfileDetails.cvfile = {
          name: file.name,
          url: url,
          type: filetype,
          id: Math.floor(Math.random() * 100000000),
        };

        setTimeout(() => {
          $("#uploadingfilealert").modal("hide");

          //Confirmation toast
          iziToast.show({
            title: "CV added !",
            backgroundColor: "#47be7d",
            titleColor: "white",
            theme: "dark",
            position: "bottomRight",
            timeout: 3000,
            icon: "fa fa-check",
          });


          //If there is a linked client or lakeside profile, update it/them
          if (store.state.currentProfileDetails.crossaccountid) {
            setTimeout(() => {
              updateLinkedProfiles(store.state.currentProfileDetails.id);
              
            }, 1000);
          } 


        }, 700);


      })
      .catch((error) => {
        // Handle any errors
      });
  });
};

const deleteCvExternal = () => {
  //IMPORTANT: here we need to actually delete the file from Firebase storage. For the moment this function only sets the CV Url field to false in Firestore.

  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    cvfile: false,
  }).then(() => {
    //Update Vuex manually as there is no realtime listener attached
    //to currentProfileDetails
    store.state.currentProfileDetails.cvfile = false;
    //Confirmation toast
    iziToast.show({
      title: "CV deleted !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });


          //If there is a linked client or lakeside profile, update it/them
          if (store.state.currentProfileDetails.crossaccountid) {
            setTimeout(() => {
              updateLinkedProfiles(store.state.currentProfileDetails.id);
              
            }, 1000);
          } 
  });
};

const uploadFileExternal = () => {
  const file = document.getElementById("uploadFileButton").files[0];
  const filetype = file.name.split(".")[1];

  if (
    filetype != "doc" &&
    filetype != "docx" &&
    filetype != "pdf" &&
    filetype != "xls" &&
    filetype != "xlsx" &&
    filetype != "ppt" &&
    filetype != "pptx"
  ) {
    alert(
      "This file type is not supported. Please upload one of the following file types: Word, Excel, CSV, PowerPoint or PDF."
    );
    returnd;
  }

  const storage = getStorage();
  const storageRef = ref(
    storage,
    "userid" +
      store.state.userData.userid +
      "fileforprofile" +
      store.state.currentProfileDetails.id +
      "filename" +
      file.name
  );

  $("#uploadingfilealert").modal("show");

  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, file).then((snapshot) => {

    getDownloadURL(storageRef)
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        const profileRef = doc(
          db,
          "profiles",
          store.state.currentProfileDetails.id
        );

        const fileId = Math.floor(Math.random() * 100000000);

        updateDoc(profileRef, {
          profilefiles: arrayUnion({
            name: file.name,
            url: url,
            type: filetype,
            id: fileId,
          }),
        });

        //as there is no realtime listener attached to currentProfileDetails, we need to update the Vuex data manually
        //with the uploaded file

        //create the array if it doesn't exist, otherwise .push will throw an error
        if (!store.state.currentProfileDetails.profilefiles) {
          store.state.currentProfileDetails.profilefiles = [];
        }

        //add to vuex
        store.state.currentProfileDetails.profilefiles.push({
          name: file.name,
          url: url,
          type: filetype,
          id: fileId,
        });

        setTimeout(() => {
          $("#uploadingfilealert").modal("hide");

          //Confirmation toast
          iziToast.show({
            title: "File added !",
            backgroundColor: "#47be7d",
            titleColor: "white",
            theme: "dark",
            position: "bottomRight",
            timeout: 3000,
            icon: "fa fa-check",
          });
        }, 700);

        
          //If there is a linked client or lakeside profile, update it/them
          if (store.state.currentProfileDetails.crossaccountid) {
            setTimeout(() => {
              updateLinkedProfiles(store.state.currentProfileDetails.id);
              
            }, 1000);
          } 
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });
};

const deleteFileExternal = (file) => {
  //IMPORTANT: here we need to actually delete the file from Firebase storage.

  let profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);
  updateDoc(profileRef, {
    profilefiles: arrayRemove(file),
  }).then(() => {
    // Remove the item from Vuex as there is no realtime listener attached to profile details

    // Find the index of the item to be removed
    const index = store.state.currentProfileDetails.profilefiles.findIndex(
      (f) => f.id === file.id
    );
    //delete the file ref from vuex
    if (index > -1) {
      store.state.currentProfileDetails.profilefiles.splice(index, 1);
    }

    //Update Vuex manually as there is no realtime listener attached
    //to currentProfileDetails
    store.state.currentProfileDetails.cvfile = false;
    //Confirmation toast
    iziToast.show({
      title: "File deleted !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

const saveExperienceExternal = () => {
  const profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);

  updateDoc(profileRef, {
    experiencelist: store.state.currentProfileDetails.experiencelist,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("experiencelist");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const saveEducationExternal = () => {
  const profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);

  updateDoc(profileRef, {
    educationlist: store.state.currentProfileDetails.educationlist,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("educationlist");

      //If there is a linked client or lakeside profile, update it/them
      if (store.state.currentProfileDetails.crossaccountid) {
        updateLinkedProfiles(store.state.currentProfileDetails.id);
      }
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const updateEndYearExternal = (expObject, newEndYear) => {
  expObject.endyear = newEndYear;
  const expObjectWithNewEndYear = expObject;

  let matchingExperienceIndex =
    store.state.currentProfileDetails.experiencelist.findIndex(
      (exp) => exp.id === expObject.id
    );

  store.state.currentProfileDetails.experiencelist[matchingExperienceIndex] =
    expObjectWithNewEndYear;

  const profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);

  updateDoc(profileRef, {
    experiencelist: store.state.currentProfileDetails.experiencelist,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("experiencelist");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//from form in Edit experience in modal. There is another similar function in this file for deletion from the card
const deleteExpExternal = (experience) => {
  let expIndex;

  const experienceList = store.state.currentProfileDetails.experiencelist;
  for (let i = 0; i < experienceList.length; i++) {
    if (experienceList[i].id === experience.id) {
      expIndex = i;
    }
  }

  store.state.currentProfileDetails.experiencelist.splice(expIndex, 1);

  const profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);

  updateDoc(profileRef, {
    experiencelist: store.state.currentProfileDetails.experiencelist,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("experiencelist");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

//from form in Edit experience in modal. There is another similar function in this file for deletion from the card
const deleteEducationExternalFromForm = (education) => {
  let eduIndex;

  const educationList = store.state.currentProfileDetails.educationlist;
  for (let i = 0; i < educationList.length; i++) {
    if (educationList[i].id === education.id) {
      eduIndex = i;
    }
  }

  store.state.currentProfileDetails.educationlist.splice(eduIndex, 1);

  const profileRef = doc(db, "profiles", store.state.currentProfileDetails.id);

  updateDoc(profileRef, {
    educationlist: store.state.currentProfileDetails.educationlist,
  })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("educationlist");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const addLiProfileExternal = () => {
  updateDoc(doc(db, "profiles", store.state.currentProfileDetails.id), {
    linkedinurl: store.state.currentProfileDetails.linkedinurl,
  })
    .then(() => {
      iziToast.show({
        title: "LinkedIn profile added !",
        backgroundColor: "#47be7d",
        titleColor: "white",
        theme: "dark",
        position: "bottomRight",
        timeout: 3000,
        icon: "fa fa-check",
      });
    })
    .then(() => {
      //this function is called every time the user updates a field in
      //the profile document that can potentially be exported in a progress report.
      //it is important to update currentProfileDetails, otherwise
      //the progress report will be exported without the updated data,
      //as there is no realtime link between profiledetails and profilelist.
      updateProfileInVuexProfileList("linkedinurl");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const newEmailWithChatGPTMessageExternal = () => {
  if (!store.state.currentProfileDetails.email) {
    alert("Please add an email address to the profile first.");
    return;
  }

  $("#generatemessage").modal("hide");

  //Clear previous email in modal, if any
  store.state.currentEmailMessage = "";

  //prepare form
  store.state.emailSubject = "";
  store.state.emailBody =
    "<p>" +
    store.state.generatedOutreachMessage.replace(/\n/g, "<br>") +
    "</p>" +
    store.state.userData.emailsignature;

  $("#email").modal("show");
};

const clearNotificationExternal = (notif) => {
  if (notif.notificationtype == "email") {
    updateDoc(
      doc(db, "users/" + store.state.userData.id + "/emailsin", notif.id),
      {
        readstatus: "read",
      }
    );
  } else if (notif.notificationtype == "task") {
    updateDoc(
      doc(db, "profiles/" + notif.profileid + "/profiletasks", notif.id),
      {
        readstatus: "read",
      }
    );
  } else if (notif.notificationtype == "clientcomment") {
    updateDoc(
      doc(db, "profiles/" + notif.profileid + "/profilecomments", notif.id),
      {
        readstatus: "read",
      }
    );
  }
};

const addAsCVExternal = (fileobject, profileid) => {
  let randomid = Math.floor(Math.random() * 100000000 + 10000000) % 100000000;
  let filetype = fileobject.fileName.split(".").pop();

  let cvfileobject = {
    id: randomid,
    name: fileobject.fileName,
    type: filetype,
    url: fileobject.url,
  };

  let profileRef = doc(db, "profiles", profileid);
  updateDoc(profileRef, {
    cvfile: cvfileobject,
  }).then(() => {
    store.state.currentProfileDetails.cvfile = cvfileobject;
    //Confirmation toast
    iziToast.show({
      title:
        "CV added to " +
        store.state.currentProfileDetails.firstname +
        " " +
        store.state.currentProfileDetails.lastname +
        " !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    
          //If there is a linked client or lakeside profile, update it/them
          if (store.state.currentProfileDetails.crossaccountid) {
            setTimeout(() => {
              updateLinkedProfiles(store.state.currentProfileDetails.id);
              
            }, 1000);
          } 
  });
};

/* WARNING: This was used only to convert the projectid in the projectattachment array in profile docs, 
//with now the clientname and position title. In order to make the projects searchable in the facet (advanced search)
//And to make it easier to import legacy profiles with project references from new users who want to migrate 
//their data. It should be used with care as it alters all profiles.
async function updateProfileProjectAttachments() {
  // 1. Go through all the documents in the profiles collection
  const profilesCollection = collection(db, 'profiles');
  const profileSnapshot = await getDocs(profilesCollection);
  const profilesDocs = profileSnapshot.docs;

  for (let profileDoc of profilesDocs) {
    // 2. For each document, take the values in the projectattachments field
    const projectAttachments = profileDoc.data().projectattachments;

    // Checking if projectAttachments is not empty
    if (projectAttachments && projectAttachments.length > 0) {
      const newProjectAttachments = [];

      // 3. For each value in the projectattachments field, search the document in the projects collection
      for (let projectAttachment of projectAttachments) {

if(projectAttachment.includes("/")) {

} else {



        const projectDocRef = doc(db, 'projects', projectAttachment);
        const projectDocSnapshot = await getDoc(projectDocRef);

        if (projectDocSnapshot.exists()) {
          const projectDoc = projectDocSnapshot.data();

          // 4. Change the string into the following way: projectsdocument.clientobject.clientname value + " / " + projectsdocument.positiontitle value
          const newProjectAttachment = `${projectDoc.clientobject.clientname} / ${projectDoc.positiontitle}`;
          newProjectAttachments.push(newProjectAttachment);
        }
      }
      }

      // Update the profile document with the new projectattachments field
      await updateDoc(doc(db, 'profiles', profileDoc.id), {
        projectattachments: newProjectAttachments,
      });
    }
    console.log("...")
  }
}
*/

export {
  attachProfileToProjectExternal,
  detachFromThisProjectExternal,
  storeCurrentProfileProjectBindingExternal,
  saveNotesExternal,
  updateProfileStatusExternal,
  addProfileCommentExternal,
  addProfileCommentFromClientExternal,
  changeSelectedProjectForCommentAttachementExternal,
  deleteProfileCommentExternal,
  addProfileTaskExternal,
  handleProfileTaskCheckBoxExternal,
  deleteProfileTaskExternal,
  reAssignUserExternal,
  reAssignUserAfterAddExternal,
  addProfileTagExternal,
  deleteProfileTagExternal,
  uploadAttachmentFile,
  sendEmailExternal,
  markAsReadExternal,
  deleteThiEmailExternal,
  saveSignatureExternal,
  saveProfileEmailExternal,
  switchClientVisibilityExternal,
  switchClientVisibilityFromPortalModalExternal,
  switchTeamAccessExternal,
  updateCandidateOrClientSwitchExternal,
  updateGdprStatusExternal,
  modifyFirstNameExternal,
  modifyLastNameExternal,
  modifyTitleExternal,
  modifyCompanyExternal,
  modifyPhoneExternal,
  modifyEmailExternal,
  modifyLocationExternal,
  deleteProExperienceExternal,
  deleteEducationExternal,
  updateShowToClientCheckboxExternal,
  toggleattachcommentineditmodalExternal,
  changeSelectedProjectForCommentAttachementExternalInEditModal,
  updateCommentTextExternal,
  assignClientToProfileExternal,
  deleteProfileExternal,
  uploadCvExternal,
  deleteCvExternal,
  uploadFileExternal,
  deleteFileExternal,
  saveExperienceExternal,
  saveEducationExternal,
  updateEndYearExternal,
  deleteExpExternal,
  deleteEducationExternalFromForm,
  addLiProfileExternal,
  newEmailWithChatGPTMessageExternal,
  //for emails
  markAsUnreadExternal,
  //for all notifications
  clearNotificationExternal,
  addAsCVExternal,
};
