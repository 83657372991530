<template>
  <!--begin::Messenger-->
  <div class="card w-100 border-0 rounded-0" id="kt_drawer_chat_messenger">
    <!--begin::Card header-->
    <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
      <!--begin::Title-->
      <div class="card-title">
        <!--begin::User-->
        <div class="d-flex justify-content-center flex-column me-3">
          <a
            href="#"
            class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
            >LinkedIn Chat</a
          >

          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::User-->
      </div>
      <!--end::Title-->

      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Menu-->
        <div class="me-0">
          <button
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <i class="ki-duotone ki-dots-square fs-2"
              ><span class="path1"></span><span class="path2"></span
              ><span class="path3"></span><span class="path4"></span
            ></i>
          </button>

          <!--begin::Menu 3-->
          <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
            data-kt-menu="true"
          >
            <!--begin::Heading-->
            <div class="menu-item px-3">
              <div
                class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase"
              >
                Contacts
              </div>
            </div>
            <!--end::Heading-->

            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <a
                href="#"
                class="menu-link px-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_users_search"
              >
                Add Contact
              </a>
            </div>
            <!--end::Menu item-->

            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <a
                href="#"
                class="menu-link flex-stack px-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_invite_friends"
              >
                Invite Contacts

                <span
                  class="ms-2"
                  data-bs-toggle="tooltip"
                  title="Specify a contact email to send an invitation"
                >
                  <i class="ki-duotone ki-information fs-7"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span
                  ></i>
                </span>
              </a>
            </div>
            <!--end::Menu item-->

            <!--begin::Menu item-->
            <div
              class="menu-item px-3"
              data-kt-menu-trigger="hover"
              data-kt-menu-placement="right-start"
            >
              <a href="#" class="menu-link px-3">
                <span class="menu-title">Groups</span>
                <span class="menu-arrow"></span>
              </a>

              <!--begin::Menu sub-->
              <div class="menu-sub menu-sub-dropdown w-175px py-4">
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a
                    href="#"
                    class="menu-link px-3"
                    data-bs-toggle="tooltip"
                    title="Coming soon"
                  >
                    Create Group
                  </a>
                </div>
                <!--end::Menu item-->

                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a
                    href="#"
                    class="menu-link px-3"
                    data-bs-toggle="tooltip"
                    title="Coming soon"
                  >
                    Invite Members
                  </a>
                </div>
                <!--end::Menu item-->

                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a
                    href="#"
                    class="menu-link px-3"
                    data-bs-toggle="tooltip"
                    title="Coming soon"
                  >
                    Settings
                  </a>
                </div>
                <!--end::Menu item-->
              </div>
              <!--end::Menu sub-->
            </div>
            <!--end::Menu item-->

            <!--begin::Menu item-->
            <div class="menu-item px-3 my-1">
              <a
                href="#"
                class="menu-link px-3"
                data-bs-toggle="tooltip"
                title="Coming soon"
              >
                Settings
              </a>
            </div>
            <!--end::Menu item-->
          </div>
          <!--end::Menu 3-->
        </div>
        <!--end::Menu-->

        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-color-primary"
          id="kt_drawer_chat_close"
        >
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            id="kt_activities_close"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg--><span
              class="svg-icon svg-icon-1"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                ></rect></svg></span
            ><!--end::Svg Icon-->
          </button>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body" id="kt_drawer_chat_messenger_body">
      <!--begin::Messages-->
      <div
        class="scroll-y me-n5 pe-5"
        data-kt-element="messages"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="330px"
        data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
        data-kt-scroll-offset="0px"
        id="limessageselement"
      >
        <div v-if="transformedChatMessages == 'loadingchat'">Loading chat...</div>

        <div v-if="transformedChatMessages == 'nochatfound'">No messages yet. Try send a contact request, an InMail or a message (if you are connected with this profile).</div>

        <div v-for="item in transformedChatMessages" :key="item.id">
          <!--begin::Message(out)-->
          <div
            v-if="item.is_sender == 1"
            class="d-flex justify-content-end mb-10"
          >
            <!--begin::Wrapper-->
            <div class="d-flex flex-column align-items-end">
              <!--begin::User-->
              <div class="d-flex align-items-center mb-2">
                <!--begin::Details-->
                <div class="d-flex flex-column">
                  <div class="me-3">
                    <span class="text-muted fs-7 mb-1 me-1">
                      {{ transformedDate(item.timestamp) }},
                      {{ transformedTime(item.timestamp) }}
                      <span class="fs-5 fw-boldest">·</span>
                    </span>
                    <a
                      href="#"
                      class="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                      >Me</a
                    >
                  </div>
                  <div class="fs-8 text-muted">
                    <span v-if="item.origin == 'linkedinclassic'"
                      >LinkedIn classic</span
                    >
                    <span v-if="item.origin == 'linkedinrecruiter'"
                      >LinkedIn Recruiter</span
                    >
                  </div>
                </div>

                <!--end::Details-->

                <!--begin::Avatar-->
                <div class="symbol symbol-35px symbol-circle">
                  <img alt="Pic" :src="$store.state.userData.avatarurl" />
                </div>
                <!--end::Avatar-->
              </div>
              <!--end::User-->

              <!--begin::Text-->
              <div
                class="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-350px text-start"
                data-kt-element="message-text"
              >
                {{ item.text }}
              </div>
              <!--end::Text-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Message(out)-->

          <!--begin::Message(in)-->
          <div
            v-if="item.is_sender == 0"
            class="d-flex justify-content-start mb-10"
          >
            <!--begin::Wrapper-->
            <div class="d-flex flex-column align-items-start">
              <!--begin::User-->
              <div class="d-flex align-items-center mb-2">
                <!--begin::Avatar-->
                <div class="symbol symbol-35px symbol-circle">
                  <img
                    alt="Pic"
                    :src="$store.state.currentProfileDetails.profilepic"
                  />
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-3">
                  <a
                    href="#"
                    class="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                    >{{ $store.state.currentProfileDetails.firstname }}</a
                  >

                  <span class="text-muted fs-7 mb-1 ms-1"
                    ><span class="fs-5 fw-boldest">·</span>
                    {{ transformedDate(item.timestamp) }},
                    {{ transformedTime(item.timestamp) }}
                    <br />
                    <span class="fs-8 text-muted" v-if="item.origin == 'linkedinclassic'"
                      >LinkedIn classic</span
                    >
                    <span class="fs-8 text-muted" v-if="item.origin == 'linkedinrecruiter'"
                      >LinkedIn Recruiter</span
                    >
                  </span>
                </div>
              </div>
              <!--end::Details-->

              <!--end::User-->

              <!--begin::Text-->
              <div
                class="p-5 rounded bg-light text-gray-900 fw-semibold mw-lg-400px text-start"
                data-kt-element="message-text"
              >
                {{ item.text }}
              </div>
              <!--end::Text-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Message(in)-->
        </div>

        <!--begin::Message(template for out)-->
        <div
          class="d-flex justify-content-end mb-10 d-none"
          data-kt-element="template-out"
        >
          <!--begin::Wrapper-->
          <div class="d-flex flex-column align-items-end">
            <!--begin::User-->
            <div class="d-flex align-items-center mb-2">
              <!--begin::Details-->
              <div class="me-3">
                <span class="text-muted fs-7 mb-1">Just now</span>
                <a
                  href="#"
                  class="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                  >You</a
                >
              </div>
              <!--end::Details-->

              <!--begin::Avatar-->
              <div class="symbol symbol-35px symbol-circle">
                <img
                  alt="Pic"
                  src="/metronic8/demo1/assets/media/avatars/300-1.jpg"
                />
              </div>
              <!--end::Avatar-->
            </div>
            <!--end::User-->

            <!--begin::Text-->
            <div
              class="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end"
              data-kt-element="message-text"
            ></div>
            <!--end::Text-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Message(template for out)-->

        <!--begin::Message(template for in)-->
        <div
          class="d-flex justify-content-start mb-10 d-none"
          data-kt-element="template-in"
        >
          <!--begin::Wrapper-->
          <div class="d-flex flex-column align-items-start">
            <!--begin::User-->
            <div class="d-flex align-items-center mb-2">
              <!--begin::Avatar-->
              <div class="symbol symbol-35px symbol-circle">
                <img
                  alt="Pic"
                  src="/metronic8/demo1/assets/media/avatars/300-25.jpg"
                />
              </div>
              <!--end::Avatar-->
              <!--begin::Details-->
              <div class="ms-3">
                <a
                  href="#"
                  class="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                  >Brian Cox</a
                >
                <span class="text-muted fs-7 mb-1">Just now</span>
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Text-->
            <div
              class="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
              data-kt-element="message-text"
            >
              Right before vacation season we have the next Big Deal for you.
            </div>
            <!--end::Text-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Message(template for in)-->
      </div>
      <!--end::Messages-->
    </div>
    <!--end::Card body-->

    <!--begin::Card footer-->
    <div class="ps-8 pe-8">
      <!--begin::Form-->
      <form
        id="linkedinmessageinput"
        class="mt-1"
        @submit.prevent="sendLinkedInMessage()"
      >
        <!--begin::Body-->
        <div class="d-block">
          <!--begin::Subject-->
          <div class="border-bottom rounded border border-secondary">
            <textarea
              class="form-control border-0 min-h-45px"
              cols="30"
              rows="6"
              name="compose_subject"
              placeholder="Type your message here..."
              v-model="$store.state.emailSubject"
            >
            </textarea>
          </div>
          <!--end::Subject-->
        </div>
        <!--end::Body-->
        <!--begin::Footer-->
        <div class="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 border-top">
          <!--begin::Actions-->
          <div class="d-flex align-items-center ms-auto">
            <!--begin::Upload attachement-->

            <!--end::Upload attachement-->

            <!--begin::end-->
            <div class="btn-group">
              <!--begin::Submit-->
              <button class="btn btn-primary btn-sm fs-bold" type="submit">
                Send
              </button>
              <!--end::Submit-->
            </div>
            <!--end::send-->
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Footer-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Card footer-->
  </div>
  <!--end::Messenger-->
</template>

<script>
export default {
  methods: {
    sendLinkedInMessage() {
      alert("hello");
    },
    transformedDate(timestamp) {
      const date = new Date(timestamp);
      const milliseconds = date.getTime();

      const itemDate = new Date(timestamp).getDate();
      const itemMonth = new Date(timestamp).getMonth();
      const itemYear = new Date(timestamp).getFullYear();

      const currentDate = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      if (itemDate == currentDate) {
        return "Today";
      } else if (
        itemDate == currentDate - 1 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "Yest.";
      } else if (
        itemDate == currentDate - 2 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "2 days ago";
      } else if (
        itemDate == currentDate - 3 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "3 days ago";
      } else {
        const monthName = new Date(timestamp).toLocaleString("en-US", {
          month: "short",
        });
        const finalDate = monthName + ". " + itemDate;

        return finalDate;
      }
    },
    transformedTime(timestamp) {
      const date = new Date(timestamp);

      // Calculate UTC+2 offset in milliseconds (2 hours * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
      const utcOffset = 2*(60 * 60 * 1000);
      const localDate = new Date(date.getTime() + utcOffset);

      // Get hours and minutes from the adjusted date
      const hours = localDate.getUTCHours(); // Use UTC methods to avoid issues with local daylight saving changes
      const minutes = localDate.getUTCMinutes();

      // Format hours and minutes to ensure two digits (e.g., 05, 09)
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");

      // Combine the formatted hours and minutes
      const time = `${formattedHours}:${formattedMinutes}`;

      return time;
    },
  },
  computed: {
    

    transformedChatMessages() {

        if(this.$store.state.lichatcontent[0] == "noclassicchatyet") {
            return "loadingchat"
        }

        if(this.$store.state.lichatcontent[1] == "norecruiterchatyet") {
            return "loadingchat"
        }

      let linkedinclassicchat = this.$store.state.lichatcontent[0];
      let linkedinrecruiterchat = this.$store.state.lichatcontent[1];

      if (linkedinclassicchat == "nochatsfound") {
        //do nothing, there is not classic linkedin chat
      } else {
        //there is a chassic linkedin chat, add origin property
        linkedinclassicchat = this.$store.state.lichatcontent[0].items.map(
          (item) => {
            return { ...item, origin: "linkedinclassic" };
          }
        );
      }

      if (linkedinrecruiterchat == "nochatsfound") {
        //do nothing, there is not recruiter chat
      } else {
        //there is a recruiter chat, add origin property
        linkedinrecruiterchat = this.$store.state.lichatcontent[1].items.map(
          (item) => {
            return { ...item, origin: "linkedinrecruiter" };
          }
        );
      }

      //build final chat by merging classic and recruiter or not, based on what exists
      let fulllinkedinchat;
      if (linkedinclassicchat != "nochatsfound" && linkedinrecruiterchat == "nochatsfound") {
        //only classic chat
        fulllinkedinchat = linkedinclassicchat;
      } else if (linkedinclassicchat == "nochatsfound" && linkedinrecruiterchat != "nochatsfound") {
        //only recruiter chat
        fulllinkedinchat = linkedinrecruiterchat;
      } else if (linkedinclassicchat != "nochatsfound" && linkedinrecruiterchat != "nochatsfound") {
        //chat is a mix of both
        fulllinkedinchat = linkedinclassicchat.concat(linkedinrecruiterchat);
      }

      if (linkedinclassicchat == "nochatsfound" && linkedinrecruiterchat == "nochatsfound") {
        //there is not chat at all
        fulllinkedinchat = "nochatfound";
      } else {
        //sort the not empty caht
        fulllinkedinchat.sort((a, b) => {
          return new Date(a.timestamp) - new Date(b.timestamp);
        });
      }

      return fulllinkedinchat;
    },
  },
};
</script>

<style></style>
