<template>

											<!--begin::Card-->
											<div class="card border-hover-primary bg-light w-300px">
												<!--begin::Card header-->
												<div class="card-header border-0 pt-9">
													<!--begin::Card Title-->
													<div class="card-title m-0">
														<!--begin::logo-->
														<div class="symbol symbol-50px bg-light">
															<img v-if="logoURL" :src="logoURL" alt="image" class="">
															<img v-else src="https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png" alt="image" class="">
														</div>
														<!--end::logo-->
													</div>
													<!--end::Car Title-->
				
												</div>
												<!--end:: Card header-->
												<!--begin:: Card body-->
												<div class="card-body p-9">
													<!--begin::Name-->
													<div class="fs-3 fw-bold text-dark">{{ companyName }}</div>
													<!--end::Name-->
													<!--begin::Description-->
													<p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">{{ companyCity }}, {{ companyCountry }}</p>
													<!--end::Description-->

												</div>
												<!--end:: Card body-->
											</div>
											<!--end::Card-->
										

</template>

<script>
export default {
    props: ["companyName", "logoURL", "companyCity", "companyCountry"]

}
</script>

<style>

</style>