


<template>


	<!--begin::Main-->

		<!--begin::Root-->

		<!--begin::Main app UI-->
		<div class="d-flex flex-column flex-root">

			<!--begin::Page-->
			<div class="page d-flex flex-column flex-column-fluid">



				<!--begin::Main Tab Nav bar-->
				<MainTabNav></MainTabNav>
				<!--end::Main Tab Nav bar-->





				<!--begin::Wrapper : the different tabs of the app are injected here-->
        		<ProjectList v-if="$store.state.showingProjectsAs == 'myProjects' || $store.state.showingProjectsAs == 'teamProjects' || $store.state.showingProjectsAs == 'allProjects'"></ProjectList>
				<ProjectDetails v-if="$store.state.showingProjectsAs == 'projectDetails'"></ProjectDetails>
				<!--end::Wrapper-->


			</div>
			<!--end::Page-->

		</div>




		<!--end::Root-->





</template>

<script>

import ProjectList from '../components/ProjectList.vue'
import ProjectDetails from '../components/ProjectDetails.vue'
import MainTabNav from '../components/MainTabNav.vue'




export default {
		  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
    components: {
        ProjectList, ProjectDetails, MainTabNav
    },
	methods: {
		closemodals: function() {
			this.$store.state.showusermenu = false;
		}
	},
    created() {
    this.$store.state.currentRoute = this.$route.path
  },
  mounted() {

	  	KTMenu.createInstances();
	  	//KTDrawer.init();
		KTDrawer.createInstances();
		//KTDrawer.handleShow();
    	//KTDrawer.handleDismiss();
		KTScroll.createInstances();
		KTScrolltop.createInstances();
		KTSticky.createInstances();
		KTDialer.createInstances();
		KTImageInput.createInstances();
		KTPasswordMeter.createInstances();
		KTSwapper.createInstances();
		KTToggle.createInstances();
		KTWidgets.init()


  }


}
</script>
