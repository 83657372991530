<template>
  <!--begin::Mixed Widget 12-->
  <div class="card card-xl-stretch mb-5 mb-xl-8 shadow-lg">
    <!--begin::Header-->
    <div class="card-header border-0 bg-danger py-5">
      <div class="d-flex flex-column">
        <h3
          v-if="origin == 'dashboardmodal'"
          class="card-title fw-bolder text-white"
        >
          Profile activity
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/general/gen046.svg-->
            <span class="svg-icon svg-icon-white svg-icon-2x ms-2 tooltip custompointer"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  fill="currentColor"
                />
                <path
                  d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                  fill="currentColor"
                />
              </svg>
                <span class="tooltiptext">
                    This KPI tracks the profile actions over a 12 month period. Profile actions include profile creation, status updates, new comments... Your projects only.
                </span>
            </span>
            <!--end::Svg Icon-->
        </h3>
        <h3
          v-if="origin == 'dashboardmain'"
          class="card-title fw-bolder text-white"
        >
          Analytics
        </h3>
        <span
          v-if="origin == 'dashboardmodal'"
          class="text-white opacity-75 fw-semibold fs-6"
          >Your profile actions (12 months)</span
        >
        <span
          v-if="origin == 'dashboardmain'"
          class="text-white opacity-75 fw-semibold fs-6"
          >1 year Activity</span
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Chart-->
      <div
        class="card-rounded-bottom bg-danger"
        data-kt-color="danger"
        style="height: 250px"
      >
        <apexchart
          v-if="seriesActivity[0].data.length == 12"
          class="pe-5 animate__animated animate__fadeIn"
          type="line"
          :options="chartOptionsForActivity"
          :series="seriesActivity"
        ></apexchart>
        <div v-else>
          <div class="loading-dots">
            <div class="loading-dots--dot whitedots me-1 mt-10"></div>
            <div class="loading-dots--dot whitedots me-1"></div>
            <div class="loading-dots--dot whitedots"></div>
          </div>
        </div>
      </div>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-rounded bg-body mt-n10 position-relative card-px py-15">
        <!--begin::Row-->
        <div v-if="origin == 'dashboardmodal'" class="d-flex">
          <div>
            <div class="fs-6 text-gray-400">This month</div>
          </div>
          <div class="flex-column ms-auto text-end">
            <div class="fs-2 fw-bolder text-gray-800">
              {{ $store.state.userActivityForThisMonth }}
            </div>
            <div class="fs-8 text-gray-400">Profile actions</div>
          </div>
        </div>
        <div v-if="origin == 'dashboardmain'" class="d-flex">
          <button
            class="mx-auto btn btn-lg btn-secondary"
            @click="reRenderKpiComponents()"
            data-bs-toggle="modal"
            data-bs-target="#dashboardkpis"
            style="margin-top: -20px;"
          >
            See more
          </button>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 12-->
</template>

<script>
import { countActivityForThisUser } from "../../js/dashboard";

import { useStore } from "vuex";

export default {
  props: ["origin"],
  mounted() {
    countActivityForThisUser();
  },
  computed: {
    seriesActivity() {
      const store = useStore();

      return [
        {
          name: "Monthly Activity",
          data: store.state.userMonthlyActivity.map((item) => {
            return {
              x: item[0],
              y: item[1],
            };
          }),
        },
      ];
    },
    chartOptionsForActivity() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
          height: 120,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          colors: ["#FFFFFF"],
        },
        xaxis: {
          categories: this.$store.state.userMonthlyActivity.map(
            (monthlyData) => monthlyData[0]
          ),
          title: {
            text: undefined,
          },
          labels: {
            style: {
              fontSize: "13px",
              colors: [
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
                "#FFFFFF",
              ],
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            style: {
              fontSize: "9px",
              colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
            },
          },
        },
      };
    },
    userActivityTrend: function () {
      // Get the activity count for the current month and the last month
      let currentMonthActivity = this.$store.state.userActivityForThisMonth;
      let lastMonthActivity =
        this.$store.state.userMonthlyActivity[
          this.$store.state.userMonthlyActivity.length - 1
        ][1];

      // Get the number of days in the current month so far
      let now = new Date();
      let daysInCurrentMonthSoFar = now.getDate();

      // If there was no activity last month or it's the first day of the month, we can't calculate a trend
      if (lastMonthActivity === 0 || daysInCurrentMonthSoFar === 1) {
        return {
          trend: 0,
          display: "N/A",
        };
      }

      // Estimate the activity count for the last month in the same number of days
      let lastMonthActivityEstimate =
        lastMonthActivity * (daysInCurrentMonthSoFar / 30); // Assuming 30 days in a month for simplicity

      // Calculate the trend
      let trend =
        ((currentMonthActivity - lastMonthActivityEstimate) /
          lastMonthActivityEstimate) *
        100;

      // Round the trend to the nearest integer
      trend = Math.round(trend);

      // Return the trend as a number and a string with a '%' sign and a '+' sign before positive numbers
      return {
        trend: trend,
        display: `${trend > 0 ? "+" : ""}${trend}%`,
      };
    },  
  },
  methods: {
    reRenderKpiComponents() {
      this.$store.state.timeToHireComponentKey++;
      this.$store.state.profileActivityComponentKey++;
      this.$store.state.newProjectsComponentKey++;
    },
  },
};
</script>

<style>
</style>