<template>



<!--
<div v-if="this.$store.state.browserwidth > 1400 && showProfileListLeftPreview">
<ProfileListLeftPreview></ProfileListLeftPreview>
</div>
-->
  <div
    class="wrapper d-flex flex-column flex-row-fluid container-xxl"
    id="kt_wrapper"
  >
    <!--begin::Toolbar-->
    <Toolbar title="Profile details"></Toolbar>
    <!--end::Toolbar-->

    <!--begin::Main-->

    <!--begin::Main new-->
    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div class="content flex-row-fluid" id="kt_content">
        <!--begin::Layout-->
        <div class="d-flex flex-column">
          <!--begin:grid-->
          <div class="row">
            <!--begin::left col-->
            <div class="col-8" style="z-index: 2 !important;">
              <div class="flex-column flex-lg-row-auto mb-10 me-4 animate__animated animate__fadeIn">
                <IdCard whichCard="" title="" subTitle="none"></IdCard>

                <SmallCard
                  whichCard="profileexperiencerich"
                  title="Experience"
                  subTitle="none"
                ></SmallCard>
              </div>
            </div>
            <!--end::left col-->

            <!--begin::right col-->
            <div class="col-4">
              <div class="flex-column flex-lg-row-auto mb-10 animate__animated animate__fadeIn">
                <SmallCard
                  v-if="
                    $store.state.currentProfileDetails.candidateorclient ==
                    'Candidate'
                  "
                  whichCard="projectprofileactivity"
                  title="Projects"
                  subTitle="none"
                ></SmallCard>

                <SmallCard
                  v-if="
                    $store.state.currentProfileDetails.candidateorclient ==
                    'Client'
                  "
                  whichCard="profileclientifclient"
                  title="Client company"
                  subTitle="none"
                ></SmallCard>

                <!--begin::Comments Card-->
                <SmallCard
                  whichCard="profilecomments"
                  title="Comments"
                  subTitle=""
                ></SmallCard>
                <!--end::Comments Card-->
              </div>
            </div>
            <!--end::right col-->
          </div>
          <!--end:grid-->
        </div>
        <!--end::Layout-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Main new-->

    <!--begin::Footer-->
    <div
      class="
        footer
        py-4
        d-flex
        flex-column flex-md-row
        align-items-center
        justify-content-between
      "
      id="kt_footer"
    >
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="text-white opacity-75 fw-bold me-1">2021©</span>
        <a
          href="https://kiwanotalent.com"
          target="_blank"
          class="text-white text-hover-primary opacity-75"
          >Keenthemes</a
        >
      </div>
      <!--end::Copyright-->
						<!--begin::Menu-->
						<ul class="menu menu-white menu-hover-primary fw-bold order-1 opacity-75">
							<li class="menu-item">
								<a href="https://kiwanotalent.com" target="_blank" class="menu-link px-2">About</a>
							</li>

						</ul>
						<!--end::Menu-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
import Toolbar from "../components/Toolbar-Component.vue";
import SmallCard from "./Cards/SmallCard.vue";
import MediumCard from "./Cards/MediumCard.vue";
import IdCard from "./Cards/IdCard.vue";
import Tagify from "@yaireo/tagify";
import VueApexCharts from "vue3-apexcharts";
import { attachProfileToProjectExternal } from "../js/profiledetails";
import { unsubtoprofileprojectbindings } from "../js/getdata";
import ProfileListLeftPreview from "../components/ProfileListLeftPreview.vue"

export default {
  mounted() {},
  methods: {
      checkprofile() {
    console.log(this.$store.state.currentProfileDetails)
  },
    attachProfileToProject(profileid, project) {
      //located in profiledetails.js
      attachProfileToProjectExternal(profileid, project);
    },

  },

  computed: {
    myActiveProjects() {
      const myProjects = this.$store.state.currentMyProjectList;

      const results = myProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    teamActiveProjects() {
      const teamProjects = this.$store.state.currentTeamProjectList;

      const results = teamProjects.filter((obj) => {
        return obj.status === "Active";
      });

      return results;
    },
    sortedProfileProjectBindings() {
      const allBindings = this.$store.state.currentProfileProjectBindings;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      return allBindings.sort(compare);
    },
    sortedProfileActivityLogs() {
      const logs = this.$store.state.currentProfileActivityLogs;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      return logs.sort(compare);
    },
  },

  data() {
    return {
      showProfileListLeftPreview: false,
      rating: 50,
      series: [75],
      chartOptions: {
        chart: {
          height: 100,
          type: "radialBar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: false,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: false,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: 13,
                show: true,
                color: "#888",
                fontSize: "10px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                offsetY: -15,
                color: "#111",
                fontSize: "18px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["%"],
      },

      //tagify test data
      mcuHeros: [
        { value: "ironman", code: "im" },
        { value: "antman", code: "am" },
        { value: "captain america", code: "ca" },
        { value: "thor", code: "th" },
        { value: "spiderman", code: "sm" },
      ],

      tags: [],
    };
  },
  components: {
    Toolbar,
    apexchart: VueApexCharts,
    SmallCard,
    MediumCard,
    IdCard,
    ProfileListLeftPreview,
  },
  mounted() {

    setTimeout(() => {
      this.showProfileListLeftPreview = true;
    }, 2000);




    //this.tags.push('VIP')
    /*
for(let i = 0 ; i < this.$store.state.currentProfileDetails.taglist.length ; i++) {
	this.tags.push(this.$store.state.currentProfileDetails.taglist[i])
}
*/
    /*
    var input = document.getElementById("onlyMarvel");
    var tagify = new Tagify(input, {
      whitelist: this.$store.state.profileTagNameList,
      enforceWhitelist: true,
      dropdown: {
        maxItems: 20, // <- mixumum allowed rendered suggestions
        classname: "tagify__inline__suggestions", // <- custom classname for this dropdown, so it could be targeted
        enabled: 0, // <- show suggestions on focus
        closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
      },
    });
    tagify.on("add", function (e) {
      console.log(e.detail.data);
    });
    */
  },
};
</script>

<style>
</style>