<template>
  <div class="card card-xl-stretch mb-5 mb-xl-8 shadow-lg">
    <!--begin::Body-->
    <div class="card-body p-0">
      <h3 class="card-title fw-bolder text-dark p-7">Metrics</h3>

      <div class="mx-5 my-5">
        <CardAlert
          v-if="
            $store.state.currentRoute == '/projects' &&
            $store.state.currentProjectDetails.numberofpotentialcandidates +
              $store.state.currentProjectDetails.numberofinprogress +
              $store.state.currentProjectDetails.numberofdiscarded +
              $store.state.currentProjectDetails.numberofnostatus ==
              0
          "
          alerttype="info"
          title="No profiles in this project yet"
          text="Attach at least one profile to this project"
        ></CardAlert>
      </div>

      <div
        v-if="
          $store.state.currentRoute == '/projects' &&
          $store.state.currentProjectDetails.numberofpotentialcandidates +
            $store.state.currentProjectDetails.numberofinprogress +
            $store.state.currentProjectDetails.numberofdiscarded +
            $store.state.currentProjectDetails.numberofnostatus >
            0
        "
        class="row p-7"
      >
        <div class="col-6">
          <div class="row">
            <div class="col-4 text-center text-gray-800 fw-bolder fs-3">
              {{
                $store.state.currentProjectDetails.numberofpotentialcandidates +
                $store.state.currentProjectDetails.numberofinprogress +
                $store.state.currentProjectDetails.numberofdiscarded +
                $store.state.currentProjectDetails.numberofnostatus
              }}
            </div>
            <div
              class="col-8 text-gray-400 fs-7 fw-semibold mt-1"
              style="margin-left: -15px"
            >
              Total profiles
            </div>
            <hr class="text-muted ms-3" style="width: 100px" />
          </div>
          <div class="row">
            <div class="col-4 text-center text-success fw-bolder fs-3">
              {{
                $store.state.currentProjectDetails.numberofpotentialcandidates
              }}
            </div>
            <div
              class="col-8 text-gray-400 fs-7 fw-semibold mt-1"
              style="margin-left: -15px"
            >
              Interested
            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center text-primary fw-bolder fs-3">
              {{ $store.state.currentProjectDetails.numberofinprogress }}
            </div>
            <div
              class="col-8 text-gray-400 fs-7 fw-semibold mt-1"
              style="margin-left: -15px"
            >
              In progress
            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center text-danger fw-bolder fs-3">
              {{ $store.state.currentProjectDetails.numberofdiscarded }}
            </div>
            <div
              class="col-8 text-gray-400 fs-7 fw-semibold mt-1"
              style="margin-left: -15px"
            >
              Discarded
            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center text-gray-400 fw-bolder fs-3">
              {{ $store.state.currentProjectDetails.numberofnostatus }}
            </div>
            <div
              class="col-8 text-gray-400 fs-7 fw-semibold mt-1"
              style="margin-left: -15px"
            >
              No status
            </div>
          </div>
        </div>
        <div class="col-6" style="margin-left: -30px">
          <apexchart
            width="200"
            type="donut"
            :options="options"
            :series="[
              $store.state.currentProjectDetails.numberofpotentialcandidates,
              $store.state.currentProjectDetails.numberofinprogress,
              $store.state.currentProjectDetails.numberofdiscarded,
              $store.state.currentProjectDetails.numberofnostatus,
            ]"
          ></apexchart>
        </div>
      </div>

      <!--
  <div
    v-if="$store.state.currentRoute == '/clients'"
    class="row"
    style="margin-top: -20px"
  >
    <div class="col-6">
      <div class="row">
        <div class="col-3 text-center text-gray-800 fw-bolder fs-3">
          {{ $store.state.numberOfProjectsForThisClient.total }}
        </div>
        <div
          class="col-8 text-gray-400 fs-7 fw-semibold mt-1"
          style="margin-left: -15px"
        >
          Projects
        </div>
        <hr class="text-muted ms-5" style="width: 70px" />
      </div>
      <div class="row">
        <div class="col-3 text-center text-success fw-bolder fs-3">
          {{ $store.state.numberOfProjectsForThisClient.active }}
        </div>
        <div
          class="col-10 text-gray-400 fs-7 fw-semibold mt-1"
          style="margin-left: -15px"
        >
          Active
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-center text-danger fw-bolder fs-3">
          {{ $store.state.numberOfProjectsForThisClient.closed }}
        </div>
        <div
          class="col-8 text-gray-400 fs-7 fw-semibold mt-1"
          style="margin-left: -15px"
        >
          Closed
        </div>
      </div>
  
    </div>
    <div class="col-6" style="margin-left: -30px">
      <apexchart
        width="200"
        type="donut"
        :options="options2"
        :series="[
          $store.state.numberOfProjectsForThisClient.active,
          $store.state.numberOfProjectsForThisClient.closed
        ]"
      ></apexchart>
    </div>
  </div>
  -->
    </div>
  </div>
</template>

<script>
import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
  computed: {},

  components: {
    CardAlert,
  },

  data: function () {
    return {
      options: {
        colors: ["#04C8C8", "#009EF7", "#F1416C", "#F5F8FA"],
        chart: {
          offsetX: 0,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "60%",
              labels: {
                show: false,
                name: {
                  show: false,
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: false,
                  fontSize: "10px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 4,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: false,
                  showAlways: false,
                  label: "Total",
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
      options2: {
        colors: ["#04C8C8", "#F1416C"],
        chart: {
          offsetX: 0,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "60%",
              labels: {
                show: false,
                name: {
                  show: false,
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: false,
                  fontSize: "10px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 4,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: false,
                  showAlways: false,
                  label: "Total",
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
      series: [],
    };
  },
};
</script>

<style>
</style>