<template>
  <!--begin:Project info main-->
  <div
    v-if="
      $store.state.currentRoute == '/projects' &&
      $store.state.currentProjectDetails
    "
    class="d-flex"
    style="margin-top: -30px"
  >
    <div class="symbol symbol-40px companylogo">
      <img
        v-if="$store.state.currentProjectDetails.clientobject.clientlogourl"
        :src="$store.state.currentProjectDetails.clientobject.clientlogourl"
        class="h-40 align-self-center  border border-gray-400"
        alt=""
      />
      <img
        v-else
        src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
        class="h-40 align-self-center"
        alt=""
      />
    </div>
    <div class="d-flex flex-column ms-3 text-muted">
      <span class="text-muted fw-boldest d-block">{{
        $store.state.currentProjectDetails.positiontitle
      }}</span>
      <span class="text-muted fw-semibold d-block">{{
        $store.state.currentProjectDetails.clientobject.clientname
      }}</span>

      <div class="mt-3 d-flex">
        <StatusDropdown
          origin="projectDetailsCard"
          :elementObject="$store.state.currentProjectDetails"
        ></StatusDropdown>

        <!--begin: Show project teamaccess (if user is not the owner of the project)-->
        <span
          v-if="
            $store.state.currentProjectDetails.owninguser !=
            $store.state.userData.userid
          "
          class="badge badge-sm badge-light ms-3"
        >
          {{ $store.state.currentProjectDetails.teamaccess }}
        </span>
        <!--end: Show project teamaccess (if user is not the owner of the project)-->


      </div>

      <hr style="color: grey" />
              <!--begin: Update project teamaccess (if user is the owner of the project)-->
        <span
          v-if="
            $store.state.currentProjectDetails.owninguser ==
            $store.state.userData.userid
          "
          class=" mb-2 dropdown-toggle custompointer"
          :id="'updateTeamAccess' + $store.state.currentProjectDetails.id"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span v-if="$store.state.currentProjectDetails.teamaccess == 'Public'">&#128275; Visible to team</span>
          <span v-if="$store.state.currentProjectDetails.teamaccess == 'Private'">&#x1F512; Hidden from team</span>
        </span>
        <ul
          class="
            dropdown-menu
            menu-sub
            menu-sub-dropdown
            menu-column
            menu-rounded
            menu-title-gray-700
            menu-icon-muted
            menu-active-bg
            menu-state-primary
            fw-bold
            py-4
            fs-6
            w-150px
          "
          :aria-labelledby="
            'updateTeamAccess' + $store.state.currentProjectDetails.id
          "
          role="menu"
        >
          <li
            class="badge ms-4 me-4 mb-3 custombadgehover badge-light"
            style="cursor: pointer"
            @click.stop="updateProjectTeamAccess('Public')"
          >
            <a> Visible to team </a>
          </li>
          <li
            class="badge ms-4 me-4 mb-3 custombadgehover badge-light"
            style="cursor: pointer"
            @click.stop="updateProjectTeamAccess('Private')"
          >
            <a> Hidden from team </a>
          </li>
        </ul>
        <!--end: Update project teamaccess (if user is the owner of the project)-->
      <div class="d-flex mb-2">
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/communication/com013.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-5"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
              fill="currentColor"
            />
            <rect
              opacity="0.3"
              x="8"
              y="3"
              width="8"
              height="8"
              rx="4"
              fill="currentColor"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->

        <span style="margin-left: 5px"
          >Created by {{ computedOwningUser.firstname }} {{ computedOwningUser.lastname }}</span
        >

      </div>

      <span>
        <i class="bi bi-play me-1"></i>
        Started
        {{
          new Date($store.state.currentProjectDetails.startedat).toLocaleString(
            "en-US",
            { month: "short" }
          ) +
          ". " +
          new Date($store.state.currentProjectDetails.startedat).getDate() +
          ", " +
          new Date($store.state.currentProjectDetails.startedat).getFullYear()
        }}
      </span>
      <span class="mt-2" v-if="$store.state.currentProjectDetails.location">
        <i class="bi bi-geo-alt-fill me-1"></i>
        {{ $store.state.currentProjectDetails.location }}
      </span>


    </div>
  </div>
  <!--end:Project info main-->

  <!--begin:Client info main-->

  <div
    v-if="
      $store.state.currentRoute == '/clients' &&
      $store.state.currentClientDetails
    "
    class="d-flex"
    style="margin-top: -70px"
  >
    <div class="symbol symbol-45px companylogo">
      <img
        v-if="$store.state.currentClientDetails.clientlogourl"
        :src="$store.state.currentClientDetails.clientlogourl"
        class="h-45 align-self-center"
        alt=""
      />
      <img
        v-else
        src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png"
        class="h-45 align-self-center"
        alt=""
      />
    </div>
    <div class="d-flex flex-column ms-3 text-muted">
      <a class="text-dark card-label fw-bolder fs-3">{{
        $store.state.currentClientDetails.clientname
      }}</a>
      <div class="d-flex">
        <StatusDropdown
          origin="clientDetailsCard"
          :elementObject="$store.state.currentClientDetails"
        ></StatusDropdown>

        <!--begin: Show project teamaccess (if user is not the owner of the project)-->
        <span
          v-if="
            $store.state.currentClientDetails.owninguser !=
            $store.state.userData.userid
          "
          class="badge badge-sm badge-light ms-3 mt-2"
        >
          {{ $store.state.currentClientDetails.teamaccess }}
        </span>
        <!--end: Show project teamaccess (if user is not the owner of the project)-->

        <!--begin: Update project teamaccess (if user is the owner of the project)-->
        <span
          v-if="
            $store.state.currentClientDetails.owninguser ==
            $store.state.userData.userid
          "
          class="
            badge badge-sm badge-light
            ms-3
            mt-2
            dropdown-toggle
            custompointer
          "
          :id="'updateTeamAccess' + $store.state.currentClientDetails.id"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ $store.state.currentClientDetails.teamaccess }}
        </span>
        <ul
          class="
            dropdown-menu
            menu-sub
            menu-sub-dropdown
            menu-column
            menu-rounded
            menu-title-gray-700
            menu-icon-muted
            menu-active-bg
            menu-state-primary
            fw-bold
            py-4
            fs-6
            w-150px
          "
          :aria-labelledby="
            'updateTeamAccess' + $store.state.currentClientDetails.id
          "
          role="menu"
        >
          <li
            class="badge ms-4 me-4 mb-3 custombadgehover badge-light"
            style="cursor: pointer"
            @click.stop="updateClientTeamAccess('Public')"
          >
            <a> Public </a>
          </li>
          <li
            class="badge ms-4 me-4 mb-3 custombadgehover badge-light"
            style="cursor: pointer"
            @click.stop="updateClientTeamAccess('Private')"
          >
            <a> Private </a>
          </li>
        </ul>
        <!--end: Update project teamaccess (if user is the owner of the project)-->
      </div>

      <hr style="color: grey" />

      <div class="d-flex mb-2">
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/communication/com013.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-5"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
              fill="currentColor"
            />
            <rect
              opacity="0.3"
              x="8"
              y="3"
              width="8"
              height="8"
              rx="4"
              fill="currentColor"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->

        <span style="margin-left: 5px"
          >Created by {{ computedOwningUser.firstname }}</span
        >
        <div
          style="margin-top: -2px"
          class="ms-2 symbol symbol-circle symbol-20px"
        >
          <img :src="computedOwningUser.avatarurl" alt="img" />
        </div>
      </div>

      <span>
        <i class="bi bi-play me-1"></i>
        Since
        {{
          new Date($store.state.currentClientDetails.createdat).toLocaleString(
            "en-US",
            { month: "short" }
          ) +
          ". " +
          new Date($store.state.currentClientDetails.createdat).getDate() +
          ", " +
          new Date($store.state.currentClientDetails.createdat).getFullYear()
        }}
      </span>
      <span class="mt-2">
        <i class="bi bi-geo-alt-fill me-1"></i>
        {{ $store.state.currentClientDetails.location }}
      </span>
    </div>
  </div>
  <!--end:Client info main-->



</template>

<script>
import StatusDropdown from "../../Dropdowns/StatusDropdown.vue";
import { updateProjectTeamAccessExternal } from "../../../js/projectdetails";
import { updateClientTeamAccessExternal } from "../../../js/clientdetails";

export default {
  components: {
    StatusDropdown,
  },
  computed: {
    computedOwningUser() {

      if(this.$store.state.currentRoute == '/projects') {
      let owningUser = this.$store.state.teammembers.find(
        (member) =>
          member.userid === this.$store.state.currentProjectDetails.owninguser
      );

      return owningUser;

      }
      if(this.$store.state.currentRoute == '/clients') {
              let owningUser = this.$store.state.teammembers.find(
        (member) =>
          member.userid === this.$store.state.currentClientDetails.owninguser
      );

      return owningUser;
      }

    },
  },
  mounted() {},
  methods: {
    updateProjectTeamAccess(teamaccess) {
      updateProjectTeamAccessExternal(teamaccess);
    },
    updateClientTeamAccess(teamaccess) {
      updateClientTeamAccessExternal(teamaccess);
    },
  },
};
</script>

<style>
</style>