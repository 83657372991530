<template>

  <!--begin::Main-->
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root" style="margin-top: -100px;">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">

      <!--begin::Body-->
      <div class="d-flex flex-column flex-lg-row-fluid">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <!--begin::Wrapper-->
          <div class="w-lg-500px p-10 p-lg-15 mx-auto">

              <!--begin::Heading-->
              <div class="text-center mb-10">
                <!--begin::Title-->
                <h1 class="text-white mb-3">Create account</h1>
                <!--end::Title-->
                              <!--begin::Link-->
                <div class="text-gray-400 fw-bold fs-4">And get instant access to the project
                  
                </div>
                <!--end::Link-->
              </div>
              <!--begin::Heading-->
              <!--begin::Input group-->
              <div class="fv-row mb-5">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder required text-white">Email</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  v-model="email"
                  class="form-control form-control-lg form-control-solid"
                :class="{
                            'border':
                              emailempty,
                            'border-danger':
                              emailempty,
                            'border-3':
                              emailempty,
                          }"
                  type="text"
                  name="email"
                  autocomplete="off"
                />
                <!--end::Input-->
                <div class="text-muted mt-2">Please use your professional email.</div>
                <div v-if="emailsdonotmatch" class="text-danger mt-1">Emails do not match.</div>

              </div>
              <!--end::Input group-->
                            <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 required fw-bolder text-white">Confirm Email</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  v-model="emailconfirmation"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="email"
                  autocomplete="off"
                />
                <!--end::Input-->
                <div v-if="emailsdonotmatch" class="text-danger mt-3">Emails do not match.</div>

              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row mb-5">
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack mb-2">
                  <!--begin::Label-->
                  <label class="form-label fw-bolder required text-white fs-6 mb-0">Password</label>
                  <!--end::Label-->

                </div>
                <!--end::Wrapper-->
                <!--begin::Input-->
                <input
                  v-model="password"
                  class="form-control form-control-lg form-control-solid"
                                  :class="{
                            'border':
                              passwordempty,
                            'border-danger':
                              passwordempty,
                            'border-3':
                              passwordempty,
                          }"
                  type="password"
                  name="password"
                  autocomplete="off"
                />
                <!--end::Input-->
                <div v-if="passwordsdonotmatch" class="text-danger mt-3">Passwords do not match.</div>
                <div v-if="passwordtooshort" class="text-danger mt-3">Password too short (min 6 characters).</div>

              </div>
              <!--end::Input group-->
                            <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack mb-2">
                  <!--begin::Label-->
                  <label class="form-label fw-bolder required text-white fs-6 mb-0">Confirm Password</label>
                  <!--end::Label-->

                </div>
                <!--end::Wrapper-->
                <!--begin::Input-->
                <input
                  v-model="passwordconfirmation"
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  name="password"
                  autocomplete="off"
                />
                <!--end::Input-->
                <div v-if="passwordsdonotmatch" class="text-danger mt-3">Passwords do not match.</div>

              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="text-center">

              </div>
              <!--end::Actions-->
            
        
            <!--begin::Submit button-->
            <button
              @click="signup()"
              class="btn btn-lg btn-primary w-100 mb-5"
            >
              <span class="indicator-label">Signup & Access project</span>
              <span class="indicator-progress">Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            <!--end::Submit button-->

          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
        <!--begin::Footer-->
        <div class="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <!--begin::Links-->
          <div class="d-flex flex-center fw-bold fs-6">
            <span class="text-primary">Kiwano 2023 | All rights reserved</span>
            <a
          href="https://kiwanotalent.com"
              class="d-none text-muted text-hover-primary px-2"
              target="_blank"
            >About</a>

          </div>
          <!--end::Links-->
        </div>
        <!--end::Footer-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
  <!--end::Root-->
  <!--end::Main-->

</template>

<script>

import { clientSignUpFunc } from "../js/auth"


export default {
  data() {
    return {
      email: "",
      emailconfirmation: "",
      password: "",
      passwordconfirmation: "",
      emailsdonotmatch: false,
      passwordsdonotmatch: false,
      passwordtooshort: false,
      emailempty: false,
      passwordempty: false
    };
  },
  methods: {

      signup() {

        let oktogo = true

        if(this.email != this.emailconfirmation) {
            this.emailsdonotmatch = true
            oktogo = false
        }
        if(this.password != this.passwordconfirmation) {
            this.passwordsdonotmatch = true
            oktogo = false
        }
        if(this.password.length < 6) {
            this.passwordtooshort = true
            oktogo = false
        }
        if(this.email == "") {
            this.emailempty = true
            oktogo = false
        }
        if(this.password == "") {
            this.passwordempty = true
            oktogo = false
        }

        if(oktogo) {
            //signup

        const queryString = window.location.href.split("?")[1];

        const queryStringArray = queryString.split("&");

        //the object holding the query string parameters
        //params: teamid (not used yet), projectid, teamlogourl
        let params = {};

        for (let i = 0; i < queryStringArray.length; i++) {
          const thisParamArray = queryStringArray[i].split("=");
          const key = thisParamArray[0];
          const value = thisParamArray[1];
          params[key] = value;
        }


            clientSignUpFunc(this.email, this.password, params.teamid, params.projectid)
        }
    
      }


  },
  mounted() {
      
  },
};
</script>

<style>
</style>