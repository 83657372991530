<template>
  <Signup></Signup>
</template>

<script>

import Signup from '../components/Signup-Component.vue'

export default {
      components: {
        Signup
    },

}
</script>

<style>

</style>