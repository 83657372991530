
import { onSnapshot, query, collection, collectionGroup, getDocs, where, doc, updateDoc, getDoc, orderBy, addDoc, limit, getCountFromServer } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut, setPersistence } from "firebase/auth";

import { db } from "../firebase/index";
import store from "../store/index";
import route from "../router/index"



//Activity card

/*
const countActivityForThisUser = () => {


    let currentTimestamp = Date.now()

    let lastMonth = currentTimestamp - 2629800000

    let twoMonthsAgo = lastMonth - 2629800000
    let threeMonthsAgo = twoMonthsAgo - 2629800000
    let fourMonthsAgo = threeMonthsAgo - 2629800000
    let fiveMonthsAgo = fourMonthsAgo - 2629800000
    let sixMonthsAgo = fiveMonthsAgo - 2629800000
    let sevenMonthsAgo = sixMonthsAgo - 2629800000
    let eightMonthsAgo = sevenMonthsAgo - 2629800000
    let nineMonthsAgo = eightMonthsAgo - 2629800000
    let tenMonthsAgo = nineMonthsAgo - 2629800000
    let elevenMonthsAgo = tenMonthsAgo - 2629800000
    let twelveMonthsAgo = elevenMonthsAgo - 2629800000




    //User activity this month
    const qThisMonth = query(
        collectionGroup(db, "activitylogs"),
        where("byuser", "==", store.state.userData.userid),
        where("timestamp", ">", lastMonth)
    );
    getCountFromServer(qThisMonth).then((activitySnap) => {
        let activityCount = activitySnap.data().count;
        store.state.userActivityThisMonth = activityCount
    });


    //User activity last month
    const qLastMonth = query(
        collectionGroup(db, "activitylogs"),
        where("byuser", "==", store.state.userData.userid),
        where("timestamp", "<", lastMonth),
        where("timestamp", ">", twoMonthsAgo),
    );
    getCountFromServer(qLastMonth).then((activitySnap) => {
        let activityCount = activitySnap.data().count;
        store.state.userActivityLastMonth = activityCount
    });

    //User activity 2 months ago
    const qtwoMonthsAgo = query(
        collectionGroup(db, "activitylogs"),
        where("byuser", "==", store.state.userData.userid),
        where("timestamp", "<", twoMonthsAgo),
        where("timestamp", ">", threeMonthsAgo),
    );
    getCountFromServer(qtwoMonthsAgo).then((activitySnap) => {
        let activityCount = activitySnap.data().count;
        store.state.userActivityTwoMonthsAgo = activityCount
    });


    //User activity 3 months ago
    const qthreeMonthsAgo = query(
        collectionGroup(db, "activitylogs"),
        where("byuser", "==", store.state.userData.userid),
        where("timestamp", "<", threeMonthsAgo),
        where("timestamp", ">", fourMonthsAgo),
    );
    getCountFromServer(qthreeMonthsAgo).then((activitySnap) => {
        let activityCount = activitySnap.data().count;
        store.state.userActivityThreeMonthsAgo = activityCount
    });

    //User activity 4 months ago
    const qfourMonthsAgo = query(
        collectionGroup(db, "activitylogs"),
        where("byuser", "==", store.state.userData.userid),
        where("timestamp", "<", fourMonthsAgo),
        where("timestamp", ">", fiveMonthsAgo),
    );
    getCountFromServer(qfourMonthsAgo).then((activitySnap) => {
        let activityCount = activitySnap.data().count;
        store.state.userActivityFourMonthsAgo = activityCount
    });


    //and so on...

}
*/

const countActivityForThisUser = async () => {


    const MONTHS_TO_TRACK = 12; // Number of months to track

    let monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

    let now = new Date();
    let currentYear = now.getFullYear();
    let currentMonth = now.getMonth(); // Note: JavaScript months are 0-based

    // Clear the existing data
    store.state.userMonthlyActivity = [];

    for (let i = 1; i <= MONTHS_TO_TRACK; i++) {
        let year = currentYear;
        let month = currentMonth - i;
        if (month < 0) {
            month += 12;
            year--;
        }

        // Create date objects for the start and end of the month
        let start = new Date(year, month, 1).getTime(); // Start of the month
        let end = new Date(year, month + 1, 0).getTime(); // End of the month

        if(store.state.userData.userid) {
            try {
                let q = query(
                    collectionGroup(db, "activitylogs"),
                    where("byuser", "==", store.state.userData.userid),
                    where("timestamp", ">=", start),
                    where("timestamp", "<=", end)
                );
    
                let activitySnap = await getCountFromServer(q);
                let activityCount = activitySnap.data().count;
    
                let monthName = monthNames[month];
                store.state.userMonthlyActivity.unshift([monthName, activityCount]);
    
            } catch (error) {
                console.error("An error occurred when fetching activities for past months: ", error);
            }
        }



        // Now get the activity for the current month

        let startOfMonth = new Date(currentYear, currentMonth, 1).getTime(); // Start of the current month
        let currentDate = new Date().getTime(); // Current date

        if(store.state.userData.userid) {

        try {
            let qCurrentMonth = query(
                collectionGroup(db, "activitylogs"),
                where("byuser", "==", store.state.userData.userid),
                where("timestamp", ">=", startOfMonth),
                where("timestamp", "<=", currentDate)
            );

            let activitySnapCurrentMonth = await getCountFromServer(qCurrentMonth);
            let activityCountCurrentMonth = activitySnapCurrentMonth.data().count;

            store.state.userActivityForThisMonth = activityCountCurrentMonth; // Store the activity count for the current month

        } catch (error) {
            console.error("An error occurred when fetching activities for the current month: ", error);
        }

    }
    }
};


const countNewProjectsForThisUser = async () => {
    const MONTHS_TO_TRACK = 12; // Number of months to track

    let monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

    let now = new Date();
    let currentYear = now.getFullYear();
    let currentMonth = now.getMonth(); // Note: JavaScript months are 0-based

    // Clear the existing data
    store.state.userMonthlyNewProjects = [];

    for (let i = 1; i <= MONTHS_TO_TRACK; i++) {
        let year = currentYear;
        let month = currentMonth - i;
        if (month < 0) {
            month += 12;
            year--;
        }

        // Create date objects for the start and end of the month
        let start = new Date(year, month, 1).getTime(); // Start of the month
        let end = new Date(year, month + 1, 0).getTime(); // End of the month

        if(store.state.userData.userid) {
            let q = query(
                collection(db, "projects"),
                where("owninguser", "==", store.state.userData.userid),
                where("startedat", ">=", start),
                where("startedat", "<=", end)
            );
    
            let projectSnap = await getCountFromServer(q);
            let projectCount = projectSnap.data().count;
    
            let monthName = monthNames[month];
            store.state.userMonthlyNewProjects.unshift([monthName, projectCount]);
    
        }


    }

    // Now get the projects for the current month
    let startOfMonth = new Date(currentYear, currentMonth, 1).getTime(); // Start of the current month
    let currentDate = new Date().getTime(); // Current date

    if(store.state.userData.userid) {


        let q = query(
            collection(db, "projects"),
            where("owninguser", "==", store.state.userData.userid),
            where("startedat", ">=", startOfMonth),
            where("startedat", "<=", currentDate)
        );

        let projectSnap = await getCountFromServer(q);
        let projectCount = projectSnap.data().count;



    let projectCountCurrentMonth = projectCount


    store.state.userProjectsForThisMonth = projectCountCurrentMonth; // Store the project count for the current month


    } else {
        //console.log("No user identified")
    }
};


const calculateAvgTimeForhire = () => {


    setTimeout(() => {

        //let timeToHireAvgArray;

        let trackedStatusIds = store.state.profileStatusList
    .filter(status => status.tracked === true)
    .map(status => status.id);

    let sumTimes = {};  // Initialize an object to store the sum of timespans for each status
    let countTimes = {};  // Initialize an object to store the count of timespans for each status
    
    // Go through each project
    store.state.currentMyProjectList.forEach(project => {
        // Go through each object in the 'timetohire' array if it exists
        project.timetohire?.forEach(timeObj => {
            // Check if the status is tracked
            if (trackedStatusIds.includes(timeObj.statusid)) {
                // If it is, add the timespan to the sum and increment the count
                if (sumTimes.hasOwnProperty(timeObj.statusid)) {
                    sumTimes[timeObj.statusid] += timeObj.timespan;
                    countTimes[timeObj.statusid] += 1;
                } else {
                    sumTimes[timeObj.statusid] = timeObj.timespan;
                    countTimes[timeObj.statusid] = 1;
                }
            }
        });
    });
    
    // Calculate the average timespan for each tracked status
    let avgTimes = {};
    for (let statusId in sumTimes) {
        avgTimes[statusId] = sumTimes[statusId] / countTimes[statusId];
    }
    

    store.state.avgTimeToReach = avgTimes;
    
      }, 2000);
    

}

const selectStatusToTrackExternal = async (statusobject) => {



    if (!statusobject.tracked) {
        //track the status
        // check how many objects within the store.state.profileStatusList have a .tracked property set to true
        const trackedCount = store.state.profileStatusList.filter(item => item.tracked).length;

        if (trackedCount <= 3) {
            // if there are 3 or less, run the code below
            await updateDoc(doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist", statusobject.id), {
                tracked: true
            }).then(() => {
                //update the TimeToHire component key to force the component to re-render and thus re-calculating the average for the new tracked status
                store.state.timeToHireComponentKey = store.state.timeToHireComponentKey + 1
            });
        } else {
            // find the object with the same id as statusobject.id and set its tracked property to false
            const objectToUpdate = store.state.profileStatusList.find(item => item.id === statusobject.id);
            if (objectToUpdate) {
                //reverse (cancel) the automatic checkbox check
                setTimeout(() => {
                    objectToUpdate.tracked = false;
                    alert("You can track a mamimum of 4 status items. Please uncheck a status before selecting another one.")

                }, 300);
            }

        }

    }


    else {
        //un-track the status
        await updateDoc(doc(db, "teams/" + store.state.userData.teamid + "/profilestatuslist", statusobject.id), {
            tracked: false
        }).then(() => {
            // Code to run after successfully updating the document
        });

    }


}

export { countActivityForThisUser, countNewProjectsForThisUser, selectStatusToTrackExternal,
    calculateAvgTimeForhire }

