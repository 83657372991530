<template>
  <div class="ms-20">
    <div class="fs6 text-muted fw-bold animate__animated">
      <div v-if="$store.state.userData.subscriptiontype == 'free'">
        We're grateful for your participation in our Beta
        testing program. As a Beta user, you have a free and unrestricted access
        to all features. Please be aware that this is a pre-release version, and
        you may come across bugs.
      </div>

      <div v-if="$store.state.userData.subscriptiontype == 'trial'">
        We're very happy to have you onboard. You now have a
        2 weeks trial access to all features. You can upgrade at anytime by
        clicking on "Upgrade" in the top-banner.
      </div>

      <div class="mb-7" v-if="$store.state.userData.subscriptiontype == 'paid'">
        Thank you for subscribing! We're very thankful for your trust and
        thrilled to be part of your executive search workflow.
      </div>

      <br />
      We truly appreciate any feedback, whether positive or negative, and we're
      here to assist with any support or general inquiries. Feel free to reach
      out to us anytime at
      <a href="mailto:support@kiwanotalent.com">support@kiwanotalent.com</a>. Or
      use the Chat for a quicker response.

      <div class="d-flex mt-10">
        <button
          @click="closemodalandlaunchguide"
          class="mx-auto btn btn-lg btn-primary"
        >
          Got it !
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    closemodalandlaunchguide() {
      // Show the modal
      $("#welcomeUser").modal("hide");

      setTimeout(() => {
         document.getElementById("guidelauncher").click() 
      }, 1000);
    },
  },
};
</script>

<style></style>
