<template>
  

              <!--begin::Input group-->
              <div class="mt-20 fv-row mb-2">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark">Your email</label>
                <!--end::Label-->
                <div class="d-flex">
                <!--begin::Input-->
                <input
                  v-model="resetpasswordemeail"
                  class="form-control form-control-xs form-control-solid"
                  type="text"
                  name="email"
                  autocomplete="off"
                />
                <!--end::Input-->
                <button v-if="!$store.state.resetemailsent" class="btn btn-sm btn-primary ms-5 w-150px" @click="sendResetLink(resetpasswordemeail)">Send link</button>
                </div>

              </div>
              <!--end::Input group-->
              <div class="mt-2 badge badge-lg badge-danger" v-if="$store.state.usernotfound">User not found</div>
              <div class="mt-2 badge badge-lg badge-success" v-if="$store.state.resetemailsent">Link sent</div>


            <Alert alerttype="info" class="mt-10" text="If you have an existing account with us under this email, you will receive a link to change your password. Please check your Spam folder if you haven't received anything."></Alert>



</template>

<script>

import { sendResetLinkExternal } from "../../../js/auth";

import Alert from "../../CardAlerts/CardAlert.vue"


export default {
    
    components: {
        Alert
    },

    data() {
        return {
            resetpasswordemeail: ""
        }
    },
    methods: {
        sendResetLink(email) {

            this.$store.state.usernotfound = false;

            //check if email is valid
            if(!email.includes("@") || !email.includes(".")) {
                alert("Please enter a valid email.")
            }

            
            sendResetLinkExternal(email)
        }
    }
}
</script>

<style>

</style>