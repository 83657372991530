<template>

  <!--begin::Main-->
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">

      <!--begin::Body-->
      <div class="d-flex flex-column flex-lg-row-fluid">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <!--begin::Wrapper-->
          <div class="w-lg-500px p-10 p-lg-15 mx-auto">

              <!--begin::Heading-->
              <div class="text-center mb-10">
                <!--begin::Title-->
                <h1 class="text-white mb-3">Log-in to access the project</h1>
                <!--end::Title-->
              </div>
              <!--begin::Heading-->
              <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-white">Email</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  v-model="signinemail"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="email"
                  autocomplete="off"
                />
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack mb-2">
                  <!--begin::Label-->
                  <label class="form-label fw-bolder text-white fs-6 mb-0">Password</label>
                  <!--end::Label-->
                  <!--begin::Link-->
                  <a
                  href="#"
                    class="link-muted fs-6 fw-bolder"
                    data-bs-toggle="modal"
                    data-bs-target="#resetpassword"
                  >Forgot Password ?</a>
                  <!--end::Link-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Input-->
                <input
                  v-model="signinpassword"
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  name="password"
                  autocomplete="off"
                />
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="text-center">

              </div>
              <!--end::Actions-->
            
        
            <!--begin::Submit button-->
            <button
              @click="clientSignIn()"
              class="btn btn-lg btn-primary w-100 mb-5"
            >
              <span class="indicator-label">Log-in</span>
              <span class="indicator-progress">Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            <!--end::Submit button-->

          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
        <!--begin::Footer-->
        <div class="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <!--begin::Links-->
          <div class="d-flex flex-center fw-bold fs-6">
            <span class="text-primary">Kiwano 2023 | All rights reserved</span>
            <a
              href="https://kiwanotalent.com"
              class="d-none text-muted text-hover-primary px-2"
              target="_blank"
            >About</a>
            <a
              href="https://kiwanotalent.com/userguide"
              class="d-none text-muted text-hover-primary px-2"
              target="_blank"
            >Support</a>
            <a
              href="https://1.envato.market/EA4JP"
              class="d-none text-muted text-hover-primary px-2"
              target="_blank"
            >Purchase</a>
          </div>
          <!--end::Links-->
        </div>
        <!--end::Footer-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
  <!--end::Root-->
  <!--end::Main-->

</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../firebase/index";
import router from "../router/index";
import { clientSigninFunc } from "../js/auth";

export default {
  data() {
    return {
      signinemail: "",
      signinpassword: "",
      params: {}
    };
  },
  methods: {

      clientSignIn() {
          //imported from js/auth.js
          clientSigninFunc(this.signinemail, this.signinpassword, this.params.projectid)
      }


  },
  mounted() {

    const queryString = window.location.href.split("?")[1];

    const queryStringArray = queryString.split("&");

    //the object holding the query string parameters
    //params: teamid (not used yet), projectid, teamlogourl

    for (let i = 0; i < queryStringArray.length; i++) {
      const thisParamArray = queryStringArray[i].split("=");
      const key = thisParamArray[0];
      const value = thisParamArray[1];
      this.params[key] = value;
    }
      
  },
};
</script>

<style>
</style>