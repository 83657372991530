<template>

<div class="fs-7">


<h5 class="mb-5 mt-15">Article 1 – Introduction</h5>
<p><span class="fw-boldest">1.1.</span> LAKESIDE TALENT SOURCING, a company incorporated and existing under the laws of Belgium registered with the Crossroads Bank under the number 0780.639.271, and having its registered office at 1000 Brussels Square de Meeus, 35 (“Provider”), has developed a software (“Software”) for the purpose of facilitate candidate recruitment by recruitment companies (“User”).</p>
<p><span class="fw-boldest">1.2.</span> The Provider is holder of the intellectual property rights on the Software.</p>
<p><span class="fw-boldest">1.3.</span> The User desires to use the Software in the conduct of its business.</p>
<p><span class="fw-boldest">1.4.</span> These Terms and Conditions of Use (“Terms”) establish a contract for the license and provision of services between the Provider and the User for the using of the Software.</p>
<p><span class="fw-boldest">1.5.</span> Parties are referred to as "Party" individually and as "Parties" collectively within the context of these Terms.</p>

<h5 class="mb-5 mt-15">Article 2 – Scope</h5>
<p><span class="fw-boldest">2.1.</span> The Provider grants the User a non-exclusive, non-sublicensable and non-transferable licence to use the Software according to the conditions in these Terms.</p>
<p><span class="fw-boldest">2.2.</span> User will have a two-week free trial period starting from their first use of the Software. Users may also cancel their subscription at any time during this trial period and no charges will apply.</p>

<h5 class="mb-5 mt-15">Article 3 – Financial Consideration</h5>
<p><span class="fw-boldest">3.1.</span> Users shall pay to Provider a monthly subscription fee of 89 EUR (“Subscription”).</p>
<p><span class="fw-boldest">3.2.</span> In the event of non-payment of Subscription by the due date, the User shall be liable for both interest at a rate of 12% per annum and a penalty charge equal to 10% of the Subscription. No prior notice shall be required for the application of this charge.</p>

<h5 class="mb-5 mt-15">Article 4 – Duration</h5>
<p>These Terms shall take effect upon the Users' first use of the Software and shall extend until the last day of the Subscription period.</p>

<h5 class="mb-5 mt-15">Article 5 – Software Updates</h5>
<p>The Provider reserves the right to modify, update or enhance the Software without prior notice to User. Such modifications shall not constitute grounds for User to terminate these Terms before the duration stipulated in article 4.</p>

<h5 class="mb-5 mt-15">Article 6 – Liability</h5>
<p><span class="fw-boldest">6.1.</span> Except in cases provided for under Articles 5.89 1° (intentional fault) and 2° (fault causing harm to a person's physical integrity) of the Belgian Civil Code, the Provider shall in no event be liable to the User or any third party for any direct, indirect, special, incidental, or consequential damages arising from the performance or non-performance of its obligations towards the User.</p>
<p><span class="fw-boldest">6.2.</span> Pursuant to Article 5.89 of the Belgian Civil Code, the Provider disclaims all liability, including in cases of gross negligence, towards the User.</p>
<p><span class="fw-boldest">6.3.</span> The Provider's role is strictly limited to providing software solutions and services, without access to or knowledge of any information protected by trade secrets [Regulation 2016/43] between the User, candidates, and/or interested companies. The Provider shall not be held liable for any damages, losses, or disputes that may arise between User, candidates, and/or interested companies.</p>
<p><span class="fw-boldest">6.4.</span> In any event, the Provider's liability for all compensation claims shall not exceed 10% of the Subscription agreed upon between the User and the Provider.</p>

<h5 class="mb-5 mt-15">Article 7 – Early Termination</h5>
<p><span class="fw-boldest">7.1.</span> Without prejudice to article 4, the Provider shall have the rights to terminate the Terms immediately and without prior notice in the following circumstances: In the event of User’s bankruptcy, judicial reorganization or any similar proceedings; If the User fails to comply with any obligation under these Terms and such non-compliance exceed a period of 30 days after the User receiving a notice via email by the Provider; In case of non-payment of any amounts due by the User under Article 3.</p>
<p><span class="fw-boldest">7.2.</span> In the event of early termination of these Terms, User shall remain obliged to pay the full Subscription as well as any additional amounts due under Articles 3.1 and 3.2. The User's payment obligations shall not be affected by the early termination, and the Provider reserves the right to seek full payment for any additional losses incurred.</p>

<h5 class="mb-5 mt-15">Article 8 – Confidentially</h5>
<p><span class="fw-boldest">8.1.</span> Any information which is communicated by a Party to the other within the framework of these Terms will be considered confidential information (“Information”).</p>
<p><span class="fw-boldest">8.2.</span> The User undertakes not to disclose the Information and to give access to it only to persons under its direct responsibility (employees, colleagues, agents, customers, etc.) and/or to persons with whom the User works (service providers, representatives, experts, lawyers, auditors, investors, affiliates, parent companies, assignees, etc.). The User agrees to inform third parties of the private nature of Information and to require such persons to treat confidential information as stipulated in this provision.</p>
<p><span class="fw-boldest">8.3.</span> The above non-disclosure obligation does not apply to the following: information in the public domain or that subsequently enters the public domain through the use or publication thereof or other similar action, other than through a breach or wrongdoing by the User; information provided to the User by a third party authorized to disclose it and who did not himself receive the information on a confidential basis; information which the User is required to disclose in response to a valid order by a court or a state or equivalent authority (tax authority, etc.), provided that the User inform the Provider and (if applicable) any other client(s) of the Provider concerned by the Information in writing, in advance where possible or otherwise as soon as possible, and concert with each other in order to limit the extent of such disclosure; or Information which the User has been expressly permitted to disclose by any other user(s) of the Provider concerned by the Information.</p>
<p><span class="fw-boldest">8.4.</span> This provision applies for the duration of these Terms and for a period of five (5) years after termination of these Terms.</p>

<h5 class="mb-5 mt-15">Article 9 – Intellectual Property Rights</h5>
<p><span class="fw-boldest">9.1.</span> The Provider is the sole owner of the Software, including all the intellectual property rights on this object, such as but not limited to copyrights, software and computer program rights, preparatory documents to the software, trademarks, designs, patents and know how.</p>
<p><span class="fw-boldest">9.2.</span> The User acknowledges that no intellectual property rights are transferred to it in the execution of these Terms and that no rights or licences are granted to the rights licensed based on these Terms.</p>

<h5 class="mb-5 mt-15">Article 10 – Data protection</h5>
<p><span class="fw-boldest">10.1.</span> Pursuant to these Terms and within the scope of using the Software, the User is qualified as the  data controller responsible for the processing of the personal data in accordance with applicable laws and regulations, including to the General Data Protection Regulation (EU) 2016/679. The Provider acts as a processor insofar if requested by User, e.g. by storing the data relating to candidates for recruitment in the context of the User’s activity. In this case the Parties are obliged to conclude a separate Data Processing Agreement (“DPA”) that set forth specific terms and conditions governing the processing of personal data by the Provider as and to the extent requested by the User. The DPA is attached as Annex I hereto.</p>

<h5 class="mb-5 mt-15">Article 11 – Force majeure</h5>
<p><span class="fw-boldest">11.1.</span> “Force Majeure Event" means any event or circumstance beyond the reasonable control of the Provider, including but not limited to acts of God, natural disasters, war, civil unrest, terrorism, strikes, labor disputes, government actions or regulations, pandemics, or any other similar event.</p>
<p><span class="fw-boldest">11.2.</span> The Provider shall not be liable for any failure or delay in the performance of its obligations under these Terms to the extent such failure or delay is caused by a Force Majeure Event.</p>
<p><span class="fw-boldest">11.3.</span> If a Force Majeure Event prevents or delays the performance of any obligation under these Terms for a period exceeding 30 consecutive days, the Provider may, at its option, terminate these Terms without any obligation to compensate the User, without prejudice to Article 4.</p>

<h5 class="mb-5 mt-15">Article 12 – Acceptance of Terms</h5>
<p>By utilizing the Software, the User fully and unconditionally accepts the Terms. If the User does not agree to these Terms, they should refrain from using the Software.</p>

<h5 class="mb-5 mt-15">Article 13 – Supremacy</h5>
<p>These Terms prevail any other specific terms and conditions of the User.</p>

<h5 class="mb-5 mt-15">Article 14 – Relationship between the Parties</h5>
<p>None of the provisions of these Terms can be interpreted as indicating the intent of the Parties to form a company, association or joint venture.</p>

<h5 class="mb-5 mt-15">Article 15 – Entire Terms and Conditions</h5>
<p><span class="fw-boldest">15.1.</span> These Terms, together with its Annex, contains the entire Terms and understanding between the Parties with respect to subject matter hereof and supersedes and replaces all prior agreements or understandings, whether written or oral, with respect to the same subject matter that are still in force between the Parties.</p>
<p><span class="fw-boldest">15.2.</span> Any amendments to these Terms, as well as any additions or deletions, must be agreed in writing by both Parties.</p>

<h5 class="mb-5 mt-15">Article 16 – Severability</h5>
<p>Whenever possible, the provisions of these Terms shall be interpreted in such a manner as to be valid and enforceable under the applicable law. However, if one or more provisions of these Terms are found to be invalid, illegal or unenforceable, in whole or in part, the remainder of that provision and of these Terms shall remain in full force and effect as if such invalid, illegal or unenforceable provision had never been contained herein. Moreover, in such an event, the Parties shall amend the invalid, illegal or unenforceable provision(s) or any part thereof and/or agree a new provision in such a way to reflect insofar as possible the purpose of the invalid, illegal pr unenforceable provision(s).</p>

<h5 class="mb-5 mt-15">Article 17 – Applicable law and jurisdiction</h5>
<p><span class="fw-boldest">17.1</span> All issues, questions and disputes concerning the validity, interpretation, enforcement, performance and termination of these Terms shall be governed by and construed in accordance with Belgian law.</p>
<p><span class="fw-boldest">17.2.</span> All disputes concerning the validity, interpretation, enforcement, performance and termination of these Terms shall be submitted to the exclusive jurisdiction of the courts of Brussels.</p>


</div>
Annex: DPA


</template>

<script>
export default {

}
</script>

<style>

</style>