<template>
    
    <div v-if="loadingsimulation" style="margin-left: 45%; margin-right: 20%; margin-top: 10%;">
    <div class="ms-5 mt-10 me-20" id="preloader_1">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
     </div>
    </div>



    <RedirectToPortal v-if="$store.state.userData.id && !loadingsimulation && $store.state.profileStatusList != []"></RedirectToPortal>
    <ClientAuthHome v-if="$store.state.clientauthstatus == 'home' && !loadingsimulation"></ClientAuthHome>
    <ClientSignup v-if="$store.state.clientauthstatus == 'signup' && !loadingsimulation"></ClientSignup>
    <ClientSignin v-if="$store.state.clientauthstatus == 'signin' && !loadingsimulation"></ClientSignin>
    

    

</template>

<script>



import ClientAuthHome from "../components/ClientAuthHome.vue"
import ClientSignup from "../components/ClientSignup.vue"
import ClientSignin from "../components/ClientSignin.vue"
import RedirectToPortal from "../components/RedirectToPortal.vue"

export default {

    data() {
        return {
            loadingsimulation: true
        }
    },

components: {
    ClientAuthHome, ClientSignup, ClientSignin, RedirectToPortal
},
mounted() {
    setTimeout(() => {
        this.loadingsimulation = false
    }, 1800);
}

}
</script>

<style>

</style>