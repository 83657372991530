<template>

<div v-if="!showPreviewArrows" class="profilespreviewheader shadow animate__animated animate__fadeInLeft" style="--animate-duration: 0.40s;">
<div class="previewheadercard">

<div class="h3">
    <div v-if="$store.state.profilesViewInCache == 'recentProfiles'">
        Recent profiles
    </div>
    <div v-if="$store.state.profilesViewInCache == 'projectProfiles'">
        {{$store.state.currentProjectDetailsForProfileList.positiontitle && $store.state.currentProjectDetailsForProfileList.positiontitle.length > 10 ? $store.state.currentProjectDetailsForProfileList.positiontitle.slice(0, 10) + '...' : $store.state.currentProjectDetailsForProfileList.positiontitle }}
        {{ " / " }}
       {{ $store.state.currentProjectDetailsForProfileList.clientobject.clientname && $store.state.currentProjectDetailsForProfileList.clientobject.clientname.length > 10 ? $store.state.currentProjectDetailsForProfileList.clientobject.clientname.slice(0, 10) + '...' : $store.state.currentProjectDetailsForProfileList.clientobject.clientname}}
    </div>

</div>

</div>
</div>



  <div
    class="card profilespreviewcard animate__animated animate__fadeInLeft"
    :class="{'shadow-lg': $store.state.profilePreviewHoverOn}"
    @mouseenter="toggleHover('enter')"
    @mouseleave="toggleHover('leave')"
  >
    <!--begin::Body-->

    <div>
      <div v-if="showPreviewArrows" class="fade-overlay customarrowup">
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr073.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-2hx"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <div
        class="hover-scroll-y mh-200px mh-lg-425px me-3 mt-4"
        ref="scrollContainer"
      >
        <div
          class="ms-3 py-3 bg-hover-light"
          v-for="profile in $store.state.currentProfileList"
          :key="profile.id"
          ref="profileDivs"
          @click="goToDetailedProfile(profile.id)"
        >
          <div class="d-flex ms-2">
            <div
              class="symbol symbol-35px me-5"
              :class="{
                border: profile.profilepic,
                'border-solid': profile.profilepic,
                'border-1': profile.profilepic && (profile.id != $store.state.currentProfileDetails.id),
                'border-0': profile.profilepic && (profile.id == $store.state.currentProfileDetails.id),
                'border-secondary': profile.profilepic && (profile.id != $store.state.currentProfileDetails.id),
                'border-warning': profile.profilepic && (profile.id == $store.state.currentProfileDetails.id),
                'p-1': profile.profilepic,
                'bg-warning': profile.id == $store.state.currentProfileDetails.id
              }"
              style="border-radius: 7px"
            >
              <img
                alt="Pic"
                v-if="profile.profilepic"
                :src="profile.profilepic"
                onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
              />

              <div
                class="border border-solid border-1 border-secondary p-1"
                style="border-radius: 7px"
                v-if="!profile.profilepic"
              >
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/communication/com013.svg-->
                <span class="svg-icon svg-icon-muted svg-icon-2hx"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                      fill="currentColor"
                    />
                    <rect
                      opacity="0.3"
                      x="8"
                      y="3"
                      width="8"
                      height="8"
                      rx="4"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </div>
            </div>
            <div
              v-if="$store.state.profilePreviewHoverOn"
              class="d-flex flex-column animate__animated animate__fadeIn"
              style="margin-bottom: -2px"
            >
              <span class="text-dark fw-bolder fs-7">
                {{ profile.firstname + " " + profile.lastname }}
              </span>
              <span class="text-muted fw-bold d-block fs-7 w-170px">
                {{ profile.currentcompany && profile.currentcompany.length > 15 ? profile.currentcompany.slice(0, 15) + '...' : profile.currentcompany }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showGradientFade" class="fade-overlay fade-overlay-top"></div>
      <div
        v-if="showGradientFade"
        class="fade-overlay fade-overlay-bottom"
      ></div>
      <div v-if="showPreviewArrows" class="fade-overlay customarrowdown">
        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr072.svg-->
        <span class="svg-icon svg-icon-muted svg-icon-2hx"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { goToDetailedProfileExternal } from "../js/navigation";

export default {
  data() {
    return {
      showGradientFade: true,
      showPreviewArrows: true,
    enterTimeoutId: null,
    leaveTimeoutId: null,
    };
  },
  methods: {
    toggleHover(direction) {
  if (direction == "enter") {
    clearTimeout(this.leaveTimeoutId); // Clear the leave timeout when entering

    this.showPreviewArrows = false;
    this.showGradientFade = false;
    this.enterTimeoutId = setTimeout(() => {
      this.$store.state.profilePreviewHoverOn = !this.$store.state.profilePreviewHoverOn;
    }, 130);
  } else if (direction == "leave") {
    clearTimeout(this.enterTimeoutId); // Clear the enter timeout when leaving

    this.showPreviewArrows = true;
    this.leaveTimeoutId = setTimeout(() => {
      this.showGradientFade = true;
    }, 300);
    this.$store.state.profilePreviewHoverOn = !this.$store.state.profilePreviewHoverOn;
  }
},

    scrollToCurrentProfile() {
      const profileIndex = this.currentProfileList.findIndex(
        (profile) => profile.id === this.currentProfileDetails.id
      );

      if (profileIndex !== -1 && this.$refs.profileDivs[profileIndex]) {
        const targetDiv = this.$refs.profileDivs[profileIndex];
        const container = this.$refs.scrollContainer;

        const targetDivRect = targetDiv.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        container.scrollTop =
          container.scrollTop +
          targetDivRect.top -
          containerRect.top -
          container.offsetHeight / 2 +
          targetDiv.offsetHeight / 2;
      }
    },
    goToDetailedProfile(profileId) {
        this.$store.state.profileListScrollPosition = 0;
      goToDetailedProfileExternal(profileId);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToCurrentProfile();
      this.$store.state.profilePreviewHoverOn = false;
    });
  },
  computed: {
    ...mapState(["currentProfileList", "currentProfileDetails"]),
  },
};
</script>

<style>
</style>