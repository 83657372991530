<template>
  <div class="d-flex flex-column mb-3 mt-5">
    <textarea
      class="form-control form-control-solid"
      rows="3"
      name="target_details"
      id="profileTaskTextarea"
      placeholder="Add task here..."
      v-model="$store.state.profiletasktext"
    ></textarea>
  </div>

  <!--begin: Due date-->
  <div>
    <div class="mb-0 d-flex flex-column">
      <!--this input is hidden but kept here just to be clicked programatically to use it's datepicker functionality & v-model binding-->
      <input
        placeholder="Pick a date"
        id="kt_datepicker_2"
        style="height: 10px; visibility: hidden"
        v-model="$store.state.currentprofiletaskduedate"
      />
    </div>
    <button class="btn btn-sm btn-flex text-muted" @click="openDatePicker()">
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/general/gen013.svg-->
      <span
        class="svg-icon svg-icon-2"
        :class="{
          'svg-icon-primary':
            $store.state.currentprofiletaskduedate * 1 > Date.now(),
          'svg-icon-danger':
            $store.state.currentprofiletaskduedate * 1 < Date.now(),
          'svg-icon-muted': !$store.state.currentprofiletaskduedate,
        }"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
            fill="currentColor"
          />
          <path
            d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
      {{ taskDueDate }}
      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr072.svg-->
      <span class="svg-icon svg-icon-muted svg-icon-5"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </button>
  </div>
  <!--end: Due date-->

  <!--begin: Assign task manager-->
  <div class="mt-1">
    <div>
      <div>
        <div class="d-flex flex-row justify-content-start">
          <div class="d-flex">
            <!--begin::change task manager dropdown-->
            <div class="dropdown">
              <button
                class="btn btn-sm btn-flex"
                type="button"
                id="dropdownMenuButton4"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div
                  v-if="$store.state.currentprofiletaskassigneduser.avatarurl"
                  class="symbol symbol-circle symbol-20px"
                >
                  <img
                    :src="$store.state.currentprofiletaskassigneduser.avatarurl"
                    class="h-30 align-self-center"
                    alt=""
                  />
                </div>
                <div v-else class="symbol symbol-circle symbol-20px">
                  <div>
                    <div
                      v-if="
                        $store.state.currentprofiletaskassigneduser.firstname
                      "
                      class="symbol-label fs-8 fw-semibold text-primary"
                    >
                      {{
                        $store.state.currentprofiletaskassigneduser.firstname.substring(
                          0,
                          1
                        ) +
                        $store.state.currentprofiletaskassigneduser.lastname.substring(
                          0,
                          1
                        )
                      }}
                    </div>
                  </div>
                </div>

                <span class="ms-2 text-muted"
                  >Assigned to
                  {{ $store.state.currentprofiletaskassigneduser.firstname }}
                </span>
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                <span class="ms-2 svg-icon svg-icon-4 m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </button>
              <div
                v-if="$store.state.teammembers"
                class="dropdown-menu dropdown-menu-end w-300px w-md-150px dropdownFadeIn"
                aria-labelledby="dropdownMenuButton4"
              >
                <div
                  class="menu-item my-0 bg-hover-light"
                  v-for="user in filteredTeamMembers"
                  :key="user.userid"
                >
                  <div
                    class="d-flex"
                    v-if="
                      user.userid !=
                      $store.state.currentprofiletaskassigneduser.userid
                    "
                  >
                    <div
                      v-if="user.avatarurl"
                      class="symbol symbol-circle symbol-20px mt-1 ps-4"
                    >
                      <img
                        :src="user.avatarurl"
                        class="h-30 align-self-center"
                        alt=""
                      />
                    </div>
                    <div
                      v-else
                      class="symbol symbol-circle symbol-20px mt-1 ps-4"
                    >
                      <div>
                        <div class="symbol-label fs-8 fw-semibold text-primary">
                          {{
                            user.firstname.substring(0, 1) +
                            user.lastname.substring(0, 1)
                          }}
                        </div>
                      </div>
                    </div>
                    <a
                      class="menu-link text-muted py-2"
                      @click="reAssignUser(user)"
                      ><span class="">{{ user.firstname }}</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--end::change task manager dropdown-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end: Assign task manager-->

  <!--begin: Save comment Button-->
  <div class="d-flex justify-content-end mt-15">
    <button
      class="btn btn-sm btn-primary"
      data-bs-dismiss="modal"
      @click="addProfileTask($store.state.currentProfileDetails.id)"
    >
      Save task
    </button>
  </div>
  <!--end: Save comment Button-->
</template>

<script>
import { addProfileTaskExternal } from "../../../js/profiledetails";
import { reAssignUserExternal } from "../../../js/profiledetails";

export default {
  data() {
    return {};
  },
  props: ["origin"],
  methods: {
    addProfileTask(profileid) {
      if (this.origin == "batchmanipulatebanner") {
        let selectedProfiles = this.$store.state.selectedProfiles;
        for (let i = 0; i < selectedProfiles.length; i++) {
          addProfileTaskExternal(selectedProfiles[i], this.origin);
        }
      } else {
        addProfileTaskExternal(profileid, this.origin);
      }
    },
    logduedate() {
      console.log(this.$store.state.currentprofiletaskduedate);
    },
    openDatePicker() {
      document.getElementById("kt_datepicker_2").click();
    },
    reAssignUser(user) {
      reAssignUserExternal(user);
      //dropdownMenuButton4
      $("#dropdownMenuButton4").dropdown("hide");
    },
  },
  mounted() {
    $("#kt_datepicker_2").flatpickr({ dateFormat: "U\\000" });
  },
  computed: {
    filteredTeamMembers() {
      let teammembers = this.$store.state.teammembers.filter(
        (member) => member.subscriptiontype !== "client"
      );
      return teammembers;
    },
    taskDueDate() {
      //this calculates the number of days left till due date
      const nowTimestamp = new Date().getTime();
      const nowHours = new Date().getHours();
      const hoursLeftTillTomorrow = 23 - nowHours; //don't know why but it only works with 23, not 24...
      const millisecsLeftTillTomorrow = hoursLeftTillTomorrow * 3600000;
      const dueDateTimestamp = this.$store.state.currentprofiletaskduedate * 1;
      const timeTillDueDate = dueDateTimestamp - nowTimestamp;
      const timeTillDueDateMinusToday =
        timeTillDueDate - millisecsLeftTillTomorrow;

      const daysTillDueDate = timeTillDueDateMinusToday / 86400000;

      if (!dueDateTimestamp) {
        return "Pick a Due date";
      } else if (daysTillDueDate > -1 && daysTillDueDate < 0) {
        return "Due today";
      } else if (daysTillDueDate < 1 && daysTillDueDate > 0) {
        return "Due tomorrow";
      } else if (daysTillDueDate < 2 && daysTillDueDate > 1) {
        return "Due in 2 days";
      } else if (daysTillDueDate < 3 && daysTillDueDate > 2) {
        return "Due in 3 days";
      } else if (daysTillDueDate < 4 && daysTillDueDate > 3) {
        return "Due in 4 days";
      } else if (daysTillDueDate < 5 && daysTillDueDate > 3) {
        return "Due in 5 days";
      } else if (daysTillDueDate > 5) {
        const dueDate = new Date(dueDateTimestamp);
        let options = { weekday: "long", month: "short", day: "numeric" };
        return "Due " + dueDate.toLocaleString("en-US", options);
      } else if (daysTillDueDate < -1 && daysTillDueDate > -2) {
        return "Due Yesterday";
      } else if (daysTillDueDate < -2 && daysTillDueDate > -3) {
        return "Due 2 days ago";
      } else if (daysTillDueDate < -3 && daysTillDueDate > -4) {
        return "Due 3 days ago";
      } else if (daysTillDueDate < -4 && daysTillDueDate > -5) {
        return "Due 4 days ago";
      } else if (daysTillDueDate < -5 && daysTillDueDate > -6) {
        return "Due 5 days ago";
      } else if (daysTillDueDate < 6) {
        const dueDate = new Date(dueDateTimestamp);
        let options = { weekday: "long", month: "short", day: "numeric" };
        return "Due " + dueDate.toLocaleString("en-US", options);
      }
    },
  },
};
</script>

<style>
</style>