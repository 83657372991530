<template>

    <div
    class="d-flex justify-content-center"
    id="kt_app_main"
    style="margin-top: -30px"
  >
    <div id="kt_app_content_container" class="w-500px">
      <!--begin::About card-->
      <div class="card">
        <!--begin::Body-->
        <div class="card-body p-lg-17">
          <!--begin::About-->
          <div>
            <!--begin::Wrapper-->

              <!--begin::Top-->
              <div class="text-center">

                <!--begin::Title-->
            <div>
              <h2 class="mb-2 text-left mb-8">
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/general/gen043.svg-->
<span class="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
                Payment confirmed !</h2>
                <!--end::Title-->

           
                <div class="fs-5">
                    Thank you very much for joining Kiwano!
                </div>

                <div class="mt-10">
                    <button @click="goToApp()" class="btn btn-primary btn-lg">
                        Activate subscription
                    </button>
                </div>

                <div>
                  <div>

                  </div>

      


                </div>
              </div>
              <!--end::Top-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::About-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::About card-->
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { activateSubscription } from '../js/user';

export default {
    methods: {
        goToApp() {

            //this updates 2 things on the user doc: sets firsttimeloggedin back to true, so that the user sees the welcome message again
            //then sets subscriptiontype to 'paid' so that the Upgrade banner dissapears.
            //finally, it closes the current tab so that the user can go back to the previous Kiwano with their 
            //subscription activated
            activateSubscription() 

            router.push('/profiles')

        }
    }

}
</script>

<style>

</style>