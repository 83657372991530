import store from "../store/index";
import { getSingleProfileExternal, getSingleProjectExternal, getSingleClientExternal } from "./getdata"

import { onSnapshot, query, collection, collectionGroup, getDocs, where, doc, updateDoc, getDoc, orderBy, addDoc, limit, getCountFromServer, arrayUnion, arrayRemove } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut, setPersistence } from "firebase/auth";

import { db } from "../firebase/index";
import router from '@/router';


const goToDetailedProfileExternal = (profileId, origin, notificationtype) => {


    //specific flow when user searches from mobile UI
    if (router.currentRoute.value.path == '/mobile') {
        store.state.showMain = false;
        store.state.animationname = "slideleft";
        store.state.searchState = "off";
        store.state.searchInputText = "";

    }

    //get the profile
    getSingleProfileExternal(profileId)


    //go to profiles if on desktop only
    if(router.currentRoute.value.path != '/mobile') {
        router.push('/profiles')
    }

    if(notificationtype == 'task') {

      document.getElementById("openNotifications2").click()
        //document.getElementById("openNotifications2").hide()
        setTimeout(() => {
            document.getElementById("profiletasksdropdownbutton").click()            
        }, 500);
    } else if(notificationtype == 'clientcomment') {

        document.getElementById("openNotifications2").click()

    }
    


    if (router.currentRoute.value.path != '/mobile' && origin != 'notifications') {


        //add currently viewed profile in desktop UI to this array so that the moibile UI always shows the latest 3 profiles viewed
        //WARNING: currently the latest profile viewed workflow works only when the user opens a profile from the desktop version. 
        //Would be great to have that working also when opening a profile from the mobile UI. For some reason this creates a duplicate of
        //every profile in store.state.latestprofilesvieweddocs. Couldn't find out why so I deactivated it from mobile version in 
        //this if/else statement for now.

        //if navigation comes from notifications, then don't update the array as it will result in many firestore reads

        if (!store.state.userData.threelatestprofiles) {
            store.state.userData.threelatestprofiles = []
        }

        const threelatestprofiles = store.state.userData.threelatestprofiles;

        function addProfile(newProfileId) {

            // Check if the newProfileId is already in the array
            const index = threelatestprofiles.indexOf(newProfileId);

            if (index > -1) {
                // If the newProfileId is already in the array, remove it
                threelatestprofiles.splice(index, 1);
            } else if (threelatestprofiles.length === 3) {
                // If the array is full, remove the first element to make room for the new one
                threelatestprofiles.shift();
            }

            // Add the newProfileId to the end of the array
            threelatestprofiles.push(newProfileId);
        }


        addProfile(profileId);


        const userRef = doc(db, "users", store.state.userData.id);

        updateDoc(userRef, {
            threelatestprofiles: threelatestprofiles
        })
            .then(() => {
                for (let i = 0; i < store.state.userData.threelatestprofiles.length; i++) {
                    //Check if the profile is a deleted profile. When the user fetches recommended profiles from the database
                    //in the AI Search component, and if one of the recommended profiles was deleted in the meantime, 
                    //it will create a temporary profile item in the Store only. This document will be assigned an ID
                    //which includes "deletedprofile". In this way the user will see "Deleted profile" in the profilelist when launching the fetch function.
                    //But here we need to filter it out because no need to include such a deleted profile in the last 3 viewed and
         
                    if (store.state.userData.threelatestprofiles[i].includes("deletedprofile")) {
                        const profileRef = doc(db, "profiles", store.state.userData.threelatestprofiles[i]);
                        getDoc(profileRef)
                            .then((profileDoc) => {
                                if (profileDoc.exists()) {
                                    let tempProfile = profileDoc.data()
                                    tempProfile.id = profileDoc.id
                                    store.state.latestprofilesvieweddocs.push(tempProfile)
                                }
    
                            })
                    } else {
                        //Do nothing in this case and go to next array item
                    }
   
                }
            })

    }



}

const goToDetailedProjectExternal = (projectid) => {



    getSingleProjectExternal(projectid)
    store.state.showProjectList = false
    store.state.showProjectDetails = true
}


const goToDetailedClientExternal = (clientid) => {

    //store.state.currentProfileDetails = false
    getSingleClientExternal(clientid)


    //Switch from ClientList.vue to ClientDetails.vue
    store.state.showClientList = false
    store.state.showClientDetails = true


}

export { goToDetailedProfileExternal, goToDetailedProjectExternal, goToDetailedClientExternal }