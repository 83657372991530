<template>
  <div
    style="
      width: 160px;
      height: 60px;
      border: none;
      border-radius: 15px;
      padding-left: 52px;
      padding-top: 15px;
      font-family: sans-serif;
      font-weight: 600;
      font-size: 16px;
      background-color: #227DB4;
      color: white;
    "
  >
    Signup
  </div>
  <div>
    
  </div>

</template>

<script>

export default {
      components: {
  },
};
</script>

<style>
</style>