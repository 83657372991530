import { collection, addDoc, getDocs, query, where, connectFirestoreEmulator, collectionGroup, getCountFromServer, arrayUnion, arrayRemove } from 'firebase/firestore';
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fetchCalendarEventsExternal } from "./getdata";
import { db } from "../firebase/index";
import store from "../store/index";

import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";


const savePROptionsInUserDocExternal = () => {
    let userRef = doc(db, "users", store.state.userData.id)
    updateDoc(userRef, {
      proptions: store.state.userData.proptions
    })
}

const alertMessageIsReadExternal = () => {
  let userRef = doc(db, "users", store.state.userData.id)
  updateDoc(userRef, {
    generalalertstatus: "read"
  })
}

const activateSubscription = () => {
  let userRef = doc(db, "users", store.state.userData.id)
  updateDoc(userRef, {
    firsttimeloggedin: true,
    subscriptiontype: 'paid'
  })
  .then(() => {
    wondow.close
  })
}


function acceptTermsAndDpaExternal(userId) {

  const functions = getFunctions();
  const callAcceptTermsCloudFunction = httpsCallable(functions, "logUserTermsAcceptance");


  callAcceptTermsCloudFunction({ userId: userId })
  .then(result => {
      console.log(result.data.message);
      store.state.termsacceptancestored = true;
  })
  .catch(error => {
      console.error('Error logging user activity:', error);
  });
}

//this either
// 1. (if template with this name found): adds a new email template to the list in the User doc
// or 2. (if no template with same name found): deletes the template with same name and adds the new template
const saveNewTemplateExternal = () => {
  const nameininput = store.state.currentEmailTemplate.name;
  const bodyinput = store.state.currentEmailTemplate.body;

  const alltemplates = store.state.emailtemplates;
  let index = -1;
  if (alltemplates) {
    index = alltemplates.findIndex((object) => {
      return object.name === nameininput;
    });
  }

  if (index > -1) {
    // alert("found a match");

    deleteDoc(
      doc(
        db,
        "users/" + store.state.userData.id + "/emailtemplates/",
        alltemplates[index].id
      )
    ).then(() => {
      //confirmation goes here
      // alert("Email template updated!");
    });
  }

  //Add the template to Firestore
  let templateRef = collection(
    db,
    "users/" + store.state.userData.id + "/emailtemplates/"
  );
  addDoc(templateRef, {
    name: nameininput,
    body: bodyinput,
    userid: store.state.userData.userid,
  }).then(() => {
    store.state.currentEmailTemplate.body = "";
    store.state.currentEmailTemplate.name = "";

    //Confirmation toast
    iziToast.show({
      title: "Email template added !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

const loadTemplateExternal = (templateid) => {
  const alltemplates = store.state.emailtemplates;

  //look for corresponding template in Vuex
  const index = alltemplates.findIndex((object) => {
    return object.id === templateid;
  });

  //COPY (not assign) the value into currentEmailTemplate to render data in the text editor
  store.state.currentEmailTemplate = Object.assign({}, alltemplates[index]);
};

const deleteTemplateExternal = (templateid) => {
  deleteDoc(
    doc(db, "users/" + store.state.userData.id + "/emailtemplates/", templateid)
  ).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Email template deleted !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });
};

const addEventToCalendarExternal = async (eventObject) => {


//show spinner in add button
store.state.addingEventState = "on";

  const functions = getFunctions();
  const addEventToCalendar = httpsCallable(functions, "addEventToCalendar");

  addEventToCalendar({
    nylasToken: store.state.userData.nylasemailtoken, // This should be securely stored and retrieved
    eventDetails: eventObject
  })
    .then((result) => {
      store.state.addingEventState = "done";

      const newEventToAddToVuex = {
  title: eventObject.title,
  when: {
    start_time: eventObject.start,
    end_time: eventObject.end,
    object: 'event',
    id: Math.floor(Math.random() * 1000001).toString()
  }
      }

      store.state.userCalendarEvents.push(newEventToAddToVuex);
      console.log("Here are the updated ones: " + store.state.userCalendarEvents)


    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
 
}


/*
const integrateTeamsExternal = () => {
        // Reference the callable function

        const functions = getFunctions();
        const createTeamsIntegration = httpsCallable(functions, "createTeamsIntegration");


        // Now call the function and handle the promise
        createTeamsIntegration().then((result) => {
          // Read result of the Cloud Function.
          console.log(result.data);
        }).catch((error) => {
          // Getting the Error details.
          console.log('Error:', error);
        });
}
*/


export {
    savePROptionsInUserDocExternal,
    alertMessageIsReadExternal,
    activateSubscription,
    acceptTermsAndDpaExternal,
    saveNewTemplateExternal,
    loadTemplateExternal,
    deleteTemplateExternal,
    addEventToCalendarExternal,
    //integrateTeamsExternal
  }


