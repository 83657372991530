<template>
  <h5 class="mb-5 mt-15">BETWEEN: </h5>

  
  <span class="fw-boldest">LAKESIDE TALENT SOURCING,</span> a company incorporated and existing under the laws of BELGIUM, registered under the company number 0780.639.271 with its registered office at 1000 Brussel, Square de Meeûs, 35. 
Hereinafter referred to as <span class="fw-boldest"> “Data Processor”.</span>

  <h5 class="mb-5 mt-15">AND: </h5>
<span class="fw-boldest">The entity or individual</span> who accepts this Agreement by ticking the checkbox or by using the services provided by LAKESIDE TALENT SOURCING.
Hereinafter referred to as the <span class="fw-boldest"> “Data Controller”</span>
<br><br>
<p>
  The Provider and the User be individually referred to as a “Party” and collectively to as the “Parties”, as the context may require. 
</p>

<br><br>
<h5>BEFOREHAND</h5>

<ul>
  <li>WHEREAS, Parties have concluded a separate agreement known as the Terms and Conditions of Use (“The Main Agreement”),</li>
  <li>WHEREAS, in the context of the Main Agreement, the Data Controller will make personal data available to the Data Processor (“the Personal Data”), which will process the later (“The Processing” or “Data Processing”),</li>
  <li>WHEREAS, in light of the respective roles and responsibilities, the Parties make agreement about the Processing, taking into account the applicable legislation, in particular the General Data Protection Regulation (EU) 2016/679 (“GDPR”) and Data Protection Framework Act of 30 July 2018 (“The Privacy Legislation”).</li>
</ul>

<br><br>
<h5>NOW, THEREFORE, the Parties have agreed as follows: </h5>

<h5 class="mb-5 mt-15">ARTICLE 1 – RESPONSIBILITY OF THE DATA CONTROLLER</h5>
<p><span class="fw-boldest">1.1.</span> The Data Controller shall ensure that it has all necessary rights, consents, and permissions to lawfully process the Personal Data as provided in the GDPR and the Privacy Legislation. He only makes those Personal Data available to the Processor, which the latter needs for the execution of the Main Agreement, and to the extent permitted by the GDPR and the Privacy Legislation.</p>
<p><span class="fw-boldest">1.2.</span> The Data Controller shall be responsible for the accuracy, quality, legality, and appropriateness of the Personal Data it provides to the Data Processor.</p>

<h5 class="mb-5 mt-15">Article 2 – Processing</h5>
<p><span class="fw-boldest">2.1.</span> The Processor will only process the Personal Data to the extent necessary for the execution of the Main Agreement, within the purposes determined by the Data Controller, and on the documenting instructions of the Data Controller. If, on the basis of a legal obligation, the Processor is required to carry out a data processing operation from which it cannot withdraw under Belgian or European Union law, it shall inform the Data Controller of this legal obligation immediately prior to processing, unless the relevant law prohibits such communication.</p>
<p><span class="fw-boldest">2.2.</span> The Personal Data and the instructions and modalities for the processing are listed in Annex 1, which will be updated whenever necessary.</p>
<p><span class="fw-boldest">2.3.</span> The Processor will treat all data strictly confidentially and will respect the GDPR and the Privacy Legislation. The confidentiality obligations entered into under this agreement will continue throughout the term of the agreement, as well as for two years from the date on which the agreement is terminated.</p>
<p><span class="fw-boldest">2.4.</span> If the Processor suspects that certain instructions from the Data Controller are contrary to the GDPR and the Privacy Legislation, it will immediately report this to the Data Controller. Insofar as this presumption is justified and well-founded, the Processor may then refuse to follow the unlawful instructions.</p>

<h5 class="mb-5 mt-15">Article 3 – Staff</h5>
<p><span class="fw-boldest">3.1.</span> The Processor only grants access to the Personal Data to staff members to the extent necessary for the execution of the Main Agreement.</p>
<p><span class="fw-boldest">3.2.</span> The Processor guarantees that the persons mentioned in point 3.1 are subject to confidentiality and will comply with the current agreement.</p>

<h5 class="mb-5 mt-15">Article 4 – Protection</h5>
<p><span class="fw-boldest">4.1.</span> Taking into account the state of the art, the costs, and the nature, scope, context and purposes of the processing, as well as the risks of breaches, the Processor takes the necessary technical and organizational measures to protect the Personal Data, including, where appropriate, the measures listed in Article 32.1 GDPR, such as pseudonymization and encryption.</p>
<p><span class="fw-boldest">4.2.</span> The Parties recognize that security requirements are constantly evolving and that effective protection requires frequent evaluation and regular improvement of outdated security measures. The Parties shall therefore adapt the measures to the state of the art.</p>
<p><span class="fw-boldest">4.3.</span> The Processor is also obliged to assist the Data Controller so that it can fulfil its obligations with regard to the security of the Processing, and, where necessary, to carry out a data protection impact assessment.</p>

<h5 class="mb-5 mt-15">Article 5 – Third parties</h5>
<p>The Processor may not communicate Personal Data and may not outsource all or part of the Processing to third parties without the prior written consent of the Data Controller. The Processor confirms the existence of procedures so that any third party who gains access to the Personal Data will observe its confidentiality and security. The Processor ensures that the sub-processors are bound by obligations that are not less or weaker than those stated in the current Processor Agreement. This provision does not apply to persons who are authorised by law to have access to the Personal Data.</p>

<h5 class="mb-5 mt-15">Article 6 – Transfer to other countries</h5>
<p>The Processor may only transfer the Personal Data to countries outside the EEA and the countries recognized by the European Commission with a similar level of protection, after the prior written consent of the Controller.</p>

<h5 class="mb-5 mt-15">Article 7 – Infringements</h5>
<p><span class="fw-boldest">7.1.</span> The Processor will inform the Data Controller as soon as possible, and at the latest within 24 hours, of breaches related to the Personal Data (loss of data, unauthorized access, etc.), and provide the Data Controller with all the necessary information and documents to assess the situation.</p>
<p><span class="fw-boldest">7.2.</span> The necessary information within the meaning of Article 7.1 shall include: i. the nature of the Personal Data breach; ii. if possible, the categories of Data Subjects; iii. the estimated number of Data Subjects Involved; iv. the categories of Personal Data; v. the estimated number of Personal Data breached; vi. where applicable, the name and contact details of the person designated as data protection officer, or any other contact person who can provide information about the breach; vii. the possible consequences and risks, including for the Data Subjects; viii. the measures taken to mitigate or remedy the consequences and risks of the data breach.</p>
<p><span class="fw-boldest">7.3.</span> The Processor has a plan for the handling of infringements, and provides the Data Controller, at its request, with a copy of (the latest version of) the plan.</p>
<p><span class="fw-boldest">7.4.</span> The Processor shall keep a detailed log of all infringements, and of the measures taken, and shall provide the Data Controller, at its request, with a copy thereof.</p>

<h5 class="mb-5 mt-15">Article 8 – Rights of data subjects</h5>
<p><span class="fw-boldest">8.1.</span> If the Processor receives a request from a data subject in execution of one of his rights, the Processor will forward the request to the Data Controller as soon as possible.</p>
<p><span class="fw-boldest">8.2.</span> The Processor will provide all necessary assistance to the Data Controller in responding to requests from data subjects.</p>
<p><span class="fw-boldest">8.3.</span> The Processor stores the Personal Data in a structured, commonly used and machine-readable format, in order to guarantee the right to portability of the Personal Data.</p>

<h5 class="mb-5 mt-15">Article 9 – Register of processing activities</h5>
<p>Parties are obliged to keep a register of all categories of Processing activities. This register shall contain at least the following information: i. The name and contact details of the Processor and of the Data Controller on whose behalf the Processor is acting and, where applicable, of the representative of the Data Controller or the Processor and of the data protection officer; ii. The categories of processing operations carried out on behalf of the Data Controller; iii. Where applicable, the transfers of Personal Data to a third country or organisation, indicating that third country or international organisation and, in the case of transfers as referred to in the second subparagraph of Article 49(1) of the GDPR, the documents proving the existence of appropriate safeguards; iv. If possible, a general description of the organisational and technical security measures referred to in Article 32(1) of the GDPR.</p>

<h5 class="mb-5 mt-15">Article 10 – Assistance</h5>
<p>The parties shall provide each other with the necessary assistance to comply with their obligations in application of the Privacy Legislation and the GDPR, in particular for the performance of any data protection impact assessment.</p>

<h5 class="mb-5 mt-15">Article 11 – Audits</h5>
<p><span class="fw-boldest">11.1.</span> The Controller may verify compliance with this agreement. The Processor shall cooperate fully with the Controller or its appointed auditor and provide all useful information and documents. The Data Controller will announce the check at least seven working days in advance.</p>
<p><span class="fw-boldest">11.2.</span> If the audit reveals that the Processor does not comply with its obligations, the Processor shall bear all the costs of the check and of the corrective measures.</p>

<h5 class="mb-5 mt-15">Article 12 – Liability</h5>
<p><span class="fw-boldest">12.1.</span> The Processor is liable – and indemnifies the Data Controller responsible for the processing – for all damage and any claim from third parties, including the person concerned, that arise directly from an attributable shortcoming by the Processor and/or sub-processors engaged by the Processor in the fulfilment of its obligations under this Data Processing Agreement. The indemnification only applies
</p>

<p><span class="fw-boldest">12.1.</span> The Processor is liable – and indemnifies the Data Controller responsible for the processing – for all damage and any claim from third parties, including the person concerned, that arise directly from an attributable shortcoming by the Processor and/or sub-processors engaged by the Processor in the fulfilment of its obligations under this Data Processing Agreement. The indemnification only applies if and insofar as the Processor would be liable under the applicable laws and regulations.</p>
<p><span class="fw-boldest">12.2.</span> The Data Controller is liable for any misuse by one of its employees, as well as for the quality and correctness of the personal data entered by its employees. The Controller indemnifies the Processor against all damages and any claim from third parties, including the Data Subject, as a result of non-compliance with the specific obligations of the Privacy Legislation or GDPR that fall to the Data Controller.</p>

<h5 class="mb-5 mt-15">Article 13 – Duration and termination</h5>
<p>This Agreement commences on the date of signature by both parties of the Main Agreement and shall remain in force until the termination of the Main Agreement. Both parties may terminate this agreement immediately by registered letter if the other party fails to fulfil its obligations and has not remedied this within 30 days of notice of default. Obligations that by their nature are intended to remain in force after the end of the Processor Agreement will remain in force after the end of the Processor Agreement. This includes, inter alia, the obligations arising from the provisions on confidentiality, liability and applicable law and competent courts. Upon termination of the Processor Agreement, the Processor will inform all sub-processors involved in the Processing of Personal Data of the termination of the Processor Agreement. The obligations set out in Article 13 shall apply to these sub-processors and the Processor shall ensure that all sub-processors comply with these obligations.</p>

<h5 class="mb-5 mt-15">Article 14 – Return of Personal Data</h5>
<p>Within ten days of the end of the present agreement, the Processor will hand over all Personal Data and all copies thereof to the Data Controller, or, at the discretion of the Data Controller, destroy and confirm the destruction in writing. This does not apply to data that must be kept in application of the Privacy Legislation, or pursuant to regulations relating to ensuring scientific integrity.</p>

<h5 class="mb-5 mt-15">Article 15 – Contacts</h5>
<p>The contact persons for the implementation of this agreement are: - for the Processor: Thomas Toussaint / Lakeside Talent Sourcing / thomas.toussaint@lakeside-talent.com - for the Data Controller: the details have been provided to Lakeside Talent Sourcing.</p>

<h5 class="mb-5 mt-15">Article 16 – Disputes and applicable law</h5>
<p>All disputes in connection with this agreement will be submitted to the competent French-speaking court of Brussels. Belgian law applies to this Processor Agreement.</p>

<h5 class="mb-5 mt-15">Article 17 – Entire Agreement</h5>
<p><span class="fw-boldest">17.1.</span> This Processor Agreement is deemed to be divisible, whereby the total or partial invalidity, nullity or unenforceability of provisions, which do not affect the essence of the Processor Agreement, do not affect the validity and enforceability of the other provisions of the Processor Agreement. In that case, the Data Processing Agreement between the parties will continue to exist as if the invalid, void or unenforceable provisions had never existed.</p>
<p><span class="fw-boldest">17.2.</span> The parties undertake to renegotiate in good faith the replacement or amendment of one or more provisions of the Processor Agreement that have been declared invalid, void or unenforceable in whole or in part.</p>
<p><span class="fw-boldest">17.3.</span> Any amendment, replacement or addition to the Processor Agreement must be expressly authorised in writing by the Parties.</p>
<p><span class="fw-boldest">17.4.</span> When the legal relationship of the Parties is subject to new legislation and/or jurisprudence at both Belgian and European level, the Parties agree to renegotiate the Processor Agreement in good faith in order to bring it into line with the new legislation and/or jurisprudence at Belgian and European level.</p>
<p><span class="fw-boldest">17.5.</span> Nothing in the Data Processing Agreement releases the Parties from their own obligations and responsibilities as set out in the Data Processing Agreement and the GDPR.</p>



</template>

<script>
export default {

}
</script>

<style>

</style>