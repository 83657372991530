<template>
  <div class="row" v-if="$store.state.userData.proptions != undefined">
    <h3 class="mt-3 mb-5">Sort & Filter profiles</h3>
    <div class="col-10">
      <span
        >By default, all profiles in the list behind this pop-up will be
        included in the progress report. If you want to, you can sort and filter
        the profiles here.</span
      >
    </div>
    <div class="d-flex">
      <!--begin: sort profiles-->
      <div class="mt-4 mb-7">
        <SortProfileList></SortProfileList>
      </div>
      <!--end: sort profiles-->
      <!--begin::filter Profiles-->
      <div
        v-if="
          ($store.state.currentRoute === '/profiles' || $store.state.currentRoute === '/') &&
          ($store.state.showingProfilesAs == 'recentProfiles' ||
            $store.state.showingProfilesAs == 'projectProfiles' ||
            $store.state.showingProfilesAs == 'searchResults')
        "
        class="dropdown me-2 mt-4 mb-7"
      >
        <button
          class="btn btn-sm btn-flex btn-light"
          type="button"
          id="filterProfilesButton"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-11-24-050857/core/html/src/media/icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-5"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          <div
            v-if="$store.state.filterAppliedToProfiles"
            style="margin-top: -24px; margin-left: -5px"
          >
            <span
              class="bullet bullet-dot h-6px w-6px bg-primary me-2 mt-5"
            ></span>
          </div>
          Filter
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
          <span class="ms-2 svg-icon svg-icon-4 m-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                fill="black"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <div
          class="dropdown-menu menu-sub menu-sub-dropdown"
          aria-labelledby="filterProfilesButton"
          id="filterProfilesDrodownMenu"
          role="menu"
          :class="{
            'w-600px': $store.state.showingProfilesAs == 'projectProfiles',
            'w-400px':
              $store.state.showingProfilesAs == 'recentProfiles' ||
              $store.state.showingProfilesAs == 'searchResults',
          }"
        >
          <SmallCard
            whichCard="filterprofilelist"
            title="Filter options"
            subTitle="none"
          ></SmallCard>
        </div>
      </div>
      <!--end::filter Profiles-->
    </div>
  </div>
  <div class="row" v-if="$store.state.userData.proptions != undefined">
    <!--Begin: Left col: Choose fields to include-->
    <div class="col-6">
      <h3 class="mt-3 mb-5">Included fields</h3>
      <div class="d-flex mb-5">
        <span class="ms-10">Order</span>
        <span class="ms-10">Field</span>
      </div>
      <!--Begin: Name-->
      <div class="form-check form-check-sm form-check-custom form-check-solid">
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          checked
          disabled
          id="profilename"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectNameOrder"
          v-model="$store.state.userData.proptions.fields.name.order"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <label class="form-check-label" for="profilename">
          Name (required)
        </label>
      </div>
      <!--End: Name-->

      <!--Begin: Company-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profilecompany"
          v-model="$store.state.userData.proptions.fields.company.included"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectCompanyOrder"
          v-model="$store.state.userData.proptions.fields.company.order"
          v-if="$store.state.userData.proptions.fields.company.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-75px h-30px"></div>
        <label class="form-check-label" for="profilecompany"> Company </label>
      </div>
      <!--End: Company-->

      <!--Begin: Title-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profiletitle"
          v-model="$store.state.userData.proptions.fields.title.included"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectTitleOrder"
          v-model="$store.state.userData.proptions.fields.title.order"
          v-if="$store.state.userData.proptions.fields.title.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-75px h-30px"></div>

        <label class="form-check-label" for="profiletitle"> Job title </label>
      </div>
      <!--End: Title-->

      <!--Begin: Experience-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profileExperience"
          v-model="$store.state.userData.proptions.fields.experience.included"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectExpOrder"
          v-model="$store.state.userData.proptions.fields.experience.order"
          v-if="$store.state.userData.proptions.fields.experience.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-75px h-30px"></div>
        <label class="form-check-label" for="profileExperience">
          Experience
        </label>
      </div>
      <!--End: Experience-->

      <!--Begin: Notes-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profileNotes"
          v-model="$store.state.userData.proptions.fields.notes.included"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectNotesOrder"
          v-model="$store.state.userData.proptions.fields.notes.order"
          v-if="$store.state.userData.proptions.fields.notes.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-85px h-30px"></div>
        <label class="form-check-label" for="profileNotes">
          Notes (in this project)
        </label>
      </div>
      <!--End: Notes-->

      <!--Begin: Status-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profilestatus"
          v-model="$store.state.userData.proptions.fields.status.included"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectStatusOrder"
          v-model="$store.state.userData.proptions.fields.status.order"
          v-if="$store.state.userData.proptions.fields.status.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-75px h-30px"></div>
        <label class="form-check-label" for="profilestatus">
          Status (in this project)
        </label>
      </div>
      <!--End: Status-->

      <!--Begin: Location-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profileLocation"
          v-model="$store.state.userData.proptions.fields.location.included"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectLocationOrder"
          v-model="$store.state.userData.proptions.fields.location.order"
          v-if="$store.state.userData.proptions.fields.location.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-75px h-30px"></div>
        <label class="form-check-label" for="profileLocation"> Location </label>
      </div>
      <!--End: Location-->

      <!--Begin: Contact details-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profileContactDetails"
          v-model="
            $store.state.userData.proptions.fields.contactdetails.included
          "
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectContactDetailsOrder"
          v-model="$store.state.userData.proptions.fields.contactdetails.order"
          v-if="$store.state.userData.proptions.fields.contactdetails.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-75px h-30px"></div>
        <label class="form-check-label" for="profileContactDetails">
          Contact details
        </label>
      </div>
      <!--End: Contact details-->

      <!--Begin: LinkedIn profile link-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="1"
          id="profileContactDetails"
          v-model="$store.state.userData.proptions.fields.linkedinurl.included"
        />
        <select
          class="ms-3 form-select w-65px h-30px py-0"
          aria-label="selectLIOrder"
          v-model="$store.state.userData.proptions.fields.linkedinurl.order"
          v-if="$store.state.userData.proptions.fields.linkedinurl.included"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <div v-else class="w-75px h-30px"></div>
        <label class="form-check-label" for="profileContactDetails">
          LinkedIn profile link
        </label>
      </div>
      <!--End: LinkedIn profile link-->
    </div>
    <!--End: Left col: Choose fields to include-->
    <!--Begin: Right col-->
    <div class="col-5 ms-6">
      <!--Begin: Select name display-->

      <h3 class="mt-3 mb-5">Name display</h3>
      <!--begin: Full name option-->
      <div class="form-check form-check-sm form-check-custom form-check-solid">
        <input
          class="form-check-input"
          type="radio"
          value="fullname"
          id="flexRadioDefault"
          v-model="$store.state.userData.proptions.namedisplay"
        />

        <label class="form-check-label" for="flexRadioDefault">
          Full name (ex. John DOE)
        </label>
      </div>
      <!--end: Full name option-->
      <!--begin: Last name initial-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="radio"
          value="lastnameinitial"
          id="flexRadioDefault"
          v-model="$store.state.userData.proptions.namedisplay"
        />
        <label class="form-check-label" for="flexRadioDefault">
          Last name initial (ex. John D.)
        </label>
      </div>
      <!--end: Last name initial-->
      <!--begin: All initials-->
      <div
        class="form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="radio"
          value="initials"
          id="flexRadioDefault"
          v-model="$store.state.userData.proptions.namedisplay"
        />
        <label class="form-check-label" for="flexRadioDefault">
          Initials (ex. J.D.)
        </label>
      </div>
      <!--end: All initials-->
      <!--end: Select name display-->

      <!--Begin: Select theme color-->

      <h3 class="mt-10 mb-5">Theme color</h3>
      <div class="d-flex">
        <div
          class="
            me-6
            mb-4
            form-check form-check-sm form-check-custom form-check-solid
          "
        >
          <input
            class="form-check-input"
            type="radio"
            value="gray"
            id="flexRadioDefault"
            v-model="$store.state.userData.proptions.themecolor"
          />
          <label class="form-check-label" for="flexRadioDefault"></label>
          <div
            class="ms-3 w-20px h-20px"
            style="background-color: #535353"
          ></div>
          <div class="w-20px h-20px" style="background-color: #e5e5e4"></div>
        </div>
        <div
          class="
            mb-4
            form-check form-check-sm form-check-custom form-check-solid
          "
        >
          <input
            class="form-check-input"
            type="radio"
            value="blue"
            id="flexRadioDefault"
            v-model="$store.state.userData.proptions.themecolor"
          />
          <label class="form-check-label" for="flexRadioDefault"></label>
          <div
            class="ms-3 w-20px h-20px"
            style="background-color: #4b7190"
          ></div>
          <div class="w-20px h-20px" style="background-color: #bdcedc"></div>
        </div>
      </div>

      <div class="d-flex">
        <div
          class="
            me-6
            mb-4
            form-check form-check-sm form-check-custom form-check-solid
          "
        >
          <input
            class="form-check-input"
            type="radio"
            value="red"
            id="flexRadioDefault"
            v-model="$store.state.userData.proptions.themecolor"
          />
          <label class="form-check-label" for="flexRadioDefault"></label>
          <div
            class="ms-3 w-20px h-20px"
            style="background-color: #a43b39"
          ></div>
          <div class="w-20px h-20px" style="background-color: #dc9d9b"></div>
        </div>
        <div
          class="
            mb-4
            form-check form-check-sm form-check-custom form-check-solid
          "
        >
          <input
            class="form-check-input"
            type="radio"
            value="green"
            id="flexRadioDefault"
            v-model="$store.state.userData.proptions.themecolor"
          />
          <label class="form-check-label" for="flexRadioDefault"></label>
          <div
            class="ms-3 w-20px h-20px"
            style="background-color: #3e5b1f"
          ></div>
          <div class="w-20px h-20px" style="background-color: #dfefcf"></div>
        </div>
      </div>

      <!--End: Select theme color-->

      <!--Begin (deactivated, code below can be used if we decide to implement the feature): Select file format-->

      <h3 class="d-none mt-10 mb-5">File format</h3>
      <!--begin: Excel option-->
      <div class="d-none form-check form-check-sm form-check-custom form-check-solid">
        <input
          class="form-check-input"
          type="radio"
          value="excel"
          id="flexRadioDefault"
          v-model="$store.state.userData.proptions.fileformat"
        />
        <label class="form-check-label" for="flexRadioDefault"> Excel </label>
      </div>
      <!--end: Excel option-->
      <!--begin: PDF option-->
      <div
        class="d-none form-check form-check-sm form-check-custom form-check-solid mt-3"
      >
        <input
          class="form-check-input"
          type="radio"
          value="pdf"
          id="flexRadioDefault"
          v-model="$store.state.userData.proptions.fileformat"
        />
        <label class="form-check-label" for="flexRadioDefault"> PDF </label>
      </div>
      <!--end: PDF option-->

      <!--end: Select file format-->

      <!--begin: Include logo (deactivated as the logo doesn't render well in the excel file-->
      <div class="d-none d-flex flex-column">
        <h3 class="mt-10 mb-2">Logo</h3>
        <div class="mt-6 mb-5 form-check form-switch mb-10">

          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            v-model="$store.state.userData.proptions.logo"
            id="includeLogoSwitch"
            @click="checkIfLogoExists()"
          />
                        <label class="form-check-label" for="includeLogoSwitch">
        Include my firm's logo
    </label>
        </div>
      </div>

      <!--end: Include logo-->

      <!--begin: Download button-->
      <div class="d-flex mt-17 me-5">
        <button
          class="btn btn-sm btn-primary ms-auto"
          @click="downloadPRFromModal()"
        >
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-01-26-051612/core/html/src/media/icons/duotune/arrows/arr091.svg-->
          <span class="svg-icon svg-icon-muted svg-icon-2"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                width="12"
                height="2"
                rx="1"
                transform="matrix(0 -1 -1 0 12.75 19.75)"
                fill="currentColor"
              />
              <path
                d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z"
                fill="currentColor"
              />
              <path
                opacity="0.3"
                d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          Download
        </button>
      </div>
      <!--end: Download button-->
    </div>
    <!--End: Right col-->
  </div>
</template>

<script>
import { savePROptionsInUserDocExternal } from "../../../js/user";
import { exportToExcelExternal } from "../../../js/profilelist";

import SortProfileList from "./../../Cards/ProfileCardContents/SortProfileList.vue";
import SmallCard from "../../Cards/SmallCard.vue";

export default {
  components: {
    SortProfileList,
    SmallCard,
  },
  methods: {
    downloadPRFromModal() {
      if(this.$store.state.userData.userrole != 'Administrator' && this.$store.state.userData.userrole != 'Operator') {
        alert("Access Denied: Your account does not have the required permissions to export data.")
        return
      }

      savePROptionsInUserDocExternal();

      exportToExcelExternal();
    },
    checkIfLogoExists() {
      if(!this.$store.state.teamData.teamlogourl) {
        alert("You need to add a logo for your team first.")
        setTimeout(() => {
        this.$store.state.userData.proptions.logo = false
          
        }, 300);
        return
      }
    }
  },
};
</script>

<style>
</style>