<template>
</template>

<script>


import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";


export default {

mounted() {
  const params = new URLSearchParams(window.location.search);
  const page = params.get('page');

  // Use the 'page' parameter as needed
  if (page === 'emailconfirmation') {
    const accessToken = params.get('access_token');
    const userId = this.$store.state.userData.id; // Assuming this is where you have the user ID
    const nylasUserId = params.get('namespace_id'); // Assuming the Nylas user ID is in the 'namespace_id' parameter

    // Send an HTTP request using Axios to trigger the Cloud Function
    axios.post('https://us-central1-kiwano-3f6de.cloudfunctions.net/updateNylasEmailToken', { data: { token: accessToken, userId, nylasUserId } })
      .then(response => {
        // Clean up the URL
        const cleanedURL = window.location.origin;
        window.history.replaceState(null, null, cleanedURL);

        // Show the success message
       Swal.fire({
          html: "<span class='text-muted'>Your email account is now connected! You can start emailing candidates and receive their replies directly in Kiwano. <br><br> <a href='https://kiwanotalent.com/userguide?emails=true' target='_blank'>Read our userguide</a> on how to use emails in Kiwano.</span>",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-success"
          }
        }).then(() => {
          // Reload the page
          window.location.reload();
        });
      })
      .catch(error => {
        alert('An error occurred while connecting account');
        console.error('Error:', error);
      });
  }
}







}
</script>

<style>

</style>