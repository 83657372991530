<template>

  <div
    class="timeline scroll-y me-n5 pe-5"
    v-if="$store.state.currentProfileActivityLogs.length > 0"
    data-kt-element="messages"
    data-kt-scroll="true"
    data-kt-scroll-activate="true"
    data-kt-scroll-height="auto"
    data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
    data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
    data-kt-scroll-offset="0px"
    style="height: 400px;"
  >
    <!--begin::Comment item-->
    <TransitionGroup
      name="list"
      tag="ul"
    >
      <div
        class="timeline-item"
        v-for="log in sortedActivityLogs"
        :key="log.id"
      >
        <!--begin::Timeline line-->
        <div
          v-if="!$store.state.showonlyprofilecomments || ($store.state.showonlyprofilecomments && log.typeofupdate == 'New comment')"
          class="timeline-line w-40px"
        ></div>
        <!--end::Timeline line-->
        <!--begin::Timeline icon-->
        <div
          v-if="!$store.state.showonlyprofilecomments || ($store.state.showonlyprofilecomments && log.typeofupdate == 'New comment')"
          class="timeline-icon symbol symbol-circle symbol-40px"
        >

          <img
            v-if="getUserAvatar(log.byuser)"
            :src="getUserAvatar(log.byuser)"
            alt="img"
          >
         <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/communication/com006.svg-->
<span v-else class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"/>
<path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"/>
<rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
          <div class="d-flex flex-column flex-center">
            <div class="fs-7 text-muted mt-1">{{ timelineItemDate(log.timestamp) }}</div>
            <div
              v-if="timelineItemDate(log.timestamp) == '2 days' || timelineItemDate(log.timestamp) == '3 days'"
              class="fs-7 text-muted mt-1 mb-5"
            >ago</div>
            <div class="fs-7 text-muted mt-1">{{ timelineItemYear(log.timestamp) }}</div>

          </div>

        </div>
        <!--end::Timeline icon-->
        <!--begin::Timeline content-->
        <div
          v-if="!$store.state.showonlyprofilecomments || ($store.state.showonlyprofilecomments && log.typeofupdate == 'New comment')"
          class="timeline-content mb-10"
          :class="{
                                                         'bg-light p-3 rounded': log.typeofupdate == 'New comment',
                                                         'p-3': log.typeofupdate == 'New comment',
                                                         'rounded': log.typeofupdate == 'New comment',
                                                        }"
        >
          <!--begin::Timeline heading-->
          <div class="pe-3 mb-5">
            <!--begin::Type of update-->
            <div class="d-flex flex-row">
              <div class="fs-7 text-muted ps-3">{{ log.typeofupdate }}</div>
              <!--begin::Svg Icon (delete btn) | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr011.svg-->
              <span
                v-if="log.typeofupdate == 'New comment'"
                class="svg-icon svg-icon-muted ms-3 svg-icon-xs custompointer"
                @click="deleteProfileComment(log.commentid)"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>

              </span>
              <!--end::Svg Icon (delete btn)-->
            </div>

            <!--end::Type of update-->

            <!--begin::Description-->
            <div
              v-if="log.commenttext"
              class="d-flex align-items-center mt-1 fs-6"
            >
              <!--begin::Info-->
              <div class="d-flex flex-column">
                <!--commenttext can be either a comment or a task-->
                <div class="d-flex align-items-start">
                <div
                  class="fs-7 text-muted ps-3"  
                  v-if="log.assigneduserobj"
                >Assigned to {{log.assigneduserobj.firstname}}</div>

                </div>
                <div class="fs-7 fw-bold text-gray-800 ps-3">{{log.commenttext}}</div>

              </div>
              <!--end::Info-->

            </div>
            <div v-if="log.typeofupdate == 'New status in a project'">
              <span
                class="ms-3 mt-2 badge"
                :class="{'badge-success': returnStatusCategory(log.statusid) == 'Interested',
                         'badge-danger': returnStatusCategory(log.statusid) == 'Discarded',
                         'badge-primary': returnStatusCategory(log.statusid) == 'In progress',
                         'badge-light': returnStatusCategory(log.statusid) == 'nothing'
                        }"
              >
                {{ returnStatusName(log.statusid) }}

              </span>
            </div>
            <!--end::Description-->
          </div>

          <!--end::Timeline heading-->
          <!--begin::Timeline details-->
          <div class="overflow-auto">
            <!--begin::related project-->
            <div
              class="ms-3"
              v-if="log.attachproject"
            >

              <div class="d-flex flex-row">
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/coding/cod007.svg-->
                <span class="svg-icon svg-icon-muted svg-icon-2 me-1 mt-2"><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z"
                      fill="currentColor"
                    />
                    <path
                      d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
                <div class="symbol symbol-circle symbol-30px me-3">

                  <img
                    :src="log.clientlogo"
                    class="align-self-center"
                    alt=""
                  />
                </div>
                <div>
                  <a
                    href="#"
                    class="text-dark fw-bold text-hover-primary fs-8"
                  >{{ log.clientname }}</a>
                  <div class="">
                    <span class="text-muted fw-semibold d-block fs-9">{{ log.positiontitle }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--end::related project-->
          </div>
          <!--end::Timeline details-->
        </div>
        <!--end::Timeline content-->
      </div>
    </TransitionGroup>
    <!--end::Comment item-->
  </div>
  <div v-else>

    <CardAlert
      v-if="location == 'profiledetailsTimelineCard'"
      alerttype="info"
      title="No comments yet"
      text="Click the button above to add a comment."
    ></CardAlert>

    <CardAlert
      v-if="location == 'editProfileProjectBindingModal'"
      alerttype="info"
      title="No comments yet"
      text="Close this popup and click on 'Add comment' to add a comment."
    ></CardAlert>

  </div>

</template>

<script>
import { deleteProfileCommentExternal } from "../../../js/profiledetails";
import CardAlert from "../../CardAlerts/CardAlert.vue";

export default {
  props: ["location"],
  components: {
    CardAlert,
  },
  methods: {
    deleteProfileComment(commentid) {
      //before deleting the comment from Firestore, we need to remove it from the rendered array, so that the Vue animation can happen
      const commentList = this.$store.state.currentProfileActivityLogs;
      const index = commentList.map((e) => e.commentid).indexOf(commentid);
      commentList.splice(index, 1);

      //call Delete function
      deleteProfileCommentExternal(commentid);
    },
    returnStatusCategory(statusid) {
      const statusList = this.$store.state.profileStatusList;

      const index = statusList.findIndex((object) => {
        return object.id === statusid;
      });
      if(index > 0) {
        return statusList[index].category;
      }
      else {
        return "nothing"
      }
    },
      returnStatusName(statusid) {
      const statusList = this.$store.state.profileStatusList;

      const index = statusList.findIndex((object) => {
        return object.id === statusid;
      });
      if(index > 0) {
        return statusList[index].status;
      }
      else {
        return "Status deleted from list"
      }
    },
    timelineItemDate(timelineItemDate) {
      const itemDate = new Date(timelineItemDate).getDate();
      const itemMonth = new Date(timelineItemDate).getMonth();
      const itemYear = new Date(timelineItemDate).getFullYear();

      const currentDate = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      if (itemDate == currentDate) {
        return "Today";
      } else if (
        itemDate == currentDate - 1 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "Yest.";
      } else if (
        itemDate == currentDate - 2 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "2 days";
      } else if (
        itemDate == currentDate - 3 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "3 days";
      } else {
        const monthName = new Date(timelineItemDate).toLocaleString("en-US", {
          month: "short",
        });
        const finalDate = monthName + ". " + itemDate;

        return finalDate;
      }
    },
    timelineItemYear(timelineItemDate) {
      const itemYear = new Date(timelineItemDate).getFullYear();
      const currentYear = new Date().getFullYear();

      if (itemYear == currentYear) {
        return "";
      } else {
        return itemYear;
      }
    },
    getUserAvatar(userid) {
      const users = this.$store.state.teammembers;
      //only returns the comments with reference to the project of this profile/project binding
      let byuser = users.filter((obj) => {
        //first check if the comment has a project reference
        return obj.userid === userid;
      });
      let avatarurl;
      

      if(!byuser[0]) {
        avatarurl = "https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
      } else {
        avatarurl = byuser[0].avatarurl;
      }

      return avatarurl;
    },
  },
  computed: {
    sortedActivityLogs() {
      const allTimelineItems = this.$store.state.currentProfileActivityLogs;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      if (this.location == "profiledetailsTimelineCard") {
        //returns all comments for this profile
        return allTimelineItems.sort(compare);
      } else if (this.location == "editProfileProjectBindingModal") {
        //only returns the comments with reference to the project of this profile/project binding
        let itemsWithProject = allTimelineItems.filter((obj) => {
          //first check if the comment has a project reference
          return obj.attachproject === true;
        });
        let itemsForThisProject = itemsWithProject.filter((obj) => {
          //then check if the referenced project is the one in this project/profile biding
          return (
            obj.projectid ===
            this.$store.state.currentProfileProjectBinding.projectid
          );
        });

        let commentsForThisProject = itemsForThisProject.filter((obj) => {
          return obj.typeofupdate === "New comment";
        });

        return commentsForThisProject;
      }
    },  
  },
};
</script>

<style>
</style>