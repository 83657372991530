<template>
  <PaymentConfirmationComponent></PaymentConfirmationComponent>
</template>

<script>
import PaymentConfirmationComponent from '../components/Payment-Confirmation.vue';
export default {
    components: {
        PaymentConfirmationComponent
    }

}
</script>

<style>

</style>

