
<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="'profilesForTeam' + $store.state.userData.teamid"
  >
  <!--begin:hidden search box (operated through v-model value in mainheader input)-->
    <ais-search-box
      class="d-none mb-4"
      v-model="$store.state.searchInputText"
      :class-names="{
        'ais-SearchBox-input':
          'search-input form-control form-control-flush ps-10',
      }"
    />
  <!--begin:hidden search box (operated through v-model value in mainheader input)-->

<div class="d-flex">






    <!--begin:results-->
    <ais-hits
      v-if="transformItems.length > 0"
      :transform-items="transformItems"
    >
      <template v-slot:item="{ item }">
        <div
          class="container bg-hover-light"
          style="margin-top: -14px; margin-bottom: -14px"
        >
          <!-- Begin result row (profile row) -->
          <div
            class="row justify-content-between py-4"
            @click="goToDetailedProfile(item.objectID)"
            @click.stop=""
          >
            <!-- Begin profile info (pic, name, title & company) -->
            <div class="col-12 d-flex align-items-center">
              <div class="symbol symbol-45px me-5" style="margin-left: -20px">
                <img
                  alt="Pic"
                  v-if="item.profilepic"
                  :src="item.profilepic"
                  onerror="this.onerror=null; this.src='https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png'"
                />
                <img
                  alt="Pic"
                  v-if="!item.profilepic"
                  src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
                />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder fs-6">{{
                  item.firstname + " " + item.lastname.toUpperCase()
                }}</a>
                <span
                  v-if="item.currentjobtitle"
                  class="text-muted fw-bold d-block fs-7"
                  >{{
                    item.currentjobtitle.length < 30
                      ? item.currentjobtitle
                      : item.currentjobtitle.substring(0, 30) + "..."
                  }}</span
                >
                <span
                  v-if="item.currentcompany"
                  class="text-dark fw-bold d-block fs-7"
                  >{{
                    item.currentcompany.length < 30
                      ? item.currentcompany
                      : item.currentcompany.substring(0, 30) + "..."
                  }}</span
                >
              </div>
            </div>
            <!-- End profile info (pic, name, title & company) -->

            <div class="d-none col-1">
              <a
                class="btn btn-icon btn-primary btn-sm"
                @click="goToDetailedProfile(item.objectID)"
                @click.stop=""
              >
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/metronic/releases/2022-07-14-092914/core/html/src/media/icons/duotune/arrows/arr064.svg-->
                <span class="svg-icon svg-icon-muted svg-icon-2x"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="18"
                      y="13"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-180 18 13)"
                      fill="currentColor"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </a>
            </div>
          </div>
          <!-- End result row (profile row) -->
        </div>
      </template>
    </ais-hits>
    <!--end:results-->

</div>



    <!--begin:"Nothing found" Alert-->
    <div
      v-if="$store.state.searchResults.length == 0 && origin == 'desktopversion'"
      style="width: 460px !important"
    >
      <CardAlert
        class="mt-6"
        alerttype="info"
        title="No profiles found"
        :text="'Try something else !'"
      ></CardAlert>
    </div>
    <div v-if="$store.state.searchResults.length == 0 && origin != 'desktopversion'">
      <CardAlert
        class="mt-6"
        alerttype="info"
        title="No profiles found"
        :text="'Try something else !'"
      ></CardAlert>
    </div>
    <!--end:"Nothing found" Alert-->


    <!--begin:See All results Button-->
    <div class="d-flex justify-content-center flex-nowrap mt-6">
      <button
        class="btn btn-sm btn-primary"
        v-if="
          $store.state.searchResults.length > 3 && origin != 'mobileversion'
        "
        @click="sendResultsToStore()"
      >
        See all results
      </button>
    </div>
    <!--end:See All results Button-->

    <div class="mt-4"></div>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite-min.css";
import { goToDetailedProfileExternal } from "../../../js/navigation";
import { getSearchResultsExternal } from "../../../js/getdata";
import { createWidgetMixin } from "vue-instantsearch/vue3/es";
import { connectMenu } from "instantsearch.js/es/connectors";
import { connectHits } from "instantsearch.js/es/connectors";
import store from "../../../store/index";
import CardAlert from "../../CardAlerts/CardAlert.vue";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'



export default {
  props: ["origin"],
  components: {
    CardAlert,
    VueSlider
  },
  mounted() { },
  data() {
    return {
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APPID,
        this.$store.state.userData.algoliasearchkey
      ),
      inputtext: "",
    };
  },
  methods: {

  
    toValue(value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max,
      ];
    },
  
    transformItems(items) {
      store.state.searchResults = [];

      for (let i = 0; i < items.length; i++) {
        let profile = items[i];
        profile.id = items[i].objectID;
        profile.statusorder = 0;
        if (
          profile.owninguser == store.state.userData.userId ||
          profile.teamaccess
        ) {
          store.state.searchResults.push(profile);
        }
      }

      const filteredItems = items.filter((item) => {
        return (
          item.owninguser === store.state.userData.userId || item.teamaccess
        );
      });

      return filteredItems.slice(0, 3);
    },
    sendResultsToStore() {
      store.state.searchOn = true;
      //in case the SelectAll checkbox was checked, uncheck it
      if(document.getElementById("selectAllBtn")) {
      document.getElementById("selectAllBtn").checked = false;
      }

      //clear list of selected profiles so that the Batch Manipulate Banner does not show up when clicking Back from ProfileDetails.vue
      store.state.selectedProfiles = [];

      $("#searchDropdown").dropdown("hide");

      getSearchResultsExternal();
    },

    storeSelectedProfile(profileid) {
      alert(profileid);
    },
    goToDetailedProfile(profileId) {
      $("#searchDropdown").dropdown("hide");

      goToDetailedProfileExternal(profileId);
    },
  },
};
</script>

<style>
</style>