<template>
  <!--begin: Add client form-->
  <div class="col-sm mt-10">
    <div class="col-sm d-flex flex-column">
      <div class="row d-flex">
        <div class="col-9">
          <label for="" class="form-label ms-3"
            >Client name
            <span class="required"></span>
          </label>
          <input type="text" v-model="clientname" autocomplete="off" class="form-control mb-6" />
        </div>
        <div class="col-3 mt-8">
          <button v-if="!showlogos" class="btn btn-primary" @click="execute()">
            Add
          </button>
        </div>
      </div>
      <div class="row mt-4" v-if="showlogos && !selectedlogo">
        <label for="" class="form-label ms-3 mb-4">Select logo </label>
        <div class="d-flex" v-if="imageurls.length == 3">
          <div
            v-for="image in imageurls"
            :key="image"
            class="bg-hover-secondary me-3 animate__animated animate__fadeIn companylogo p-2 border border-1 rounded border-light shadow-sm"
            @click="selectLogo(image)"
          >
            <img :src="image" style="width: 100px" alt="" />
          </div>
          <div
            style="width: 120px"
            v-if="imageurls.length == 3"
            class="bg-hover-secondary text-muted d-flex flex-column align-items-center justify-content-center me-3 animate__animated animate__fadeIn companylogo p-2 border border-1 rounded border-light shadow-sm"
            @click="selectLogo('no logo')"
          >
            <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-03-24-172858/core/html/src/media/icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-2hx"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
            No logo
          </div>
        </div>
        <div v-else class="d-flex">
          <div class="mx-auto mt-18 pe-15 mb-16" id="preloader_1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="row mt-4" v-if="selectedlogo">
        <label for="" class="form-label ms-3">Selected logo </label>
        <div
          v-if="imageurls.length == 3 && selectedlogo != 'no logo'"
          style="width: 50px"
          class="ms-7 text-muted d-flex flex-column align-items-center justify-content-center me-3 animate__animated animate__fadeIn companylogo p-2"
        >
          <img :src="selectedlogo" style="width: 50px" alt="" />
        </div>
                <div
          v-if="imageurls.length == 3 && selectedlogo == 'no logo'"
          style="width: 50px"
          class="ms-7 text-muted d-flex flex-column align-items-center justify-content-center me-3 animate__animated animate__fadeIn companylogo p-2"
        >
          <img src="https://cdn-icons-png.flaticon.com/512/4812/4812244.png" style="width: 50px" alt="" />
        </div>
        
      </div>

      <div class="row mt-6 animate__animated animate__fadeIn" v-if="selectedlogo">
        <div class="col-4">
          <label for="" class="form-label ms-3">Location</label>
          <input
            type="text"
            v-model="location"
            id="clientlocationinput"
            autocomplete="off"
            class="form-control mb-6"
          />
        </div>
        <div class="d-none col-3">
          <label for="" class="form-label ms-3">Status</label>

          <select class="form-select custompointer" v-model="status" aria-label="Select example">
            <option>Select</option>
            <option value="Client">Client</option>
            <option value="Lead">Lead</option>
          </select>
        </div>
        <div class="col-5">
          <label for="" class="form-label ms-3">Who can see this client ?</label>
          <select class="form-select custompointer" v-model="teamaccess" aria-label="Select example">
            <option value="Public">
              &#128275; Me and my team</option>
            <option value="Private">&#x1F512; Only me</option>
          </select>
        </div>
      </div>
      <div class="row animate__animated animate__fadeIn" style="margin-top: -10px;" v-if="teamaccess != 'Select'">
        <div class="col-7"></div>
        <div class="col-4 ms-3">
        <span class="fs-7 text-muted" v-if="teamaccess == 'Private'">
          <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/general/gen044.svg-->
<span class="svg-icon svg-icon-muted svg-icon-sm"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/>
<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
</svg>
</span>
<!--end::Svg Icon-->
          Selecting this option can make collaboration difficult. It is intended for highly sensitive data that should not be seen by anyone but you.</span>
        </div>
      </div>

      <div class="row mt-15" v-if="showlogos">
        <div class="d-flex">
          <div class="mx-auto d-flex">
            <button class="btn btn-sm btn-light-danger" @click="resetForm()">Reset</button>
            <button class="ms-3 btn btn-sm btn-primary" @click="saveClient()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end: Add client form-->
</template>

<script>
import store from "../../../store/index";
import { addClientExternal } from "../../../js/createdata.js";
export default {
  data() {
    return {
      showlogos: false,
      showRestOfForm: false,
      clientname: "",
      imageurls: [],
      selectedlogo: "",
      location: "",
      status: "Select",
      teamaccess: "Public"
    };
  },

  //https://developers.google.com/custom-search/v1/reference/rest/v1/cse/list?hl=en&apix_params=%7B%22cx%22%3A%22b1cf97567f978d10c%22%2C%22hq%22%3A%22logo%20square%22%2C%22q%22%3A%22danone%22%2C%22searchType%22%3A%22image%22%7D&apix=true
  methods: {
    loadClient() {
      gapi.client.setApiKey("AIzaSyAGlsOJFLEcQ1EGkXw2gAiQd5m2XrlZF-4");
      return gapi.client
        .load(
          "https://content.googleapis.com/discovery/v1/apis/customsearch/v1/rest"
        )
        .then(
          function () {
            
          },
          function (err) {
            console.error("Error loading GAPI client for API", err);
          }
        );
    },
    execute() {
      this.showlogos = true;

      let images = this.imageurls

      return gapi.client.search.cse
        .list({
          cx: "b1cf97567f978d10c",
          hq: "logo",
          q: this.clientname,
          searchType: "image",
          siteSearchFilter: "i",
        })
        .then(
          function (response) {
            // Handle the results here (response.result has the parsed body).
            console.log("Response", response);
            console.log(response.result.items[0].link);

            images.push(response.result.items[0].link);
            images.push(response.result.items[1].link);
            images.push(response.result.items[2].link);
          },
          function (err) {
            console.error("Execute error", err);
          }
        );
    },
    selectLogo(imageurl) {
      this.selectedlogo = imageurl;
      setTimeout(() => {
        document.getElementById("clientlocationinput").focus();
      }, 200);
    },
    resetForm() {
      this.showlogos = false
      this.showRestOfForm = false
      this.clientname = ""
      this.selectedlogo = ""
      this.imageurls = []
      this.location = ""
      this.status = "Select"
      this.teamaccess = "Public"
    },
    saveClient() {
      let name = this.clientname 
      let logo;
      if(this.selectedlogo == 'no logo') {
        logo = ""
      } else {
        logo = this.selectedlogo
      }
      
      let location = this.location
      let status = this.status
      let teamaccess = this.teamaccess

/* Not needed anymore as the status is always Public by default
      if(status == "Select" || status == "" || teamaccess == "Select" || teamaccess == "") {
        alert("Please select Status and Team access")
        return
      };
      */

      //Add to Firestore
      addClientExternal(name, logo, location, status, teamaccess)

      //Close modal
      $("#addnewclient").modal('hide');

      //Reset form for next time
      this.showlogos = false
      this.showRestOfForm = false
      this.clientname = ""
      this.selectedlogo = ""
      this.imageurls = []
      this.location = ""
      this.status = "Select"
      this.teamaccess = "Public"




    }
  },

  mounted() {
    gapi.load("client");
    setTimeout(() => {
      this.loadClient();
    }, 1000);


  },
};
</script>

<style>
</style>