<template>



  <!--begin::Body-->
  <div class="d-block">
    <!--begin::Message-->
    <Editor
      api-key="uzj6zx7zdrbapy2mocpl5ykvele0syt6hsz6siw79padif48"
      :init="{
        menubar: false,
        skin: 'snow',
      }"
      v-model="computedNotes"
    />
  </div>
  <!--end::Body-->
  <!--begin::Footer-->
  <div class="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top">
    <!--begin::Actions-->
    <div class="d-flex align-items-center me-3 ms-auto">
      <div class="btn-group">
        <!--begin::Submit-->
        <button
          class="btn btn-primary btn-sm fs-bold px-6"
          @click="saveNotes()"
        >
          Save
        </button>
        <!--end::Submit-->
      </div>
      <!--end::send-->
    </div>
    <!--end::Actions-->
  </div>
  <!--end::Footer-->

  <!--end::Form-->
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import CardAlert from "../../CardAlerts/CardAlert.vue";
import { saveClientNotesExternal } from "../../../js/clientdetails"
import { saveProjectNotesExternal } from "../../../js/projectdetails"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
 
computed: {
      computedNotes: {
      get() {
        if (this.$store.state.currentRoute === "/projects") {
          return this.$store.state.currentProjectDetails.notes;
        } else if (this.$store.state.currentRoute === "/clients") {
          return this.$store.state.currentClientDetails.notes;
        }
        // default value
        return '';
      },
      set(newValue) {
        if (this.$store.state.currentRoute === "/projects") {
          this.$store.state.currentProjectDetails.notes = newValue;
        } else if (this.$store.state.currentRoute === "/clients") {
          this.$store.state.currentClientDetails.notes = newValue;
        }
      },
    },
},
  components: {
    Editor,
    CardAlert,
  },
  methods: {
    saveNotes() {
        if(this.$store.state.currentRoute == '/clients') {
        saveClientNotesExternal()

        } else if (this.$store.state.currentRoute == '/projects') {
            saveProjectNotesExternal()
        }
    },
    uploadFile() {
const storage = getStorage();
const storageRef = ref(storage, 'some-child');

console.log("IN PROGRESS...")


// 'file' comes from the Blob or File API
uploadBytes(storageRef, document.getElementById("avatar").files[0]).then((snapshot) => {
  console.log('Uploaded a blob or file!');

getDownloadURL(ref(storage, 'some-child'))
  .then((url) => {
    // `url` is the download URL for 'images/stars.jpg'

/*
    // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();
    */

    // Or inserted into an <img> element
    const img = document.getElementById('myImage');
    img.setAttribute('src', url);

console.log("DONE...")

  })
  .catch((error) => {
    // Handle any errors
  });

});


    },
    showFile() {
      console.log(document.getElementById("avatar").files[0])
    }
  }
};
</script>

<style>
</style>