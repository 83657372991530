<template>

  <!--begin: Main textarea-->
  <div class="d-flex flex-column mb-8 mt-5">
    <textarea
      class="form-control form-control-solid"
      rows="8"
      style=""
      name="target_details"
      id="editProfileCommentTextarea"
      placeholder="Type comment here..."
      v-model="$store.state.currentProfileComment.commenttext"
    ></textarea>
  </div>
  <!--end: Main textarea-->

  <!--begin: Show to client Checkbox-->
  <div class="mb-5">
    <!--begin: checkbox-->
    <div  v-if="$store.state.currentProfileDetails.candidateorclient == 'Candidate'"
          class="mb-5 form-check form-check-custom form-check-solid">
      <input
        class="form-check-input"
        type="checkbox"
        v-model="$store.state.currentProfileComment.showtoclient"
        id="showtoclientcheckbox"
      />
      <label
        class="form-check-label"
        for="showtoclientcheckbox"
      >
        Show to client   <a type="button" class="ms-2 badge badge-circle badge-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Will appear in the client portal">
    ?
</a>
      </label>
    </div>
    <!--end: Show to client Checkbox-->
  </div>


  <!--begin: Attach comment to a project-->
  <div class="mb-5" v-if="$store.state.currentProfileProjectBindings.length > 0 && $store.state.currentProfileDetails.candidateorclient == 'Candidate'">
    <!--begin: checkbox-->
    <div class="mb-5 form-check form-check-custom form-check-solid">
      <input
        class="form-check-input"
        type="checkbox"
        v-model="$store.state.currentProfileComment.attachproject"
        id="editcommentattachcommentroproject"
        @click="toggleattachcommentineditmodal()"
      />
      <label
        class="form-check-label"    
        for="editcommentattachcommentroproject"
      >
        Attach a project to this comment
      </label>
    </div>
    <!--end: checkbox-->

    <div v-if="$store.state.currentProfileComment.attachproject">
      <div>
        <div class="d-flex flex-row justify-content-start">

          <div class="d-flex">
            <div class="symbol symbol-40px">
              <img
                :src="$store.state.currentProfileComment.clientlogo"
                class="h-70 align-self-center"
                alt=""
              />
            </div>
            <div class="ms-4">
              <div class="text-dark fw-bold fs-6">
                {{ $store.state.currentProfileComment.clientname }}
              </div>
              <div class="text-muted fw-semibold d-block">
                {{ $store.state.currentProfileComment.positiontitle }}
              </div>
            </div>
          </div>

          <div class="ms-4 me-4 fs-2 mt-2 text-muted">-</div>

          <div class="d-flex">

            <!--begin::change project dropdown-->
            <div class="dropdown ms-3">

              <button
                class="btn btn-sm btn-flex btn-light"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Or select other project
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                <span class="ms-2 svg-icon svg-icon-4 m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </button>
              <ul
                class="dropdown-menu w-300px w-md-350px py-7 px-7 dropdownFadeIn"
                aria-labelledby="dropdownMenuButton2"
                role="menu"
              >

                <!--begin::Items-->
                <div class="scroll-y mh-200px mh-lg-325px">
                  <!--begin::Item-->
                  <li v-if="$store.state.currentProfileProjectBindings.length > 1" class="ps-3 text-muted mb-4">
                    Projects attached to this profile :
                  </li>
                  <li v-else class="ps-3 text-muted mb-4">
                    No other projects attached <br> to this profile !
                  </li>

                  <li
                    v-for="project in sortedAllProfileBindings"
                    :key="project.bindingid"
                    class="d-flex align-items-center mb-1 py-2 ps-3 pointer latestprofilesitem"
                    @click="changeSelectedProjectForCommentAttachement(project.projectid, project.clientname, project.clientlogo, project.positiontitle, project.linkedproject)"
                  >
                    <!--begin::Symbol-->
                    <div class="symbol symbol-40px me-4">
                      <img
                        :src="project.clientlogo"
                        class=""
                        alt=""
                      />
                    </div>
                    <!--end::Symbol-->
                    <!--begin::Title-->
                    <a
                      class="d-flex flex-column"
                      href="javascript:void(0)"
                    >
                      <span class="fs-6 text-gray-800 fw-bold">{{project.positiontitle}}</span>
                      <span class="fs-7 text-muted fw-bold">{{project.clientname}}</span>
                    </a>
                    <!--end::Title-->
                  </li>
                  <!--end::Item-->
                </div>
                <!--end:items-->

              </ul>
            </div>
            <!--end::change project dropdown-->
          </div>
        </div>
      </div>

    </div>

  </div>
  <!--end: Attach comment to a project-->

  <!--begin: Save comment Button-->
  <div class="d-flex justify-content-end mt-15">
    <button
      class="btn btn-sm btn-primary"
      @click="updateCommentText()"
    >
      Save comment
    </button>
  </div>
  <!--end: Save comment Button-->

</template>

<script>
import { addProfileCommentExternal } from "../../../js/profiledetails.js";
import { changeSelectedProjectForCommentAttachementExternalInEditModal } from "../../../js/profiledetails.js";
import { toggleattachcommentineditmodalExternal } from "../../../js/profiledetails.js";
import { updateCommentTextExternal } from "../../../js/profiledetails.js";




export default {
  data() {
    return {
      attachcomment: true,
    };
  },
  computed: {
    sortedAllProfileBindings() {
      const allBindings = this.$store.state.currentProfileProjectBindings;

      function compare(a, b) {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      }

      return allBindings.sort(compare);
    },
    slicedMoreProfileBindings() {
      return this.sortedAllProfileBindings.slice(1);
    },
  },
  methods: {
    updateCommentText() {
        updateCommentTextExternal()
    },
    toggleattachcommentineditmodal() {
      toggleattachcommentineditmodalExternal()

    },
    changeSelectedProjectForCommentAttachement(projectid, clientname, clientlogo, positiontitle) {

      changeSelectedProjectForCommentAttachementExternalInEditModal(projectid, clientname, clientlogo, positiontitle)

    },
    toggleCommentShowClient() {
      this.$store.state.showCommentToClient = !this.$store.state.showCommentToClient 
    }
  },
};
</script>

<style>
</style>