import {
  onSnapshot,
  query,
  collection,
  collectionGroup,
  getDocs,
  where,
  doc,
  updateDoc,
  getDoc,
  orderBy,
  addDoc,
  limit,
  setDoc,
  writeBatch 
} from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import { db } from "../firebase/index";
import store from "../store/index";
import route from "../router/index";

let unsubToUserList;
let unsubToTeamList;
let unsubToUserLogs;

const getUsersExternal = () => {
  if (unsubToUserList) {
    unsubToUserList();
  }

  const qUserList = query(collection(db, "users"));

  unsubToUserList = onSnapshot(qUserList, (usersSnapshot) => {
    store.state.userList = [];
    usersSnapshot.forEach((userDoc) => {
      const user = userDoc.data();
      user.id = userDoc.id;

      const relatedTeamRef = doc(db, "teams", user.teamid);
      getDoc(relatedTeamRef).then((relatedTeamSnap) => {
        if (relatedTeamSnap.exists()) {
          user.teamname = relatedTeamSnap.data().teamname;
          store.state.userList.push(user);
        } else {
          alert(
            "User " +
              user.firstname +
              " " +
              user.lastname +
              "does not seem to be attached to a team!"
          );
        }
      });
    });
  });
};

const getTeamsExternal = () => {
  if (unsubToTeamList) {
    unsubToTeamList();
  }

  const qTeamList = query(collection(db, "teams"));

  unsubToTeamList = onSnapshot(qTeamList, (teamsSnapshot) => {
    store.state.teamList = [];
    teamsSnapshot.forEach((teamDoc) => {
      const team = teamDoc.data();
      team.id = teamDoc.id;
      store.state.teamList.push(team);
    });
  });
};



//gets the activitylogs for the user when it is loaded into the modal in backoffice
const backOfficeActivityLogsExternal = () => {
  if (unsubToUserLogs) {
    unsubToUserLogs();
  }
  const qUserLogs = query(
    collectionGroup(db, "activitylogs"),
    where("byuser", "==", store.state.backofficeUserDetails.userid),
    orderBy("timestamp", "desc"),
    limit(30)
  );

  unsubToUserLogs = onSnapshot(
    qUserLogs,
    (logsSnapshot) => {
      store.state.backofficeUserDetails.logs = [];
      logsSnapshot.forEach((logDoc) => {
        const log = logDoc.data();
        log.id = logDoc.id;

        //get the parent object (profile, project or client)
        getDoc(logDoc.ref.parent.parent).then((parentSnap) => {
          //store resulting profile in object
          log.parentobject = parentSnap.data();
          log.parentobject.id = parentSnap.id;

          store.state.backofficeUserDetails.logs.push(log);
        });
      });
    },
    (error) => {
      console.log(error);
    }
  );
};

const saveSearchKeyToProfileExternal = (keyvalue, userdocid) => {
  //Update Firestore user doc with the team search API Key
  let userRef = doc(db, "users", userdocid);
  updateDoc(userRef, {
    algoliasearchkey: keyvalue,
  }).then(() => {});
};

const saveAndActivateGeneralAlertExternal = (title, content) => {
  //Update Firestore user doc with the team search API Key
  let settingsRef = doc(db, "globalappsettings", "4fxYN8frxpfDsORtIP1e");
  updateDoc(settingsRef, {
    generalalerttitle: title,
    generalalertmessage: content,
    showgeneralalert: true,
  }).then(() => {
    console.log("General alert updated!");

    // Get a reference to the "users" collection
    const usersCollectionRef = collection(db, "users");

    // Fetch all documents in the collection
    getDocs(usersCollectionRef)
      .then((snapshot) => {
        // Loop through each document
        snapshot.forEach((userDoc) => {
          // Get a reference to the current document
          const docRef = doc(db, "users", userDoc.id);

          // Update the "generalalertstatus" field
          updateDoc(docRef, {
            generalalertstatus: "unread",
          }).catch((error) => {
            console.error("Error updating document: ", error);
          });
        });
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  });
};

const deactivateGeneralAlertExternal = () => {
  //Update Firestore user doc with the team search API Key
  let settingsRef = doc(db, "globalappsettings", "4fxYN8frxpfDsORtIP1e");
  updateDoc(settingsRef, {
    showgeneralalert: false,
  }).then(() => {
    console.log("General alert updated!");
  });
};

const enterTeamExternal = async (team) => {
  const SYSTEM_USER_ID = "Lpq0dVlRDePvmDbh6veRwFOXMF63";

  //check that the user is the System account
  if (store.state.userData.id == SYSTEM_USER_ID) {
    const teamRef = doc(db, "teams", team.id);
    const teamSnap = await getDoc(teamRef);

    if (teamSnap.exists()) {
      // Get algoliasearchkey from the team document
      const algoliasearchkey = teamSnap.data().algoliasearchkey;

      let settingsRef = doc(db, "users", SYSTEM_USER_ID);

      // Update the user document with the team id and algoliasearchkey
      await updateDoc(settingsRef, {
        teamid: team.id,
        algoliasearchkey: algoliasearchkey
      });

      alert("Added system account to team!");
    } else {
      alert("Team not found");
    }
  } else {
    alert("This is not the System Account. Please log in again as system account");
  }
};

const copyStatusListExternal = async (team) => {
  alert(team.id)
  const profileStatusList = store.state.profileStatusList;
  const subCollectionPath = `teams/${team.id}/profilestatuslist`;
  const batch = writeBatch(db);

  profileStatusList.forEach((item) => {
    const docRef = doc(db, subCollectionPath, item.id);
    const data = {
      category: item.category,
      order: item.order,
      status: item.status,
      tracked: false,
    };
    batch.set(docRef, data);
  });

  try {
    await batch.commit();
    console.log('Successfully added items to Firestore.');
  } catch (error) {
    console.error('Error adding items to Firestore: ', error);
  }

}

export {
  getUsersExternal,
  getTeamsExternal,
  backOfficeActivityLogsExternal,
  saveSearchKeyToProfileExternal,
  saveAndActivateGeneralAlertExternal,
  deactivateGeneralAlertExternal,
  enterTeamExternal,
  copyStatusListExternal
};
