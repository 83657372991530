<template>
  <div
    class="d-flex justify-content-center"
    id="kt_app_main"
    style="margin-top: -30px"
  >
    <div id="kt_app_content_container" class="w-500px">
      <!--begin::About card-->
      <div class="card">
        <!--begin::Body-->
        <div class="card-body p-lg-17">
          <!--begin::About-->
          <div>
            <!--begin::Wrapper-->
            <div>
              <h2 class="mb-2 text-left mb-8">Unlock the power of Kiwano</h2>
              <!--begin::Top-->
              <div class="text-left">
                <!--begin::Title-->

                <!--end::Title-->

                <div class="row">
                  <div class="col-4">
                    <!--begin::Pricing-->
                    <div
                      class="text-dark fw-bolder"
                      style="font-size: 4rem"
                    >
                      89€
                    </div>
                    <div class="fs-5 text-muted fw-semibold mb-5">
                      / user / month
                    </div>
                    <!--end::Pricing-->
                  </div>
                  <div class="col-4">
                    <div class="mt-4" style="font-size: 4rem">&#128640;</div>
                  </div>
                </div>
                <div class="row">
                  <!--begin::Text-->
                  <div class="fs-8 text-muted fw-semibold">
                    No long term comittment. <br />Can be canceled at any
                    time.<br />
                    When canceled, the current month will be billed.
                  </div>
                  <!--end::Text-->
                </div>

                <div>
                  <div>
                    <div
                      class="form-check form-check-custom form-check-sm d-flex justify-content-start mt-8"
                    >
                      <div class="border rounded p-4 border-gray-400 d-flex">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="hasacceptedterms"
                          id="flexCheckDefault"
                          @click="acceptTerms()"
                        />
                        <label
                          class="ms-3 fs-5 text-dark fw-semibold"
                          for="flexCheckDefault"
                        >
                          I accept the
                          <a class="text-primary custompointer"
                                          data-bs-toggle="modal"
                data-bs-target="#generalterms"
                            >general terms</a
                          > & the 
                           <a class="text-primary custompointer"
                                          data-bs-toggle="modal"
                data-bs-target="#dpa"
                            >Data processing agreement</a
                          > 

                        </label>
                      </div>
                    </div>
                  </div>



                  <div
                    class="mt-6 text-center"
                    v-if="hasacceptedterms && $store.state.termsacceptancestored"
                  >

<!--TODO: create a third button for users not in EU at all: like CH, NO, UK... with 0 tax and no VAT input field -->                  
<stripe-buy-button
v-if="$store.state.teamData.teamcountry == 'Belgium'"
  buy-button-id="buy_btn_1NqExvLtBQrQJCx8q4pagkxe"
  publishable-key="pk_live_51NSJGMLtBQrQJCx8GYN4MV4F5nZXSJszwwzhPgCqe9kWyA8UXv4uBbOv7Sv0RKdzP4K6CbUyk076OJV6nUBgR2NA00rTW5CNgh"
>
</stripe-buy-button>


<stripe-buy-button
v-if="isEUVATCountry"
  buy-button-id="buy_btn_1NqF45LtBQrQJCx8qok7WwVJ"
  publishable-key="pk_live_51NSJGMLtBQrQJCx8GYN4MV4F5nZXSJszwwzhPgCqe9kWyA8UXv4uBbOv7Sv0RKdzP4K6CbUyk076OJV6nUBgR2NA00rTW5CNgh"
>
</stripe-buy-button>
                  </div>
                  <div class="d-flex" v-if="hasacceptedterms && !$store.state.termsacceptancestored">
                    <div class="mx-auto mt-5 text-muted">
                      Please wait...
                    </div>
                    
                  </div>
                </div>
              </div>
              <!--end::Top-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::About-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::About card-->
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, addDoc } from "firebase/firestore/lite";
import { db } from "../firebase/index";
import router from "../router/index";
import store from "../store/index";
import { addBillingDetailsExternal } from "../js/auth";
import Signup3 from "./FormContents/Signup3.vue";
import Signup4 from "./FormContents/Signup4.vue";
import { acceptTermsAndDpaExternal } from "../js/user";

export default {
  components: { Signup3, Signup4 },
  data() {
    return {
      activeComponent: "Signup3",
      hasacceptedterms: false,
    };
  },
  computed: {
        isEUVATCountry() {
      const euVATCountries = [
        'Austria', 'Bulgaria', 'Croatia', 'Cyprus', 
        'Czech Republic', 'Denmark', 'Estonia', 'Finland', 
        'France', 'Germany', 'Greece', 'Hungary', 'Ireland', 
        'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 
        'Netherlands', 'Poland', 'Portugal', 'Romania', 'Slovakia', 
        'Slovenia', 'Spain', 'Sweden'
      ];
      return euVATCountries.includes(this.$store.state.teamData.teamcountry);
    }
  },
  methods: {
    acceptTerms() {
      setTimeout(() => {
      if(this.hasacceptedterms) {
        acceptTermsAndDpaExternal(this.$store.state.userData.userid)
      }        
      }, 500);

    },
    goToNextStep() {
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (this.activeComponent == "Signup3") {
        //reset FieldIssue for these inputs
        this.$store.state.signup_BillingCompanyNameFieldIssue = false;
        this.$store.state.signup_BillingAddressFieldIssue = false;
        this.$store.state.signup_BillingCityFieldIssue = false;
        this.$store.state.signup_BillingPostalCodeFieldIssue = false;
        this.$store.state.signup_BillingCountryFieldIssue = false;
        this.$store.state.signup_BillingVATNumberFieldIssue = false;
        this.$store.state.signup_ProfileMobileNumberFieldIssue = false;

        //check for empty values
        let FieldIssue = false;

        if (this.$store.state.signup_BillingCompanyName == "") {
          this.$store.state.signup_BillingCompanyNameFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_BillingAddress == "") {
          this.$store.state.signup_BillingAddressFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_BillingCity == "") {
          this.$store.state.signup_BillingCityFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_BillingPostalCode == "") {
          this.$store.state.signup_BillingPostalCodeFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_BillingCountry == "") {
          this.$store.state.signup_BillingCountryFieldIssue = true;
          FieldIssue = true;
        }
        if (this.$store.state.signup_BillingVATNumber == "") {
          this.$store.state.signup_BillingVATNumberFieldIssue = true;
          FieldIssue = true;
        }

        if (FieldIssue) {
          alert("Please fill out the required fields");
          return;
        }

        //go to next step
        this.activeComponent = "Signup4";

        this.addBillingDetails();
      }
    },

    addBillingDetails() {
      addBillingDetailsExternal();
    },
  },
  mounted() {

    const stripe = Stripe('pk_test_51NSJGMLtBQrQJCx81nmrPrhYxnJ6bDDAG4XAzSPtgSXiBlljUqMrdtnaffqW43zjdF6CFlph0tCZWL2sujyyUCS600Ogi4unDz');
const elements = stripe.elements();

    if (
      this.$store.state.userData.subscriptiontype == "free" 
      // ||this.$store.state.userData.subscriptiontype == "paid"
    ) {
      router.push("/profiles");
    }

    //why is this here??
    store.state.currentRoutePath = this.$route.path;

    //get URL params if user is joining an existing company
    console.log(window.location.href);

    const paramArray = window.location.search;

    /*
    this.joinedCompanyName = paramArray[1];
    this.joinedCompanyLogoURL = paramArray[2];
    this.joinedCompanyCity = paramArray[3];
    this.joinedCompanyCountry = paramArray[4];
    */

    console.log(paramArray);

    if (this.joinedCompanyName) {
      this.activeComponent = "Signup1ExistingCompany";
    }
  },
};
</script>

<style></style>
