<template>
  <div class="d-none" v-if="$store.state.allemailsin.length > 0">
    <div
      v-for="email in $store.state.allemailsin"
      :key="email.id"
      class="d-flex align-items-center bg-hover-light py-3"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#email"
      @click="openEmail(email)"
    >
      <!--begin::Unread Email bar-->
      <span
        v-if="email.readstatus == 'unread'"
        class="me-2 bullet bullet-vertical h-40px bg-primary"
      ></span>
      <span
        v-if="email.readstatus != 'unread'"
        class="me-2 bullet bullet-vertical h-40px bg-white"
      ></span>
      <!--end::Unread Email bar-->

      <div class="pe-5">
        <!--begin::Author details-->
        <div class="d-flex flex-column flex-wrap gap-1">
          <div class="d-flex">
            <div>
              {{
                email.emailfrom.length > 15
                  ? email.emailfrom.slice(0, 15) + "..."
                  : email.emailfrom
              }}
            </div>

            <div class="ms-3">
              <!--begin::Svg Icon | path: icons/duotune/abstract/abs050.svg-->
              <span class="svg-icon svg-icon-7 svg-icon-success me-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
                </svg>
              </span>
              <!--end::Svg Icon-->
              <span class="text-muted fw-bold">{{
                emailDate(email.timestamp)
              }}</span>
            </div>
          </div>

          <!--begin::Preview message-->
          <div class="d-flex">
            <span
              v-if="
                email.attachmentcount != '0' && email.attachmentcount != 'False'
              "
              ><!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/communication/com008.svg-->
              <span class="svg-icon svg-icon-muted svg-icon-3 me-2"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <!--end::Svg Icon--></span
            >
            <div
              class="text-muted fw-semibold mw-450px"
              data-kt-inbox-message="preview"
            >
              {{ email.emailsubject }}
            </div>
          </div>

          <!--end::Preview message-->
        </div>
        <!--end::Author details-->
      </div>
    </div>
  </div>

  <div v-if="showEmailLoader">
    <div class="loading-dots mt-20">
      <div class="loading-dots--dot graydots me-1"></div>
      <div class="loading-dots--dot graydots me-1"></div>
      <div class="loading-dots--dot graydots"></div>
    </div>
  </div>
  <div v-if="!showEmailLoader" class="animate__animated animate__fadeIn">
    <!--begin::Accordion-->
    <div
      class="accordion h-400px scroll-y me-n5 pe-5"
      v-if="$store.state.allemailsin.length > 0"
      id="emails_accordion"
    >
      <div class="" v-for="email in $store.state.allemailsin" :key="email.id">
        <h2 class="accordion-header" :id="'kt_accordion_header_' + email.id">
          <button
            class="accordion-button fs-7 fw-semibold collapsed"
            style="
              padding-top: 10px !important;
              padding-bottom: 10px !important;
              padding-left: 10px !important;
            "
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#kt_accordion_body_' + email.id"
            aria-expanded="true"
            :aria-controls="'#kt_accordion_body_' + email.id"
          >
            <!--begin::Unread Email bar-->
            <span
              v-if="email.readstatus == 'unread'"
              class="me-2 bullet bullet-vertical h-40px bg-primary"
            ></span>
            <span
              v-if="email.readstatus != 'unread'"
              class="me-2 bullet bullet-vertical h-40px bg-white"
            ></span>
            <!--end::Unread Email bar-->

            <div class="pe-5">
              <!--begin::Author details-->
              <div class="d-flex flex-column flex-wrap gap-1">
                <div class="d-flex">
                  <div>
                    {{
                      email.emailfrom.length > 15
                        ? email.emailfrom.slice(0, 15) + "..."
                        : email.emailfrom
                    }}
                  </div>

                  <div class="ms-3">
                    <!--begin::Svg Icon | path: icons/duotune/abstract/abs050.svg-->
                    <span class="svg-icon svg-icon-7 svg-icon-success me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <circle
                          fill="currentColor"
                          cx="12"
                          cy="12"
                          r="8"
                        ></circle>
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <span class="text-muted fw-bold">{{
                      emailDate(email.timestamp)
                    }}</span>
                  </div>
                </div>

                <!--begin::Preview message-->
                <div class="d-flex">
                  <span
                    v-if="
                      email.attachmentcount != '0' &&
                      email.attachmentcount != 'False'
                    "
                    ><!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/communication/com008.svg-->
                    <span class="svg-icon svg-icon-muted svg-icon-3 me-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                          fill="currentColor"
                        />
                        <path
                          d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon--></span
                  >
                  <div
                    class="text-muted fw-semibold mw-450px"
                    data-kt-inbox-message="preview"
                  >
                    {{ email.emailsubject }}
                  </div>
                </div>

                <!--end::Preview message-->
              </div>
              <!--end::Author details-->
            </div>
          </button>
        </h2>
        <div
          :id="'kt_accordion_body_' + email.id"
          class="accordion-collapse collapse"
          :aria-labelledby="'kt_accordion_header_' + email.id"
          data-bs-parent="#emails_accordion"
        >
          <div class="accordion-body text-muted">
            {{ email.emailsnippet }}
          </div>
          <div class="d-flex mb-8" style="margin-top: -10px">
            <button
              class="btn btn-sm btn-light-primary ms-auto me-8"
              data-bs-toggle="modal"
              data-bs-target="#email"
              @click="openEmail(email)"
            >
              Open email
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->
    <div v-if="$store.state.allemailsin.length == 0 && $store.state.userData.nylasaccountid">
      <CardAlert
        alerttype="info"
        title="No emails"
        text="It seems that you have not received any emails yet."
      ></CardAlert>
    </div>
    <div v-if="!$store.state.userData.nylasaccountid" style="margin-top: -10px">
      <CardAlert
        alerttype="light"
        title="No email account connected"
        text="Connect your Gmail or Outlook account to use your email directly in Kiwano."
      ></CardAlert>
      <div class="d-flex mt-6 mb-20">
        <a
          class="btn btn-sm btn-light-primary mx-auto"
                                      data-bs-toggle="modal"
                            data-bs-target="#emailintegration"
          ><!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-06-08-055059/core/html/src/media/icons/duotune/coding/cod007.svg--><span
            class="me-3 svg-icon svg-icon-muted svg-icon-2x"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z"
                fill="currentColor"
              ></path>
              <path
                d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z"
                fill="currentColor"
              ></path></svg></span
          ><!--end::Svg Icon-->
          Connect your email account
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllEmailsIn, getSingleProfileExternal } from "@/js/getdata";
import { markAsReadExternal } from "@/js/profiledetails";

import CardAlert from "../../CardAlerts/CardAlert.vue";

import router from "../../../router/index.js";

export default {
  data() {
    return {
      showEmailLoader: true,
    };
  },

  components: {
    CardAlert,
  },
  created() {
    this.$store.state.allemailsin = [];
  },
  mounted() {
    getAllEmailsIn();
    setTimeout(() => {
      this.showEmailLoader = false;
    }, 1500);
  },
  methods: {
    emailDate(emaildate) {
      const itemDate = new Date(emaildate).getDate();
      const itemMonth = new Date(emaildate).getMonth();
      const itemYear = new Date(emaildate).getFullYear();

      const currentDate = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      if (itemDate == currentDate) {
        return "Today";
      } else if (
        itemDate == currentDate - 1 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "Yest.";
      } else if (
        itemDate == currentDate - 2 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "2 days ago";
      } else if (
        itemDate == currentDate - 3 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "3 days ago";
      } else {
        const monthName = new Date(emaildate).toLocaleString("en-US", {
          month: "short",
        });
        const finalDate = monthName + ". " + itemDate;

        return finalDate;
      }
    },
    openEmail(email) {
      //the second argument is used to prevent switching to projectdetails when opening an email from notification
      getSingleProfileExternal(email.profileid, "notifications");

      this.$store.state.showingProfilesAs = "profileDetails";

      //go to profiles
      router.push("/profiles");

      document.getElementById("openNotifications2").click();

      //open email
      //Clear previous email in modal, if any
      this.$store.state.currentEmailMessage = {};

      //prepare form
      this.$store.state.emailSubject = "Re: " + email.emailsubject;
      this.$store.state.emailBody =
        "<p></p><p></p>" +
        this.$store.state.userData.emailsignature +
        "<br>On " +
        Date(email.timestamp).toLocaleString("en-US") +
        "<br><" +
        email.emailfrom +
        "> wrote: " +
        email.emailbodyhtml;

      //load clicked email in the modal
      this.$store.state.currentEmailMessage = email;

      //if it's an incoming email...
      if (email.emailfrom != this.$store.state.userData.email) {
        markAsReadExternal(email);
      }
    },
  },
};
</script>

<style></style>
