<template>

  <div
    class="wrapper d-flex flex-column flex-row-fluid container-xxl"
    id="kt_wrapper"
  >
    <div class="d-flex flex-row flex-column-fluid align-items-stretch">
      <!--begin::Content-->
      <div
        class="content flex-row-fluid"
        id="kt_content"
      >
        <!--begin::Card-->

        <!--begin::Tables Widget 9-->
        <div class="card mb-5 mb-xl-8">
          <!--begin::Header-->

          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-3">

            <div class="container">
              <div class="row">
                <!--begin: Add client form-->
                <div class="col-sm d-flex flex-column">
                  <h3 class="mb-4 mt-4">Add a project</h3>
                  <label
                    for=""
                    class="form-label mt-5 ms-3"
                  >Client name</label>
                  <select
                    v-model="projectclient"
                    class="form-select mb-6"
                  >
                    <option
                      v-for="client in $store.state.currentClientList"
                      :value="client"
                      :key="client.id"
                    >
                      {{ client.clientname }}
                    </option>
                  </select>
                  <label
                    for=""
                    class="form-label ms-3"
                  >Position</label>
                  <input
                    type="text"
                    v-model="positiontitle"
                    class="form-control mb-6"
                    placeholder="positiontitle"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Location</label>
                  <input
                    type="text"
                    v-model="projectlocation"
                    class="form-control mb-6"
                    placeholder="location"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Start at</label>
                  <input
                    type="text"
                    v-model="startedat"
                    class="form-control mb-6"
                    placeholder="startedat"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Ended at</label>
                  <input
                    type="text"
                    v-model="endedat"
                    class="form-control mb-6"
                    placeholder="endedat"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Status</label>
                  <input
                    type="text"
                    v-model="projectstatus"
                    class="form-control mb-6"
                    placeholder="status"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Teamaccess</label>
                  <input
                    type="text"
                    v-model="projectteamaccess"
                    class="form-control mb-6"
                    placeholder="teamaccess"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Owning user</label>
                  <input
                    type="text"
                    v-model="projectowninguser"
                    class="form-control mb-6"
                    placeholder="owninguser"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Other users</label>
                  <input
                    type="text"
                    v-model="projectotherusers"
                    class="form-control mb-6"
                    placeholder="otherusers"
                  />
                  <label
                    for=""
                    class="form-label ms-3"
                  >Team</label>
                  <input
                    type="text"
                    v-model="projectteamid"
                    class="form-control mb-6"
                    placeholder="other users"
                  />
                  <button
                    class="btn btn-primary"
                    @click="addNewProject()"
                  >Add</button>
                </div>
                <!--end: Add client form-->
                <!--begin: Add project form-->
                <div class="col-sm">
                  <div class="col-sm d-flex flex-column">
                    <h3 class="mb-4 mt-4">Add a client</h3>
                    <label
                      for=""
                      class="form-label mt-5 ms-3"
                    >Client name</label>
                    <input
                      type="text"
                      v-model="clientclientname"
                      class="form-control mb-6"
                      placeholder="clientname"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Location</label>
                    <input
                      type="text"
                      v-model="clientlocation"
                      class="form-control mb-6"
                      placeholder="location"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Number of projects</label>
                    <input
                      type="text"
                      v-model="numberfprojects"
                      class="form-control mb-6"
                      placeholder="number of projects"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Client logo URL</label>
                    <input
                      type="text"
                      v-model="clientlogourl"
                      class="form-control mb-6"
                      placeholder="logo url"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Status</label>
                    <input
                      type="text"
                      v-model="clientstatus"
                      class="form-control mb-6"
                      placeholder="status"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Owning user</label>
                    <input
                      type="text"
                      v-model="clientowninguser"
                      class="form-control mb-6"
                      placeholder="owninguser"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Other users</label>
                    <input
                      type="text"
                      v-model="clientotherusers"
                      class="form-control mb-6"
                      placeholder="other users"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Team</label>
                    <input
                      type="text"
                      v-model="clientteamid"
                      class="form-control mb-6"
                      placeholder="other users"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Creation date</label>
                    <input
                      type="text"
                      v-model="clientcreatedat"
                      class="form-control mb-6"
                      placeholder="created at"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Teamaccess</label>
                    <select
                      v-model="clientteamaccess"
                      class="form-select mb-6"
                    >
                      <option
                        disabled
                        value=""
                      >Please select one</option>
                      <option>Private</option>
                      <option>Public</option>
                    </select>
                    <button
                      class="btn btn-primary"
                      @click="addNewClient()"
                    >Add</button>
                  </div>

                </div>
                <!--end: Add project form-->
                <!--begin: Add user form-->
                <div class="col-sm">
                  <div class="col-sm d-flex flex-column">
                    <h3 class="mb-4 mt-4">Add a user</h3>
                    <label
                      for=""
                      class="form-label mt-5 ms-3"
                    >Lastname</label>
                    <input
                      type="text"
                      v-model="lastname"
                      class="form-control mb-6"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Firstname</label>
                    <input
                      type="text"
                      v-model="firstname"
                      class="form-control mb-6"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Email</label>
                    <input
                      type="text"
                      v-model="email"
                      class="form-control mb-6"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Teamid</label>
                    <input
                      type="text"
                      v-model="teamid"
                      class="form-control mb-6"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Userid</label>
                    <input
                      type="text"
                      v-model="userid"
                      class="form-control mb-6"
                    />
                    <label
                      for=""
                      class="form-label ms-3"
                    >Profile pic URL</label>
                    <input
                      type="text"
                      v-model="avatarurl"
                      class="form-control mb-6"
                    />

                    <button
                      class="btn btn-primary"
                      @click="addNewUser()"
                    >Add</button>
                  </div>

                </div>
                <!--end: Add user form-->
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import {
  onSnapshot,
  query,
  collection,
  collectionGroup,
  getDocs,
  where,
  doc,
  updateDoc,
  getDoc,
  orderBy,
  addDoc,
  limit,
  arrayUnion
} from "firebase/firestore";
import { db } from "../firebase/index";

import { addNewUserExternal } from "../js/createdata"

export default {
  data() {
    return {
      //For projects
      projectclient: "",
      positiontitle: "",
      projectclientlogourl: "",
      projectlocation: "",
      startedat: Date.now(),
      endedat: "todo",
      projectstatus: "",
      projectteamaccess: "",
      projectowninguser: this.$store.state.currentUserId,
      projectotherusers: "",
      projectteamid: this.$store.state.userData.teamid,

      //For clients
      clientclientname: "",
      clientlocation: "",
      numberfprojects: "",
      clientlogourl: "",
      clientstatus: "",
      clientowninguser: this.$store.state.currentUserId,
      clientteamid: this.$store.state.userData.teamid,
      clientotherusers: "todo",
      clientcreatedat: Date.now(),
      clientteamaccess: "",

      //For users
      lastname: "",
      firstname: "",
      email: "",
      teamid: "",
      userid: "",
      avatarurl: "",
    };
  },
  methods: {
    addNewProject() {
      let profilesRef = collection(db, "projects");

      addDoc(profilesRef, {
        clientobject: this.projectclient,
        positiontitle: this.positiontitle,
        location: this.projectlocation,
        startedat: this.startedat,
        endedat: this.endedat,
        status: this.projectstatus,
        teamaccess: this.projectteamaccess,
        owninguser: this.projectowninguser,
        otherusers: this.projectotherusers,
        team: this.projectteamid,
        targetcompanies: [],
        numberofpotentialcandidates: 0,
        numberofdiscarded: 0,
        numberofinprogress: 0,
        numberofnostatus: 0,
        notes: "",
        otherusers: []
      })
        .catch((e) => {
          console.log(e.error);
        })
        .then(() => {
          alert("Project added!");
        });
    },
    addNewClient() {
      let clientsRef = collection(db, "clients");

      addDoc(clientsRef, {
        clientname: this.clientclientname,
        location: this.clientlocation,
        numberfprojects: this.numberfprojects,
        clientlogourl: this.clientlogourl,
        clientstatus: this.clientstatus,
        owninguser: this.clientowninguser,
        otherusers: this.clientotherusers,
        createdat: this.clientcreatedat,
        teamaccess: this.clientteamaccess,
        team: this.clientteamid,
        notes: ""
      })
        .catch((e) => {
          console.log(e.error);
        })
        .then(() => {
          alert("Client added!");
        });
    },
    addNewUser() {

      addNewUserExternal(this.lastname, this.firstname, this.email, this.teamid, this.userid, this.avatarurl)


    },
  },
  mounted() {},
};
</script>

<style>
</style>