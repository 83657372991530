<template>
  <!--begin::Sticky aside-->
  <div class="card bg-dark card-flush mb-0 w-200px" style="border-radius: 0; height: 100vh">
    <!--begin::Aside content-->
    <div class="card-header">
                <!--begin::Logo-->
        <div class="d-flex mt-15">
          <a href="https://app.kiwanotalent.com">
            <!--begin::Desktop modes-->
            <img
              alt="Logo"
              src="../assets/media/logos/kiwano.png"
              class="d-none d-lg-inline-block h-50px"
            />
            <!--end::Desktop modes-->
            <!--begin::Table & mobile modes-->
            <img
              alt="Logo"
              src="../assets/media/logos/logo-2.svg"
              class="d-lg-none h-25px"
            />
            <!--end::Table & mobile modes-->
          </a>
          <!--begin::Signup subtitle Signup-->
          <div v-if="currentRouteName == '/signup'">
            <div
              class="h-30px mt-3 ms-2"
              style="border-left: 2px solid white"
            ></div>
            <div style="margin-top: -28px">
              <h1 class="ms-7 text-white">Signup</h1>
            </div>
          </div>
          <!--end::Signup subtitle Signup-->
          <!--begin::Signup subtitle Billing-->
          <div v-if="currentRouteName == '/billing'">
            <div
              class="h-30px mt-3 ms-2"
              style="border-left: 2px solid white"
            ></div>
            <div style="margin-top: -28px">
              <h1 class="ms-7 text-white">Subscribe</h1>
            </div>
          </div>
          <!--end::Signup subtitle Billing-->
        </div>
        <!--end::Logo-->
        
    </div>
    <div class="card-body">


<div class="ms-2 text-white">Navigation</div>
      <!--begin::Menu-->
      <div
        class="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10"
      >
        <!--begin::Menu item-->
        <div class="menu-item mb-1">
          <!--begin::profiles-->
          <router-link to="/profiles">
            <span class="menu-link bg-opacity-0" @click="goToProfileList()">
              <span
                class="menu-title fw-bold"
                :class="{ 'text-white': currentRouteName != '/profiles' }"
                >
<i class="fa fa-genderless fs-1 me-3" aria-hidden="true" :class="{ 'text-muted': currentRouteName != '/profiles', 'text-primary': currentRouteName == '/profiles'  }"></i>
                Profiles</span
              >
            </span>
          </router-link>
          <!--end::profiles-->
          <!--begin::recent profile-->
          <div class="ms-12 d-flex animate__animated animate__fadeIn" v-if="$store.state.currentProfileDetails">
<router-link to="/profiles">
<div class="menu-link text-white bg-opacity-0 text-hover-primary fs-8" @click="goToProfile()" style="margin-top: -7px; margin-left: -5px; text-decoration: underline;">

        {{ $store.state.currentProfileDetails.firstname + " " + $store.state.currentProfileDetails.lastname }}
</div>
</router-link>
    </div>
    <!--end::recent profile-->
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item mb-1">
          <!--begin::projects-->
          <router-link to="/projects">
            <span class="menu-link bg-opacity-0" @click="goToProjectList()">
              <span
                class="menu-title fw-bold"
                :class="{ 'text-white': currentRouteName != '/projects' }"
                >
                <i class="fa fa-genderless text-primary fs-1 me-3" aria-hidden="true" :class="{ 'text-muted': currentRouteName != '/projects', 'text-primary': currentRouteName == '/projects'  }"></i>
                Projects</span
              >
            </span>
          </router-link>
          <!--end::projects-->
                    <!--begin::recent project-->
          <div class="ms-12 d-flex animate__animated animate__fadeIn" v-if="$store.state.currentProjectDetails">
<router-link to="/projects">
<div class="menu-link text-muted text-hover-primary fs-8" @click="goToProject()" style="margin-top: -7px; margin-left: -5px; text-decoration: underline;">

        {{ $store.state.currentProjectDetails.positiontitle + " - " + $store.state.currentProjectDetails.clientobject.clientname }}
</div>
</router-link>
    </div>
    <!--end::recent project-->
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item mb-1">
          <!--begin::clients-->
          <router-link to="/clients">
            <span class="menu-link bg-opacity-0" @click="goToClientList()">
              <span
                class="menu-title fw-bold"
                :class="{ 'text-white': currentRouteName != '/clients' }"
                >
                <i class="fa fa-genderless text-primary fs-1 me-3" aria-hidden="true" :class="{ 'text-muted': currentRouteName != '/clients', 'text-primary': currentRouteName == '/clients'  }"></i>
                Clients</span
              >
            </span>
          </router-link>
          <!--end::clients-->
        <!--begin::recent client-->
          <div class="ms-12 d-flex animate__animated animate__fadeIn" v-if="$store.state.currentClientDetails">
<router-link to="/clients">
<div class="menu-link text-muted text-hover-primary fs-8"  @click="goToClient()" style="margin-top: -7px; margin-left: -5px; text-decoration: underline;">

        {{ $store.state.currentClientDetails.clientname }}
</div>
</router-link>
    </div>
    <!--end::recent client-->
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item mb-1">
          <!--begin::dashboard-->
          <router-link to="/dashboard">
            <span class="menu-link bg-opacity-0">
              <span
                class="menu-title fw-bold"
                :class="{ 'text-white': currentRouteName != '/dashboard' }"
                >
                <i class="fa fa-genderless text-primary fs-1 me-3" aria-hidden="true" :class="{ 'text-muted': currentRouteName != '/dashboard', 'text-primary': currentRouteName == '/dashboard'  }"></i>
                Dashboard</span
              >
            </span>
          </router-link>
          <!--end::dashboard-->

        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu-->
    </div>
    <!--end::Aside content-->
  </div>
  <!--end::Sticky aside-->
</template>

<script>
export default {
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
  },
  methods: {
        goToProfile() {
            this.$store.state.showingProfilesAs = "profileDetails"
        },
        goToProfileList() {
            if(this.$store.state.profilesViewInCache) {
                this.$store.state.showingProfilesAs = this.$store.state.profilesViewInCache
            } else {
                this.$store.state.showingProfilesAs = "recentProfiles"
            }
        },
        goToProject() {
           this.$store.state.showingProjectsAs = "projectDetails" 
        },
        goToProjectList() {
           this.$store.state.showingProjectsAs = "myProjects" 
        },
        goToClient() {
            this.$store.state.showingClientsAs = "clientDetails" 
        },
        goToClientList() {
            this.$store.state.showingClientsAs = "myClients" 
        }

    }
};
</script>

<style>
</style>