<template>
  <!--begin::Timeline-->
  <div class="timeline" v-if="$store.state.currentProfileComments.length > 0">
    <!--begin::Timeline item-->

    <TransitionGroup name="list" tag="div">
      <div
        class="timeline-item"
        v-for="comment in $store.state.currentProfileComments"
        :key="comment.id"
      >
        <!--begin::Timeline line-->
        <div class="timeline-line w-40px"></div>
        <!--end::Timeline line-->
        <!--begin::Timeline icon-->
        <div class="ms-1 timeline-icon symbol symbol-circle symbol-40px me-4">
          <div class="symbol symbol-circle symbol-35px">
            <img
              v-if="
                !comment.userisaclient && getUserAvatar(comment.userwhocomments).length > 2
              "
              :src="getUserAvatar(comment.userwhocomments)"
              alt="img"
            />
            <div
            class="symbol-label fs-5 fw-semibold text-primary"
             v-if="
                !comment.userisaclient && getUserAvatar(comment.userwhocomments).length == 2
              ">
              {{ getUserAvatar(comment.userwhocomments) }}
            </div>
            <div
              v-if="
                !comment.userisaclient &&
                !getUserAvatar(comment.userwhocomments)
              "
            > 
              <img src="" alt="">
            </div>

            <img
              v-if="
                comment.userisaclient && getUserAvatar(comment.userwhocomments)
              "
              src="https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png"
              alt="img"
            />
          </div>
        </div>
        <!--end::Timeline icon-->
        <!--begin::Timeline content-->
        <div class="timeline-content mb-8 mt-n1">
          <!--begin::Timeline content-->
          <div class="d-flex flex-column">
            <div
              v-if="comment.userisaclient"
              style="margin-bottom: -10px; z-index: 2"
            >
              <span class="badge badge-sm badge-light text-primary"
                >Client ({{ computedUser(null, comment, "fromcomment") }})</span
              >
            </div>
            <div
              class="timeline-content mb-2 bg-light bg-hover-secondary p-3 rounded"
              :class="{
                'border-primary': comment.userisaclient,
                border: comment.userisaclient,
              }"
              data-bs-toggle="modal"
              data-bs-target="#editProfileComment"
              @click="initializeEditCommentModal(comment)"
            >
              <!--begin::Timeline main-->
              <div class="pe-3 mb-5">
                <!--begin::Description-->
                <div
                  v-if="comment.commenttext"
                  class="d-flex align-items-center mt-1 fs-6"
                >
                  <!--begin::Info-->
                  <div class="d-flex flex-column">
                    <!--commenttext can be either a comment or a task-->
                    <div class="d-flex align-items-start"></div>
                    <div class="fs-7 fw-bold text-gray-800 ps-3">
                      {{ comment.commenttext }}
                    </div>
                  </div>
                  <!--end::Info-->
                </div>

                <!--end::Description-->
              </div>

              <!--end::Timeline main-->
              <!--begin::Timeline details-->
              <div class="overflow-auto">
                <!--begin::related project-->
                <div class="ms-2" v-if="comment.attachproject">
                  <div class="d-flex flex-row">
                    <div class="symbol symbol-circle symbol-30px me-3">
                      <img
                        :src="comment.clientlogo"
                        class="align-self-center"
                        alt=""
                      />
                    </div>
                    <div>
                      <a
                        href="#"
                        class="text-dark fw-bold text-hover-primary fs-8"
                        >{{ comment.clientname }}</a
                      >
                      <div class="">
                        <span class="text-muted fw-semibold d-block fs-9">{{
                          comment.positiontitle
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::related project-->
              </div>
              <!--end::Timeline details-->
            </div>
            <!--end::Timeline content-->

            <!--end::Timeline content-->
            <!--begin::Info-->
            <!--begin: Footer-->
            <div class="d-flex">
              <!--begin: checkbox-->
              <div
                v-if="
                  $store.state.currentProfileDetails.candidateorclient ==
                    'Candidate' && comment.attachproject
                "
                class="form-check form-check-custom form-check-solid ms-6"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  style="height: 17px !important; width: 17px !important"
                  v-model="comment.showtoclient"
                  id="showtoclientcheckboxinlist"
                  @click="
                    updateShowToClientCheckbox(comment.id, comment.showtoclient)
                  "
                />
                <label
                  class="form-check-label mt-1"
                  for="showtoclientcheckboxinlist"
                >
                  Show to client
                  <a
                    type="button"
                    class="ms-2 badge badge-circle badge-secondary"
                    style="height: 17px; width: 17px"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Will appear in the client portal"
                  >
                    ?
                  </a>
                </label>
              </div>
              <!--end: Show to client Checkbox-->
              <!--begin: Date-->
              <div class="d-flex ms-auto">
                <div class="fs-7 text-muted">
                  {{ timelineItemDate(comment.timestamp) }}
                </div>
                <div
                  v-if="
                    timelineItemDate(comment.timestamp) == '2 days' ||
                    timelineItemDate(comment.timestamp) == '3 days'
                  "
                  class="fs-7 text-muted mb-5 ms-1"
                >
                  ago
                </div>
                <div class="fs-7 text-muted">
                  {{ timelineItemYear(comment.timestamp) }}
                </div>
              </div>
              <!--end: Date-->
              <div class="d-flex flex-row">
                <!--begin::Svg Icon (delete btn) | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-08-29-071832/core/html/src/media/icons/duotune/arrows/arr011.svg-->
                <span
                  class="svg-icon svg-icon-muted ms-1 svg-icon-xs custompointer"
                  @click="deleteProfileComment(comment.id)"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="6"
                      y="17.3137"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    />
                    <rect
                      x="7.41422"
                      y="6"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon (delete btn)-->
              </div>
            </div>
            <!--end: Footer-->

            <!--end::Info-->
          </div>
        </div>
      </div>
    </TransitionGroup>
    <!--end::Timeline item-->
  </div>
  <!--end::Timeline-->

  <div v-else>
    <CardAlert
      alerttype="info"
      title="No comments yet"
      text="Click the button above to add a comment."
    ></CardAlert>
  </div>
</template>

<script>
import { deleteProfileCommentExternal } from "../../../js/profiledetails";
import CardAlert from "../../CardAlerts/CardAlert.vue";
import { updateShowToClientCheckboxExternal } from "../../../js/profiledetails";

export default {
  props: ["location"],
  components: {
    CardAlert,
  },
  methods: {
 computedUser(email, comment, origin) {
  if (origin === "fromprofilebutton") {
    const user = this.$store.state.currentProjectDetails.authorisedclientusersforportal.find(
      (member) => member.email === email
    );

    if (user) {
      return user.firstname;
    } else {
      return this.$store.state.userData.firstname;
    }
  } else if (origin === "fromcomment") {
    const allProjects = this.$store.state.currentMyProjectList.concat(
      this.$store.state.currentTeamProjectList
    );

    const currentproject = allProjects.find(
      (project) => project.id === comment.projectid
    );

    if (!currentproject) {
      return "Deleted project"; // Return an appropriate default value
    }

    const user = this.$store.state.teammembers.find(
      (member) => member.userid === comment.userwhocomments
    );

    if (!user) {
      return "User not found2"; // Return an appropriate default value
    }

    const user2 = currentproject.authorisedclientusersforportal.find(
      (member) => member.email === user.email
    );

    if (!user2) {
      return "Client contact deleted by user"; // Return an appropriate default value
    }

    return user2.firstname;
  } else {
    return "Invalid origin2"; // Return an appropriate default value for unknown origins
  }
},
    showId(commentid) {
      alert(commentid);
    },
    deleteProfileComment(commentid) {

  if (this.$store.state.userData.userrole != 'Administrator') {
    alert("Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data.")
    return
  } 

      //before deleting the comment from Firestore, we need to remove it from the rendered array, so that the Vue animation can happen
      const commentList = this.$store.state.currentProfileComments;
      const index = commentList.map((e) => e.id).indexOf(commentid);
      commentList.splice(index, 1);

      //call Delete function
      deleteProfileCommentExternal(commentid);
    },
    returnStatusCategory(status) {
      const statusList = this.$store.state.profileStatusList;

      const index = statusList.findIndex((object) => {
        return object.status === status;
      });
      return statusList[index].category;
    },
    timelineItemDate(timelineItemDate) {
      const itemDate = new Date(timelineItemDate).getDate();
      const itemMonth = new Date(timelineItemDate).getMonth();
      const itemYear = new Date(timelineItemDate).getFullYear();

      const currentDate = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      if (itemDate == currentDate) {
        return "Today";
      } else if (
        itemDate == currentDate - 1 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "Yest.";
      } else if (
        itemDate == currentDate - 2 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "2 days";
      } else if (
        itemDate == currentDate - 3 &&
        itemMonth == currentMonth &&
        itemYear == currentYear
      ) {
        return "3 days";
      } else {
        const monthName = new Date(timelineItemDate).toLocaleString("en-US", {
          month: "short",
        });
        const finalDate = monthName + ". " + itemDate;

        return finalDate;
      }
    },
    timelineItemYear(timelineItemDate) {
      const itemYear = new Date(timelineItemDate).getFullYear();
      const currentYear = new Date().getFullYear();

      if (itemYear == currentYear) {
        return "";
      } else {
        return " ," + itemYear;
      }
    },
    getUserAvatar(userid) {
      const users = this.$store.state.teammembers;
      const defaultAvatarUrl =
        "https://as.nyu.edu/content/dam/nyu-as/faculty/images/profilePhotos/profile-image-placeholder.png";


      //If Lakeside commented on a client account
      if(userid == "2WUdpmVtGtX3PwZU9HKo4g6MYOj2") {
        return "https://firebasestorage.googleapis.com/v0/b/kiwano-3f6de/o/clientuseravatars%2F1577967171116.jpeg?alt=media&token=9957cf85-eb4a-4976-974c-1686ddd71dd0"
      }

      // Find the user with the matching userID
      let byuser = users.filter((obj) => {
        return obj.userid === userid;
      });

      // If the user is not found, return the default avatar URL
      if (byuser.length === 0) {
        return defaultAvatarUrl;
      }


      //If user is a client
      if(byuser[0].subscriptiontype == 'client') {
        return defaultAvatarUrl;
      }

      // If the user has an avatar URL, return it; otherwise, return the initials
      if(byuser[0] && byuser[0].avatarurl) {
      }
      return byuser[0].avatarurl ? byuser[0].avatarurl : byuser[0].firstname.substring(0, 1) + byuser[0].lastname.substring(0, 1);
    },
    updateShowToClientCheckbox(commentid, showtoclient) {
      updateShowToClientCheckboxExternal(commentid, showtoclient);
    },
    initializeEditCommentModal(comment) {
      this.$store.state.currentProfileComment = "";
      this.$store.state.currentProfileComment = comment;

      document.getElementById("editProfileCommentTextarea").style.height =
        "250px";
    },
  },
  computed: {
    sortedComments() {
      //the comments are already sorted in the Firestore query, but we sort them again here to keep sorting when adding a new comment
      const comments = this.$store.state.currentProfileComments;

      function compare(a, b) {
        if (a.last_nom < b.last_nom) {
          return -1;
        }
        if (a.last_nom > b.last_nom) {
          return 1;
        }
        return 0;
      }

      comments.sort(compare);

      return comments;
    },
  },
};
</script>

<style>
</style>