import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  connectFirestoreEmulator,
  arrayUnion,
  getCountFromServer,
  arrayRemove,
} from "firebase/firestore";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/index";
import store from "../store/index";

import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import { deleteProjectExternal } from "./projectdetails";

const updateClientStatusExternal = (statusname, elementObject) => {
  let clientRef = doc(db, "clients", elementObject.id);
  updateDoc(clientRef, {
    clientstatus: statusname,
  }).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Client status updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    if (store.state.showingClientsAs == "clientDetails") {
      store.state.currentClientDetails.clientstatus = statusname;
      $("#buttoninclientDetailsCard" + elementObject.id).dropdown("hide");
    } else if (store.state.showingClientsAs == "clientList") {
    }
  });
};

const updateClientTeamAccessExternal = (teamaccess) => {
  let clientRef = doc(db, "clients", store.state.currentClientDetails.id);
  updateDoc(clientRef, {
    teamaccess: teamaccess,
  }).then(() => {
    store.state.currentClientDetails.teamaccess = teamaccess;

    //Confirmation Toast
    iziToast.show({
      title: "Team access updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });

  $("#updateTeamAccess" + store.state.currentClientDetails.id).dropdown(
    "toggle"
  );
};

const saveClientInfoExternal = () => {
  if (!store.state.currentClientDetails.clientname) {
    alert("You must add a client name!");
    return;
  }

  let projectRef = doc(db, "clients", store.state.currentClientDetails.id);
  updateDoc(projectRef, {
    clientname: store.state.currentClientDetails.clientname,
    clientlogourl: store.state.currentClientDetails.clientlogourl,
    location: store.state.currentClientDetails.location,
  }).then(() => {
    //Confirmation Toast
    iziToast.show({
      title: "Client info updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });
  });

  $("#editprojectandclientinfo").modal("toggle");
};

//loads client profiles in the Client Contacts card in Client Details.
const getClientContactsExternal = () => {
  store.state.clientContactsForThisClient = [];

  const qClientContacts = query(
    collection(db, "profiles"),
    where("clientname", "==", store.state.currentClientDetails.clientname),
    where("candidateorclient", "==", "Client")
  );
  getDocs(qClientContacts).then((contactsSnapshot) => {
    contactsSnapshot.forEach((contactsDoc) => {
      let clientContact = contactsDoc.data();
      clientContact.id = contactsDoc.id;

      store.state.clientContactsForThisClient.push(clientContact);
    });
  });
};

const getClientProjectsExternal = () => {
  store.state.projectsForThisClient = [];

  const projectsQuery = query(
    collection(db, "projects"),
    where("clientobject.id", "==", store.state.currentClientDetails.id),
    where("team", "==", store.state.userData.teamid)
  );
  getDocs(projectsQuery).then((projectsSnaps) => {
    projectsSnaps.forEach((projectSnap) => {
      let project = projectSnap.data();
      project.id = projectSnap.id;

      store.state.projectsForThisClient.push(project);
    });
  });
};

const saveClientNotesExternal = () => {
  let clientRef = doc(db, "clients", store.state.currentClientDetails.id);
  updateDoc(clientRef, {
    notes: store.state.currentClientDetails.notes,
  }).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Client notes updated !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

    $("#addprojectorclientnotes").modal("toggle");
  });
};

const uploadClientFileExternal = () => {
  const file = document.getElementById("uploadProjectFile").files[0];
  const filenameParts = file.name.split(".");
  const filetype = filenameParts[filenameParts.length - 1]; // Get the last element of the array

  if (
    filetype != "doc" &&
    filetype != "docx" &&
    filetype != "pdf" &&
    filetype != "xls" &&
    filetype != "xlsx" &&
    filetype != "ppt" &&
    filetype != "pptx"
  ) {
    alert(
      "This file type is not supported. Please upload one of the following file types: Word, Excel, CSV, PowerPoint or PDF."
    );
    return;
  }

  const storage = getStorage();
  const storageRef = ref(
    storage,
    "userid" +
      store.state.userData.userid +
      "fileforclient" +
      store.state.currentClientDetails.id +
      "" +
      file.name
  );

  console.log("IN PROGRESS...");
  $("#uploadingfilealert").modal("show");

  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log("Uploaded a blob or file!");

    getDownloadURL(storageRef)
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        /*
            // This can be downloaded directly:
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            */
        const projectRef = doc(
          db,
          "clients",
          store.state.currentClientDetails.id
        );

        updateDoc(projectRef, {
          files: arrayUnion({
            name: file.name,
            url: url,
            type: filetype,
            id: Math.floor(Math.random() * 100000000),
          }),
        });

        //console.log("DONE... URL: " + url)

        //Confirmation toast
        iziToast.show({
          title: "File uploaded !",
          backgroundColor: "#47be7d",
          titleColor: "white",
          theme: "dark",
          position: "bottomRight",
          timeout: 3000,
          icon: "fa fa-check",
        });

        setTimeout(() => {
          $("#uploadingfilealert").modal("hide");
        }, 700);
      })
      .catch((error) => {
        // Handle any errors
      });
  });
};

const deleteClientExternal = (clientid) => {
  if (store.state.userData.userrole != "Administrator") {
    alert(
      "Your current userrole is 'Operator'. Only 'Administrators' are authorised to delete data."
    );
    return;
  }

  const confirmation = window.confirm(
    "CAUTION: Are you sure you want to delete this client? All project records associated with this client will be deleted. All candidates' history with this client will be deleted aswell. But the candidate records themselves will be kept intact, and they can be attached to other projects / clients."
  );

  if (!confirmation) {
    return; // Exit the function if the user clicks "Cancel"
  }

  store.state.showClientDetails = false;
  store.state.showingClientsAs = "myClients";
  deleteDoc(doc(db, "clients", clientid)).then(() => {
    //Confirmation toast
    iziToast.show({
      title: "Client deleted !",
      backgroundColor: "#47be7d",
      titleColor: "white",
      theme: "dark",
      position: "bottomRight",
      timeout: 3000,
      icon: "fa fa-check",
    });

        //also delete project and associated bindings from the profile docs
    // Create a query against the collection.
    console.log("about to delete project")
    const projectQuery = query(
      collection(db, "projects"),
      where("clientobject.id", "==", clientid),
      where("team", "==", store.state.userData.teamid)
    );

    // Execute the query
    getDocs(projectQuery)
      .then((querySnapshot) => {
        console.log(querySnapshot.docs.length)
        console.log("got the project")
        querySnapshot.forEach((docSnapshot) => {
          console.log("this is the project data: " + JSON.stringify(docSnapshot.data()))
          // Call your deleteProjectExternal function from projectdetails.js
          let projectobject = docSnapshot.data()
          projectobject.id = docSnapshot.id
          deleteProjectExternal(projectobject, "deleteclientfunction");
          console.log("called client project function")
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  });
};

export {
  updateClientStatusExternal,
  updateClientTeamAccessExternal,
  saveClientInfoExternal,
  getClientContactsExternal,
  getClientProjectsExternal,
  saveClientNotesExternal,
  uploadClientFileExternal,
  deleteClientExternal,
};
